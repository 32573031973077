import React from 'react'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import ReactToPrint from 'react-to-print'
import TagToPrint from '../../../Vaccination/Realisation/Process/partials/ContainerVaccineTag/partials/TagToPrint'
import CardHeader from './partials/CardHeader'
import PrepInfos from './partials/PrepInfos'

const OrderRealisation = props => {
  const { hist, intl } = props
  const { action: data } = hist.history
  const { preparation } = data
  let componentToPrint = ''

  return (
    <div className="vaccinationHistory">
      <div className="row historyCard">
        <CardHeader date={hist.createdAt} operator={hist.firstName} />
        <div className="col s12 card" key={data.containerNumber}>
          <div className="row line">
            <div className="col s6">
              <span className="bold">
                {intl.formatMessage({ id: 'app.label.preparationDate' })} :
              </span>{' '}
              {moment(preparation.firstScannedAt).format('DD/MM/YYYY HH:mm')}
            </div>
          </div>
          <div className="row line">
            <div className="col s6">
              {Object.entries(data.orders).map(orderNumber => (
                <div className="row">
                  <div className="col s6">
                    <span className="bold">
                      {intl.formatMessage({ id: 'app.label.orderNumber' })} :{' '}
                    </span>{' '}
                    {orderNumber[0]}
                  </div>
                  <div className="col s6">
                    <span className="bold">{intl.formatMessage({ id: 'app.label.doses' })} : </span>{' '}
                    {Object.entries(orderNumber[1]).map(([key, order]) => {
                      return (
                        order.doses + (orderNumber[1].length === parseInt(key) + 1 ? '' : ' + ')
                      )
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="row line">
            <div className="col s4">
              <span className="bold">{intl.formatMessage({ id: 'app.label.putInReuse' })} :</span>{' '}
              {preparation.reuse === true
                ? intl.formatMessage({ id: 'app.label.yes' })
                : intl.formatMessage({ id: 'app.label.no' })}
            </div>
            <div className="col s4">
              <span className="bold">
                {intl.formatMessage({ id: 'app.label.sharedpreparation' })} :
              </span>{' '}
              {preparation.shared === true
                ? intl.formatMessage({ id: 'app.label.yes' })
                : intl.formatMessage({ id: 'app.label.no' })}
            </div>
            {preparation.dosesLeft !== undefined && (
              <div className="col s4">
                <span className="bold">{intl.formatMessage({ id: 'app.label.dosesLeft' })} :</span>{' '}
                {preparation.dosesLeft}
              </div>
            )}
          </div>
          <div className="divider greyDivider" />
          <div className="row line">
            <PrepInfos prep={preparation} customClass="col s12" />
          </div>
          <div className="row line">
            <div className="col s4 offset-s4">
              <div className="flex flex-center tag">
                <div id="tagContainer" className="toPrint" style={{ display: 'none' }}>
                  <TagToPrint
                    action={data}
                    toPrint
                    ref={el => {
                      componentToPrint = el
                    }}
                  />
                </div>
                <div id="tagContainer">
                  <TagToPrint action={data} />
                </div>
              </div>
              <br />
              <div className="row tagBtns">
                <div className="col s12 flex flex-center">
                  <ReactToPrint
                    trigger={() => (
                      <button className="btn blueBtn" type="button">
                        {intl.formatMessage({ id: 'app.button.printTag' })}
                      </button>
                    )}
                    content={() => componentToPrint}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default injectIntl(OrderRealisation)
