import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { catchError } from '../../../../../actions'
import { api } from '../../../../../parameters'
import CenterButton from '../../../../CenterButton'
import { validatedAction } from '../../../../globalAction'
import OrderInfosBlock from './partials/OrderInfosBlock'
import PrestationsBlock from './partials/PrestationsBlock'

class PrestationsStep extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      result: { prestations: [] }
    }

    this.getStateFromChild = this.getStateFromChild.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  getStateFromChild(prestations) {
    this.setState({ result: { prestations } })
  }

  handleSubmit() {
    const { group, token } = this.props
    const { result } = this.state

    this.setState({ loading: true }, () => {
      Axios.patch(`${api}/api/vaccination/preparation/prestations/${group.id}`, result, {
        headers: { Authorization: token }
      })
        .then(() => validatedAction())
        .catch(err => catchError(err.response))
    })
  }

  render() {
    const { group, intl, totalFixed } = this.props
    const { result, loading } = this.state

    return (
      <>
        <OrderInfosBlock group={group} headerText="prestations" total={totalFixed} />
        <PrestationsBlock setDataFromParent={this.getStateFromChild} data={result} group={group} />
        <CenterButton
          text={intl.formatMessage({ id: 'app.button.validate' })}
          onClick={this.handleSubmit}
          disabled={loading}
        />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(PrestationsStep))
