import React from 'react'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import CardHeader from './partials/CardHeader'
import GroupInfos from './partials/GroupInfos'
import OrderInfos from './partials/OrderInfos'

const OrderPreparation = props => {
  const { hist, intl, isCreation, isPlanning } = props
  const { action: data } = hist.history
  const { group } = data
  const {
    bigDropsOrder,
    injectionOrder,
    thinDropsOrder,
    bigDropsGroup,
    injectionGroup,
    thinDropsGroup
  } = group
  function getGroup() {
    return bigDropsGroup ?? injectionGroup ?? thinDropsGroup ?? group
  }
  return (
    <div className="vaccinationHistory">
      <div className="row historyCard">
        <CardHeader date={hist.createdAt} operator={hist.firstName} />
        <div className="col s12 card" key={data.containerNumber}>
          <div className="row line">
            <div className="col s6">
              <span className="bold">{intl.formatMessage({ id: 'app.label.orderNumber' })}: </span>{' '}
              {hist.history.orderNumber}
            </div>
            <div className="col s6">
              <span className="bold">{intl.formatMessage({ id: 'app.label.hatchingDate' })}: </span>{' '}
              {moment(group.hatchingDate).format('DD/MM/YYYY')}
            </div>
          </div>
          <div className="divider greyDivider" />
          <GroupInfos group={getGroup()} isCreation={isCreation} />
          {!isPlanning && (
            <>
              {injectionOrder && (
                <>
                  <div className="divider greyDivider" />
                  <OrderInfos
                    order={injectionOrder}
                    orderType={0}
                    isCreation={isCreation}
                    isPlanning={isPlanning}
                  />
                </>
              )}
              {thinDropsOrder && (
                <>
                  <div className="divider greyDivider" />
                  <OrderInfos
                    order={thinDropsOrder}
                    orderType={1}
                    isCreation={isCreation}
                    isPlanning={isPlanning}
                  />
                </>
              )}
              {bigDropsOrder && (
                <>
                  <div className="divider greyDivider" />
                  <OrderInfos
                    order={bigDropsOrder}
                    orderType={2}
                    isCreation={isCreation}
                    isPlanning={isPlanning}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
export default injectIntl(OrderPreparation)
