import React from 'react'
import { injectIntl } from 'react-intl'
import InjectableVaccineRadio from './partials/InjectableVaccineRadio'
import SprayableVaccineRadio from './partials/SprayableVaccineRadio'

const ReportProblemFormRadioInput = props => {
  const { intl, preparation, getDataFromChild } = props
  const { bigDropsGroup, injectionGroup, thinDropsGroup } = preparation.planningOrder

  let titleBlock
  let blockContent

  if (injectionGroup !== null) {
    titleBlock = intl.formatMessage({ id: 'app.label.vaccineInjectableProblem' })
    blockContent = <InjectableVaccineRadio getDataFromChild={getDataFromChild} />
  } else if (thinDropsGroup !== null) {
    titleBlock = intl.formatMessage({ id: 'app.label.vaccineThinDropProblem' })
    blockContent = <SprayableVaccineRadio getDataFromChild={getDataFromChild} />
  } else if (bigDropsGroup !== null) {
    titleBlock = intl.formatMessage({ id: 'app.label.vaccineBigDropProblem' })
    blockContent = <SprayableVaccineRadio getDataFromChild={getDataFromChild} />
  }

  return (
    <>
      <div className="row col s12">
        <div className="custom-card card col s12 noPadding">
          <div className="row col s12 card-header">
            <div className="col s6 bold em1-2">{titleBlock}</div>
            <div className="col s6" />
          </div>
          {blockContent}
        </div>
      </div>
    </>
  )
}

export default injectIntl(ReportProblemFormRadioInput)
