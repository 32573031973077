module.exports = {
  api: process.env.REACT_APP_API_URL,
  mercure: process.env.REACT_APP_MERCURE_HUB,
  roles: [
    { role: ['ROLE_OPERATOR'], name: 'Opérateur' },
    { role: ['ROLE_VET'], name: 'Vétérinaire' },
    { role: ['ROLE_HATCHING_RESP'], name: 'Responsable éclosion' },
    { role: ['ROLE_HATCH_RESP'], name: 'Responsable couvoir' }
  ],
  i18n: {
    months: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Aout',
      'Septembre',
      'Octobre',
      'Novembre',
      'Decembre'
    ],
    monthsShort: [
      'Jan',
      'Fev',
      'Mar',
      'Avr',
      'Mai',
      'Juin',
      'Jui',
      'Aou',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    weekdaysAbbrev: ['D', 'L', 'Ma', 'Me', 'J', 'V', 'S'],
    cancel: 'Annuler'
  },
  providers: [
    { name: 'MSD' },
    { name: 'Boehringer' },
    { name: 'Ceva' },
    { name: 'Hipra' },
    { name: 'Huvepharma' },
    { name: 'Zoetis' }
  ],
  containerProviders: [
    { name: 'MSD', capacity: [11, 48] },
    { name: 'Boehringer', capacity: [47] },
    { name: 'Ceva', capacity: [47] },
    { name: 'Zoetis', capacity: [11] }
  ],
  administrations: [{ name: 0 }, { name: 1 }, { name: 2 }],
  valences: [
    { name: 0 },
    { name: 1 },
    { name: 2 },
    { name: 3 },
    { name: 4 },
    { name: 5 },
    { name: 6 },
    { name: 7 },
    { name: 8 },
    { name: 9 },
    { name: 10 },
    { name: 11 },
    { name: 12 },
    { name: 13 },
    { name: 14 },
    { name: 15 },
    { name: 16 }
  ],
  exiprationDateDays: [{ name: 1 }, { name: 15 }, { name: 30 }, { name: 60 }],
  dyesColor: ['sterileBlueDye', 'blue', 'red', 'purple'],
  doses: [{ id: 1 }, { id: 2 }]
}
