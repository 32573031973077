import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import Planning from '../../../../Planning/Planning'
import { goBack } from '../../../../globalAction'

const SprayableVaccineTable = props => {
  const { manageSelectedOrder, intl, role, token, vaccinesType, vaccineType, history } = props

  const pathGoBack = '/vaccination/realisation'

  return (
    <div className="col s12 content">
      <div className="row pageHeader pageTitle itemHeader">
        <div
          onClick={() => goBack(pathGoBack, history)}
          role="button"
          tabIndex="0"
          onKeyPress={() => goBack(pathGoBack, history)}
          className="goBackBtn col s1"
        >
          <i className="material-icons">chevron_left</i>
        </div>
        <h5 className="col s12">
          {intl.formatMessage({ id: 'app.label.realisation' })}&nbsp;
          {vaccinesType.includes(1) && intl.formatMessage({ id: 'app.part.thinDropsVaccines' })}
          {vaccinesType.includes(2) && intl.formatMessage({ id: 'app.part.bigDropsVaccines' })}
        </h5>
      </div>
      <Planning
        token={token}
        role={role}
        sprayableVaccine
        isRealisation
        vaccineType={vaccineType}
        vaccinesType={vaccinesType}
        manageSelectedOrder={manageSelectedOrder}
      />
    </div>
  )
}

export default injectIntl(withRouter(SprayableVaccineTable))
