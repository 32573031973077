import React, { createContext, useState, useEffect } from 'react'
import { Configs, getConfig } from '../config/getConfig'

const HatcheryContext = createContext({
  hatchery: []
})

export const HatcheryProvider = ({ children }) => {
  const [hatchery, setHatchery] = useState([])

  useEffect(() => {
    setHatchery(getConfig(Configs.Hatchery))
  }, [])

  return (
    <HatcheryContext.Provider
      value={{
        hatchery
      }}
    >
      {children}
    </HatcheryContext.Provider>
  )
}

export default HatcheryContext
