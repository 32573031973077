import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Swal from 'sweetalert2'
import { catchError } from '../../../../actions'
import LastAlert from '../../../../components/Alerts/LastAlert'
import FridgeContentArray from '../../../../components/EquipmentManagement/FridgeManagement/FridgeList/FridgeContentArray'
import { BackToPrevious, validatedAction } from '../../../../components/globalAction'
import { renderTemperature } from '../../../../components/globalRenderActions'
import LoadingPage from '../../../../components/LoadingPage'
import StatePill from '../../../../components/StatePill'
import { api } from '../../../../parameters'
import roles from '../../../../config/userRoles.json'

class FridgeSingleView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fridge: {
        fridgeVaccinesBatches: [],
        fridgeDyeBatches: [],
        fridgeDiluentBatches: []
      },
      loading: true
    }
    this.deleteFridge = this.deleteFridge.bind(this)
    this.getFridgeInformations = this.getFridgeInformations.bind(this)
  }

  componentDidMount() {
    this.getFridgeInformations()
  }

  getFridgeInformations() {
    const { token, match } = this.props
    Axios.get(`${api}/api/fridges/${match.params.id}`, { headers: { Authorization: token } })
      .then(res => this.setState({ fridge: res.data, loading: false }))
      .catch(err => catchError(err.response))
  }

  async deleteFridge() {
    const { fridge } = this.state
    const { intl, history, match, token } = this.props
    if (
      fridge.fridgeVaccinesBatches.length > 0 ||
      fridge.fridgeDyeBatches.length > 0 ||
      fridge.fridgeDiluentBatches.length > 0
    ) {
      return Swal.fire({
        title: intl.formatMessage({ id: 'app.alert.areYouSureTitle' }),
        text: intl.formatMessage({ id: 'app.alert.fridgeNotEmpty' }),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: intl.formatMessage({ id: 'app.label.no' }),
        confirmButtonText: intl.formatMessage({ id: 'app.label.yes' }),
        customClass: {
          icon: 'swalErrorIcon',
          title: 'swalTitle',
          content: 'swalContentText',
          confirmButton: 'blueBtn',
          cancelButton: 'blueBtn'
        }
      }).then(result => result.value && history.push('/equipments/fridges/transfer'))
    }

    return Swal.fire({
      title: intl.formatMessage({ id: 'app.alert.areYouSureTitle' }),
      text: intl.formatMessage({ id: 'app.alert.areYouSureText' }),
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: intl.formatMessage({ id: 'app.label.no' }),
      confirmButtonText: intl.formatMessage({ id: 'app.label.yes' }),
      customClass: {
        icon: 'swalErrorIcon',
        title: 'swalTitle',
        content: 'swalContentText',
        confirmButton: 'blueBtn',
        cancelButton: 'blueBtn'
      }
    }).then(result => {
      if (result.value) {
        return Axios.delete(`${api}/api/fridges/delete/${match.params.id}`, {
          headers: { Authorization: token }
        })
          .then(() => validatedAction(''))
          .catch(err => {
            catchError(err.response)
            history.push('/equipments/fridges/list')
          })
      }
      return false
    })
  }

  render() {
    const { fridge, loading } = this.state
    const { history, intl, role } = this.props
    const { ROLE_OPERATOR } = roles

    if (loading) return <LoadingPage />

    return (
      <div className="row Container fridgesManagement">
        <div className="col s12">
          <div className="pageHeader pageTitle row">
            <BackToPrevious history={history} text={fridge.name} />
            {!role.includes(ROLE_OPERATOR) && (
              <button
                type="button"
                className="btn blueBtn col offset-m5 m2"
                onClick={this.deleteFridge}
              >
                Supprimer
              </button>
            )}
            {role.includes(ROLE_OPERATOR) && <div className="col m7" />}
          </div>
          <div className="row cardContainer">
            <div className="col s12 card">
              <div className="row equipmentDescription">
                <div className="col m6">
                  <p>
                    <span className="bold">{intl.formatMessage({ id: 'app.label.brand' })} </span>
                    {fridge.brand}
                  </p>
                </div>
                <div className="col m6">
                  <p>
                    <span className="bold">
                      {`${intl.formatMessage({ id: 'app.label.lastControlDate' })} `}
                    </span>
                    {moment(fridge.lastControl.createdAt).format('DD/MM/YYYY')}{' '}
                    <StatePill type="FridgeDate" data={fridge} />
                  </p>
                </div>
              </div>
              <div className="row equipmentDescription">
                <div className="col m6">
                  <p>
                    <span className="bold">{intl.formatMessage({ id: 'app.label.volume' })} </span>
                    {fridge.volume}
                  </p>
                </div>
                <div className="col m6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({
                        id: 'app.equipment.lastControl.instantTemperature'
                      })}{' '}
                    </span>
                    {renderTemperature(fridge.lastControl.instantTemperature, intl)}{' '}
                    <StatePill type="FridgeInstantTemperature" data={fridge} />
                  </p>
                </div>
              </div>
              <div className="row equipmentDescription">
                <div className="col m6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({
                        id: 'app.label.dateAdded'
                      })}{' '}
                    </span>
                    {moment(fridge.addedDate).format('DD/MM/YYYY')}
                  </p>
                </div>
                <div className="col m6">
                  <span className="bold">
                    {intl.formatMessage({
                      id: 'app.equipment.lastControl.minTemperature'
                    })}{' '}
                  </span>
                  {renderTemperature(fridge.lastControl.minTemperature, intl)}{' '}
                  <StatePill type="FridgeMinTemperature" data={fridge} />
                </div>
              </div>
              <div className="row equipmentDescription">
                <div className="col m6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({ id: 'app.label.hourAdded' })}{' '}
                    </span>
                    {moment(fridge.addedDate).format('HH:mm')}
                  </p>
                </div>
                <div className="col m6">
                  <span className="bold">
                    {intl.formatMessage({
                      id: 'app.equipment.lastControl.maxTemperature'
                    })}{' '}
                  </span>
                  {renderTemperature(fridge.lastControl.maxTemperature, intl)}{' '}
                  <StatePill type="FridgeMaxTemperature" data={fridge} />
                </div>
              </div>
              <div className="divider greyDivider" />
              <LastAlert equipmentId={fridge.name} cat={[0, 12]} />
            </div>
          </div>
        </div>
        <FridgeContentArray
          Vaccines={fridge.fridgeVaccinesBatches}
          Dyes={fridge.fridgeDyeBatches}
          Diluents={fridge.fridgeDiluentBatches}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    notifs: state.mercure.notifications,
    role: state.auth.role
  }
}

export default withRouter(connect(mapStateToProps)(injectIntl(FridgeSingleView)))
