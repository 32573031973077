import M from 'materialize-css'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router'
import StockParamsProducts from '../../../components/Parameters/Stock/StockParamsProducts'
import StockParamsVaccineInjectable from '../../../components/Parameters/Stock/StockParamsVaccineInjectable'
import StockParamsVaccineSprayable from '../../../components/Parameters/Stock/StockParamsVaccineSprayable'

class StockParamsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      idSelected: 0
    }
    this.handleClickLink = this.handleClickLink.bind(this)
  }

  componentDidMount() {
    const { location } = this.props

    if (location.hash === '#sprayedVaccine') {
      this.setState({ idSelected: 1 })
    } else if (location.hash === '#products') {
      this.setState({ idSelected: 2 })
    }

    M.AutoInit()
  }

  handleClickLink(e, id) {
    const { history } = this.props

    if (id === 0) {
      history.push('#injectedVaccine')
    } else if (id === 1) {
      history.push('#sprayedVaccine')
    } else if (id === 2) {
      history.push('#products')
    }
    this.setState({ idSelected: id })
  }

  render() {
    const { idSelected } = this.state
    return (
      <div className="row Container params">
        <div className="col s12 paramsContent">
          <ul className="tabs">
            <li className="tab col s4">
              <a
                className="active"
                href="#injectedVaccine"
                onClick={() => this.handleClickLink(this, 0)}
              >
                <FormattedMessage id="app.label.injectedVaccine" />
              </a>
            </li>
            <li className="tab col s4">
              <a href="#sprayedVaccine" onClick={() => this.handleClickLink(this, 1)}>
                <FormattedMessage id="app.label.sprayedVaccine" />
              </a>
            </li>
            <li className="tab col s4">
              <a href="#products" onClick={() => this.handleClickLink(this, 2)}>
                <FormattedMessage id="app.label.products" />
              </a>
            </li>
            <li className="indicator" />
          </ul>
        </div>
        <div id="injectedVaccine" className="col s12 content marginTop30 active">
          {idSelected === 0 && <StockParamsVaccineInjectable />}
        </div>
        <div id="sprayedVaccine" className="col s12 content marginTop30">
          {idSelected === 1 && <StockParamsVaccineSprayable />}
        </div>
        <div id="products" className="col s12 content marginTop30">
          {idSelected === 2 && <StockParamsProducts />}
        </div>
      </div>
    )
  }
}

export default withRouter(StockParamsList)
