import React from 'react'
import { injectIntl } from 'react-intl'

const Header = props => {
  const { intl } = props

  return (
    <div className="waitedVaccinesHeader">
      <div>{intl.formatMessage({ id: 'app.label.waitedVaccines' })}</div>
    </div>
  )
}

export default injectIntl(Header)
