import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import { ExportFile } from '../../../components/globalAction'
import productTypes from '../../../config/productTypes'

function Single({ token, location, intl, history, role }) {
  const [item, setItem] = useState(null)
  const id = Number(location.pathname.split('/').pop())
  const translationKey = 'app.label.vaccine_or_product.single.'
  const t = key => intl.formatMessage({ id: `${translationKey}${key}` })
  const unknownValue = intl.formatMessage({ id: 'app.label.N/A' })
  function mapItem(items, start, end) {
    return [...items]
      .map(i => ({
        ...i,
        article: i.article ?? i.batchNumber,
        batchNumber: i.batchNumber ?? unknownValue,
        expirationDate: i.expirationDate
          ? moment(i.expirationDate).format('DD/MM/YYYY')
          : unknownValue,
        firstOccurrenceDate:
          start ?? i.firstOccurrenceDate
            ? moment(i.firstOccurrenceDate).format('DD/MM/YYYY')
            : unknownValue,
        lastOccurrenceDate: end ?? unknownValue,
        provider: i.provider ?? unknownValue,
        speciality: i.speciality ?? unknownValue
      }))
      .shift()
  }

  useEffect(() => {
    Axios.get(`${api}/api/batch_traceabilities?id=${id}`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const batchType = res.data['hydra:member']?.[0]?.batchType
        if (
          [
            productTypes.VACCINES_BATCH,
            productTypes.DILUENT_BATCH,
            productTypes.DYE_BATCH
          ].includes(batchType)
        ) {
          Axios.get(`${api}/api/batch_traceability/${id}/storage/light`, {
            headers: { Authorization: token }
          }).then(lightRes => {
            const start = lightRes.data['hydra:member'].start?.date
            const end = lightRes.data['hydra:member'].end?.date
            const used = lightRes.data['hydra:member'].start?.used
            setItem(
              mapItem(
                res.data['hydra:member'],
                start ? moment(start).format('DD/MM/YYYY') : t('not_yet_used'),
                end ? moment(end).format('DD/MM/YYYY') : t(used ? 'during_use' : 'not_yet_used')
              )
            )
          })
        } else if (batchType === productTypes.PRODUCT_BAG) {
          setItem(mapItem(res.data['hydra:member'], null, t('during_use')))
        } else {
          setItem(mapItem(res.data['hydra:member'], unknownValue, unknownValue))
        }
      })
      .catch(err => catchError(err))
  }, [])

  const categories = [
    {
      label: 'reception',
      path: 'reception',
      excludedProductTypes: []
    },
    {
      label: 'orders_involved',
      path: 'orders_involved',
      excludedProductTypes: []
    },
    {
      label: 'storage_device',
      path: 'storage_device',
      excludedProductTypes: [productTypes.PRODUCT_BAG]
    },
    {
      label: 'vaccine_transfer',
      path: 'vaccine_transfer',
      excludedProductTypes: [
        productTypes.PRODUCT_BAG,
        productTypes.DILUENT_BATCH,
        productTypes.DYE_BATCH
      ]
    },
    {
      label: 'stock_withdrawal',
      path: 'stock_withdrawal',
      excludedProductTypes: []
    },
    {
      label: 'problems_encountered',
      path: 'problems_encountered',
      excludedProductTypes: [productTypes.DILUENT_BATCH, productTypes.DYE_BATCH]
    }
  ]

  return !item ? (
    <LoadingPage />
  ) : (
    <div className="row Container stockAvailibilityItem traceabilitySingle">
      <div className="col s12 content">
        <div className="row pageHeader pageTitle itemHeader">
          <div
            onClick={() => history.goBack()}
            role="button"
            tabIndex="0"
            onKeyPress={() => history.goBack()}
            className="goBackBtn col s1"
          >
            <i className="material-icons">chevron_left</i>
          </div>
          <h5 className="col s12">{item.article}</h5>
          <div className="col m1">
            <ExportFile data={{ role, token }} url={`/api/batch_traceability/${id}/export/xlsx`} />
          </div>
        </div>
        <div className="row cardContainer">
          <div className="col s12 card">
            <div className="pageTitle">
              <h5>
                {intl.formatMessage({ id: `app.filter.stockProductsType.${item.batchType}` })}
              </h5>
            </div>
            <div className="divider greyDivider" />
            <div className="row description">
              <div className="col m6">
                <p>
                  <span className="label">
                    {intl.formatMessage({
                      id: `app.label.vaccine_or_product.single.${
                        item.batchType === 0 ? 'specialty' : 'supplier'
                      }`
                    })}
                    &nbsp;:&nbsp;
                  </span>
                  {item.batchType === 0 ? item.speciality : item.provider}
                </p>
              </div>
              <div className="col m6">
                <p>
                  <span className="label">
                    {intl.formatMessage({
                      id: `app.label.vaccine_or_product.single.expiration_date`
                    })}
                    &nbsp;:&nbsp;
                  </span>
                  {item.expirationDate}
                </p>
              </div>
              <div className="col m6">
                <p>
                  <span className="label">
                    {intl.formatMessage({ id: 'app.label.vaccine_or_product.single.article' })}
                    &nbsp;:&nbsp;
                  </span>
                  {item.article}
                </p>
              </div>
              <div className="col m6">
                <p>
                  <span className="label">
                    {intl.formatMessage({
                      id: `app.label.vaccine_or_product.single.first_occurence_date`
                    })}
                    &nbsp;:&nbsp;
                  </span>
                  {item.firstOccurrenceDate}
                </p>
              </div>
              <div className="col m6">
                <p>
                  <span className="label">
                    {intl.formatMessage({ id: `app.label.vaccine_or_product.single.batchNumber` })}
                    &nbsp;:&nbsp;
                  </span>
                  {item.batchNumber}
                </p>
              </div>
              <div className="col m6">
                <p>
                  <span className="label">
                    {intl.formatMessage({
                      id: `app.label.vaccine_or_product.single.last_occurence_date`
                    })}
                    &nbsp;:&nbsp;
                  </span>
                  {item.lastOccurrenceDate}
                </p>
              </div>
            </div>
          </div>
        </div>
        <table className="table">
          {categories.map(
            category =>
              !category.excludedProductTypes.includes(item.batchType) && (
                <tr
                  key={item.id}
                  onClick={() => {
                    history.push({
                      pathname: `/traceability/vaccine_or_product/single/${id}/${category.path}`
                    })
                  }}
                  className="pointer"
                >
                  <td className="label">
                    {intl.formatMessage({
                      id: `app.label.vaccine_or_product.single.${category.label}`
                    })}
                  </td>
                  <td>
                    <i className="material-icons arrow-right">keyboard_arrow_right</i>
                  </td>
                </tr>
              )
          )}
        </table>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(Single)))
