import { FormattedMessage } from 'react-intl'
import React from 'react'
import moment from 'moment'
import HeadSingleHistory from './HeadSingleHistory'

export default function ContainerUseEnd(props) {
  const { history, operator, date, action, intl } = props

  return (
    <div className="Container equipmentHistory">
      <HeadSingleHistory operator={operator} date={date} action={action}>
        <div className="row">
          <div className="col s6">
            <p>
              <span className="label">
                <FormattedMessage id="app.label.containerId" />
              </span>
              {history.action.nitrogenContainer.containerNumber}
            </p>
          </div>
          <div className="col s6">
            <p>
              <span className="label">
                <FormattedMessage id="app.equipment.lastControl" />
                &nbsp;:&nbsp;
              </span>
              {moment(history.action.containerLastControlDate).format('DD/MM/YYYY')}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col s6">
            <p>
              <span className="label">
                <FormattedMessage id="app.label.container" />
                &nbsp;:&nbsp;
              </span>
              <FormattedMessage
                id={`app.equipment.containerType.${history.action.nitrogenContainer.containerType}`}
              />
            </p>
          </div>
          <div className="col s6">
            <p>
              <span className="label">
                <FormattedMessage id="app.label.provider" />
                &nbsp;:&nbsp;
              </span>
              {history.action.nitrogenContainer.containerProvider}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col s6">
            <p>
              <span className="label">
                <FormattedMessage id="app.label.containerCapacity" />
                &nbsp;:&nbsp;
              </span>
              {`${history.action.nitrogenContainer.containerCapacity} ${intl.formatMessage({
                id: 'app.unit.containerCapacity'
              })}`}
            </p>
          </div>
        </div>
        {history.action.askedDate && (
          <>
            <div className="row">
              <div className="col s6">
                <p>
                  <span className="label">
                    <FormattedMessage id="app.label.formOrder" />
                    &nbsp;:&nbsp;
                  </span>
                  <FormattedMessage id={`app.label.${history.action.filledDeliveryForm}`} />
                </p>
              </div>
              <div className="col s6">
                {history.action.filledDeliveryFormComment !== '' && (
                  <p>
                    <span className="label">
                      <FormattedMessage id="app.label.commentary" />
                      &nbsp;:&nbsp;
                    </span>
                    {history.action.filledDeliveryFormComment}
                  </p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col s6">
                <p>
                  <span className="label">
                    <FormattedMessage id="app.label.generalState" />
                    &nbsp;:&nbsp;
                  </span>
                  <FormattedMessage id={`app.label.${history.action.generalState}`} />
                </p>
              </div>
              <div className="col s6">
                {history.action.generalStateComment !== '' && (
                  <p>
                    <span className="label">
                      <FormattedMessage id="app.label.commentary" />
                      &nbsp;:&nbsp;
                    </span>
                    {history.action.generalStateComment}
                  </p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col s6">
                <p>
                  <span className="label">
                    <FormattedMessage id="app.label.originCarton" />
                    &nbsp;:&nbsp;
                  </span>
                  <FormattedMessage id={`app.label.${history.action.returnedInOriginBox}`} />
                </p>
              </div>
              <div className="col s6">
                {history.action.returnedInOriginBoxComment !== '' && (
                  <p>
                    <span className="label">
                      <FormattedMessage id="app.label.commentary" />
                      &nbsp;:&nbsp;
                    </span>
                    {history.action.returnedInOriginBoxComment}
                  </p>
                )}
              </div>
            </div>
          </>
        )}
        <div className="row">
          {history.action.askedDate && (
            <div className="col s4">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.requestDate" />
                  &nbsp;:&nbsp;
                </span>
                {moment(history.action.askedDate).format('DD/MM/YYYY')}
              </p>
            </div>
          )}
          <div className="col s4">
            <p>
              <span className="label">
                <FormattedMessage id="app.label.receiptDate" />
                &nbsp;:&nbsp;
              </span>
              {moment(history.action.lastControlDate).format('DD/MM/YYYY')}
            </p>
          </div>
          <div className="col s4">
            <p>
              <span className="label">
                <FormattedMessage id="app.label.receiptHour" />
                &nbsp;:&nbsp;
              </span>
              {moment(history.action.lastControlDate).format('HH:mm')}
            </p>
          </div>
        </div>
      </HeadSingleHistory>
    </div>
  )
}
