import React from 'react'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import SprayableVaccineTable from '../../../components/Vaccination/Realisation/Process/SprayableVaccinePart/SprayableVaccineTable'

class SprayableVaccine extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      vaccinesType: [parseInt(props.match.params.vaccineType)],
      vaccineType: parseInt(props.match.params.vaccineType),
      pagePart: 0,
      actualPart: 1,
      orderSelected: []
    }
    this.changePagePart = this.changePagePart.bind(this)
    this.manageSelectedOrder = this.manageSelectedOrder.bind(this)
  }

  manageSelectedOrder(checked, group) {
    const { orderSelected } = this.state

    if (checked) {
      orderSelected.push(group)
    } else {
      let indexToDelete = null
      orderSelected.forEach((item, index) => {
        if (JSON.stringify(item) === JSON.stringify(group)) {
          indexToDelete = index
        }
      })
      orderSelected.splice(indexToDelete, 1)
    }
    this.setState({ orderSelected })
  }

  changePagePart() {
    let { actualPart, pagePart } = this.state

    if (pagePart === 1) {
      actualPart += 1
      this.setState({ actualPart })
    }

    pagePart += 1
    this.setState({ pagePart })
  }

  render() {
    const { role, token } = this.props
    const { orderSelected, vaccinesType, vaccineType } = this.state

    return (
      <div className="row Container stockAvailibilityItem">
        <SprayableVaccineTable
          token={token}
          role={role}
          vaccineType={vaccineType}
          vaccinesType={vaccinesType}
          changePagePart={this.changePagePart}
          manageSelectedOrder={this.manageSelectedOrder}
          orderSelected={orderSelected}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(SprayableVaccine)))
