import React from 'react'
import HeaderProductBagPart from './partials/HeaderProductBagPart'
import SinglePartRadios from '../SinglePartRadios'

class ProductBagPart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getDataFromChild(name, value) {
    if (name === 'batchesVaccinated') {
      this.setState({ [name]: parseInt(value) })
    } else {
      this.setState({ [name]: value })
    }
  }

  render() {
    const { prep, prepId } = this.props

    return (
      <>
        <HeaderProductBagPart prep={prep} />
        <SinglePartRadios container="pBag" prepId={prepId} />
      </>
    )
  }
}

export default ProductBagPart
