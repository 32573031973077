import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'

class SelectPart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    M.AutoInit()
  }

  render() {
    const { intl, newVaccines, handleChange } = this.props

    return (
      <>
        <label htmlFor="newVaccine" className="labelParams">
          {intl.formatMessage({ id: 'app.label.newVaccine' })}
        </label>
        <select
          className="select-wrapper"
          name="newVaccine"
          defaultValue="0"
          onChange={handleChange}
          required
        >
          <option value="0" disabled>
            {intl.formatMessage({ id: 'app.label.selectVaccine' })}
          </option>
          {newVaccines.map(newVaccine =>
            (newVaccine.ampoulesNumber !== undefined && newVaccine.ampoulesNumber !== 0) ||
            (newVaccine.vialsNumber !== undefined && newVaccine.vialsNumber !== 0) ? (
              <option key={newVaccine.id} value={newVaccine.id}>
                {newVaccine.nitrogenContainer
                  ? intl.formatMessage({ id: 'app.label.container' })
                  : intl.formatMessage({ id: 'app.label.fridge' })}{' '}
                {newVaccine.nitrogenContainer
                  ? newVaccine.nitrogenContainer.containerNumber
                  : newVaccine.fridge.name}
                {' - '}
                {newVaccine.vaccinesBatch.batchNumber}
              </option>
            ) : (
              ''
            )
          )}
        </select>
      </>
    )
  }
}

export default injectIntl(SelectPart)
