import React from 'react'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import icons from '../../../assets/icons/icons'
import StatePill from '../../StatePill'

const BlockItem = props => {
  const { arrowLogo, prep, intl, orderNumberDisplay } = props

  let logoArrow = null

  if (arrowLogo === true) {
    logoArrow = (
      <div className="col s1">
        <img src={icons.blueArrow} alt="logo blue arrow" />
      </div>
    )
  }

  const meltOrders = []

  prep.orderOrderPreparations.forEach(order => {
    const { planningOrder } = order
    const { injectionGroup, thinDropsGroup, bigDropsGroup } = planningOrder
    let group

    if (injectionGroup !== null) {
      group = injectionGroup
    } else if (thinDropsGroup !== null) {
      group = thinDropsGroup
    } else if (bigDropsGroup !== null) {
      group = bigDropsGroup
    }

    const { number } = group

    meltOrders.push(number)
  })

  const meltVaccines = []

  prep.orderOrderPreparations[0].planningOrder.vaccines.forEach(vaccine => {
    meltVaccines.push(
      `${vaccine.speciality.name} ${intl.formatMessage({
        id: `app.label.doses.${vaccine.doseType}`
      })}`
    )
  })

  return (
    <div className="row card custom-card endRealListItem">
      <div className="row card-content">
        <div className="card-block flex">
          <div className="col s6 bold">
            <div className="logoWithTitle">
              {prep.bottle !== null ? (
                <img src={icons.bottle} alt="logo bottle" />
              ) : (
                <img src={icons.productBag} alt="logo product bag" />
              )}
              <span>{prep.qr}</span>
            </div>
          </div>
          <div className={`col  ${arrowLogo ? 's5' : 's6'} bold`}>{meltVaccines.join(' + ')}</div>
          {logoArrow}
        </div>
        <div className="divider greyDivider" />
        <div className="card-block flex blue-text">
          <div className="col s6">
            <span className="bold">{intl.formatMessage({ id: 'app.label.preparationDate' })}</span>
            &nbsp;
            {moment(prep.firstScannedAt).format('DD/MM/YYYY HH:mm')}
          </div>
          <div className="col s6">
            <StatePill type="Parameter" data={prep.useState} />
            &nbsp;
            <span className="bold">{intl.formatMessage({ id: 'app.label.endUseDate' })}</span>
            &nbsp;
            {moment(prep.secondScannedAt).format('DD/MM/YYYY HH:mm')}
          </div>
        </div>
        {orderNumberDisplay && (
          <>
            <div className="divider greyDivider" />
            <div className="card-block flex">
              <div className="col s12">
                <span className="bold">{intl.formatMessage({ id: 'app.label.ordersNumber' })}</span>
                &nbsp;
                {meltOrders.join(' + ')}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default injectIntl(BlockItem)
