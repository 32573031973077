import React from 'react'
import { injectIntl } from 'react-intl'

const OrderBlockInfo = props => {
  const { intl, order, group, vaccineType } = props
  let vaccineTypeString = 'injectableVaccine'
  let containerType = 'productBags'

  if (vaccineType > 0) {
    containerType = 'cans'

    if (vaccineType === 1) {
      vaccineTypeString = 'fineDrops'
    } else if (vaccineType === 2) {
      vaccineTypeString = 'bigDrops'
    }
  }

  const { orderOrderPreparations } = order
  const { boxesNumber, clientName, number, totalChicks } = group

  const melt = []

  if (orderOrderPreparations.length !== 0) {
    orderOrderPreparations[0].preparation.vaccines.forEach(vaccine => {
      melt.push(vaccine.speciality)
    })
  }

  return (
    <div className="row">
      <div className="col s12">
        <div className="custom-card col s12 noPadding">
          <div className="col s12 card-header">
            <div className="col s3 bold em1-2">
              {intl.formatMessage({ id: 'app.label.ORDER' })} {number}{' '}
            </div>
            <div className="col s3 bold">
              {intl.formatMessage({ id: `app.label.${vaccineTypeString}` })}
            </div>
            <div className="col s6 bold">
              {intl.formatMessage({ id: 'app.label.clientfr319' })} {clientName}
            </div>
          </div>
          <div className="col s12 card-content">
            <div className="card-block row">
              <div className="col s4">
                {totalChicks} {intl.formatMessage({ id: 'app.label.chicks' })}
              </div>
              <div className="col s4">
                {boxesNumber} {intl.formatMessage({ id: 'app.label.boxes' })}
              </div>
              <div className="col s4">
                {orderOrderPreparations.length}{' '}
                {intl.formatMessage({ id: `app.label.${containerType}` })}
              </div>
            </div>
            <div className="card-block row">
              <div className="col s12">{melt.join(' + ')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(OrderBlockInfo)
