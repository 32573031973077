import React from 'react'
import { injectIntl } from 'react-intl'

function WithUnit({ text, unit, intl }) {
  const unknownValue = intl.formatMessage({ id: 'app.label.N/A' })
  const textWithUnit =
    text !== unknownValue
      ? `${text} ${intl.formatMessage({
          id: `app.unit.${unit}`
        })}`
      : text
  return <span>{textWithUnit}</span>
}

export default injectIntl(WithUnit)
