import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { catchError } from '../../../actions'
import icons from '../../../assets/icons/icons'
import { api } from '../../../parameters'
import AsyncLoader from '../../AsyncLoader'
import Filters from '../../Filters/Filters'
import LoadingPage from '../../LoadingPage'

class StockParamsProducts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contentStatus: false,
      url: `${api}/api/stock_parameters`,
      filters: [
        { category: 'order[productType]', sortBy: ['asc', 'desc'] },
        { category: 'order[provider]', sortBy: ['asc', 'desc'] },
        { category: 'order[article]', sortBy: ['asc', 'desc'] },
        { category: 'order[volume]', sortBy: ['asc', 'desc'] }
      ],
      parameters: [],
      loading: true
    }
    this.getStateFromChild = this.getStateFromChild.bind(this)
  }

  componentDidMount() {
    const { token } = this.props

    Axios.get(`${api}/api/stock_parameters`, {
      headers: {
        Authorization: token
      }
    })
      .then(res => {
        this.setState({
          parameters: res.data['hydra:member'],
          totalItems: res.data['hydra:totalItems'],
          contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult',
          loading: false
        })
      })
      .catch(err => catchError(err.response))
  }

  getStateFromChild(data, infiniteLoader = false) {
    let { parameters, contentStatus } = this.state
    if (infiniteLoader) {
      parameters = [...parameters, ...data['hydra:member']]
    } else {
      parameters = data['hydra:member']
    }

    if (parameters.length >= data['hydra:totalItems']) {
      contentStatus = 'noMoreResult'
    }
    this.setState({
      parameters,
      loading: false,
      contentStatus
    })
  }

  inLoad(contentStatus) {
    this.setState({ contentStatus })
  }

  render() {
    const { intl, token, role } = this.props
    const { loading, parameters, url, filters, contentStatus, totalItems } = this.state

    if (loading) return <LoadingPage />
    return (
      <div>
        <div className="row">
          <div className="col s12">
            <div className="pageHeader">
              <div>
                <Filters
                  token={token}
                  setStateToParent={this.getStateFromChild}
                  url={url}
                  role={role}
                  infiniteLoader
                  totalItems={totalItems}
                  sortBy="asc"
                  sortCategory="order[provider]"
                  filters={filters}
                  inLoad={this.inLoad}
                />
              </div>
              <Link className="btn blueBtn addBtn" to={{ pathname: '/stock_parameters/add' }}>
                {intl.formatMessage({ id: 'app.label.add' })}
              </Link>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>{intl.formatMessage({ id: `app.label.productType` })}</th>
                  <th>{intl.formatMessage({ id: `app.label.provider` })}</th>
                  <th>{intl.formatMessage({ id: `app.label.article` })}</th>
                  <th>{intl.formatMessage({ id: `app.label.volume` })}</th>
                  <th>{intl.formatMessage({ id: `app.label.conservation` })}</th>
                  <th>{intl.formatMessage({ id: `app.label.minStock` })}</th>
                  <th>{intl.formatMessage({ id: `app.label.expirationDate` })}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {parameters.map(param => (
                  <tr className="pointer" key={`param-${param.id}`}>
                    <td>
                      {intl.formatMessage({ id: `app.label.productType.${param.productType}` })}
                    </td>
                    <td>{param.provider || intl.formatMessage({ id: 'app.label.N/A' })}</td>
                    <td>{param.article || intl.formatMessage({ id: 'app.label.N/A' })}</td>
                    <td>{param.volume || intl.formatMessage({ id: 'app.label.N/A' })}</td>
                    <td>
                      {intl.formatMessage({
                        id: `app.label.conservationMethod.${param.conservation}`
                      })}
                    </td>
                    <td>
                      <div className="flex">
                        <i className="material-icons blocking-text orange-text pill">
                          brightness_1
                        </i>
                        {param.nonBlockingAlert.value.stock}
                      </div>
                    </td>
                    <td>
                      <div className="flex">
                        {param.nonBlockingAlert.value.expirationDate && (
                          <>
                            {' '}
                            <i className="material-icons blocking-text orange-text pill">
                              brightness_1
                            </i>
                            {param.nonBlockingAlert.value.expirationDate}{' '}
                            {intl.formatMessage({
                              id: 'app.unit.days'
                            })}
                          </>
                        )}
                        &nbsp;
                        {param.blockingAlert.value && (
                          <>
                            <i className="material-icons blocking-text red-text pill">
                              brightness_1
                            </i>
                            {param.blockingAlert.value}{' '}
                            {intl.formatMessage({
                              id: 'app.unit.days'
                            })}
                          </>
                        )}
                      </div>
                    </td>
                    <td>
                      <Link to={`/parameters/stock/products/edit/${param.id}`}>
                        <img src={icons.blackPen} alt="black pen icon" />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot className="center">
                <tr>
                  <th colSpan="12">
                    <AsyncLoader status={contentStatus} />
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default injectIntl(connect(mapStateToProps)(StockParamsProducts))
