import { FormattedMessage, injectIntl } from 'react-intl'
import React from 'react'
import HeadSingleHistoryStock from './HeadSingleHistoryStock'

const NitrogenReceipt = props => {
  const { history, operator, date, intl, type } = props
  const { action: data } = history
  const { nitrogenContainers } = data

  return (
    <div className="Container stockHistory">
      <HeadSingleHistoryStock operator={operator} date={date} action={type}>
        {nitrogenContainers.length > 0 && (
          <div className="row historyCard">
            {nitrogenContainers.map(nitrogenContainer => (
              <div className="col s12 card" key={nitrogenContainer.containerNumber}>
                <div className="row">
                  <div className="col s12">
                    <div className="row">
                      <h5>
                        <FormattedMessage id="app.label.catcherContainer" />
                      </h5>
                    </div>
                    <div className="row">
                      <div className="col s4">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.containerId" />
                          </span>
                          {nitrogenContainer.containerNumber}
                        </p>
                      </div>
                      <div className="col s4">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.container" />
                            :&nbsp;
                          </span>
                          <FormattedMessage
                            id={`app.equipment.containerType.${nitrogenContainer.containerType}`}
                          />
                        </p>
                      </div>
                      <div className="col s4">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.provider" />
                            &nbsp;:&nbsp;
                          </span>
                          {nitrogenContainer.containerProvider}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col s4">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.containerCapacity" />
                            :&nbsp;
                          </span>
                          {`${nitrogenContainer.containerCapacity} ${intl.formatMessage({
                            id: 'app.unit.containerCapacity'
                          })}`}
                        </p>
                      </div>
                      <div className="col s4">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.prevNitrogenLevel" />
                            &nbsp;:&nbsp;
                          </span>
                          {`${nitrogenContainer.prevNitrogenLevel} ${intl.formatMessage({
                            id: 'app.unit.nitrogenLevel'
                          })}`}
                        </p>
                      </div>
                      <div className="col s4">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.newNitrogenLevel" />
                            :&nbsp;
                          </span>
                          {`${nitrogenContainer.newNitrogenLevel} ${intl.formatMessage({
                            id: 'app.unit.nitrogenLevel'
                          })}`}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </HeadSingleHistoryStock>
    </div>
  )
}

export default injectIntl(NitrogenReceipt)
