import React from 'react'
import { injectIntl } from 'react-intl'
import M from 'materialize-css'
import { pBagVolumes } from '../../../../../../../../config/productConfig'

class VolumeSelector extends React.Component {
  constructor() {
    super()

    this.handleVolumeChange = this.handleVolumeChange.bind(this)
  }

  componentDidMount() {
    M.AutoInit()
  }

  handleVolumeChange(e) {
    const { setVolume, setTotalQuantity, neededDoses } = this.props
    const { value: volume } = e.target

    setVolume(parseInt(volume))
    setTotalQuantity(Math.ceil(neededDoses / volume))
  }

  render() {
    const { intl, volume, totalQuantity, selectedBatches, handleReinit, disabled } = this.props

    return (
      <div className="row card-block">
        <div className="input col l3">
          <label htmlFor="volume">{intl.formatMessage({ id: `app.label.volume` })}</label>
          <select
            className="select-wrapper"
            name="volume"
            defaultValue={volume}
            onChange={this.handleVolumeChange}
            disabled={disabled}
          >
            {pBagVolumes.map(pBagVolume => (
              <option key={pBagVolume.doses} value={pBagVolume.doses}>
                {pBagVolume.label}
              </option>
            ))}
          </select>
        </div>

        <div className="input col l3">
          <label htmlFor="quantity">
            {intl.formatMessage({ id: `app.label.totalNeededQuantity` })}
          </label>
          <input type="number" disabled name="quantity" value={totalQuantity} />
        </div>

        <div className="col offset-l5 l1">
          {selectedBatches[0].batch !== undefined && (
            <button type="button" className="btn redoBtn" onClick={handleReinit}>
              <i className="material-icons">replay</i>
            </button>
          )}
        </div>
      </div>
    )
  }
}

export default injectIntl(VolumeSelector)
