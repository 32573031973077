import React from 'react'
import { injectIntl } from 'react-intl'
import icons from '../../../../assets/icons/icons'
import ReceivedVaccineOnly from '../FrozenVaccineReception/ContainerInformations/ReceivedVaccineOnly'
import ReceivedNitrogenOnly from './ReceivedNitrogenOnly'

class ContainerInformations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      receptionType: 0
    }
    this.changeType = this.changeType.bind(this)
  }

  getContent() {
    const { receptionType } = this.state
    const {
      intl,
      handleChange,
      changePart,
      receiverContainers,
      setDataToParent,
      setDisabled,
      disabled
    } = this.props
    switch (receptionType) {
      case 1:
        return (
          <ReceivedVaccineOnly
            handleChange={handleChange}
            intl={intl}
            changePart={changePart}
            setDataToParent={setDataToParent}
            receiverContainers={receiverContainers}
            disabled={disabled}
            setDisabled={setDisabled}
            nitrogen
          />
        )
      case 2:
        return (
          <ReceivedNitrogenOnly
            handleChange={handleChange}
            setDataToParent={setDataToParent}
            intl={intl}
            changePart={changePart}
            receiverContainers={receiverContainers}
            disabled={disabled}
            setDisabled={setDisabled}
          />
        )
      default:
        return false
    }
  }

  changeType(e) {
    const { value } = e.target
    const { receptionType } = this.state
    const { setDataToParent } = this.props
    this.setState({ receptionType: parseInt(value) }, () =>
      setDataToParent('receptionType', receptionType)
    )
  }

  render() {
    const { intl, handleChange } = this.props
    return (
      <div className="row">
        <div className="col s12">
          <div className="row">
            <div className="col s12">
              <div className="form">
                <div className="row">
                  <p className="col s6 radio">
                    <label htmlFor="receptionType1">
                      <input
                        name="receptionType"
                        value="1"
                        id="receptionType1"
                        type="radio"
                        onClick={this.changeType}
                        onChange={handleChange}
                      />
                      <span>
                        {intl.formatMessage({ id: 'app.label.nitrogen&VaccineReception' })}
                      </span>
                    </label>
                  </p>
                  <p className="radio col s6">
                    <label htmlFor="receptionType2">
                      <input
                        name="receptionType"
                        value="2"
                        id="receptionType2"
                        type="radio"
                        onClick={this.changeType}
                        onChange={handleChange}
                      />
                      <span>{intl.formatMessage({ id: 'app.label.onlyNitrogenReception' })}</span>
                    </label>
                  </p>
                </div>
                <div className="divider greyDivider" />
                <div className="row ">
                  <div className="col s12 redMsg">
                    <img src={icons.redDanger} alt="red danger icon" />
                    <p className="red-text">
                      {intl.formatMessage({ id: 'app.part.nitrogenTransfer.text2' })}
                    </p>
                  </div>
                </div>
                <div className="row hidden">
                  <div className="col s12">Alert</div>
                </div>
              </div>
            </div>
          </div>
          {this.getContent()}
        </div>
      </div>
    )
  }
}

export default injectIntl(ContainerInformations)
