import React from 'react'
import { withRouter } from 'react-router'
import { injectIntl } from 'react-intl'
import ReactToPrint from 'react-to-print'
import CertificateToPrint from '../../../Vaccination/Validation/Certificate/CertificateToPrint'
import SharedPreps from '../../../Vaccination/Validation/GroupValidation/SharedPreps'

const Certificate = props => {
  const { hist, intl } = props
  const { action: certificate } = hist.history
  const { sharedPreps } = certificate
  let componentToPrint = ''

  return (
    <div id="certificate">
      <SharedPreps
        sharedPreps={sharedPreps}
        orderNumber={certificate.females.orderNumber || certificate.males.orderNumber}
      />
      <div style={{ display: 'none' }}>
        <CertificateToPrint
          certificate={certificate}
          toPrint
          ref={el => {
            componentToPrint = el
          }}
        />
      </div>
      <div>
        <CertificateToPrint certificate={certificate} id={hist.id} />
      </div>
      <br />
      <div className="btns">
        <ReactToPrint
          trigger={() => (
            <button className="btn transparentBtn" type="button">
              {intl.formatMessage({ id: 'app.button.printCertificate' })}
            </button>
          )}
          content={() => componentToPrint}
          closeAfterPrint={false}
        />
      </div>
    </div>
  )
}

export default withRouter(injectIntl(Certificate))
