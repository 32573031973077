import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { catchError } from '../../../../actions'
import { api } from '../../../../parameters'
import { validatedAction } from '../../../globalAction'

class ModalAddVaccineApplicator extends React.Component {
  constructor(props) {
    super(props)
    this.state = { name: null }
    this.handleSubmitModal = this.handleSubmitModal.bind(this)
    this.handlechange = this.handlechange.bind(this)
  }

  handlechange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleSubmitModal() {
    const { machine, token, type } = this.props
    const { name } = this.state

    const formData = {
      name,
      type,
      machine
    }

    Axios.post(`${api}/api/vaccine_applicators`, formData, { headers: { Authorization: token } })
      .then(() => validatedAction('', 0))
      .catch(err => catchError(err.response))
  }

  render() {
    const { intl, type } = this.props
    return (
      <div>
        <div id="modalAddVaccineApplicator" className="modal">
          <div className="modal-content">
            <div className="row">
              <div className="col s12">
                <h2 className="swal2-title swalTitle">
                  {intl.formatMessage({ id: `app.label.addAVaccineApplicator.${type}` })}
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <form>
                  <div className="form noCard">
                    <div className="row">
                      <div className="col s12 input">
                        <label htmlFor="articleName" className="labelParams">
                          {intl.formatMessage({ id: 'app.label.name' })}
                          <input name="name" type="text" onChange={this.handlechange} required />
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col s12 btnSwalAddArticle">
                        <button
                          className="modal-close btn blueBtn"
                          type="button"
                          onClick={this.handleSubmitModal}
                        >
                          {intl.formatMessage({ id: 'app.button.validate' })}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(ModalAddVaccineApplicator))
