import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { catchError } from '../../../../actions'
import LastAlert from '../../../../components/Alerts/LastAlert'
import { renderNitrogenLevel } from '../../../../components/globalRenderActions'
import LoadingPage from '../../../../components/LoadingPage'
import StatePill from '../../../../components/StatePill'
import Td from '../../../../components/Table/Td'
import { api } from '../../../../parameters'
import ValenceContext from '../../../../context/ValencesContext'

class ContainerItem extends React.Component {
  constructor() {
    super()
    this.state = {
      container: {
        lastControl: '',
        vialBatches: []
      },
      loading: true
    }
    moment.locale('fr')
  }

  componentDidMount() {
    const { match, token } = this.props
    Axios.get(`${api}/api/nitrogen_containers/${match.params.id}`, {
      headers: {
        Authorization: token
      }
    })
      .then(res => {
        const vaccines = res.data.nitrogenContainerVaccinesBatches
        let noVacc = false

        vaccines.forEach((vacc, i) => {
          if (vacc.ampoulesNumber === 0) {
            vaccines.splice(i, 1)
            noVacc = true
          }
        })

        this.setState({ container: res.data, loading: false, vaccines, noVacc })
      })
      .catch(err => catchError(err.response))
  }

  static contextType = ValenceContext

  renderEmptyVacc() {
    const { noVacc } = this.state
    const { intl } = this.props

    return noVacc === false ? (
      <div className="card-vaccines">
        {intl.formatMessage({ id: 'app.message.noVaccinesInContainer' })}
      </div>
    ) : (
      <div className="card-vaccines">
        {intl.formatMessage({ id: 'app.message.containerVaccinesInUse' })}
      </div>
    )
  }

  render() {
    const { container, loading, vaccines } = this.state
    const { history, intl } = this.props
    const { getValenceLabelByKey } = this.context

    if (loading) return <LoadingPage />

    return (
      <div className="row Container containersManagement">
        <div className="col s12 content">
          <div className="row pageHeader pageTitle">
            <div
              onClick={() => history.goBack()}
              role="button"
              tabIndex="0"
              onKeyPress={() => history.goBack()}
              className="goBackBtn col m1"
            >
              <i className="material-icons">chevron_left</i>
            </div>
            <h5 className="col m11">
              {intl.formatMessage({ id: `app.label.container` })} {container.containerNumber}
            </h5>
          </div>
          <div className="row cardContainer">
            <div className="col s12 card">
              <div className="row equipmentDescription">
                <div className="col m6 bold">
                  <p>{intl.formatMessage({ id: `app.container.${container.type}` })}</p>
                </div>
                <div className="col m6">
                  <p>
                    <span className="bold">{intl.formatMessage({ id: `app.label.provider` })}</span>
                    {` ${container.provider}`}
                  </p>
                </div>
                <div className="col m6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({ id: `app.label.nitrogenLevel` })}
                    </span>
                    &nbsp;{renderNitrogenLevel(container.lastControl.nitrogenLevel, intl)}&nbsp;
                    <StatePill type="ContainerNitrogenLevel" data={container} />
                  </p>
                </div>
                <div className="col m6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({ id: `app.label.receiptDate` })}
                    </span>
                    {` ${moment(container.receptionDate).format('DD/MM/YYYY')}`}
                  </p>
                </div>
                {container.lastControl.nitrogenLevel === null ? (
                  <div className="col m6">
                    <p>
                      <span className="bold">
                        {intl.formatMessage({ id: `app.label.lastControlDate` })}
                      </span>
                      &nbsp;{renderNitrogenLevel(container.lastControl.nitrogenLevel, intl)}&nbsp;
                      <StatePill type="ContainerNitrogenLevel" data={container} />
                    </p>
                  </div>
                ) : (
                  <div className="col m6">
                    <p>
                      <span className="bold">
                        {intl.formatMessage({ id: `app.label.lastControlDate` })}
                      </span>
                      {` ${moment(container.lastControl.createdAt).format('DD/MM/YYYY')} `}
                      <StatePill type="ContainerDate" data={container} />
                    </p>
                  </div>
                )}

                <div className="col m6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({ id: `app.label.receiptHour` })}
                    </span>
                    {` ${moment(container.receptionDate).format('HH:mm')}`}
                  </p>
                </div>
              </div>
              <div className="divider greyDivider" />
              <LastAlert equipmentId={container.containerNumber} cat={[1, 12]} type="int" />
            </div>
          </div>
          <div className="row">
            <p className="associatedVaccines">
              <FormattedMessage id="app.equipment.associatedVaccinesBatches" />
            </p>

            {vaccines.length !== 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>{intl.formatMessage({ id: `app.label.batchesNumber` })}</th>
                    <th>{intl.formatMessage({ id: `app.label.speciality` })}</th>
                    <th>{intl.formatMessage({ id: `app.label.article` })}</th>
                    <th>{intl.formatMessage({ id: `app.label.valence` })}</th>
                    <th>{intl.formatMessage({ id: `app.label.administration` })}</th>
                    <th>{intl.formatMessage({ id: `app.label.bulbsNumber` })}</th>
                    <th>{intl.formatMessage({ id: `app.label.dosesNumber` })}</th>
                    <th>{intl.formatMessage({ id: `app.label.expirationDate` })}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {vaccines.map(row => (
                    <tr key={row.id}>
                      <Td to={`/stocks/stock-availibility/injected-vaccine/frozen/${row.id}`}>
                        {row.vaccinesBatch.batchNumber}
                      </Td>
                      <Td to={`/stocks/stock-availibility/injected-vaccine/frozen/${row.id}`}>
                        {row.vaccinesBatch.vaccineArticle.speciality.name}
                      </Td>
                      <Td to={`/stocks/stock-availibility/injected-vaccine/frozen/${row.id}`}>
                        {row.vaccinesBatch.vaccineArticle.name}
                      </Td>
                      <Td to={`/stocks/stock-availibility/injected-vaccine/frozen/${row.id}`}>
                        {getValenceLabelByKey(row.vaccinesBatch.vaccineArticle.speciality.valence)}
                      </Td>
                      <Td to={`/stocks/stock-availibility/injected-vaccine/frozen/${row.id}`}>
                        {intl.formatMessage({
                          id: `app.administrationMethod.${row.vaccinesBatch.vaccineArticle.speciality.administrationMethod}`
                        })}
                      </Td>
                      <Td to={`/stocks/stock-availibility/injected-vaccine/frozen/${row.id}`}>
                        {row.ampoulesNumber}
                      </Td>
                      <Td to={`/stocks/stock-availibility/injected-vaccine/frozen/${row.id}`}>
                        {row.ampoulesNumber * row.vaccinesBatch.vaccineArticle.dosesNumber}
                      </Td>
                      <Td to={`/stocks/stock-availibility/injected-vaccine/frozen/${row.id}`}>
                        <div className="flex">
                          <StatePill type="ExpirationDate" data={row.vaccinesBatch} />
                          {moment(row.vaccinesBatch.expirationDate).format('DD/MM/YYYY')}
                        </div>
                      </Td>
                      <Td to={`/stocks/stock-availibility/injected-vaccine/frozen/${row.id}`}>
                        <i className="material-icons arrow-right">keyboard_arrow_right</i>
                      </Td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              this.renderEmptyVacc()
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    notifs: state.mercure.notifications
  }
}

export default injectIntl(connect(mapStateToProps)(ContainerItem))
