import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { injectIntl } from 'react-intl'
import ReactToPrint from 'react-to-print'
import Axios from 'axios'
import Swal from 'sweetalert2'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import CertificateToPrint from '../../../components/Vaccination/Validation/Certificate/CertificateToPrint'

class Certificate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      certificate: {},
      loading: true
    }

    this.handleValidate = this.handleValidate.bind(this)
  }

  componentDidMount() {
    const { match, token } = this.props
    const { id } = match.params

    Axios.get(`${api}/api/certificates/${id}`, {
      headers: { Authorization: token }
    })
      .then(res => this.setState({ certificate: res.data, loading: false }))
      .catch(err => catchError(err.response))
  }

  handleValidate() {
    const { history, intl } = this.props

    return Swal.fire({
      type: 'info',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({ id: 'app.label.yes' }),
      cancelButtonText: intl.formatMessage({ id: 'app.label.no' }),
      text: intl.formatMessage({ id: 'app.message.printedCertificateConfirmation' }),
      customClass: {
        icon: 'swalInfoIcon',
        title: 'swalTitle',
        content: 'swalContentText',
        confirmButton: 'blueBtn',
        cancelButton: 'redBtn'
      }
    })
      .then(() => {
        history.push('/vaccination/validation')
      })
      .catch(err => catchError(err))
  }

  render() {
    const { intl, match } = this.props
    const { certificate, loading } = this.state
    const { id } = match.params
    let componentToPrint = ''

    if (loading === true) {
      return <LoadingPage />
    }

    return (
      <div className="row Container VaccinationContainer" id="certificate">
        <br />
        <div className="btns">
          <ReactToPrint
            trigger={() => (
              <button className="btn blueBtn" type="button">
                {intl.formatMessage({ id: 'app.button.printCertificate' })}
              </button>
            )}
            content={() => componentToPrint}
            closeAfterPrint={false}
            onAfterPrint={this.handleValidate}
          />
          <button className="btn blueBtn" type="button" onClick={this.handleValidate}>
            {intl.formatMessage({ id: 'app.button.validate' })}
          </button>
        </div>
        <br />
        <div style={{ display: 'none' }}>
          <CertificateToPrint
            certificate={certificate}
            ref={el => {
              componentToPrint = el
            }}
          />
        </div>
        <div>
          <CertificateToPrint certificate={certificate} id={id} />
        </div>
      </div>
    )
  }
}

const mapStatetoProps = state => {
  return {
    token: state.auth.token
  }
}

export default withRouter(injectIntl(connect(mapStatetoProps)(Certificate)))
