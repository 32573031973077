import React from 'react'
import { injectIntl } from 'react-intl'
import Axios from 'axios'
import Swal from 'sweetalert2'
import { closePage, validatedAction } from '../../../components/globalAction'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'

class FridgeCreate extends React.Component {
  constructor() {
    super()
    this.state = {
      desinfected: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(e) {
    const { name, checked, type } = e.target
    let { value } = e.target
    if (type === 'checkbox') {
      value = checked
    } else if (type === 'number') {
      value = parseInt(value)
    }
    this.setState({ [name]: value })
  }

  handleSubmit(e) {
    e.preventDefault()
    const { token, intl } = this.props
    const { desinfected } = this.state

    if (desinfected) {
      return Axios.post(`${api}/api/fridges`, this.state, { headers: { Authorization: token } })
        .then(() => validatedAction(intl.formatMessage({ id: 'app.alert.newFridge' })))
        .catch(err => catchError(err.response))
    }

    return Swal.fire({
      type: 'error',
      title: intl.formatMessage({ id: 'app.alert.error' }),
      text: intl.formatMessage({ id: 'app.alert.notDesinfected' }),
      customClass: {
        icon: 'swalErrorIcon',
        title: 'swalTitle',
        content: 'swalContentText',
        confirmButton: 'blueBtn'
      }
    })
  }

  render() {
    const { intl } = this.props
    return (
      <form className="row Container fridgesManagement" autoComplete="off">
        <div className="col s12">
          <div className="pageHeader pageTitle row">
            <h5 className="col m11"> {intl.formatMessage({ id: 'app.label.addFridge' })}</h5>
            <div
              className="right goBackBtn col m1"
              role="button"
              tabIndex="0"
              onKeyPress={() => closePage(intl)}
              onClick={() => closePage(intl)}
            >
              <i className="material-icons">close</i>
            </div>
          </div>
          <div className="form">
            <div className="row">
              <div className="col m6 input">
                <label>{intl.formatMessage({ id: 'app.label.brand' })}</label>
                <input type="text" name="brand" onChange={this.handleChange} required />
              </div>
              <div className="col m6 input">
                <label>{intl.formatMessage({ id: 'app.label.name' })}</label>
                <input type="text" name="name" onChange={this.handleChange} required />
              </div>
            </div>
            <div className="row">
              <div className="col m6 input">
                <label>{intl.formatMessage({ id: 'app.label.volume' })}</label>
                <input type="number" name="volume" onChange={this.handleChange} required />
              </div>
              <div className="col m6">
                <p className="switchLabel">
                  {intl.formatMessage({ id: 'app.label.desinfectedFridge' })}
                </p>
                <div className="switch">
                  <label>
                    {intl.formatMessage({ id: 'app.label.no' })}
                    <input type="checkbox" name="desinfected" onChange={this.handleChange} />
                    <span className="lever" />
                    {intl.formatMessage({ id: 'app.label.yes' })}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col offset-s5 s2">
          <input
            type="submit"
            className="btn blueBtn validationBtn"
            onClick={this.handleSubmit}
            value="Valider"
          />
        </div>
      </form>
    )
  }
}

export default injectIntl(FridgeCreate)
