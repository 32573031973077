import React, { createContext, useState, useEffect } from 'react'
import fetchItems from './fetchItems'

const ValencesContext = createContext({
  valences: [],
  getValenceLabelByKey: () => '',
  fetchValences: () => {}
})

export const ValencesProvider = ({ children }) => {
  const [valences, setValences] = useState([])

  const fetchValences = () => {
    fetchItems('valences', setValences)
  }

  useEffect(() => {
    fetchValences()
  }, [])

  const getValenceLabelByKey = key => {
    const findResult = valences.find(valence => valence.value === key)
    return findResult ? findResult.name : ''
  }

  return (
    <ValencesContext.Provider
      value={{
        valences,
        getValenceLabelByKey,
        fetchValences
      }}
    >
      {children}
    </ValencesContext.Provider>
  )
}

export default ValencesContext
