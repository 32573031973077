import jwtDecode from 'jwt-decode'
import React from 'react'
import ReactDOM from 'react-dom'
import { addLocaleData, IntlProvider } from 'react-intl'
import localeEn from 'react-intl/locale-data/en'
import localeFr from 'react-intl/locale-data/fr'
import { Provider } from 'react-redux'
import { AUTH_USER } from './actions/types'
import App from './App'
import {
  ShapsCodesProvider,
  GenerationsProvider,
  ProductsForOrderProvider,
  PrestationsProvider,
  ValencesProvider,
  HatcheryProvider,
  PlanningProvider,
  AlertsProvider
} from './context/providers'
import Router from './router'
import * as serviceWorker from './serviceWorker'
import store from './store'
import messagesEn from './translations/en.json'
import messagesFr from './translations/fr.json'

const messages = {
  fr: messagesFr,
  en: messagesEn
}
const language = 'fr'

addLocaleData([...localeEn, ...localeFr])

const token = localStorage.getItem('token')
if (token) {
  const userInfo = jwtDecode(token)
  store.dispatch({
    type: AUTH_USER,
    token,
    role: userInfo.roles,
    username: userInfo.username,
    email: userInfo.email
  })
}

// TODO à décommenter
// window.history.pushState(null, null, document.URL)
// window.addEventListener('popstate', () => {
//   window.history.pushState(null, null, document.URL)
// })

ReactDOM.render(
  <ValencesProvider>
    <ShapsCodesProvider>
      <PrestationsProvider>
        <ProductsForOrderProvider>
          <GenerationsProvider>
            <HatcheryProvider>
              <PlanningProvider>
                <AlertsProvider>
                  <Provider store={store}>
                    <IntlProvider locale={language} messages={messages[language]}>
                      <Router>
                        <App />
                      </Router>
                    </IntlProvider>
                  </Provider>
                </AlertsProvider>
              </PlanningProvider>
            </HatcheryProvider>
          </GenerationsProvider>
        </ProductsForOrderProvider>
      </PrestationsProvider>
    </ShapsCodesProvider>
  </ValencesProvider>,
  document.getElementById('root')
)

// TODO à décommenter
// document.onkeydown = e => {
//   disableBrowserActions(e)
// }

serviceWorker.unregister()
