import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ReactToPrint from 'react-to-print'
import Swal from 'sweetalert2'
import { catchError } from '../../../../../../actions'
import { api } from '../../../../../../parameters'
import LoadingPage from '../../../../../LoadingPage'
import TagToPrint from './partials/TagToPrint'

class ContainerVaccineTag extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: true
    }

    this.handleValidate = this.handleValidate.bind(this)
  }

  componentDidMount() {
    const { prepId, token } = this.props

    Axios.get(`${api}/api/order_order_preparations/${prepId}`, {
      headers: { Authorization: token }
    })
      .then(res => this.setState({ loading: false, preparation: res.data }))
      .catch(err => catchError(err.response))
  }

  handleValidate() {
    const { water, changePrep, intl, prepId, token, sequence } = this.props

    let waterToSend = null
    if (water) {
      waterToSend = water
    }

    return Swal.fire({
      type: 'info',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({ id: 'app.label.yes' }),
      cancelButtonText: intl.formatMessage({ id: 'app.label.no' }),
      text: intl.formatMessage({ id: 'app.message.printedTagConfirmation' }),
      customClass: {
        icon: 'swalInfoIcon',
        title: 'swalTitle',
        content: 'swalContentText',
        confirmButton: 'blueBtn',
        cancelButton: 'redBtn'
      }
    }).then(res => {
      if (res.value === true) {
        Axios.patch(
          `${api}/api/vaccination/realisation/preparation/${prepId}`,
          { water: waterToSend, sequence },
          {
            headers: { Authorization: token }
          }
        )
          .then(() => {
            changePrep()
          })
          .catch(err => catchError(err))
      }
    })
  }

  render() {
    const { intl, vaccineType, sequence, order } = this.props
    const { loading, preparation } = this.state

    if (loading === true) {
      return <LoadingPage />
    }

    let componentToPrint = ''
    let label = 'printAndPasteOnProductBag'

    if (vaccineType !== undefined) {
      label = 'printAndPasteOnCan'
    }
    const orders = preparation.preparation.orderOrderPreparations
    const mappedOrders = {}
    mappedOrders['0'] = {
      planningOrder: order
    }
    orders
      .filter(filteredOrder => filteredOrder instanceof Object)
      .forEach((validOrder, index) => {
        mappedOrders[(index + 1).toString()] = {
          planningOrder: validOrder.planningOrder
        }
      })
    return (
      <>
        <br />
        <div className="row tagBtns">
          <div className="col s12 flex flex-center">
            <ReactToPrint
              trigger={() => (
                <button className="btn blueBtn" type="button">
                  {intl.formatMessage({ id: 'app.button.printTag' })}
                </button>
              )}
              content={() => componentToPrint}
              onAfterPrint={this.handleValidate}
            />
            <button className="btn blueBtn" type="button" onClick={this.handleValidate}>
              {intl.formatMessage({ id: 'app.button.validate' })}
            </button>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col s12">
            <div className="flex flexSpaceBetween">
              <div className="labelCustom bold em1-2">
                {intl.formatMessage({ id: 'app.label.tagToPrint' })}
              </div>
              <div className="greyText">{intl.formatMessage({ id: `app.label.${label}` })}</div>
            </div>
          </div>
        </div>
        <div className="flex flex-center">
          <div id="tagContainer" className="toPrint" style={{ display: 'none' }}>
            <TagToPrint
              action={{
                preparation: preparation.preparation,
                orders: mappedOrders
              }}
              toPrint
              ref={el => {
                componentToPrint = el
              }}
              sequence={sequence}
            />
          </div>
          <div id="tagContainer">
            <TagToPrint
              action={{
                preparation: preparation.preparation,
                orders: mappedOrders
              }}
              sequence={sequence}
            />
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(ContainerVaccineTag)))
