import Axios from 'axios'
import { api } from '../parameters'
import store from '../store'

const fetchItems = (endpoint, setter) => {
  const { token } = store.getState().auth
  if (token) {
    Axios.get(`${api}/api/${endpoint}?items=200`, {
      headers: { Authorization: token }
    }).then(res => {
      setter(res.data['hydra:member'])
    })
  }
}

export default fetchItems
