import React from 'react'
import { injectIntl } from 'react-intl'

const NoBlockEquipmentHistoryPart = props => {
  const {
    intl,
    paramId,
    noBlockPartAlertAfter,
    noBlockPartAlertBefore,
    noBlockPartAlertMaxAfter,
    noBlockPartAlertMaxBefore,
    noBlockPartAlertMinAfter,
    noBlockPartAlertMinBefore
  } = props
  switch (paramId) {
    case 0:
    case 6:
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.before' })}
                </label>
              </div>
            </div>
            <div className="row input">
              <div className="col s12">
                <label htmlFor="noBlockPartAlert" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;:&nbsp;
                </label>
                <span>{noBlockPartAlertBefore}</span>
              </div>
            </div>
          </div>
          <div className="col s6">
            <div className="row input">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.after' })}
                </label>
              </div>
            </div>
            <div className="row input">
              <div className="col s12">
                <label htmlFor="noBlockPartAlert" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;:&nbsp;
                </label>
                <span>{noBlockPartAlertAfter}</span>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    case 7:
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.before' })}
                </label>
              </div>
            </div>
            <div className="row input">
              <div className="col s12">
                <label htmlFor="noBlockPartAlert" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;:&nbsp;
                </label>
                <span>{noBlockPartAlertBefore}</span>
              </div>
            </div>
          </div>
          <div className="col s6">
            <div className="row input">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.after' })}
                </label>
              </div>
            </div>
            <div className="row input">
              <div className="col s12">
                <label htmlFor="noBlockPartAlert" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;:&nbsp;
                </label>
                <span>{noBlockPartAlertAfter}</span>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    case 8:
    case 9:
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.before' })}
                </label>
              </div>
            </div>
            <div className="row input">
              <div className="col s12">
                <label htmlFor="noBlockPartAlert" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;:&nbsp;
                </label>
                <span>{noBlockPartAlertBefore}</span>
              </div>
            </div>
          </div>
          <div className="col s6">
            <div className="row input">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.after' })}
                </label>
              </div>
            </div>
            <div className="row input">
              <div className="col s12">
                <label htmlFor="noBlockPartAlert" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;:&nbsp;
                </label>
                <span>{noBlockPartAlertAfter}</span>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    default:
      return (
        <div className="row">
          <div className="col s6">
            <div className="row input">
              <div className="row">
                <div className="col s12">
                  <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.before' })}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <label htmlFor="noBlockPartAlertMinBefore" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.alertNitrogenLow' })}
                    &nbsp;:
                  </label>
                  <span>{noBlockPartAlertMinBefore}</span>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <label htmlFor="noBlockPartAlertMaxBefore" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.alertNitrogenHigh' })}
                    &nbsp;:&nbsp;
                  </label>
                  <span>{noBlockPartAlertMaxBefore}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col s6">
            <div className="row input">
              <div className="row">
                <div className="col s12">
                  <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.after' })}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <label htmlFor="noBlockPartAlertMinBefore" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.alertNitrogenLow' })}
                    &nbsp;:
                  </label>
                  <span>{noBlockPartAlertMinAfter}</span>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <label htmlFor="noBlockPartAlertMaxBefore" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.alertNitrogenHigh' })}
                    &nbsp;:&nbsp;
                  </label>
                  <span>{noBlockPartAlertMaxAfter}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
  }
}

export default injectIntl(NoBlockEquipmentHistoryPart)
