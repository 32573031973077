import React from 'react'
import { injectIntl } from 'react-intl'

const GeneralInfos = props => {
  const { group, intl } = props

  return (
    <div className="generalInfos row custom-card">
      <div className="col s6 number">
        <span className="label">{intl.formatMessage({ id: 'app.label.orderNumero' })}</span>{' '}
        {group.number}
      </div>
      <div className="offset-s4 col s2">
        <span className="label">{intl.formatMessage({ id: 'app.label.clientfr319' })}</span>{' '}
        {group.clientName}
      </div>
    </div>
  )
}

export default injectIntl(GeneralInfos)
