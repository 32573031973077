import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { catchError } from '../../../actions'
import CenterButton from '../../../components/CenterButton'
import DiluentForm from '../../../components/EquipmentManagement/FridgeManagement/ProductsTransfer/diluentForm'
import DyeForm from '../../../components/EquipmentManagement/FridgeManagement/ProductsTransfer/dyeForm'
import VaccineForm from '../../../components/EquipmentManagement/FridgeManagement/ProductsTransfer/vaccineForm'
import { BackToPrevious, closePage, validatedAction } from '../../../components/globalAction'
import { api } from '../../../parameters'

class FridgeTransfer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fridges: [],
      donorFridge: false,
      allProductsSelected: false,
      vaccineSelected: false,
      diluentSelected: false,
      dyeSelected: false,
      disabled: true,
      vaccines: [{}],
      diluents: [{}],
      dyes: [{}],
      diluentDisabled: true,
      vaccineDisabled: true,
      dyeDisabled: true,
      disabledDiluentsForm: [],
      disabledVaccinesForm: [],
      disabledDyesForm: [],
      isValidate: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.selectProducts = this.selectProducts.bind(this)
    this.selectFridgeDonor = this.selectFridgeDonor.bind(this)
    this.selectFridgeTaker = this.selectFridgeTaker.bind(this)
    this.itemsToMove = this.itemsToMove.bind(this)
    this.addBatch = this.addBatch.bind(this)
    this.setDiluentDisabled = this.setDiluentDisabled.bind(this)
    this.setVaccineDisabled = this.setVaccineDisabled.bind(this)
    this.setDyeDisabled = this.setDyeDisabled.bind(this)
    this.setDisabled = this.setDisabled.bind(this)
    this.validate = this.validate.bind(this)
  }

  componentDidMount() {
    const { token } = this.props
    Axios.get(`${api}/api/fridges?active=true`, { headers: { Authorization: token } })
      .then(res => this.setState({ fridges: res.data['hydra:member'] }, () => M.AutoInit()))
      .catch(err => catchError(err.response))
  }

  setDiluentDisabled(diluentDisabled) {
    this.setState({ diluentDisabled })
  }

  setVaccineDisabled(vaccineDisabled) {
    this.setState({ vaccineDisabled })
  }

  setDyeDisabled(dyeDisabled) {
    this.setState({ dyeDisabled })
  }

  setDisabled(disabled) {
    this.setState({ disabled })
  }

  selectProducts(e) {
    const { name, checked } = e.target
    if (name === 'allProductsSelected') {
      return this.setState({
        diluentSelected: false,
        vaccineSelected: false,
        dyeSelected: false,
        [name]: checked,
        disabled: !checked
      })
    }
    return this.setState({ [name]: checked, allProductsSelected: false })
  }

  selectFridgeDonor(e) {
    const { name, value } = e.target
    const { fridges } = this.state
    fridges.forEach(fridge => {
      if (fridge.id === parseInt(value)) {
        return this.setState({
          [name]: fridge,
          vaccineSelected: false,
          diluentSelected: false,
          dyeSelected: false,
          allProductsSelected: false
        })
      }
      return true
    })
  }

  selectFridgeTaker(e) {
    const { name, value } = e.target
    const { fridges } = this.state
    fridges.forEach(fridge => {
      if (fridge.id === parseInt(value)) {
        return this.setState({
          [name]: fridge
        })
      }
      return true
    })
  }

  validate() {
    const { disabledDiluentsForm, disabledVaccinesForm, disabledDyesForm } = this.state
    this.setState(
      { isValidate: true, diluentDisabled: true, dyeDisabled: true, vaccineDisabled: true },
      () => M.AutoInit()
    )
    disabledDiluentsForm.push(true)
    disabledVaccinesForm.push(true)
    disabledDyesForm.push(true)
  }

  addBatch(type) {
    const {
      vaccines,
      diluents,
      dyes,
      disabledDiluentsForm,
      disabledVaccinesForm,
      disabledDyesForm
    } = this.state
    const disabledDiluentsFormTmp = disabledDiluentsForm
    const disabledVaccinesFormTmp = disabledVaccinesForm
    const disabledDyesFormTmp = disabledDyesForm
    switch (type) {
      case 'vaccine':
        this.setState({ vaccineDisabled: true, disabled: true })
        disabledVaccinesFormTmp.push(true)
        vaccines.push({})
        return this.setState({
          vaccines,
          disabledVaccinesForm
        })
      case 'diluent':
        this.setState({ diluentDisabled: true, disabled: true })
        disabledDiluentsFormTmp.push(true)
        diluents.push({})
        return this.setState({
          diluents,
          disabledDiluentsForm: disabledDiluentsFormTmp
        })
      case 'dye':
        this.setState({ dyeDisabled: true, disabled: true })
        disabledDyesFormTmp.push(true)
        dyes.push({})
        return this.setState({ dyes })
      default:
        return true
    }
  }

  itemsToMove(type, index, e, batchId) {
    const { value } = e.target
    const { vaccines, diluents, dyes } = this.state
    const id = parseInt(batchId)
    const vialsNumberToTransfer = parseInt(value)
    switch (type) {
      case 'vaccines':
        vaccines[index] = { id, vialsNumberToTransfer }
        return this.setState({ vaccines })
      case 'diluent':
        diluents[index] = { id, vialsNumberToTransfer }
        return this.setState({ diluents })
      case 'dye':
        dyes[index] = { id, vialsNumberToTransfer }
        return this.setState({ dyes })
      default:
        return false
    }
  }

  handleSubmit() {
    const { vaccines, diluents, dyes, donorFridge, fridgeTaker, allProductsSelected } = this.state
    const { token, history, intl } = this.props
    let data = {}

    if (allProductsSelected) {
      data = {
        giverId: donorFridge.id,
        catcherId: fridgeTaker.id
      }
      Axios.post(`${api}/api/fridges/transfer/all`, data, {
        headers: { Authorization: token }
      })
        .then(() =>
          validatedAction(intl.formatMessage({ id: 'app.alert.fridgeContentTransfered' }))
        )
        .catch(err => {
          catchError(err.response)
          history.goBack()
        })
    } else {
      if (Object.keys(vaccines[0]).length > 0) {
        data = {
          giverId: donorFridge.id,
          catcherId: fridgeTaker.id,
          fridgeVaccinesBatches: vaccines
        }
        Axios.post(`${api}/api/fridges/transfer/vaccines`, data, {
          headers: { Authorization: token }
        })
          .then(() => validatedAction(''))
          .catch(err => catchError(err.response))
      }

      if (Object.keys(diluents[0]).length > 0) {
        data = {
          giverId: donorFridge.id,
          catcherId: fridgeTaker.id,
          fridgeDiluentsBatches: diluents
        }
        Axios.post(`${api}/api/fridges/transfer/diluents`, data, {
          headers: { Authorization: token }
        })
          .then(() => validatedAction(''))
          .catch(err => catchError(err.response))
      }

      if (Object.keys(dyes[0]).length > 0) {
        data = {
          giverId: donorFridge.id,
          catcherId: fridgeTaker.id,
          fridgeDyesBatches: dyes
        }
        Axios.post(`${api}/api/fridges/transfer/dyes`, data, {
          headers: { Authorization: token }
        })
          .then(() => validatedAction(''))
          .catch(err => catchError(err.response))
      }
    }
  }

  render() {
    const {
      fridges,
      donorFridge,
      vaccineSelected,
      diluentSelected,
      dyeSelected,
      vaccines,
      diluents,
      dyes,
      isValidate,
      fridgeTaker,
      allProductsSelected,
      diluentDisabled,
      vaccineDisabled,
      dyeDisabled,
      disabledDiluentsForm,
      disabledVaccinesForm,
      disabledDyesForm,
      disabled
    } = this.state
    const { intl, token } = this.props

    return (
      <div className="row Container fridgesManagement fridgesTransfer">
        <div className="col m12">
          <div className="pageHeader pageTitle">
            <BackToPrevious text={intl.formatMessage({ id: 'app.part.transferFridge' })} />
            <div className="col m7">
              <div
                className="goBackBtn right"
                role="button"
                tabIndex="0"
                onKeyPress={() => closePage(intl)}
                onClick={() => closePage(intl)}
              >
                <i className="material-icons">close</i>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col s12 form">
              <div className="row">
                <div className="col m3 input">
                  <label htmlFor="donorFridge">
                    {intl.formatMessage({ id: 'app.label.fridgeDonor' })}
                  </label>
                  <select
                    className="select-wrapper"
                    defaultValue="0"
                    name="donorFridge"
                    id="donorFridge"
                    onChange={this.selectFridgeDonor}
                  >
                    <option value="0" />
                    {fridges.map(fridge => (
                      <option key={fridge.id} value={fridge.id}>
                        {fridge.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col m3 input">
                  <label className="active" htmlFor="Tmin">
                    {intl.formatMessage({ id: 'app.equipment.lastControl.minTemperature' })}
                  </label>
                  <input
                    disabled
                    value={donorFridge.lastControl ? donorFridge.lastControl.minTemperature : ''}
                    id="Tmin"
                    type="text"
                  />
                </div>
                <div className="col m3 input">
                  <label htmlFor="Tmax" className="active">
                    {intl.formatMessage({ id: 'app.equipment.lastControl.maxTemperature' })}
                  </label>
                  <input
                    disabled
                    value={donorFridge.lastControl ? donorFridge.lastControl.maxTemperature : ''}
                    id="Tmax"
                    type="text"
                  />
                </div>
                <div className="col m3 input">
                  <label className="active" htmlFor="Tinstant">
                    {intl.formatMessage({ id: 'app.equipment.lastControl.instantTemperature' })}
                  </label>
                  <input
                    disabled
                    value={
                      donorFridge.lastControl ? donorFridge.lastControl.instantTemperature : ''
                    }
                    id="Tinstant"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
          {Object.keys(donorFridge).length !== 0 && (
            <div className="row">
              <div className="col s12 form checkBoxesBox">
                <div className="col m3 input">
                  <label>
                    <input
                      type="checkbox"
                      className="filled-in checkbox"
                      name="allProductsSelected"
                      checked={allProductsSelected}
                      onClick={this.selectProducts}
                      readOnly
                    />
                    <span>{intl.formatMessage({ id: 'app.label.allProducts' })}</span>
                  </label>
                </div>
                <div className="col m3 input">
                  <label>
                    <input
                      type="checkbox"
                      className="filled-in checkbox"
                      name="vaccineSelected"
                      checked={vaccineSelected}
                      onClick={this.selectProducts}
                      readOnly
                    />
                    <span>{intl.formatMessage({ id: 'app.label.vaccin' })}</span>
                  </label>
                </div>
                <div className="col m3 input">
                  <label>
                    <input
                      type="checkbox"
                      className="filled-in checkbox"
                      name="dyeSelected"
                      checked={dyeSelected}
                      onClick={this.selectProducts}
                      readOnly
                    />
                    <span>{intl.formatMessage({ id: 'app.label.dye' })}</span>
                  </label>
                </div>
                <div className="col m3 input">
                  <label>
                    <input
                      type="checkbox"
                      className="filled-in checkbox"
                      name="diluentSelected"
                      checked={diluentSelected}
                      onClick={this.selectProducts}
                      readOnly
                    />
                    <span>{intl.formatMessage({ id: 'app.label.diluent' })}</span>
                  </label>
                </div>
              </div>
            </div>
          )}
          {vaccineSelected && (
            <div className="row">
              <div className="row">
                <div className="col m12 form">
                  {vaccines.map((vaccine, index) => (
                    <VaccineForm
                      key={index}
                      vaccines={vaccines}
                      vaccine={vaccine}
                      itemsToMove={this.itemsToMove}
                      index={index}
                      token={token}
                      donorId={donorFridge.id}
                      setDisabled={this.setDisabled}
                      setVaccineDisabled={this.setVaccineDisabled}
                      disabledVaccinesForm={disabledVaccinesForm}
                    />
                  ))}
                </div>
              </div>
              <div className="row">
                <button
                  type="button"
                  className="btn transparentBtn col offset-m5 m2"
                  onClick={() => this.addBatch('vaccine')}
                  disabled={vaccineDisabled}
                >
                  {intl.formatMessage({ id: 'app.label.addBatch' })}
                </button>
              </div>
            </div>
          )}
          {dyeSelected && (
            <div className="row">
              <div className="row">
                <div className="form">
                  {dyes.map((dye, index) => (
                    <DyeForm
                      key={index}
                      dye={dye}
                      dyes={dyes}
                      token={token}
                      index={index}
                      donorId={donorFridge.id}
                      itemsToMove={this.itemsToMove}
                      setDisabled={this.setDisabled}
                      setDyeDisabled={this.setDyeDisabled}
                      disabledDyesForm={disabledDyesForm}
                    />
                  ))}
                </div>
              </div>
              <div className="row">
                <button
                  type="button"
                  className="btn transparentBtn col offset-m5 m2"
                  onClick={() => this.addBatch('dye')}
                  disabled={dyeDisabled}
                >
                  {intl.formatMessage({ id: 'app.label.addBatch' })}
                </button>
              </div>
            </div>
          )}
          {diluentSelected && (
            <div className="row">
              <div className="row">
                <div className="col s12 form">
                  {diluents.map((diluent, index) => (
                    <DiluentForm
                      key={index}
                      diluent={diluent}
                      diluents={diluents}
                      index={index}
                      token={token}
                      donorId={donorFridge.id}
                      itemsToMove={this.itemsToMove}
                      setDisabled={this.setDisabled}
                      setDiluentDisabled={this.setDiluentDisabled}
                      disabledDiluentsForm={disabledDiluentsForm}
                    />
                  ))}
                </div>
              </div>
              <div className="row">
                <button
                  type="button"
                  className="btn transparentBtn col offset-m5 m2"
                  onClick={() => this.addBatch('diluent')}
                  disabled={diluentDisabled}
                >
                  {intl.formatMessage({ id: 'app.label.addBatch' })}
                </button>
              </div>
            </div>
          )}
          {isValidate && (
            <div className="row">
              <div className="col s12 form">
                <div className="row">
                  <div className="col m3 input">
                    <label htmlFor="fridgeTaker" className="active">
                      {intl.formatMessage({ id: 'app.label.fridgeTaker' })}
                    </label>
                    <select
                      className="select-wrapper"
                      defaultValue="select"
                      name="fridgeTaker"
                      onChange={this.selectFridgeTaker}
                    >
                      <option value="select" disabled>
                        {intl.formatMessage({ id: 'app.label.select' })}{' '}
                      </option>
                      {fridges.map(
                        fridge =>
                          fridge.id !== donorFridge.id && (
                            <option key={fridge.id} value={fridge.id}>
                              {fridge.name}
                            </option>
                          )
                      )}
                    </select>
                  </div>
                  <div className="input col m3">
                    <label htmlFor="tmin">
                      {intl.formatMessage({ id: 'app.equipment.lastControl.minTemperature' })}
                    </label>
                    <input
                      disabled
                      value={fridgeTaker ? fridgeTaker.lastControl.minTemperature : ''}
                      id="tmin"
                      type="text"
                    />
                  </div>
                  <div className="input col m3">
                    <label htmlFor="tmax">
                      {intl.formatMessage({ id: 'app.equipment.lastControl.maxTemperature' })}
                    </label>
                    <input
                      disabled
                      value={fridgeTaker ? fridgeTaker.lastControl.maxTemperature : ''}
                      id="tmax"
                      type="text"
                    />
                  </div>
                  <div className="input col m3">
                    <label htmlFor="tmin">
                      {intl.formatMessage({ id: 'app.equipment.lastControl.instantTemperature' })}
                    </label>
                    <input
                      disabled
                      value={fridgeTaker ? fridgeTaker.lastControl.instantTemperature : ''}
                      id="tinstant"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {donorFridge && (
            <CenterButton
              text={intl.formatMessage({ id: `app.button.validate` })}
              onClick={isValidate ? this.handleSubmit : this.validate}
              disabled={disabled}
            />
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(injectIntl(FridgeTransfer))
