import React from 'react'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import icons from '../../../../../../../../../assets/icons/icons'

const PreviousProductBagCard = props => {
  const { intl, previousPreparation } = props

  let preparationQrId
  let prepDate
  let prepDateAfter1h

  if (previousPreparation !== undefined) {
    preparationQrId = previousPreparation.preparation.qr
    prepDate = moment(previousPreparation.preparation.firstScannedAt).format('DD/MM/YYYY HH:mm')
    prepDateAfter1h = moment(previousPreparation.preparation.expirationDate).format(
      'DD/MM/YYYY HH:mm'
    )
  }

  return (
    <div className="row productBagCard">
      <div className="col s12">
        <div className="row">
          <div className="col s12">
            <div className="row flex">
              <span className="productBag-img">
                <img src={icons.productBag} alt="product bag logo" />
              </span>
              <span className="labelCustom">{preparationQrId}</span>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
        <div className="row">
          <div className="col s12">
            <span className="labelCustom bold">
              {intl.formatMessage({ id: 'app.label.dateAndHourRealisationPreparation' })}
            </span>
            &nbsp;{prepDate}
          </div>
          <div className="col s12">
            <span className="labelCustom bold">
              {intl.formatMessage({ id: 'app.label.endUse' })}
            </span>
            &nbsp;{prepDateAfter1h}
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(PreviousProductBagCard)
