/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react'
import Axios from 'axios'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import AsyncLoader from '../../../components/AsyncLoader'
import { SpanLink } from '../Components'
import { getOrderByCurrentNumber } from '../ReverseRedirections'
import { GenerationsContext, ProductsForOrderContext } from '../../../context/contexts'

function OrdersInvolved({ token, location, intl, history }) {
  const [page, setPage] = useState(1)
  const [state, setState] = useState({
    loading: true,
    items: null,
    contentStatus: false,
    totalItems: 0,
    batchNumber: null
  })
  const { getGenerationLabelByKey } = useContext(GenerationsContext)
  const { getProductForOrderLabelByKey } = useContext(ProductsForOrderContext)

  function loadMore() {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (state.totalItems > page * 30) {
        setPage(page + 1)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', loadMore)
  }, [])

  useEffect(() => {
    const pathName = location.pathname.split('/')
    const id = Number(pathName[pathName.length - 2])
    Axios.get(`${api}/api/batch_traceability/${id}/order?page=${page}`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const unknownValue = intl.formatMessage({ id: 'app.label.N/A' })
        const mappedData = [...res.data['hydra:member']].map(i => ({
          ...i,
          firstOccurrenceDate: i.firstOccurrenceDate
            ? moment(i.firstOccurrenceDate).format('DD/MM/YYYY')
            : unknownValue,
          currentNumber: i.currentNumber ?? unknownValue,
          clientName: i.clientName ?? unknownValue,
          hatchery: i.hatchery ?? unknownValue,
          chicks: i.chicks ?? unknownValue,
          generation: i.generation !== null ? getGenerationLabelByKey(i.generation) : unknownValue,

          products:
            i.products && Array.isArray(i.products)
              ? i.products.map(p => getProductForOrderLabelByKey(p)).join(', ')
              : unknownValue
        }))
        setState({
          ...state,
          loading: false,
          items: state.items ? state.items.concat(mappedData) : mappedData,
          totalItems: res.data['hydra:totalItems'],
          contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult',
          batchNumber: res.data.speciality ?? res.data.batchNumber
        })
      })
      .catch(err => catchError(err))
  }, [page])

  return (
    <div className="row Container stockAvailibilityItem">
      <div className="col s12 Content">
        <div className="row pageHeader pageTitle itemHeader">
          <div
            onClick={() => history.goBack()}
            role="button"
            tabIndex="0"
            onKeyPress={() => history.goBack()}
            className="goBackBtn col s1"
          >
            <i className="material-icons">chevron_left</i>
          </div>
          {state.batchNumber !== null && (
            <h5 className="col s12">
              {state.batchNumber} - {intl.formatMessage({ id: 'app.title.orders_involved' })}
            </h5>
          )}
        </div>
        {!state.loading ? (
          <div className="row">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.vaccine_or_product.orders_involved.order_number'
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.vaccine_or_product.orders_involved.hitching_date'
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.vaccine_or_product.orders_involved.customer'
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.vaccine_or_product.orders_involved.generation'
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.vaccine_or_product.orders_involved.strain'
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.vaccine_or_product.orders_involved.chick_quantity'
                    })}
                  </th>
                </tr>
              </thead>
              <tbody>
                {state.items.map(item => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <SpanLink
                          value={item.currentNumber}
                          setLoading={loading => {
                            setState({ ...state, loading })
                          }}
                          redirect={getOrderByCurrentNumber}
                          token
                          history
                        />
                      </td>
                      <td>{item.firstOccurrenceDate}</td>
                      <td>{item.clientName}</td>
                      <td>{item.generation}</td>
                      <td>{item.products}</td>
                      <td>{item.chicks}</td>
                    </tr>
                  )
                })}
              </tbody>
              <tfoot className="center">
                <tr>
                  <th colSpan="5">
                    <AsyncLoader status={state.contentStatus} />
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        ) : (
          <LoadingPage />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(OrdersInvolved)))
