import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { catchError } from '../../../actions'
import { validatedAction } from '../../../components/globalAction'
import DiluentReceptionPart from '../../../components/StockManagement/OrderReception/DiluentReception/DiluentInformations/DiluentReceptionPart'
import FridgeInformations from '../../../components/StockManagement/OrderReception/DyeReception/FridgeInformations/FridgeInformations'
import HeaderReception from '../../../components/StockManagement/OrderReception/partials/HeaderReception'
import OrderConformity from '../../../components/StockManagement/OrderReception/partials/OrderConformity'
import ProgressBarReception from '../../../components/StockManagement/OrderReception/partials/ProgressBarReception'
import { api } from '../../../parameters'
import globalHistory from '../../../history'

class DiluentReception extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      progress: 18,
      part: 1,
      fridges: [],
      formData: {
        diluents: [],
        history: {
          nameConformity: true,
          quantityConformity: true,
          batchNumberConformity: true,
          expirationDateConformity: true,
          nameNoConformityReason: null,
          quantityNoConformityReason: null,
          batchNumberNoConformityReason: null,
          expirationDateNoConformityReason: null,
          comment: ''
        }
      }
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.changePart = this.changePart.bind(this)
    this.getChildInformation = this.getChildInformation.bind(this)
    this.getContent = this.getContent.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
  }

  getChildInformation(name, value, index = false) {
    const { formData } = this.state

    if (name === 'fridges') return this.setState({ [name]: value })
    if (index) return this.setState({ formData: { ...formData, [name]: value } })

    return this.setState({ formData: { ...formData, [name]: value } })
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  getContent() {
    const { part, formData, fridges } = this.state

    switch (part) {
      case 1:
        return (
          <FridgeInformations
            changePart={this.changePart}
            setDataToParent={this.getChildInformation}
          />
        )
      case 2:
        return (
          <DiluentReceptionPart
            changePart={this.changePart}
            setDataToParent={this.getChildInformation}
            fridgeSelected={formData.fridgeSelected}
            fridges={fridges}
          />
        )
      case 3:
        return (
          <OrderConformity
            handleSubmit={this.handleSubmit}
            setDataToParent={this.getDataFromChild}
            getDataFromChild={this.getDataFromChild}
            diluentPart
            formData={formData}
          />
        )
      default:
        return <div />
    }
  }

  changePart() {
    const { part, progress } = this.state

    this.setState({ part: part + 1, progress: progress + 33 })
  }

  handleSubmit(e, stopped = false) {
    const {
      formData,
      dataSelected,
      controlHour,
      controlMinutes,
      nameConformity,
      quantityConformity,
      batchNumberConformity,
      expirationDateConformity,
      nameNoConformityReason,
      quantityNoConformityReason,
      batchNumberNoConformityReason,
      expirationDateNoConformityReason
    } = this.state
    const { token, intl } = this.props
    const { history, allFridgeSelected } = formData
    const diluents = []
    const fridgeId = allFridgeSelected[0].id

    allFridgeSelected.forEach(fridge => {
      const diluentsArray = fridge.diluents
      diluentsArray.forEach(diluent => {
        diluents.push(diluent)
      })
    })

    let receptionDate = moment().format()

    if (!stopped) {
      receptionDate = moment(dataSelected)
        .hours(controlHour)
        .minutes(controlMinutes)
        .format()
    }

    for (let i = 0; i < diluents.length; i++) {
      const diluent = diluents[i]
      diluent.receptionDate = receptionDate
    }

    history.nameConformity = nameConformity
    history.quantityConformity = quantityConformity
    history.batchNumberConformity = batchNumberConformity
    history.expirationDateConformity = expirationDateConformity
    history.nameNoConformityReason = nameNoConformityReason
    history.quantityNoConformityReason = quantityNoConformityReason
    history.batchNumberNoConformityReason = batchNumberNoConformityReason
    history.expirationDateNoConformityReason = expirationDateNoConformityReason
    history.receptionDate = receptionDate
    const diluentBatches = diluents
    const data = { diluentBatches, history, fridgeId, stopped }
    Axios.post(`${api}/api/diluent_batches`, data, { headers: { Authorization: token } })
      .then(() => {
        if (stopped === true) {
          Swal.fire({
            type: 'info',
            title: intl.formatMessage({ id: 'app.title.stoppedReception' }),
            text: intl.formatMessage({ id: 'app.message.stoppedReception' }),
            customClass: {
              icon: 'swalInfoIcon',
              title: 'swalTitle',
              content: 'swalContentText',
              confirmButton: 'blueBtn'
            }
          }).then(() => globalHistory.goBack())
        } else {
          validatedAction()
        }
      })
      .catch(err => {
        catchError(err.response)
      })
  }

  render() {
    const { intl } = this.props
    const { progress, part } = this.state

    return (
      <div className="Container row">
        <div className="col s12">
          <HeaderReception text={intl.formatMessage({ id: 'app.part.diluentReception' })} />
          <ProgressBarReception progress={progress} part={part} diluentPart />
        </div>
        <div className="row">
          <div className="col s12">{this.getContent()}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token
})

export default injectIntl(connect(mapStateToProps)(DiluentReception))
