import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Filters from '../../../Filters/Filters'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { history, inLoad, intl, token, role, url, filters, setStateToParent } = this.props

    return (
      <>
        <div className="row col s12 title">
          {intl.formatMessage({ id: 'app.title.vaccinationValidationPage' })}
          <div
            className="goBackBtn"
            role="button"
            tabIndex="0"
            onKeyPress={history.goBack}
            onClick={history.goBack}
          >
            <i className="material-icons">close</i>
          </div>
        </div>
        <div className="row">
          <div className="col s12 filters">
            <div>
              <Filters
                token={token}
                setStateToParent={setStateToParent}
                url={url}
                role={role}
                hydra={false}
                hasDate={false}
                noFilterParam
                noActive
                filters={filters}
                inLoad={inLoad}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStatetoProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default injectIntl(withRouter(connect(mapStatetoProps)(Header)))
