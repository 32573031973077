import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

class ModalMoreAction extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    M.Modal.init(document.getElementById('actionButton'))
  }

  render() {
    const { intl } = this.props
    return (
      <div>
        <div id="actionButton" className="modal" style={{ top: '50%' }}>
          <div className="modal-content">
            <div className="row center">
              <div className="col s12">
                <span>
                  <Link
                    to={{
                      pathname: '/planning/addOrder'
                    }}
                    onClick={() =>
                      M.Modal.getInstance(document.getElementById('actionButton')).close()
                    }
                  >
                    <h5 className="labelCustom">
                      {intl.formatMessage({ id: 'app.label.addNewOrder' })}
                    </h5>
                  </Link>
                </span>
              </div>
            </div>
            <div className="row center">
              <div className="col s12">
                <span>
                  <Link
                    to={{
                      pathname: '/planning/day-without-hatching'
                    }}
                    onClick={() =>
                      M.Modal.getInstance(document.getElementById('actionButton')).close()
                    }
                  >
                    <h5 className="labelCustom">
                      {intl.formatMessage({ id: 'app.label.dayWithoutHatching' })}
                    </h5>
                  </Link>
                </span>
              </div>
            </div>
            <div className="row center">
              <div className="col s12">
                <Link
                  to="/planning/import/shaps-csv"
                  onClick={() =>
                    M.Modal.getInstance(document.getElementById('actionButton')).close()
                  }
                >
                  <h5 className="labelCustom">
                    {intl.formatMessage({ id: 'app.label.importPlanning' })}
                  </h5>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(injectIntl(connect()(ModalMoreAction)))
