import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import roles from '../../config/userRoles.json'
import Filters from '../Filters/Filters'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.addDocument = this.addDocument.bind(this)
  }

  addDocument() {
    const { history } = this.props
    history.push('/documentation/import')
  }

  render() {
    const { filters, intl, role, token, getStateFromChild, url } = this.props
    const { ROLE_HATCHING_RESP, ROLE_HATCH_RESP, ROLE_VET } = roles

    return (
      <div className="pageHeader row">
        <div className="flex col m8 l9">
          <div>
            <Filters
              filters={filters}
              token={token}
              setStateToParent={getStateFromChild}
              inLoad={() => {}}
              hasDate={false}
              url={url}
              role={role}
              noActive
            />
          </div>
        </div>
        <div className="col m4 l3 flex">
          {(role.includes(ROLE_HATCHING_RESP) ||
            role.includes(ROLE_HATCH_RESP) ||
            role.includes(ROLE_VET)) && (
            <button type="button" className="btn blueBtn" onClick={this.addDocument}>
              {intl.formatMessage({ id: 'app.button.addDocument' })}
            </button>
          )}
        </div>
      </div>
    )
  }
}

export default injectIntl(withRouter(Header))
