import moment from 'moment'
import React, { useContext, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import {
  GenerationsContext,
  PrestationsContext,
  ProductsForOrderContext
} from '../../../../../../context/contexts'
import { i18n } from '../../../../../../parameters'

const OrderInfosBlock = ({ group, intl }) => {
  const { getGenerationLabelByKey } = useContext(GenerationsContext)
  const { getPrestationLabelByKey } = useContext(PrestationsContext)
  const { getProductForOrderLabelByKey } = useContext(ProductsForOrderContext)

  useEffect(() => {
    moment.updateLocale('fr', { weekdays: i18n.weekdays })
  }, [])

  const generateInfoLine = (label, data) => {
    return (
      <div className="col s6">
        <span className="bold">{intl.formatMessage({ id: `app.label.${label}` })}</span> {data}
      </div>
    )
  }

  const generateVaccinesLine = (type, vaccines, doses) => {
    return (
      <div className="col s12">
        <span className="bold">
          {intl.formatMessage({ id: `app.label.administrationMethod.${type}` })}
        </span>{' '}
        {vaccines.map((vaccine, i) => (
          <span key={vaccine.id}>
            {vaccine}{' '}
            {intl.formatMessage({
              id: `app.label.doses.${doses[i]}`
            })}{' '}
            {i === vaccines.length - 1 ? '' : '+ '}
          </span>
        ))}
      </div>
    )
  }

  return (
    <div className="row custom-card">
      <div className="card-header">
        <div className="col s6 em1-2">
          <span className="bold">
            {intl.formatMessage({ id: 'app.label.order' }).toUpperCase()} {group.number}
          </span>
        </div>
        {generateInfoLine('clientfr319', group.clientName)}
      </div>
      <div className="card-content">
        <div className="card-block row">
          {generateInfoLine('hatchingWeek', group.hatchingWeek)}
          {generateInfoLine('hatchery', group.hatchery)}
          {generateInfoLine('farmer', group.farmer)}
          {generateInfoLine('hatchingDay', moment(group.hatchingDate).format('dddd DD/MM'))}
          {generateInfoLine('generation', getGenerationLabelByKey(group.generation))}
          {generateInfoLine('product', getProductForOrderLabelByKey(group.product))}
          {generateInfoLine('chicksQuantity', group.totalChicks)}
          {generateInfoLine('gender', group.gender)}
        </div>
        <div className="divider greyDivider" />
        <div className="card-block row">
          {group.injectableVaccines.length !== 0 &&
            generateVaccinesLine(0, group.injectableVaccines, group.injectableVaccinesDosesType)}
          {group.thinDropsVaccines.length !== 0 &&
            generateVaccinesLine(1, group.thinDropsVaccines, group.thinDropsVaccinesDosesType)}
          {group.bigDropsVaccines.length !== 0 &&
            generateVaccinesLine(2, group.bigDropsVaccines, group.bigDropsVaccinesDosesType)}
          <div className="col s12">
            <span className="bold">{intl.formatMessage({ id: `app.label.interventions` })}</span>{' '}
            {group.prestations.map((prestation, i) => (
              <span key={prestation}>
                {getPrestationLabelByKey(prestation)}{' '}
                {i === group.prestations.length - 1 ? '' : '+ '}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(OrderInfosBlock)
