module.exports = {
  dosesLeftListBottle: [
    {
      doseNumber: 250
    },
    {
      doseNumber: 500
    },
    {
      doseNumber: 750
    },
    {
      doseNumber: 1000
    },
    {
      doseNumber: 2000
    },
    {
      doseNumber: 3000
    },
    {
      doseNumber: 4000
    },
    {
      doseNumber: 5000
    },
    {
      doseNumber: 6000
    },
    {
      doseNumber: 7000
    },
    {
      doseNumber: 8000
    },
    {
      doseNumber: 9000
    },
    {
      doseNumber: 10000
    }
  ]
}
