/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import problems from '../../../config/problems'
import { SpanLink } from '../Components'
import { getVaccineOrProductByBatchNumber, getEquipmentById } from '../ReverseRedirections'

function Comments({ token, location, intl, history }) {
  const [state, setState] = useState({
    loading: true,
    items: null,
    batchNumber: null
  })

  const setLoading = loading => {
    setState({
      ...state,
      loading
    })
  }

  useEffect(() => {
    const pathName = location.pathname.split('/')
    const id = Number(pathName[pathName.length - 2])
    Axios.get(`${api}/api/order_traceability/${id}/problem`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const mapper = i => {
          const unknownValue = intl.formatMessage({ id: 'app.label.N/A' })
          return {
            ...i,
            operator: i.operator ?? unknownValue,
            date: i.date ? moment(i.date).format('DD/MM/YYYY') : unknownValue,
            qrCode: i.qrCode ?? unknownValue,
            hour: i.date ? moment(i.date).format('HH:mm') : unknownValue,
            orderNumber: i.orderNumber ?? unknownValue,
            problem: Number.isInteger(i.problem)
              ? intl.formatMessage({
                  id: (
                    problems.find(problem => problem.value === i.problem) ||
                    problems.find(e => true)
                  ).label
                })
              : 'unknownValue',
            oldNumberBatch: i.oldNumberBatch ?? unknownValue,
            newNumberBatch: i.newNumberBatch ?? unknownValue,
            speciality: i.speciality ?? unknownValue,
            oldEquipmentId: i.oldEquipmentId ?? unknownValue,
            newEquipmentId: i.newEquipmentId ?? unknownValue
          }
        }
        const items = []
        items[0] = []
        items[1] = []
        if (res.data['hydra:member'].outre !== undefined) {
          Object.entries(res.data['hydra:member'].outre).forEach(i => {
            items[0].push(mapper({ ...i[1] }))
          })
        }
        if (res.data['hydra:member'].vaccine !== undefined) {
          Object.entries(res.data['hydra:member'].vaccine).forEach(i => {
            items[1].push(mapper({ ...i[1] }))
          })
        }
        setState({
          loading: false,
          items,
          batchNumber: res.data.currentNumber
        })
      })
      .catch(err => catchError(err))
  }, [])

  return (
    <div className="row Container stockAvailibilityItem">
      <div className="col s12 Content">
        <div className="row pageHeader pageTitle itemHeader">
          <div
            onClick={() => history.goBack()}
            role="button"
            tabIndex="0"
            onKeyPress={() => history.goBack()}
            className="goBackBtn col s1"
          >
            <i className="material-icons">chevron_left</i>
          </div>
          {state.batchNumber !== null && (
            <h5 className="col s12">
              {intl.formatMessage({ id: 'app.title.order' })} {state.batchNumber} -{' '}
              {intl.formatMessage({ id: 'app.title.comments' })}
            </h5>
          )}
        </div>
        {!state.loading ? (
          <div className="row" style={{ overflowX: 'auto' }}>
            {state.items.map((productType, key) => {
              return (
                productType.length > 0 && (
                  <>
                    <div>
                      {intl.formatMessage({
                        id: key === 0 ? 'app.label.order.comments.0' : 'app.label.order.comments.1'
                      })}
                    </div>
                    <table className="table" style={{ marginBottom: '16px' }}>
                      <thead>
                        <tr>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.comments.2'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.comments.3'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.comments.4'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.comments.5'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.comments.6'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.comments.7'
                            })}
                          </th>
                          {key === 1 && (
                            <th>
                              {intl.formatMessage({
                                id: 'app.label.order.comments.10'
                              })}
                            </th>
                          )}
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.comments.8'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.comments.9'
                            })}
                          </th>
                          {key === 1 && (
                            <>
                              <th>
                                {intl.formatMessage({
                                  id: 'app.label.order.comments.old_equipment'
                                })}
                              </th>
                              <th>
                                {intl.formatMessage({
                                  id: 'app.label.order.comments.new_equipment'
                                })}
                              </th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {productType.map(item => {
                          return (
                            <tr>
                              <td>{item.date}</td>
                              <td>{item.hour}</td>
                              <td>{item.operator}</td>
                              <td>{item.qrCode}</td>
                              <td>{item.orderNumber}</td>
                              <td>{item.problem}</td>
                              {key === 1 && <td>{item.speciality}</td>}
                              <td>
                                <SpanLink
                                  value={item.oldNumberBatch}
                                  setLoading={setLoading}
                                  redirect={getVaccineOrProductByBatchNumber}
                                  token
                                  history
                                />
                              </td>
                              <td>
                                <SpanLink
                                  value={item.newNumberBatch}
                                  setLoading={setLoading}
                                  redirect={getVaccineOrProductByBatchNumber}
                                  token
                                  history
                                />
                              </td>
                              {key === 1 && (
                                <>
                                  <td>
                                    <SpanLink
                                      value={item.oldEquipmentId}
                                      setLoading={setLoading}
                                      redirect={getEquipmentById}
                                      token
                                      history
                                    />
                                  </td>
                                  <td>
                                    <SpanLink
                                      value={item.newEquipmentId}
                                      setLoading={setLoading}
                                      redirect={getEquipmentById}
                                      token
                                      history
                                    />
                                  </td>
                                </>
                              )}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </>
                )
              )
            })}
          </div>
        ) : (
          <LoadingPage />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(Comments)))
