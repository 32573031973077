import React from 'react'
import M from 'materialize-css'
import { injectIntl } from 'react-intl'

const TextareaBlock = props => {
  const { handleChange, name, intl } = props
  M.CharacterCounter.init(document.getElementById('textarea'))

  return (
    <div className="col s12 input">
      <label htmlFor="textarea">{intl.formatMessage({ id: 'app.label.commentary' })}</label>
      <textarea
        id="textarea"
        className="materialize-textarea"
        maxLength="300"
        onChange={handleChange}
        name={name}
      />
    </div>
  )
}

export default injectIntl(TextareaBlock)
