import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import BlockPart from '../../BlockPart/BlockPart'
import NoBlockPart from '../../NoBlockPart/NoBlockPart'

class AlerteDoses extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      articles: props.articles,
      articlesModified: props.articlesModified
    }
    this.getDataFromChild = this.getDataFromChild.bind(this)
  }

  componentDidMount() {
    this.isValidForm()
    M.AutoInit()
  }

  getDataFromChild(name, value) {
    const { index, getDataFromChild } = this.props
    const { articles, articlesModified } = this.state

    if (articles[index].nonBlockingExpirationAlert === null) {
      articles[index].nonBlockingExpirationAlert = { type: 2 }
    }

    if (articles[index].blockingExpirationAlert === null) {
      articles[index].blockingExpirationAlert = { type: 3 }
    }

    if (name === 'noBlockPartAlertExpirationDate') {
      articles[index].nonBlockingExpirationAlert.message = value
    } else if (name === 'noBlockExpirationDate') {
      articles[index].nonBlockingExpirationAlert.value = value
    } else if (name === 'blockAlert') {
      articles[index].blockingExpirationAlert.message = value
    } else if (name === 'blockExpirationDate') {
      articles[index].blockingExpirationAlert.value = value
    }

    if (!articlesModified.includes(index)) {
      articlesModified.push(index)
    }

    this.setState({ articles })
    this.isValidForm()
    getDataFromChild('articles', articles)
    getDataFromChild('articlesModified', articlesModified)
  }

  isValidForm() {
    const { index, disabledList, getDataFromChild } = this.props
    const { articles } = this.state

    disabledList[index] =
      articles[index].nonBlockingExpirationAlert === null ||
      articles[index].blockingExpirationAlert === null ||
      articles[index].nonBlockingExpirationAlert.message === undefined ||
      articles[index].nonBlockingExpirationAlert.message === '' ||
      articles[index].nonBlockingExpirationAlert.value === undefined ||
      articles[index].blockingExpirationAlert.message === undefined ||
      articles[index].blockingExpirationAlert.message === '' ||
      articles[index].blockingExpirationAlert.value === undefined

    getDataFromChild('disabledList', disabledList)
  }

  render() {
    const { article } = this.props
    const { id, name, nonBlockingExpirationAlert, blockingExpirationAlert } = article

    return (
      <div className="row">
        <div className="col s12">
          <div className="row">
            <div className="col s12">
              <div>
                <span className="labelCustom">
                  <h5>{name}</h5>
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <form>
                <NoBlockPart
                  articleId={id}
                  article={article}
                  nonBlockingExpirationAlert={nonBlockingExpirationAlert}
                  setDataToParent={this.getDataFromChild}
                />
                <BlockPart
                  articleId={id}
                  article={article}
                  blockingExpirationAlert={blockingExpirationAlert}
                  setDataToParent={this.getDataFromChild}
                />
              </form>
            </div>
          </div>
        </div>
        <div className="divider greyDivider" />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(AlerteDoses))
