import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { catchError } from '../../actions'
import Header from '../../components/DailyReport/Header'
import TableReport from '../../components/DailyReport/TableReport/TableReport'
import LoadingPage from '../../components/LoadingPage'
import { api } from '../../parameters'

class DailyReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contentStatus: false,
      loading: true,
      filters: [],
      url: `${api}/api/daily_reports?order[createdAt]=desc`
    }

    this.getStateFromChild = this.getStateFromChild.bind(this)
    this.inLoad = this.inLoad.bind(this)
    this.filterByDate = this.filterByDate.bind(this)
  }

  componentDidMount() {
    const { token } = this.props
    const { url } = this.state

    Axios.get(url, { headers: { Authorization: token } })
      .then(res => {
        this.setState({
          dailyReports: res.data['hydra:member'],
          totalItems: res.data['hydra:totalItems'],
          contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult',
          loading: false
        })
      })
      .catch(err => catchError(err.response))
  }

  getContent() {
    const { intl } = this.props
    const { contentStatus, dailyReports } = this.state

    if (dailyReports.length === 0) {
      return (
        <div className="custom-card">{intl.formatMessage({ id: 'app.message.noReports' })}</div>
      )
    }

    return <TableReport contentStatus={contentStatus} dailyReports={dailyReports} />
  }

  getStateFromChild(data, infiniteLoader = false) {
    let { dailyReports, contentStatus } = this.state

    if (infiniteLoader) {
      dailyReports = [...dailyReports, ...data['hydra:member']]
    } else {
      dailyReports = data['hydra:member']
    }

    if (dailyReports.length >= data['hydra:totalItems']) {
      contentStatus = 'noMoreResult'
    }

    this.setState({
      dailyReports,
      loading: false,
      contentStatus
    })
  }

  filterByDate(date) {
    const { token } = this.props

    const dateToApi = moment(date)

    const dateToApiAfter = dateToApi.format('YYYY-MM-DD')
    const dateToApiBefore = moment(dateToApi.format())
      .add(1, 'd')
      .format('YYYY-MM-DD')

    const url = `${api}/api/daily_reports?createdAt[before]=${dateToApiBefore}&createdAt[after]=${dateToApiAfter}`

    Axios.get(url, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({
          loading: false,
          dailyReports: res.data['hydra:member']
        })
      })
      .catch(err => catchError(err))
  }

  inLoad(contentStatus) {
    this.setState({ contentStatus })
  }

  render() {
    const { role, token } = this.props
    const { filters, loading, url, totalItems } = this.state

    if (loading) {
      return <LoadingPage />
    }

    return (
      <div className="Container row dailyRContainer">
        <div className="col s12">
          <Header
            setStateToParent={this.getStateFromChild}
            url={url}
            role={role}
            totalItems={totalItems}
            filters={filters}
            filterByDate={this.filterByDate}
            token={token}
            inLoad={this.inLoad}
          />
          {this.getContent()}
        </div>
      </div>
    )
  }
}

export default injectIntl(DailyReport)
