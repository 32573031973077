import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { catchError } from '../../actions'
import icons from '../../assets/icons/icons'
import { validatedAction } from '../../components/globalAction'
import LoadingPage from '../../components/LoadingPage'
import ProductsForOrderContext from '../../context/ProductsForOrderContext'
import { api } from '../../parameters'

class Verification extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      redirection: false,
      loading: true,
      uuid: props.match.params.id,
      alertUuid: props.match.params.uuid,
      verification: {}
    }

    this.handleValidate = this.handleValidate.bind(this)
    this.handleRefuse = this.handleRefuse.bind(this)
  }

  componentDidMount() {
    const { token } = this.props
    const { uuid } = this.state

    Axios.get(`${api}/api/vet_verifications/${uuid}`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const { changes } = res.data.data
        this.setState({ verification: res.data, title: res.data.type })

        if (res.data.type === 0) {
          changes.forEach(change => {
            const vaccineToEditId = parseInt(change.vaccineToEditId)
            this.setState({ newVaccine: { doseType: change.newDoseType } })
            Axios.get(`${api}/api/planning_order_vaccines/${vaccineToEditId}`, {
              headers: { Authorization: token }
            })
              .then(resVaccineBefore => {
                this.setState({ vaccineBefore: resVaccineBefore.data })

                Axios.get(`${api}/api/vaccine_specialities/${change.newSpeciality}`, {
                  headers: { Authorization: token }
                }).then(resNewVaccine => {
                  this.setState({
                    loading: false,
                    newVaccine: {
                      speciality: resNewVaccine.data,
                      doseType: change.newDoseType
                    }
                  })
                  this.setDataToCheckRender()
                })
              })
              .catch(err => catchError(err.response))
          })
        } else {
          this.setDataToCheckRender()
          this.setState({ loading: false })
        }
      })
      .catch(err => catchError(err.response))
  }

  setDataToCheckRender() {
    const { intl } = this.props
    const { newVaccine, vaccineBefore, verification } = this.state
    const { type } = verification

    let dataToCheck = null

    switch (type) {
      case 0:
        dataToCheck = (
          <div className="col s12 flex flex-center">
            <span className="marginRight2percent">
              {`${vaccineBefore.speciality.name} ${intl.formatMessage({
                id: `app.label.doses.${vaccineBefore.doseType}`
              })}`}
            </span>
            <span className="marginRight2percent">
              <img src={icons.blueRightArrow} alt="logo blue right arrow" />
            </span>
            <span>
              {`${newVaccine.speciality.name} ${intl.formatMessage({
                id: `app.label.doses.${newVaccine.doseType}`
              })}`}
            </span>
          </div>
        )
        this.setState({ dataToCheck })
        break
      case 1:
        dataToCheck = ''
        this.setState({ dataToCheck })
        break
      default:
    }
  }

  static contextType = ProductsForOrderContext

  handleValidate() {
    const { token } = this.props
    const { alertUuid, uuid, verification } = this.state

    if (verification.type === 0) {
      Axios.patch(
        `${api}/api/planning_order_groups/edit/vaccines/${uuid}`,
        {},
        {
          headers: { Authorization: token }
        }
      ).then(() => {
        Axios.delete(`${api}/api/alert/${alertUuid}`, {
          headers: { Authorization: token }
        })
          .then(() => {
            validatedAction(null, 0)
            this.setState({ redirection: true })
          })
          .catch(err => {
            catchError(err.response)
          })
      })
    } else if (verification.type === 1) {
      Axios.patch(
        `${api}/api/vaccination/realisation/add_preparation/${verification.data.order}`,
        verification.data.preps,
        {
          headers: { Authorization: token }
        }
      ).then(() => {
        Axios.delete(`${api}/api/vet_verifications/${uuid}`, {
          headers: { Authorization: token }
        }).then(() => {
          Axios.delete(`${api}/api/alert/${alertUuid}`, {
            headers: { Authorization: token }
          })
            .then(() => {
              validatedAction(null, 0)
              this.setState({ redirection: true })
            })
            .catch(err => {
              catchError(err.response)
            })
        })
      })
    }
  }

  handleRefuse() {
    const { token } = this.props
    const { alertUuid, uuid, verification } = this.state

    if (verification.type === 0) {
      Axios.patch(
        `${api}/api/planning_order_groups/decline/edit/vaccines/${uuid}`,
        {},
        {
          headers: { Authorization: token }
        }
      )
        .then(() => {
          Axios.delete(`${api}/api/vet_verifications/${uuid}`, {
            headers: { Authorization: token }
          }).then(() => {
            Axios.delete(`${api}/api/alert/${alertUuid}`, {
              headers: { Authorization: token }
            })
              .then(() => {
                this.setState({ redirection: true })
              })
              .catch(err => {
                catchError(err.response)
              })
          })
        })
        .catch(err => {
          catchError(err.response)
        })
    } else {
      Axios.delete(`${api}/api/vet_verifications/${uuid}`, {
        headers: { Authorization: token }
      }).then(() => {
        Axios.delete(`${api}/api/alert/${alertUuid}`, {
          headers: { Authorization: token }
        })
          .then(() => {
            this.setState({ redirection: true })
          })
          .catch(err => {
            catchError(err.response)
          })
      })
    }
  }

  render() {
    const { intl } = this.props
    const { dataToCheck, loading, redirection, title, verification } = this.state
    const { getProductForOrderLabelByKey } = this.context
    if (loading === true) {
      return <LoadingPage />
    }

    if (redirection) {
      return <Redirect to="/alerts" />
    }

    return (
      <div className="Container">
        <section className="row vetContainer vetVerification">
          <div className="vetHeader">
            <h1 className="title">
              {intl.formatMessage({ id: `app.title.verification.${title}` })}
            </h1>
          </div>
          <div className="row vetContent">
            <div className="col s12 flex flexSpaceBetween">
              <div className="bold">
                {`${intl.formatMessage({ id: 'app.label.orderNumber' })} ${
                  verification.data.orderNumber
                }`}
              </div>
              <div>
                <span className="bold">{intl.formatMessage({ id: 'app.label.client' })} : </span>
                {verification.data.client}
              </div>
              <div>
                <span className="bold">{intl.formatMessage({ id: 'app.label.product' })} : </span>
                {getProductForOrderLabelByKey(verification.data.product)}
              </div>
              <div>
                {`${intl.formatMessage({ id: 'app.label.actionBy' })} ${verification.askedBy}`}
              </div>
            </div>
          </div>
          {verification.data.shapsCode && (
            <div className="row vetContent">
              <div className="bold">{intl.formatMessage({ id: 'app.label.newShapsCode' })}</div>
              {verification.data.shapsCode}
            </div>
          )}
          <div className="row vetContent marginTop80">
            <div className="col s12 flex flexSpaceBetween">{dataToCheck}</div>
          </div>
          <div className="row vetContent marginTop80">
            <div className="col s12 flex flex-center">
              <button
                className="btn blueBtn center space5pixel"
                type="button"
                onClick={this.handleValidate}
              >
                {intl.formatMessage({ id: 'app.button.validate' })}
              </button>
              <button className="btn redBtn center" type="button" onClick={this.handleRefuse}>
                {intl.formatMessage({ id: 'app.label.refuse' })}
              </button>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default withRouter(injectIntl(connect(mapStateToProps)(Verification)))
