import React from 'react'
import { injectIntl } from 'react-intl'
import NoBlockArticleHistoryPart from './partials/NoBlockArticleHistoryPart'
import NoBlockEquipmentHistoryPart from './partials/NoBlockEquipmentHistoryPart'
import NoBlockStockHistoryPart from './partials/NoBlockStockHistoryPart'
import NoBlockVaccinationHistoryPart from './partials/NoBlockVaccinationHistoryPart'

const NoBlockHistoryPart = props => {
  const { articleId, paramId, stockId, vaccinationParamId } = props

  if (stockId) {
    const { minimalStockAlert } = props
    return <NoBlockStockHistoryPart minimalStockAlert={minimalStockAlert} />
  }

  if (articleId) {
    const { nonBlockingExpirationAlert } = props
    return <NoBlockArticleHistoryPart nonBlockingExpirationAlert={nonBlockingExpirationAlert} />
  }

  if (paramId !== undefined) {
    const {
      noBlockPartAlertAfter,
      noBlockPartAlertBefore,
      noBlockPartAlertMinBefore,
      noBlockPartAlertMaxBefore,
      noBlockPartAlertMinAfter,
      noBlockPartAlertMaxAfter
    } = props

    return (
      <NoBlockEquipmentHistoryPart
        paramId={paramId}
        noBlockPartAlertAfter={noBlockPartAlertAfter}
        noBlockPartAlertBefore={noBlockPartAlertBefore}
        noBlockPartAlertMaxAfter={noBlockPartAlertMaxAfter}
        noBlockPartAlertMaxBefore={noBlockPartAlertMaxBefore}
        noBlockPartAlertMinAfter={noBlockPartAlertMinAfter}
        noBlockPartAlertMinBefore={noBlockPartAlertMinBefore}
      />
    )
  }
  const { action } = props
  return <NoBlockVaccinationHistoryPart action={action} vaccinationParamId={vaccinationParamId} />
}

export default injectIntl(NoBlockHistoryPart)
