import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { catchError } from '../../../actions'
import AsyncLoader from '../../../components/AsyncLoader'
import Filters from '../../../components/Filters/Filters'
import { ExportFile } from '../../../components/globalAction'
import { api } from '../../../parameters'

class EquipementHistory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      historyList: [],
      url: `${api}/api/equipment_histories`,
      loading: true,
      contentStatus: false,
      filters: [
        { category: 'orderDate', sortBy: ['asc', 'desc'] },
        { category: 'equipmentType', sortBy: [0, 1, 2, 3], translationPrefix: 'equipmentType' },
        { category: 'orderEquipmentId', sortBy: ['asc', 'desc'] },
        {
          category: 'type',
          sortBy: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
        },
        {
          category: 'orderFirstName',
          sortBy: ['asc', 'desc']
        }
      ]
    }
    this.inLoad = this.inLoad.bind(this)
    this.filterByDate = this.filterByDate.bind(this)
    this.getStateFromChild = this.getStateFromChild.bind(this)
  }

  componentDidMount() {
    const { token } = this.props
    const { url, filters } = this.state

    Axios.get(`${api}/api/users`, { headers: { Authorization: token } })
      .then(res => {
        const tableOperatorId = []
        res.data['hydra:member'].forEach((operator, index) => {
          tableOperatorId[index] = [operator.id, operator.firstname]
        })
        filters.push({
          category: 'operator',
          sortBy: tableOperatorId,
          toTranslate: false,
          operator: true
        })
        this.setState({ filters })
      })
      .catch(err => catchError(err.response))

    Axios.get(`${url}?orderDate=desc`, { headers: { Authorization: token } })
      .then(res => {
        this.setState({
          historyList: res.data['hydra:member'],
          totalItems: res.data['hydra:totalItems'],
          contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult',
          loading: false
        })
      })
      .catch(err => catchError(err.response))
  }

  getStateFromChild(data, infiniteLoader = false) {
    let { historyList, contentStatus } = this.state
    if (infiniteLoader) {
      historyList = [...historyList, ...data['hydra:member']]
    } else {
      historyList = data['hydra:member']
    }

    if (historyList.length >= data['hydra:totalItems']) {
      contentStatus = 'noMoreResult'
    }
    this.setState({
      historyList,
      loading: false,
      contentStatus
    })
  }

  filterByDate(date) {
    const { token } = this.props

    const dateToApi = moment(date).format('YYYY-MM-DD')

    const url = `${api}/api/equipment_histories?dateFilter=${dateToApi}`

    Axios.get(url, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({
          loading: false,
          historyList: res.data['hydra:member']
        })
      })
      .catch(err => catchError(err))
  }

  inLoad(contentStatus) {
    this.setState({ contentStatus })
  }

  render() {
    const { url, filters, loading, historyList, contentStatus, totalItems } = this.state
    const { token, role, history, intl } = this.props
    return (
      <div className="row Container equipmentHistory">
        <div className="col s12 content">
          <div className="row pageHeader pageTitle">
            <h5 className="col m5">{intl.formatMessage({ id: 'app.label.equipmentHistory' })}</h5>
            <div className="col m7">
              <div className="row">
                <div className="col m12">
                  <div className="filters flex flexSpaceBetween">
                    <div>
                      <Filters
                        token={token}
                        setStateToParent={this.getStateFromChild}
                        url={url}
                        role={role}
                        totalItems={totalItems}
                        hasDate
                        filterByDate={this.filterByDate}
                        infiniteLoader
                        noActive
                        filters={filters}
                        inLoad={this.inLoad}
                      />
                    </div>
                    <div>
                      <ExportFile data={this.props} url="/api/equipment_histories/export/xlsx" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!loading && (
            <div className="row">
              <div className="col s12">
                <table className="table">
                  <thead>
                    <tr>
                      <th>{intl.formatMessage({ id: 'app.label.date&hour' })} </th>
                      <th>{intl.formatMessage({ id: 'app.label.equipmentType' })} </th>
                      <th>{intl.formatMessage({ id: 'app.label.equipmentNumber' })} </th>
                      <th>{intl.formatMessage({ id: 'app.label.actionType' })} </th>
                      <th>{intl.formatMessage({ id: 'app.label.user' })} </th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyList.map(item => {
                      const { action } = item.history
                      return (
                        <tr
                          key={item.id}
                          style={item.outdated ? { background: '#c1c7d0' } : {}}
                          onClick={() =>
                            history.push({
                              pathname: `/equipments/equipment-history/single/${item.id}`,
                              state: {
                                type: action.fridgeName
                                  ? action.fridgeName
                                  : action.nitrogenContainer?.containerNumber
                                  ? action.nitrogenContainer?.containerNumber
                                  : action.equipementId
                              }
                            })
                          }
                          className="pointer"
                        >
                          <td>{moment(item.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                          <td>
                            {intl.formatMessage({
                              id: `app.label.equipmentType.${item.history.equipmentType}`
                            })}
                          </td>
                          <td>{item.history.equipmentId}</td>
                          <td>{intl.formatMessage({ id: `app.label.actionType.${item.type}` })}</td>
                          <td>{item.firstName}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                  <tfoot className="center">
                    <tr>
                      <th colSpan="5">
                        <AsyncLoader status={contentStatus} />
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default injectIntl(EquipementHistory)
