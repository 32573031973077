import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ModalMoreAction from '../../components/Planning/ModalMoreAction'
import Planning from '../../components/Planning/Planning'

class PlanningPage extends React.Component {
  constructor() {
    super()
    this.state = {
      vaccinesType: [0, 1, 2]
    }
  }

  render() {
    const { token, role } = this.props
    const { listOfOrderFixed, vaccinesType } = this.state

    return (
      <div className="Container equipmentHistory">
        <div className="row pageHeader pageTitle">
          <div className="col s12">
            <Planning role={role} token={token} vaccinesType={vaccinesType} dragBtn />
            <ModalMoreAction orders={listOfOrderFixed} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default withRouter(injectIntl(connect(mapStateToProps)(PlanningPage)))
