import React from 'react'
import icons from '../../../assets/icons/icons'

function RefreshBtn({ redirect = () => {} }) {
  return (
    <div
      onClick={() => {
        redirect()
        window.location.reload(false)
      }}
      onKeyPress={() => {
        redirect()
        window.location.reload(false)
      }}
      role="button"
      tabIndex="0"
    >
      <img src={icons.refresh} style={{ width: '75px' }} alt="refresh icon" />
    </div>
  )
}

export default RefreshBtn
