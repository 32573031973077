import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import moment from 'moment'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import ProgressBar from '../../../components/ProgressBar'
import ChicksPerBoxStep from '../../../components/Vaccination/Preparation/Process/Informations/ChicksPerBoxesStep'
import BigDropsVaccinesStep from '../../../components/Vaccination/Preparation/Process/VaccinesSteps/BigDropsVaccinesStep'
import InjectedVaccinesStep from '../../../components/Vaccination/Preparation/Process/VaccinesSteps/InjectedVaccinesStep'
import PrestationsStep from '../../../components/Vaccination/Preparation/Process/VaccinesSteps/PrestationsStep'
import ThinDropsVaccinesStep from '../../../components/Vaccination/Preparation/Process/VaccinesSteps/ThinDropsVaccinesStep'
import { api } from '../../../parameters'

class PreparationProcess extends React.Component {
  constructor() {
    super()
    this.state = {
      actualPart: 0,
      loading: true,
      labelParts: ['interventions'],
      steps: [0, 4]
    }
    this.changePart = this.changePart.bind(this)
  }

  componentDidMount() {
    const { match, token } = this.props
    const { id } = match.params
    let { actualPart } = this.state

    Axios.get(`${api}/api/planning_order_groups/${id}`, { headers: { Authorization: token } })
      .then(res => {
        const { injectionOrder, thinDropsOrder, bigDropsOrder } = res.data
        const steps = this.setGroup(res.data)
        this.setState({ apiGroup: res.data, loading: false })

        if (res.data.boxesNumber !== null) {
          const i = steps.indexOf(actualPart)
          actualPart = steps[i + 1]
        }
        if (injectionOrder !== null && injectionOrder.processState === 1) {
          const i = steps.indexOf(actualPart)
          actualPart = steps[i + 1]
        }
        if (thinDropsOrder !== null && thinDropsOrder.processState === 1) {
          const i = steps.indexOf(actualPart)
          actualPart = steps[i + 1]
        }
        if (bigDropsOrder !== null && bigDropsOrder.processState === 1) {
          const i = steps.indexOf(actualPart)
          actualPart = steps[i + 1]
        }
        this.setState({ actualPart })
      })
      .catch(err => catchError(err))
  }

  setGroup(group) {
    const { match } = this.props
    const { id } = match.params
    const { labelParts, steps } = this.state
    const {
      boxesNumber,
      chicksPerBox,
      clientName,
      farmer,
      hatchery,
      hatchingDate,
      number,
      generation,
      plannedVaccinationDate,
      product,
      sequence,
      totalChicks,
      gender
    } = group
    const bigDropsVaccines = []
    const bigDropsVaccinesDosesType = []
    let bigDropsProcessState = null
    const injectableVaccines = []
    const injectableVaccinesDosesType = []
    let injectionProcessState = null
    const prestations = []
    const thinDropsVaccines = []
    const thinDropsVaccinesDosesType = []
    let thinDropsProcessState = null
    const hatchingWeek = group.hatchingWeek.split('_')[1]

    group.prestations.forEach(prestation => {
      if (!prestations.includes(prestation)) {
        prestations.push(prestation)
      }
    })

    if (group.injectionOrder !== null) {
      group.injectionOrder.vaccines.forEach(vaccine => {
        if (
          !injectableVaccines.includes(vaccine.speciality.name) ||
          (injectableVaccines.includes(vaccine.speciality.name) &&
            !injectableVaccinesDosesType.includes(vaccine.doseType))
        ) {
          injectableVaccines.push(vaccine.speciality.name)
          injectableVaccinesDosesType.push(vaccine.doseType)
        }
      })

      injectionProcessState = group.injectionOrder.processState
      labelParts.splice(labelParts.length - 1, 0, 'injectedVaccines')
      steps.splice(steps.length - 1, 0, 1)
    }

    if (group.thinDropsOrder !== null) {
      group.thinDropsOrder.vaccines.forEach(vaccine => {
        if (
          !thinDropsVaccines.includes(vaccine.speciality.name) ||
          (thinDropsVaccines.includes(vaccine.speciality.name) &&
            !thinDropsVaccinesDosesType.includes(vaccine.doseType))
        ) {
          thinDropsVaccines.push(vaccine.speciality.name)
          thinDropsVaccinesDosesType.push(vaccine.doseType)
        }
      })

      thinDropsProcessState = group.thinDropsOrder.processState
      labelParts.splice(labelParts.length - 1, 0, 'thinDropsVaccines')
      steps.splice(steps.length - 1, 0, 2)
    }

    if (group.bigDropsOrder !== null) {
      group.bigDropsOrder.vaccines.forEach(vaccine => {
        if (
          !bigDropsVaccines.includes(vaccine.speciality.name) ||
          (bigDropsVaccines.includes(vaccine.speciality.name) &&
            !bigDropsVaccinesDosesType.includes(vaccine.doseType))
        ) {
          bigDropsVaccines.push(vaccine.speciality.name)
          bigDropsVaccinesDosesType.push(vaccine.doseType)
        }
      })

      bigDropsProcessState = group.bigDropsOrder.processState
      labelParts.splice(labelParts.length - 1, 0, 'bigDropsVaccines')
      steps.splice(steps.length - 1, 0, 3)
    }

    const result = {
      bigDropsVaccines,
      bigDropsVaccinesDosesType,
      bigDropsProcessState,
      boxesNumber,
      chicksPerBox,
      clientName,
      farmer,
      hatchery,
      hatchingDate,
      hatchingWeek,
      id: parseInt(id),
      injectableVaccines,
      injectableVaccinesDosesType,
      injectionProcessState,
      number,
      generation,
      plannedVaccinationDate,
      prestations,
      product,
      sequence,
      totalChicks,
      thinDropsVaccines,
      thinDropsVaccinesDosesType,
      thinDropsProcessState,
      gender
    }

    this.setState({ group: result, total: totalChicks })

    return steps
  }

  getContent() {
    const { match } = this.props
    const { actualPart, apiGroup, group, total } = this.state
    const { id } = match.params
    const hatchingDate = moment(group.hatchingDate).format('DD-MM-YYYY')

    switch (actualPart) {
      case 0:
        return <ChicksPerBoxStep changePart={this.changePart} group={group} id={id} />
      case 1:
        return (
          <InjectedVaccinesStep
            changePart={this.changePart}
            group={apiGroup}
            id={id}
            order={apiGroup.injectionOrder}
            totalFixed={group.totalChicks}
            total={total}
            hatchingDate={hatchingDate}
          />
        )
      case 2:
        return (
          <ThinDropsVaccinesStep
            changePart={this.changePart}
            group={apiGroup}
            id={id}
            order={apiGroup.thinDropsOrder}
            totalFixed={group.totalChicks}
            total={total}
            hatchingDate={hatchingDate}
          />
        )
      case 3:
        return (
          <BigDropsVaccinesStep
            changePart={this.changePart}
            group={apiGroup}
            id={id}
            order={apiGroup.bigDropsOrder}
            totalFixed={group.totalChicks}
            total={total}
            hatchingDate={hatchingDate}
          />
        )
      case 4:
        return <PrestationsStep group={apiGroup} totalFixed={group.totalChicks} />
      default:
        return <LoadingPage />
    }
  }

  changePart(boxesNumber = null) {
    const { actualPart, apiGroup, steps } = this.state

    this.setState({ loading: true }, () => {
      const i = steps.indexOf(actualPart)

      if (boxesNumber !== null) {
        apiGroup.boxesNumber = boxesNumber
        this.setState({ apiGroup })
      }

      this.setState({ actualPart: steps[i + 1], loading: false })
    })
  }

  render() {
    const { actualPart, loading, labelParts, steps } = this.state

    if (loading === true) {
      return <LoadingPage />
    }

    return (
      <div className="Container VaccinationContainer row">
        <ProgressBar
          steps={steps}
          partsTotal={labelParts.length}
          actualPart={actualPart}
          parts={labelParts}
        />
        <div className="col s12">{this.getContent()}</div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(PreparationProcess))
