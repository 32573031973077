import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { catchError } from '../../../actions'
import { validatedAction } from '../../../components/globalAction'
import HeaderReception from '../../../components/StockManagement/OrderReception/partials/HeaderReception'
import OrderConformity from '../../../components/StockManagement/OrderReception/partials/OrderConformity'
import ProgressBarReception from '../../../components/StockManagement/OrderReception/partials/ProgressBarReception'
import ProductBagInformations from '../../../components/StockManagement/OrderReception/ProductBagReception/ProductBagInformations/ProductBagInformations'
import { api } from '../../../parameters'
import globalHistory from '../../../history'

class ProductBag extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: true,
      part: 1,
      progress: 10,
      formData: {
        history: {
          nameConformity: true,
          quantityConformity: true,
          batchNumberConformity: true,
          expirationDateConformity: true,
          nameNoConformityReason: null,
          quantityNoConformityReason: null,
          batchNumberNoConformityReason: null,
          expirationDateNoConformityReason: null,
          comment: ''
        }
      }
    }

    this.changePart = this.changePart.bind(this)
    this.getDataFromPart = this.getDataFromPart.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
    this.getContent = this.getContent.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  getDataFromPart(name, data, part) {
    const { formData } = this.state
    const { history } = formData

    if (part === 'history') {
      return this.setState({ formData: { ...formData, history: { ...history, [name]: data } } })
    }

    return this.setState({ formData: { ...formData, [name]: data } })
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  getContent() {
    const { part, formData, disabled } = this.state

    switch (part) {
      case 1:
        return (
          <ProductBagInformations
            getDataFromPart={this.getDataFromPart}
            changePart={this.changePart}
          />
        )
      case 2:
        return (
          <OrderConformity
            handleSubmit={this.handleSubmit}
            handleChange={this.handleChange}
            setDataToParent={this.getDataFromChild}
            getDataFromChild={this.getDataFromChild}
            productBagPart
            disabled={disabled}
            formData={formData}
            setDisabled={this.setDisabled}
          />
        )
      default:
        return <div />
    }
  }

  handleSubmit(e, stopped = false) {
    const {
      formData,
      dateSelected,
      controlHour,
      controlMinutes,
      nameConformity,
      quantityConformity,
      batchNumberConformity,
      expirationDateConformity,
      nameNoConformityReason,
      quantityNoConformityReason,
      batchNumberNoConformityReason,
      expirationDateNoConformityReason
    } = this.state
    const { intl, token } = this.props
    const { history, productBags } = formData

    const receptionDate =
      dateSelected && controlHour && controlMinutes
        ? moment(dateSelected)
            .hours(controlHour)
            .minutes(controlMinutes)
            .format()
        : moment().format()
    if (!stopped) {
      productBags.receptionDate = moment(dateSelected)
        .hours(controlHour)
        .minutes(controlMinutes)
        .format()
    }
    for (let i = 0; i < productBags.length; i++) {
      const productBag = productBags[i]
      productBag.receptionDate = receptionDate
      if (productBag.capacity) {
        productBag.volume = productBag.capacity
        delete productBag.capacity
      }
    }

    formData.stopped = stopped
    formData.history.nameConformity = nameConformity
    formData.history.quantityConformity = quantityConformity
    formData.history.batchNumberConformity = batchNumberConformity
    formData.history.expirationDateConformity = expirationDateConformity
    formData.history.nameNoConformityReason = nameNoConformityReason
    formData.history.quantityNoConformityReason = quantityNoConformityReason
    formData.history.batchNumberNoConformityReason = batchNumberNoConformityReason
    formData.history.expirationDateNoConformityReason = expirationDateNoConformityReason
    history.comment = ''
    history.receptionDate = receptionDate

    return Axios.post(`${api}/api/product_bags`, formData, {
      headers: { Authorization: token }
    })
      .then(() => {
        if (stopped === true) {
          Swal.fire({
            type: 'info',
            title: intl.formatMessage({ id: 'app.title.stoppedReception' }),
            text: intl.formatMessage({ id: 'app.message.stoppedReception' }),
            customClass: {
              icon: 'swalInfoIcon',
              title: 'swalTitle',
              content: 'swalContentText',
              confirmButton: 'blueBtn'
            }
          }).then(() => globalHistory.goBack())
        } else {
          validatedAction(intl.formatMessage({ id: 'app.message.receivedPBags' }))
        }
      })
      .catch(err => catchError(err.response))
  }

  changePart() {
    const { part } = this.state

    this.setState({ part: part + 1, progress: 95 })
  }

  render() {
    const { part, progress } = this.state
    const { intl } = this.props
    return (
      <div className="Container row">
        <HeaderReception text={intl.formatMessage({ id: 'app.part.productBagReception' })} />
        <ProgressBarReception progress={progress} part={part} productBagPart />
        <div className="col s12">{this.getContent()}</div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token
})

export default injectIntl(connect(mapStateToProps)(ProductBag))
