import moment from 'moment'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import HeadSingleHistory from './HeadSingleHistory'
import ValenceContext from '../../../../context/ValencesContext'

export default function ProductsTransfer(props) {
  const { history, operator, date, action, intl } = props
  const { diluentsBatches, dyesBatches, vaccinesBatches } = history.action
  const { getValenceLabelByKey } = useContext(ValenceContext)

  return (
    <div className="Container equipmentHistory">
      <HeadSingleHistory operator={operator} date={date} action={action}>
        <div className="row">
          <div className="col s3">
            <p>
              <span className="label">
                <FormattedMessage id="app.label.fridgeDonor" />
                &nbsp;:&nbsp;
              </span>
              {` ${history.action.giver.name}`}
            </p>
          </div>
          <div className="col s3">
            <p>
              <span className="label">
                <FormattedMessage id="app.equipment.lastControl.instantTemperature" />
                &nbsp;:&nbsp;
              </span>
              {history.action.giver.instantTemperature}{' '}
              {intl.formatMessage({ id: 'app.unit.fridgeTemp' })}
            </p>
          </div>
          <div className="col s3">
            <p>
              <span className="label">
                <FormattedMessage id="app.equipment.lastControl.minTemperature" />
                &nbsp;:&nbsp;
              </span>
              {history.action.giver.minTemperature}{' '}
              {intl.formatMessage({ id: 'app.unit.fridgeTemp' })}
            </p>
          </div>
          <div className="col s3">
            <p>
              <span className="label">
                <FormattedMessage id="app.equipment.lastControl.maxTemperature" />
                &nbsp;:&nbsp;
              </span>
              {history.action.giver.maxTemperature}{' '}
              {intl.formatMessage({ id: 'app.unit.fridgeTemp' })}
            </p>
          </div>
        </div>
        <div className="divider greyDivider" />
        <div className="row">
          <div className="col s3">
            <p>
              <span className="label">
                <FormattedMessage id="app.label.fridgeTaker" />
                &nbsp;:&nbsp;
              </span>
              {` ${history.action.catcher.name}`}
            </p>
          </div>
          <div className="col s3">
            <p>
              <span className="label">
                <FormattedMessage id="app.equipment.lastControl.instantTemperature" />
                &nbsp;:&nbsp;
              </span>
              {history.action.catcher.instantTemperature}{' '}
              {intl.formatMessage({ id: 'app.unit.fridgeTemp' })}
            </p>
          </div>
          <div className="col s3">
            <p>
              <span className="label">
                <FormattedMessage id="app.equipment.lastControl.minTemperature" />
                &nbsp;:&nbsp;
              </span>
              {history.action.catcher.minTemperature}{' '}
              {intl.formatMessage({ id: 'app.unit.fridgeTemp' })}
            </p>
          </div>
          <div className="col s3">
            <p>
              <span className="label">
                <FormattedMessage id="app.equipment.lastControl.maxTemperature" />
                &nbsp;:&nbsp;
              </span>
              {history.action.catcher.maxTemperature}{' '}
              {intl.formatMessage({ id: 'app.unit.fridgeTemp' })}
            </p>
          </div>
        </div>
      </HeadSingleHistory>
      {diluentsBatches.length > 0 && (
        <div className="row historyCard">
          <caption className="col s12 productsTransferHistoryCardHeader">
            <FormattedMessage id="app.part.diluents" />
          </caption>
          <div className="col s12 card">
            <table>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="app.label.batchesNumber" />
                  </th>
                  <th>
                    <FormattedMessage id="app.label.expirationDate" />
                  </th>
                  <th>
                    <FormattedMessage id="app.label.provider" />
                  </th>
                  <th>
                    <FormattedMessage id="app.label.quantity" />
                  </th>
                  <th>
                    <FormattedMessage id="app.label.transferedQuantity" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {diluentsBatches.map(diluent => (
                  <tr key={diluent.batchNumber} className="row">
                    <td>{diluent.batchNumber}</td>
                    <td>{moment(diluent.expirationDate).format('DD/MM/YYYY')}</td>
                    <td>{diluent.provider}</td>
                    <td>{diluent.newStorageUnits + diluent.movedUnits}</td>
                    <td>{diluent.movedUnits}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {dyesBatches.length > 0 && (
        <div className="row historyCard">
          <caption className="col s12 productsTransferHistoryCardHeader">
            <FormattedMessage id="app.part.dyes" />
          </caption>
          <div className="col s12 card">
            <table>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="app.label.batchesNumber" />
                  </th>
                  <th>
                    <FormattedMessage id="app.label.expirationDate" />
                  </th>
                  <th>
                    <FormattedMessage id="app.label.provider" />
                  </th>
                  <th>
                    <FormattedMessage id="app.label.quantity" />
                  </th>
                  <th>
                    <FormattedMessage id="app.label.transferedQuantity" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {dyesBatches.map(dye => (
                  <tr key={dye.batchNumber}>
                    <td>{dye.batchNumber}</td>
                    <td>{moment(dye.expirationDate).format('DD/MM/YYYY')}</td>
                    <td>{dye.provider}</td>
                    <td>{dye.newStorageUnits + dye.movedUnits}</td>
                    <td>{dye.movedUnits}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {vaccinesBatches.length > 0 && (
        <div className="row historyCard">
          <caption className="col s12 productsTransferHistoryCardHeader">
            <FormattedMessage id="app.part.vaccines" />
          </caption>
          <div className="col s12 card">
            <table>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="app.label.article" />
                  </th>
                  <th>
                    <FormattedMessage id="app.label.batchesNumber" />
                  </th>
                  <th>
                    <FormattedMessage id="app.label.expirationDate" />
                  </th>
                  <th>
                    <FormattedMessage id="app.label.speciality" />
                  </th>
                  <th>
                    <FormattedMessage id="app.label.quantity" />
                  </th>
                  <th>
                    <FormattedMessage id="app.label.transferedQuantity" />
                  </th>
                  <th>
                    <FormattedMessage id="app.label.valence" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {vaccinesBatches.map(vaccin => (
                  <tr key={vaccin.batchNumber}>
                    <td>{vaccin.article}</td>
                    <td>{vaccin.batchNumber}</td>
                    <td>{moment(vaccin.expirationDate).format('DD/MM/YYYY')}</td>
                    <td>{vaccin.speciality}</td>
                    <td>{vaccin.newStorageUnits + vaccin.movedUnits}</td>
                    <td>{vaccin.movedUnits}</td>
                    <td>{getValenceLabelByKey(vaccin.valence)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}
