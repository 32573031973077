/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import traceabilityProductTypes from '../../../config/traceabilityProductTypes'
import { SpanLink } from '../Components'
import { getEquipmentById } from '../ReverseRedirections'
import productTypes from '../../../config/productTypes'

function StorageDevice({ token, location, intl, history }) {
  const [page, setPage] = useState(1)
  const [state, setState] = useState({
    loading: true,
    items: null,
    batchNumber: null,
    batchType: null
  })

  useEffect(() => {
    const pathName = location.pathname.split('/')
    const id = Number(pathName[pathName.length - 2])
    Axios.get(`${api}/api/batch_traceability/${id}/storage`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const unknownValue = intl.formatMessage({ id: 'app.label.N/A' })
        const fromObjectToArray = []
        Object.entries(res.data['hydra:member']).forEach(i => {
          fromObjectToArray.push({ ...i[1], key: i[0] })
        })
        const { batchType } = res.data
        const mappedData = fromObjectToArray.map(i => ({
          ...i,
          start: {
            controlInstant: i.start?.control
              ? `${i.start.control.instant}
            ${intl.formatMessage({
              id:
                res.data.equipmentType === traceabilityProductTypes.VACCINES_BATCH
                  ? 'app.unit.centimeter'
                  : 'app.unit.temp'
            })}`
              : unknownValue,
            controlMin: i.start?.control
              ? `${i.start.control.min}
            ${intl.formatMessage({
              id:
                res.data.equipmentType === traceabilityProductTypes.VACCINES_BATCH
                  ? 'app.unit.centimeter'
                  : 'app.unit.temp'
            })}`
              : unknownValue,
            controlMax: i.start?.control
              ? `${i.start.control.max}
            ${intl.formatMessage({
              id:
                res.data.equipmentType === traceabilityProductTypes.VACCINES_BATCH
                  ? 'app.unit.centimeter'
                  : 'app.unit.temp'
            })}`
              : unknownValue,
            date: i.start?.date ? moment(i.start.date).format('DD/MM/YYYY') : unknownValue,
            hour: i.start?.date ? moment(i.start.date).format('HH:mm') : unknownValue,
            quantity: i.start?.quantity
              ? `${i.start.quantity}
            ${intl.formatMessage({
              id: `app.unit.${
                [productTypes.DILUENT_BATCH, productTypes.DYE_BATCH].includes(batchType)
                  ? 'units'
                  : 'vials'
              }`
            })}`
              : '0',
            raison: i.start?.raison ?? unknownValue,
            from: i.start?.from ?? unknownValue
          },
          end: i.end
            ? {
                label: 'app.label.vaccine_or_product.storage_device.end_of_storage',
                controlInstant: i.end?.control
                  ? `${i.end.control.instant}
              ${intl.formatMessage({
                id:
                  res.data.equipmentType === traceabilityProductTypes.VACCINES_BATCH
                    ? 'app.unit.centimeter'
                    : 'app.unit.temp'
              })}`
                  : unknownValue,
                controlMin: i.end?.control
                  ? `${i.end.control.min}
              ${intl.formatMessage({
                id:
                  res.data.equipmentType === traceabilityProductTypes.VACCINES_BATCH
                    ? 'app.unit.centimeter'
                    : 'app.unit.temp'
              })}`
                  : unknownValue,
                controlMax: i.end?.control
                  ? `${i.end.control.max}
              ${intl.formatMessage({
                id:
                  res.data.equipmentType === traceabilityProductTypes.VACCINES_BATCH
                    ? 'app.unit.centimeter'
                    : 'app.unit.temp'
              })}`
                  : unknownValue,
                date: i.end?.date ? moment(i.end.date).format('DD/MM/YYYY') : unknownValue,
                hour: i.end?.date ? moment(i.end.date).format('HH:mm') : unknownValue,
                quantity: i.end?.quantity
                  ? `${i.end.quantity}
          ${intl.formatMessage({
            id: 'app.unit.vials'
          })}`
                  : '0',
                raison: i.end?.raison ?? unknownValue,
                to: i.end?.to ?? unknownValue
              }
            : {
                label: 'app.label.vaccine_or_product.storage_device.currently_in_storage'
              }
        }))
        setState({
          loading: false,
          items: state.items ? state.items.concat(mappedData) : mappedData,
          batchNumber: res.data.speciality ?? res.data.batchNumber,
          batchType,
          equipmentType: res.data.equipmentType
        })
      })
      .catch(err => catchError(err))
  }, [])

  return (
    <div className="row Container stockAvailibilityItem">
      <div className="col s12 Content">
        <div className="row pageHeader pageTitle itemHeader">
          <div
            onClick={() => history.goBack()}
            role="button"
            tabIndex="0"
            onKeyPress={() => history.goBack()}
            className="goBackBtn col s1"
          >
            <i className="material-icons">chevron_left</i>
          </div>
          {state.batchNumber !== null && (
            <h5 className="col s12">
              {state.batchNumber} - {intl.formatMessage({ id: 'app.title.storage_device' })}
            </h5>
          )}
        </div>
        {!state.loading ? (
          <div className="row">
            {state.items.map(item => {
              return (
                <>
                  <div>
                    {`${intl.formatMessage({
                      id:
                        state.equipmentType === traceabilityProductTypes.VACCINES_BATCH
                          ? 'app.label.vaccine_or_product.storage_device.container'
                          : 'app.label.vaccine_or_product.storage_device.fridge'
                    })} ${item.key}`}
                  </div>
                  <table className="table" style={{ marginBottom: '16px' }}>
                    <thead>
                      <tr>
                        <th />
                        <th>
                          {intl.formatMessage({
                            id: 'app.label.vaccine_or_product.storage_device.date'
                          })}
                        </th>
                        <th>
                          {intl.formatMessage({
                            id: 'app.label.vaccine_or_product.storage_device.hour'
                          })}
                        </th>
                        <th>
                          {intl.formatMessage({
                            id:
                              state.equipmentType === traceabilityProductTypes.VACCINES_BATCH
                                ? 'app.label.vaccine_or_product.storage_device.nitrogen_level'
                                : 'app.label.vaccine_or_product.storage_device.temperature_instant'
                          })}
                        </th>
                        {state.equipmentType ===
                          traceabilityProductTypes.TYPE_NITROGEN_CONTAINER && (
                          <>
                            <th>
                              {intl.formatMessage({
                                id: 'app.label.vaccine_or_product.storage_device.temperature_min'
                              })}
                            </th>
                            <th>
                              {intl.formatMessage({
                                id: 'app.label.vaccine_or_product.storage_device.temperature_max'
                              })}
                            </th>
                          </>
                        )}
                        <th>
                          {intl.formatMessage({
                            id: 'app.label.vaccine_or_product.storage_device.reason'
                          })}
                        </th>
                        <th>
                          {intl.formatMessage({
                            id: `app.label.vaccine_or_product.storage_device.${
                              [productTypes.DILUENT_BATCH, productTypes.DYE_BATCH].includes(
                                state.batchType
                              )
                                ? 'unit'
                                : 'vial'
                            }_number`
                          })}
                        </th>
                        <th>
                          {intl.formatMessage({
                            id: 'app.label.vaccine_or_product.storage_device.new_device'
                          })}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {intl.formatMessage({
                            id: 'app.label.vaccine_or_product.storage_device.beginning_of_storage'
                          })}
                        </td>
                        <td>{item.start.date}</td>
                        <td>{item.start.hour}</td>
                        <td> {item.start.controlInstant}</td>
                        {state.equipmentType ===
                          traceabilityProductTypes.TYPE_NITROGEN_CONTAINER && (
                          <>
                            <td> {item.start.controlMin}</td>
                            <td> {item.start.controlMax}</td>
                          </>
                        )}
                        <td>{item.start.raison}</td>
                        <td>{item.start.quantity}</td>
                        <td>
                          <SpanLink
                            value={item.start.from}
                            setLoading={loading => {
                              setState({ ...state, loading })
                            }}
                            redirect={getEquipmentById}
                            token
                            history
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {intl.formatMessage({
                            id: item.end.label
                          })}
                        </td>
                        <td>{item.end.date}</td>
                        <td>{item.end.hour}</td>
                        <td>{item.end.controlInstant}</td>
                        {state.equipmentType ===
                          traceabilityProductTypes.TYPE_NITROGEN_CONTAINER && (
                          <>
                            <td> {item.end.controlMin}</td>
                            <td> {item.end.controlMax}</td>
                          </>
                        )}
                        <td>{item.end.raison}</td>
                        <td>{item.end.quantity}</td>
                        <td>
                          <SpanLink
                            value={item.end.to}
                            setLoading={loading => {
                              setState({ ...state, loading })
                            }}
                            redirect={getEquipmentById}
                            token
                            history
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )
            })}
          </div>
        ) : (
          <LoadingPage />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(StorageDevice)))
