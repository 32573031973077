/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import AsyncLoader from '../../../components/AsyncLoader'
import equipmentTypes from '../../../config/equipmentTypes'
import { WithUnit, Translation } from '../Components'

function EquipmentControl({ token, location, intl, history }) {
  const [page, setPage] = useState(1)
  const [state, setState] = useState({
    loading: true,
    headers: null,
    items: null,
    contentStatus: false,
    totalItems: 0,
    equipmentType: null,
    equipmentId: null,
    batchNumber: null
  })

  const translationKey = 'app.label.equipment.equipment_control.'

  const t = value => {
    return <Translation id={`${translationKey}${value}`} />
  }

  function loadMore() {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (state.totalItems > page * 30) {
        setPage(page + 1)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', loadMore)
  }, [])

  useEffect(() => {
    const pathName = location.pathname.split('/')
    const id = Number(pathName[pathName.length - 2])
    Axios.get(`${api}/api/equipment_traceability/${id}/control?page=${page}`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const unknownValue = intl.formatMessage({ id: 'app.label.N/A' })
        const equipmentType = res.data?.equipmentType

        let headers = []
        const equipmentId = res.data?.equipmentId
        if (equipmentType === equipmentTypes.CONTAINER) {
          headers = [
            'firstControlAt',
            'firstControlHour',
            'firstControlBy',
            'type',
            'firstControlLevel',
            'lastControlAt',
            'lastControlBy',
            'lastControlLevel'
          ]
        }
        if (equipmentType === equipmentTypes.FRIDGE) {
          headers = [
            'controlAt',
            'hour',
            'controlBy',
            'controlMinT',
            'controlMaxT',
            'controlInstantT'
          ]
        }
        if (equipmentType === equipmentTypes.WATHER_BATH) {
          headers = ['controlAt', 'hour', 'controlBy', 'bathTemp']
        }
        if (equipmentType === equipmentTypes.VACCINATION_EQUIPMENT) {
          let extraHeader = []
          if (equipmentId === 'BIND' || equipmentId === 'COCC') {
            extraHeader = ['sprayUniformity', 'nozzles']
          }
          if (equipmentId === 'Injection') {
            extraHeader = ['needlesType', 'newNeedles', 'needles']
          }
          headers = ['controlAt', 'hour', 'controlBy', 'rinsePrimeMachines', ...extraHeader]
        }
        const mappedData = [...res.data['hydra:member']].map(i => {
          if (equipmentType === equipmentTypes.CONTAINER) {
            return {
              firstControlAt: i.firstControlAt
                ? moment(i.firstControlAt).format('DD/MM/YYYY')
                : unknownValue,
              hour: i.firstControlAt ? moment(i.firstControlAt).format('HH[h]mm') : unknownValue,
              firstControlBy: i.firstControlBy ?? unknownValue,
              type: i.type
                ? intl.formatMessage({
                    id: `${translationKey}type.${i.type}`
                  })
                : unknownValue,
              firstControlLevel: (
                <WithUnit text={i.firstControlLevel ?? unknownValue} unit="centimeter" />
              ),
              lastControlAt: i.lastControlAt
                ? moment(i.lastControlAt).format('HH[h]mm')
                : unknownValue,
              lastControlBy: i.lastControlBy ?? unknownValue,
              lastControlLevel: (
                <WithUnit text={i.lastControlLevel ?? unknownValue} unit="centimeter" />
              )
            }
          }
          if (equipmentType === equipmentTypes.FRIDGE) {
            return {
              controlAt: i.controlAt ? moment(i.controlAt).format('DD/MM/YYYY') : unknownValue,
              hour: i.controlAt ? moment(i.controlAt).format('HH[h]mm') : unknownValue,
              controlBy: i.controlBy ?? unknownValue,
              controlMinT: i.controlMinT
                ? `${i.controlMinT} ${intl.formatMessage({
                    id: 'app.unit.temp'
                  })}`
                : unknownValue,
              controlMaxT: i.controlMaxT
                ? `${i.controlMaxT} ${intl.formatMessage({
                    id: 'app.unit.temp'
                  })}`
                : unknownValue,
              controlInstantT: i.controlInstantT
                ? `${i.controlInstantT} ${intl.formatMessage({
                    id: 'app.unit.temp'
                  })}`
                : unknownValue
            }
          }
          if (equipmentType === equipmentTypes.VACCINATION_EQUIPMENT) {
            let extra = {}
            if (equipmentId === 'BIND' || equipmentId === 'COCC') {
              extra = {
                sprayUniformity:
                  i.sprayUniformity !== null
                    ? i.sprayUniformity
                      ? t('yes')
                      : t('no')
                    : unknownValue,
                nozzles: i.nozzles
                  ? i.nozzles.map(nozzle => `${nozzle.name} : ${nozzle.volume}`).join('\n')
                  : unknownValue
              }
            }
            if (equipmentId === 'Injection') {
              extra = {
                needlesType: i.needlesType ? `${i.needlesType}g` : unknownValue,
                newNeedles:
                  i.newNeedles !== null ? (i.newNeedles ? t('yes') : t('no')) : unknownValue,
                needles: i.needles
                  ? i.needles.map(needle => `${needle.name} : ${needle.volume}`).join('\n')
                  : unknownValue
              }
            }
            return {
              controlAt: i.controlAt ? moment(i.controlAt).format('DD/MM/YYYY') : unknownValue,
              hour: i.controlAt ? moment(i.controlAt).format('HH[h]mm') : unknownValue,
              controlBy: i.controlBy ?? unknownValue,
              rinsePrimeMachines:
                i.rinsePrimeMachines !== null
                  ? i.rinsePrimeMachines
                    ? t('yes')
                    : t('no')
                  : unknownValue,
              ...extra
            }
          }
          if (equipmentType === equipmentTypes.WATHER_BATH) {
            return {
              controlAt: i.controlAt ? moment(i.controlAt).format('DD/MM/YYYY') : unknownValue,
              hour: i.controlAt ? moment(i.controlAt).format('HH[h]mm') : unknownValue,
              controlBy: i.controlBy ?? unknownValue,
              bathTemp: i.bathTemp
                ? `${i.bathTemp} ${intl.formatMessage({
                    id: 'app.unit.bathTemp'
                  })}`
                : unknownValue
            }
          }
          return {}
        })
        setState({
          ...state,
          loading: false,
          headers,
          items: state.items ? state.items.concat(mappedData) : mappedData,
          totalItems: res.data['hydra:totalItems'],
          contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult',
          equipmentType: intl.formatMessage({
            id: `app.filter.equipmentType.${res.data?.equipmentType}`
          }),
          equipmentId,
          batchNumber: res.data.speciality ?? res.data.batchNumber
        })
      })
      .catch(err => catchError(err))
  }, [page])

  return (
    <div className="row Container stockAvailibilityItem">
      <div className="col s12 Content">
        <div className="row pageHeader pageTitle itemHeader">
          <div
            onClick={() => history.goBack()}
            role="button"
            tabIndex="0"
            onKeyPress={() => history.goBack()}
            className="goBackBtn col s1"
          >
            <i className="material-icons">chevron_left</i>
          </div>
          {state.batchNumber !== null && (
            <h5 className="col s12">
              {state.equipmentType} {state.equipmentId} -{' '}
              {intl.formatMessage({ id: 'app.title.equipment_control' })}
            </h5>
          )}
        </div>
        {!state.loading ? (
          <div className="row">
            <table className="table">
              <thead>
                <tr>
                  {state.headers.map(header => {
                    return (
                      <th>
                        {intl.formatMessage({
                          id: `${translationKey}${header}`
                        })}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {state.items.map(item => {
                  return (
                    <tr key={item.id}>
                      {Object.entries(item).map((value, _) => {
                        return <td style={{ whiteSpace: 'pre-wrap' }}>{value[1]}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
              <tfoot className="center">
                <tr>
                  <th colSpan="5">
                    <AsyncLoader status={state.contentStatus} />
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        ) : (
          <LoadingPage />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(EquipmentControl)))
