import React from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import HeadSingleHistory from './HeadSingleHistory'

export default function FridgeControl(props) {
  const { history, operator, date, action, intl } = props

  return (
    <div className="Container equipmentHistory">
      <HeadSingleHistory operator={operator} date={date} action={action}>
        <div className="row">
          <div className="col s6">
            <p>
              <span className="label">
                <FormattedMessage id="app.label.name" />
                &nbsp;:
              </span>
              {` ${history.action.fridge.name}`}
            </p>
          </div>
          <div className="col s6">
            <p>
              <span className="label">
                <FormattedMessage id="app.equipment.lastControl" />
                &nbsp;:
              </span>
              {` ${moment(history.action.lastControlDate).format('DD/MM/YYYY')}`}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col s4">
            <p>
              <span className="label">
                <FormattedMessage id="app.equipment.lastControl.instantTemperature" />
                &nbsp;:
              </span>
              {` ${history.action.fridge.instantTemperature}  ${intl.formatMessage({
                id: 'app.unit.fridgeTemp'
              })}`}
            </p>
          </div>
          <div className="col s4">
            <p>
              <span className="label">
                <FormattedMessage id="app.equipment.lastControl.minTemperature" />
                &nbsp;:
              </span>
              {` ${history.action.fridge.minTemperature}  ${intl.formatMessage({
                id: 'app.unit.fridgeTemp'
              })}`}
            </p>
          </div>
          <div className="col s4">
            <p>
              <span className="label">
                <FormattedMessage id="app.equipment.lastControl.maxTemperature" />
                &nbsp;:
              </span>
              {` ${history.action.fridge.maxTemperature}  ${intl.formatMessage({
                id: 'app.unit.fridgeTemp'
              })}`}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col s4">
            <p>
              <span className="label">
                <FormattedMessage id="app.equipment.previousInstantT" />
                &nbsp;:
              </span>
              {` ${history.action.prevInstantTemperature}  ${intl.formatMessage({
                id: 'app.unit.fridgeTemp'
              })}`}
            </p>
          </div>
          <div className="col s4">
            <p>
              <span className="label">
                <FormattedMessage id="app.equipment.previousMinT" />
                &nbsp;:
              </span>
              {` ${history.action.prevMinTemperature}  ${intl.formatMessage({
                id: 'app.unit.fridgeTemp'
              })}`}
            </p>
          </div>
          <div className="col s4">
            <p>
              <span className="label">
                <FormattedMessage id="app.equipment.previousMaxT" />
                &nbsp;:
              </span>
              {` ${history.action.prevMaxTemperature}  ${intl.formatMessage({
                id: 'app.unit.fridgeTemp'
              })}`}
            </p>
          </div>
        </div>
      </HeadSingleHistory>
    </div>
  )
}
