import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import pjson from '../../version.json'
import { logoutUser } from '../../actions'
import icons from '../../assets/icons/icons'
import logo from '../../assets/images/light_logo.svg'
import roles from '../../config/userRoles.json'

class Header extends React.Component {
  constructor(props) {
    super()
    const { ROLE_HATCHING_RESP, ROLE_HATCH_RESP, ROLE_VET, ROLE_OPERATOR, ROLE_DEV } = roles
    this.state = {
      navItems: [
        {
          name: props.intl.formatMessage({ id: 'app.part.planning' }),
          path: '/planning',
          mainPath: '/planning',
          activeIcon: icons.yellowCalendar,
          icon: icons.whiteCalendar,
          roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR, ROLE_DEV],
          extends: []
        },
        {
          name: props.intl.formatMessage({ id: 'app.part.user' }),
          path: '/users',
          mainPath: '/users',
          activeIcon: icons.yellowUser,
          icon: icons.whiteUser,
          roleAccess: [ROLE_HATCH_RESP],
          extends: []
        },
        {
          name: props.intl.formatMessage({ id: 'app.part.equipment' }),
          path: '/equipments/management',
          mainPath: '/equipments',
          activeIcon: icons.yellowEquipment,
          icon: icons.whiteEquipment,
          roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR, ROLE_DEV],
          extends: [
            {
              name: 'controlEquipment',
              path: '/equipments/management',
              roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR]
            },
            {
              name: 'containers',
              path: '/equipments/containers',
              roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR]
            },
            {
              name: 'fridges',
              path: '/equipments/fridges',
              roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR]
            },
            {
              name: 'history',
              path: '/equipments/equipment-history',
              roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR, ROLE_DEV]
            }
          ]
        },
        {
          name: props.intl.formatMessage({ id: 'app.part.stock' }),
          path: '/stocks/stock-availibility',
          mainPath: '/stocks',
          activeIcon: icons.yellowStock,
          icon: icons.whiteStock,
          roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR, ROLE_DEV],
          extends: [
            {
              name: 'stockAvailibility',
              path: '/stocks/stock-availibility',
              roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR]
            },
            {
              name: 'orderReceived',
              path: '/stocks/order-received',
              roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP]
            },
            {
              name: 'history',
              path: '/stocks/stock-history',
              roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_DEV]
            }
          ]
        },
        {
          name: props.intl.formatMessage({ id: 'app.part.vaccination' }),
          path: '/vaccination',
          mainPath: '/vaccination',
          activeIcon: icons.yellowChick,
          icon: icons.whiteChick,
          roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR],
          extends: []
        },
        {
          name: props.intl.formatMessage({ id: 'app.part.dailyReport' }),
          path: '/dailyReport',
          mainPath: '/dailyReport',
          activeIcon: icons.yellowCheck,
          icon: icons.whiteCheck,
          roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR, ROLE_DEV],
          extends: []
        },
        {
          name: props.intl.formatMessage({ id: 'app.part.history' }),
          path: '/history',
          mainPath: '/history',
          activeIcon: icons.yellowHistory,
          icon: icons.whiteHistory,
          roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR, ROLE_DEV],
          extends: []
        },
        {
          name: props.intl.formatMessage({ id: 'app.part.documentation' }),
          path: '/documentation',
          mainPath: '/documentation',
          activeIcon: icons.yellowDocumentation,
          icon: icons.whiteDocumentation,
          roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_VET, ROLE_OPERATOR, ROLE_DEV],
          extends: []
        },
        {
          name: props.intl.formatMessage({ id: 'app.part.parameters' }),
          path: '/parameters/equipment',
          mainPath: '/parameters',
          activeIcon: icons.yellowParam,
          icon: icons.whiteParam,
          roleAccess: [ROLE_HATCH_RESP, ROLE_DEV],
          extends: [
            {
              name: 'equipmentParams',
              path: '/parameters/equipment',
              roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR]
            },
            {
              name: 'stockParams',
              path: '/parameters/stock',
              roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR]
            },
            {
              name: 'vaccinationParams',
              path: '/parameters/parameter-vaccination',
              roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR]
            },
            {
              name: 'history',
              path: '/parameters/parameter-history',
              roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR, ROLE_DEV]
            }
          ]
        },
        {
          name: props.intl.formatMessage({ id: 'app.part.parameters' }),
          path: '/parameters/incompatibilities',
          mainPath: '/parameters',
          activeIcon: icons.yellowParam,
          icon: icons.whiteParam,
          roleAccess: [ROLE_VET],
          extends: [
            {
              name: 'incompatibility',
              path: '/parameters/incompatibilities',
              roleAccess: [ROLE_VET]
            },
            {
              name: 'addVaccine',
              path: '/parameters/add-vaccine',
              roleAccess: [ROLE_VET]
            },
            {
              name: 'history',
              path: '/parameters/parameter-history',
              roleAccess: [ROLE_VET]
            }
          ]
        },
        {
          name: props.intl.formatMessage({ id: 'app.part.traceability' }),
          path: '/traceability/order/list',
          mainPath: '/traceability',
          activeIcon: icons.yellowTraceability,
          icon: icons.whiteTraceability,
          roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR, ROLE_DEV],
          extends: [
            {
              name: 'traceability_order',
              path: '/traceability/order/list',
              roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR]
            },
            {
              name: 'traceability_vaccine_or_product',
              path: '/traceability/vaccine_or_product/list',
              roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR]
            },
            {
              name: 'traceability_equipment',
              path: '/traceability/equipment/list',
              roleAccess: [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR]
            }
          ]
        }
      ],
      navBar: []
    }
  }

  componentDidMount() {
    const { navItems } = this.state
    const { role } = this.props
    const navBar = []
    const { ROLE_SUPER_ADMIN } = roles

    if (role) {
      navItems.forEach(el => {
        const item = el
        if (el.roleAccess.includes(role[0]) || role[0] === ROLE_SUPER_ADMIN) {
          if (item.extends) {
            const navExtensions = []
            item.extends.forEach(extend => {
              if (extend.roleAccess.includes(role[0]) || role[0] === ROLE_SUPER_ADMIN) {
                navExtensions.push(extend)
              }
              item.extends = navExtensions
            })
          }
          navBar.push(item)
        }
      })
      return this.setState({ navBar })
    }
    return logoutUser()
  }

  render() {
    const { navBar } = this.state
    const { location, intl } = this.props
    const { version } = pjson
    return (
      <div className="header">
        <nav>
          <div className="topBarLogo">
            <Link to={{ pathname: '/' }}>
              <img src={logo} alt="logo" className="topBarLogoImg" />
            </Link>
          </div>
          <ul className="headerUl">
            {navBar.map(NavItem => (
              <li className="headerUlLi" key={NavItem.name}>
                <div
                  className={
                    location.pathname.includes(NavItem.mainPath)
                      ? 'headerUlLiItem active'
                      : 'headerUlLiItem'
                  }
                >
                  <img
                    src={
                      location.pathname.includes(NavItem.mainPath)
                        ? NavItem.activeIcon
                        : NavItem.icon
                    }
                    alt="icon"
                  />
                  <Link to={{ pathname: NavItem.path, state: { part: NavItem.name } }}>
                    {NavItem.name}
                  </Link>
                </div>
                {location.pathname.includes(NavItem.mainPath) && NavItem.extends.length > 0 ? (
                  <ul className="navigationSubList">
                    {NavItem.extends.map(item => (
                      <li
                        key={item.name}
                        className={
                          location.pathname.includes(item.path)
                            ? 'navigationSubItems active'
                            : 'navigationSubItems'
                        }
                      >
                        <Link to={{ pathname: item.path, state: { part: item.name } }}>
                          {intl.formatMessage({ id: `app.part.${item.name}` })}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div />
                )}
              </li>
            ))}
          </ul>
        </nav>
        <div className="version">v{version}</div>
      </div>
    )
  }
}

export default withRouter(injectIntl(connect()(Header)))
