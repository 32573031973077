import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { catchError } from '../../../../actions'
import Filters from '../../../../components/Filters/Filters'
import { BackToPrevious, ExportFile } from '../../../../components/globalAction'
import { renderTemperature } from '../../../../components/globalRenderActions'
import LoadingPage from '../../../../components/LoadingPage'
import StatePill from '../../../../components/StatePill'
import { api } from '../../../../parameters'

class FridgeList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fridges: [],
      loading: true,
      url: `${api}/api/fridges`,
      filters: [
        { category: 'order[name]', sortBy: ['asc', 'desc'] },
        {
          category: 'lastControl.minTemperatureState',
          sortBy: [0, 1, 2, 3],
          translationPrefix: 'state'
        },
        {
          category: 'lastControl.maxTemperatureState',
          sortBy: [0, 1, 2, 3],
          translationPrefix: 'state'
        },
        {
          category: 'lastControl.instantTemperatureState',
          sortBy: [0, 1, 2, 3],
          translationPrefix: 'state'
        },
        {
          category: 'order[lastControl.createdAt]',
          sortBy: ['asc', 'desc']
        }
      ]
    }
    this.getStateFromChild = this.getStateFromChild.bind(this)
    this.inLoad = this.inLoad.bind(this)
    this.filterByDate = this.filterByDate.bind(this)
  }

  componentDidMount() {
    const { token } = this.props
    const { url } = this.state
    Axios.get(`${url}?order[name]=asc`, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({
          loading: false,
          fridges: res.data['hydra:member']
        })
      })
      .catch(err => catchError(err.response))
  }

  getStateFromChild(fridges) {
    this.setState({ fridges, loading: false })
  }

  filterByDate(date) {
    const { token } = this.props

    const dateToApi = moment(date)

    const dateToApiAfter = dateToApi.format('YYYY-MM-DD')
    const dateToApiBefore = moment(dateToApi.format())
      .add(1, 'd')
      .format('YYYY-MM-DD')

    const url = `${api}/api/fridges?lastControl.createdAt[before]=${dateToApiBefore}&lastControl.createdAt[after]=${dateToApiAfter}`

    Axios.get(url, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({
          loading: false,
          fridges: res.data['hydra:member']
        })
      })
      .catch(err => catchError(err))
  }

  inLoad(loading) {
    this.setState({ loading })
  }

  render() {
    const { intl, token, history } = this.props
    const { filters, loading, url, fridges } = this.state

    return (
      <div className="row Container fridgesManagement">
        <div className="col s12 content">
          <div className="row pageHeader pageTitle">
            <BackToPrevious
              history={history}
              text={intl.formatMessage({ id: 'app.part.listFridge' })}
            />
            <div className="filters col m12 flex flexSpaceBetween">
              <div>
                <Filters
                  intl={intl}
                  token={token}
                  filters={filters}
                  hasDate
                  filterByDate={this.filterByDate}
                  inLoad={this.inLoad}
                  url={url}
                  sortCategory="order[name]"
                  sortBy="asc"
                  setStateToParent={this.getStateFromChild}
                />
              </div>
              <div>
                <ExportFile
                  data={this.props}
                  url="/api/fridges/export/xlsx"
                  name="fridges_list.xlsx"
                />
              </div>
            </div>
          </div>
          <div className="row">
            {!loading ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>{intl.formatMessage({ id: 'app.label.fridge' })}</th>
                    <th>
                      {intl.formatMessage({ id: 'app.equipment.lastControl.minTemperature' })}
                    </th>
                    <th>
                      {intl.formatMessage({ id: 'app.equipment.lastControl.maxTemperature' })}
                    </th>
                    <th>
                      {intl.formatMessage({ id: 'app.equipment.lastControl.instantTemperature' })}
                    </th>
                    <th>{intl.formatMessage({ id: 'app.equipment.lastControl' })}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {fridges.map(fridge => (
                    <tr
                      key={fridge.id}
                      className="pointer"
                      onClick={() =>
                        history.push({
                          pathname: `/equipments/fridges/single/${fridge.id}`
                        })
                      }
                    >
                      <td>{fridge.name}</td>
                      <td>
                        <div>
                          <StatePill type="FridgeMinTemperature" data={fridge} />
                          {renderTemperature(fridge.lastControl.minTemperature, intl)}
                        </div>
                      </td>
                      <td>
                        <div>
                          <StatePill type="FridgeMaxTemperature" data={fridge} />
                          {renderTemperature(fridge.lastControl.maxTemperature, intl)}
                        </div>
                      </td>
                      <td>
                        <div>
                          <StatePill type="FridgeInstantTemperature" data={fridge} />
                          {renderTemperature(fridge.lastControl.instantTemperature, intl)}
                        </div>
                      </td>
                      <td>
                        <StatePill type="FridgeDate" data={fridge} />
                        {moment(fridge.lastControl.createdAt).format('DD/MM/YYYY')}
                      </td>
                      <td>
                        <i className="material-icons arrow-right">keyboard_arrow_right</i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <LoadingPage />
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(FridgeList)))
