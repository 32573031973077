import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { catchError } from '../../../../../../../../../../actions'
import { api } from '../../../../../../../../../../parameters'
import LoadingPage from '../../../../../../../../../LoadingPage'
import ControlPart from './partials/ControlPart'
import SelectPart from './partials/SelectPart'

class InputSelectNewVaccine extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const { index, newVaccinesToSubmit, vaccineSelected, token } = this.props
    const { vaccine } = vaccineSelected

    newVaccinesToSubmit.push({ oldVaccine: vaccine.batch })

    if (newVaccinesToSubmit[index].control === undefined) {
      newVaccinesToSubmit[index].control = {}
    }

    if (vaccine.conservation === 0) {
      Axios.get(`${api}/api/container_vaccines/${vaccine.batch}`, {
        headers: { authorization: token }
      })
        .then(res => {
          const articleId = res.data.vaccinesBatch.vaccineArticle.id
          Axios.get(`${api}/api/container_vaccines?vaccinesBatch.vaccineArticle.id=${articleId}`, {
            headers: { authorization: token }
          })
            .then(res2 => {
              this.setState({ newVaccines: res2.data['hydra:member'], loading: false })
              M.AutoInit()
            })
            .catch(err => catchError(err.response))
        })
        .catch(err => catchError(err.response))
    } else if (vaccine.conservation === 1) {
      Axios.get(`${api}/api/fridge_vaccines/${vaccine.batch}`, {
        headers: { authorization: token }
      })
        .then(res => {
          const articleId = res.data.vaccinesBatch.vaccineArticle.id
          Axios.get(`${api}/api/fridge_vaccines?vaccinesBatch.vaccineArticle.id=${articleId}`, {
            headers: { authorization: token }
          })
            .then(res2 => {
              this.setState({ newVaccines: res2.data['hydra:member'], loading: false })
              M.AutoInit()
            })
            .catch(err => catchError(err.response))
        })
        .catch(err => catchError(err.response))
    }
  }

  handleChange(e) {
    const { name, value } = e.target
    const { disabledList, getDataFromChild, newVaccinesToSubmit, index, type } = this.props

    const parseIntValue = parseInt(value)
    const parseFloatValue = parseFloat(value)

    const addBoolToDisabledList = (bool, boolIndex) => {
      disabledList[boolIndex] = bool
      getDataFromChild('disabledList', disabledList)
    }

    if (name === 'newVaccine') {
      newVaccinesToSubmit[index].newVaccine = parseIntValue
      getDataFromChild('newVaccinesToSubmit', newVaccinesToSubmit)
    } else if (name === 'nitrogenLevel') {
      newVaccinesToSubmit[index].nitrogenLevel = parseIntValue
      getDataFromChild(name, parseIntValue)
    } else if (name === 'fridgeMin') {
      newVaccinesToSubmit[index].control.min = parseFloatValue
      getDataFromChild('newVaccinesToSubmit', newVaccinesToSubmit)
    } else if (name === 'fridgeMax') {
      newVaccinesToSubmit[index].control.max = parseFloatValue
      getDataFromChild('newVaccinesToSubmit', newVaccinesToSubmit)
    } else if (name === 'fridgeInstant') {
      newVaccinesToSubmit[index].control.instant = parseFloatValue
      getDataFromChild('newVaccinesToSubmit', newVaccinesToSubmit)
    }

    let boolToDisabledList = false

    let idx = 0
    while (boolToDisabledList === false && idx < newVaccinesToSubmit.length) {
      const newVaccineToSubmit = newVaccinesToSubmit[idx]
      if (type >= 2 && type <= 9) {
        if (
          newVaccineToSubmit.nitrogenLevel === undefined ||
          Number.isNaN(newVaccineToSubmit.nitrogenLevel) ||
          newVaccineToSubmit.oldVaccine === undefined ||
          newVaccineToSubmit.newVaccine === undefined
        ) {
          boolToDisabledList = true
        }
      } else if (type === 12) {
        if (
          newVaccineToSubmit.control &&
          (newVaccineToSubmit.control.min === undefined ||
            newVaccineToSubmit.control.max === undefined ||
            newVaccineToSubmit.control.instant === undefined ||
            Number.isNaN(newVaccineToSubmit.control.min) ||
            Number.isNaN(newVaccineToSubmit.control.max) ||
            Number.isNaN(newVaccineToSubmit.control.instant) ||
            newVaccineToSubmit.oldVaccine === undefined ||
            newVaccineToSubmit.newVaccine === undefined)
        ) {
          boolToDisabledList = true
        }
      } else if (type > 12) {
        if (
          newVaccineToSubmit.oldVaccine === undefined ||
          newVaccineToSubmit.newVaccine === undefined
        ) {
          boolToDisabledList = true
        }
      }
      if (boolToDisabledList === true) {
        addBoolToDisabledList(true, idx)
      } else if (boolToDisabledList === false) {
        addBoolToDisabledList(false, idx)
      }
      idx += 1
    }
  }

  render() {
    const { type, vaccineSelected } = this.props
    const { loading, newVaccines } = this.state

    const { vaccine } = vaccineSelected

    if (loading) {
      return <LoadingPage />
    }

    return (
      <div className="row">
        <div className="col s12 input">
          <div className="col s6">
            <SelectPart newVaccines={newVaccines} handleChange={this.handleChange} />
          </div>
          <div className="col s6">
            <ControlPart
              conservation={vaccine.conservation}
              type={type}
              handleChange={this.handleChange}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(InputSelectNewVaccine))
