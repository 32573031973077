import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import { goBack } from '../../globalAction'
import BlockPart from '../BlockPart/BlockPart'
import NoBlockPart from '../NoBlockPart/NoBlockPart'
import ChangesDisplayNotification from './partials/ChangesDisplayNotification'

class EditParamStock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    M.AutoInit()
  }

  render() {
    const { data, intl, history } = this.props
    const { type, history: hist } = data
    const { action } = hist
    const { changes } = action
    const { channels, volume, provider, conservation, article, description, productType } = changes

    const pathGoBack = '/parameters/parameter-history'

    return (
      <div className="Container stockHistory">
        <div>
          <div className="pageTitle pageHeader row">
            <div className="col s12">
              <div
                role="button"
                className="flexInline pointer"
                onClick={() => goBack(pathGoBack, history)}
                onKeyPress={() => goBack(pathGoBack, history)}
                tabIndex={0}
              >
                {' '}
                <div className="goBackBtn">
                  <i role="button" tabIndex="0" className="material-icons">
                    chevron_left
                  </i>
                </div>
                <h5>
                  <span>
                    {intl.formatMessage({
                      id: `app.label.parameters.action.${type}`
                    })}
                  </span>
                </h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <div className="row">
                <div className="row form">
                  <div className="col s12">
                    <div className="row">
                      <div className="col s12">
                        <div className="row">
                          <h4>
                            {`${intl.formatMessage({
                              id: `app.label.productType.${(productType && productType[0]) ||
                                action.productType}`
                            })} ${(provider && provider[0]) || action.provider} ${(volume &&
                              volume[0]) ||
                              action.volume ||
                              ''}`}
                          </h4>
                          <h5>
                            <span>{intl.formatMessage({ id: 'app.label.modificationDone' })}</span>
                          </h5>
                        </div>
                        <div className="row params">
                          <div className="col s12">
                            <div className="row">
                              <div className="col s12">
                                <div className="row">
                                  <div className="col s6 input">
                                    <div className="row">
                                      <div className="col s12">
                                        <label
                                          htmlFor="congruentPartTempBetweenMin"
                                          className="labelParams"
                                        >
                                          {intl.formatMessage({ id: 'app.label.before' })}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="row input">
                                      {(productType !== undefined ||
                                        action.productType !== undefined) && (
                                        <div className="col s6">
                                          <label htmlFor="noBlockPartAlert" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.productType' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>
                                            {intl.formatMessage({
                                              id: `app.label.productType.${(productType &&
                                                productType[0]) ||
                                                action.productType}`
                                            })}
                                          </span>
                                        </div>
                                      )}
                                      {(provider !== undefined ||
                                        action.provider !== undefined) && (
                                        <div className="col s6">
                                          <label htmlFor="noBlockPartAlert" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.provider' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>
                                            {(provider && provider[0]) || action.provider}
                                          </span>
                                        </div>
                                      )}
                                    </div>

                                    <div className="row input">
                                      {(volume || action.volume) && (
                                        <div className="col s6">
                                          <label htmlFor="noBlockPartAlert" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.volume' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>{(volume && volume[0]) || action.volume}</span>
                                        </div>
                                      )}
                                      {(conservation || action.conservation) && (
                                        <div className="col s6">
                                          <label htmlFor="noBlockPartAlert" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.conservation' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>
                                            {intl.formatMessage({
                                              id: `app.label.conservationMethod.${(conservation &&
                                                conservation[0]) ||
                                                action.conservation}`
                                            })}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    {(article || action.article) && (
                                      <div className="row input">
                                        <div className="col s6">
                                          <label htmlFor="noBlockPartAlert" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.article' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>{(article && article[0]) || action.article}</span>
                                        </div>
                                      </div>
                                    )}
                                    {(description || action.description) && (
                                      <div className="row input">
                                        <div className="col s12">
                                          <label htmlFor="noBlockPartAlert" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.description' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>
                                            {(description && description[0]) || action.description}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="col s6">
                                    <div className="row input">
                                      <div className="col s12">
                                        <label
                                          htmlFor="congruentPartTempBetweenMin"
                                          className="labelParams"
                                        >
                                          {intl.formatMessage({ id: 'app.label.after' })}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="row input">
                                      {action.productType !== undefined && (
                                        <div className="col s6">
                                          <label htmlFor="noBlockPartAlert" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.productType' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>
                                            {intl.formatMessage({
                                              id: `app.label.productType.${(productType &&
                                                productType[1].toString()) ||
                                                action.productType.toString()}`
                                            })}
                                          </span>
                                        </div>
                                      )}
                                      {action.provider && (
                                        <div className="col s6">
                                          <label htmlFor="noBlockPartAlert" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.provider' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>
                                            {(provider && provider[1]) || action.provider}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    {(action.volume || action.conservation) && (
                                      <div className="row input">
                                        {action.volume && (
                                          <div className="col s6">
                                            <label
                                              htmlFor="noBlockPartAlert"
                                              className="labelParams"
                                            >
                                              {intl.formatMessage({ id: 'app.label.volume' })}
                                              &nbsp;:&nbsp;
                                            </label>
                                            <span>{(volume && volume[1]) || action.volume}</span>
                                          </div>
                                        )}
                                        {action.conservation !== undefined &&
                                          action.conservation !== null && (
                                            <div className="col s6">
                                              <label
                                                htmlFor="noBlockPartAlert"
                                                className="labelParams"
                                              >
                                                {intl.formatMessage({
                                                  id: 'app.label.conservation'
                                                })}
                                                &nbsp;:&nbsp;
                                              </label>
                                              <span>
                                                {intl.formatMessage({
                                                  id: `app.label.conservationMethod.${(conservation &&
                                                    conservation[1].toString()) ||
                                                    action.conservation.toString()}`
                                                })}
                                              </span>
                                            </div>
                                          )}
                                      </div>
                                    )}
                                    <div className="row input">
                                      {action.article && (
                                        <div className="col s6">
                                          <label htmlFor="noBlockPartAlert" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.article' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>{(article && article[1]) || action.article}</span>
                                        </div>
                                      )}
                                    </div>
                                    <div className="row input">
                                      {action.description && (
                                        <div className="col s12">
                                          <label htmlFor="noBlockPartAlert" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.description' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>
                                            {(description && description[1]) || action.description}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="divider greyDivider" />
                                </div>
                              </div>
                            </div>
                            {changes && changes.nonBlockingAlert && (
                              <div className="row">
                                <div className="col s12">
                                  <NoBlockPart
                                    productType={productType}
                                    action={action}
                                    alertNumber="1"
                                    history
                                  />
                                </div>
                              </div>
                            )}
                            {changes && changes.blockingAlert && (
                              <div className="row">
                                <div className="col s12">
                                  <BlockPart
                                    productType={productType}
                                    action={action}
                                    editParamProducts
                                    history
                                  />
                                </div>
                              </div>
                            )}
                            {changes && changes.nonBlockingAlert && (
                              <div className="row">
                                <div className="col s12">
                                  <NoBlockPart
                                    productType={productType}
                                    action={action}
                                    alertNumber="2"
                                    history
                                  />
                                </div>
                              </div>
                            )}
                            {channels !== undefined && (
                              <div className="row">
                                <div className="col s12">
                                  <div className="row">
                                    <div className="col s12">
                                      <p className="greyTitle">
                                        {intl.formatMessage({ id: 'app.label.notification' })}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col s12">
                                      <div className="row">
                                        <ChangesDisplayNotification
                                          value1={channels[0]}
                                          value2={channels[1]}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(withRouter(EditParamStock))
