import React from 'react'
import { injectIntl } from 'react-intl'

const ProgressBar = props => {
  const { actualPart, intl, onlyOneLabel, parts, partsTotal, steps, vaccineType } = props
  const progress =
    steps === undefined
      ? (actualPart / partsTotal) * 100
      : (steps.indexOf(actualPart) / partsTotal - 1 / partsTotal) * 100
  const partsContent = []
  const colClass = `s${12 / partsTotal}`

  let label = 'injectableVaccine'

  if (vaccineType === 1) {
    label = 'thinDropsVaccines'
  } else if (vaccineType === 2) {
    label = 'bigDropsVaccines'
  }

  if (onlyOneLabel) {
    partsContent.push(
      <div className="col s12">
        {intl.formatMessage({ id: `app.label.${label}` })} {actualPart} / {partsTotal}
      </div>
    )
  } else {
    for (let i = 1; i <= partsTotal; i++) {
      partsContent.push(
        <div
          className={
            steps.indexOf(actualPart) === i ? `col ${colClass} blue-text` : `col ${colClass}`
          }
        >
          {intl.formatMessage({ id: `app.label.${parts[i - 1]}` })}
        </div>
      )
    }
  }

  return (
    <div>
      <div className="progress">
        <div className="determinate" style={{ width: `${progress}%` }} />
      </div>
      <div className="row">{partsContent}</div>
    </div>
  )
}

export default injectIntl(ProgressBar)
