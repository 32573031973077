import React, { createContext, useState, useEffect } from 'react'
import fetchItems from './fetchItems'
import getLabelByKey from './getLabelByKey'

const ProductsForOrderContext = createContext({
  productsForOrders: [],
  getProductForOrderLabelByKey: () => {},
  fetchProductsForOrder: () => {}
})

export const ProductsForOrderProvider = ({ children }) => {
  const [productsForOrder, setProductsForOrder] = useState([])

  const fetchProductsForOrder = () => {
    fetchItems('products', setProductsForOrder)
  }

  useEffect(() => {
    fetchProductsForOrder()
  }, [])

  const getProductForOrderLabelByKey = key => getLabelByKey(productsForOrder, key)

  return (
    <ProductsForOrderContext.Provider
      value={{
        productsForOrder,
        getProductForOrderLabelByKey,
        fetchProductsForOrder
      }}
    >
      {children}
    </ProductsForOrderContext.Provider>
  )
}

export default ProductsForOrderContext
