import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import M from 'materialize-css'
import { catchError } from '../../../actions'
import AsyncLoader from '../../../components/AsyncLoader'
import LoadingPage from '../../../components/LoadingPage'
import { api, i18n } from '../../../parameters'
import icons from '../../../assets/icons/icons'
import { ExportFile } from '../../../components/globalAction'
import productTypes from '../../../config/productTypes'
import { RefreshBtn, TdLink } from '../Components'

class List extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      url: `${api}/api/batch_traceabilities`,
      loading: false,
      elementList: [],
      contentStatus: false,
      totalItems: 0,
      batchNumber: null,
      batchType: null,
      search: null,
      startDate: null,
      endDate: null,
      page: 1
    }
    this.loadMore = this.loadMore.bind(this)
  }

  componentDidMount() {
    const { location } = this.props
    const urlSearch = new URLSearchParams(location.search)
    const batchNumber = urlSearch.get('batchNumber')
    const batchType = urlSearch.get('batchType')
    const search =
      urlSearch.get('provider') ??
      urlSearch.get('speciality') ??
      urlSearch.get('speciality..or..provider')
    const startDate = urlSearch.get('startDate')
    const endDate = urlSearch.get('endDate')
    this.setState(
      {
        batchNumber,
        batchType,
        search,
        startDate: startDate ? new Date(startDate) : null,
        endDate: endDate ? new Date(endDate) : null
      },
      () => {
        M.AutoInit()
        M.Datepicker.init(document.getElementById('startDate'), {
          autoClose: true,
          format: 'dd/mm/yyyy',
          selectMonth: true,
          firstDay: 1,
          i18n,
          defaultDate: startDate ? new Date(startDate) : new Date(),
          setDefaultDate: startDate,
          onSelect: dateSelected => {
            const { instanceStartDate } = this.state
            instanceStartDate.gotoDate(new Date(dateSelected))
            this.setState({ startDate: dateSelected })
            this.fetchEntries()
          }
        })
        M.Datepicker.init(document.getElementById('endDate'), {
          autoClose: true,
          format: 'dd/mm/yyyy',
          selectMonth: true,
          firstDay: 1,
          i18n,
          defaultDate: endDate ? new Date(endDate) : new Date(),
          setDefaultDate: endDate,
          onSelect: dateSelected => {
            const { instanceEndDate } = this.state
            instanceEndDate.gotoDate(new Date(dateSelected))
            this.setState({ endDate: dateSelected })
            this.fetchEntries()
          }
        })
        const instanceStartDate = M.Datepicker.getInstance(document.getElementById('startDate'))
        this.setState({ instanceStartDate })
        const instanceEndDate = M.Datepicker.getInstance(document.getElementById('endDate'))
        this.setState({ instanceEndDate })
        document.addEventListener('scroll', this.loadMore)
        this.fetchEntries()
      }
    )
  }

  loadMore() {
    const { page, totalItems } = this.state
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (totalItems > page * 30) {
        this.setState({ page: page + 1 }, () => this.fetchEntries(true))
      }
    }
  }

  fetchEntries(loadMore) {
    const { token, intl, location, history } = this.props
    const {
      url,
      batchNumber,
      batchType,
      search,
      startDate,
      endDate,
      // eslint-disable-next-line no-unused-vars
      page,
      elementList
    } = this.state
    this.setState({ loading: true })
    if (startDate !== null && endDate !== null) {
      // const pageQueryParam = `page=${page}`
      const pageQueryParam = ``
      const periodQueryParam = `period=${moment(startDate).format('YYYY-MM-DD')}..${moment(
        endDate
      ).format('YYYY-MM-DD')}`
      const batchNumberQueryParam = batchNumber ? `&batchNumber=${batchNumber}` : ''
      const batchTypeQueryParam = batchType ? `&batchType=${batchType}` : ''
      const searchQueryParam = search
        ? `&${
            batchType !== null
              ? batchType > 0
                ? 'provider'
                : 'speciality'
              : 'speciality..or..provider'
          }=${search}`
        : ''
      const newUrlQuery = `startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(
        endDate
      ).format('YYYY-MM-DD')}${batchNumberQueryParam}${batchTypeQueryParam}${searchQueryParam}`
      const poppedUrl = location.pathname.split('?').pop()
      const currentUrlQuery = poppedUrl !== location.pathname ? poppedUrl : ''
      if (newUrlQuery !== currentUrlQuery) {
        history.push({
          pathname: `/traceability/vaccine_or_product/list/?${newUrlQuery}`
        })
      }
      Axios.get(
        `${url}?${pageQueryParam}${periodQueryParam}${batchNumberQueryParam}${batchTypeQueryParam}${searchQueryParam}`,
        {
          headers: { Authorization: token }
        }
      )
        .then(res => {
          const machines = [
            { id: 0, label: 'app.label.vaccine_or_product_machine_injection' },
            { id: 1, label: 'app.label.vaccine_or_product_machine_bind' },
            { id: 2, label: 'app.label.vaccine_or_product_machine_cocc' }
          ]

          function getArticleBasedOnBatchType(item) {
            let article = intl.formatMessage({ id: 'app.label.traceability_unknown' })
            if (item.batchType === productTypes.VACCINES_BATCH) {
              article = item.article ?? intl.formatMessage({ id: 'app.label.traceability_unknown' })
            } else if (item.batchType === productTypes.DYE_BATCH) {
              article = item.color ?? intl.formatMessage({ id: 'app.label.traceability_unknown' })
            } else if (item.batchType === productTypes.PRODUCT_BAG) {
              article = item.volume
                ? `${item.volume} ${intl.formatMessage({ id: 'app.unit.volume' })}`
                : intl.formatMessage({ id: 'app.label.traceability_unknown' })
            }
            return article
          }

          const unknownValue = intl.formatMessage({ id: 'app.label.traceability_unknown' })
          const mappedItems = [...res.data['hydra:member']].map(i => ({
            id: i.id,
            batchNumber: i.batchNumber ?? unknownValue,
            providerOrSpecialty: i.provider ?? i.speciality ?? unknownValue,
            administration: machines.find(type => type.id?.toString() === i.administration)
              ? intl.formatMessage({
                  id: machines.find(type => type.id?.toString() === i.administration).label
                })
              : unknownValue,
            article: getArticleBasedOnBatchType(i),
            expirationDate: i.expirationDate
              ? moment(i.expirationDate).format('DD/MM/YYYY')
              : unknownValue
          }))
          this.setState({
            loading: false,
            elementList: loadMore ? elementList.concat(mappedItems) : mappedItems,
            totalItems: res.data['hydra:totalItems'],
            contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult',
            page: 1
          })
        })
        .catch(err => catchError(err))
    } else {
      this.setState({
        loading: false,
        elementList: [],
        totalItems: 0,
        contentStatus: false
      })
    }
  }

  render() {
    const { intl, role, token, history } = this.props
    // eslint-disable-next-line no-unused-vars
    const {
      loading,
      elementList,
      contentStatus,
      batchType,
      batchNumber,
      search,
      startDate,
      endDate
    } = this.state
    const batchTypes = [
      { id: null, label: 'app.label.vaccine_or_product_filter_batch_type' },
      { id: 0, label: 'app.filter.stockProductsType.0' },
      { id: 3, label: 'app.filter.stockProductsType.3' },
      { id: 4, label: 'app.filter.stockProductsType.4' },
      { id: 5, label: 'app.filter.stockProductsType.5' }
    ]
    return (
      <div className="row Container stockHistory">
        <div className="col s12 Content paramsContent traceability">
          <div className="row pageHeader pageTitle">
            <h5 className="col m10">
              {intl.formatMessage({ id: 'app.label.traceability_vaccine_or_product' })}
            </h5>
            {startDate !== null && endDate !== null ? (
              <>
                <div className="col m1">
                  <RefreshBtn
                    redirect={() => {
                      history.push({
                        pathname: `/traceability/vaccine_or_product/list`
                      })
                    }}
                  />
                </div>
                <div className="col m1">
                  <ExportFile
                    data={{ role, token }}
                    url={`/api/batch_traceabilities/export/xlsx?startAt=${moment(startDate).format(
                      'YYYY-MM-DD'
                    )}&endAt=${moment(endDate).format('YYYY-MM-DD')}${
                      batchType ? `&batchType=${batchType}` : ''
                    }${search ? `&speciality__or__provider=${search}` : ''}`}
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
          <div>
            <div className="filters">
              <div className="input">
                <div className="filterDate">
                  <input
                    type="text"
                    className="datepicker"
                    id="startDate"
                    name="startDate"
                    readOnly
                    placeholder={intl.formatMessage({ id: 'app.label.traceability_start_date' })}
                  />
                  <img src={icons.blueCalendar} alt="calendar icon" />
                </div>
              </div>
              <div className="input">
                <div className="filterDate">
                  <input
                    type="text"
                    className="datepicker"
                    id="endDate"
                    name="endDate"
                    readOnly
                    placeholder={intl.formatMessage({ id: 'app.label.traceability_end_date' })}
                  />
                  <img src={icons.blueCalendar} alt="calendar icon" />
                </div>
              </div>
              <div className="input">
                <input
                  placeholder={intl.formatMessage({
                    id: 'app.label.vaccine_or_product_filter_batch_number'
                  })}
                  type="text"
                  className="batchNumber"
                  value={batchNumber}
                  onChange={e => {
                    const { value } = e.target
                    this.setState({ batchNumber: value }, () => {
                      this.fetchEntries()
                    })
                  }}
                />
              </div>
              <div className="input">
                <select
                  className="select-wrapper"
                  name="batchType"
                  value={batchType}
                  onChange={e => {
                    const { value } = e.target
                    this.setState(
                      { search: '', batchType: !Number.isNaN(Number(value)) ? value : null },
                      () => {
                        this.fetchEntries()
                      }
                    )
                  }}
                >
                  {batchTypes.map(entry => (
                    <option key={entry.id} value={entry.id}>
                      {intl.formatMessage({ id: entry.label })}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input">
                <input
                  placeholder={intl.formatMessage({
                    id:
                      batchType === null
                        ? 'app.label.vaccine_or_product_provider_or_specialty'
                        : batchType > 0
                        ? 'app.label.vaccine_or_product_provider'
                        : 'app.label.vaccine_or_product_specialty'
                  })}
                  value={search}
                  type="text"
                  className="search"
                  onChange={e => {
                    const { value } = e.target
                    this.setState({ search: value }, () => {
                      this.fetchEntries()
                    })
                  }}
                />
              </div>
            </div>
          </div>
          {!loading ? (
            <div className="row">
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      {intl.formatMessage({ id: 'app.label.vaccine_or_product_batch_number' })}
                    </th>
                    <th>
                      {intl.formatMessage({
                        id:
                          batchType === null
                            ? 'app.label.vaccine_or_product_provider_or_specialty'
                            : batchType > 0
                            ? 'app.label.vaccine_or_product_provider'
                            : 'app.label.vaccine_or_product_specialty'
                      })}
                    </th>
                    <th>
                      {intl.formatMessage({ id: 'app.label.vaccine_or_product_administration' })}
                    </th>
                    <th>{intl.formatMessage({ id: 'app.label.vaccine_or_product_article' })}</th>
                    <th>
                      {intl.formatMessage({ id: 'app.label.vaccine_or_product_expiry_date' })}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {elementList.map(item => {
                    const url = `/traceability/vaccine_or_product/single/${item.id}`
                    return (
                      <tr key={item.id} className="pointer">
                        <TdLink url={url}>{item.batchNumber}</TdLink>
                        <TdLink url={url}>{item.providerOrSpecialty}</TdLink>
                        <TdLink url={url}>{item.administration}</TdLink>
                        <TdLink url={url}>{item.article}</TdLink>
                        <TdLink url={url}>{item.expirationDate}</TdLink>
                      </tr>
                    )
                  })}
                </tbody>
                <tfoot className="center">
                  <tr>
                    <th colSpan="5">
                      <AsyncLoader status={contentStatus} />
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          ) : (
            <LoadingPage />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(List)))
