import { Body, Cell, Head, HeaderCell, HeaderRow, Row, Table } from '@zendeskgarden/react-tables'
import '@zendeskgarden/react-tables/dist/styles.css'
import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import icons from '../../../assets/icons/icons'
import roles from '../../../config/userRoles.json'
import { PrestationsContext, ProductsForOrderContext } from '../../../context/contexts'
import { api, i18n } from '../../../parameters'

class DragAndDropPlanningTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: this.getItems()
    }
    this.onDragEnd = this.onDragEnd.bind(this)
    this.getItems = this.getItems.bind(this)
    moment.locale('fr', { weekdaysMin: i18n.weekdaysAbbrev })
  }

  onDragEnd(result) {
    const { setGroupsToParent, token } = this.props
    const { items } = this.state
    const parentItems = []

    if (!result.destination) {
      return
    }

    const resultItems = this.reorder(items, result.source.index, result.destination.index)

    Axios.patch(
      `${api}/api/planning_order_groups_sequences`,
      { groups: resultItems },
      {
        headers: { Authorization: token }
      }
    ).then()

    resultItems.forEach((item, i) => {
      parentItems[i] = item.group
    })

    setGroupsToParent(parentItems)
    this.setState({ items: resultItems }, () => {
      document.getElementById(result.draggableId).focus()
    })
  }

  getItems() {
    const { groups } = this.props

    return Array.from(groups, group => ({
      id: `item-${group.sequence}`,
      group
    }))
  }

  setHatchingDateColor(hatchingDate) {
    switch (moment(hatchingDate).day()) {
      case 1:
        return 'monday'
      case 2:
        return 'tuesday'
      case 3:
        return 'wednesday'
      case 4:
        return 'thursday'
      case 5:
        return 'friday'
      default:
        return ''
    }
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  render() {
    const { intl, role, selectedDay, selectedWeek } = this.props
    const { items } = this.state
    const { ROLE_HATCHING_RESP, ROLE_HATCH_RESP, ROLE_VET } = roles

    return (
      <PrestationsContext.Consumer>
        {prestationsContext => (
          <ProductsForOrderContext.Consumer>
            {productsForOrderContext => (
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Table className="droppableTable">
                  <Head>
                    <HeaderRow className="tableTitle">
                      <HeaderCell width="100%">
                        {role.includes(ROLE_HATCHING_RESP) ||
                        role.includes(ROLE_HATCH_RESP) ||
                        role.includes(ROLE_VET)
                          ? `${intl.formatMessage({
                              id: 'app.label.week'
                            })} ${selectedWeek}`
                          : `${intl.formatMessage({
                              id: 'app.label.day'
                            })} ${moment(selectedDay).format('DD/MM')}`}
                      </HeaderCell>
                    </HeaderRow>
                    <HeaderRow className="tableHeader">
                      <HeaderCell width="7%">
                        {intl.formatMessage({ id: 'app.label.orderNumber' })}
                      </HeaderCell>
                      <HeaderCell width="7%">
                        {intl.formatMessage({ id: 'app.label.hatchingDay' })}
                      </HeaderCell>
                      <HeaderCell width="5%">
                        {intl.formatMessage({ id: 'app.label.product' })}
                      </HeaderCell>
                      <HeaderCell width="11%">
                        {intl.formatMessage({ id: 'app.label.chickQuantity' })}
                      </HeaderCell>
                      <HeaderCell width="17.5%">
                        {intl.formatMessage({ id: 'app.label.injectedVaccine' })}
                      </HeaderCell>
                      <HeaderCell width="17.5%">
                        {intl.formatMessage({ id: 'app.label.fineDrops' })}
                      </HeaderCell>
                      <HeaderCell width="17.5%">
                        {intl.formatMessage({ id: 'app.label.bigDrops' })}
                      </HeaderCell>
                      <HeaderCell width="17.5%">
                        {intl.formatMessage({ id: 'app.label.interventions' })}
                      </HeaderCell>
                      <HeaderCell minimum />
                    </HeaderRow>
                  </Head>
                  <Droppable droppableId="droppable">
                    {(provided, droppableSnapshot) => (
                      <Body
                        ref={provided.innerRef}
                        isDraggingOver={droppableSnapshot.isDraggingOver}
                      >
                        {items.map((item, index) => {
                          const { group, id } = item

                          return (
                            <Draggable key={id} draggableId={id} index={index}>
                              {(prov, snapshot) => (
                                <Row
                                  className="tableRow"
                                  ref={prov.innerRef}
                                  isDragging={snapshot.isDragging}
                                  isDraggingOver={droppableSnapshot.isDraggingOver}
                                  hovered={snapshot.isDragging}
                                  focused={
                                    droppableSnapshot.isDraggingOver
                                      ? snapshot.isDragging
                                      : undefined
                                  }
                                  {...prov.draggableProps.style}
                                  {...prov.draggableProps}
                                >
                                  <Cell
                                    isDragging={snapshot.isDragging}
                                    width="7%"
                                    className={`bold ${this.setHatchingDateColor(
                                      group.hatchingDate
                                    )}`}
                                  >
                                    {group.number}
                                  </Cell>
                                  <Cell isDragging={snapshot.isDragging} width="7%">
                                    {moment(group.hatchingDate).format('dd DD/MM')}
                                  </Cell>
                                  <Cell isDragging={snapshot.isDragging} width="5%">
                                    {productsForOrderContext.getProductForOrderLabelByKey(
                                      group.product
                                    )}
                                  </Cell>
                                  <Cell isDragging={snapshot.isDragging} width="11%">
                                    {group.totalChicks}
                                  </Cell>
                                  <Cell isDragging={snapshot.isDragging} width="17.5%">
                                    <ul>
                                      {group.vaccinesInjectable &&
                                        group.vaccinesInjectable.map((vaccine, i) => (
                                          <li key={vaccine.id}>
                                            {`${vaccine} ${intl.formatMessage({
                                              id: `app.label.doses.${group.vaccinesInjectableDosesType[i]}`
                                            })}`}
                                          </li>
                                        ))}
                                    </ul>
                                  </Cell>
                                  <Cell isDragging={snapshot.isDragging} width="17.5%">
                                    <ul>
                                      {group.vaccinesThinDrops &&
                                        group.vaccinesThinDrops.map((vaccine, i) => (
                                          <li key={vaccine.id}>
                                            {`${vaccine} ${intl.formatMessage({
                                              id: `app.label.doses.${group.vaccinesThinDropsDosesType[i]}`
                                            })}`}
                                          </li>
                                        ))}
                                    </ul>
                                  </Cell>
                                  <Cell isDragging={snapshot.isDragging} width="17.5%">
                                    <ul>
                                      {group.vaccinesBigDrops &&
                                        group.vaccinesBigDrops.map((vaccine, i) => (
                                          <li key={vaccine.id}>
                                            {`${vaccine} ${intl.formatMessage({
                                              id: `app.label.doses.${group.vaccinesBigDropsDosesType[i]}`
                                            })}`}
                                          </li>
                                        ))}
                                    </ul>
                                  </Cell>
                                  <Cell isDragging={snapshot.isDragging} width="17.5%">
                                    <ul>
                                      {group.prestations &&
                                        group.prestations.map(prestation => (
                                          <li key={prestation}>
                                            {prestationsContext.getPrestationLabelByKey(prestation)}
                                          </li>
                                        ))}
                                    </ul>
                                  </Cell>
                                  <Cell minimum className="gripIcon">
                                    <div id={id} {...prov.dragHandleProps}>
                                      <img
                                        src={icons.blueGrip}
                                        alt="blue grip icon"
                                        height="100%"
                                      />
                                    </div>
                                  </Cell>
                                </Row>
                              )}
                            </Draggable>
                          )
                        })}
                        {provided.placeholder}
                      </Body>
                    )}
                  </Droppable>
                </Table>
              </DragDropContext>
            )}
          </ProductsForOrderContext.Consumer>
        )}
      </PrestationsContext.Consumer>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(DragAndDropPlanningTable))
