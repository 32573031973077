import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { catchError } from '../../../actions'
import icons from '../../../assets/icons/icons'
import params from '../../../parameters'
import LoadingPage from '../../LoadingPage'
import ConformitiesListView from '../ConformitiesListView'

class VaccinationParamsListPart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      parameters: [],
      loading: true
    }
  }

  componentDidMount() {
    const { token } = this.props

    Axios.get(`${params.api}/api/equipment_parameters?equipmentType=2`, {
      headers: {
        Authorization: token
      }
    })
      .then(res => {
        this.setState({ parameters: res.data['hydra:member'], loading: false })
      })
      .catch(err => catchError(err.response))
  }

  setUnit(paramId) {
    const { intl } = this.props
    let unit = ''

    switch (paramId) {
      case 6:
        unit = intl.formatMessage({ id: 'app.unit.bathTemp' })
        break
      case 7:
        unit = intl.formatMessage({ id: 'app.unit.needleVolume' })
        break
      default:
        unit = intl.formatMessage({ id: 'app.unit.nozzleVolume' })
    }

    return unit
  }

  render() {
    const { intl } = this.props
    const { loading, parameters } = this.state

    if (loading === true) {
      return <LoadingPage />
    }

    return (
      <div>
        <div className="row">
          <div className="col s12">
            {parameters.map(param => (
              <div className="card paramListItem" key={`param-${param.id}`}>
                <div className="paramHeader">
                  <div className="paramTitle">
                    {intl.formatMessage({ id: `app.title.equipmentParam${param.paramId}` })}
                  </div>
                  <Link to={`/parameters/equipment/edit/${param.id}`}>
                    <img src={icons.blackPen} alt="black pen icon" />
                  </Link>
                </div>
                {param.paramId !== 10 && (
                  <div>
                    <div className="divider greyDivider" />
                    <div className="paramListItemContent">
                      <ConformitiesListView
                        conformity={param.conformityAlert.value}
                        nonBlocking={param.nonBlockingAlert.value}
                        blocking={param.blockingAlert.value}
                        blockingMinCongruent={param.paramId === 7}
                        paramId={param.paramId}
                        unit={this.setUnit(param.paramId)}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(VaccinationParamsListPart))
