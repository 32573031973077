import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import React from 'react'
import icons from '../../../assets/icons/icons'

export default function OrderReceivedMain() {
  const items = [
    { icon: icons.frozenVaccine, name: 'frozenVaccine', path: 'frozen-vaccine' },
    { icon: icons.refrigeratedVaccine, name: 'refrigeratedVaccine', path: 'refrigerated-vaccine' },
    { icon: icons.containerReserve, name: 'containerReserve', path: 'container-reserve' },
    { icon: icons.productBag, name: 'productBag', path: 'product-bag' },
    { icon: icons.dye, name: 'dye', path: 'dye' },
    { icon: icons.diluent, name: 'diluent', path: 'diluent' },
    { icon: icons.nitrogen, name: 'nitrogen', path: 'nitrogen' }
  ]
  return (
    <div className="Container">
      <div className="row">
        <div className="col s12">
          <div className="row">
            {items.map(item => (
              <div className="col m4 stockManagementItem" key={item.name}>
                <Link
                  to={{
                    pathname: `/stocks/order-received/${item.path}`,
                    state: { item: item.name }
                  }}
                >
                  <div className="card center">
                    <img src={item.icon} alt="icon container" className="responsive-img" />
                    <p>
                      <FormattedMessage id={`app.part.${item.name}`} />
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
