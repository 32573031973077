import React from 'react'
import { FormattedMessage } from 'react-intl'

export default function FridgeBlock(props) {
  const { fridge } = props
  return (
    <div className="row">
      <div className="col s12">
        <div className="row">
          <h5>
            <FormattedMessage id="app.label.fridge" />
            &nbsp;
            {fridge.name}
          </h5>
        </div>
        <div className="row">
          <div className="col s4">
            <p>
              <span className="label">
                <FormattedMessage id="app.label.instantTemperature" />
                &nbsp;:&nbsp;
              </span>
              {fridge.instantTemperature}
              <FormattedMessage id="app.unit.fridgeTemp" />
            </p>
          </div>
          <div className="col s4">
            <p>
              <span className="label">
                <FormattedMessage id="app.label.minTemperature" />
                &nbsp;:&nbsp;
              </span>
              {fridge.minTemperature}
              <FormattedMessage id="app.unit.fridgeTemp" />
            </p>
          </div>
          <div className="col s4">
            <p>
              <span className="label">
                <FormattedMessage id="app.label.maxTemperature" />
                &nbsp;:&nbsp;
              </span>
              {fridge.maxTemperature}
              <FormattedMessage id="app.unit.fridgeTemp" />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
