import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { containerProviders } from '../../../../parameters'

class ReserveContainerForm extends React.Component {
  componentDidMount() {
    M.AutoInit()
  }

  render() {
    const {
      handleChange,
      capacities,
      selectProvider,
      selectedProvider,
      containerNumber,
      intl,
      parentState
    } = this.props
    return (
      <div className="row">
        <div className="col s12">
          <div className="form frozenVaccine">
            <div className="row">
              <div className="col s12">
                <div className="row col s12">
                  <div className="col s6 input">
                    <label htmlFor="provider">
                      {intl.formatMessage({ id: 'app.label.provider' })}
                    </label>
                    <select
                      className="select-wrapper"
                      defaultValue="0"
                      name="provider"
                      onChange={selectProvider}
                      required
                    >
                      <option value="0" disabled>
                        {intl.formatMessage({ id: 'app.label.selectContainer' })}
                      </option>
                      {containerProviders.map((provider, i) => (
                        <option key={provider.name} value={i}>
                          {provider.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col s6 input">
                    {capacities && (
                      <div>
                        <label htmlFor="capacity">
                          {intl.formatMessage({ id: 'app.label.containerCapacity' })}
                        </label>
                        <select
                          className="select-wrapper"
                          defaultValue="0"
                          name="capacity"
                          onChange={handleChange}
                          disabled={!selectedProvider}
                        >
                          <option value="0" disabled>
                            {intl.formatMessage({ id: 'app.label.selectCapacity' })}
                          </option>
                          {capacities.map(capacity => (
                            <option key={capacity} value={capacity}>
                              {capacity}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {!capacities && (
                      <div>
                        <label>Materialize Select</label>
                        <input type="text" name="capacity" onChange={handleChange} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="row col s12">
                  <div className="col s6 input">
                    <label>{intl.formatMessage({ id: 'app.equipment.containerNumber' })}</label>
                    <input
                      type="number"
                      value={containerNumber}
                      name="containerNumber"
                      minLength="3"
                      maxLength="4"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col s6 input">
                    <p className="labelCustom">
                      {intl.formatMessage({ id: 'app.label.generalBoxStatus' })}
                    </p>
                    <div className="switch">
                      <label>
                        {intl.formatMessage({ id: 'app.label.conformity.false' })}
                        <input
                          type="checkbox"
                          name="boxState"
                          onChange={handleChange}
                          checked={parentState.boxState}
                        />
                        <span className="lever" />
                        {intl.formatMessage({ id: 'app.label.conformity.true' })}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row col s12">
                  <div className="col s6 input">
                    <p className="labelCustom">
                      {intl.formatMessage({ id: 'app.label.generalState' })}
                    </p>
                    <div className="switch">
                      <label>
                        {intl.formatMessage({ id: 'app.label.conformity.false' })}
                        <input
                          type="checkbox"
                          name="containerState"
                          onChange={handleChange}
                          checked={parentState.containerState}
                        />
                        <span className="lever" />
                        {intl.formatMessage({ id: 'app.label.conformity.true' })}
                      </label>
                    </div>
                  </div>
                  <div className="col s6 input">
                    <label>{intl.formatMessage({ id: 'app.label.nitrogenLevel' })}</label>
                    <input
                      type="number"
                      min={0}
                      name="nitrogenLevel"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="row col s12">
                  <div className="input  col s6">
                    <p className="labelCustom">
                      {intl.formatMessage({ id: 'app.label.containerDesinfected' })}
                    </p>
                    <div className="switch">
                      <label>
                        {intl.formatMessage({ id: 'app.label.no' })}
                        <input
                          type="checkbox"
                          name="disinfected"
                          onChange={handleChange}
                          checked={parentState.disinfected}
                        />
                        <span className="lever" />
                        {intl.formatMessage({ id: 'app.label.yes' })}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(ReserveContainerForm)
