import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import { goBack } from '../../globalAction'
import BlockPart from '../BlockPart/BlockPart'
import CongruentPart from '../CongruentPart/CongruentPart'
import NoBlockPart from '../NoBlockPart/NoBlockPart'
import ChangesDisplayNotification from './partials/ChangesDisplayNotification'
import VaccineApplicatorBlock from './partials/VaccineApplicatorBlock'

class EditParamEquipment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    M.AutoInit()
  }

  render() {
    const { data, intl, history } = this.props
    const { type, history: hist } = data
    const { action } = hist
    const { changes, paramId } = action
    const { channels } = changes

    const pathGoBack = '/parameters/parameter-history'

    return (
      <div className="Container stockHistory">
        <div>
          <div className="pageTitle pageHeader row">
            <div className="col s12">
              <div
                role="button"
                className="flexInline pointer"
                onClick={() => goBack(pathGoBack, history)}
                onKeyPress={() => goBack(pathGoBack, history)}
                tabIndex={0}
              >
                <div className="goBackBtn">
                  <i role="button" tabIndex="0" className="material-icons">
                    chevron_left
                  </i>
                </div>
                <h5>
                  <span>
                    {intl.formatMessage({
                      id: `app.label.parameters.action.${type}`
                    })}
                  </span>
                </h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <div className="row">
                <div className="row form">
                  <div className="col s12">
                    <div className="row">
                      <div className="col s12">
                        <div className="row">
                          <h4>
                            {intl.formatMessage({
                              id: `app.title.equipmentParam${paramId}`
                            })}
                          </h4>
                          <h5>
                            {intl.formatMessage({
                              id: 'app.label.modificationDone'
                            })}
                          </h5>
                        </div>
                        <div className="row params">
                          <div className="col s12">
                            {(paramId === 7 || paramId === 8 || paramId === 9) && (
                              <VaccineApplicatorBlock action={action} />
                            )}
                            <div className="row">
                              <div className="col s12">
                                <CongruentPart paramId={paramId} action={action} history />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s12">
                                <NoBlockPart paramId={paramId} action={action} history />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s12">
                                <BlockPart paramId={paramId} action={action} history />
                              </div>
                            </div>
                            {channels !== undefined && (
                              <div className="row">
                                <div className="col s12">
                                  <div className="row">
                                    <div className="col s12">
                                      <p className="greyTitle">
                                        {intl.formatMessage({ id: 'app.label.notification' })}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col s12">
                                      <div className="row">
                                        <ChangesDisplayNotification
                                          value1={channels[0]}
                                          value2={channels[1]}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(withRouter(EditParamEquipment))
