import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

const NoAuthRoute = ({ component: Component, ...props }) => {
  const { isAuth } = props

  return (
    <Route
      {...props}
      render={subProps =>
        !isAuth ? (
          <Component {...subProps} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: subProps.location } }} />
        )
      }
    />
  )
}

function mapStateToProps(state, ownProps) {
  return {
    isAuth: state.auth.authenticated,
    currentURL: ownProps.location.pathname
  }
}

export default connect(mapStateToProps)(NoAuthRoute)
