import Axios from 'axios'
import React from 'react'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { catchError } from '../../../actions'
import { api } from '../../../parameters'

class BlockChickOrdered extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showReason: false
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const { getDataFromChild, dataToSend, token } = this.props

    Axios.get(`${api}/api/vaccination_parameters?paramId=8`, { headers: { Authorization: token } })
      .then(res => {
        const { value } = res.data['hydra:member'][0].nonBlockingAlert
        const minPercent = value.min / 100 + 1
        const maxPercent = value.max.percent / 100 + 1

        Axios.get(`${api}/api/daily_reports/chicks/${moment().format('YYYY-MM-DD')}`, {
          headers: { Authorization: token }
        })
          .then(result => {
            this.setState({
              minPercent,
              maxPercent,
              minValue: result.data * minPercent,
              maxValue: result.data * maxPercent,
              orderedChicks: result.data
            })
            dataToSend.orderedChicks = result.data
            dataToSend.vaccinatedChicks = result.data
            dataToSend.reason = null

            getDataFromChild('dataToSend', dataToSend)
          })
          .catch(err => catchError(err.response))
      })
      .catch(err => catchError(err))
  }

  componentDidUpdate(prevProps, PrevState) {
    const { minPercent, maxPercent, orderedChicks } = this.state

    if (PrevState.orderedChicks !== orderedChicks) {
      this.setState({
        minValue: orderedChicks * minPercent,
        maxValue: orderedChicks * maxPercent
      })
    }
  }

  handleChange(e) {
    const { name, value } = e.target
    const { getDataFromChild, dataToSend } = this.props
    const { minValue, maxValue } = this.state

    const parseIntValue = parseInt(value)
    dataToSend.reason = null

    if (name === 'vaccinatedChicks') {
      dataToSend.vaccinatedChicks = parseIntValue

      if (minValue < parseIntValue && maxValue > parseIntValue) {
        this.setState({ showReason: false })
      } else {
        this.setState({ showReason: true })
      }
    } else if (name === 'reason') {
      dataToSend.reason = parseIntValue
    }

    getDataFromChild('dataToSend', dataToSend)
  }

  render() {
    const { intl } = this.props
    const { showReason, orderedChicks } = this.state

    return (
      <>
        <div className="row">
          <div className="col s12">
            <div className="custom-card">
              <div className="card-header">
                <div className="card-block">
                  <div className="bold">
                    {orderedChicks} {intl.formatMessage({ id: 'app.label.reproOrderedChicks' })}
                  </div>
                </div>
              </div>
              <div className="card-content">
                <div className="form">
                  <div className="row">
                    <div className="input col m6">
                      <label htmlFor="vaccinatedChicks">
                        {intl.formatMessage({ id: `app.label.reproTotalVaccinatedChicks` })}
                      </label>
                      <input
                        name="vaccinatedChicks"
                        type="number"
                        onChange={this.handleChange}
                        defaultValue={orderedChicks}
                      />
                    </div>
                  </div>
                  {showReason === true ? (
                    <>
                      <div className="row">
                        <div className="divider greyDivider" />
                      </div>
                      <div className="row">
                        <div className="col s12">
                          <span className="labelCustom">
                            {intl.formatMessage({
                              id:
                                'app.label.theDifferenceBetweenChickVaccinatedAndChickOrderedIsImportant'
                            })}
                          </span>
                        </div>
                        <div className="col s6 radio">
                          <p>
                            <label htmlFor="reason01">
                              <input
                                id="reason01"
                                className="checkbox"
                                type="radio"
                                name="reason"
                                value={1}
                                onClick={this.handleChange}
                              />
                              <span className="labelCustom">
                                {intl.formatMessage({ id: 'app.label.aLotOfFreeChicks' })}
                              </span>
                            </label>
                          </p>
                        </div>
                        <div className="col s6 radio">
                          <p>
                            <label htmlFor="reason02">
                              <input
                                id="reason02"
                                className="checkbox"
                                type="radio"
                                name="reason"
                                value={2}
                                onClick={this.handleChange}
                              />
                              <span className="labelCustom">
                                {intl.formatMessage({ id: 'app.label.aLotOfSortAfterNovatech' })}
                              </span>
                            </label>
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(BlockChickOrdered))
