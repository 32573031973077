import React from 'react'
import { FormattedMessage } from 'react-intl'
import HeadSingleHistory from './HeadSingleHistory'

export default function SprayControl(props) {
  const { history, operator, date, action, intl } = props

  return (
    <div className="Container equipmentHistory">
      <HeadSingleHistory operator={operator} date={date} action={action}>
        <div className=" row">
          <div className="col s6">
            <p>
              <span className="label">
                <FormattedMessage id="app.label.name" />
                &nbsp;:&nbsp;
              </span>
              {history.equipmentId}
            </p>
          </div>
          <div className="col s6">
            <p>
              <span className="label">
                <FormattedMessage id="app.control.vaccine.uniformitySpray" />
                &nbsp;:&nbsp;
              </span>
              <FormattedMessage id={`app.label.${history.action.sprayUniformity}`} />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            <p>
              <span className="label">
                <FormattedMessage id="app.control.vaccine.rinsePrime" />
                &nbsp;:&nbsp;
              </span>
              <FormattedMessage id={`app.label.${history.action.rinsePrimeMachines}`} />
            </p>
          </div>
        </div>
        <div className="row">
          {history.action.nozzles.map(
            nozzle =>
              nozzle.name && (
                <div key={nozzle.name} className="col s3">
                  <p>
                    <span className="label">
                      <FormattedMessage id="app.label.name" />
                      &nbsp;:&nbsp;
                    </span>
                    {nozzle.name}
                  </p>
                  <p>
                    <span className="label">
                      <FormattedMessage id="app.label.volume" />
                      &nbsp;:&nbsp;
                    </span>
                    {`${nozzle.volume} ${intl.formatMessage({ id: 'app.unit.nozzleVolume' })}`}
                  </p>
                </div>
              )
          )}
        </div>
      </HeadSingleHistory>
    </div>
  )
}
