module.exports = {
  injected: [
    {
      label: 'sterileBlueDye',
      value: 'sterileBlueDye'
    },
    {
      label: 'blue',
      value: 'blue'
    }
  ],
  sprayed: [
    {
      label: 'red',
      value: 'red'
    },
    {
      label: 'purple',
      value: 'purple'
    },
    {
      label: 'blue',
      value: 'blue'
    }
  ],
  all: [
    {
      label: 'sterileBlueDye',
      value: 'sterileBlueDye'
    },
    {
      label: 'blue',
      value: 'blue'
    },
    {
      label: 'red',
      value: 'red'
    },
    {
      label: 'purple',
      value: 'purple'
    }
  ]
}
