import React from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import icons from '../../../assets/icons/icons'
import roles from '../../../config/userRoles.json'
import DragAndDropBtn from '../../Button/DragAndDropBtn'
import Filters from '../../Filters/Filters'
import { ExportFile } from '../../globalAction'

const PlanningHeader = props => {
  const {
    intl,
    token,
    getStateFromChild,
    url,
    role,
    filters,
    inLoad,
    injectableVaccine,
    sprayableVaccine,
    weekToExport,
    filterByDate,
    totalItems,
    originalFilter,
    dragBtn,
    toggleDragAndDrop,
    toggleDeleteOrders
  } = props

  const { ROLE_OPERATOR, ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_DEV } = roles

  let actionButton = (
    <a className="btnExport white-text waves-effect waves-light modal-trigger" href="#actionButton">
      <img src={icons.moreActionWhite} alt="more action" />
    </a>
  )

  if (role.includes(ROLE_OPERATOR) || role.includes(ROLE_DEV)) {
    actionButton = (
      <div>
        <Link
          to={{
            pathname: '/planning/day-without-hatching'
          }}
          className="btn blueBtn"
        >
          <span>{intl.formatMessage({ id: 'app.label.dayWithoutHatching' })}</span>
        </Link>
      </div>
    )
  }

  let withButton = true

  if (injectableVaccine || sprayableVaccine) {
    withButton = false
  }

  return (
    <div className="row flex flexSpaceBetween">
      <div>
        <div className="filters">
          <div>
            <Filters
              token={token}
              setStateToParent={getStateFromChild}
              url={url}
              role={role}
              originalFilter={originalFilter}
              totalItems={totalItems}
              sortBy="desc"
              hasDate={role.includes(ROLE_HATCH_RESP) || role.includes(ROLE_HATCHING_RESP)}
              noActive
              infiniteLoader
              sortCategory="order[number]"
              filters={filters}
              inLoad={inLoad}
              filterByDate={filterByDate}
            />
          </div>
        </div>
      </div>
      {withButton && (
        <div className="headerButton flex">
          <span>
            <ExportFile
              data={props}
              url={`/api/planning_order_groups/export/xlsx/${weekToExport}`}
            />
          </span>
          <span className="space5pixel">{actionButton}</span>
          {dragBtn && !role.includes(ROLE_OPERATOR) && !role.includes(ROLE_DEV) && (
            <span>
              <DragAndDropBtn onClick={toggleDragAndDrop} />
            </span>
          )}
          <div
            className="btnExport"
            onClick={toggleDeleteOrders}
            role="button"
            tabIndex="0"
            onKeyPress={toggleDeleteOrders}
          >
            <img src={icons.whiteDelete} alt="delete orders" />
          </div>
        </div>
      )}
    </div>
  )
}

export default injectIntl(PlanningHeader)
