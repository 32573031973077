import { FormattedMessage } from 'react-intl'
import React from 'react'
import moment from 'moment'
import HeadSingleHistoryStock from './HeadSingleHistoryStock'

const ProductBagsDestock = props => {
  const { history, operator, date, type } = props
  const { action: data } = history
  const { productBag } = data

  return (
    <div className="Container stockHistory">
      <HeadSingleHistoryStock operator={operator} date={date} action={type}>
        <div className="row historyCard">
          <div className="col s12 card">
            <div className="row">
              <h5>
                <FormattedMessage id="app.label.productBagsDestock" />
              </h5>
            </div>
            <div className="col s12 row" key={productBag.batchNumber}>
              <div className="row">
                <div className="col s4">
                  <p>
                    <span className="label">
                      <FormattedMessage id="app.label.batchesNumber" />
                      &nbsp;:&nbsp;
                    </span>
                    {productBag.batchNumber}
                  </p>
                </div>
                <div className="col s4">
                  <p>
                    <span className="label">
                      <FormattedMessage id="app.label.productBagNumberOutStocked" />
                      &nbsp;:&nbsp;
                    </span>
                    {data.toOutStock}
                  </p>
                </div>
                <div className="col s4">
                  <p>
                    <span className="label">
                      <FormattedMessage id="app.label.expirationDate" />
                      &nbsp;:&nbsp;
                    </span>
                    {moment(productBag.expirationDate).format('DD/MM/YYYY')}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col s4">
                  <p>
                    <span className="label">
                      <FormattedMessage id="app.label.provider" />
                      &nbsp;:&nbsp;
                    </span>
                    {productBag.provider}
                  </p>
                </div>
                <div className="col s4">
                  <p>
                    <span className="label">
                      <FormattedMessage id="app.label.volume" />
                      &nbsp;:&nbsp;
                    </span>
                    {productBag.volume}
                    &nbsp;
                    <FormattedMessage id="app.unit.productBagVolume" />
                  </p>
                </div>
                <div className="col s4">
                  <p>
                    <span className="label">
                      <FormattedMessage id="app.label.remainingUnities" />
                      &nbsp;:&nbsp;
                    </span>
                    {productBag.newTotalUnits}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <p>
                    <span className="label">
                      <FormattedMessage id="app.label.reason" />
                      &nbsp;:&nbsp;
                    </span>
                    {data.reason}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HeadSingleHistoryStock>
    </div>
  )
}

export default ProductBagsDestock
