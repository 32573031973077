import React from 'react'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import CheckboxBlock from './partials/CheckboxBlock'
import DatePickerPart from './partials/DatePickerPart'
import CenterButton from '../../../../../../CenterButton'

class ReserveVaccineBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      reservedVaccine: false,
      disabled: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
  }

  getDataFromChild(name, value) {
    const { data, setStateToParent } = this.props
    data.reservationDate = moment(value).format()

    setStateToParent(false, data)
  }

  handleChange(e) {
    const { data, setStateToParent } = this.props
    const { checked } = e.target

    data.reservedVaccine = checked

    if (checked === false) {
      data.reservationDate = ''
    }

    this.setState({ reservedVaccine: checked })
    setStateToParent(false, data)
  }

  handleSubmit() {
    const { handleSubmit } = this.props

    this.setState({ disabled: true }, () => handleSubmit())
  }

  render() {
    const { intl } = this.props
    const { reservedVaccine, disabled } = this.state
    let textBtn = intl.formatMessage({ id: 'app.button.validate' })

    if (reservedVaccine) {
      textBtn = intl.formatMessage({ id: 'app.label.finilizePreparation' })
    }

    return (
      <>
        <CheckboxBlock handleChange={this.handleChange} />
        {reservedVaccine === true && <DatePickerPart getDataFromChild={this.getDataFromChild} />}
        <CenterButton text={textBtn} onClick={this.handleSubmit} disabled={disabled} />
      </>
    )
  }
}

export default injectIntl(ReserveVaccineBlock)
