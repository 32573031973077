import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { catchError } from '../../actions'
import LoadingPage from '../../components/LoadingPage'
import { api } from '../../parameters'

class UserList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      sortBy: { target: null, order: null }
    }
    this.loadUsers = this.loadUsers.bind(this)
    this.sortBy = this.sortBy.bind(this)
  }

  componentDidMount() {
    this.loadUsers()
  }

  loadUsers() {
    const { token } = this.props
    const { sortBy } = this.state
    Axios.get(`${api}/api/users?sort[${sortBy.target}]=${sortBy.order}`, {
      headers: { Authorization: token }
    })
      .then(res => {
        return this.setState({
          users: res.data['hydra:member']
        })
      })
      .catch(err => catchError(err.response))
  }

  sortBy(target) {
    const { sortBy } = this.state
    this.setState({ sortBy: { target, order: sortBy.order === 'asc' ? 'desc' : 'asc' } }, () =>
      this.loadUsers()
    )
  }

  render() {
    const { users } = this.state
    const { intl } = this.props

    if (users.length === 0) {
      return <LoadingPage />
    }
    return (
      <div className="user Container">
        <div className="row pageHeader">
          <div className="pageTitle col m4">
            <h5>{intl.formatMessage({ id: `app.part.listUser` })}</h5>
          </div>
          <div className="col offset-m4 m4 center">
            <Link
              to={{
                pathname: '/users/create',
                state: {
                  name: intl.formatMessage({ id: `app.part.user` })
                }
              }}
            >
              <button type="button" className="btn transparentBtn">
                {intl.formatMessage({ id: `app.label.createUser` })}
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <div>
                      <p>{intl.formatMessage({ id: `app.label.name` })}</p>
                      <i
                        role="button"
                        onClick={() => this.sortBy('username')}
                        className="material-icons"
                        onKeyPress={() => this.sortBy('username')}
                        tabIndex="0"
                      >
                        sort_by_alpha
                      </i>
                    </div>
                  </th>
                  <th>Email</th>
                  <th>{intl.formatMessage({ id: `app.label.userType` })}</th>
                  <th>{intl.formatMessage({ id: `app.label.PIN` })}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {users.map(user => (
                  <tr key={user.id}>
                    <td className="first-table-element">{user.username}</td>
                    <td>{user.email}</td>
                    <td>{intl.formatMessage({ id: `app.${user.roles[0]}` })}</td>
                    <td>{user.pin}</td>
                    <td>
                      <Link
                        to={{
                          pathname: `/users/edit/${user.id}`,
                          state: {
                            name: intl.formatMessage({ id: `app.part.user` }),
                            icon: 'person'
                          }
                        }}
                      >
                        <i className="material-icons">chevron_right</i>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(UserList)
