import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { catchError } from '../../../actions'
import { validatedAction } from '../../../components/globalAction'
import DyeReceptionPart from '../../../components/StockManagement/OrderReception/DyeReception/DyeInformations/DyeReceptionPart'
import FridgeInformations from '../../../components/StockManagement/OrderReception/DyeReception/FridgeInformations/FridgeInformations'
import HeaderReception from '../../../components/StockManagement/OrderReception/partials/HeaderReception'
import OrderConformity from '../../../components/StockManagement/OrderReception/partials/OrderConformity'
import ProgressBarReception from '../../../components/StockManagement/OrderReception/partials/ProgressBarReception'
import { api } from '../../../parameters'
import globalHistory from '../../../history'

class DyeReception extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      progress: 10,
      part: 1,
      fridges: [],
      formData: {
        dyes: [],
        history: {
          nameConformity: true,
          quantityConformity: true,
          batchNumberConformity: true,
          expirationDateConformity: true,
          nameNoConformityReason: null,
          quantityNoConformityReason: null,
          batchNumberNoConformityReason: null,
          expirationDateNoConformityReason: null,
          comment: ''
        }
      }
    }

    this.getDataFromChild = this.getDataFromChild.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.changePart = this.changePart.bind(this)
    this.handleChangeHistory = this.handleChangeHistory.bind(this)
    this.getChildInformation = this.getChildInformation.bind(this)
    this.getContent = this.getContent.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
  }

  getChildInformation(name, value, index = false) {
    const { formData } = this.state

    if (name === 'fridges') return this.setState({ [name]: value })
    if (index) return this.setState({ formData: { ...formData, [name]: value } })

    return this.setState({ formData: { ...formData, [name]: value } })
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  getContent() {
    const { part, formData, fridges } = this.state

    switch (part) {
      case 1:
        return (
          <FridgeInformations
            changePart={this.changePart}
            setDataToParent={this.getChildInformation}
          />
        )
      case 2:
        return (
          <DyeReceptionPart
            changePart={this.changePart}
            setDataToParent={this.getChildInformation}
            fridgeSelected={formData.fridgeSelected}
            fridges={fridges}
          />
        )
      case 3:
        return (
          <OrderConformity
            handleSubmit={this.handleSubmit}
            setDataToParent={this.getDataFromChild}
            getDataFromChild={this.getDataFromChild}
            dyePart
            formData={formData}
          />
        )
      default:
        return <div />
    }
  }

  changePart() {
    const { part, progress } = this.state

    this.setState({ part: part + 1, progress: progress + 34 })
  }

  handleChangeHistory(e) {
    const { name, value, type, checked } = e.target
    const { formData } = this.state
    const { history } = formData

    if (type === 'checkbox') {
      return this.setState({ formData: { ...formData, history: { ...history, [name]: checked } } })
    }
    return this.setState({ [name]: value })
  }

  handleSubmit(e, stopped = false) {
    const {
      formData,
      dataSelected,
      controlHour,
      controlMinutes,
      nameConformity,
      quantityConformity,
      batchNumberConformity,
      expirationDateConformity,
      nameNoConformityReason,
      quantityNoConformityReason,
      batchNumberNoConformityReason,
      expirationDateNoConformityReason
    } = this.state
    const { token, intl } = this.props
    const { history, allFridgeSelected } = formData
    const dyeBatches = []
    const fridgeId = allFridgeSelected[0].id

    allFridgeSelected.forEach(fridge => {
      const batchesArray = fridge.dyeBatches
      batchesArray.forEach(batch => {
        dyeBatches.push(batch)
      })
    })

    let receptionDate = moment().format()

    if (!stopped) {
      receptionDate = moment(dataSelected)
        .hours(controlHour)
        .minutes(controlMinutes)
        .format()
    }

    for (let i = 0; i < dyeBatches.length; i++) {
      const batch = dyeBatches[i]
      batch.receptionDate = receptionDate
    }

    history.nameConformity = nameConformity
    history.quantityConformity = quantityConformity
    history.batchNumberConformity = batchNumberConformity
    history.expirationDateConformity = expirationDateConformity
    history.nameNoConformityReason = nameNoConformityReason
    history.quantityNoConformityReason = quantityNoConformityReason
    history.batchNumberNoConformityReason = batchNumberNoConformityReason
    history.expirationDateNoConformityReason = expirationDateNoConformityReason
    history.receptionDate = receptionDate
    const data = { dyeBatches, history, fridgeId, stopped }

    Axios.post(`${api}/api/dye_batches`, data, { headers: { Authorization: token } })
      .then(() => {
        if (stopped === true) {
          Swal.fire({
            type: 'info',
            title: intl.formatMessage({ id: 'app.title.stoppedReception' }),
            text: intl.formatMessage({ id: 'app.message.stoppedReception' }),
            customClass: {
              icon: 'swalInfoIcon',
              title: 'swalTitle',
              content: 'swalContentText',
              confirmButton: 'blueBtn'
            }
          }).then(() => globalHistory.goBack())
        } else {
          validatedAction()
        }
      })
      .catch(err => catchError(err.response))
  }

  render() {
    const { intl } = this.props
    const { progress, part } = this.state

    return (
      <div className="Container row">
        <div className="col s12">
          <HeaderReception text={intl.formatMessage({ id: 'app.part.dyeReception' })} />
          <ProgressBarReception progress={progress} part={part} dyePart />
        </div>
        <div className="col s12">{this.getContent()}</div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token
})

export default injectIntl(connect(mapStateToProps)(DyeReception))
