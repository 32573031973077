import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import Axios from 'axios'
import Swal from 'sweetalert2'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import { validatedAction } from '../../globalAction'
import roles from '../../../config/userRoles.json'

class ModalMoreActionRealisation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.handleOperatorAddClick = this.handleOperatorAddClick.bind(this)
  }

  componentDidMount() {
    const { groupId } = this.props
    M.Modal.init(document.getElementById(`modalMoreActionRealisation${groupId}`))
  }

  handleOperatorAddClick() {
    const { intl } = this.props

    Swal.fire({
      title: intl.formatMessage({ id: 'app.label.areYouSureTitle' }),
      text: intl.formatMessage({ id: 'app.message.confirmSendAddPrepToResp' }),
      type: 'info',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({ id: 'app.label.yes' }),
      cancelButtonText: intl.formatMessage({ id: 'app.label.no' }),
      customClass: {
        icon: 'swalInfoIcon',
        title: 'swalTitle',
        content: 'swalContentText',
        confirmButton: 'blueBtn',
        cancelButton: 'redBtn'
      }
    }).then(res => this.confirmOperatorSendAddPrep(res))
  }

  confirmOperatorSendAddPrep(res) {
    const { groupId, vaccineType, orderId, token } = this.props

    if (res.value) {
      Axios.post(
        `${api}/api/vaccination/realisation/add_preparation/${orderId}/operator`,
        { groupId, vaccineType },
        {
          headers: { Authorization: token }
        }
      )
        .then(() => validatedAction(''))
        .catch(err => catchError(err.response))
    }
  }

  render() {
    const { groupId, intl, orderId, vaccineType, role } = this.props
    const { ROLE_OPERATOR, ROLE_HATCHING_RESP, ROLE_HATCH_RESP } = roles

    return (
      <div>
        <div
          id={`modalMoreActionRealisation${groupId}`}
          className="modal noPadding"
          style={{ top: '50%' }}
        >
          <div className="modal-content">
            {role.includes(ROLE_OPERATOR) && (
              <>
                <div className="row center noPadding">
                  <div className="col s12 noPadding">
                    <span>
                      <h5
                        className="labelCustom pointer"
                        onClick={this.handleOperatorAddClick}
                        onKeyPress={this.handleOperatorAddClick}
                        role="button"
                        tabIndex={0}
                      >
                        {intl.formatMessage({ id: 'app.label.addPreparation' })}
                      </h5>
                    </span>
                  </div>
                </div>
                <div className="row center">
                  <div className="col s12 noPadding">
                    <span>
                      <Link
                        to={{
                          pathname: `/vaccination/realisation/remove-preparation/${orderId}/${vaccineType}/null`
                        }}
                      >
                        <h5 className="labelCustom">
                          {intl.formatMessage({ id: 'app.label.removePreparation' })}
                        </h5>
                      </Link>
                    </span>
                  </div>
                </div>
              </>
            )}
            {(role.includes(ROLE_HATCHING_RESP) || role.includes(ROLE_HATCH_RESP)) && (
              <>
                <div className="row center noPadding">
                  <div className="col s12 noPadding">
                    <span>
                      <Link
                        to={{
                          pathname: `/vaccination/realisation/add-preparation/${groupId}/${vaccineType}`
                        }}
                      >
                        <h5 className="labelCustom">
                          {intl.formatMessage({ id: 'app.label.addPreparation' })}
                        </h5>
                      </Link>
                    </span>
                  </div>
                </div>
                <div className="row center">
                  <div className="col s12 noPadding">
                    <span>
                      <Link
                        to={{
                          pathname: `/vaccination/realisation/remove-preparation/${orderId}/${vaccineType}/null`
                        }}
                      >
                        <h5 className="labelCustom">
                          {intl.formatMessage({ id: 'app.label.removePreparation' })}
                        </h5>
                      </Link>
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    role: state.auth.role,
    token: state.auth.token
  }
}

export default withRouter(injectIntl(connect(mapStateToProps)(ModalMoreActionRealisation)))
