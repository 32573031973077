import M from 'materialize-css'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import NoResult from '../../../noResult'
import StatePill from '../../../StatePill'
import ValenceContext from '../../../../context/ValencesContext'

class FridgeContentArray extends React.Component {
  componentDidMount() {
    M.Tabs.init(document.getElementById('tabs'), { swipeable: true })
  }

  static contextType = ValenceContext

  render() {
    const { intl, Vaccines, Dyes, Diluents } = this.props
    const { getValenceLabelByKey } = this.context

    return (
      <div className="equipmentControl row">
        <div className="col s12 equipmentControlContent">
          <ul className="tabs" id="tabs">
            <li className="tab col s4">
              <a href="#vaccins" className="active">
                Vaccins
              </a>
            </li>
            <li className="tab col s4">
              <a href="#dye">Colorants</a>
            </li>
            <li className="tab col s4">
              <a href="#diluent">Diluants</a>
            </li>
          </ul>
        </div>
        <div id="vaccins" className="col s12">
          {Vaccines.length > 0 ? (
            <table className="table width100">
              <thead>
                <tr>
                  <th>{intl.formatMessage({ id: 'app.label.batchesNumber' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.speciality' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.article' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.valence' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.administration' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.ampoulesVialsNumber' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.dosesNumber' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.expirationDate' })}</th>
                </tr>
              </thead>
              <tbody>
                {Vaccines.map(vaccin => (
                  <tr key={vaccin.vaccinesBatch.id}>
                    <td>{vaccin.vaccinesBatch.batchNumber}</td>
                    <td>{vaccin.vaccinesBatch.vaccineArticle.speciality.name}</td>
                    <td>{vaccin.vaccinesBatch.vaccineArticle.name}</td>
                    <td>
                      {getValenceLabelByKey(vaccin.vaccinesBatch.vaccineArticle.speciality.valence)}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: `app.administrationMethod.${vaccin.vaccinesBatch.vaccineArticle.speciality.administrationMethod}`
                      })}
                    </td>
                    <td>{vaccin.vialsNumber}</td>
                    <td>{vaccin.vaccinesBatch.vaccineArticle.dosesNumber * vaccin.vialsNumber}</td>
                    <td>
                      <StatePill type="ExpirationDate" data={vaccin.vaccinesBatch} />
                      {moment(vaccin.vaccinesBatch.expirationDate).format('DD/MM/YYYY')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <NoResult intl={intl} />
          )}
        </div>
        <div id="dye" className="col s12">
          {Dyes.length > 0 ? (
            <table className="table width100">
              <thead>
                <tr>
                  <th>{intl.formatMessage({ id: 'app.label.batchesNumber' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.color' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.provider' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.vialsTotalNumber' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.expirationDate' })}</th>
                </tr>
              </thead>
              <tbody>
                {Dyes.map(dye => (
                  <tr key={dye.dyeBatch.id}>
                    <td>{dye.dyeBatch.batchNumber}</td>
                    <td>{dye.dyeBatch.color}</td>
                    <td>{dye.dyeBatch.provider}</td>
                    <td>{dye.vialsNumber}</td>
                    <td>
                      <StatePill type="ExpirationDate" data={dye.dyeBatch} />
                      {moment(dye.dyeBatch.expirationDate).format('DD/MM/YYYY')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <NoResult intl={intl} />
          )}
        </div>
        <div id="diluent" className="col s12">
          {Diluents.length > 0 ? (
            <table className="table width100">
              <thead>
                <tr>
                  <th>{intl.formatMessage({ id: 'app.label.batchesNumber' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.provider' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.vialsTotalNumber' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.expirationDate' })}</th>
                </tr>
              </thead>
              <tbody>
                {Diluents.map(diluent => (
                  <tr key={diluent.diluentBatch.id}>
                    <td>{diluent.diluentBatch.batchNumber}</td>
                    <td>{diluent.diluentBatch.provider}</td>
                    <td>{diluent.unitsNumber}</td>
                    <td>
                      <StatePill type="ExpirationDate" data={diluent.diluentBatch} />
                      {moment(diluent.diluentBatch.expirationDate).format('DD/MM/YYYY')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <NoResult intl={intl} />
          )}
        </div>
      </div>
    )
  }
}

FridgeContentArray.defaultProps = {
  Vaccines: [],
  Dyes: [],
  Diluents: []
}

export default injectIntl(FridgeContentArray)
