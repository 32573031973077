import { PREVIOUS_PAGE, NEXT_PAGE, TARGET_PAGE } from '../actions/types'

const INITIAL_STATE = {
  pagePart: 0
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PREVIOUS_PAGE:
      return {
        pagePart: state.pagePart > 0 ? state.pagePart - 1 : 0
      }
    case NEXT_PAGE:
      return {
        pagePart: state.pagePart + 1
      }
    case TARGET_PAGE:
      return {
        pagePart: action.number
      }
    default:
      break
  }
  return state
}
