/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import AsyncLoader from '../../../components/AsyncLoader'
import traceabilityProductTypes from '../../../config/traceabilityProductTypes'
import { Translation, SpanLink, WithUnit } from '../Components'
import { getEquipmentById } from '../ReverseRedirections'

function VaccineTransfer({ token, location, intl, history }) {
  const [page, setPage] = useState(1)
  const [state, setState] = useState({
    loading: true,
    items: null,
    contentStatus: false,
    totalItems: 0,
    rawEquipmentType: null,
    equipmentType: null,
    equipmentId: null,
    batchNumber: null
  })

  function loadMore() {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (state.totalItems > page * 30) {
        setPage(page + 1)
      }
    }
  }
  const translationKey = 'app.label.equipment.vaccine_transfer.'

  const t = value => {
    return <Translation id={`${translationKey}${value}`} />
  }

  useEffect(() => {
    document.addEventListener('scroll', loadMore)
  }, [])

  useEffect(() => {
    const pathName = location.pathname.split('/')
    const id = Number(pathName[pathName.length - 2])
    Axios.get(`${api}/api/equipment_traceability/${id}/transfer?page=${page}`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const unknownValue = intl.formatMessage({ id: 'app.label.N/A' })
        const unit =
          res.data.equipmentType === traceabilityProductTypes.VACCINES_BATCH ? 'centimeter' : 'temp'
        const mappedData = [...res.data['hydra:member']].map(i => ({
          ...i,
          date: i.date ? moment(i.date).format('DD/MM/YYYY') : unknownValue,
          hour: i.date ? moment(i.date).format('HH:mm') : unknownValue,
          operator: i.operator ?? unknownValue,

          oldGiverEquipmentControlInstant: {
            text: i.oldGiverEquipmentControl?.instant ?? unknownValue,
            unit
          },
          oldGiverEquipmentControlMin: {
            text: i.oldGiverEquipmentControl?.min ?? unknownValue,
            unit
          },
          oldGiverEquipmentControlMax: {
            text: i.oldGiverEquipmentControl?.max ?? unknownValue,
            unit
          },
          newGiverEquipmentControlInstant: {
            text: i.newGiverEquipmentControl?.instant ?? unknownValue,
            unit
          },
          newGiverEquipmentControlMin: {
            text: i.newGiverEquipmentControl?.min ?? unknownValue,
            unit
          },
          newGiverEquipmentControlMax: {
            text: i.newGiverEquipmentControl?.max ?? unknownValue,
            unit
          },
          oldCatcherEquipmentControlInstant: {
            text: i.oldCatcherEquipmentControl?.instant ?? unknownValue,
            unit
          },
          oldCatcherEquipmentControlMin: {
            text: i.oldCatcherEquipmentControl?.min ?? unknownValue,
            unit
          },
          oldCatcherEquipmentControlMax: {
            text: i.oldCatcherEquipmentControl?.max ?? unknownValue,
            unit
          },
          newCatcherEquipmentControlInstant: {
            text: i.newCatcherEquipmentControl?.instant ?? unknownValue,
            unit
          },
          newCatcherEquipmentControlMin: {
            text: i.newCatcherEquipmentControl?.min ?? unknownValue,
            unit
          },
          newCatcherEquipmentControlMax: {
            text: i.newCatcherEquipmentControl?.max ?? unknownValue,
            unit
          },
          vialNumber: i.batches
            .map(
              batch =>
                `${
                  batch.speciality
                    ? batch.speciality
                    : batch.color
                    ? intl.formatMessage({ id: `${translationKey}dye` })
                    : batch.newStorageUnits
                    ? intl.formatMessage({ id: `${translationKey}diluent` })
                    : ''
                } - ${batch.batchNumber} : ${batch.movedUnits}`
            )
            .join('\n'),
          donorDevice: i.oldEquipmentId ?? unknownValue,
          receipterDevice: i.newEquipmentId ?? unknownValue
        }))
        setState({
          ...state,
          loading: false,
          items: state.items ? state.items.concat(mappedData) : mappedData,
          totalItems: res.data['hydra:totalItems'],
          contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult',
          rawEquipmentType: res.data?.equipmentType,
          equipmentType: intl.formatMessage({
            id: `app.filter.equipmentType.${res.data?.equipmentType}`
          }),
          equipmentId: res.data?.equipmentId,
          batchNumber: res.data.speciality ?? res.data.batchNumber
        })
      })
      .catch(err => catchError(err))
  }, [page])
  return (
    <div className="row Container stockAvailibilityItem">
      <div className="col s12 Content">
        <div className="row pageHeader pageTitle itemHeader">
          <div
            onClick={() => history.goBack()}
            role="button"
            tabIndex="0"
            onKeyPress={() => history.goBack()}
            className="goBackBtn col s1"
          >
            <i className="material-icons">chevron_left</i>
          </div>
          {state.batchNumber !== null && (
            <h5 className="col s12">
              {state.equipmentType} {state.equipmentId} -{' '}
              {intl.formatMessage({ id: 'app.title.vaccine_transfer' })}
            </h5>
          )}
        </div>
        {!state.loading ? (
          <div className="row">
            <table className="table">
              <thead>
                <tr>
                  <th />
                  <th>{t('date')}</th>
                  <th>{t('hour')}</th>
                  <th>{t('operator')}</th>
                  <th>{t('donor_device')}</th>
                  {state.rawEquipmentType === traceabilityProductTypes.VACCINES_BATCH && (
                    <>
                      <th>{t('nitrogen_level_before_transfer')}</th>
                      <th>{t('nitrogen_level_after_transfer')}</th>
                    </>
                  )}
                  {state.rawEquipmentType !== traceabilityProductTypes.VACCINES_BATCH && (
                    <>
                      <th>{t('min_t_before_transfer')}</th>
                      <th>{t('max_t_before_transfer')}</th>
                      <th>{t('instant_t_before_transfer')}</th>
                    </>
                  )}
                  <th>{t('receipter_device')}</th>
                  {state.rawEquipmentType === traceabilityProductTypes.VACCINES_BATCH && (
                    <>
                      <th>{t('nitrogen_level_before_transfer')}</th>
                      <th>{t('nitrogen_level_after_transfer')}</th>
                    </>
                  )}
                  {state.rawEquipmentType !== traceabilityProductTypes.VACCINES_BATCH && (
                    <>
                      <th>{t('min_t_after_transfer')}</th>
                      <th>{t('max_t_after_transfer')}</th>
                      <th>{t('instant_t_after_transfer')}</th>
                    </>
                  )}
                  <th>
                    {t('vial_number')}
                    {<br />}
                    {t('vial_number_description')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {state.items.map(item => {
                  return (
                    <tr key={item.id}>
                      <td />
                      <td>{item.date}</td>
                      <td>{item.hour}</td>
                      <td>{item.operator}</td>
                      <td>
                        <SpanLink
                          value={item.donorDevice}
                          setLoading={loading => setState(...state, loading)}
                          redirect={getEquipmentById}
                          token
                          history
                        />
                      </td>
                      {state.rawEquipmentType !== traceabilityProductTypes.VACCINES_BATCH && (
                        <>
                          <td>
                            <WithUnit {...item.oldGiverEquipmentControlMin} />
                          </td>
                          <td>
                            <WithUnit {...item.oldGiverEquipmentControlMax} />
                          </td>
                        </>
                      )}
                      <td>
                        <WithUnit {...item.oldGiverEquipmentControlInstant} />
                      </td>
                      {state.rawEquipmentType === traceabilityProductTypes.VACCINES_BATCH && (
                        <td>
                          <WithUnit {...item.newGiverEquipmentControlInstant} />
                        </td>
                      )}
                      <td>
                        <SpanLink
                          value={item.receipterDevice}
                          setLoading={loading => setState(...state, loading)}
                          redirect={getEquipmentById}
                          token
                          history
                        />
                      </td>
                      {state.rawEquipmentType !== traceabilityProductTypes.VACCINES_BATCH && (
                        <>
                          <td>
                            <WithUnit {...item.oldCatcherEquipmentControlMin} />
                          </td>
                          <td>
                            <WithUnit {...item.oldCatcherEquipmentControlMax} />
                          </td>
                        </>
                      )}
                      <td>
                        <WithUnit {...item.oldCatcherEquipmentControlInstant} />
                      </td>
                      {state.rawEquipmentType === traceabilityProductTypes.VACCINES_BATCH && (
                        <td>
                          <WithUnit {...item.newCatcherEquipmentControlInstant} />
                        </td>
                      )}
                      <td style={{ whiteSpace: 'pre-wrap' }}>{item.vialNumber}</td>
                    </tr>
                  )
                })}
              </tbody>
              <tfoot className="center">
                <tr>
                  <th colSpan="5">
                    <AsyncLoader status={state.contentStatus} />
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        ) : (
          <LoadingPage />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(VaccineTransfer)))
