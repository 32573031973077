import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import icons from '../../assets/icons/icons'
import LinksPage from '../../components/LinksPage'
import roles from '../../config/userRoles.json'

const Vaccination = props => {
  const { role } = props

  const { ROLE_OPERATOR } = roles

  let items = [
    { icon: icons.preparation, name: 'preparation', path: '/vaccination/preparation' },
    { icon: icons.realisation, name: 'realisation', path: '/vaccination/realisation' },
    { icon: icons.endRealisation, name: 'end-realisation', path: '/vaccination/end-realisation' },
    {
      icon: icons.validationCertification,
      name: 'validation-certification',
      path: '/vaccination/validation'
    }
  ]

  if (role.includes(ROLE_OPERATOR)) {
    items = [
      { icon: icons.realisation, name: 'realisation', path: '/vaccination/realisation' },
      { icon: icons.endRealisation, name: 'end-realisation', path: '/vaccination/end-realisation' },
      {
        icon: icons.validationCertification,
        name: 'validation-certification',
        path: '/vaccination/validation'
      }
    ]
  }

  return <LinksPage items={items} itemsPerLine={2} operator={role.includes(ROLE_OPERATOR)} />
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(Vaccination)))
