import React from 'react'
import { injectIntl } from 'react-intl'
import Axios from 'axios'
import Swal from 'sweetalert2'
import history from '../../../history'
import { api } from '../../../parameters'
import { closePage, validatedAction } from '../../../components/globalAction'
import TbodyProductsBag from '../../../components/Vaccination/Realisation/TbodyProductsBag'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import CenterButton from '../../../components/CenterButton'
import roles from '../../../config/userRoles.json'
import OrderBlockInfo from '../../../components/Vaccination/Realisation/AddPreparation/OrderBlockInfo'

class RemovePreparation extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      preparationSelected: []
    }
    this.handleChangeCheckBoxes = this.handleChangeCheckBoxes.bind(this)
    this.handleValidationClick = this.handleValidationClick.bind(this)
  }

  componentDidMount() {
    const { intl, match, token } = this.props
    const { id, preparations } = match.params
    const { preparationSelected } = this.state

    Axios.get(`${api}/api/planning_orders/${id}`, { headers: { Authorization: token } })
      .then(res => {
        if (res.data.orderOrderPreparations.length === 0) {
          return Swal.fire({
            type: 'info',
            confirmButtonText: intl.formatMessage({ id: 'app.label.ok' }),
            text: intl.formatMessage({ id: 'app.message.noPreparationsForOrder' }),
            customClass: {
              icon: 'swalInfoIcon',
              title: 'swalTitle',
              content: 'swalContentText',
              confirmButton: 'blueBtn',
              cancelButton: 'redBtn'
            }
          }).then(() => history.goBack())
        }

        const idsToCheck = []

        for (const preparation of res.data.orderOrderPreparations) {
          idsToCheck.push(preparation.id)
        }

        if (preparations !== 'null') {
          for (const preparationId of preparations.split('+')) {
            const parseIntId = parseInt(preparationId)

            if (!preparationSelected.includes(parseIntId) && idsToCheck.includes(parseIntId)) {
              preparationSelected.push(parseIntId)
            } else {
              preparationSelected.splice(preparationSelected.indexOf(parseIntId), 1)
            }
          }
        }

        this.setState({ order: res.data, loading: false, preparationSelected })
      })
      .catch(err => catchError(err.response))
  }

  handleChangeCheckBoxes(e) {
    const { id } = e.target
    const { preparationSelected } = this.state

    const parseIntId = parseInt(id)

    if (!preparationSelected.includes(parseIntId)) {
      preparationSelected.push(parseIntId)
    } else {
      preparationSelected.splice(preparationSelected.indexOf(parseIntId), 1)
    }

    this.setState({ preparationSelected })
  }

  handleValidationClick() {
    const { token, role, intl } = this.props
    const { preparationSelected, order } = this.state
    const { ROLE_OPERATOR } = roles

    if (role.includes(ROLE_OPERATOR)) {
      Axios.post(
        `${api}/api/vaccination/realisation/remove_preparations/operator`,
        { preparations: preparationSelected, order: order.id },
        { headers: { Authorization: token } }
      )
        .then(() => validatedAction(intl.formatMessage({ id: 'app.label.askRemoveToResp' })))
        .catch(err => catchError(err.response))
    } else {
      Axios.patch(
        `${api}/api/vaccination/realisation/remove_preparations`,
        { preparations: preparationSelected },
        { headers: { Authorization: token } }
      )
        .then(() => history.goBack())
        .catch(err => catchError(err.response))
    }
  }

  render() {
    const { match, intl } = this.props
    const { order, loading, preparationSelected } = this.state
    let { vaccineType } = match.params

    vaccineType = parseInt(vaccineType)

    if (loading) {
      return <LoadingPage />
    }

    let removeType = 'removeProductBag'

    if (vaccineType > 0) {
      removeType = 'removeCan'
    }

    const { injectionGroup, thinDropsGroup, bigDropsGroup, orderOrderPreparations } = order
    let group

    if (injectionGroup !== null) {
      group = injectionGroup
    } else if (thinDropsGroup !== null) {
      group = thinDropsGroup
    } else {
      group = bigDropsGroup
    }

    return (
      <div className="Container row">
        <div className="col s12">
          <div className="row pageHeader pageTitle">
            <div className="flex col m12">
              <h5 className="col m12">
                {intl.formatMessage({ id: 'app.label.removePreparation' })}
              </h5>
            </div>
            <div className="col">
              <div
                role="button"
                tabIndex="0"
                onKeyPress={() => closePage(intl)}
                onClick={() => closePage(intl)}
                className="goBackBtn"
              >
                <i className="material-icons">close</i>
              </div>
            </div>
          </div>
          <OrderBlockInfo order={order} group={group} vaccineType={vaccineType} />
          <div className="row">
            <div className="col s12">
              <table className="table width100">
                <thead>
                  <th>
                    {intl.formatMessage({
                      id: `app.label.${vaccineType === 0 ? 'productBag' : 'bottle'}`
                    })}
                  </th>
                  {vaccineType === 0 && (
                    <th>{intl.formatMessage({ id: 'app.label.batchNumber' })}</th>
                  )}
                  <th>{intl.formatMessage({ id: 'app.label.progression' })}</th>
                  <th>{intl.formatMessage({ id: `app.label.${removeType}` })}</th>
                </thead>
                {orderOrderPreparations &&
                  orderOrderPreparations.map((preparation, index) => (
                    <TbodyProductsBag
                      key={preparation.id}
                      id={preparation.id}
                      length={orderOrderPreparations.length}
                      productBag={preparation.preparation.productBag}
                      state={preparation.preparation.state}
                      vaccineType={vaccineType}
                      handleChangeCheckBoxes={this.handleChangeCheckBoxes}
                      index={index}
                      shared={preparation.preparation.shared}
                      reuse={preparation.preparation.reuse}
                      checked={preparationSelected.includes(parseInt(preparation.id))}
                    />
                  ))}
              </table>
            </div>
          </div>
          {preparationSelected.length > 0 && (
            <div className="row">
              <div className="col s12">
                <CenterButton
                  text={intl.formatMessage({ id: 'app.button.validate' })}
                  onClick={this.handleValidationClick}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default injectIntl(RemovePreparation)
