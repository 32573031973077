import React from 'react'
import { injectIntl } from 'react-intl'

const BlockArticlePart = props => {
  const { intl, history, article, handleChange, exiprationDateDays, blockAlert } = props

  if (!history) {
    const { blockingExpirationAlert } = article
    return (
      <div className="row">
        <div className="col s6 input">
          <div className="row">
            <div className="input col s6">
              <label htmlFor="blockExpirationDate" className="labelParams">
                {intl.formatMessage({ id: 'app.label.expirationDate' })}
              </label>
              <select
                className="select-wrapper"
                name="blockExpirationDate"
                defaultValue={
                  blockingExpirationAlert && blockingExpirationAlert.value !== ''
                    ? blockingExpirationAlert.value
                    : '0'
                }
                onChange={handleChange}
              >
                <option
                  value={
                    blockingExpirationAlert && blockingExpirationAlert.value !== ''
                      ? blockingExpirationAlert.value
                      : '0'
                  }
                  disabled
                >
                  {blockingExpirationAlert && blockingExpirationAlert.value !== ''
                    ? blockingExpirationAlert.value
                    : intl.formatMessage({ id: 'app.label.selectDay' })}
                </option>

                {exiprationDateDays.map(day => (
                  <option key={day.name} value={day.name}>
                    {day.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col s12 input">
              <label htmlFor="blockAlert" className="labelParams">
                {intl.formatMessage({ id: 'app.label.alert' })}
              </label>
              <textarea
                className="materialize-textarea"
                name="blockAlert"
                type="text"
                value={blockAlert}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return <></>
}

export default injectIntl(BlockArticlePart)
