import Axios from 'axios'
import M from 'materialize-css'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import Swal from 'sweetalert2'
import { catchError } from '../../../actions'
import DatepickerBlock from '../../../components/DatepickerBlock'
import ReturnContainer from '../../../components/EquipmentManagement/ContainerManagement/UseEnd/ReturnContainer'
import { closePage, validatedAction } from '../../../components/globalAction'
import LoadingPage from '../../../components/LoadingPage'
import TimePickerBlock from '../../../components/TimePickerBlock'
import { api } from '../../../parameters'

class ContainerEndOfUse extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      emptyContainers: [],
      action: '',
      controlHour: moment().format(),
      controlMinutes: moment().format(),
      generalState: true,
      generalStateComment: '',
      filledDeliveryForm: true,
      filledDeliveryFormComment: '',
      returnedInOriginBox: true,
      returnedInOriginBoxComment: ''
    }

    this.selectEmptyContainer = this.selectEmptyContainer.bind(this)
    this.selectAction = this.selectAction.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
  }

  componentDidMount() {
    const { token } = this.props

    Axios.get(`${api}/api/nitrogen_containers?type[]=0&type[]=2`, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({ emptyContainers: res.data['hydra:member'], loading: false })
        return M.FormSelect.init(document.querySelectorAll('select'))
      })
      .catch(err => catchError(err.response))
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  selectEmptyContainer(e) {
    const { value } = e.target
    const { token } = this.props

    Axios.get(`${api}/api/nitrogen_containers/${value}`, { headers: { Authorization: token } })
      .then(res => {
        return this.setState({ selectedContainer: res.data, action: '' })
      })
      .catch(err => catchError(err.response))
  }

  selectAction(e) {
    const { value } = e.target

    return this.setState({ action: value })
  }

  async handleSubmit() {
    const {
      action,
      controlHour,
      controlMinutes,
      askedDate,
      generalState,
      filledDeliveryForm,
      returnedInOriginBox,
      generalStateComment,
      filledDeliveryFormComment,
      returnedInOriginBoxComment,
      selectedContainer,
      lastControl
    } = this.state

    const { token, intl } = this.props
    const lastControlDate = moment(lastControl)
      .hour(controlHour)
      .minute(controlMinutes)

    if (selectedContainer === undefined || action.length === 0) {
      let swalMessage = 'selectContainerEndUseAction'

      if (selectedContainer === undefined) {
        swalMessage = 'selectContainer'
      }

      Swal.fire({
        type: 'warning',
        text: intl.formatMessage({ id: `app.swal.message.${swalMessage}` }),
        confirmButtonText: intl.formatMessage({ id: 'app.label.ok' }),
        customClass: {
          icon: 'swalOrangeIcon',
          content: 'swalContentText',
          confirmButton: 'blueBtn'
        }
      })
    } else {
      try {
        if (action === 'return') {
          if (askedDate === undefined) {
            Swal.fire({
              type: 'warning',
              text: intl.formatMessage({ id: 'app.swal.message.selectReturnDate' }),
              confirmButtonText: intl.formatMessage({ id: 'app.label.ok' }),
              customClass: {
                icon: 'swalOrangeIcon',
                content: 'swalContentText',
                confirmButton: 'blueBtn'
              }
            })
          } else {
            const data = {
              nitrogenContainerReturn: {
                generalState,
                askedDate: moment(askedDate).format(),
                filledDeliveryForm,
                returnedInOriginBox,
                generalStateComment,
                filledDeliveryFormComment,
                returnedInOriginBoxComment,
                nitrogenContainer: selectedContainer.id
              },
              'control.createdAt': lastControlDate.format()
            }

            Axios.post(`${api}/api/nitrogen_containers/return`, data, {
              headers: { Authorization: token }
            }).then(() => validatedAction('', '/equipments/containers'))
          }
        } else {
          Axios.patch(
            `${api}/api/nitrogen_containers/store/${selectedContainer.id}`,
            {
              createdAt: lastControlDate.format()
            },
            {
              headers: { Authorization: token }
            }
          ).then(() => validatedAction('', '/equipments/containers'))
        }
      } catch (err) {
        catchError(err.response)
      }
    }
  }

  render() {
    const { action, emptyContainers, loading, selectedContainer } = this.state
    const { intl } = this.props

    if (loading) return <LoadingPage />

    return (
      <div className="row Container containersManagement">
        <div className="col m12">
          <div className="row pageHeader pageTitle">
            <h5 className="col m11">{intl.formatMessage({ id: 'app.label.containerUseEnd' })}</h5>
            <div
              className="goBackBtn col m1"
              role="button"
              tabIndex="0"
              onKeyPress={() => closePage(intl)}
              onClick={() => closePage(intl)}
            >
              <i className="material-icons">close</i>
            </div>
          </div>
          <div className="content">
            <div className="form">
              <div className="row input">
                <div className="input-field col m6">
                  <select
                    className="select-wrapper"
                    onChange={this.selectEmptyContainer}
                    defaultValue="0"
                  >
                    <option value="0" disabled>
                      {intl.formatMessage({ id: `app.label.selectContainer` })}
                    </option>
                    {emptyContainers.map(container => (
                      <option key={container.id} value={container.id}>
                        {`${intl.formatMessage({ id: `app.label.container` })} ${
                          container.containerNumber
                        }`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {selectedContainer && (
                <div className="choices">
                  <div className="row">
                    <div className="col m6">
                      <h5>
                        {`${intl.formatMessage({ id: `app.label.container` })} ${
                          selectedContainer.containerNumber
                        }`}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col m6">
                      <span className="bold">
                        {intl.formatMessage({ id: `app.label.provider` })}
                      </span>
                      <span className="thin">{` ${selectedContainer.provider}`}</span>
                    </div>
                    <div className="col m6">
                      <span className="bold">
                        {intl.formatMessage({ id: `app.label.capacityContainer` })}
                      </span>
                      <span className="thin">
                        {` ${selectedContainer.capacity} ${intl.formatMessage({
                          id: 'app.unit.containerCapacity'
                        })}`}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col m6 radio">
                      <p>
                        <label>
                          <input
                            name="action"
                            value="return"
                            type="radio"
                            checked={action === 'return'}
                            onChange={this.selectAction}
                          />
                          <span>{intl.formatMessage({ id: `app.label.containerReturn` })}</span>
                        </label>
                      </p>
                    </div>
                    <div className="col m6 radio">
                      <p>
                        <label>
                          <input
                            value="store"
                            name="action"
                            type="radio"
                            checked={action === 'store'}
                            disabled={
                              !!(selectedContainer.type !== null && selectedContainer.type === 2)
                            }
                            onChange={this.selectAction}
                          />
                          <span>{intl.formatMessage({ id: `app.label.containerStore` })}</span>
                          {selectedContainer.type !== null && selectedContainer.type === 2 && (
                            <span className="flex red-text">
                              {intl.formatMessage({ id: `app.alert.storedContainer` })}
                            </span>
                          )}
                        </label>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {action.length > 0 && (
              <div className="form">
                {action === 'return' ? (
                  <ReturnContainer
                    handleCheck={this.handleCheck}
                    getDataFromChild={this.getDataFromChild}
                  />
                ) : (
                  <div className="row">
                    <div className="col s4">
                      <DatepickerBlock
                        setDataFromChild={this.getDataFromChild}
                        name="lastControl"
                        label={intl.formatMessage({ id: 'app.label.controlDate' })}
                      />
                    </div>
                    <div className="col s4">
                      <TimePickerBlock
                        setDataToParent={this.getDataFromChild}
                        defaultValue
                        label="controlHour"
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="row">
            <button
              type="button"
              className="btn blueBtn col offset-m5 m2 validationBtn"
              onClick={this.handleSubmit}
            >
              {intl.formatMessage({ id: 'app.button.validate' })}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(ContainerEndOfUse)
