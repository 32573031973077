import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import moment from 'moment'
import Swal from 'sweetalert2'
import { catchError } from '../../../../../actions'
import { api } from '../../../../../parameters'
import ProductBagInformationForm from './ProductBagInformationForm'

class ProductBagInformations extends React.Component {
  constructor() {
    super()
    this.state = { productBags: [{}], disabled: [true] }

    this.getBatchNumbers = this.getBatchNumbers.bind(this)
    this.addProductBag = this.addProductBag.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.setDisabled = this.setDisabled.bind(this)
  }

  componentDidMount() {
    this.getBatchNumbers()
  }

  getDataFromChild(name, value, index) {
    const { productBags } = this.state
    const { getDataFromPart } = this.props

    productBags[index][name] = value

    return this.setState({ productBags }, () => {
      getDataFromPart('productBags', productBags, 'productBags')
    })
  }

  setDisabled(bool, index) {
    const { disabled } = this.state
    disabled[index] = bool

    this.setState({ disabled })
  }

  getBatchNumbers() {
    const { token } = this.props

    Axios.get(`${api}/api/product_bags`, { headers: { Authorization: token } })
      .then(res => {
        const batches = res.data['hydra:member']
        const existingBatchNumber = []
        batches.forEach(batch => existingBatchNumber.push(batch.batchNumber))
        this.setState({ existingBatchNumber }, () =>
          M.FormSelect.init(document.querySelectorAll('select'), {})
        )
      })
      .catch(err => catchError(err.response))
  }

  addProductBag() {
    const { productBags, disabled } = this.state

    productBags.push({})
    disabled.push(true)

    this.setState({ productBags, disabled }, () =>
      M.FormSelect.init(document.querySelectorAll('select'))
    )
  }

  handleClick() {
    const { existingBatchNumber, productBags } = this.state
    const { changePart, intl } = this.props
    let validForm = true

    productBags.forEach(bag => {
      if (!bag.provider || !bag.batchNumber || !bag.expirationDate || !bag.units) {
        validForm = false
      }
    })

    if (!validForm) {
      return Swal.fire({
        type: 'error',
        title: intl.formatMessage({ id: 'app.alert.error' }),
        text: intl.formatMessage({ id: 'app.alert.emptyFields' }),
        customClass: {
          icon: 'swalErrorIcon',
          title: 'swalTitle',
          content: 'swalContentText',
          confirmButton: 'blueBtn'
        }
      })
    }

    let swal = false
    let swalDate = false
    const numbersToEdit = []

    productBags.forEach(pBag => {
      if (moment().isSameOrAfter(pBag.expirationDate) === true) {
        swalDate = true
      }

      if (existingBatchNumber.includes(pBag.batchNumber)) {
        if (swal === false) swal = true

        numbersToEdit.push(pBag.batchNumber)
      }
    })

    if (swal === true) {
      Swal.fire({
        type: 'warning',
        text: intl.formatMessage({ id: 'app.swal.message.existingBatchNumber' }, { numbersToEdit }),
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({ id: 'app.label.yes' }),
        cancelButtonText: intl.formatMessage({ id: 'app.label.no' }),
        customClass: {
          icon: 'swalOrangeIcon',
          content: 'swalContentText',
          confirmButton: 'blueBtn',
          cancelButton: 'redBtn'
        }
      }).then(res => {
        if (swalDate === true) {
          this.fireExpDateSwal(numbersToEdit)
        } else if (res.value === true) {
          changePart()
        }
      })
    } else if (swalDate === true) {
      this.fireExpDateSwal(numbersToEdit)
    } else {
      return changePart()
    }
  }

  fireExpDateSwal(numbersToEdit) {
    const { intl } = this.props

    Swal.fire({
      type: 'warning',
      text: intl.formatMessage({ id: 'app.swal.message.expirationDateVaccine' }, { numbersToEdit }),
      confirmButtonText: intl.formatMessage({ id: 'app.label.ok' }),
      customClass: {
        icon: 'swalOrangeIcon',
        content: 'swalContentText',
        confirmButton: 'blueBtn'
      }
    })
  }

  render() {
    const { productBags, disabled } = this.state
    const { intl } = this.props

    return (
      <div className="row">
        <form autoComplete="off">
          {productBags.map((_productBag, i) => (
            <ProductBagInformationForm
              index={i}
              key={i}
              getDataFromChild={this.getDataFromChild}
              setDisabled={this.setDisabled}
            />
          ))}
        </form>
        <div className="row">
          <div className="col offset-s4 s2">
            <button
              type="button"
              className="btn blueBtn"
              onClick={this.addProductBag}
              disabled={disabled.includes(true)}
            >
              {intl.formatMessage({ id: 'app.label.add' })}
            </button>
          </div>
          <div className="col s2">
            <button
              type="button"
              className="btn blueBtn"
              onClick={this.handleClick}
              disabled={disabled.includes(true)}
            >
              {intl.formatMessage({ id: 'app.button.validate' })}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token
})

export default injectIntl(connect(mapStateToProps)(ProductBagInformations))
