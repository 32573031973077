import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { catchError } from '../../../actions'
import { validatedAction } from '../../../components/globalAction'
import FridgeInformations from '../../../components/StockManagement/OrderReception/partials/FridgeInformations/FridgeInformations'
import HeaderReception from '../../../components/StockManagement/OrderReception/partials/HeaderReception'
import OrderConformity from '../../../components/StockManagement/OrderReception/partials/OrderConformity'
import ProgressBarReception from '../../../components/StockManagement/OrderReception/partials/ProgressBarReception'
import VaccineInformations from '../../../components/StockManagement/OrderReception/partials/VaccineInformations'
import { api } from '../../../parameters'
import globalHistory from '../../../history'

class RefrigeratedVaccine extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      part: 1,
      progress: 10,
      disabled: true,
      formData: {
        history: {
          nameConformity: true,
          quantityConformity: true,
          batchNumberConformity: true,
          expirationDateConformity: true,
          nameNoConformityReason: null,
          quantityNoConformityReason: null,
          batchNumberNoConformityReason: null,
          expirationDateNoConformityReason: null,
          comment: ''
        }
      }
    }

    this.changePart = this.changePart.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getChildInformation = this.getChildInformation.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
    this.setDisabled = this.setDisabled.bind(this)
  }

  getChildInformation(name, value) {
    const { formData } = this.state

    this.setState({ formData: { ...formData, [name]: value } })
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  getContent() {
    const { part, disabled, formData } = this.state

    switch (part) {
      case 1:
        return (
          <FridgeInformations
            changePart={this.changePart}
            setDataToParent={this.getChildInformation}
          />
        )
      case 2:
        return (
          <VaccineInformations
            handleChange={this.handleChange}
            changePart={this.changePart}
            conservationMethod={1}
            setDataToParent={this.getChildInformation}
            disabled={disabled}
            setDisabled={this.setDisabled}
          />
        )
      case 3:
        return (
          <OrderConformity
            handleSubmit={this.handleSubmit}
            handleChange={this.handleChange}
            setDataToParent={this.getChildInformation}
            getDataFromChild={this.getDataFromChild}
            disabled={disabled}
            formData={formData}
            setDisabled={this.setDisabled}
          />
        )
      default:
        return <div />
    }
  }

  setDisabled(disabled) {
    this.setState({ disabled })
  }

  changePart() {
    const { part, progress } = this.state

    this.setState({ part: part + 1, progress: progress + 34 })
    this.setDisabled(true)
  }

  handleSubmit(_e, stopped = false) {
    const { formData } = this.state
    const { token, intl } = this.props
    let receptionDate = moment().format()

    formData.stopped = stopped
    formData.fridge_id = formData.fridgeId
    formData.history.nameConformity = formData.nameConformity
    formData.history.quantityConformity = formData.quantityConformity
    formData.history.batchNumberConformity = formData.batchNumberConformity
    formData.history.expirationDateConformity = formData.expirationDateConformity
    formData.history.nameNoConformityReason = formData.nameNoConformityReason
    formData.history.quantityNoConformityReason = formData.quantityNoConformityReason
    formData.history.batchNumberNoConformityReason = formData.batchNumberNoConformityReason
    formData.history.expirationDateNoConformityReason = formData.expirationDateNoConformityReason

    delete formData.history.disabled
    delete formData.history.datepicker
    delete formData.history.timepicker
    delete formData.fridgeId

    if (!stopped) {
      receptionDate = moment(formData.dateSelected)
        .hour(formData.controlHour)
        .minutes(formData.controlMinutes)
        .format()
      delete formData.controlHour
      delete formData.controlMinutes
    }

    formData.history.receptionDate = receptionDate
    const { vaccinesBatches } = formData

    vaccinesBatches.forEach(vaccine => {
      const vacc = vaccine
      vacc.receptionDate = receptionDate
      if (!vacc.expirationDate) {
        vacc.expirationDate = moment().format()
      }
    })
    Axios.post(`${api}/api/vaccines_batches/refrigerated`, formData, {
      headers: { Authorization: token }
    })
      .then(() => {
        if (stopped === true) {
          Swal.fire({
            type: 'info',
            title: intl.formatMessage({ id: 'app.title.stoppedReception' }),
            text: intl.formatMessage({ id: 'app.message.stoppedReception' }),
            customClass: {
              icon: 'swalInfoIcon',
              title: 'swalTitle',
              content: 'swalContentText',
              confirmButton: 'blueBtn'
            }
          }).then(() => globalHistory.goBack())
        } else {
          validatedAction(
            intl.formatMessage({ id: 'app.message.successRefrigeratedVaccineContainer' })
          )
        }
      })
      .catch(err => catchError(err.response))
  }

  handleChange(e) {
    const { name, value, type, checked } = e.target
    const { formData } = this.state

    if (type === 'checkbox') {
      return this.setState({ [name]: checked })
    }

    return this.setState({ formData: { ...formData, [name]: value } })
  }

  render() {
    const { progress, part } = this.state
    const { intl } = this.props

    return (
      <div className="Container row">
        <div className="col s12">
          <HeaderReception text={intl.formatMessage({ id: 'app.part.refrigeredVaccinReceipt' })} />
          <ProgressBarReception progress={progress} part={part} fridgePart />
          {this.getContent()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token
})

export default injectIntl(connect(mapStateToProps)(RefrigeratedVaccine))
