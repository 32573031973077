import React from 'react'

export default function NoResult(props) {
  const { intl } = props
  return (
    <div className="row">
      <div className="col offset-s5 s2">
        <p className="center">{intl.formatMessage({ id: 'app.label.noResult' })}</p>
      </div>
    </div>
  )
}
