/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react'
import Axios from 'axios'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import { Translation, SpanLink, WithUnit } from '../Components'
import { getOrderByCurrentNumber } from '../ReverseRedirections'
import ProductsForOrderContext from '../../../context/ProductsForOrderContext'

function Share({ token, location, intl, history }) {
  const [state, setState] = useState({
    loading: true,
    items: null,
    batchNumber: null
  })

  const vaccineTypes = {
    injection: 0,
    spray: 1
  }

  const { getProductForOrderLabelByKey } = useContext(ProductsForOrderContext)

  const t = value => {
    const translationKey = 'app.label.order.share.'
    return <Translation id={`${translationKey}${value}`} />
  }

  useEffect(() => {
    const pathName = location.pathname.split('/')
    const id = Number(pathName[pathName.length - 2])
    Axios.get(`${api}/api/order_traceability/${id}/certification`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const items = []
        const mapper = (type, key, shareWith) => {
          return {
            orderNumber: res.data.currentNumber,
            sharedOrderNumber: key,
            strain: getProductForOrderLabelByKey(shareWith.product),
            sharedStrain: getProductForOrderLabelByKey(shareWith.productSharedOrder),
            sharedDoseNumber: {
              text: shareWith.doses,
              unit: 'doses'
            },
            sharedTypeOfPreparation: type,
            identification: shareWith.qr
          }
        }
        res.data['hydra:member'].forEach(i => {
          if (i.detail?.big?.shareWith) {
            Object.entries(i.detail.big.shareWith).forEach(([key, shareWith]) => {
              shareWith.forEach(o => {
                items.push(mapper('big', key, o))
              })
            })
          }
          if (i.detail?.injection?.shareWith) {
            Object.entries(i.detail.injection.shareWith).forEach(([key, shareWith]) => {
              shareWith.forEach(o => {
                items.push(mapper('injection', key, o))
              })
            })
          }
          if (i.detail?.thin?.shareWith) {
            Object.entries(i.detail.thin.shareWith).forEach(([key, shareWith]) => {
              shareWith.forEach(o => {
                items.push(mapper('thin', key, o))
              })
            })
          }
        })
        setState({
          loading: false,
          items,
          batchNumber: res.data.currentNumber
        })
      })
      .catch(err => catchError(err))
  }, [])

  return (
    <div className="row Container stockAvailibilityItem">
      <div className="col s12 Content">
        <div className="row pageHeader pageTitle itemHeader">
          <div
            onClick={() => history.goBack()}
            role="button"
            tabIndex="0"
            onKeyPress={() => history.goBack()}
            className="goBackBtn col s1"
          >
            <i className="material-icons">chevron_left</i>
          </div>
          {state.batchNumber !== null && (
            <h5 className="col s12">
              {intl.formatMessage({ id: 'app.title.order' })} {state.batchNumber} -{' '}
              {intl.formatMessage({ id: 'app.title.share' })}
            </h5>
          )}
        </div>
        {!state.loading ? (
          <div className="row">
            <table className="table" style={{ marginBottom: '16px' }}>
              <thead>
                <tr>
                  <th>{t('order_number')}</th>
                  <th>{t('shared_order_number')}</th>
                  <th>{t('strain')}</th>
                  <th>{t('shared_strain')}</th>
                  <th>{t('shared_dose_number')}</th>
                  <th>{t('shared_type_of_preparation')}</th>
                  <th>{t('identification')}</th>
                </tr>
              </thead>
              <tbody>
                {state.items.map(item => {
                  return (
                    <tr>
                      <td>
                        <SpanLink
                          value={item.orderNumber}
                          setLoading={loading => {
                            setState({ ...state, loading })
                          }}
                          redirect={getOrderByCurrentNumber}
                          token
                          history
                        />
                      </td>
                      <td>
                        <SpanLink
                          value={item.sharedOrderNumber}
                          setLoading={loading => {
                            setState({ ...state, loading })
                          }}
                          redirect={getOrderByCurrentNumber}
                          token
                          history
                        />
                      </td>
                      <td>{item.strain}</td>
                      <td>{item.sharedStrain}</td>
                      <td>
                        <WithUnit {...item.sharedDoseNumber} />
                      </td>
                      <td>{t(item.sharedTypeOfPreparation)}</td>
                      <td>{item.identification}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <LoadingPage />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(Share)))
