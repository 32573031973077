import Axios from 'axios'
import M from 'materialize-css'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { catchError } from '../../../../actions'
import { api } from '../../../../parameters'
import LoadingPage from '../../../LoadingPage'
import ValenceContext from '../../../../context/ValencesContext'

class BatchForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      specialites: [],
      articles: [],
      batches: [],
      selectedSpeciality: '',
      selectedArticle: '',
      selectedBatch: false,
      allSelected: false,
      vaccinesSelected: [],
      ampoulesNumberToTransfer: 0,
      containerVaccinesBatches: {
        dosesMoved: '',
        id: ''
      },
      disabledArticles: true,
      disabledBatches: true
    }
    this.loadSpecialities = this.loadSpecialities.bind(this)
    this.loadBatches = this.loadBatches.bind(this)
    this.loadArticles = this.loadArticles.bind(this)
    this.loadAllVaccines = this.loadAllVaccines.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getVaccinesSelected = this.getVaccinesSelected.bind(this)
  }

  componentDidMount() {
    const { setDisabled } = this.props
    setDisabled(true)
    this.loadSpecialities()
  }

  componentDidUpdate(_prevProps, prevState) {
    const { batchContainerToState, index, batches } = this.props
    const { containerVaccinesBatches } = this.state
    if (_prevProps.batches !== batches) {
      this.getVaccinesSelected()
    }
    if (prevState.containerVaccinesBatches !== containerVaccinesBatches) {
      batchContainerToState(containerVaccinesBatches, index)
    }
  }

  getVaccinesSelected() {
    const { index, batches2 } = this.props
    const vaccinesSelected = []
    batches2.forEach(vaccine => {
      if (vaccine.id !== undefined && vaccinesSelected[index] === undefined)
        vaccinesSelected[index] = parseInt(vaccine.id)
    })
    this.setState({ vaccinesSelected })
  }

  getSnapshotBeforeUpdate(prevProps) {
    const { id } = this.props
    if (prevProps.id !== id) {
      this.setState({ loading: true })
      this.loadSpecialities()
      return true
    }
    return null
  }

  static contextType = ValenceContext

  loadSpecialities() {
    const { id, token } = this.props
    Axios.get(
      `${api}/api/vaccine_specialities?vaccineArticles.vaccinesBatches.nitrogenContainerVaccinesBatches.nitrogenContainer.id=${id}`,
      { headers: { Authorization: token } }
    )
      .then(res => {
        this.setState({ specialites: res.data['hydra:member'], loading: false })
        M.AutoInit()
      })
      .catch(err => catchError(err.response))
    this.getVaccinesSelected()
  }

  loadArticles() {
    const { token, id } = this.props
    const { selectedSpeciality } = this.state

    Axios.get(
      `${api}/api/vaccine_articles?speciality.id=${selectedSpeciality}&vaccinesBatches.nitrogenContainerVaccinesBatches.nitrogenContainer.id=${id}`,
      {
        headers: { Authorization: token }
      }
    )
      .then(res => {
        this.setState({ articles: res.data['hydra:member'] }, () => M.AutoInit())
      })
      .catch(err => catchError(err.response))
  }

  loadBatches() {
    const { token, id } = this.props
    const { selectedArticle } = this.state
    Axios.get(
      `${api}/api/container_vaccines?vaccinesBatch.vaccineArticle.name=${selectedArticle}&nitrogenContainer.id=${id}`,
      {
        headers: { Authorization: token }
      }
    )
      .then(res => {
        this.setState({ batches: res.data['hydra:member'] }, () => M.AutoInit())
      })
      .catch(err => catchError(err.response))
  }

  loadDetails() {
    const { token } = this.props
    const { selectedBatch } = this.state
    Axios.get(`${api}/api/container_vaccines/${selectedBatch}`, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({ details: res.data }, () => M.AutoInit())
      })
      .catch(err => catchError(err.response))
  }

  loadAllVaccines() {
    const { token, id, setContainerVaccinesBatchesSelected } = this.props
    Axios.get(`${api}/api/container_vaccines?nitrogenContainer.id=${id}`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const vaccinesBatches = res.data['hydra:member']
        let containerVaccinesBatchesIndex = {
          ampoulesNumberToTransfer: 0,
          id: ''
        }
        const containerVaccinesBatchesSelected = []
        let ampoulesNumberForAll = 0
        for (let i = 0; i < vaccinesBatches.length; i += 1) {
          ampoulesNumberForAll += vaccinesBatches[i].ampoulesNumber
          containerVaccinesBatchesIndex = {
            ampoulesNumberToTransfer: vaccinesBatches[i].vaccinesBatch.ampoulesNumber,
            id: vaccinesBatches[i].id
          }
          containerVaccinesBatchesSelected.push(containerVaccinesBatchesIndex)
        }
        this.setState({ ampoulesNumberToTransfer: ampoulesNumberForAll })
        setContainerVaccinesBatchesSelected(containerVaccinesBatchesSelected)
      })
      .catch(err => catchError(err.response))
  }

  handleChange(e) {
    const { name, value } = e.target
    const { intl, setDisabled, index } = this.props
    const { details, selectedBatch, allSelected } = this.state
    switch (name) {
      case `speciality${index}`:
        this.setState({ selectedSpeciality: value }, () => this.loadArticles())

        if (value === intl.formatMessage({ id: 'app.label.allTransfer' })) {
          this.setState(
            { allSelected: true, disabledArticles: true, disabledBatches: true },
            () => {
              document.getElementById(`selectedArticle${index}`).value = '0'
              document.getElementById(`selectedBatch${index}`).value = '0'
              this.loadAllVaccines()
            }
          )
        } else {
          this.setState(
            { allSelected: false, disabledArticles: false, disabledBatches: true },
            () => {
              document.getElementById(`selectedArticle${index}`).value = '0'
              document.getElementById(`selectedBatch${index}`).value = '0'
              document.getElementById(`valence${index}`).value = ''
              document.getElementById(`expiration${index}`).value = ''
              document.getElementById(`ampoulesNumber${index}`).value = ''
              document.getElementById(`ampoulesNumberToTransfer${index}`).value = ''
              setDisabled(true)
            }
          )
        }
        break
      case `selectedArticle${index}`:
        this.setState({ selectedArticle: value, disabledBatches: false }, () => this.loadBatches())
        break
      case `selectedBatch${index}`:
        this.setState({ allSelected: false })
        this.setState({ selectedBatch: value }, () => this.loadDetails())
        this.getVaccinesSelected()
        break
      case `ampoulesNumberToTransfer${index}`:
        if (
          document.getElementById(`speciality${index}`).value === '0' ||
          document.getElementById(`selectedArticle${index}`).value === '0' ||
          document.getElementById(`selectedBatch${index}`).value === '0' ||
          document.getElementById(`ampoulesNumberToTransfer${index}`).value === ''
        ) {
          setDisabled(true)
        } else {
          setDisabled(false)
        }
        if (!allSelected) {
          if (details && details.ampoulesNumber >= value) {
            this.setState({
              containerVaccinesBatches: {
                ampoulesNumberToTransfer: value,
                id: selectedBatch
              }
            })
          }
        }

        break
      default:
        return false
    }
    return true
  }

  render() {
    const {
      specialites,
      loading,
      articles,
      containerVaccinesBatches,
      batches,
      details,
      allSelected,
      ampoulesNumberToTransfer,
      vaccinesSelected,
      disabledArticles,
      disabledBatches
    } = this.state
    const { intl, index, disabledForm } = this.props
    const { getValenceLabelByKey } = this.context

    if (loading) {
      return <LoadingPage />
    }

    return (
      <div className="input">
        <div className="divider greyDivider" />
        <div className="batchForm">
          <div className="row">
            <div className="input col m6">
              <label htmlFor={`speciality${index}`} className="active">
                {intl.formatMessage({ id: `app.label.speciality` })}
              </label>
              <select
                className="select-wrapper"
                id={`speciality${index}`}
                name={`speciality${index}`}
                onChange={this.handleChange}
                defaultValue="0"
                disabled={disabledForm[index]}
              >
                <option value="0" disabled />
                {specialites && (
                  <option value={intl.formatMessage({ id: 'app.label.allTransfer' })}>
                    {intl.formatMessage({ id: 'app.label.allTransfer' })}
                  </option>
                )}
                {specialites.map(speciality => (
                  <option key={speciality.id} value={speciality.id}>
                    {speciality.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="input col m3">
              <label htmlFor={`selectedArticle${index}`} className="active">
                {intl.formatMessage({ id: `app.label.article` })}
              </label>
              <select
                className="select-wrapper"
                id={`selectedArticle${index}`}
                name={`selectedArticle${index}`}
                onChange={this.handleChange}
                defaultValue="0"
                disabled={disabledForm[index] || disabledArticles}
              >
                <option value="0" disabled />
                {articles.map(article => (
                  <option key={article.name} value={article.name}>
                    {article.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="input col m3">
              <label htmlFor={`selectedBatch${index}`} className="active">
                {intl.formatMessage({ id: `app.label.batchesNumber` })}
              </label>
              <select
                className="select-wrapper"
                id={`selectedBatch${index}`}
                name={`selectedBatch${index}`}
                onChange={this.handleChange}
                defaultValue="0"
                disabled={disabledForm[index] || disabledBatches}
              >
                <option value="0" disabled />
                {batches &&
                  batches.map(
                    batch =>
                      vaccinesSelected.indexOf(batch.id) === -1 && (
                        <option key={batch.id} value={batch.id}>
                          {batch.vaccinesBatch.batchNumber}
                        </option>
                      )
                  )}
              </select>
            </div>
          </div>

          <div className="row">
            {!allSelected && (
              <div className="input col l3 m4">
                <label htmlFor={`valence${index}`} className="active">
                  {intl.formatMessage({ id: `app.label.valence` })}
                </label>
                <input
                  disabled
                  value={
                    details
                      ? getValenceLabelByKey(
                          details.vaccinesBatch.vaccineArticle.speciality.valence
                        )
                      : ''
                  }
                  id={`valence${index}`}
                  name={`valence${index}`}
                  type="text"
                />
              </div>
            )}

            {!allSelected && (
              <div className="input col l3 m4">
                <label htmlFor={`expiration${index}`} className="active">
                  {intl.formatMessage({ id: `app.label.expirationDate` })}
                </label>
                <input
                  disabled
                  value={
                    details ? moment(details.vaccinesBatch.expirationDate).format('DD/MM/YYYY') : ''
                  }
                  id={`expiration${index}`}
                  name={`expiration${index}`}
                  type="text"
                />
              </div>
            )}
            {!allSelected && (
              <div className="input col l3 m4">
                <label htmlFor={`ampoulesNumber${index}`} className="active">
                  {intl.formatMessage({ id: `app.label.bulbsNumber` })}
                </label>
                <input
                  disabled
                  value={details ? details.ampoulesNumber : ''}
                  id={`ampoulesNumber${index}`}
                  name={`ampoulesNumber${index}`}
                  type="text"
                />
              </div>
            )}
            {allSelected && (
              <div className="input col l3 m4">
                <label htmlFor={`ampoulesNumberToTransfer${index}`} className="active">
                  {intl.formatMessage({ id: `app.label.bulbsMove` })}
                </label>
                <input
                  onChange={this.handleChange}
                  value={ampoulesNumberToTransfer}
                  id={`ampoulesNumberToTransfer${index}`}
                  type="number"
                  required
                  name={`ampoulesNumberToTransfer${index}`}
                  disabled={allSelected}
                  max={details && details.ampoulesNumberToTransfer}
                />
              </div>
            )}
            {!allSelected && (
              <div className="input col l3 m4">
                <label htmlFor={`ampoulesNumberToTransfer${index}`} className="active">
                  {intl.formatMessage({ id: `app.label.bulbsMove` })}
                </label>
                <input
                  onChange={this.handleChange}
                  value={containerVaccinesBatches.ampoulesNumberToTransfer || ''}
                  id={`ampoulesNumberToTransfer${index}`}
                  type="number"
                  required
                  name={`ampoulesNumberToTransfer${index}`}
                  disabled={disabledForm[index]}
                  max={details ? details.ampoulesNumberToTransfer : ''}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(BatchForm)
