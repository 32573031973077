import React from 'react'
import { withRouter } from 'react-router'
import { injectIntl } from 'react-intl'
import Axios from 'axios'
import { connect } from 'react-redux'
import moment from 'moment'
import { closePage, validatedAction } from '../../components/globalAction'
import { api } from '../../parameters'
import { catchError } from '../../actions'
import DatepickerBlock from '../../components/DatepickerBlock'

class AddDayWithoutHatching extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.getDataFromChild = this.getDataFromChild.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidUpdate(_prevProps, prevState) {
    const { dateSelected1, dateSelected2 } = this.state

    if (prevState.dateSelected1 !== dateSelected1) {
      if (dateSelected2 < dateSelected1) {
        this.setState({ dateSelected2: dateSelected1 })
      }
    }

    if (prevState.dateSelected2 !== dateSelected2) {
      if (dateSelected2 < dateSelected1) {
        this.setState({ dateSelected2: dateSelected1 })
      }
    }
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  handleSubmit() {
    const { token } = this.props
    const { dateSelected1 } = this.state
    let { dateSelected2 } = this.state

    if (dateSelected2 === undefined) {
      dateSelected2 = dateSelected1
    }

    const formData = {
      start: moment(dateSelected1).format(),
      end: moment(dateSelected2).format()
    }

    Axios.post(`${api}/api/no_hatching_periods`, formData, { headers: { Authorization: token } })
      .then(() => validatedAction(''))
      .catch(err => catchError(err))
  }

  render() {
    const { intl } = this.props
    const { dateSelected1, dateSelected2 } = this.state

    return (
      <div className=" row Container params">
        <div className="col s12 content">
          <div className="row center marginTop30">
            <div className="col s11">
              <h5 className="labelCustom">
                {intl.formatMessage({ id: 'app.label.addDayWithoutHatching' })}
              </h5>
            </div>
            <div className="col s1">
              <div
                className="goBackBtn right"
                role="button"
                tabIndex="0"
                onKeyPress={() => closePage(intl, false)}
                onClick={() => closePage(intl, false)}
              >
                <i className="material-icons">close</i>
              </div>
            </div>
          </div>
          <div className="row col s12 card">
            <div className="row col s12">
              <div className="col s12 form">
                <form>
                  <div className="row">
                    <div className="col s6">
                      <DatepickerBlock
                        label={intl.formatMessage({ id: 'app.label.startDate' })}
                        setDataFromChild={this.getDataFromChild}
                        name="startDate"
                        number={1}
                        required
                        value={dateSelected1 && moment(dateSelected1).format('DD/MM/YYYY')}
                      />
                    </div>
                    <div className="col s6">
                      <DatepickerBlock
                        label={intl.formatMessage({ id: 'app.label.endDate' })}
                        setDataFromChild={this.getDataFromChild}
                        name="endDate"
                        number={2}
                        disabled={undefined === dateSelected1}
                        minDate={dateSelected1}
                        value={dateSelected2 && moment(dateSelected2).format('DD/MM/YYYY')}
                      />
                    </div>
                  </div>
                </form>
                <div className="row">
                  <div className="col offset-s5 s7 marginTop30">
                    <button type="button" className="btn blueBtn" onClick={this.handleSubmit}>
                      {intl.formatMessage({ id: 'app.label.save' })}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(AddDayWithoutHatching)))
