import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { catchError } from '../../../../actions'
import CenterButton from '../../../../components/CenterButton'
import { validatedAction } from '../../../../components/globalAction'
import LoadingPage from '../../../../components/LoadingPage'
import ReportProblemHeader from '../../../../components/Vaccination/Realisation/Process/InjectableVaccinePart/ProductBagsPart/ReportProblem/ReportProblemHeader'
import ReportProblemOrderBlockInfo from '../../../../components/Vaccination/Realisation/Process/InjectableVaccinePart/ProductBagsPart/ReportProblem/ReportProblemOrderBlockInfo'
import ReportProblemFormDamagedVaccine from '../../../../components/Vaccination/Realisation/Process/partials/VaccineScan/ReportProblem/ReportProblemFormDamagedVaccine/ReportProblemFormDamagedVaccine'
import ReportProblemFormRadioInput from '../../../../components/Vaccination/Realisation/Process/partials/VaccineScan/ReportProblem/ReportProblemFormRadioInput'
import ReportProblemSelectNewVaccine from '../../../../components/Vaccination/Realisation/Process/partials/VaccineScan/ReportProblem/ReportProblemSelectNewVaccine/ReportProblemSelectNewVaccine'
import { api } from '../../../../parameters'

class ReportProblem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      part: 0,
      disabledButton: true,
      loading: true,
      newVaccinesToSubmit: [],
      disabledList: []
    }
    this.getDataFromChild = this.getDataFromChild.bind(this)
    this.changePart = this.changePart.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const { match, token } = this.props
    const { id } = match.params

    Axios.get(`${api}/api/order_order_preparations/${id}`, { headers: { authorization: token } })
      .then(res => {
        this.setState({ preparation: res.data, loading: false })
      })
      .catch(err => catchError(err.response))
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  getContent() {
    const { intl, match } = this.props
    const {
      disabledButton,
      newVaccinesToSubmit,
      disabledList,
      part,
      preparation,
      type,
      vaccinesSelected
    } = this.state

    const { vaccineType } = match.params
    let btnLabel = 'searchAnOtherVial'

    if (vaccineType === '0') {
      btnLabel = 'searchAnOtherBulb'
    }

    switch (part) {
      case 0:
        return (
          <>
            <ReportProblemHeader />
            <ReportProblemOrderBlockInfo preparation={preparation} vaccineType={vaccineType} />
            <ReportProblemFormRadioInput
              getDataFromChild={this.getDataFromChild}
              preparation={preparation}
            />
            {type && (
              <>
                <ReportProblemFormDamagedVaccine
                  getDataFromChild={this.getDataFromChild}
                  type={type}
                  preparation={preparation}
                  disabledButton={disabledButton}
                  disabledList={disabledList}
                />
                <CenterButton
                  text={intl.formatMessage({ id: `app.button.${btnLabel}` })}
                  onClick={this.changePart}
                  disabled={disabledButton}
                />
              </>
            )}
          </>
        )
      default:
        return (
          <>
            <ReportProblemSelectNewVaccine
              getDataFromChild={this.getDataFromChild}
              vaccinesSelected={vaccinesSelected}
              newVaccinesToSubmit={newVaccinesToSubmit}
              disabledList={disabledList}
              type={type}
            />
            <CenterButton
              text={intl.formatMessage({ id: 'app.button.validate' })}
              onClick={this.handleSubmit}
              disabled={disabledList.includes(true)}
            />
          </>
        )
    }
  }

  handleSubmit() {
    const { match, token } = this.props
    const { newVaccinesToSubmit, type } = this.state
    const { id } = match.params

    const dataTosubmit = {
      type,
      vaccines: newVaccinesToSubmit
    }

    Axios.patch(`${api}/api/report_problem/${id}`, dataTosubmit, {
      headers: { authorization: token }
    })
      .then(() => {
        validatedAction('')
      })
      .catch(err => catchError(err.response))
  }

  changePart() {
    let { part } = this.state
    part += 1
    this.setState({ part })
  }

  render() {
    const { loading } = this.state

    if (loading) {
      return <LoadingPage />
    }

    return <div className="row Container stockAvailibilityItem">{this.getContent()}</div>
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(ReportProblem)))
