import Axios from 'axios'
import React from 'react'
import { connect } from 'react-redux'
import { catchError } from '../../../../../actions'
import { api } from '../../../../../parameters'
import ChickNumberBlock from './partials/ChickNumberBlock'
import OrderInfosBlock from './partials/OrderInfosBlock'
import ReserveVaccineBlock from './partials/ReserveVaccineBlock/ReserveVaccineBlock'

class ChicksPerBoxStep extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      result: {
        reservedVaccine: false,
        reservationDate: ''
      }
    }
    this.getStateFromChild = this.getStateFromChild.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  getStateFromChild(hiddenBoxPart, result) {
    this.setState({ hiddenBoxPart, ...result })
  }

  handleSubmit() {
    const { changePart, id, token } = this.props
    const { result } = this.state

    Axios.patch(`${api}/api/vaccination/preparation/informations/${id}`, result, {
      headers: { Authorization: token }
    })
      .then(() => changePart(result.boxesNumber))
      .catch(err => catchError(err.response))
  }

  render() {
    const { group } = this.props
    const { hiddenBoxPart, result } = this.state

    return (
      <>
        <OrderInfosBlock group={group} />
        <ChickNumberBlock
          chicksNb={group.totalChicks}
          data={result}
          setStateToParent={this.getStateFromChild}
        />
        {hiddenBoxPart !== true && (
          <ReserveVaccineBlock
            data={result}
            handleSubmit={this.handleSubmit}
            setStateToParent={this.getStateFromChild}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default connect(mapStateToProps)(ChicksPerBoxStep)
