import React from 'react'
import { injectIntl } from 'react-intl'
import GeneralInfos from './partials/GeneralInfos'
import MoreInfos from './partials/MoreInfos/MoreInfos'
import ValidateButton from './partials/ValidateButton'

class OrderGroupItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMore: false
    }

    this.toggleMore = this.toggleMore.bind(this)
  }

  toggleMore() {
    const { showMore } = this.state

    this.setState({ showMore: !showMore })
  }

  render() {
    const { group, number } = this.props
    const { showMore } = this.state

    return (
      <div className="orderGroupItem">
        <GeneralInfos
          group={group[0]}
          number={number}
          onClick={this.toggleMore}
          showMore={showMore}
        />
        <MoreInfos group={group} show={showMore} />
        <ValidateButton number={number} show={showMore} />
      </div>
    )
  }
}

export default injectIntl(OrderGroupItem)
