import React from 'react'
import { injectIntl } from 'react-intl'

class ControlPart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { conservation, intl, handleChange, type } = this.props

    let content

    if (conservation === 0) {
      content = (
        <>
          <label htmlFor="nitrogenLevel" className="labelParams">
            {intl.formatMessage({ id: 'app.label.newNitrogenLevel' })}
          </label>
          <input name="nitrogenLevel" type="number" onChange={handleChange} required />
        </>
      )
    } else if (conservation === 1 && type === 12) {
      content = (
        <>
          <div className="col s4">
            <label htmlFor="fridgeMin" className="labelParams">
              {intl.formatMessage({ id: 'app.equipment.lastControl.minTemperature' })}
            </label>
            <input name="fridgeMin" type="number" step="0.1" onChange={handleChange} required />
          </div>
          <div className="col s4">
            <label htmlFor="fridgeMax" className="labelParams">
              {intl.formatMessage({ id: 'app.equipment.lastControl.maxTemperature' })}
            </label>
            <input name="fridgeMax" type="number" step="0.1" onChange={handleChange} required />
          </div>
          <div className="col s4">
            <label htmlFor="fridgeInstant" className="labelParams">
              {intl.formatMessage({ id: 'app.equipment.lastControl.instantTemperature' })}
            </label>
            <input name="fridgeInstant" type="number" step="0.1" onChange={handleChange} required />
          </div>
        </>
      )
    }

    return <>{content}</>
  }
}

export default injectIntl(ControlPart)
