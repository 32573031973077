import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { catchError } from '../../../../../actions'
import { dosesLeftListBottle } from '../../../../../config/dosesLeftListBottle'
import { dosesLeftListProductBag } from '../../../../../config/dosesLeftListProductBag'
import { api } from '../../../../../parameters'

class SinglePartRadios extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      comment: '',
      continueToUse: false,
      dosesLeft: 0,
      emptyContainer: true
    }

    this.handleCheck = this.handleCheck.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleCheck(e) {
    const { name, checked } = e.target

    this.setState({ [name]: checked }, () => M.FormSelect.init(document.querySelectorAll('select')))
  }

  handleSelect(e) {
    const { name, value } = e.target

    this.setState({ [name]: parseInt(value) })
  }

  handleChange(e) {
    const { name, value } = e.target

    this.setState({ [name]: value })
  }

  handleSubmit() {
    const { history, prepId, token } = this.props

    Axios.patch(`${api}/api/vaccination/end_realisation/${prepId}`, this.state, {
      headers: { Authorization: token }
    })
      .then(() => history.goBack())
      .catch(err => catchError(err.response))
  }

  render() {
    const { container, intl } = this.props
    const { emptyContainer, continueToUse } = this.state

    let dosesLeftList
    if (container === 'pBag') {
      dosesLeftList = dosesLeftListProductBag
    }

    if (container === 'bottle') {
      dosesLeftList = dosesLeftListBottle
    }

    return (
      <>
        <div className="row form custom-card">
          <div className="card-content">
            <div className="col s6 card-block flex flexSpaceBetween">
              <p className="bold labelCustom blue-text">
                {intl.formatMessage({ id: `app.label.empty${container}` })}
              </p>
              <div className="switch">
                <label>
                  <span className="bold labelCustom">
                    {intl.formatMessage({ id: `app.label.no` })}
                  </span>
                  <input
                    type="checkbox"
                    name="emptyContainer"
                    onChange={this.handleCheck}
                    checked={emptyContainer}
                  />
                  <span className="lever" />
                  <span className="bold labelCustom">
                    {intl.formatMessage({ id: `app.label.yes` })}
                  </span>
                </label>
              </div>
            </div>
            <div className="row card-content">
              {!emptyContainer && (
                <>
                  <div className="divider greyDivider" />
                  <div className="row card-block">
                    <div className="col s6 input">
                      <label htmlFor="dosesLeft">
                        {intl.formatMessage({ id: 'app.label.doseLeftNumber' })}
                      </label>
                      <select
                        className="select-wrapper"
                        name="dosesLeft"
                        defaultValue="0"
                        onChange={this.handleSelect}
                      >
                        <option value="0" disabled>
                          {intl.formatMessage({ id: 'app.label.selectDose' })}
                        </option>
                        {dosesLeftList.map(dose => (
                          <option key={dose.doseNumber} value={dose.doseNumber}>
                            {dose.doseNumber}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {!emptyContainer && (
          <div className="row form custom-card">
            <div className="row card-content">
              <div className="col s6 card-block flex flexSpaceBetween">
                <p className="bold labelCustom blue-text">
                  {intl.formatMessage({ id: `app.label.${container}UseContinue` })}
                </p>
                <div className="switch">
                  <label>
                    <span className="bold labelCustom">
                      {intl.formatMessage({ id: `app.label.no` })}
                    </span>
                    <input
                      type="checkbox"
                      name="continueToUse"
                      onChange={this.handleCheck}
                      checked={continueToUse}
                    />
                    <span className="lever" />
                    <span className="bold labelCustom">
                      {intl.formatMessage({ id: `app.label.yes` })}
                    </span>
                  </label>
                </div>
              </div>
              {!continueToUse && (
                <>
                  <div className="divider greyDivider" />
                  <div className="card-block input">
                    <label htmlFor="comment" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.addCommentary' })}
                    </label>
                    <textarea
                      className="materialize-textarea"
                      name="comment"
                      onChange={this.handleChange}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col s12 flex flex-center">
            <button className="btn blueBtn" type="button" onClick={this.handleSubmit}>
              {intl.formatMessage({ id: 'app.button.validateAndFinalize' })}
            </button>
          </div>
        </div>
      </>
    )
  }
}

const mapStatetoProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(withRouter(connect(mapStatetoProps)(SinglePartRadios)))
