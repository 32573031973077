import React from 'react'
import { injectIntl } from 'react-intl'

const TbodyProductsBag = props => {
  const {
    checked,
    handleChangeCheckBoxes,
    id,
    index,
    productBag,
    intl,
    length,
    state,
    vaccineType,
    shared,
    reuse
  } = props
  let label = ''

  if (shared === true) {
    label = `(${intl.formatMessage({ id: 'app.word.shared' })})`
  }

  if (reuse === true) {
    label = `(${intl.formatMessage({ id: 'app.word.reusable' })})`
  }

  return (
    <tbody disabled={state !== 0}>
      <tr>
        <td>{`${index + 1}/${length} ${label}`}</td>
        {vaccineType === 0 && <td>{productBag.batchNumber}</td>}
        <td>{intl.formatMessage({ id: `app.preparation.progression.state.${state}` })} </td>
        <td>
          <div className="input">
            <label>
              <input
                id={id}
                type="checkbox"
                className="filled-in checkbox"
                name="preparationSelected"
                disabled={state !== 0}
                onChange={handleChangeCheckBoxes}
                checked={checked}
              />
              <span />
            </label>
          </div>
        </td>
      </tr>
    </tbody>
  )
}

export default injectIntl(TbodyProductsBag)
