import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { catchError } from '../../../actions'
import icons from '../../../assets/icons/icons'
import params from '../../../parameters'
import LoadingPage from '../../LoadingPage'
import ConformitiesListView from '../ConformitiesListView'

class ValidationPart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      parameters: [],
      loading: false
    }
  }

  componentDidMount() {
    const { token } = this.props

    Axios.get(`${params.api}/api/vaccination_parameters`, {
      headers: {
        Authorization: token
      }
    })
      .then(res => {
        this.setState({ parameters: res.data['hydra:member'], loading: false })
      })
      .catch(err => catchError(err.response))
  }

  render() {
    const { intl } = this.props
    const { loading, parameters } = this.state

    if (loading === true) {
      return <LoadingPage />
    }

    return (
      <div>
        <div className="row">
          <div className="col s12">
            {parameters.map(param => (
              <div key={param.paramId}>
                {param.paramId > 5 && param.paramId < 8 && (
                  <div className="card paramListItem" key={`param-${param.id}`}>
                    <div className="paramHeader">
                      <div className="paramTitle">
                        {intl.formatMessage({ id: `app.title.vaccinationParam.${param.paramId}` })}
                      </div>
                      <Link to={`/parameters/parameter-vaccination/edit/${param.id}`}>
                        <img src={icons.blackPen} alt="black pen icon" />
                      </Link>
                    </div>
                    <div>
                      <div className="divider greyDivider" />
                      <div className="paramListItemContent">
                        <ConformitiesListView
                          conformity={param.conformityAlert.value}
                          nonBlocking={param.nonBlockingAlert.value}
                          blocking={param.blockingAlert.value}
                          vaccinationParamId={param.paramId}
                          unit={intl.formatMessage({ id: 'app.unit.percent' })}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(ValidationPart))
