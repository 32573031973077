import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { catchError } from '../../../../../actions'
import { api } from '../../../../../parameters'
import CenterButton from '../../../../CenterButton'
import DiluentReceptionForm from './DiluentReceptionForm'

class DiluentReceptionPart extends React.Component {
  constructor() {
    super()
    this.state = {
      numbers: [],
      diluents: [{}],
      allFridgeSelected: []
    }

    this.addDiluent = this.addDiluent.bind(this)
    this.getDate = this.getDate.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.loadBatchNumbers = this.loadBatchNumbers.bind(this)
    this.isDisabled = this.isDisabled.bind(this)
  }

  componentDidMount() {
    const { fridgeSelected, fridges } = this.props
    const allFridgeSelected = []

    fridgeSelected.forEach(fridgeId => {
      for (let i = 0; i < fridges.length; i++) {
        const fridge = fridges[i]
        if (fridge.id === fridgeId.id) {
          fridge.diluents = [{}]
          allFridgeSelected.push(fridge)
        }
      }
    })

    this.setState({ allFridgeSelected })
    this.loadBatchNumbers()
  }

  getDate(_name, value, fridgeIndex, diluentIndex) {
    const { allFridgeSelected } = this.state
    const { diluents } = allFridgeSelected[fridgeIndex]
    const { setDataToParent } = this.props

    diluents[diluentIndex].expirationDate = moment(value).format()
    this.setState({ diluents }, () => setDataToParent('allFridgeSelected', allFridgeSelected))
  }

  handleChange(e, dyeIndex, fridgeIndex) {
    const { allFridgeSelected } = this.state
    const { setDataToParent } = this.props
    const { name, value } = e.target
    const { diluents } = allFridgeSelected[fridgeIndex]
    diluents[dyeIndex][name] = value

    this.setState({ diluents }, () => setDataToParent('allFridgeSelected', allFridgeSelected))
  }

  loadBatchNumbers() {
    const { token } = this.props

    Axios.get(`${api}/api/diluent_batches/batch_numbers`, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({ numbers: res.data })
      })
      .catch(err => catchError(err.response))
  }

  addDiluent(index) {
    const { allFridgeSelected } = this.state
    const { setDataToParent } = this.props
    const { diluents } = allFridgeSelected[index]

    diluents.push({})
    this.setState({ allFridgeSelected }, () => setDataToParent('diluents', diluents))
  }

  isDisabled() {
    const { allFridgeSelected } = this.state

    const disabledList = [false]
    for (let i = 0; i < allFridgeSelected.length; i++) {
      const fridge = allFridgeSelected[i]

      let disabled = false

      for (let i2 = 0; i2 < fridge.diluents.length; i2++) {
        const diluent = fridge.diluents[i2]

        disabled =
          diluent.batchNumber === undefined ||
          diluent.batchNumber === '' ||
          diluent.expirationDate === undefined ||
          diluent.expirationDate === '' ||
          diluent.provider === undefined ||
          diluent.provider === '' ||
          diluent.unitsNumber === undefined ||
          diluent.unitsNumber === ''

        disabledList[i] = disabled

        if (disabled === true) {
          break
        }
      }

      if (disabled === true) {
        break
      }
    }

    return disabledList.includes(true)
  }

  handleClick() {
    const { diluents, numbers } = this.state
    const { changePart, intl } = this.props
    let validForm = true

    diluents.forEach(diluent => {
      if (
        !diluent.provider ||
        !diluent.batchNumber ||
        !diluent.expirationDate ||
        !diluent.unitsNumber
      ) {
        validForm = false
      }
    })

    if (!validForm) {
      return Swal.fire({
        type: 'error',
        title: intl.formatMessage({ id: 'app.alert.error' }),
        text: intl.formatMessage({ id: 'app.alert.emptyFields' }),
        customClass: {
          icon: 'swalErrorIcon',
          title: 'swalTitle',
          content: 'swalContentText',
          confirmButton: 'blueBtn'
        }
      })
    }

    let swal = false
    let swalDate = false
    const numbersToEdit = []

    diluents.forEach(batch => {
      if (moment().isSameOrAfter(batch.expirationDate) === true) {
        swalDate = true
      }

      if (numbers.includes(batch.batchNumber)) {
        if (swal === false) swal = true

        numbersToEdit.push(batch.batchNumber)
      }
    })

    if (swal === true) {
      Swal.fire({
        type: 'warning',
        text: intl.formatMessage({ id: 'app.swal.message.existingBatchNumber' }, { numbersToEdit }),
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({ id: 'app.label.yes' }),
        cancelButtonText: intl.formatMessage({ id: 'app.label.no' }),
        customClass: {
          icon: 'swalOrangeIcon',
          content: 'swalContentText',
          confirmButton: 'blueBtn',
          cancelButton: 'redBtn'
        }
      }).then(res => {
        if (swalDate === true) {
          this.fireExpDateSwal(numbersToEdit)
        } else if (res.value === true) {
          changePart()
        }
      })
    } else if (swalDate === true) {
      return this.fireExpDateSwal(numbersToEdit)
    } else {
      return changePart()
    }
  }

  fireExpDateSwal(numbersToEdit) {
    const { intl } = this.props

    Swal.fire({
      type: 'warning',
      text: intl.formatMessage({ id: 'app.swal.message.expirationDateVaccine' }, { numbersToEdit }),
      confirmButtonText: intl.formatMessage({ id: 'app.label.ok' }),
      customClass: {
        icon: 'swalOrangeIcon',
        content: 'swalContentText',
        confirmButton: 'blueBtn'
      }
    })
  }

  render() {
    const { allFridgeSelected } = this.state
    const { intl } = this.props

    return (
      <>
        <div className="row">
          <div className="col s12">
            {allFridgeSelected.map((fridge, index) => (
              <div key={fridge}>
                <h2 className="titleDiluent">{fridge.name}</h2>
                <div className="form row col s12">
                  {fridge.diluents.map((_diluent, i) => (
                    <div className="row" key={i}>
                      <div className="col s12 row">
                        <h6 className="subTitleDiluent">Diluant n°{i + 1}</h6>
                      </div>
                      <DiluentReceptionForm
                        key={i}
                        index={i}
                        fridgeIndex={index}
                        currentDiluent={allFridgeSelected[index].diluents[i]}
                        handleChange={e => this.handleChange(e, i, index)}
                        getDate={this.getDate}
                      />
                    </div>
                  ))}
                  <CenterButton
                    text={intl.formatMessage({ id: 'app.label.add' })}
                    onClick={() => this.addDiluent(index)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <CenterButton
          text={intl.formatMessage({ id: 'app.label.next' })}
          onClick={this.handleClick}
          disabled={this.isDisabled()}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token
})

export default injectIntl(connect(mapStateToProps)(DiluentReceptionPart))
