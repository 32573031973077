import React from 'react'
import { injectIntl } from 'react-intl'
import CardHeader from '../partials/CardHeader'
import PrepInfos from '../partials/PrepInfos'
import DamagedBagPb from './partials/DamagedBagPb'
import FridgeVaccPb from './partials/FridgeVaccPb'
import FrozenVaccPb from './partials/FrozenVaccPb'
import ProblemTitle from './ProblemTitle'

const ProblemReport = props => {
  const { hist, intl } = props
  const { action: data } = hist.history
  let content = ''

  switch (data.type) {
    case 0:
    case 1:
      content = <DamagedBagPb changes={data.changes} />
      break
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
      content = <FrozenVaccPb changes={data.changes} />
      break
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
      content = <FridgeVaccPb changes={data.changes} />
      break
    default:
  }
  return (
    <div className="vaccinationHistory">
      <div className="row historyCard">
        <CardHeader date={hist.createdAt} operator={hist.firstName} />
        <div className="col s12 card" key={data.containerNumber}>
          <div className="row line">
            <div className="col s6">
              <span className="bold">{intl.formatMessage({ id: 'app.label.orderNumber' })}: </span>{' '}
              {hist.history.orderNumber}
            </div>
            <div className="col s6">
              <span className="bold">{intl.formatMessage({ id: 'app.label.qrNumber' })}: </span>{' '}
              {hist.history.action.preparation.qr}
            </div>
          </div>
          <div className="divider greyDivider" />
          <ProblemTitle type={data.type} />
          <div className="row line">
            <PrepInfos prep={data.preparation} customClass="col s12" />
          </div>
          <div className="row line">{content}</div>
        </div>
      </div>
    </div>
  )
}
export default injectIntl(ProblemReport)
