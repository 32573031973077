import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { catchError } from '../../actions'
import roles from '../../config/userRoles.json'
import { api } from '../../parameters'
import LoadingPage from '../LoadingPage'
import DragAndDropPlanningTable from './partials/DragAndDropPlanningTable'
import PlanningHeader from './partials/PlanningHeader'
import PlanningTable from './partials/PlanningTable'
import PlanningContext from '../../context/PlanningContext'

class Planning extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dragAndDrop: false,
      deleteOrders: false,
      loading: true,
      contentStatus: false,
      filters: [
        { category: 'order[prestations]', sortBy: ['asc', 'desc'] },
        { category: 'order[product]', sortBy: ['asc', 'desc'] },
        { category: 'order[number]', sortBy: ['asc', 'desc'] }
      ],
      reordered: false,
      url: `${api}/api/planning_order_groups`
    }
    this.getStateFromChild = this.getStateFromChild.bind(this)
    this.getSequenceFromChild = this.getSequenceFromChild.bind(this)
    this.selectWeek = this.selectWeek.bind(this)
    this.selectDay = this.selectDay.bind(this)
    this.filterByDate = this.filterByDate.bind(this)
    this.inLoad = this.inLoad.bind(this)
    this.toggleDragAndDrop = this.toggleDragAndDrop.bind(this)
    this.toggleDeleteOrders = this.toggleDeleteOrders.bind(this)
  }

  componentDidMount() {
    const { isPreparation, isRealisation, role, token } = this.props
    const { ROLE_OPERATOR } = roles
    const { setOrdersList } = this.context

    const currentDate = moment()
    let isVaccination = false
    let step = 0

    if (currentDate)
      if (isPreparation) {
        isVaccination = true
        step = 1
      }

    if (isRealisation) {
      isVaccination = true
      step = 2
    }

    if (role.includes(ROLE_OPERATOR)) {
      const currentDay = moment(currentDate)
      const currentDayToAxiosAfter = currentDate.format('YYYY-MM-DD')
      const currentDayToAxiosBefore = moment(currentDay.format())
        .add(1, 'd')
        .format('YYYY-MM-DD')

      const url = `${api}/api/planning_order_groups?hatchingDate[strictly_before]=${currentDayToAxiosBefore}&hatchingDate[after]=${currentDayToAxiosAfter}&order[hatchingDate]=asc&order[sequence]=asc${
        isVaccination === true ? `&step=${step}` : ''
      }`

      this.setState({
        selectedDay: currentDay,
        currentDate
      })

      Axios.get(`${url}&order[sequence]=asc`, {
        headers: { Authorization: token }
      })
        .then(res => {
          setOrdersList(res.data['hydra:member'])
          this.setState({
            url,
            loading: false,
            totalItems: res.data['hydra:totalItems'],
            contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult',
            isVaccination,
            step
          })
        })
        .catch(err => catchError(err))
    } else {
      const currentWeek = currentDate.format('W')
      const currentWeekToAxios = `${currentDate.format('GGGG')}_${currentWeek}`

      const url = `${api}/api/planning_order_groups?hatchingWeek=${currentWeekToAxios}&order[hatchingDate]=asc&order[sequence]=asc${
        isVaccination === true ? `&step=${step}` : ''
      }`

      this.setState({
        selectedWeek: currentWeek,
        weekToExport: currentWeekToAxios,
        currentDate
      })

      Axios.get(`${url}&order[sequence]=asc`, {
        headers: { Authorization: token }
      })
        .then(res => {
          setOrdersList(res.data['hydra:member'])
          this.setState({
            url,
            isVaccination,
            loading: false,
            totalItems: res.data['hydra:totalItems'],
            contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult',
            step
          })
        })
        .catch(err => catchError(err))
    }
  }

  getStateFromChild(data, infiniteLoader = false) {
    let { contentStatus } = this.state
    const { ordersList, setOrdersList } = this.context
    if (infiniteLoader) {
      setOrdersList([...ordersList, ...data['hydra:member']])
    } else {
      setOrdersList(data['hydra:member'])
    }

    if (ordersList.length >= data['hydra:totalItems']) {
      contentStatus = 'noMoreResult'
    }

    this.setState({
      loading: false,
      contentStatus
    })
  }

  getSequenceFromChild(groups) {
    const { setOrdersList } = this.context
    setOrdersList(groups)
    this.setState({
      reordered: true
    })
  }

  setOrderGroupsInfos() {
    const { ordersList } = this.context
    const groups = []

    ordersList.forEach(group => {
      const {
        clientName,
        farmer,
        hatchingDate,
        id,
        injectionOrder,
        thinDropsOrder,
        bigDropsOrder,
        number,
        generation,
        product,
        sequence,
        totalChicks,
        gender,
        color
      } = group

      const prestations = []
      const vaccinesInjectable = []
      const vaccinesInjectableDosesType = []
      const vaccinesThinDrops = []
      const vaccinesThinDropsDosesType = []
      const vaccinesBigDrops = []
      const vaccinesBigDropsDosesType = []
      let injectionProcessState = null
      let thinDropsProcessState = null
      let bigDropsProcessState = null

      group.prestations.forEach(prestation => {
        if (!prestations.includes(prestation)) {
          prestations.push(prestation)
        }
      })

      if (injectionOrder !== null) {
        injectionOrder.vaccines.forEach(vaccine => {
          if (
            !vaccinesInjectable.includes(vaccine.speciality.name) ||
            (vaccinesInjectable.includes(vaccine.speciality.name) &&
              !vaccinesInjectableDosesType.includes(vaccine.doseType))
          ) {
            vaccinesInjectable.push(vaccine.speciality.name)
            vaccinesInjectableDosesType.push(vaccine.doseType)
          }
        })

        injectionProcessState = injectionOrder.processState
      }

      if (thinDropsOrder !== null) {
        thinDropsOrder.vaccines.forEach(vaccine => {
          if (
            !vaccinesThinDrops.includes(vaccine.speciality.name) ||
            (vaccinesThinDrops.includes(vaccine.speciality.name) &&
              !vaccinesThinDropsDosesType.includes(vaccine.doseType))
          ) {
            vaccinesThinDrops.push(vaccine.speciality.name)
            vaccinesThinDropsDosesType.push(vaccine.doseType)
          }
        })

        thinDropsProcessState = thinDropsOrder.processState
      }

      if (bigDropsOrder !== null) {
        bigDropsOrder.vaccines.forEach(vaccine => {
          if (
            !vaccinesBigDrops.includes(vaccine.speciality.name) ||
            (vaccinesBigDrops.includes(vaccine.speciality.name) &&
              !vaccinesBigDropsDosesType.includes(vaccine.doseType))
          ) {
            vaccinesBigDrops.push(vaccine.speciality.name)
            vaccinesBigDropsDosesType.push(vaccine.doseType)
          }
        })

        bigDropsProcessState = bigDropsOrder.processState
      }

      groups.push({
        id,
        number,
        product,
        clientName,
        farmer,
        hatchingDate,
        generation,
        sequence,
        totalChicks,
        vaccinesInjectable,
        vaccinesInjectableDosesType,
        injectionProcessState,
        vaccinesThinDrops,
        vaccinesThinDropsDosesType,
        thinDropsProcessState,
        vaccinesBigDrops,
        vaccinesBigDropsDosesType,
        bigDropsProcessState,
        injectionOrder,
        thinDropsOrder,
        bigDropsOrder,
        prestations,
        gender,
        color
      })
    })

    return groups
  }

  static contextType = PlanningContext

  toggleDragAndDrop() {
    const { dragAndDrop } = this.state

    this.setState({ dragAndDrop: !dragAndDrop })
  }

  toggleDeleteOrders() {
    const { deleteOrders } = this.state

    this.setState({ deleteOrders: !deleteOrders })
  }

  filterByDate(date) {
    const { token, role } = this.props
    const { isVaccination, step } = this.state
    const { ROLE_OPERATOR } = roles
    const { setOrdersList } = this.context

    const dateToApi = moment(date)
    const dateToApiWeek = dateToApi.isoWeek()
    const dateToApiWeekToAxios = `${dateToApi.weekYear()}_${dateToApiWeek}`
    const dateToApiAfter = dateToApi.format('YYYY-MM-DD')
    const dateToApiBefore = moment(dateToApi.format())
      .add(1, 'd')
      .format('YYYY-MM-DD')

    let url = `${api}/api/planning_order_groups?hatchingWeek=${dateToApiWeekToAxios}&hatchingDate[strictly_before]=${dateToApiBefore}&hatchingDate[after]=${dateToApiAfter}&order[hatchingDate]=asc&order[sequence]=asc${
      isVaccination === true ? `&step=${step}` : ''
    }`

    if (role.includes(ROLE_OPERATOR)) {
      url = `${api}/api/planning_order_groups?hatchingDate[strictly_before]=${dateToApiBefore}&hatchingDate[after]=${dateToApiAfter}&order[hatchingDate]=asc&order[sequence]=asc${
        isVaccination === true ? `&step=${step}` : ''
      }`
    }

    const formattedDate = dateToApi.format('DD/MM/YYYY')
    this.setState({
      selectedWeek: dateToApiWeek,
      selectedDay: formattedDate,
      currentDate: moment(formattedDate, 'DD/MM/YYYY')
    })
    Axios.get(url, {
      headers: { Authorization: token }
    })
      .then(res => {
        setOrdersList(res.data['hydra:member'])
        this.setState({
          loading: false,
          totalItems: res.data['hydra:totalItems'],
          contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult'
        })
      })
      .catch(err => catchError(err))
  }

  inLoad(contentStatus) {
    this.setState({ contentStatus })
  }

  selectWeek(state) {
    const { token } = this.props
    const { currentDate, isVaccination, step } = this.state
    const { setOrdersList } = this.context

    let days = 0

    switch (state) {
      case 'after':
        days = 7
        break
      case 'before':
        days = -7
        break
      default:
    }
    const dateToSelect = currentDate.add(days, 'd')
    const weekToSelect = dateToSelect.isoWeek()
    const weekToSelectToAxios = `${dateToSelect.format('GGGG')}_${dateToSelect.format('W')}`

    this.setState({
      selectedWeek: weekToSelect,
      weekToExport: weekToSelectToAxios,
      loading: true
    })

    const url = `${api}/api/planning_order_groups?hatchingWeek=${weekToSelectToAxios}&order[hatchingDate]=asc&order[sequence]=asc${
      isVaccination === true ? `&step=${step}` : ''
    }`

    Axios.get(url, {
      headers: { Authorization: token }
    })
      .then(res => {
        setOrdersList(res.data['hydra:member'])
        this.setState({
          url,
          loading: false,
          totalItems: res.data['hydra:totalItems'],
          contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult'
        })
      })
      .catch(err => catchError(err))
  }

  selectDay(state) {
    const { token } = this.props
    const { currentDate, isVaccination, step } = this.state
    const { setOrdersList } = this.context

    let dateToSelect = moment(currentDate)

    switch (state) {
      case 'after':
        dateToSelect = dateToSelect.add(1, 'days')
        break
      case 'before':
        dateToSelect = currentDate
        break
      default:
    }

    const dayToSelect = dateToSelect
    const dayToSelectToAxiosAfter = dateToSelect.format('YYYY-MM-DD')
    const dayToSelectToAxiosBefore = moment(dayToSelect.format())
      .add(1, 'd')
      .format('YYYY-MM-DD')

    const url = `${api}/api/planning_order_groups?hatchingDate[strictly_before]=${dayToSelectToAxiosBefore}&hatchingDate[after]=${dayToSelectToAxiosAfter}&order[hatchingDate]=asc&order[sequence]=asc${
      isVaccination === true ? `&step=${step}` : ''
    }`

    this.setState({
      selectedDay: dayToSelect,
      loading: true
    })

    Axios.get(url, {
      headers: { Authorization: token }
    })
      .then(res => {
        setOrdersList(res.data['hydra:member'])
        this.setState({
          url,
          loading: false,
          totalItems: res.data['hydra:totalItems'],
          contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult'
        })
      })
      .catch(err => catchError(err))
  }

  render() {
    const {
      dragBtn,
      role,
      token,
      manageSelectedOrder,
      isPreparation,
      isRealisation,
      injectableVaccine,
      sprayableVaccine,
      vaccinesType,
      vaccineType
    } = this.props
    const {
      dragAndDrop,
      contentStatus,
      filters,
      url,
      loading,
      reordered,
      selectedDay,
      selectedWeek,
      weekToExport,
      totalItems,
      deleteOrders
    } = this.state
    const { ordersList } = this.context

    const groups = reordered === true ? ordersList : this.setOrderGroupsInfos()
    let showHeader = true

    if (loading) {
      return <LoadingPage />
    }

    if (isPreparation === true) {
      showHeader = false
    }
    return (
      <div>
        <span style={{ display: showHeader ? 'auto' : 'none' }}>
          <PlanningHeader
            token={token}
            role={role}
            weekToExport={weekToExport}
            getStateFromChild={this.getStateFromChild}
            url={url}
            originalFilter
            filters={filters}
            totalItems={totalItems}
            filterByDate={this.filterByDate}
            inLoad={this.inLoad}
            injectableVaccine={injectableVaccine}
            sprayableVaccine={sprayableVaccine}
            dragBtn={dragBtn}
            toggleDragAndDrop={this.toggleDragAndDrop}
            toggleDeleteOrders={this.toggleDeleteOrders}
          />
        </span>
        {dragAndDrop === true ? (
          <DragAndDropPlanningTable
            role={role}
            selectDay={this.selectDay}
            selectWeek={this.selectWeek}
            selectedWeek={selectedWeek}
            selectedDay={selectedDay}
            groups={groups}
            isPreparation={isPreparation}
            injectableVaccine={injectableVaccine}
            contentStatus={contentStatus}
            vaccineType={vaccineType}
            sprayableVaccine={sprayableVaccine}
            setGroupsToParent={this.getSequenceFromChild}
          />
        ) : (
          <>
            <PlanningTable
              role={role}
              selectDay={this.selectDay}
              selectWeek={this.selectWeek}
              selectedWeek={selectedWeek}
              selectedDay={selectedDay}
              manageSelectedOrder={manageSelectedOrder}
              groups={groups}
              isPreparation={isPreparation}
              isRealisation={isRealisation}
              injectableVaccine={injectableVaccine}
              contentStatus={contentStatus}
              vaccineType={vaccineType}
              vaccinesType={vaccinesType}
              sprayableVaccine={sprayableVaccine}
              deleteOrders={deleteOrders}
            />
          </>
        )}
      </div>
    )
  }
}

export default injectIntl(Planning)
