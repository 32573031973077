import React from 'react'
import { injectIntl } from 'react-intl'

class ReportProblemProductBagRadio extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    const { name, value } = e.target
    const { getDataFromChild } = this.props

    const parseIntValue = parseInt(value)

    getDataFromChild(name, parseIntValue)
    getDataFromChild('disabledButton', false)
  }

  render() {
    const { intl, preparation } = this.props
    const { productBag } = preparation.preparation

    return (
      <div className="row">
        <div className="col s12">
          <div className="custom-card form">
            <div className="card-content">
              <div className="card-block">
                <div className="row">
                  <label className="col s6" htmlFor="type0">
                    <input
                      id="type0"
                      name="type"
                      className="checkbox"
                      value={0}
                      type="radio"
                      onChange={this.handleChange}
                    />
                    <span className="labelCustom">
                      {intl.formatMessage({ id: 'app.label.opaqueOrYellowProductBag' })}
                    </span>
                  </label>
                  <label className="col s6" htmlFor="type1">
                    <input
                      id="type1"
                      name="type"
                      className="checkbox"
                      value={1}
                      type="radio"
                      onChange={this.handleChange}
                    />
                    <span className="labelCustom">
                      {intl.formatMessage({ id: 'app.label.piercedProductBag' })}
                    </span>
                  </label>
                </div>
              </div>
              <div className=" divider greyDivider" />
              <div className="card-content">
                <div className="card-block">
                  <div className="row">
                    <div className="col s6 input">
                      <label htmlFor="expirationDateNoConformityReason">
                        {intl.formatMessage({ id: 'app.label.batchNumber' })}{' '}
                        {intl.formatMessage({ id: 'app.label.damagedProductBag' })}
                      </label>
                      <input type="text" value={productBag.batchNumber} disabled />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(ReportProblemProductBagRadio)
