import React from 'react'
import { injectIntl } from 'react-intl'
import CenterButton from '../../../CenterButton'
import DiluentSelect from './partials/DiluentSelect'

class BlockDiluentFlask extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      disabledList: []
    }
    this.getDataFromChild = this.getDataFromChild.bind(this)
    this.addDiluent = this.addDiluent.bind(this)
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  addDiluent() {
    const { getDataFromChild, disabledButton } = this.props
    const { disabledList, diluents } = this.state

    disabledList.push(true)
    disabledButton.push(true)
    diluents.push({})
    getDataFromChild('disabledButton', disabledButton)
    getDataFromChild('diluents', diluents)
    this.setState({ disabledList })
  }

  render() {
    const { intl, disabledButton, diluents, getDataFromChild } = this.props
    const { disabledList } = this.state

    return (
      <div className="row">
        <div className="col s12">
          <div className="custom-card">
            <div className="card-header">
              <div className="card-block">
                <div className="bold">
                  {intl.formatMessage({ id: 'app.label.nbDiluentFlaskUsedInDay' })}
                </div>
              </div>
            </div>
            <div className="card-content">
              <div className="form">
                {diluents.map((_, index) => (
                  <DiluentSelect
                    key={index}
                    index={index}
                    diluents={diluents}
                    disabledButton={disabledButton}
                    disabledBlock={disabledList[index]}
                    getDataFromChildFromGrandParent={getDataFromChild}
                    getDataFromChild={this.getDataFromChild}
                  />
                ))}
                <CenterButton
                  text={intl.formatMessage({ id: 'app.button.addDiluent' })}
                  disabled={disabledButton.includes(true)}
                  onClick={this.addDiluent}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(BlockDiluentFlask)
