import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import StatePill from '../../StatePill'
import CongruentEquipmentHistoryPart from './CongruentHistoryPart/CongruentEquipmentHistoryPart'
import CongruentVaccinationHistoryPart from './CongruentHistoryPart/CongruentVaccinationHistoryPart'
import CongruentEquipmentPart from './partials/CongruentEquipmentPart'
import CongruentVaccinationPart from './partials/CongruentVaccinationPart'

class CongruentPart extends React.Component {
  constructor() {
    super()
    this.state = {}
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const {
      conformityAlert,
      nonBlockingAlert,
      blockingAlert,
      action,
      setDataToParent,
      vaccinationParamId
    } = this.props

    if (nonBlockingAlert !== undefined && nonBlockingAlert.value !== undefined) {
      if (vaccinationParamId === 8) {
        this.setState({
          congruentPartNoBlockValueMaxPercent: nonBlockingAlert.value.max.percent,
          congruentPartNoBlockValueMaxValue: nonBlockingAlert.value.max.value
        })
        setDataToParent('congruentPartNoBlockValueMaxPercent', nonBlockingAlert.value.max.percent)
        setDataToParent('congruentPartNoBlockValueMaxValue', nonBlockingAlert.value.max.value)
      }
      this.setState({ congruentPartNoBlockValueMin: nonBlockingAlert.value.min })
      setDataToParent('congruentPartNoBlockValueMin', nonBlockingAlert.value.min)
    }

    if (blockingAlert !== undefined && blockingAlert.value !== undefined) {
      this.setState({ congruentPartBlockValueMax: blockingAlert.value.max })
      this.setState({ congruentPartBlockValueMin: blockingAlert.value.min })
      setDataToParent('congruentPartBlockValueMin', blockingAlert.value.min)
      setDataToParent('congruentPartBlockValueMax', blockingAlert.value.max)
    }

    if (conformityAlert !== undefined) {
      this.setState({
        congruentPartValueMin: conformityAlert.value.min,
        congruentPartValueMax: conformityAlert.value.max
      })
      setDataToParent('congruentPartValueMin', conformityAlert.value.min)
      setDataToParent('congruentPartValueMax', conformityAlert.value.max)
    } else if (action !== undefined) {
      const { changes } = action
      const conformityAlert2 = changes.conformityAlert

      if (conformityAlert2) {
        this.setState({
          congruentPartValueMinBefore: conformityAlert2[0].value.min,
          congruentPartValueMaxBefore: conformityAlert2[0].value.max,
          congruentPartValueMinAfter: conformityAlert2[1].value.min,
          congruentPartValueMaxAfter: conformityAlert2[1].value.max
        })
      } else if (action.conformityAlert.length !== 0) {
        this.setState({
          congruentPartValueMinBefore: action.conformityAlert.value.min,
          congruentPartValueMaxBefore: action.conformityAlert.value.max,
          congruentPartValueMinAfter: action.conformityAlert.value.min,
          congruentPartValueMaxAfter: action.conformityAlert.value.max
        })
      }
    }

    M.AutoInit()
  }

  handleChange(e) {
    const { setDataToParent } = this.props
    const { name, value } = e.target

    this.setState({ [name]: value })
    setDataToParent(name, value)
  }

  render() {
    const { paramId, intl, history, vaccinationParamId } = this.props
    const {
      congruentPartValueMin,
      congruentPartValueMax,
      congruentPartValueMinBefore,
      congruentPartValueMaxBefore,
      congruentPartValueMaxAfter,
      congruentPartValueMinAfter,
      congruentPartNoBlockValueMin,
      congruentPartBlockValueMin,
      congruentPartBlockValueMax,
      congruentPartNoBlockValueMaxPercent,
      congruentPartNoBlockValueMaxValue
    } = this.state

    let renderCongruent = null

    if (vaccinationParamId !== undefined) {
      if (!history) {
        renderCongruent = (
          <CongruentVaccinationPart
            vaccinationParamId={vaccinationParamId}
            congruentPartBlockValueMax={congruentPartBlockValueMax}
            congruentPartBlockValueMin={congruentPartBlockValueMin}
            congruentPartNoBlockValueMaxPercent={congruentPartNoBlockValueMaxPercent}
            congruentPartNoBlockValueMaxValue={congruentPartNoBlockValueMaxValue}
            congruentPartNoBlockValueMin={congruentPartNoBlockValueMin}
            handleChange={this.handleChange}
          />
        )
      } else {
        renderCongruent = (
          <CongruentVaccinationHistoryPart
            vaccinationParamId={vaccinationParamId}
            congruentPartValueMaxAfter={congruentPartValueMaxAfter}
            congruentPartValueMaxBefore={congruentPartValueMaxBefore}
            congruentPartValueMinAfter={congruentPartValueMinAfter}
            congruentPartValueMinBefore={congruentPartValueMinBefore}
          />
        )
      }
    } else if (paramId !== undefined) {
      if (!history) {
        renderCongruent = (
          <CongruentEquipmentPart
            paramId={paramId}
            congruentPartValueMax={congruentPartValueMax}
            congruentPartValueMin={congruentPartValueMin}
            handleChange={this.handleChange}
          />
        )
      } else {
        renderCongruent = (
          <CongruentEquipmentHistoryPart
            paramId={paramId}
            congruentPartValueMaxAfter={congruentPartValueMaxAfter}
            congruentPartValueMaxBefore={congruentPartValueMaxBefore}
            congruentPartValueMinAfter={congruentPartValueMinAfter}
            congruentPartValueMinBefore={congruentPartValueMinBefore}
          />
        )
      }
    }

    return (
      <>
        {paramId !== 10 && (
          <div>
            <div className="row">
              <p>
                <StatePill type="conformityTitleParams" />
                <span className="greenTitle">
                  {intl.formatMessage({ id: 'app.label.conformityTitleParams' })}
                </span>
              </p>
            </div>
            {renderCongruent}
          </div>
        )}
      </>
    )
  }
}

export default injectIntl(CongruentPart)
