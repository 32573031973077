import { createStore, applyMiddleware } from 'redux'
import reduxThunk from 'redux-thunk'
import logger from 'redux-logger'
import reducers from './reducers/index'

let createStoreWithMiddleware = applyMiddleware()
if (process.env.NODE_ENV === 'development') {
  createStoreWithMiddleware = applyMiddleware(reduxThunk, logger)(createStore)
} else {
  createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore)
}

const store = createStoreWithMiddleware(reducers)

export default store
