import React from 'react'
import { injectIntl } from 'react-intl'
import OrderGroupItem from './OrderGroupItem/OrderGroupItem'

const OrderGroupsList = props => {
  const { groups, intl } = props

  return (
    <div className="orderGroupsList">
      <div className="listHeader" />
      {groups.length !== 0 ? (
        Object.keys(groups).map(number => (
          <OrderGroupItem key={number} group={groups[number]} number={number} />
        ))
      ) : (
        <div className="noResultCard">
          {intl.formatMessage({ id: 'app.message.noOrderToValidate' })}
        </div>
      )}
    </div>
  )
}

export default injectIntl(OrderGroupsList)
