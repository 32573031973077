import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import productTypes from '../../../config/productTypes'
import equipmentTypes from '../../../config/equipmentTypes'
import { WithUnit } from '../Components'
import { ExportFile } from '../../../components/globalAction'

function Single({ token, location, intl, history, role }) {
  const translationKey = 'app.label.equipment.single.'
  const [item, setItem] = useState(null)
  const id = Number(location.pathname.split('/').pop())
  useEffect(() => {
    Axios.get(`${api}/api/equipment_traceabilities?id=${id}`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const unknownValue = intl.formatMessage({ id: 'app.label.N/A' })
        const equipmentType = res.data['hydra:member'][0]?.equipmentType
        const mappedData = [...res.data['hydra:member']]
          .map(i => ({
            ...i,
            rawEquipmentType: i.equipmentType,
            equipmentType:
              i.equipmentType != null
                ? intl.formatMessage({ id: `app.filter.equipmentType.${i.equipmentType}` })
                : unknownValue,
            equipmentId: i.equipmentId ?? unknownValue,
            operator: unknownValue,
            inputDate: unknownValue,
            outputDate: unknownValue,
            provider: i.provider ?? unknownValue,
            capacity: { text: unknownValue, unit: 'fridgeVolume' }
          }))
          .shift()
        if ([equipmentTypes.CONTAINER, equipmentTypes.FRIDGE].includes(equipmentType)) {
          Axios.get(`${api}/api/equipment_traceability/${id}/header`, {
            headers: { Authorization: token }
          }).then(hRes => {
            const header = hRes.data['hydra:member']
            const mappedDataWithHeader = {
              ...mappedData,
              operator: header.operator ?? unknownValue,
              inputDate: header.startDate
                ? moment(header.startDate).format('DD/MM/YYYY')
                : unknownValue,
              outputDate: header.endDate
                ? moment(header.endDate).format('DD/MM/YYYY')
                : intl.formatMessage({ id: 'app.label.equipment.single.during_use' }),
              provider: header.provider ?? unknownValue,
              capacity: { text: header.volume ?? unknownValue, unit: 'fridgeVolume' }
            }
            setItem(mappedDataWithHeader)
          })
        } else {
          setItem(mappedData)
        }
      })
      .catch(err => catchError(err))
  }, [])

  const categories = [
    {
      label: 'equipment_control',
      path: 'equipment_control',
      excludedProductTypes: []
    },
    {
      label: 'nitrogen_transfer',
      path: 'nitrogen_transfer',
      excludedProductTypes: [
        productTypes.TYPE_NITROGEN_CONTAINER,
        productTypes.RESERVE_CONTAINER,
        productTypes.DILUENT_BATCH,
        productTypes.DYE_BATCH,
        productTypes.PRODUCT_BAG
      ]
    },
    {
      label: 'orders_involved',
      path: 'orders_involved',
      excludedProductTypes: [
        productTypes.RESERVE_CONTAINER,
        productTypes.DILUENT_BATCH,
        productTypes.DYE_BATCH,
        productTypes.PRODUCT_BAG
      ]
    },
    {
      label: 'vaccine_transfer',
      path: 'vaccine_transfer',
      excludedProductTypes: [
        productTypes.RESERVE_CONTAINER,
        productTypes.DILUENT_BATCH,
        productTypes.DYE_BATCH,
        productTypes.PRODUCT_BAG
      ]
    }
  ]

  return !item ? (
    <LoadingPage />
  ) : (
    <div className="row Container stockAvailibilityItem traceabilitySingle">
      <div className="col s12 content">
        <div className="row pageHeader pageTitle itemHeader">
          <div
            onClick={() => history.goBack()}
            role="button"
            tabIndex="0"
            onKeyPress={() => history.goBack()}
            className="goBackBtn col s1"
          >
            <i className="material-icons">chevron_left</i>
          </div>
          <h5 className="col s12">{`${item.equipmentType} ${item.equipmentId}`}</h5>
          <div className="col m1">
            <ExportFile
              data={{ role, token }}
              url={`/api/equipment_traceability/${id}/export/xlsx`}
            />
          </div>
        </div>
        <div className="row cardContainer">
          <div className="col s12 card">
            <div className="pageTitle">
              <h5>{item.equipmentType}</h5>
            </div>
            <div className="divider greyDivider" />
            <div className="row description">
              <div className="col m6">
                <p>
                  <span className="label">
                    {intl.formatMessage({ id: `${translationKey}input_date` })}
                    &nbsp;:&nbsp;
                  </span>
                  {item.inputDate}
                </p>
              </div>
              <div className="col m6">
                <p>
                  <span className="label">
                    {intl.formatMessage({ id: `${translationKey}capacity` })}
                    &nbsp;:&nbsp;
                  </span>
                  <WithUnit {...item.capacity} />
                </p>
              </div>
              <div className="col m6">
                <p>
                  <span className="label">
                    {intl.formatMessage({ id: `${translationKey}operator` })}
                    &nbsp;:&nbsp;
                  </span>
                  {item.operator}
                </p>
              </div>
              <div className="col m6">
                <p>
                  <span className="label">
                    {intl.formatMessage({ id: `${translationKey}output_date` })}
                    &nbsp;:&nbsp;
                  </span>
                  {item.outputDate}
                </p>
              </div>
              <div className="col m6">
                <p>
                  <span className="label">
                    {intl.formatMessage({ id: `${translationKey}provider` })}
                    &nbsp;:&nbsp;
                  </span>
                  {item.provider}
                </p>
              </div>
            </div>
          </div>
        </div>
        <table className="table">
          {categories.map(
            category =>
              !category.excludedProductTypes.includes(item.rawEquipmentType) && (
                <tr
                  key={item.id}
                  onClick={() => {
                    history.push({
                      pathname: `/traceability/equipment/single/${id}/${category.path}`
                    })
                  }}
                  className="pointer"
                >
                  <td className="label">
                    {intl.formatMessage({
                      id: `app.label.equipment.single.${category.label}`
                    })}
                  </td>
                  <td>
                    <i className="material-icons arrow-right">keyboard_arrow_right</i>
                  </td>
                </tr>
              )
          )}
        </table>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(Single)))
