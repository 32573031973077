/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import AsyncLoader from '../../../components/AsyncLoader'
import productTypes from '../../../config/productTypes'
import { SpanLink } from '../Components'
import { getEquipmentById } from '../ReverseRedirections'
import traceabilityProductTypes from '../../../config/traceabilityProductTypes'

function StockWithdrawal({ token, location, intl, history }) {
  const [page, setPage] = useState(1)
  const [state, setState] = useState({
    loading: true,
    items: null,
    contentStatus: false,
    totalItems: 0,
    batchType: null,
    equipmentType: null,
    batchNumber: null
  })

  function loadMore() {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (state.totalItems > page * 30) {
        setPage(page + 1)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', loadMore)
  }, [])

  useEffect(() => {
    const pathName = location.pathname.split('/')
    const id = Number(pathName[pathName.length - 2])
    Axios.get(`${api}/api/batch_traceability/${id}/out_of_stock?page=${page}`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const unknownValue = intl.formatMessage({ id: 'app.label.N/A' })
        const { equipmentType, batchType } = res.data
        const mappedData = [...res.data['hydra:member']].map(i => ({
          ...i,
          date: i.date ? moment(i.date).format('DD/MM/YYYY') : unknownValue,
          hour: i.date ? moment(i.date).format('HH:mm') : unknownValue,
          operator: i.operator ?? unknownValue,
          units: i.units
            ? `${i.units} ${intl.formatMessage({ id: 'app.unit.units' })}`
            : unknownValue,
          toOutStock: i.toOutStock
            ? `${i.toOutStock} ${intl.formatMessage({
                id: 'app.unit.units'
              })}`
            : unknownValue,
          equipmentId: i.equipmentId ?? unknownValue,
          control: i.control
            ? `${i.control.instant}
        ${intl.formatMessage({
          id:
            equipmentType === productTypes.VACCINES_BATCH ? 'app.unit.centimeter' : 'app.unit.temp'
        })}`
            : unknownValue,
          controlMin: i.control
            ? `${i.control.min}
        ${intl.formatMessage({
          id:
            equipmentType === productTypes.VACCINES_BATCH ? 'app.unit.centimeter' : 'app.unit.temp'
        })}`
            : unknownValue,
          controlMax: i.control
            ? `${i.control.max}
        ${intl.formatMessage({
          id:
            equipmentType === productTypes.VACCINES_BATCH ? 'app.unit.centimeter' : 'app.unit.temp'
        })}`
            : unknownValue
        }))
        setState({
          ...state,
          loading: false,
          items: state.items ? state.items.concat(mappedData) : mappedData,
          totalItems: res.data['hydra:totalItems'],
          contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult',
          equipmentType,
          batchType,
          batchNumber: res.data.speciality ?? res.data.batchNumber
        })
      })
      .catch(err => catchError(err))
  }, [page])

  return (
    <div className="row Container stockAvailibilityItem">
      <div className="col s12 Content">
        <div className="row pageHeader pageTitle itemHeader">
          <div
            onClick={() => history.goBack()}
            role="button"
            tabIndex="0"
            onKeyPress={() => history.goBack()}
            className="goBackBtn col s1"
          >
            <i className="material-icons">chevron_left</i>
          </div>
          {state.batchNumber !== null && (
            <h5 className="col s12">
              {state.batchNumber} - {intl.formatMessage({ id: 'app.title.stock_withdrawal' })}
            </h5>
          )}
        </div>
        {!state.loading ? (
          <div className="row">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.vaccine_or_product.stock_withdrawal.date'
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.vaccine_or_product.stock_withdrawal.hour'
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.vaccine_or_product.stock_withdrawal.operator_name'
                    })}
                  </th>
                  {state.batchType !== productTypes.PRODUCT_BAG && (
                    <>
                      <th>
                        {intl.formatMessage({
                          id: 'app.label.vaccine_or_product.stock_withdrawal.donor_device'
                        })}
                      </th>
                      {state.batchType !== productTypes.DILUENT_BATCH &&
                        state.batchType !== productTypes.DYE_BATCH &&
                        (state.batchType !== productTypes.VACCINES_BATCH ||
                          state.equipmentType !==
                            traceabilityProductTypes.TYPE_NITROGEN_CONTAINER) && (
                          <th>
                            {intl.formatMessage({
                              id:
                                'app.label.vaccine_or_product.stock_withdrawal.donor_device_control'
                            })}
                          </th>
                        )}
                      {(state.batchType === productTypes.DILUENT_BATCH ||
                        state.batchType === productTypes.DYE_BATCH ||
                        (state.batchType === productTypes.VACCINES_BATCH &&
                          state.equipmentType ===
                            traceabilityProductTypes.TYPE_NITROGEN_CONTAINER)) && (
                        <>
                          <th>
                            {intl.formatMessage({
                              id:
                                'app.label.vaccine_or_product.stock_withdrawal.donor_device_control_temp'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id:
                                'app.label.vaccine_or_product.stock_withdrawal.donor_device_control_min'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id:
                                'app.label.vaccine_or_product.stock_withdrawal.donor_device_control_max'
                            })}
                          </th>
                        </>
                      )}
                    </>
                  )}

                  <th>
                    {intl.formatMessage({
                      id:
                        'app.label.vaccine_or_product.stock_withdrawal.number_of_units_before_movement'
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id:
                        'app.label.vaccine_or_product.stock_withdrawal.number_of_units_during_movement'
                    })}
                  </th>
                </tr>
              </thead>
              <tbody>
                {state.items.map(item => {
                  return (
                    <tr key={item.id}>
                      <td>{item.date}</td>
                      <td>{item.hour}</td>
                      <td>{item.operator}</td>
                      {state.batchType !== productTypes.PRODUCT_BAG && (
                        <>
                          <td>
                            <SpanLink
                              value={item.equipmentId}
                              setLoading={loading => {
                                setState({ ...state, loading })
                              }}
                              redirect={getEquipmentById}
                              token
                              history
                            />
                          </td>
                          <td>{item.control}</td>
                          {(state.batchType === productTypes.DILUENT_BATCH ||
                            state.batchType === productTypes.DYE_BATCH ||
                            (state.batchType === productTypes.VACCINES_BATCH &&
                              state.equipmentType ===
                                traceabilityProductTypes.TYPE_NITROGEN_CONTAINER)) && (
                            <>
                              <td> {item.controlMin}</td>
                              <td> {item.controlMax}</td>
                            </>
                          )}
                        </>
                      )}
                      <td>{item.units}</td>
                      <td>{item.toOutStock}</td>
                    </tr>
                  )
                })}
              </tbody>
              <tfoot className="center">
                <tr>
                  <th colSpan="5">
                    <AsyncLoader status={state.contentStatus} />
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        ) : (
          <LoadingPage />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(StockWithdrawal)))
