import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { catchError } from '../../../actions'
import LastAlert from '../../../components/Alerts/LastAlert'
import { renderNitrogenLevel } from '../../../components/globalRenderActions'
import LoadingPage from '../../../components/LoadingPage'
import StatePill from '../../../components/StatePill'
import ItemHeader from '../../../components/StockManagement/StockAvailibility/ItemHeader'
import { api } from '../../../parameters'
import TableIncompatibilities from './partials/TableIncompatibilities'
import sortIncompatibilities from './sortIncompatibilities'

class FrozenVaccineItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = { batch: [], loading: true }
  }

  componentDidMount() {
    const { match, token } = this.props
    Axios.get(`${api}/api/container_vaccines/${match.params.id}`, {
      headers: {
        Authorization: token
      }
    })
      .then(res => {
        const incompatibilitiesData =
          res.data.vaccinesBatch.vaccineArticle.speciality.incompatibilities

        Axios.get(`${api}/api/vaccine_specialities`, {
          headers: {
            Authorization: token
          }
        })
          .then(resultData => {
            const specialities = resultData.data['hydra:member']

            const incompatibilities = sortIncompatibilities(incompatibilitiesData, specialities)

            this.setState({
              batch: res.data,
              incompatibilities,
              loading: false
            })
          })
          .catch(err => catchError(err.response))
      })
      .catch(err => catchError(err.response))
  }

  render() {
    const { batch, loading, incompatibilities } = this.state
    const { history, intl, match } = this.props
    if (loading === true) {
      return <LoadingPage />
    }

    const title = `${batch.vaccinesBatch.vaccineArticle.speciality.name} - ${batch.vaccinesBatch.vaccineArticle.name}`
    const outStockPath = `/stocks/out-stock/frozen-vaccine/${match.params.id}`

    return (
      <div className="row Container stockAvailibilityItem">
        <div className="col s12 content">
          <ItemHeader history={history} outStockPath={outStockPath} title={title} />
          <div className="row cardContainer">
            <div className="col s12 card">
              <div className="row description">
                <div className="col m6">
                  <p>
                    <span className="label">
                      {intl.formatMessage({ id: `app.label.batchNumber` })}
                      &nbsp;:&nbsp;
                    </span>
                    {batch.vaccinesBatch.batchNumber}
                  </p>
                </div>
                <div className="col m6">
                  <p>
                    <span className="label">
                      {intl.formatMessage({ id: `app.label.expirationDate` })}
                      &nbsp;:&nbsp;
                    </span>
                    <StatePill type="ExpirationDate" data={batch.vaccinesBatch} />
                    {moment(batch.vaccinesBatch.expirationDate).format('DD/MM/YYYY')}
                  </p>
                </div>
                <div className="col m6">
                  <p>
                    <span className="label">
                      {intl.formatMessage({ id: 'app.label.ampoulesNumber' })}
                      &nbsp;:&nbsp;
                    </span>
                    {batch.ampoulesNumber}
                  </p>
                </div>
                <div className="col m6">
                  <p>
                    <span className="label">
                      {intl.formatMessage({ id: `app.label.containerNumber` })}
                    </span>
                    {batch.nitrogenContainer.containerNumber}
                  </p>
                </div>
                <div className="col m6">
                  <p>
                    <span className="label">
                      {intl.formatMessage({ id: `app.label.dosesNumber` })}
                      &nbsp;:&nbsp;
                    </span>
                    <StatePill type="doses" data={batch.ampoulesNumber} />
                    {batch.ampoulesNumber * batch.vaccinesBatch.vaccineArticle.dosesNumber}
                  </p>
                </div>
                <div className="col m6">
                  <p>
                    <span className="label">
                      {intl.formatMessage({ id: `app.label.nitrogenLevel` })}
                      &nbsp;:&nbsp;
                    </span>
                    <StatePill type="ContainerNitrogenLevel" data={batch.nitrogenContainer} />
                    {renderNitrogenLevel(batch.nitrogenContainer.lastControl.nitrogenLevel, intl)}
                  </p>
                </div>
              </div>
              <div className="divider greyDivider" />
              <LastAlert equipmentId={batch.vaccinesBatch.batchNumber} cat={[11]} />
            </div>
          </div>
          <TableIncompatibilities incompatibilities={incompatibilities} />
        </div>
      </div>
    )
  }
}

export default injectIntl(FrozenVaccineItem)
