import React from 'react'
import { Link } from 'react-router-dom'
import icons from '../../../assets/icons/icons'

const AddButton = props => {
  const { path } = props

  return (
    <Link to={{ pathname: path }}>
      <div className="btnExport" role="button">
        <img src={icons.whitePlus} alt="white plus icon" />
      </div>
    </Link>
  )
}

export default AddButton
