import Axios from 'axios'
import React from 'react'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import { catchError } from '../../actions'
import LoadingPage from '../LoadingPage'
import CategoryFilter from './partials/CategoryFilter'
import DateFiltrer from './partials/DateFiltrer'

class Filters extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sortBy: 'select',
      sortCategory: 'select',
      infiniteLoader: props.infiniteLoader,
      operator: false,
      loading: true,
      toTranslate: true,
      translationPrefix: '',
      page: 1
    }
    this.loadData = this.loadData.bind(this)
    this.activeFilter = this.activeFilter.bind(this)
    this.getSubCat = this.getSubCat.bind(this)
    this.loadMore = this.loadMore.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
  }

  componentDidMount() {
    const { infiniteLoader } = this.state

    if (infiniteLoader) document.addEventListener('scroll', this.loadMore)
    this.setState({ loading: false })
  }

  getDataFromChild(name, value, callback = null) {
    if (callback !== null) {
      return this.setState({ [name]: value }, () => callback())
    }

    return this.setState({ [name]: value })
  }

  getSubCat(e) {
    const { name, value } = e.target
    const { filters } = this.props
    this.setState(
      {
        [name]: value,
        operator: filters[value].operator,
        selectedCategory: filters[value].category,
        toTranslate: filters[value].toTranslate,
        translationPrefix: filters[value].translationPrefix
      },
      this.activeFilter(name)
    )
  }

  loadMore() {
    const { page } = this.state
    const { inLoad, totalItems } = this.props

    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (totalItems > page * 30) {
        inLoad('inLoad')
        this.setState({ page: page + 1 }, () => this.loadData(true))
      }
    }
  }

  activeFilter(filterType, change = false) {
    const { inLoad } = this.props

    if (change) {
      inLoad(false)
      return this.setState(
        { page: 1, filterType, filterDate: '', sortBy: 'select', sortCategory: 'select' },
        () => this.loadData()
      )
    }
    return this.setState({ filterType, filterDate: '', sortBy: 'select', sortCategory: 'select' })
  }

  loadData(isInfinite = false) {
    const { sortBy, selectedCategory, page, filterType, filterDate } = this.state
    const {
      hydra,
      infiniteLoader,
      noActive,
      originalFilter,
      setStateToParent,
      token,
      url
    } = this.props
    let apiRoute = `${url}${originalFilter ? '&' : '?'}${selectedCategory}=${sortBy}&page=${page}${
      noActive ? '' : '&active=true'
    }`

    if (!infiniteLoader) {
      apiRoute = `${url}${originalFilter ? '&' : '?'}${selectedCategory}=${sortBy}${
        noActive ? '' : '&active=true'
      }`
      this.setState({ loading: true })
    }

    if (filterType === 'filterDate') {
      apiRoute = `${url}${originalFilter ? '&' : '?'}dateFilter=${moment(filterDate).format(
        'YYYY-MM-DD'
      )}&page=${page}${noActive ? '' : '&active=true'}`
    }

    Axios.get(apiRoute, {
      headers: {
        Authorization: token
      }
    })
      .then(res => {
        this.setState({ loading: false })
        if (infiniteLoader || hydra === false) {
          return setStateToParent(res.data, isInfinite)
        }
        return setStateToParent(res.data['hydra:member'], isInfinite)
      })
      .catch(err => catchError(err.response))
  }

  render() {
    const { dateOnly, filters, hasDate, setStateToParent, filterByDate, inLoad } = this.props
    const {
      filterType,
      loading,
      sortCategory,
      sortBy,
      toTranslate,
      operator,
      translationPrefix,
      filterDate,
      infiniteLoader
    } = this.state

    return (
      <form className="filtersForm" autoComplete="off">
        {hasDate && (
          <DateFiltrer
            filterDate={filterDate}
            filterType={filterType}
            activeFilter={this.activeFilter}
            getDataFromChild={this.getDataFromChild}
            filterByDate={filterByDate}
            inLoad={inLoad}
            infiniteLoader={infiniteLoader}
          />
        )}
        {loading ? (
          <LoadingPage />
        ) : (
          <>
            {!dateOnly && (
              <CategoryFilter
                infiniteLoader={infiniteLoader}
                filters={filters}
                filterType={filterType}
                sortCategory={sortCategory}
                getSubCat={this.getSubCat}
                sortBy={sortBy}
                inLoad={inLoad}
                setStateToGrandParent={setStateToParent}
                setDataToParent={this.getDataFromChild}
                loadData={this.loadData}
                activeFilter={this.activeFilter}
                toTranslate={toTranslate}
                operator={operator}
                translationPrefix={translationPrefix}
              />
            )}
          </>
        )}
      </form>
    )
  }
}

export default injectIntl(Filters)
