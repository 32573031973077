import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { catchError } from '../../../actions'
import AsyncLoader from '../../../components/AsyncLoader'
import Filters from '../../../components/Filters/Filters'
import { ExportFile } from '../../../components/globalAction'
import ColumnItem from '../../../components/StockManagement/StockHistory/ColumnItem'
import { api } from '../../../parameters'

class StockHistory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      historyList: [],
      url: `${api}/api/stock_histories`,
      contentStatus: false,
      filters: [
        { category: 'orderDate', sortBy: ['asc', 'desc'] },
        {
          category: 'type',
          sortBy: Array.from(Array(21).keys()),
          translationPrefix: 'stockAction'
        },
        { category: 'orderProductsType', sortBy: ['asc', 'desc'] },
        {
          category: 'productsType',
          sortBy: Array.from(Array(6).keys()),
          translationPrefix: 'stockProductsType'
        },
        { category: 'orderProducts', sortBy: ['asc', 'desc'] }
      ]
    }
    this.inLoad = this.inLoad.bind(this)
    this.filterByDate = this.filterByDate.bind(this)
    this.getStateFromChild = this.getStateFromChild.bind(this)
  }

  componentDidMount() {
    const { token } = this.props
    const { url } = this.state

    Axios.get(url, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({
          loading: false,
          historyList: res.data['hydra:member'],
          totalItems: res.data['hydra:totalItems'],
          contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult'
        })
      })
      .catch(err => catchError(err))
  }

  getStateFromChild(data, infiniteLoader = false) {
    let { historyList, contentStatus } = this.state
    if (infiniteLoader) {
      historyList = [...historyList, ...data['hydra:member']]
    } else {
      historyList = data['hydra:member']
    }

    if (historyList.length >= data['hydra:totalItems']) {
      contentStatus = 'noMoreResult'
    }
    this.setState({
      historyList,
      loading: false,
      contentStatus
    })
  }

  filterByDate(date) {
    const { token } = this.props

    const dateToApi = moment(date).format('YYYY-MM-DD')

    const url = `${api}/api/stock_histories?dateFilter=${dateToApi}`

    Axios.get(url, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({
          loading: false,
          historyList: res.data['hydra:member']
        })
      })
      .catch(err => catchError(err))
  }

  inLoad(contentStatus) {
    this.setState({ contentStatus })
  }

  render() {
    const { url, filters, loading, historyList, contentStatus, totalItems } = this.state
    const { token, role, history, intl } = this.props

    return (
      <div className="now Container stockHistory">
        <div className="col s12 content">
          <div className="row pageHeader pageTitle">
            <h5 className="col m5">{intl.formatMessage({ id: 'app.label.stock_history' })}</h5>
            <div className="filters col m7">
              <div className="flex col m12">
                <div>
                  <Filters
                    token={token}
                    setStateToParent={this.getStateFromChild}
                    url={url}
                    infiniteLoader
                    sortBy="desc"
                    sortCategory="orderDate"
                    totalItems={totalItems}
                    role={role}
                    hasDate
                    filterByDate={this.filterByDate}
                    noActive
                    hydra={false}
                    filters={filters}
                    inLoad={this.inLoad}
                  />
                </div>
              </div>
              <div className="col m2">
                <ExportFile
                  data={this.props}
                  name="history_list.xlsx"
                  url="/api/stock_histories/export/xlsx"
                />
              </div>
            </div>
          </div>
          {!loading && (
            <div className="row">
              <table className="table">
                <thead>
                  <tr>
                    <th>{intl.formatMessage({ id: 'app.label.date&hour' })} </th>
                    <th>{intl.formatMessage({ id: 'app.label.actionType' })} </th>
                    <th>{intl.formatMessage({ id: 'app.label.products_type' })} </th>
                    <th>{intl.formatMessage({ id: 'app.label.products' })} </th>
                    <th>{intl.formatMessage({ id: 'app.label.quantity' })} </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {historyList.map(item => (
                    <tr
                      key={item.id}
                      style={item.outdated ? { background: '#c1c7d0' } : {}}
                      onClick={() => {
                        history.push({
                          pathname: `/stocks/stock-history/single/${item.id}`,
                          state: { type: item.products }
                        })
                      }}
                      className="pointer"
                    >
                      <td>{moment(item.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                      <td>{intl.formatMessage({ id: `app.label.action.${item.type}` })}</td>
                      <td>
                        {intl.formatMessage({ id: `app.label.${item.history.productsType}` })}
                      </td>
                      <td>
                        <ColumnItem
                          items={item.history.products}
                          productsType={item.history.productsType}
                        />
                      </td>
                      <td>
                        {item.history.quantities === null ? (
                          <ColumnItem items={null} />
                        ) : Array.isArray(item.history.quantities) ? (
                          <ColumnItem
                            items={item.history.quantities}
                            productsType={item.history.productsType}
                          />
                        ) : (
                          <ColumnItem
                            items={Object.values(item.history.quantities)}
                            productsType={item.history.productsType}
                          />
                        )}
                      </td>
                      <td>
                        <i className="material-icons">keyboard_arrow_right</i>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot className="center">
                  <tr>
                    <th colSpan="5">
                      <AsyncLoader status={contentStatus} />
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default injectIntl(StockHistory)
