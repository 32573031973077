import jwtDecode from 'jwt-decode'
import { mercure } from '../parameters'
import { addNotif, removeNotif, addInstantNotif } from '../actions'

export const addNotification = token => {
  const u = new URL(mercure)
  const tokData = jwtDecode(token)
  const topics = tokData.mercureTopics

  topics.forEach(topic => {
    u.searchParams.append('topic', topic)
  })

  const es = new EventSource(u)
  es.onmessage = e => {
    const data = JSON.parse(e.data)
    if (data.type !== 4) {
      addNotif(data)
    }

    if (data.type === 4) {
      addInstantNotif(data)
    }
  }
}

export const removeNotification = () => {
  const u = new URL(mercure)
  u.searchParams.append('topic', 'delete')

  const es = new EventSource(u)
  es.onmessage = e => {
    removeNotif(e.data)
  }
}
