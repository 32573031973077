import Axios from 'axios'
import M from 'materialize-css'
import moment from 'moment'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import { catchError } from '../../actions'
import Containers from '../../components/EquipmentManagement/EquipmentControl/ContainersPart/ContainersPart'
import FridgePart from '../../components/EquipmentManagement/EquipmentControl/FridgePart/FridgePart'
import VaccinationPart from '../../components/EquipmentManagement/EquipmentControl/VaccinationPart'
import { renderNitrogenLevel, renderTemperature } from '../../components/globalRenderActions'
import { api } from '../../parameters'

class EquipmentControl extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lastControls: [],
      containerSelected: {},
      idSelected: 0
    }
    this.loadControls = this.loadControls.bind(this)
    this.handleClickLink = this.handleClickLink.bind(this)
  }

  componentDidMount() {
    const { location } = this.props
    const { hash } = location

    M.AutoInit()
    const el = document.getElementById('tabs')
    M.Tabs.init(el, { swipeable: false, responsiveThreshold: Infinity })

    if (hash === '#fridge') {
      this.setState({ idSelected: 1 })
    } else if (hash === '#vaccination') {
      this.setState({ idSelected: 2 })
    }

    M.AutoInit()
  }

  loadControls(containerSelected) {
    this.setState({ lastControls: [] })
    const { token } = this.props
    let type = 'fridges'

    if (containerSelected['@type'] === 'NitrogenContainer') {
      type = 'nitrogen_containers'
    }

    Axios.get(`${api}/api/${type}/${containerSelected.id}/controls?limit=4`, {
      headers: { Authorization: token }
    })
      .then(res => this.setState({ containerSelected, lastControls: res.data, type }))
      .catch(err => catchError(err.response, false))
  }

  handleClickLink(e, id) {
    const { history } = this.props

    if (id === 0) {
      history.push('#containers')
    } else if (id === 1) {
      history.push('#fridge')
    } else if (id === 2) {
      history.push('#vaccination')
    }
    this.setState({ idSelected: id })
  }

  render() {
    const { containerSelected, lastControls, idSelected, type } = this.state
    const { intl } = this.props
    let containerName = ''

    switch (type) {
      case 'nitrogen_containers':
        containerName = `${intl.formatMessage({
          id: `app.label.container`
        })} ${containerSelected.containerNumber}`
        break
      case 'fridges':
        containerName = `${intl.formatMessage({ id: `app.label.fridge` })} ${
          containerSelected.name
        }`
        break
      default:
    }

    return (
      <div className="Container equipmentControl row">
        <div className="col s12 equipmentControlContent">
          <ul className="tabs" id="tabs">
            <li className="tab col s4">
              <a
                id={0}
                href="#containers"
                className="active"
                onClick={() => this.handleClickLink(this, 0)}
              >
                <FormattedMessage id="app.tab.container" values={{ what: 'react-intl' }} />
              </a>
            </li>
            <li className="tab col s4">
              <a id={1} href="#fridge" onClick={() => this.handleClickLink(this, 1)}>
                <FormattedMessage id="app.tab.fridge" values={{ what: 'react-intl' }} />
              </a>
            </li>
            <li className="tab col s4">
              <a id={2} href="#vaccination" onClick={() => this.handleClickLink(this, 2)}>
                <FormattedMessage id="app.tab.vaccine" values={{ what: 'react-intl' }} />
              </a>
            </li>
          </ul>
        </div>
        {idSelected === 0 && <Containers loadControls={this.loadControls} />}
        {idSelected === 1 && <FridgePart loadControls={this.loadControls} />}
        {idSelected === 2 && <VaccinationPart />}

        <div id="modal1" className="modal">
          <div className="modal-content center">
            <div className="modalHeader row">
              <div className="CardEquipmentTopTitle col s12">
                <h5>Derniers contrôles</h5>
                <h5>{containerName}</h5>
              </div>
              <i className="material-icons modal-close modal-close-btn">close</i>
            </div>
            {containerSelected['@type'] === 'Fridge' ? (
              <table>
                <thead>
                  <tr>
                    <th>{intl.formatMessage({ id: 'app.equipment.lastControl' })}</th>
                    <th>
                      {intl.formatMessage({ id: 'app.equipment.lastControl.minTemperature' })}
                    </th>
                    <th>
                      {intl.formatMessage({ id: 'app.equipment.lastControl.maxTemperature' })}
                    </th>
                    <th>
                      {intl.formatMessage({ id: 'app.equipment.lastControl.instantTemperature' })}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {lastControls.map(item => (
                    <tr key={item.id}>
                      <td>
                        {item.date}
                        {moment(item.createdAt).format('DD/MM/YYYY')}
                      </td>
                      <td>
                        <div className="flex">
                          <i className={`material-icons pill state-${item.minTemperatureState}`}>
                            brightness_1
                          </i>
                          {renderTemperature(item.minTemperature, intl)}
                        </div>
                      </td>
                      <td>
                        <div className="flex">
                          <i className={`material-icons pill state-${item.maxTemperatureState}`}>
                            brightness_1
                          </i>
                          {renderTemperature(item.maxTemperature, intl)}
                        </div>
                      </td>
                      <td>
                        <div className="flex">
                          <i
                            className={`material-icons pill state-${item.instantTemperatureState}`}
                          >
                            brightness_1
                          </i>
                          {renderTemperature(item.instantTemperature, intl)}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>Date contrôle</th>
                    <th>Niveau Azote</th>
                  </tr>
                </thead>
                <tbody>
                  {lastControls.map((item, i) => (
                    <tr key={i}>
                      <td>{moment(item.createdAt).format('DD/MM/YYYY')}</td>
                      <td>
                        <div className="flex">
                          <i className={`material-icons pill state-${item.nitrogenLevelState}`}>
                            brightness_1
                          </i>
                          {renderNitrogenLevel(item.nitrogenLevel, intl)}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(injectIntl(EquipmentControl))
