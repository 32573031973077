import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import M from 'materialize-css'
import { catchError } from '../../../actions'
import AsyncLoader from '../../../components/AsyncLoader'
import LoadingPage from '../../../components/LoadingPage'
import { api, i18n } from '../../../parameters'
import icons from '../../../assets/icons/icons'
import { ExportFile } from '../../../components/globalAction'
import { RefreshBtn } from '../Components'

class List extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      url: `${api}/api/equipment_traceabilities`,
      loading: false,
      elementList: [],
      contentStatus: false,
      totalItems: 0,
      equipmentType: null,
      equipmentName: null,
      startDate: null,
      endDate: null,
      page: 1
    }
    this.loadMore = this.loadMore.bind(this)
  }

  componentDidMount() {
    const { location } = this.props
    const urlSearch = new URLSearchParams(location.search)
    const equipmentType = urlSearch.get('equipmentType')
    const equipmentName = urlSearch.get('equipmentName')
    const startDate = urlSearch.get('startDate')
    const endDate = urlSearch.get('endDate')
    this.setState(
      {
        equipmentType,
        equipmentName,
        startDate: startDate ? new Date(startDate) : null,
        endDate: endDate ? new Date(endDate) : null
      },
      () => {
        M.AutoInit()
        M.Datepicker.init(document.getElementById('startDate'), {
          autoClose: true,
          format: 'dd/mm/yyyy',
          selectMonth: true,
          firstDay: 1,
          i18n,
          defaultDate: startDate ? new Date(startDate) : new Date(),
          setDefaultDate: startDate,
          onSelect: dateSelected => {
            const { instanceStartDate } = this.state
            instanceStartDate.gotoDate(new Date(dateSelected))
            this.setState({ startDate: dateSelected })
            this.fetchEntries()
          }
        })
        M.Datepicker.init(document.getElementById('endDate'), {
          autoClose: true,
          format: 'dd/mm/yyyy',
          selectMonth: true,
          firstDay: 1,
          i18n,
          defaultDate: endDate ? new Date(endDate) : new Date(),
          setDefaultDate: endDate,
          onSelect: dateSelected => {
            const { instanceEndDate } = this.state
            instanceEndDate.gotoDate(new Date(dateSelected))
            this.setState({ endDate: dateSelected })
            this.fetchEntries()
          }
        })
        const instanceStartDate = M.Datepicker.getInstance(document.getElementById('startDate'))
        this.setState({ instanceStartDate })
        const instanceEndDate = M.Datepicker.getInstance(document.getElementById('endDate'))
        this.setState({ instanceEndDate })
        document.addEventListener('scroll', this.loadMore)
        this.fetchEntries()
      }
    )
  }

  loadMore() {
    const { page, totalItems } = this.state
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (totalItems > page * 30) {
        this.setState({ page: page + 1 }, () => this.fetchEntries(true))
      }
    }
  }

  fetchEntries(loadMore) {
    const { token, location, history } = this.props
    const {
      url,
      equipmentType,
      equipmentName,
      startDate,
      endDate,
      // eslint-disable-next-line no-unused-vars
      page,
      elementList
    } = this.state
    this.setState({ loading: true })
    if (startDate !== null && endDate !== null) {
      // const pageQueryParam = `page=${page}`
      const pageQueryParam = ``
      const periodQueryParam = `period=${moment(startDate).format('YYYY-MM-DD')}..${moment(
        endDate
      ).format('YYYY-MM-DD')}`
      const equipmentTypeQueryParam = equipmentType ? `&equipmentType=${equipmentType}` : ''
      const equipmentNameQueryParam = equipmentName ? `&equipmentId=${equipmentName}` : ''
      const newUrlQuery = `&startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(
        endDate
      ).format('YYYY-MM-DD')}${equipmentTypeQueryParam}${equipmentNameQueryParam}`
      const poppedUrl = location.pathname.split('?').pop()
      const currentUrlQuery = poppedUrl !== location.pathname ? poppedUrl : ''
      if (newUrlQuery !== currentUrlQuery) {
        history.push({
          pathname: `/traceability/equipment/list/?${newUrlQuery}`
        })
      }
      Axios.get(
        `${url}?${pageQueryParam}${periodQueryParam}${equipmentTypeQueryParam}${equipmentNameQueryParam}`,
        {
          headers: { Authorization: token }
        }
      )
        .then(res => {
          this.setState({
            loading: false,
            elementList: loadMore
              ? elementList.concat(res.data['hydra:member'])
              : res.data['hydra:member'],
            totalItems: res.data['hydra:totalItems'],
            contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult',
            page: 1
          })
        })
        .catch(err => catchError(err))
    } else {
      this.setState({
        loading: false,
        elementList: [],
        totalItems: 0,
        contentStatus: false
      })
    }
  }

  render() {
    const { intl, history, role, token } = this.props
    // eslint-disable-next-line no-unused-vars
    const {
      loading,
      elementList,
      contentStatus,
      equipmentType,
      equipmentName,
      startDate,
      endDate
    } = this.state
    const types = [
      { id: null, label: 'app.label.equipment_type_default' },
      { id: 0, label: 'app.label.equipment_type_container' },
      { id: 1, label: 'app.label.equipment_type_fridge' },
      { id: 2, label: 'app.label.equipment_type_vaccines' },
      { id: 3, label: 'app.label.equipment_type_bath' }
    ]
    return (
      <div className="row Container stockHistory">
        <div className="col s12 Content paramsContent traceability">
          <div className="row pageHeader pageTitle">
            <h5 className="col m10">
              {intl.formatMessage({ id: 'app.label.traceability_equipment' })}
            </h5>
            {startDate !== null && endDate != null ? (
              <>
                <div className="col m1">
                  <RefreshBtn
                    redirect={() => {
                      history.push({
                        pathname: `/traceability/equipment/list`
                      })
                    }}
                  />
                </div>
                <ExportFile
                  data={{ role, token }}
                  url={`/api/equipment_traceability/export/xlsx?startAt=${moment(startDate).format(
                    'YYYY-MM-DD'
                  )}&endAt=${moment(endDate).format('YYYY-MM-DD')}${
                    equipmentType ? `&equipmentType=${equipmentType}` : ''
                  }${equipmentName ? `&equipmentId=${equipmentName}` : ''}`}
                />
              </>
            ) : (
              ''
            )}
          </div>
          <div>
            <div className="filters">
              <div className="input">
                <div className="filterDate">
                  <input
                    type="text"
                    className="datepicker"
                    id="startDate"
                    name="startDate"
                    readOnly
                    placeholder={intl.formatMessage({ id: 'app.label.traceability_start_date' })}
                  />
                  <img src={icons.blueCalendar} alt="calendar icon" />
                </div>
              </div>
              <div className="input">
                <div className="filterDate">
                  <input
                    type="text"
                    className="datepicker"
                    id="endDate"
                    name="endDate"
                    readOnly
                    placeholder={intl.formatMessage({ id: 'app.label.traceability_end_date' })}
                  />
                  <img src={icons.blueCalendar} alt="calendar icon" />
                </div>
              </div>
              <div className="input">
                <select
                  className="select-wrapper"
                  name="equipmentType"
                  value={equipmentType}
                  onChange={e => {
                    const { value } = e.target
                    this.setState(
                      { equipmentType: !Number.isNaN(Number(value)) ? value : null },
                      () => {
                        this.fetchEntries()
                      }
                    )
                  }}
                >
                  {types.map(type => (
                    <option key={type.id} value={type.id}>
                      {intl.formatMessage({ id: type.label })}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input">
                <input
                  placeholder={intl.formatMessage({ id: 'app.label.equipment_number' })}
                  type="text"
                  className="equipmentNumber"
                  value={equipmentName}
                  onChange={e => {
                    const { value } = e.target
                    this.setState(
                      { equipmentName: !Number.isNaN(Number(value)) ? value : null },
                      () => {
                        this.fetchEntries()
                      }
                    )
                  }}
                />
              </div>
            </div>
          </div>
          {!loading ? (
            <div className="row">
              <table className="table">
                <thead>
                  <tr>
                    <th>{intl.formatMessage({ id: 'app.label.dateAdded' })} </th>
                    <th>{intl.formatMessage({ id: 'app.label.equipment_type' })} </th>
                    <th>{intl.formatMessage({ id: 'app.label.equipment_name' })} </th>
                    <th>{intl.formatMessage({ id: 'app.label.date_of_last_control' })} </th>
                  </tr>
                </thead>
                <tbody>
                  {elementList.map(item => {
                    return (
                      <tr
                        key={item.id}
                        onClick={() => {
                          history.push({
                            pathname: `/traceability/equipment/single/${item.id}`
                          })
                        }}
                        className="pointer"
                      >
                        <td>
                          {item.firstOccurrenceDate
                            ? moment(item.firstOccurrenceDate).format('DD/MM/YYYY')
                            : intl.formatMessage({ id: 'app.label.traceability_unknown' })}
                        </td>
                        <td>
                          {intl.formatMessage({
                            id:
                              types.find(type => type.id === item.equipmentType)?.label ??
                              'app.label.traceability_unknown'
                          })}
                        </td>
                        <td>
                          {item.equipmentId
                            ? item.equipmentId
                            : intl.formatMessage({ id: 'app.label.traceability_unknown' })}
                        </td>
                        <td>
                          {item.lastControlDate
                            ? moment(item.lastControlDate).format('DD/MM/YYYY')
                            : intl.formatMessage({ id: 'app.label.traceability_unknown' })}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
                <tfoot className="center">
                  <tr>
                    <th colSpan="5">
                      <AsyncLoader status={contentStatus} />
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          ) : (
            <LoadingPage />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(List)))
