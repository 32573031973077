import React from 'react'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import icons from '../../../assets/icons/icons'
import LinksPage from '../../../components/LinksPage'

const RealisationHome = () => {
  const items = [
    {
      icon: icons.injectedVaccine,
      name: 'injectableVaccines',
      path: '/vaccination/realisation/injected'
    },
    {
      icon: icons.thinDropVaccine,
      name: 'thinDropsVaccines',
      path: '/vaccination/realisation/sprayable-vaccine/1'
    },
    {
      icon: icons.bigDropVaccine,
      name: 'bigDropsVaccines',
      path: '/vaccination/realisation/sprayable-vaccine/2'
    }
  ]

  return <LinksPage colClass="m4" items={items} itemsPerLine={3} />
}

export default injectIntl(withRouter(RealisationHome))
