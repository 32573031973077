import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { catchError } from '../../../../actions'
import CenterButton from '../../../../components/CenterButton'
import { validatedAction } from '../../../../components/globalAction'
import LoadingPage from '../../../../components/LoadingPage'
import ReportProblemSelectNewProductBag from '../../../../components/Vaccination/Realisation/Process/InjectableVaccinePart/ProductBagsPart/ReportProblem/partials/ReportProblemSelectNewProductBag'
import ReportProblemHeader from '../../../../components/Vaccination/Realisation/Process/InjectableVaccinePart/ProductBagsPart/ReportProblem/ReportProblemHeader'
import ReportProblemOrderBlockInfo from '../../../../components/Vaccination/Realisation/Process/InjectableVaccinePart/ProductBagsPart/ReportProblem/ReportProblemOrderBlockInfo'
import ReportProblemProductBagRadio from '../../../../components/Vaccination/Realisation/Process/InjectableVaccinePart/ProductBagsPart/ReportProblem/ReportProblemProductBagRadio'
import { api } from '../../../../parameters'
import { NEXT_PAGE } from '../../../../actions/types'

class ReportProblemProductBag extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      part: 0,
      loading: true,
      disabledButton: true
    }
    this.getDataFromChild = this.getDataFromChild.bind(this)
    this.changePart = this.changePart.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const { match, token } = this.props
    const { id } = match.params

    Axios.get(`${api}/api/order_order_preparations/${id}`, { headers: { authorization: token } })
      .then(res => {
        this.setState({ preparation: res.data, loading: false })
      })
      .catch(err => catchError(err.response))
  }

  getContent() {
    const { intl } = this.props
    const { part, disabledButton, preparation } = this.state
    switch (part) {
      case 0:
        return (
          <>
            <ReportProblemHeader />
            <ReportProblemOrderBlockInfo preparation={preparation} />
            <ReportProblemProductBagRadio
              getDataFromChild={this.getDataFromChild}
              preparation={preparation}
            />
            <CenterButton
              text={intl.formatMessage({ id: 'app.button.searchAnOtherProductBag' })}
              disabled={disabledButton}
              onClick={this.changePart}
            />
          </>
        )
      default:
        return (
          <>
            <ReportProblemSelectNewProductBag
              getDataFromChild={this.getDataFromChild}
              preparation={preparation}
            />
            <CenterButton
              text={intl.formatMessage({ id: 'app.button.validate' })}
              disabled={disabledButton}
              onClick={this.handleSubmit}
            />
          </>
        )
    }
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  handleSubmit() {
    const { match, token, nextPage } = this.props
    const { id } = match.params
    const { newProductBag, type } = this.state

    const dataTosubmit = {
      type,
      newPbag: newProductBag
    }

    Axios.patch(`${api}/api/report_problem/${id}`, dataTosubmit, {
      headers: { authorization: token }
    })
      .then(() => {
        nextPage()
        validatedAction('')
      })
      .catch(err => catchError(err.response))
  }

  changePart() {
    let { part } = this.state
    part += 1
    this.setState({ part })
  }

  render() {
    const { loading } = this.state

    if (loading) {
      return <LoadingPage />
    }

    return <div className="row Container stockAvailibilityItem">{this.getContent()}</div>
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    nextPage: () => dispatch({ type: NEXT_PAGE })
  }
}

export default injectIntl(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportProblemProductBag))
)
