import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { catchError } from '../../actions'
import { closePage, validatedAction } from '../../components/globalAction'
import LoadingPage from '../../components/LoadingPage'
import IncompatibilityTable from '../../components/Parameters/VeterinaryPart/IncompatibilityTable'
import { conservationMethods } from '../../config/productConfig'
import { administrations, api, valences } from '../../parameters'
import ValenceContext from '../../context/ValencesContext'

class AddVaccine extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      incompatibilities: { blocking: [], nonBlocking: [] },
      part: 0
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.changePart = this.changePart.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
  }

  componentDidMount() {
    const { token } = this.props
    Axios.get(`${api}/api/vaccine_specialities`, {
      headers: {
        Authorization: token
      }
    })
      .then(res => {
        this.setState({ specialities: res.data['hydra:member'], loading: false })
      })
      .catch(err => catchError(err.response))

    M.AutoInit()
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  changePart() {
    const { part } = this.state
    this.setState({ part: part + 1 })
  }

  deleteArticle(e) {
    const { token, intl } = this.props
    const { id } = e.target

    Swal.fire({
      title: intl.formatMessage({ id: 'app.alert.areYouSureTitle' }),
      text: intl.formatMessage({ id: 'app.alert.deleteArticleVaccine' }),
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: intl.formatMessage({ id: 'app.label.no' }),
      confirmButtonText: intl.formatMessage({ id: 'app.label.yes' }),
      customClass: {
        icon: 'swalErrorIcon',
        title: 'swalTitle',
        content: 'swalContentText',
        confirmButton: 'blueBtn',
        cancelButton: 'blueBtn'
      }
    }).then(result => {
      if (result.value) {
        Axios.delete(`${api}/api/vaccine_articles/${id}`, { headers: { Authorization: token } })
          .then(() => validatedAction('', 0))
          .catch(err => catchError(err.response))
      }
    })
  }

  handleChange(e) {
    const { name, value, type, checked } = e.target
    if (type === 'checkbox') {
      this.setState({ [name]: checked })
    } else {
      this.setState({ [name]: value })
    }
  }

  handleSubmit() {
    const { token } = this.props
    const {
      administration,
      conservation,
      descContent,
      incompatibilities,
      name,
      shapsMatch,
      valence
    } = this.state

    let formData = {}

    formData = {
      name,
      valence: parseInt(valence),
      conservationMethod: parseInt(conservation),
      administrationMethod: parseInt(administration),
      descTitle: '',
      descContent,
      minimalStockAlert: { type: 2, value: '', message: '' },
      incompatibilities,
      shapsMatch,
      channels: []
    }

    Axios.post(`${api}/api/vaccine_specialities`, formData, {
      headers: { Authorization: token }
    })
      .then(() => validatedAction(''))
      .catch(err => catchError(err.response))
  }

  static contextType = ValenceContext

  render() {
    const { intl, match, role } = this.props
    const {
      loading,
      name,
      specialityData,
      descContent,
      specialities,
      administration,
      part,
      shapsMatch,
      incompatibilities
    } = this.state
    const { params } = match
    const { id } = params
    const { getValenceLabelByKey } = this.context

    if (loading) {
      return <LoadingPage />
    }

    return (
      <div className="row Container params">
        <div className="col s12 content marginTop30">
          <div className="pageTitle pageHeader row col s12">
            <div className="row col s12">
              <div className="flex">
                <h5>
                  <span>{specialityData && specialityData.name}</span>
                </h5>
              </div>
            </div>
            <div
              className="goBackBtn close right"
              role="button"
              tabIndex="0"
              onKeyPress={() => closePage(intl, false)}
              onClick={() => closePage(intl, false)}
            >
              <i className="material-icons">close</i>
            </div>
          </div>

          <div className="row col s12">
            <div className="row col s12 card">
              <div className="col s12 form">
                <form>
                  <div className="row">
                    <div className="col s12">
                      <p className="greyTitle">
                        {intl.formatMessage({ id: 'app.label.generalsInformations' })}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s5 input">
                      <label htmlFor="speciality" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.speciality' })}
                        <input type="text" name="name" value={name} onChange={this.handleChange} />
                      </label>
                    </div>
                    <div className="col s2 input">
                      <label htmlFor="speciality" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.vaccinesReference' })}
                        <input
                          type="text"
                          name="shapsMatch"
                          value={shapsMatch}
                          onChange={this.handleChange}
                        />
                      </label>
                    </div>
                    <div className="col s5 input">
                      <label htmlFor="valence" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.valence' })}
                      </label>
                      <select
                        className="select-wrapper"
                        defaultValue="0"
                        name="valence"
                        onChange={this.handleChange}
                      >
                        <option value="0" disabled>
                          {intl.formatMessage({ id: 'app.label.selectValence' })}
                        </option>
                        {valences.map(oneValence => (
                          <option key={oneValence.name} value={oneValence.name}>
                            {getValenceLabelByKey(oneValence.name)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s6 input">
                      <label htmlFor="conservation" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.conservation' })}
                      </label>
                      <select
                        className="select-wrapper"
                        name="conservation"
                        defaultValue="0"
                        onChange={this.handleChange}
                      >
                        <option value="0" disabled>
                          {intl.formatMessage({ id: 'app.label.selectConservationMethod' })}
                        </option>
                        {conservationMethods.map(conservationMethod => (
                          <option value={conservationMethod.value}>
                            {intl.formatMessage({
                              id: `app.label.conservationMethod.${conservationMethod.value}`
                            })}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col s6 input">
                      <label htmlFor="administration" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.administration' })}
                      </label>
                      <select
                        className="select-wrapper"
                        name="administration"
                        defaultValue="0"
                        onChange={this.handleChange}
                      >
                        <option value="0" disabled>
                          {intl.formatMessage({
                            id: 'app.label.selectAdministrationMethod'
                          })}
                        </option>
                        {administrations.map(administrationMethod => (
                          <option key={administrationMethod.name} value={administrationMethod.name}>
                            {intl.formatMessage({
                              id: `app.administrationMethod.${administrationMethod.name}`
                            })}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12 input">
                      <label htmlFor="descContent" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.specialityDescription' })}
                      </label>
                      <textarea
                        className="materialize-textarea"
                        name="descContent"
                        value={descContent}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  {part === 1 && (
                    <IncompatibilityTable
                      id={id}
                      name={name}
                      role={role}
                      specialities={specialities}
                      incompatibilities={incompatibilities}
                      administrationData={administration}
                      setDataToParent={this.getDataFromChild}
                    />
                  )}
                  <div className="row">
                    <div className="col offset-s5 s7 marginTop30">
                      <button
                        type="button"
                        className="btn blueBtn"
                        onClick={part === 1 ? this.handleSubmit : this.changePart}
                      >
                        {intl.formatMessage({ id: 'app.button.validate' })}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default injectIntl(connect(mapStateToProps)(AddVaccine))
