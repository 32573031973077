import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Axios from 'axios'
import icons from '../../../../../../assets/icons/icons'
import LoadingPage from '../../../../../LoadingPage'
import { api } from '../../../../../../parameters'
import { catchError } from '../../../../../../actions'

class DyeBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      useDye: true,
      loading: true,
      dyeList: []
    }
    this.handleCheck = this.handleCheck.bind(this)
    this.handleColorChange = this.handleColorChange.bind(this)
  }

  componentDidMount() {
    const { data, setStateToParent, hatchingDate, token } = this.props
    Axios.get(`${api}/api/fridge_dyes?dyeBatch.expirationDate[after]=${hatchingDate}`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const mappedDyeList = res.data['hydra:member'].map(i => {
          return {
            id: i.id,
            label: i.dyeBatch?.color,
            batchNumber: i.dyeBatch?.batchNumber,
            fridge: i.fridge?.name
          }
        })
        if (mappedDyeList.length > 0) {
          data.color = mappedDyeList[0].id
          setStateToParent(data)
        }
        this.setState({
          loading: false,
          dyeList: mappedDyeList
        })
      })
      .catch(err => catchError(err))
  }

  handleCheck(e) {
    const { data, setStateToParent } = this.props
    const { dyeList } = this.state
    const { checked } = e.target
    if (checked === false) {
      data.color = ''
      setStateToParent(data)
    } else {
      data.color = dyeList.length > 0 ? dyeList[0].id : ''
      setStateToParent(data)
    }
    this.setState({ useDye: checked }, () => M.AutoInit())
  }

  handleColorChange(e) {
    const { data, setStateToParent } = this.props
    const { value } = e.target
    data.color = value
    setStateToParent(data)
  }

  render() {
    const { disabled, intl } = this.props
    const { useDye, loading, dyeList } = this.state

    if (loading) {
      return <LoadingPage />
    }
    return (
      <div className="row form custom-card">
        <div className="card-content">
          <div className="row card-block">
            <div className="col s6">
              <div className="flex">
                <img
                  className="card-logo marginRight15 space5pixel"
                  src={icons.dye}
                  alt="logo injectable vaccine"
                />
                <span className="labelCustom">{intl.formatMessage({ id: 'app.label.dye' })}</span>
              </div>
            </div>
            <div className="col s6">
              <p className="labelCustom"> {intl.formatMessage({ id: `app.label.addDye` })}</p>
              <div className="switch">
                <label>
                  {intl.formatMessage({ id: `app.label.no` })}
                  <input checked={useDye} type="checkbox" onChange={this.handleCheck} />
                  <span className="lever" />
                  {intl.formatMessage({ id: `app.label.yes` })}
                </label>
              </div>
            </div>
          </div>
          {useDye === true && (
            <>
              <div className="divider greyDivider" />
              <div className="row card-block">
                <div className="col s3 input">
                  <label htmlFor="color" className="labelCustom">
                    {intl.formatMessage({ id: 'app.label.color' })}
                  </label>
                  <select
                    className="select-wrapper"
                    defaultValue="blue"
                    name="color"
                    type="number"
                    disabled={disabled}
                    onChange={this.handleColorChange}
                  >
                    {dyeList.map(col => (
                      <option key={col.id} value={col.id}>
                        {col.fridge} - {col.batchNumber} -{' '}
                        {intl.formatMessage({ id: `app.color.${col.label}` })}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(DyeBlock))
