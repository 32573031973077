/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import AsyncLoader from '../../../components/AsyncLoader'
import { WithUnit, SpanLink } from '../Components'
import { getEquipmentById } from '../ReverseRedirections'

function NitrogenTransfer({ token, location, intl, history }) {
  const translationKey = 'app.label.equipment.nitrogen_transfer.'
  const [page, setPage] = useState(1)
  const [state, setState] = useState({
    loading: true,
    items: null,
    contentStatus: false,
    orderNumber: null,
    equipmentId: null,
    equipmentType: null
  })

  function loadMore() {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (state.totalItems > page * 30) {
        setPage(page + 1)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', loadMore)
  }, [])

  useEffect(() => {
    const pathName = location.pathname.split('/')
    const id = Number(pathName[pathName.length - 2])
    Axios.get(`${api}/api/equipment_traceability/${id}/nitrogen?page=${page}`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const unknownValue = intl.formatMessage({ id: 'app.label.N/A' })
        const mapGiverOrCatcher = (i, type) => ({
          id: `${i.containerNumber}${i.containerType}`,
          type,
          equipmentId: i.containerNumber ?? unknownValue,
          status: i.containerType
            ? intl.formatMessage({
                id: `app.label.equipment.equipment_control.type.${i.containerType}`
              })
            : unknownValue,
          nitrogenLevelBeforeTransfer: {
            text: i.prevNitrogenLevel ?? unknownValue,
            unit: 'centimeter'
          },
          nitrogenLevelAfterTransfer: {
            text: i.newNitrogenLevel ?? unknownValue,
            unit: 'centimeter'
          }
        })
        const mappedData = res.data['hydra:member'].map((i, index) => {
          const givers =
            i.givers?.length > 0
              ? [
                  ...i.givers.map(giver =>
                    mapGiverOrCatcher(
                      giver,
                      intl.formatMessage({
                        id: `${translationKey}donor_device`
                      })
                    )
                  )
                ]
              : []
          const catchers =
            i.catchers?.length > 0
              ? [
                  ...i.catchers.map(catcher =>
                    mapGiverOrCatcher(
                      catcher,
                      intl.formatMessage({
                        id: `${translationKey}receipter_device`
                      })
                    )
                  )
                ]
              : []
          return {
            id: index,
            operator: i.operator ?? unknownValue,
            date: i.date ? moment(i.date).format('DD/MM/YYYY') : unknownValue,
            hour: i.date ? moment(i.date).format('HH[h]mm') : unknownValue,
            entries: [...givers, ...catchers]
          }
        })
        setState({
          ...state,
          loading: false,
          items: state.items ? state.items.concat(mappedData) : mappedData,
          equipmentType: intl.formatMessage({
            id: `app.filter.equipmentType.${res.data?.equipmentType}`
          }),
          equipmentId: res.data?.equipmentId,
          contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult'
        })
      })
      .catch(err => catchError(err))
  }, [page])

  return (
    <div className="row Container stockAvailibilityItem">
      <div className="col s12 Content">
        <div className="row pageHeader pageTitle itemHeader">
          <div
            onClick={() => history.goBack()}
            role="button"
            tabIndex="0"
            onKeyPress={() => history.goBack()}
            className="goBackBtn col s1"
          >
            <i className="material-icons">chevron_left</i>
          </div>
          {state.equipmentType !== null && (
            <h5 className="col s12">
              {state.equipmentType} {state.equipmentId} -{' '}
              {intl.formatMessage({ id: 'app.title.nitrogen_transfer' })}
            </h5>
          )}
        </div>
        {!state.loading ? (
          <div className="row">
            {state.items.map(item => {
              return (
                <div key={item.id}>
                  <div>{`${item.operator} - ${item.date} ${item.hour}`}</div>
                  <table className="table" style={{ marginBottom: '16px' }}>
                    <thead>
                      <tr>
                        <th>{intl.formatMessage({ id: `${translationKey}type` })}</th>
                        <th>{intl.formatMessage({ id: `${translationKey}equipment` })}</th>
                        <th>{intl.formatMessage({ id: `${translationKey}status` })}</th>
                        <th>
                          {intl.formatMessage({
                            id: `${translationKey}nitrogen_level_before_transfer`
                          })}
                        </th>
                        <th>
                          {intl.formatMessage({
                            id: `${translationKey}nitrogen_level_after_transfer`
                          })}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.entries.map(entry => {
                        return (
                          <tr key={entry.id}>
                            <td>{entry.type}</td>
                            <td>
                              <SpanLink
                                value={entry.equipmentId}
                                setLoading={loading => {
                                  setState({ ...state, loading })
                                }}
                                redirect={getEquipmentById}
                                token
                                history
                              />
                            </td>
                            <td>{entry.status}</td>
                            <td>
                              <WithUnit {...entry.nitrogenLevelBeforeTransfer} />
                            </td>
                            <td>
                              <WithUnit {...entry.nitrogenLevelAfterTransfer} />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              )
            })}
            <AsyncLoader status={state.contentStatus} />
          </div>
        ) : (
          <LoadingPage />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(NitrogenTransfer)))
