import Axios from 'axios'
import { saveAs } from 'file-saver'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { catchError } from '../../../../actions'
import icons from '../../../../assets/icons/icons'
import { api } from '../../../../parameters'

const DocumentItem = props => {
  const { doc, i, intl, token } = props
  const { whiteDocFile, whiteExcelFile, whitePdfFile, whiteVideoFile } = icons
  let icon = ''

  switch (doc.type) {
    case 0:
      icon = whiteVideoFile
      break
    case 1:
      icon = whiteDocFile
      break
    case 2:
      icon = whitePdfFile
      break
    case 3:
      icon = whiteExcelFile
      break
    default:
      icon = whiteDocFile
  }

  const onClick = id => {
    Axios.get(`${api}/api/documentation/download/${id}`, {
      headers: { Authorization: token },
      responseType: 'blob'
    })
      .then(res => {
        const name = res.headers['attachment-filename']
        saveAs(res.data, name)
      })
      .catch(err => catchError(err.response))
  }

  return (
    <div
      key={i}
      className="col l4 m6 pointer"
      onClick={() => onClick(doc.id)}
      onKeyPress={() => onClick(doc.id)}
      tabIndex={0}
      role="button"
    >
      <div className="col m12 card custom-card noPadding docItemCard">
        <div className="card-header white-text">
          <img src={icon} alt="icon" className="docIcon" />
          <span className="category">
            {intl.formatMessage({ id: `app.document.category.${doc.category}` })}
          </span>
        </div>
        <div className="col m12 card-content">
          <div className="row card-block">
            <div className="col m12">
              <div className="labelCustom em1-2 flex flex-center blue-text docTitle">
                {doc.title}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(DocumentItem))
