import React from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import moment from 'moment'

const CertificateInfos = props => {
  const { date, number, intl } = props

  return (
    <div id="certificateInfos" className="row">
      <div className="col s5 bold">
        {intl.formatMessage({ id: 'app.certificate.certificate' })} {number}
      </div>
      <div className="col s5">{moment(date).format('DD/MM/Y')}</div>
    </div>
  )
}

export default injectIntl(CertificateInfos)

CertificateInfos.propTypes = {
  date: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired
}
