import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'

class InputSelectVaccine extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      vaccinesBatches: []
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    M.AutoInit()
  }

  handleChange(e) {
    const { name, value } = e.target
    const { getDataFromChild, index, vaccines, vaccinesSelected } = this.props
    const { damagedVaccine, batchNumber } = this.state

    const parseIntValue = parseInt(value)
    if (name === 'damagedVaccine') {
      vaccinesSelected[index].vaccine = vaccines[parseIntValue]
      const vaccinesBatches = []

      vaccines.forEach(vaccine => {
        if (vaccine.batch === vaccinesSelected[index].vaccine.batch) {
          vaccinesBatches.push(vaccine)
        }
      })

      this.setState({ vaccinesBatches }, () => M.AutoInit())

      if (batchNumber) {
        getDataFromChild('disabledButton', false)
      }
    } else if (name === 'batchNumber') {
      vaccinesSelected[index].batchNumber = value
      if (damagedVaccine !== undefined) {
        getDataFromChild('disabledButton', false)
      }
    }
    this.setState({ [name]: parseIntValue })

    getDataFromChild('vaccinesSelected', vaccinesSelected)
  }

  render() {
    const { intl, type, vaccines, vaccineError } = this.props
    const { vaccinesBatches } = this.state

    return (
      <div className="row">
        <div className="col s12 input">
          <div className="input col s6">
            <label htmlFor="damagedVaccine" className="labelParams">
              {vaccineError.includes(type)
                ? intl.formatMessage({ id: 'app.label.wrongVaccine' })
                : intl.formatMessage({ id: 'app.label.damagedVaccine' })}
            </label>
            <select
              className="select-wrapper"
              name="damagedVaccine"
              defaultValue="0"
              onChange={this.handleChange}
              required
            >
              <option value="0" disabled>
                {intl.formatMessage({ id: 'app.label.selectVaccine' })}
              </option>
              {vaccines.map((vaccine, index) => (
                <option key={vaccine.batch} value={index}>
                  {vaccine.speciality}
                </option>
              ))}
            </select>
          </div>
          <div className="input col s6">
            <label htmlFor="batchNumber" className="labelParams">
              {intl.formatMessage({ id: 'app.label.batchNumber' })}
            </label>
            <select
              className="select-wrapper"
              name="batchNumber"
              defaultValue="0"
              onChange={this.handleChange}
              disabled={vaccinesBatches.length === 0}
              required
            >
              <option value="0" disabled>
                {intl.formatMessage({ id: 'app.label.selectBatchNumber' })}
              </option>
              {vaccinesBatches.map(vaccine => (
                <option key={vaccine.batchNumber} value={vaccine.batchNumber}>
                  {vaccine.batchNumber}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(InputSelectVaccine)
