import React from 'react'
import icons from '../../../../../assets/icons/icons'
import SprayedVaccinesStep from './SprayedVaccinesStep'

class ThinDropsVaccinesStep extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    const { changePart, group, total, totalFixed, isAdding, addSubmit, hatchingDate } = this.props

    return (
      <SprayedVaccinesStep
        changePart={changePart}
        group={group}
        headerText="thinDropsVaccines"
        icon={icons.thinDropVaccine}
        order={group.thinDropsOrder}
        totalFixed={totalFixed}
        total={total}
        vaccineType={1}
        addSubmit={addSubmit}
        isAdding={isAdding}
        hatchingDate={hatchingDate}
      />
    )
  }
}

export default ThinDropsVaccinesStep
