import { injectIntl } from 'react-intl'
import React from 'react'
import M from 'materialize-css'
import { withRouter } from 'react-router'
import ChangesDisplayNotification from './partials/ChangesDisplayNotification'
import NoBlockPart from '../NoBlockPart/NoBlockPart'
import IncompatibilityTable from '../VeterinaryPart/IncompatibilityTable'
import { goBack } from '../../globalAction'
import ValenceContext from '../../../context/ValencesContext'

class AddAndEditSpeciality extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    M.AutoInit()
  }

  static contextType = ValenceContext

  render() {
    const { data, intl, history } = this.props
    const { type, history: hist } = data
    const { changes, name } = hist.action
    const { channels, valence, descContent } = changes || hist.action
    const { getValenceLabelByKey } = this.context

    const pathGoBack = '/parameters/parameter-history'

    return (
      <div className="Container stockHistory">
        <div>
          <div className="pageTitle pageHeader row">
            <div className="col s12">
              <div
                role="button"
                className="flexInline pointer"
                onClick={() => goBack(pathGoBack, history)}
                onKeyPress={() => goBack(pathGoBack, history)}
                tabIndex={0}
              >
                <div className="goBackBtn">
                  <i role="button" tabIndex="0" className="material-icons">
                    chevron_left
                  </i>
                </div>
                <h5>
                  <span>
                    {intl.formatMessage({
                      id: `app.label.parameters.action.${type}`
                    })}
                  </span>
                </h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <div className="row">
                <div className="row form">
                  <div className="col s12">
                    <div className="row">
                      <div className="col s12">
                        <div className="row">
                          <h4>{`${hist.action.name}`}</h4>
                          <h5>
                            <span>{intl.formatMessage({ id: 'app.label.modificationDone' })}</span>
                          </h5>
                        </div>
                        <div className="row params">
                          <div className="col s12">
                            <div className="row">
                              <div className="col s12">
                                <div className="row">
                                  <div className="col s6 input">
                                    {changes && (
                                      <div className="row">
                                        <div className="col s12">
                                          <label
                                            htmlFor="congruentPartTempBetweenMin"
                                            className="labelParams"
                                          >
                                            {intl.formatMessage({ id: 'app.label.before' })}
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                    <div className="row input">
                                      {((changes && changes.name !== undefined) ||
                                        hist.action.name !== undefined) && (
                                        <div className="col s6">
                                          <label htmlFor="noBlockPartAlert" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.name' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>
                                            {changes && changes.name !== undefined
                                              ? changes.name[0]
                                              : name}
                                          </span>
                                        </div>
                                      )}
                                      {((changes && changes.valence !== undefined) ||
                                        hist.action.valence !== undefined) && (
                                        <div className="col s6">
                                          <label htmlFor="noBlockPartAlert" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.valence' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>
                                            {getValenceLabelByKey(
                                              changes && changes.valence !== undefined
                                                ? valence[0].toString()
                                                : hist.action.valence
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    <div className="row input">
                                      {((changes && changes.administrationMethod !== undefined) ||
                                        hist.action.administration !== undefined) && (
                                        <div className="col s6">
                                          <label htmlFor="administration" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.administration' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>
                                            {intl.formatMessage({
                                              id: `app.administrationMethod.${
                                                changes &&
                                                changes.administrationMethod !== undefined
                                                  ? changes.administrationMethod[0].toString()
                                                  : hist.action.administration
                                              }`
                                            })}
                                          </span>
                                        </div>
                                      )}
                                      {((changes && changes.conservationMethod !== undefined) ||
                                        hist.action.conservation !== undefined) && (
                                        <div className="col s6">
                                          <label htmlFor="conservation" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.conservation' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>
                                            {intl.formatMessage({
                                              id: `app.label.conservationMethod.${
                                                changes && changes.conservationMethod !== undefined
                                                  ? changes.conservationMethod[0].toString()
                                                  : hist.action.conservation
                                              }`
                                            })}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    <div className="row input">
                                      {((changes && changes.descContent !== undefined) ||
                                        hist.action.descContent !== undefined) && (
                                        <div className="col s12">
                                          <label htmlFor="descContent" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.description' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>
                                            {changes && changes.descContent !== undefined
                                              ? descContent[0]
                                              : hist.action.descContent}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {changes && (
                                    <div className="col s6">
                                      <div className="row input">
                                        <div className="col s12">
                                          <label
                                            htmlFor="congruentPartTempBetweenMin"
                                            className="labelParams"
                                          >
                                            {intl.formatMessage({ id: 'app.label.after' })}
                                          </label>
                                        </div>
                                      </div>

                                      <div className="row input">
                                        {hist.action.name && (
                                          <div className="col s6">
                                            <label htmlFor="name" className="labelParams">
                                              {intl.formatMessage({ id: 'app.label.name' })}
                                              &nbsp;:&nbsp;
                                            </label>
                                            <span>{hist.action.name}</span>
                                          </div>
                                        )}
                                        {hist.action.valence !== undefined && (
                                          <div className="col s6">
                                            <label htmlFor="valence" className="labelParams">
                                              {intl.formatMessage({ id: 'app.label.valence' })}
                                              &nbsp;:&nbsp;
                                            </label>
                                            <span>{getValenceLabelByKey(hist.action.valence)}</span>
                                          </div>
                                        )}
                                      </div>
                                      <div className="row input">
                                        {hist.action.administration !== undefined && (
                                          <div className="col s6">
                                            <label htmlFor="administration" className="labelParams">
                                              {intl.formatMessage({
                                                id: 'app.label.administration'
                                              })}
                                              &nbsp;:&nbsp;
                                            </label>
                                            <span>
                                              {intl.formatMessage({
                                                id: `app.administrationMethod.${hist.action.administration}`
                                              })}
                                            </span>
                                          </div>
                                        )}
                                        {hist.action.conservation !== undefined && (
                                          <div className="col s6">
                                            <label
                                              htmlFor="noBlockPartAlert"
                                              className="labelParams"
                                            >
                                              {intl.formatMessage({ id: 'app.label.conservation' })}
                                              &nbsp;:&nbsp;
                                            </label>
                                            <span>
                                              {intl.formatMessage({
                                                id: `app.label.conservationMethod.${hist.action.conservation}`
                                              })}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                      <div className="row input">
                                        {hist.action.descContent && (
                                          <div className="col s12">
                                            <label htmlFor="description" className="labelParams">
                                              {intl.formatMessage({ id: 'app.label.description' })}
                                              &nbsp;:&nbsp;
                                            </label>
                                            <span>{hist.action.descContent}</span>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  {changes && changes.minimalStockAlert && (
                                    <div className="divider greyDivider" />
                                  )}
                                </div>
                              </div>
                            </div>
                            {!changes && hist.action && hist.action.incompatibilities && (
                              <IncompatibilityTable
                                history
                                incompatibilities={hist.action.incompatibilities}
                                name={name}
                              />
                            )}
                            {changes && changes.incompatibilities && (
                              <div className="row">
                                <div className="col s6">
                                  <IncompatibilityTable
                                    history
                                    incompatibilities={changes.incompatibilities[0]}
                                  />
                                </div>
                                <div className="col s6">
                                  <IncompatibilityTable
                                    history
                                    incompatibilities={changes.incompatibilities[1]}
                                  />
                                </div>
                              </div>
                            )}
                            {changes && changes.minimalStockAlert && (
                              <div>
                                <div className="row">
                                  <div className="col s12">
                                    <NoBlockPart stockId action={hist.action} history />
                                  </div>
                                </div>
                              </div>
                            )}
                            {channels !== undefined && <div className="divider greyDivider" />}
                            {channels !== undefined && (
                              <div className="row">
                                <div className="col s12">
                                  <div className="row">
                                    <div className="col s12">
                                      <p className="greyTitle">
                                        {intl.formatMessage({ id: 'app.label.notification' })}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col s12">
                                      <div className="row">
                                        <ChangesDisplayNotification
                                          value1={channels[0]}
                                          value2={channels[1]}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(withRouter(AddAndEditSpeciality))
