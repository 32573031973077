import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { catchError } from '../../../actions'
import roles from '../../../config/userRoles.json'
import { api } from '../../../parameters'
import Filters from '../../Filters/Filters'
import { ExportFile } from '../../globalAction'
import LoadingPage from '../../LoadingPage'
import AddButton from './AddButton'
import VaccineSpeciality from './VaccineSpeciality'

class InjectedVaccinesPart extends React.Component {
  constructor() {
    super()
    this.state = {
      vaccines: [],
      loading: true,
      url: `${api}/api/vaccines_batches/injected`,
      specialitiesData: [],
      filters: [
        { category: 'batchNumber', sortBy: ['asc', 'desc'], catTranslationPrefix: 'order' },
        {
          category: 'vaccineArticle.speciality.name',
          sortBy: ['asc', 'desc'],
          catTranslationPrefix: 'order'
        },
        {
          category: 'vaccineArticle.name',
          sortBy: ['asc', 'desc'],
          catTranslationPrefix: 'order'
        },
        { category: 'expirationDate', sortBy: ['asc', 'desc'], catTranslationPrefix: 'order' }
      ]
    }

    this.getStateFromChild = this.getStateFromChild.bind(this)
  }

  componentDidMount() {
    const { token } = this.props
    const { filters } = this.state

    Axios.get(`${api}/api/vaccines_batches/injected?vaccineArticle.name=asc&active=true`, {
      headers: {
        Authorization: token
      }
    })
      .then(res => {
        Axios.get(`${api}/api/vaccine_specialities`, {
          headers: {
            Authorization: token
          }
        })
          .then(result => {
            Axios.get(`${api}/api/vaccine_articles`, {
              headers: {
                Authorization: token
              }
            })
              .then(result2 => {
                const specialities = []
                const specialitiesData = []
                const articles = []

                result.data['hydra:member'].map(speciality => specialities.push(speciality.name))
                result.data['hydra:member'].map(speciality => specialitiesData.push(speciality))
                filters.push({
                  category: 'vaccineArticle.speciality.name',
                  sortBy: specialities,
                  toTranslate: false
                })

                result2.data['hydra:member'].map(article => articles.push(article.name))
                filters.push({
                  category: 'vaccineArticle.name',
                  sortBy: articles,
                  toTranslate: false
                })

                this.setState({
                  vaccines: res.data,
                  filters,
                  specialitiesData,
                  loading: false
                })
              })
              .catch(err => catchError(err.response))
          })
          .catch(err => catchError(err.response))
      })
      .catch(err => catchError(err.response))
  }

  getAllVaccinesBySpeciality() {
    const { vaccines } = this.state
    const vaccinesBySpe = []

    vaccines.forEach(vaccine => {
      if (vaccine.ampoulesNumber === 0) {
        return
      }

      if (undefined === vaccinesBySpe[vaccine.vaccineArticle.speciality.name]) {
        vaccinesBySpe[vaccine.vaccineArticle.speciality.name] = []
      }
      vaccine.nitrogenContainerVaccinesBatches.forEach(batch => {
        vaccinesBySpe[vaccine.vaccineArticle.speciality.name].push({ batch, vaccine })
      })

      vaccine.fridgeVaccinesBatches.forEach(batch => {
        vaccinesBySpe[vaccine.vaccineArticle.speciality.name].push({ batch, vaccine })
      })
    })

    return vaccinesBySpe
  }

  getStateFromChild(vaccines) {
    this.setState({ vaccines })
  }

  render() {
    const { intl, token, role } = this.props
    const { filters, loading, url, specialitiesData } = this.state

    const { ROLE_HATCH_RESP, ROLE_HATCHING_RESP } = roles

    if (loading === true) {
      return <LoadingPage />
    }

    const vaccines = this.getAllVaccinesBySpeciality()
    const specialities = Object.keys(vaccines)

    return specialities.length !== 0 ? (
      <div>
        <div className="row pageHeader pageTitle" data-testid="injectedArray">
          <div className="col m12 filters">
            <div>
              <Filters
                hydra={false}
                token={token}
                setStateToParent={this.getStateFromChild}
                url={url}
                role={role}
                filters={filters}
                inLoad={() => {}}
                hasDate={false}
              />
            </div>
          </div>
          <div className="export col s2">
            <div>
              <ExportFile data={{ role, token }} url="/api/vaccines_batches/injected/export/xlsx" />
            </div>
            <div>
              {(role.includes(ROLE_HATCH_RESP) || role.includes(ROLE_HATCHING_RESP)) && (
                <AddButton path="/stocks/order-received/frozen-vaccine" />
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            {specialities.map((speciality, i) => {
              const key = `batch-${i}`
              let specialitieDataResult = null
              specialitiesData.forEach(specialityData => {
                if (specialityData.name === speciality) {
                  specialitieDataResult = specialityData
                }
              })
              return (
                <VaccineSpeciality
                  intl={intl}
                  speciality={speciality}
                  specialityData={specialitieDataResult}
                  vaccines={vaccines}
                  key={key}
                  path="/stocks/stock-availibility/injected-vaccine"
                />
              )
            })}
          </div>
        </div>
      </div>
    ) : (
      <div className="card-vaccines">{intl.formatMessage({ id: 'app.message.noVaccine' })}</div>
    )
  }
}

export default injectIntl(InjectedVaccinesPart)
