import Axios from 'axios'
import moment from 'moment'
import { api } from '../../parameters'

function getRedirection(
  fetchUrl,
  redirectionUrl,
  token,
  history,
  value,
  setLoading,
  fallbackUrl = null
) {
  setLoading(true)
  Axios.get(`${api}${fetchUrl}${value}`, {
    headers: { Authorization: token }
  })
    .then(res => {
      setLoading(false)
      if (res.data['hydra:member'].length > 0) {
        if (res.data['hydra:member'].length > 1 && fallbackUrl) {
          history.push(`${fallbackUrl}${value}`)
        } else {
          history.push(`${redirectionUrl}${res.data['hydra:member'][0].id}`)
        }
      }
    })
    .catch(() => setLoading(false))
}

function getVaccineOrProductByBatchNumber(token, history, value, setLoading) {
  const endDate = moment(new Date(), 'yyyy-mm-dd')
  getRedirection(
    '/api/batch_traceabilities?batchNumber=',
    '/traceability/vaccine_or_product/single/',
    token,
    history,
    value,
    setLoading,
    `/traceability/vaccine_or_product/list/?startDate=2020-01-01&endDate=${endDate}&batchNumber=`
  )
}

function getOrderByCurrentNumber(token, history, value, setLoading) {
  getRedirection(
    '/api/order_traceabilities?currentNumber=',
    '/traceability/order/single/',
    token,
    history,
    value,
    setLoading
  )
}

function getEquipmentById(token, history, value, setLoading) {
  getRedirection(
    '/api/equipment_traceabilities?equipmentId=',
    '/traceability/equipment/single/',
    token,
    history,
    value,
    setLoading
  )
}

export { getVaccineOrProductByBatchNumber, getOrderByCurrentNumber, getEquipmentById }
