import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { catchError } from '../../../../../actions'
import { api } from '../../../../../parameters'

class FridgeInformationForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fridgeInformations: {}
    }
    this.handleChange = this.handleChange.bind(this)
    this.getFridgeInformation = this.getFridgeInformation.bind(this)
  }

  componentDidMount() {
    M.AutoInit()
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      M.AutoInit()
    }
  }

  getFridgeInformation(id) {
    const { token } = this.props
    Axios.get(`${api}/api/fridges/${id}`, { headers: { Authorization: token } })
      .then(res => this.setState({ fridgeInformations: res.data }))
      .catch(err => catchError(err.response))
  }

  handleChange(e) {
    const { value } = e.target
    const { setDataToParent, index, setFridgeListSelected } = this.props
    this.getFridgeInformation(value)
    setDataToParent('selectFridge', value, index)
    setFridgeListSelected(value, index)
  }

  render() {
    const { fridges, intl, fridgeListSelected, disabledForm, index } = this.props
    const { fridgeInformations } = this.state
    const { lastControl } = fridgeInformations
    return (
      <div className="row ">
        <div className="col s12">
          <div className="form">
            <div className="row marginTop20">
              <div className="col s3 input">
                <label htmlFor="fridge">{intl.formatMessage({ id: 'app.label.fridge' })}</label>
                <select
                  className="select-wrapper"
                  defaultValue="default"
                  id="fridge"
                  onChange={this.handleChange}
                  disabled={disabledForm[index]}
                >
                  <option value="default" disabled>
                    {intl.formatMessage({ id: 'app.label.selectFridge' })}
                  </option>
                  {fridges.map(fridge => (
                    <option
                      value={fridge.id}
                      key={fridge.id}
                      disabled={!(fridgeListSelected.indexOf(fridge.id) === -1)}
                    >
                      {fridge.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col s3 input">
                <label htmlFor="minTemperature">
                  {intl.formatMessage({ id: 'app.equipment.lastControl.minTemperature' })}
                </label>
                <input
                  type="text"
                  id="minTemperature"
                  value={lastControl ? lastControl.minTemperature : '-'}
                  disabled
                />
              </div>
              <div className="col s3 input">
                <label htmlFor="maxTemperature">
                  {intl.formatMessage({ id: 'app.equipment.lastControl.maxTemperature' })}
                </label>
                <input
                  type="text"
                  id="maxTemperature"
                  value={lastControl ? lastControl.maxTemperature : '-'}
                  disabled
                />
              </div>
              <div className="col s3 input">
                <label htmlFor="instantTemperature">
                  {intl.formatMessage({ id: 'app.equipment.lastControl.instantTemperature' })}
                </label>
                <input
                  type="text"
                  id="instantTemperature"
                  value={lastControl ? lastControl.instantTemperature : '-'}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(FridgeInformationForm)
