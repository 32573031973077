import Axios from 'axios'
import React from 'react'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import ContainerReceipt from '../../../components/StockManagement/StockHistory/partials/ContainerReceipt'
import DiluentReceipt from '../../../components/StockManagement/StockHistory/partials/DiluentReceipt'
import DiluentsDestock from '../../../components/StockManagement/StockHistory/partials/DiluentsDestock'
import DyesDestock from '../../../components/StockManagement/StockHistory/partials/DyesDestock'
import DyesReceipt from '../../../components/StockManagement/StockHistory/partials/DyesReceipt'
import FrozenVaccinesDestock from '../../../components/StockManagement/StockHistory/partials/FrozenVaccinesDestock'
import FrozenVaccinePlusContainerReceipt from '../../../components/StockManagement/StockHistory/partials/FrozenVaccinesPlusContainerReceipt'
import FrozenVaccinePlusNitrogenReceipt from '../../../components/StockManagement/StockHistory/partials/FrozenVaccinesPlusNitrogenReceipt'
import FrozenVaccineReceipt from '../../../components/StockManagement/StockHistory/partials/FrozenVaccinesReceipt'
import NitrogenReceipt from '../../../components/StockManagement/StockHistory/partials/NitrogenReceipt'
import ProductBagReceipt from '../../../components/StockManagement/StockHistory/partials/ProductBagReceipt'
import ProductBagsDestock from '../../../components/StockManagement/StockHistory/partials/ProductBagsDestock'
import RefrigeratedVaccineDestock from '../../../components/StockManagement/StockHistory/partials/RefrigeratedVaccineDestock'
import RefrigeratedVaccinesReceipt from '../../../components/StockManagement/StockHistory/partials/RefrigeratedVaccinesReceipt'
import { api } from '../../../parameters'

class SingleHistoryStock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    const { token, match } = this.props
    Axios.get(`${api}/api/stock_histories/${match.params.id}`, {
      headers: { Authorization: token }
    })
      .then(res => this.setState({ history: res.data, loading: false }))
      .catch(err => catchError(err.response))
  }

  render() {
    const { history, loading } = this.state

    if (loading === true) {
      return <LoadingPage />
    }

    const { type, firstName, createdAt, history: action } = history

    switch (type) {
      case 0:
      case 15:
        return (
          <FrozenVaccineReceipt
            date={createdAt}
            history={action}
            type={type}
            operator={firstName}
          />
        )
      case 1:
      case 14:
        return (
          <RefrigeratedVaccinesReceipt
            date={createdAt}
            history={action}
            type={type}
            operator={firstName}
          />
        )
      case 2:
      case 16:
        return (
          <FrozenVaccinePlusContainerReceipt
            date={createdAt}
            history={action}
            type={type}
            operator={firstName}
          />
        )
      case 3:
      case 17:
        return (
          <FrozenVaccinePlusNitrogenReceipt
            date={createdAt}
            history={action}
            type={type}
            operator={firstName}
          />
        )
      case 4:
        return (
          <NitrogenReceipt date={createdAt} history={action} type={type} operator={firstName} />
        )
      case 5:
      case 18:
        return <DiluentReceipt date={createdAt} history={action} type={type} operator={firstName} />
      case 6:
      case 19:
        return <DyesReceipt date={createdAt} history={action} type={type} operator={firstName} />
      case 7:
      case 20:
        return (
          <ProductBagReceipt date={createdAt} history={action} type={type} operator={firstName} />
        )
      case 8:
        return (
          <ContainerReceipt date={createdAt} history={action} type={type} operator={firstName} />
        )
      case 9:
        return (
          <FrozenVaccinesDestock
            date={createdAt}
            history={action}
            type={type}
            operator={firstName}
          />
        )
      case 10:
        return (
          <RefrigeratedVaccineDestock
            date={createdAt}
            history={action}
            type={type}
            operator={firstName}
          />
        )
      case 11:
        return (
          <ProductBagsDestock date={createdAt} history={action} type={type} operator={firstName} />
        )
      case 12:
        return <DyesDestock date={createdAt} history={action} type={type} operator={firstName} />
      case 13:
        return (
          <DiluentsDestock date={createdAt} history={action} type={type} operator={firstName} />
        )
      default:
        return (
          <div>
            <LoadingPage />
          </div>
        )
    }
  }
}

export default SingleHistoryStock
