import React, { useContext } from 'react'
import { injectIntl } from 'react-intl'
import THead from './partials/THead'
import Tr from './partials/Tr'
import {
  GenerationsContext,
  PrestationsContext,
  ProductsForOrderContext
} from '../../../../../../../../context/contexts'

const MoreInfosTable = props => {
  const { group } = props
  const { getPrestationLabelByKey } = useContext(PrestationsContext)
  const { getGenerationLabelByKey } = useContext(GenerationsContext)
  const { getProductForOrderLabelByKey } = useContext(ProductsForOrderContext)
  return (
    <table className="moreInfosTable table width100">
      <THead />
      <tbody>
        {group.map(g => (
          <Tr
            key={g.id}
            group={g}
            getPrestationLabelByKey={getPrestationLabelByKey}
            getGenerationLabelByKey={getGenerationLabelByKey}
            getProductForOrderLabelByKey={getProductForOrderLabelByKey}
          />
        ))}
      </tbody>
    </table>
  )
}

export default injectIntl(MoreInfosTable)
