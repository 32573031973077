import React from 'react'
import { injectIntl } from 'react-intl'
import { closePage } from '../../../../../../globalAction'

const ReportProblemHeader = props => {
  const { intl } = props
  return (
    <div className="row pageHeader pageTitle outStockHeader">
      <h5>{intl.formatMessage({ id: 'app.label.signalProblem' })}</h5>
      <div
        className="goBackBtn col m1"
        role="button"
        tabIndex="0"
        onKeyPress={() => closePage(intl)}
        onClick={() => closePage(intl)}
      >
        <i className="material-icons">close</i>
      </div>
    </div>
  )
}

export default injectIntl(ReportProblemHeader)
