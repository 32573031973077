import React from 'react'
import { connect } from 'react-redux'
import Axios from 'axios'
import moment from 'moment'
import { api } from '../../../../../../../../parameters'
import BlockHeader from './partials/BlockHeader'
import VaccBatchSelector from './partials/VaccBatchSelector'

class VaccineBlock extends React.Component {
  constructor() {
    super()
    this.state = {
      selectedBatches: [{}]
    }

    this.setSelectedBatches = this.setSelectedBatches.bind(this)
    this.setNeededDoses = this.setNeededDoses.bind(this)
    this.reload = this.reload.bind(this)
  }

  componentDidMount() {
    const { result, vaccine, setResult } = this.props
    const { id, speciality } = vaccine
    const { conservationMethod: conservation } = speciality

    switch (conservation) {
      case 0:
        if (result.containerVaccines === undefined) {
          result.containerVaccines = {}
        }

        result.containerVaccines[id] = [{}]
        break
      case 1:
        if (result.fridgeVaccines === undefined) {
          result.fridgeVaccines = {}
        }

        result.fridgeVaccines[id] = [{}]
        break
      default:
        break
    }

    setResult(result)
    this.setBatches()
  }

  componentDidUpdate(prevProps) {
    const { neededDoses } = this.props

    if (prevProps.neededDoses !== neededDoses) {
      this.setState({ neededDoses })
    }
  }

  setBatches() {
    const { vaccine, token, neededDoses, volume, hatchingDate } = this.props
    const { conservationMethod: conservation, id: specialityId } = vaccine.speciality
    let apiName

    switch (conservation) {
      case 0:
        apiName = 'container_vaccines'
        break
      case 1:
        apiName = 'fridge_vaccines'
        break
      default:
        break
    }
    Axios.get(
      `${api}/api/${apiName}?vaccinesBatch.vaccineArticle.speciality.id=${specialityId}&vaccinesBatch.vaccineArticle.dosesNumber[lte]=${volume}&vaccinesBatch.expirationDate[after]=${hatchingDate}&order[vaccinesBatch.expirationDate]=asc`,
      { headers: { Authorization: token } }
    ).then(res => {
      let totalUnits = 0
      let batches = res.data['hydra:member']
      const batchesToCheck = {}

      for (const i in batches) {
        if (Object.prototype.hasOwnProperty.call(batches, i)) {
          const quantity =
            batches[i].ampoulesNumber !== undefined
              ? batches[i].ampoulesNumber
              : batches[i].vialsNumber

          if (quantity > 0) {
            totalUnits += quantity * batches[i].vaccinesBatch.vaccineArticle.dosesNumber

            if (batchesToCheck[batches[i].vaccinesBatch.vaccineArticle.dosesNumber] === undefined) {
              batchesToCheck[batches[i].vaccinesBatch.vaccineArticle.dosesNumber] = []
            }

            batchesToCheck[batches[i].vaccinesBatch.vaccineArticle.dosesNumber].push(batches[i])
          }
        }
      }

      batches = []

      for (const doses in batchesToCheck) {
        if (Object.prototype.hasOwnProperty.call(batchesToCheck, doses)) {
          if (volume % doses !== 0) {
            delete batchesToCheck[doses]
          } else {
            let total = 0

            for (const batch of batchesToCheck[doses]) {
              total += batch.ampoulesNumber
                ? batch.ampoulesNumber * batch.vaccinesBatch.vaccineArticle.dosesNumber
                : batch.vialsNumber * batch.vaccinesBatch.vaccineArticle.dosesNumber
            }

            if (total < volume) {
              delete batchesToCheck[doses]
            } else {
              for (const batch of batchesToCheck[doses]) {
                batches.push(batch)
              }
            }
          }
        }
      }
      batches = batches.sort((a, b) =>
        moment(a.vaccinesBatch.expirationDate).isAfter(moment(b.vaccinesBatch.expirationDate))
          ? 1
          : -1
      )
      if (neededDoses <= totalUnits) {
        this.setState({ batches, neededDoses })
      } else {
        this.setState({ batches: [], neededDoses })
      }
    })
  }

  setSelectedBatches(selectedBatches) {
    const { result, setResult, vaccine } = this.props
    const { id, speciality } = vaccine
    const { conservationMethod: conservation } = speciality
    const { batches } = this.state

    selectedBatches.forEach(selectedBatch => {
      batches.forEach((batch, i) => {
        if (selectedBatch.batch !== undefined && batch.id === selectedBatch.batch.id) {
          batches.splice(i, 1)
        }
      })
    })

    switch (conservation) {
      case 0:
        if (result.containerVaccines === undefined) {
          result.containerVaccines = {}
        }

        result.containerVaccines[id] = selectedBatches
        break
      case 1:
        if (result.fridgeVaccines === undefined) {
          result.fridgeVaccines = {}
        }

        result.fridgeVaccines[id] = selectedBatches
        break
      default:
        break
    }

    setResult(result)
    this.setState({ selectedBatches, batches })
  }

  setNeededDoses(neededDoses) {
    this.setState({ neededDoses })
  }

  reload() {
    const { neededDoses, result, setResult, vaccine } = this.props
    const { id, speciality } = vaccine
    const { conservationMethod: conservation } = speciality

    switch (conservation) {
      case 0:
        result.containerVaccines[id] = [{}]
        break
      case 1:
        result.fridgeVaccines[id] = [{}]
        break
      default:
        break
    }

    this.setState({ selectedBatches: [{}] })
    setResult(result)
    this.setNeededDoses(neededDoses)
    this.setBatches()
  }

  render() {
    const { vaccine } = this.props
    const { conservationMethod: conservation } = vaccine.speciality
    const { batches, selectedBatches, neededDoses } = this.state

    return (
      <div className="row custom-card form">
        <div className="card-content">
          <BlockHeader
            dosesType={vaccine.doseType}
            administration={vaccine.speciality.administrationMethod}
            speciality={vaccine.speciality.name}
            valence={vaccine.speciality.valence}
            canReload={selectedBatches[0].batch !== undefined}
            reload={this.reload}
          />
          <div className="divider greyDivider" />
          {batches &&
            selectedBatches.map((selectedBatch, i) => (
              <VaccBatchSelector
                key={selectedBatch.id}
                batches={batches}
                conservation={conservation}
                administration={vaccine.speciality.administrationMethod}
                neededDoses={neededDoses}
                selectedBatches={selectedBatches}
                setNeededDoses={this.setNeededDoses}
                disabled={selectedBatch.disabled}
                i={i}
                setSelectedBatches={this.setSelectedBatches}
              />
            ))}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default connect(mapStateToProps)(VaccineBlock)
