/* eslint-disable prefer-destructuring */
import Axios from 'axios'
import M from 'materialize-css'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { catchError } from '../../../actions'

class CategoryFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleChange = this.handleChange.bind(this)
    this.customFilterLastControl = this.customFilterLastControl.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    M.FormSelect.init(document.querySelectorAll('select'))
  }

  componentDidUpdate() {
    M.FormSelect.init(document.querySelectorAll('select'))
  }

  customFilterLastControl() {
    const { url, token, inLoad } = this.props
    inLoad(true)

    return Axios.get(`${url}?active=true`, {
      headers: {
        Authorization: token
      }
    })
      .then(res => res.data['hydra:member'])
      .catch(err => catchError(err.response))
  }

  handleChange(e) {
    const { value, name } = e.target
    const { selectedCategory } = this.state
    const { setStateToGrandParent, setDataToParent, loadData, inLoad } = this.props

    setDataToParent('loading', true)
    setDataToParent('page', 1)
    inLoad(false)

    if (
      selectedCategory === 'order[lastControl.createdAt]' &&
      value !== 'desc' &&
      value !== 'asc'
    ) {
      const result = this.customFilterLastControl()
      const congruent = []
      const blocking = []
      result.forEach(el => {
        if (moment(el.lastControl.createdAt).format('L') === moment().format('L')) {
          congruent.push(el)
        } else {
          blocking.push(el)
        }
      })

      return setStateToGrandParent(value === 1 ? congruent : blocking)
    }
    return setDataToParent(name, value, loadData)
  }

  handleClick() {
    const { activeFilter, inLoad } = this.props

    activeFilter('sortBy', true)
    inLoad(false)
  }

  render() {
    const {
      filters,
      filterType,
      getSubCat,
      activeFilter,
      sortCategory,
      sortBy,
      intl,
      toTranslate,
      operator,
      translationPrefix
    } = this.props

    let translatedCategory = ''
    let translatedFilter = ''

    return (
      <>
        {filterType === 'sortCategory' || !filterType || filterType === 'sortBy' ? (
          <div className="input filtersSelect">
            <select
              className="select-wrapper"
              name="sortCategory"
              onChange={e => getSubCat(e)}
              id="sortCategory"
              defaultValue={sortCategory || 'select'}
            >
              <option value="select" disabled>
                {intl.formatMessage({ id: `app.sortBy` })}
              </option>
              {filters.map((item, i) => {
                translatedCategory =
                  item.catTranslationPrefix !== undefined
                    ? intl.formatMessage({
                        id: `app.filter.${item.catTranslationPrefix}.${item.category}`
                      })
                    : intl.formatMessage({ id: `app.filter.${item.category}` })
                return (
                  <option value={i} key={`${item.catTranslationPrefix}.${item.category}`}>
                    {translatedCategory}
                  </option>
                )
              })}
            </select>
            {filterType === 'sortCategory' || filterType === 'sortBy' ? (
              <select
                className="select-wrapper"
                name="sortBy"
                onChange={this.handleChange}
                defaultValue={sortBy || 'select'}
                id="sortBy"
              >
                <option value="select" disabled>
                  {intl.formatMessage({ id: 'app.label.selectFilter' })}
                </option>
                {filters &&
                  sortCategory !== 'select' &&
                  filters[parseInt(sortCategory)].sortBy.map(item => {
                    let value = item

                    if (toTranslate === false) {
                      translatedFilter = item

                      if (operator === true) {
                        value = item[0]
                        translatedFilter = item[1]
                      }
                    } else {
                      translatedFilter =
                        translationPrefix !== undefined
                          ? intl.formatMessage({
                              id: `app.filter.${translationPrefix}.${item}`
                            })
                          : intl.formatMessage({ id: `app.filter.${item}` })
                    }
                    return (
                      <option value={value} key={item} onClick={() => activeFilter('sortBy')}>
                        {translatedFilter}
                      </option>
                    )
                  })}
              </select>
            ) : (
              <div />
            )}
          </div>
        ) : (
          <>
            <i
              className="material-icons icon-filter-sortBy"
              onClick={this.handleClick}
              onKeyPress={this.handleClick}
              role="button"
              tabIndex="0"
            >
              sort
            </i>
          </>
        )}
      </>
    )
  }
}

export default injectIntl(CategoryFilter)
