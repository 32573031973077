import React from 'react'
import VaccineBatch from './VaccineBatch'
import StatePill from '../../StatePill'

const VaccineSpeciality = props => {
  const { intl, path, speciality, vaccines, specialityData } = props
  let totalDosesNumber = 0

  vaccines[speciality].forEach(vaccineBatch => {
    const { batch, vaccine } = vaccineBatch
    totalDosesNumber +=
      vaccine.vaccineArticle.dosesNumber *
      (undefined !== batch.fridge ? batch.vialsNumber : batch.ampoulesNumber)
  })

  return (
    <table className="table stockTable">
      <thead>
        <tr>
          <th colSpan="8">{speciality}</th>
          <th>
            <StatePill type="doses" data={totalDosesNumber} specialityData={specialityData} />
            &nbsp;{totalDosesNumber}&nbsp;doses
          </th>
        </tr>
        <tr>
          <th>{intl.formatMessage({ id: 'app.label.batchesNumber' })}</th>
          <th>{intl.formatMessage({ id: 'app.label.speciality' })}</th>
          <th>{intl.formatMessage({ id: 'app.label.administration' })}</th>
          <th>{intl.formatMessage({ id: 'app.label.article' })}</th>
          <th>{intl.formatMessage({ id: 'app.label.ampoulesNumber' })}</th>
          <th>{intl.formatMessage({ id: 'app.label.dosesNumber' })}</th>
          <th>{intl.formatMessage({ id: 'app.label.expirationDate' })}</th>
          <th>{intl.formatMessage({ id: 'app.label.equipmentNumber' })}</th>
          <th>{intl.formatMessage({ id: 'app.label.equipmentControl' })}</th>
        </tr>
      </thead>
      <tbody>
        {vaccines[speciality].map((vaccineBatch, i) => {
          const { batch, vaccine } = vaccineBatch
          const key = `batch-${i}`

          return batch.ampoulesNumber && batch.ampoulesNumber !== 0 ? (
            <VaccineBatch batch={batch} intl={intl} vaccine={vaccine} key={key} path={path} />
          ) : batch.vialsNumber && batch.vialsNumber !== 0 ? (
            <VaccineBatch batch={batch} intl={intl} vaccine={vaccine} key={key} path={path} />
          ) : (
            ''
          )
        })}
      </tbody>
    </table>
  )
}
export default VaccineSpeciality
