import React, { useContext } from 'react'
import { injectIntl } from 'react-intl'
import { GenerationsContext, ProductsForOrderContext } from '../../../context/contexts'

const ChickInformations = props => {
  const { intl, handleChange } = props
  const { generations } = useContext(GenerationsContext)
  const { productsForOrder } = useContext(ProductsForOrderContext)

  return (
    <div>
      <div className="row">
        <div className="col s12">
          <p className="greyTitle">{intl.formatMessage({ id: 'app.label.chickInformations' })}</p>
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <div className="input col s2">
            <label htmlFor="product" className="labelParams">
              {intl.formatMessage({ id: 'app.label.gender' })}*
            </label>
            <select
              className="select-wrapper"
              name="gender"
              defaultValue="0"
              onChange={handleChange}
              required
            >
              <option value="0" disabled>
                {intl.formatMessage({ id: 'app.label.selectGender' })}
              </option>
              {['M', 'F'].map(gender => (
                <option key={gender} value={gender}>
                  {gender}
                </option>
              ))}
            </select>
          </div>
          <div className="input col s3">
            <label htmlFor="product" className="labelParams">
              {intl.formatMessage({ id: 'app.label.product' })}*
            </label>
            <select
              className="select-wrapper"
              name="product"
              defaultValue="0"
              onChange={handleChange}
              required
            >
              <option value="0" disabled>
                {intl.formatMessage({ id: 'app.label.selectProduct' })}
              </option>
              {productsForOrder.map(productForOrder => (
                <option key={productForOrder.value} value={productForOrder.value}>
                  {productForOrder.displayName ? productForOrder.displayName : productForOrder.name}
                </option>
              ))}
            </select>
          </div>
          <div className="input col s3">
            <label htmlFor="generation" className="labelParams">
              {intl.formatMessage({ id: 'app.label.generation' })}*
            </label>
            <select
              className="select-wrapper"
              name="generation"
              defaultValue="0"
              onChange={handleChange}
              required
            >
              <option value="0" disabled>
                {intl.formatMessage({ id: 'app.label.selectGeneration' })}
              </option>
              {generations.map(generation => (
                <option key={generation.value} value={generation.value}>
                  {generation.displayName ? generation.displayName : generation.name}
                </option>
              ))}
            </select>
          </div>
          <div className="input col s4">
            <label htmlFor="totalChicks" className="labelParams">
              {intl.formatMessage({ id: 'app.label.totalChicks' })}*
            </label>
            <input name="totalChicks" type="number" onChange={handleChange} required />
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(ChickInformations)
