import Axios from 'axios'
import M from 'materialize-css'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { catchError } from '../../../../actions'
import { api } from '../../../../parameters'
import LoadingPage from '../../../LoadingPage'
import ValenceContext from '../../../../context/ValencesContext'

class VaccineForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      vaccineSelected: null,
      vaccineBatchSelected: null,
      ampoulesNumber: null,
      specialitySelected: null,
      specialities: [],
      vaccines: [],
      batches: [],
      vaccinesSelected: [],
      loading: true
    }
    this.ampoulesNumberRef = React.createRef()
    this.vaccineRef = React.createRef()
    this.batchRef = React.createRef()
    this.handleSelect = this.handleSelect.bind(this)
    this.getVaccinesSelected = this.getVaccinesSelected.bind(this)
    this.getApi = this.getApi.bind(this)
  }

  componentDidMount() {
    const { token } = this.props
    Axios.get(`${api}/api/vaccine_specialities`, { headers: { Authorization: token } })
      .then(res =>
        this.setState({ specialities: res.data['hydra:member'], loading: false }, () =>
          M.AutoInit()
        )
      )
      .catch(err => catchError(err.response))
    this.getVaccinesSelected()
  }

  componentDidUpdate(prevProps, prevState) {
    const { vaccines, setDisabled, setVaccineDisabled } = this.props
    const { specialitySelected, vaccineSelected, vaccineBatchSelected, ampoulesNumber } = this.state
    if (prevProps.vaccines !== vaccines) {
      this.getVaccinesSelected()
    }
    if (
      prevState.specialitySelected !== specialitySelected ||
      prevState.vaccineSelected !== vaccineSelected ||
      prevState.vaccineBatchSelected !== vaccineBatchSelected ||
      prevState.ampoulesNumber !== ampoulesNumber
    )
      if (
        specialitySelected !== null &&
        specialitySelected !== undefined &&
        vaccineSelected !== null &&
        vaccineSelected !== undefined &&
        vaccineBatchSelected !== null &&
        vaccineBatchSelected !== undefined &&
        ampoulesNumber !== null &&
        ampoulesNumber !== undefined &&
        ampoulesNumber !== ''
      ) {
        setDisabled(false)
        setVaccineDisabled(false)
      } else {
        setDisabled(true)
        setVaccineDisabled(true)
      }
  }

  getVaccinesSelected() {
    const { vaccines } = this.props
    const vaccinesSelected = []
    vaccines.forEach(vaccin => {
      if (vaccin.id !== undefined) vaccinesSelected.push(vaccin.id)
    })
    this.setState({ vaccinesSelected })
  }

  getApi(url) {
    const { token } = this.props
    try {
      return Axios.get(`${api + url}`, {
        headers: { Authorization: token }
      })
    } catch (err) {
      return catchError(err.response)
    }
  }

  async handleSelect(e) {
    const { name, value } = e.target
    const { donorId, itemsToMove, index, setDisabled } = this.props
    const { batchId } = this.state

    if (name) this.setState({ [name]: value })

    switch (name) {
      case 'specialitySelected': {
        this.setState({ vaccineSelected: null, vaccineBatchSelected: null, ampoulesNumber: null })
        setDisabled(true)
        const result = await this.getApi(
          `/api/vaccine_articles?speciality.id=${value}&vaccinesBatches.fridgeVaccinesBatches.fridge.id=${donorId}`
        )
        this.batchRef.current.value = 0
        this.vaccineRef.current.value = 0
        this.ampoulesNumberRef.current.value = ''
        this.setState(
          {
            vaccines: result.data['hydra:member'],
            batches: [],
            batchId: false,
            batchSelected: ''
          },
          () => {
            M.AutoInit()
          }
        )

        break
      }
      case 'vaccineSelected': {
        const result = await this.getApi(
          `/api/fridge_vaccines?vaccinesBatch.vaccineArticle.name=${value}&fridge.id=${donorId}`
        )
        this.setState({ batches: result.data['hydra:member'] }, () => M.AutoInit())
        break
      }
      case 'vaccineBatchSelected': {
        const result = await this.getApi(`/api/fridge_vaccines/${value}`)
        this.getVaccinesSelected()
        this.setState({ batchSelected: result.data, batchId: value }, () => M.AutoInit())
        break
      }
      case 'ampoulesNumber': {
        this.setState({ ampoulesNumber: value })
        itemsToMove('vaccines', index, e, batchId)
        break
      }
      default:
        break
    }
  }

  static contextType = ValenceContext

  render() {
    const { index, intl, disabledVaccinesForm } = this.props
    const { specialities, vaccines, batches, batchSelected, vaccinesSelected, loading } = this.state
    const { getValenceLabelByKey } = this.context
    if (loading) {
      return (
        <div className="row">
          <div className="col s12">
            <LoadingPage />
          </div>
        </div>
      )
    }
    return (
      <div className="row">
        <div className="col s12">
          <h5>
            {intl.formatMessage({ id: 'app.label.vaccin' })} n°{index + 1}
          </h5>
          <div className="row">
            <div className="col m6 input">
              <label htmlFor="specialitySelected">
                {intl.formatMessage({ id: 'app.label.speciality' })}
              </label>
              <select
                className="select-wrapper"
                defaultValue="0"
                name="specialitySelected"
                id="specialitySelected"
                onChange={this.handleSelect}
                disabled={disabledVaccinesForm[index]}
              >
                <option value="0" disabled />
                {specialities.map(speciality => (
                  <option key={speciality.id} value={speciality.id}>
                    {speciality.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col m3 input">
              <label htmlFor="vaccineSelected">
                {intl.formatMessage({ id: 'app.label.article' })}
              </label>
              <select
                className="select-wrapper"
                defaultValue="0"
                name="vaccineSelected"
                ref={this.vaccineRef}
                id="vaccineSelected"
                onChange={this.handleSelect}
                disabled={disabledVaccinesForm[index]}
              >
                <option value="0" disabled />
                {vaccines.map(vaccin => (
                  <option key={vaccin.name} value={vaccin.name}>
                    {vaccin.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col m3 input">
              <label htmlFor="vaccineBatchSelected">
                {intl.formatMessage({ id: 'app.label.batchesNumber' })}
              </label>
              <select
                className="select-wrapper"
                defaultValue="0"
                name="vaccineBatchSelected"
                ref={this.batchRef}
                id="vaccineBatchSelected"
                onChange={this.handleSelect}
                disabled={disabledVaccinesForm[index]}
              >
                <option value="0" disabled />
                {batches.map(
                  batch =>
                    vaccinesSelected.indexOf(batch.id) === -1 && (
                      <option key={batch.id} value={batch.id}>
                        {batch.vaccinesBatch.batchNumber}
                      </option>
                    )
                )}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col m3 input">
              <label htmlFor="Valence" className="active">
                {intl.formatMessage({ id: 'app.label.valence' })}
              </label>
              <input
                disabled
                value={
                  batchSelected
                    ? getValenceLabelByKey(
                        batchSelected.vaccinesBatch.vaccineArticle.speciality.valence
                      )
                    : ''
                }
                id="Valence"
                type="text"
              />
            </div>
            <div className="col m3 input">
              <label htmlFor="Valence" className="active">
                {intl.formatMessage({ id: 'app.label.expirationDate' })}
              </label>
              <input
                disabled
                value={
                  batchSelected
                    ? moment(batchSelected.vaccinesBatch.expirationDate).format('DD/MM/YYYY')
                    : ''
                }
                id="expiration"
                type="text"
              />
            </div>
            <div className="col m3 input">
              <label htmlFor="Valence" className="active">
                {intl.formatMessage({ id: 'app.label.bulbsNumber' })}
              </label>
              <input
                disabled
                value={batchSelected ? batchSelected.vialsNumber : ''}
                id="ampoulesNumber"
                type="text"
              />
            </div>
            <div className="col m3 input">
              <label htmlFor="ampoulesNumber" className="active">
                {intl.formatMessage({ id: 'app.label.bulbsMove' })}
              </label>
              <input
                id="ampoulesNumber"
                name="ampoulesNumber"
                ref={this.ampoulesNumberRef}
                type="number"
                onChange={this.handleSelect}
                disabled={disabledVaccinesForm[index]}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(VaccineForm)
