import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { catchError } from '../../../actions'
import icons from '../../../assets/icons/icons'
import NitrogenTransferBlock from '../../../components/EquipmentManagement/ContainerManagement/NitrogenTransfer/NitrogenTransferBlock'
import { closePage, validatedAction } from '../../../components/globalAction'
import { api } from '../../../parameters'

class NitrogenTransfer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      containerSelected: [],
      allContainers: [],
      nitrogenGiver: [
        {
          id: 0,
          newNitrogenLevel: 0,
          state: 1,
          status: 'giver'
        }
      ],
      nitrogenCatcher: [
        {
          id: 0,
          newNitrogenLevel: 0,
          state: 1,
          status: 'catcher'
        }
      ]
    }
    this.selectContainer = this.selectContainer.bind(this)
    this.addNitrogenLevel = this.addNitrogenLevel.bind(this)
    this.addContainer = this.addContainer.bind(this)
    this.getAllContainers = this.getAllContainers.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const { token } = this.props

    Axios.get(`${api}/api/nitrogen_containers`, {
      headers: { Authorization: token }
    })
      .then(res => this.setState({ allContainers: res.data['hydra:member'] }, () => M.AutoInit()))
      .catch(err => catchError(err.response))
  }

  getAllContainers() {
    const { nitrogenGiver, nitrogenCatcher } = this.state
    const data = nitrogenGiver.concat(nitrogenCatcher)
    const containerSelected = []
    data.forEach(el => {
      containerSelected.push(parseInt(el.id))
    })
    this.setState({ containerSelected })
  }

  selectContainer(index, type, e) {
    const { value } = e.target
    const { nitrogenGiver, nitrogenCatcher, allContainers } = this.state
    const newNitrogen = type === 'nitrogenGiver' ? nitrogenGiver : nitrogenCatcher
    newNitrogen[index].id = value
    allContainers.forEach(el => {
      if (el.id === parseInt(value)) {
        newNitrogen[index].nitrogenLevel = el.lastControl.nitrogenLevel
      }
    })
    this.setState({ [type]: newNitrogen }, () => M.AutoInit())
    this.getAllContainers()
  }

  addNitrogenLevel(index, type, e) {
    const { value } = e.target
    const { nitrogenGiver, nitrogenCatcher } = this.state
    const newNitrogen = type === 'nitrogenGiver' ? nitrogenGiver : nitrogenCatcher
    newNitrogen[index].newNitrogenLevel = value
    this.setState({ [type]: newNitrogen })
  }

  addContainer(type) {
    const { nitrogenGiver, nitrogenCatcher } = this.state
    const newContainer = type === 'nitrogenGiver' ? nitrogenGiver : nitrogenCatcher
    newContainer.push({
      id: 0,
      newNitrogenLevel: 0,
      state: 1,
      status: type === 'nitrogenGiver' ? 'giver' : 'catcher'
    })
    this.setState({ [type]: newContainer }, () => M.AutoInit())
  }

  handleSubmit() {
    const { nitrogenGiver, nitrogenCatcher } = this.state
    const { token } = this.props
    const data = nitrogenGiver.concat(nitrogenCatcher)
    Axios.post(`${api}/api/nitrogen_containers/transfer/nitrogen`, data, {
      headers: { Authorization: token }
    })
      .then(() => validatedAction('', '/equipments/containers'))
      .catch(err => catchError(err.response))
  }

  render() {
    const { nitrogenGiver, nitrogenCatcher, allContainers, containerSelected } = this.state
    const { intl } = this.props
    return (
      <div className="row Container containersManagement">
        <div className="pageHeader pageTitle">
          <div className="col m12">
            <h5>{intl.formatMessage({ id: 'app.part.nitrogenTransfer' })}</h5>
            <p>{intl.formatMessage({ id: 'app.part.nitrogenTransfer.text1' })}</p>
          </div>
          <div
            className="goBackBtn right"
            role="button"
            tabIndex="0"
            onKeyPress={() => closePage(intl)}
            onClick={() => closePage(intl)}
          >
            <i className="material-icons">close</i>
          </div>
        </div>
        <div className="redMsg">
          <img src={icons.redDanger} alt="red danger icon" />
          <p className="red-text">
            {intl.formatMessage({ id: 'app.part.nitrogenTransfer.text2' })}
          </p>
        </div>

        <div className="row">
          <div className="form">
            {nitrogenGiver.map((giver, i) => (
              <NitrogenTransferBlock
                key={giver.id}
                data={giver}
                type="nitrogenGiver"
                i={i}
                intl={intl}
                allContainers={allContainers}
                selectContainer={this.selectContainer}
                addNitrogenLevel={this.addNitrogenLevel}
                selectedContainer={containerSelected}
              />
            ))}
          </div>
        </div>
        <div className="row flex flex-center">
          <button
            type="button"
            className="btn transparentBtn"
            onClick={() => this.addContainer('nitrogenGiver')}
          >
            {intl.formatMessage({ id: 'app.label.addNitrogenGiver' })}
          </button>
        </div>
        <div className="row">
          <div className="form">
            {nitrogenCatcher.map((catcher, i) => (
              <NitrogenTransferBlock
                key={catcher.id}
                data={catcher}
                type="nitrogenCatcher"
                i={i}
                intl={intl}
                allContainers={allContainers}
                selectContainer={this.selectContainer}
                addNitrogenLevel={this.addNitrogenLevel}
                selectedContainer={containerSelected}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="flex flex-center">
            <button
              type="button"
              className="btn transparentBtn addBtn"
              onClick={() => this.addContainer('nitrogenCatcher')}
            >
              {intl.formatMessage({ id: 'app.label.addNitrogenCatcher' })}
            </button>
          </div>
        </div>
        <div className="flex flex-center">
          <button type="button" className="btn blueBtn validationBtn" onClick={this.handleSubmit}>
            Valider
          </button>
        </div>
      </div>
    )
  }
}

export default injectIntl(NitrogenTransfer)
