import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { catchError } from '../../../../actions'
import LastAlert from '../../../../components/Alerts/LastAlert'
import LoadingPage from '../../../../components/LoadingPage'
import StatePill from '../../../../components/StatePill'
import OutStockSubmitButton from '../../../../components/StockManagement/StockAvailibility/OutStock/OutStockSubmitButton'
import SwitchUnitField from '../../../../components/StockManagement/StockAvailibility/OutStock/SwitchUnitField'
import OutStockHeader from '../../../../components/StockManagement/StockAvailibility/OutStockHeader'
import { api } from '../../../../parameters'

class DyeOutStock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dye: [],
      loading: true,
      reason: null,
      showUnitsInput: true,
      toOutStock: 0,
      disabledSubBtn: true
    }

    this.handleReasonChange = this.handleReasonChange.bind(this)
    this.getStateFromChild = this.getStateFromChild.bind(this)
  }

  componentDidMount() {
    const { match, token } = this.props
    Axios.get(`${api}/api/fridge_dyes/${match.params.id}`, {
      headers: {
        Authorization: token
      }
    })
      .then(res => {
        this.setState({ dye: res.data, loading: false })
      })
      .catch(err => catchError(err.response))
  }

  componentDidUpdate(_prevProps, prevState) {
    const { showUnitsInput, toOutStock } = this.state

    if (prevState.showUnitsInput !== showUnitsInput || prevState.toOutStock !== toOutStock) {
      if (showUnitsInput === false || (parseInt(toOutStock) !== 0 && toOutStock !== '')) {
        this.setState({ disabledSubBtn: false })
      } else {
        this.setState({ disabledSubBtn: true })
      }
    }
  }

  getStateFromChild(toOutStock) {
    this.setState({ toOutStock })
  }

  handleReasonChange(e) {
    this.setState({ reason: e.target.value })
  }

  render() {
    const { dye, disabledSubBtn, loading, reason, showUnitsInput, toOutStock } = this.state
    const { intl, match, token } = this.props
    const { dyeBatch, fridge } = dye

    if (loading === true) {
      return <LoadingPage />
    }

    return (
      <div className="row Container stockAvailibilityItem">
        <div className="col s12 content">
          <OutStockHeader />
          <div className="speciality-description">
            <div className="bold">{}</div>
            <div>{}</div>
          </div>
          <div className="row cardContainer stockCard">
            <div className="col s12 card">
              <h6>{`${intl.formatMessage({ id: 'app.label.dye' })} ${dyeBatch.batchNumber}`}</h6>
              <div className="divider greyDivider" />
              <div className="row description">
                <div className="col s6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({ id: `app.label.provider` })}
                      {' : '}
                    </span>
                    {dyeBatch.provider}
                  </p>
                </div>
                <div className="col s6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({ id: `app.label.fridge` })}
                      {' : '}
                    </span>
                    {fridge.name}
                  </p>
                </div>
                <div className="col s6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({ id: `app.label.batchNumber` })}
                      {' : '}
                    </span>
                    {dyeBatch.batchNumber}
                  </p>
                </div>
                <div className="col s6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({ id: `app.label.instantTemp` })}
                      {' : '}
                    </span>
                    {`${fridge.lastControl.instantTemperature} ${intl.formatMessage({
                      id: 'app.unit.fridgeTemp'
                    })} `}
                    <StatePill type="FridgeInstantTemperature" data={dye.fridge} />
                  </p>
                </div>
                <div className="col s6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({ id: `app.label.color` })}
                      {' : '}
                    </span>
                    {dyeBatch.color}
                  </p>
                </div>
                <div className="col s6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({ id: `app.label.minTemp` })}
                      {' : '}
                    </span>
                    {`${fridge.lastControl.minTemperature} ${intl.formatMessage({
                      id: 'app.unit.fridgeTemp'
                    })} `}
                    <StatePill type="FridgeMinTemperature" data={dye.fridge} />
                  </p>
                </div>
                <div className="col s6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({ id: `app.label.vialsNumber` })}
                      {' : '}
                    </span>
                    {dye.vialsNumber}
                  </p>
                </div>
                <div className="col s6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({ id: `app.label.maxTemp` })}
                      {' : '}
                    </span>
                    {`${fridge.lastControl.maxTemperature} ${intl.formatMessage({
                      id: 'app.unit.fridgeTemp'
                    })} `}
                    <StatePill type="FridgeMaxTemperature" data={dye.fridge} />
                  </p>
                </div>
                <div className="col s6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({ id: `app.label.expirationDate` })}
                      {' : '}
                    </span>
                    {moment(dye.dyeBatch.expirationDate).format('DD/MM/YYYY')}
                    <StatePill type="ExpirationDate" data={dye.dyeBatch} />
                  </p>
                </div>
              </div>
              <div className="divider greyDivider" />
              <LastAlert equipmentId={dye.dyeBatch.batchNumber} cat={[5, 8]} />
              <div className="divider greyDivider" />
              <div className="form">
                <SwitchUnitField
                  show={showUnitsInput}
                  unitsNumber={dye.vialsNumber}
                  setStateToParent={this.getStateFromChild}
                />
              </div>
              <div className="divider greyDivider" />
              <div className="form">
                <div className="row">
                  <div className="col s12 input">
                    <label htmlFor="reason">
                      {intl.formatMessage({ id: 'app.label.outStockReason' })}
                    </label>
                    <textarea
                      id="reason"
                      className="materialize-textarea"
                      maxLength="300"
                      onChange={this.handleReasonChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <OutStockSubmitButton
            id={match.params.id}
            title={intl.formatMessage({ id: 'app.label.areYouSureDyeStock' })}
            message="app.label.outStockDyesSuccess"
            reason={reason}
            token={token}
            toOutStock={toOutStock}
            urlType="fridge_dyes"
            disabled={disabledSubBtn}
          />
        </div>
      </div>
    )
  }
}

export default injectIntl(DyeOutStock)
