import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { i18n } from '../../../../parameters'
import PlanningLastTd from '../PlanningLastTd'
import TdOrderNumber from '../TdOrderNumber'
import ProcessStateSpan from './ProcessStateSpan'
import TableDropDown from './TableDropDown'
import { TARGET_PAGE } from '../../../../actions/types'
import ColorPicker from '../../../ColorPicker'
import PlanningContext from '../../../../context/PlanningContext'
import icons from '../../../../assets/icons/icons'

class PlanningTableTbody extends React.Component {
  constructor() {
    super()
    this.state = {
      clicked: false
    }
    this.deployInformations = this.deployInformations.bind(this)
    this.redirected = this.redirected.bind(this)
    moment.locale('fr', { weekdaysMin: i18n.weekdaysAbbrev })
  }

  setHatchingDateColor(hatchingDate) {
    switch (moment(hatchingDate).day()) {
      case 1:
        return 'monday'
      case 2:
        return 'tuesday'
      case 3:
        return 'wednesday'
      case 4:
        return 'thursday'
      case 5:
        return 'friday'
      default:
        return ''
    }
  }

  setHex(hex) {
    const { group } = this.props
    const { updateColor } = this.context
    updateColor(group.id, hex)
  }

  static contextType = PlanningContext

  deployInformations(e) {
    const { id } = this.props
    const { clicked } = this.state

    const tr = document.getElementById(`tr${id}`)

    const trClassesList = tr.classList
    trClassesList.toggle('hidden')
    this.setState({ clicked: !clicked })
    e.preventDefault()
  }

  redirected() {
    const { group, history, vaccineType, targetPage } = this.props
    const { bigDropsOrder, injectionOrder, thinDropsOrder } = group
    targetPage(0)
    if (injectionOrder !== null && vaccineType === 0) {
      history.push(`/vaccination/realisation/injected/process/${injectionOrder.id}`)
    } else if (thinDropsOrder !== null && vaccineType === 1) {
      history.push(`/vaccination/realisation/sprayable-vaccine/${vaccineType}/${thinDropsOrder.id}`)
    } else {
      history.push(`/vaccination/realisation/sprayable-vaccine/${vaccineType}/${bigDropsOrder.id}`)
    }
  }

  isCheckable() {
    const { sprayableVaccine } = this.props

    if (sprayableVaccine) {
      return true
    }

    return false
  }

  isEditable() {
    const { injectableVaccine, isPreparation, isRealisation, sprayableVaccine } = this.props

    if (
      sprayableVaccine ||
      isPreparation ||
      injectableVaccine ||
      isRealisation ||
      (isRealisation === undefined && isPreparation === undefined)
    ) {
      return false
    }

    return true
  }

  isClickable() {
    const { group, isRealisation, vaccineType, isPreparation } = this.props
    const { injectionProcessState, thinDropsProcessState, bigDropsProcessState } = group
    let result = false

    if (
      isRealisation ||
      isPreparation ||
      (isRealisation === undefined && isPreparation === undefined)
    ) {
      result = true
    }
    if (
      (bigDropsProcessState === 4 && vaccineType === 2) ||
      (thinDropsProcessState === 4 && vaccineType === 1) ||
      (injectionProcessState === 4 && vaccineType === 0) ||
      bigDropsProcessState === 6 ||
      thinDropsProcessState === 6 ||
      injectionProcessState === 6
    ) {
      result = false
    }

    return result
  }

  render() {
    const {
      manageSelectedOrder,
      group,
      id,
      intl,
      isPreparation,
      isRealisation,
      role,
      vaccinesType,
      vaccineType,
      productsForOrderContext,
      prestationsContext,
      deleteOrders
    } = this.props
    const { clicked } = this.state
    const {
      number,
      product,
      prestations,
      farmer,
      clientName,
      hatchingDate,
      gender,
      totalChicks,
      vaccinesInjectable,
      vaccinesInjectableDosesType,
      vaccinesThinDrops,
      vaccinesThinDropsDosesType,
      vaccinesBigDrops,
      vaccinesBigDropsDosesType,
      injectionProcessState,
      thinDropsProcessState,
      bigDropsProcessState,
      color
    } = group
    const lastTd = true
    const checkbox = this.isCheckable()
    const isEditable = this.isEditable()
    const onClick = this.isClickable()
    const colorStyle = {
      style: { color: color || '#000' }
    }
    const handleClick = onClick
      ? isPreparation === false || isRealisation === true
        ? this.redirected
        : this.deployInformations
      : null
    const { deleteOrder } = this.context
    return (
      <tbody>
        <tr>
          <TdOrderNumber
            dayClass={this.setHatchingDateColor(hatchingDate)}
            checkbox={checkbox}
            manageSelectedOrder={manageSelectedOrder}
            group={group}
            isEditable={isEditable}
            isPreparation={isPreparation}
            isRealisation={isRealisation}
            onClick={onClick}
            redirected={this.redirected}
            id={id}
            role={role}
            number={number}
          />
          <td
            className={onClick ? 'pointer' : ''}
            onClick={handleClick}
            onKeyPress={handleClick}
            tabIndex={0}
            role="gridcell"
            {...colorStyle}
          >
            {moment(hatchingDate).format('dd DD/MM')}
          </td>
          <td
            className={onClick ? 'pointer' : ''}
            onClick={handleClick}
            onKeyPress={handleClick}
            tabIndex={0}
            role="gridcell"
          >
            {clientName}
          </td>
          {isPreparation && (
            <td
              className={onClick ? 'pointer' : ''}
              onClick={handleClick}
              onKeyPress={handleClick}
              tabIndex={0}
              role="gridcell"
            >
              {farmer}
            </td>
          )}
          <td
            className={onClick ? 'pointer' : ''}
            onClick={handleClick}
            onKeyPress={handleClick}
            tabIndex={0}
            role="gridcell"
            {...colorStyle}
          >
            {productsForOrderContext.getProductForOrderLabelByKey(product)}
          </td>
          <td
            className={onClick ? 'pointer' : ''}
            onClick={handleClick}
            onKeyPress={handleClick}
            tabIndex={0}
            role="gridcell"
            {...colorStyle}
          >
            {gender && <div className="noPadding">{gender}</div>}
          </td>
          <td
            className={onClick ? 'pointer' : ''}
            onClick={handleClick}
            onKeyPress={handleClick}
            tabIndex={0}
            role="gridcell"
            {...colorStyle}
          >
            {totalChicks && <div className="noPadding">{totalChicks}</div>}
          </td>
          {vaccinesType.includes(0) && (
            <td
              className={onClick ? 'pointer' : ''}
              onClick={handleClick}
              onKeyPress={handleClick}
              tabIndex={0}
              role="gridcell"
            >
              {injectionProcessState !== null && (
                <ProcessStateSpan processState={injectionProcessState} />
              )}
              <div className="flex">
                <div>
                  {vaccinesInjectable &&
                    vaccinesInjectable.map((vaccine, index) => (
                      <div key={index} className="noPadding" {...colorStyle}>
                        {`${vaccine} ${intl.formatMessage({
                          id: `app.label.doses.${vaccinesInjectableDosesType[index]}`
                        })}`}
                      </div>
                    ))}
                </div>
                {deleteOrders && injectionProcessState === 0 && (
                  <div
                    onClick={() => deleteOrder(group.injectionOrder.id, intl)}
                    role="button"
                    tabIndex="0"
                    onKeyPress={() => deleteOrder(group.injectionOrder.id, intl)}
                    className="pointer"
                  >
                    <img
                      src={icons.whiteDelete}
                      alt="delete orders"
                      style={{ marginLeft: '5px', filter: 'invert(1)' }}
                    />
                  </div>
                )}
              </div>
            </td>
          )}
          {vaccinesType.includes(1) && (
            <td
              className={onClick ? 'pointer' : ''}
              onClick={handleClick}
              onKeyPress={handleClick}
              tabIndex={0}
              role="gridcell"
            >
              <div className="flex">
                <div>
                  {thinDropsProcessState !== null && (
                    <>
                      <ProcessStateSpan processState={thinDropsProcessState} />
                    </>
                  )}
                  {vaccinesThinDrops &&
                    vaccinesThinDrops.map((vaccine, index) => (
                      <div key={index} className="noPadding" {...colorStyle}>
                        {`${vaccine} ${intl.formatMessage({
                          id: `app.label.doses.${vaccinesThinDropsDosesType[index]}`
                        })}`}
                      </div>
                    ))}
                </div>
                {deleteOrders && thinDropsProcessState === 0 && (
                  <div
                    onClick={() => deleteOrder(group.thinDropsOrder.id, intl)}
                    role="button"
                    tabIndex="0"
                    onKeyPress={() => deleteOrder(group.thinDropsOrder.id, intl)}
                    className="pointer"
                  >
                    <img
                      src={icons.whiteDelete}
                      alt="delete orders"
                      style={{ marginLeft: '5px', filter: 'invert(1)' }}
                    />
                  </div>
                )}
              </div>
            </td>
          )}
          {vaccinesType.includes(2) && (
            <td
              className={onClick ? 'pointer' : ''}
              onClick={handleClick}
              onKeyPress={handleClick}
              tabIndex={0}
              role="gridcell"
            >
              <div className="flex">
                <div>
                  {bigDropsProcessState !== null && (
                    <ProcessStateSpan processState={bigDropsProcessState} />
                  )}
                  {vaccinesBigDrops &&
                    vaccinesBigDrops.map((vaccine, index) => (
                      <div key={index} className="noPadding" {...colorStyle}>
                        {`${vaccine} ${intl.formatMessage({
                          id: `app.label.doses.${vaccinesBigDropsDosesType[index]}`
                        })}`}
                      </div>
                    ))}
                </div>
                {deleteOrders && bigDropsProcessState === 0 && (
                  <div
                    onClick={() => deleteOrder(group.bigDropsOrder.id, intl)}
                    role="button"
                    tabIndex="0"
                    onKeyPress={() => deleteOrder(group.bigDropsOrder.id, intl)}
                    className="pointer"
                  >
                    <img
                      src={icons.whiteDelete}
                      alt="delete orders"
                      style={{ marginLeft: '5px', filter: 'invert(1)' }}
                    />
                  </div>
                )}
              </div>
            </td>
          )}
          {!isPreparation && (
            <td
              className={onClick ? 'pointer' : ''}
              onClick={handleClick}
              onKeyPress={handleClick}
              tabIndex={0}
              role="gridcell"
            >
              {prestations &&
                prestations.map(prestation => (
                  <div key={prestation} className="noPadding col s12" {...colorStyle}>
                    {prestationsContext.getPrestationLabelByKey(prestation)}
                  </div>
                ))}
            </td>
          )}
          <td
            className={onClick && isPreparation ? 'pointer' : ''}
            onClick={
              onClick && isPreparation === false
                ? undefined
                : !onClick
                ? null
                : this.deployInformations ||
                  (isRealisation === undefined && isPreparation === undefined)
            }
            onKeyPress={
              onClick && isPreparation === false
                ? undefined
                : !onClick
                ? null
                : this.deployInformations ||
                  (isRealisation === undefined && isPreparation === undefined)
            }
            tabIndex={0}
            role="gridcell"
          >
            {lastTd && onClick && (
              <PlanningLastTd
                group={group}
                clicked={clicked}
                vaccineType={vaccineType}
                isRealisation={isRealisation}
                isPreparation={isPreparation}
              />
            )}
          </td>
          <td>
            {!group.injectionProcessState &&
              !group.thinDropsProcessState &&
              !group.bigDropsProcessState && (
                <ColorPicker hex={color || '#fff'} setHex={hex => this.setHex(hex)} />
              )}
          </td>
        </tr>
        <TableDropDown
          id={id}
          group={group}
          isPreparation={isPreparation}
          prestationsContext={prestationsContext}
        />
      </tbody>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

const mapDispatchToProps = dispatch => {
  return {
    targetPage: number => {
      dispatch({ type: TARGET_PAGE, number })
    }
  }
}

export default withRouter(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(PlanningTableTbody))
)
