import React from 'react'
import { injectIntl } from 'react-intl'

const ProgressBarReception = props => {
  const {
    progress,
    intl,
    part,
    dyePart,
    fridgePart,
    productBagPart,
    diluentPart,
    nitrogenPart
  } = props

  let progressList = (
    <>
      <div className={part === 1 ? 'col s4 blue-text' : 'col s4'}>
        {intl.formatMessage({
          id: fridgePart ? 'app.label.fridgeInformations' : 'app.label.containerInformation'
        })}
      </div>
      <div className={part === 2 ? 'col s4 blue-text' : 'col s4'}>
        {intl.formatMessage({ id: 'app.label.informationVaccine' })}
      </div>
      <div className={part === 3 ? 'col s4 blue-text' : 'col s4'}>
        {intl.formatMessage({ id: 'app.label.orderConformity' })}
      </div>
    </>
  )

  if (productBagPart) {
    progressList = (
      <>
        <div className={part === 1 ? 'col s4 blue-text' : 'col s4'}>
          {intl.formatMessage({ id: 'app.label.productBagInformations' })}
        </div>
        <div className={part === 2 ? 'col offset-s4 s3 blue-text' : 'col offset-s4 s3'}>
          {intl.formatMessage({ id: 'app.label.orderConformity' })}
        </div>
      </>
    )
  } else if (dyePart) {
    progressList = (
      <>
        <div className={part === 1 ? 'col s4 blue-text' : 'col s4'}>
          {intl.formatMessage({ id: 'app.label.fridgeInformations' })}
        </div>
        <div className={part === 2 ? 'col s4 blue-text' : 'col s4'}>
          {intl.formatMessage({ id: 'app.label.dyeInformations' })}
        </div>
        <div className={part === 3 ? 'col s4 blue-text' : 'col s4'}>
          {intl.formatMessage({ id: 'app.label.orderConformity' })}
        </div>
      </>
    )
  } else if (diluentPart) {
    progressList = (
      <>
        <div className={part === 1 ? 'col s4 blue-text' : 'col s4'}>
          {intl.formatMessage({ id: 'app.label.fridgeInformations' })}
        </div>
        <div className={part === 2 ? 'col s4 blue-text' : 'col s4'}>
          {intl.formatMessage({ id: 'app.label.diluentsInformations' })}
        </div>
        <div className={part === 3 ? 'col s4 blue-text' : 'col s4'}>
          {intl.formatMessage({ id: 'app.label.orderConformity' })}
        </div>
      </>
    )
  } else if (nitrogenPart) {
    progressList = (
      <>
        <div className={part === 1 ? 'col s4 blue-text' : 'col s4'}>
          {intl.formatMessage({ id: 'app.label.nitrogenLevel' })}
        </div>
        <div className={part === 2 ? 'col s4 blue-text' : 'col s4'}>
          {intl.formatMessage({ id: 'app.label.informationVaccine' })}
        </div>
        <div className={part === 3 ? 'col s4 blue-text' : 'col s4'}>
          {intl.formatMessage({ id: 'app.label.orderConformity' })}
        </div>
      </>
    )
  }

  return (
    <div className="row">
      <div className="col s12">
        <div className="progress">
          <div className="determinate" style={{ width: `${progress}%` }} />
        </div>
        <div className="row">{progressList}</div>
      </div>
    </div>
  )
}

export default injectIntl(ProgressBarReception)
