import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Swal from 'sweetalert2'
import { connect } from 'react-redux'
import { api } from '../../../../parameters'
import { catchError } from '../../../../actions'
import CenterButton from '../../../../components/CenterButton'
import LoadingPage from '../../../../components/LoadingPage'
import ProgressBar from '../../../../components/ProgressBar'
import ProductBagsPart from '../../../../components/Vaccination/Realisation/Process/InjectableVaccinePart/ProductBagsPart/ProductBagsPart'
import ContainerVaccineTag from '../../../../components/Vaccination/Realisation/Process/partials/ContainerVaccineTag/ContainerVaccineTag'
import VaccineScan from '../../../../components/Vaccination/Realisation/Process/partials/VaccineScan/VaccineScan'
import WaterBathControl from '../../../../components/Vaccination/Realisation/Process/partials/WaterBathControl'
import { NEXT_PAGE, TARGET_PAGE } from '../../../../actions/types'

class RealisationProcess extends React.Component {
  constructor(props) {
    super()
    this.state = {
      vaccineType: parseInt(props.match.params.vaccineType),
      loading: true,
      disabledWaterBathPart: true
    }
    this.changeActualPart = this.changeActualPart.bind(this)
    this.changePagePart = this.changePagePart.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
    this.getInjectedContent = this.getInjectedContent.bind(this)
    this.getSprayContent = this.getSprayContent.bind(this)
    this.initPrep = this.initPrep.bind(this)
    this.getContent = this.getContent.bind(this)
  }

  componentDidMount() {
    const { match, token } = this.props
    const { id } = match.params
    Axios.get(`${api}/api/planning_orders/${id}`, { headers: { Authorization: token } })
      .then(res => {
        this.setState({ order: res.data })

        Axios.get(`${api}/api/equipment_histories?type=14&orderDate=desc&itemsPerPage=1`, {
          headers: { Authorization: token }
        })
          .then(result => {
            if (result.data['hydra:member'][0] !== undefined) {
              this.setState({
                data: result.data['hydra:member'][0],
                lastControl: result.data['hydra:member'][0].createdAt
              })
            }
            this.initPrep()
          })
          .catch(err => catchError(err.response))
      })
      .catch(err => catchError(err.response))
  }

  getInjectedContent() {
    const {
      actualPart,
      order,
      preparation,
      partsTotal,
      prepId,
      previousPreparation,
      water,
      lastControl,
      data
    } = this.state
    const { match, pagePart } = this.props
    const { id } = match.params

    switch (pagePart) {
      case 0:
        return moment(lastControl).isBefore(moment(), 'days') === true ||
          lastControl === undefined ||
          data === undefined ||
          (data !== undefined && data.history.action.state !== 1) ? (
          <WaterBathControl
            lastControl={lastControl}
            data={data}
            getDataFromChild={this.getDataFromChild}
          />
        ) : (
          this.changePagePart()
        )
      case 1:
        return (
          <ProductBagsPart
            actualPart={actualPart}
            partsTotal={partsTotal}
            previousPreparation={previousPreparation}
            preparation={preparation}
            prepId={prepId}
            changePagePart={this.changePagePart}
          />
        )
      case 2:
        return (
          <VaccineScan
            actualPart={actualPart}
            id={id}
            prepId={prepId}
            vaccineType={0}
            preparation={preparation}
            changePagePart={this.changePagePart}
          />
        )
      default:
        return (
          <ContainerVaccineTag
            sequence={actualPart}
            changePagePart={this.changePagePart}
            id={id}
            prepId={prepId}
            order={order}
            preparation={preparation}
            water={water}
            changePrep={this.initPrep}
          />
        )
    }
  }

  getSprayContent() {
    const { actualPart, order, preparation, prepId, vaccineType, water } = this.state
    const { match, pagePart } = this.props
    const { id } = match.params

    switch (pagePart) {
      case 0:
        return this.changePagePart(false)
      case 1:
        return (
          <VaccineScan
            preparation={preparation}
            vaccineType={vaccineType}
            changePagePart={this.changePagePart}
            getDataFromChild={this.getDataFromChild}
            prepId={prepId}
            id={id}
            water={water}
          />
        )
      case 2:
        return (
          <ContainerVaccineTag
            sequence={actualPart}
            id={id}
            changePagePart={this.changePagePart}
            prepId={prepId}
            order={order}
            preparation={preparation}
            vaccineType={vaccineType}
            changePrep={this.initPrep}
            water={water}
          />
        )
      default:
        return null
    }
  }

  getContent() {
    const { vaccineType } = this.state

    switch (vaccineType) {
      case 1:
      case 2:
        return this.getSprayContent()
      default:
        return this.getInjectedContent()
    }
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  initPrep() {
    const { order, prevI, vaccineType } = this.state
    const { history, intl, token, targetPage, pagePart } = this.props
    let prepId
    let previousPrepId

    this.setState({ loading: true })

    if (order.orderOrderPreparations.length === 0) {
      return Swal.fire({
        type: 'info',
        confirmButtonText: intl.formatMessage({ id: 'app.label.ok' }),
        text: intl.formatMessage({ id: 'app.message.noPreparationsForOrder' }),
        customClass: {
          icon: 'swalInfoIcon',
          title: 'swalTitle',
          content: 'swalContentText',
          confirmButton: 'blueBtn',
          cancelButton: 'redBtn'
        }
      }).then(() => history.goBack())
    }

    if (order.orderOrderPreparations.length === prevI + 1) {
      if (vaccineType === 1) {
        return history.push('/vaccination/realisation/sprayable-vaccine/1')
      }
      if (vaccineType === 2) {
        return history.push('/vaccination/realisation/sprayable-vaccine/2')
      }
      return history.push('/vaccination/realisation/injected')
    }

    let realisedCount = 0

    for (
      let i = prevI === undefined ? 0 : prevI + 1;
      i < order.orderOrderPreparations.length;
      ++i
    ) {
      const prep = order.orderOrderPreparations[i]

      if (prep.preparation.state === 0) {
        prepId = prep.id

        if (i !== 0) {
          previousPrepId = order.orderOrderPreparations[i - 1].id
        }
        targetPage(1)
        this.setState({ prepId, actualPart: i + 1, prevI: i })
        break
      }

      if (prep.preparation.state >= 1) {
        realisedCount += 1
      }
    }

    if (realisedCount === order.orderOrderPreparations.length) {
      return Swal.fire({
        type: 'info',
        confirmButtonText: intl.formatMessage({ id: 'app.label.ok' }),
        text: intl.formatMessage({ id: 'app.message.allPrepsRealisedForOrder' }),
        customClass: {
          icon: 'swalInfoIcon',
          title: 'swalTitle',
          content: 'swalContentText',
          confirmButton: 'blueBtn',
          cancelButton: 'redBtn'
        }
      }).then(() => history.goBack())
    }

    Axios.get(`${api}/api/order_order_preparations/${prepId}`, {
      headers: { Authorization: token }
    })
      .then(result => {
        if (previousPrepId !== undefined) {
          Axios.get(`${api}/api/order_order_preparations/${previousPrepId}`, {
            headers: { Authorization: token }
          })
            .then(result2 => {
              this.setState({ previousPreparation: result2.data })
            })
            .catch(err => catchError(err))
        }
        this.setState({
          preparation: result.data,
          loading: false,
          partsTotal: result.data.planningOrder.orderOrderPreparations.length
        })
        if (pagePart !== 2) {
          targetPage(0)
        }
      })
      .catch(err => catchError(err.response))

    return null
  }

  changeActualPart() {
    let { actualPart } = this.state

    actualPart += 1
    this.setState({ actualPart })
  }

  changePagePart() {
    const { token, nextPage, targetPage, pagePart } = this.props
    const { data, temperature, vaccineType, lastControl } = this.state

    const dataToSend = { temperature }
    let updatePagePart = 0
    if (
      pagePart === 0 &&
      Number.isNaN(vaccineType) &&
      (moment(lastControl).isBefore(moment(), 'days') === true ||
        lastControl === undefined ||
        data === undefined ||
        (data !== undefined && data.history.action.state !== 1))
    ) {
      Axios.post(`${api}/api/control/bath_temperature`, dataToSend, {
        headers: { Authorization: token }
      })
        .then(() => {
          Axios.get(`${api}/api/equipment_histories?type=14&orderDate=desc&itemsPerPage=1`, {
            headers: { Authorization: token }
          })
            .then(result => {
              if (result.data['hydra:member'][0] !== undefined) {
                this.setState({
                  data: result.data['hydra:member'][0],
                  lastControl: result.data['hydra:member'][0].createdAt
                })
              }
              nextPage()
            })
            .catch(err => catchError(err.response))
        })
        .catch(err => catchError(err.response))
    } else {
      if (pagePart === 3 && vaccineType === undefined) {
        updatePagePart = 1
        this.changeActualPart()
      } else if (pagePart === 2 && (vaccineType === 1 || vaccineType === 2)) {
        updatePagePart = 1
        this.changeActualPart()
      } else {
        updatePagePart = pagePart + 1
      }
      targetPage(updatePagePart)
    }
  }

  render() {
    const { actualPart, disabledWaterBathPart, loading, partsTotal, vaccineType } = this.state
    const { intl, pagePart } = this.props
    if (loading) {
      return <LoadingPage />
    }
    const content = this.getContent()

    return (
      <div className="row Container stockAvailibilityItem">
        {pagePart > 0 && (
          <div className="row">
            <div className="col s12">
              <ProgressBar
                vaccineType={vaccineType}
                actualPart={actualPart}
                partsTotal={partsTotal}
                onlyOneLabel
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col s12">
            {content}
            {pagePart < 1 && (
              <CenterButton
                text={intl.formatMessage({ id: 'app.button.validate' })}
                onClick={this.changePagePart}
                disabled={disabledWaterBathPart}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    pagePart: state.realisation.pagePart
  }
}

const mapDispatchToProps = dispatch => {
  return {
    nextPage: () => dispatch({ type: NEXT_PAGE }),
    targetPage: number => {
      dispatch({ type: TARGET_PAGE, number })
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(RealisationProcess)))
