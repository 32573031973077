import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Swal from 'sweetalert2'
import { catchError } from '../../../../../actions'
import { api, containerProviders } from '../../../../../parameters'
import CenterButton from '../../../../CenterButton'
import LoadingPage from '../../../../LoadingPage'

class ReceivedVaccineNitrogen extends React.Component {
  constructor() {
    super()
    this.state = {
      capacities: [],
      firstSelectStatus: ['red', 'grey'],
      secondSelectStatus: ['red', 'grey'],
      disabledValidationBtn: true,
      badContainer: false,
      containerNumber: null,
      loading: true,
      containers: [],
      formData: {
        containerState: true,
        containerDesinfected: true,
        boxState: true
      }
    }
    this.verifyContainer = this.verifyContainer.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.popAlert = this.popAlert.bind(this)
    this.selectProvider = this.selectProvider.bind(this)
    this.verifyCheckedValue = this.verifyCheckedValue.bind(this)
    this.getOut = this.getOut.bind(this)
  }

  componentDidMount() {
    this.getAllContainers()
    M.AutoInit()
  }

  componentDidUpdate(prevProps, prevState) {
    const { formData } = this.state
    const {
      boxState,
      containerDesinfected,
      firstLight,
      secondLight,
      provider,
      capacity,
      containerNumber,
      containerState
    } = formData

    if (prevState.formData !== formData) {
      if (
        provider !== undefined &&
        capacity !== undefined &&
        containerNumber !== '' &&
        containerNumber !== undefined &&
        boxState === true &&
        firstLight !== undefined &&
        secondLight !== undefined &&
        containerDesinfected === true &&
        containerState === true
      ) {
        this.setState({ disabledValidationBtn: false })
      } else {
        this.setState({ disabledValidationBtn: true })
      }

      M.AutoInit()
    }
  }

  getAllContainers() {
    const containers = []
    const { token } = this.props
    Axios.get(`${api}/api/nitrogen_containers?active=true`, { headers: { Authorization: token } })
      .then(res => {
        res.data['hydra:member'].forEach(container => {
          containers.push(container.containerNumber)
        })
        this.setState({ containers, loading: false })
      })
      .catch(err => catchError(err.response))
  }

  getOut(e) {
    const { value, name, checked } = e.target
    const { history, intl } = this.props
    if (!checked) {
      this.setState({ [name]: checked }, () => {
        Swal.fire({
          type: 'error',
          title: intl.formatMessage({ id: 'app.part.frozenVaccinReceipt' }),
          text: intl.formatMessage({ id: 'app.alert.tooDeteriorated' }),
          customClass: {
            icon: 'swalErrorIcon',
            title: 'swalTitle',
            content: 'swalContentText',
            confirmButton: 'blueBtn'
          }
        }).then(() => history.goBack())
      })
    }
    this.setState({ [name]: value })
  }

  verifyCheckedValue(target) {
    const { name, checked } = target

    if (name === 'containerDesinfected') {
      this.setState({ [name]: checked })

      if (!checked) {
        this.setState({ [name]: checked })
        this.popAlert('app.alert.containerNotDesinfected')
      }
    }

    if (name === 'boxState') {
      this.setState({ [name]: checked })
      this.popAlert('app.alert.tooDeteriorated')
    }

    return true
  }

  handleChange(e) {
    const { setDataToParent } = this.props
    let { formData } = this.state
    const { type, name, checked, value } = e.target

    if (type === 'checkbox') {
      if (this.verifyCheckedValue(e.target)) {
        formData = { ...formData, [name]: checked }
      }
    } else if (name === 'capacity') {
      if (type === 'select-one') {
        formData = { ...formData, [name]: value }
      }

      if (value.length < 3) {
        formData = { ...formData, [name]: value }
      }
    } else {
      formData = { ...formData, [name]: value }
    }

    this.setState({ formData }, () => setDataToParent('container', formData))
  }

  popAlert(text) {
    const { intl } = this.props

    Swal.fire({
      type: 'error',
      title: intl.formatMessage({ id: 'app.alert.error' }),
      text: intl.formatMessage({ id: text }),
      customClass: {
        icon: 'swalErrorIcon',
        title: 'swalTitle',
        content: 'swalContentText',
        confirmButton: 'blueBtn'
      }
    })
  }

  selectProvider(e) {
    const { formData } = this.state
    const { setDataToParent } = this.props
    const { value } = e.target
    const selectedProvider = containerProviders[value]
    const capacities = selectedProvider.capacity
    const provider = selectedProvider.name

    this.setState({ formData: { ...formData, provider }, capacities, selectedProvider }, () =>
      setDataToParent('container', formData)
    )
  }

  verifyContainer(change, e) {
    const { value } = e.target
    let { containers } = this.state

    if (value.length < 5) {
      containers = containers.map(String)

      if (containers.includes(value)) {
        return this.setState({ badContainer: true })
      }

      this.setState({ badContainer: false, containerNumber: value })

      return change(e)
    }

    return true
  }

  render() {
    const { intl, changePart } = this.props
    const {
      capacities,
      firstSelectStatus,
      secondSelectStatus,
      disabledValidationBtn,
      badContainer,
      containerNumber,
      selectedProvider,
      loading,
      formData
    } = this.state

    return (
      <form autoComplete="off">
        <div className="row">
          <div className="col s12">
            <div className="form frozenVaccine">
              <div className="row">
                <div className="row col s12">
                  <div className="input col s6">
                    <label htmlFor="provider" className="label">
                      {intl.formatMessage({ id: 'app.label.provider' })}
                    </label>
                    <select
                      className="select-wrapper"
                      onChange={this.selectProvider}
                      defaultValue="0"
                      name="provider"
                    >
                      <option value="0" disabled />
                      {containerProviders.map((provider, i) => (
                        <option key={provider.name} value={i}>
                          {provider.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col s6">
                    {capacities && (
                      <div className="row">
                        <div className="col s12">
                          <div className="input col s6">
                            <label htmlFor="capacity" className="label">
                              {intl.formatMessage({ id: 'app.label.capacity' })}
                            </label>
                            <select
                              className="select-wrapper"
                              onChange={this.handleChange}
                              defaultValue="0"
                              name="capacity"
                              disabled={!selectedProvider}
                            >
                              <option value="0" disabled />
                              {capacities.map(capacity => (
                                <option key={capacity} value={capacity}>
                                  {capacity}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row col s12">
                  <div className="input col s6">
                    <label className="label" htmlFor="containerNumber">
                      {intl.formatMessage({ id: 'app.equipment.containerNumber' })}
                    </label>
                    <input
                      id="containerNumber"
                      type="number"
                      name="containerNumber"
                      maxLength="4"
                      className={badContainer ? 'invalided' : ''}
                      value={containerNumber || ''}
                      onChange={e => this.verifyContainer(this.handleChange, e)}
                    />
                    {badContainer && (
                      <span className="helper-text red-text">
                        {intl.formatMessage({ id: 'app.label.containerNumberAlreadyUsed' })}
                      </span>
                    )}
                  </div>
                  <div className="input  col s6">
                    <p className="labelCustom">
                      {intl.formatMessage({ id: 'app.label.generalBoxStatus' })}
                    </p>
                    <div className="switch">
                      <label>
                        {intl.formatMessage({ id: 'app.label.conformity.false' })}
                        <input
                          type="checkbox"
                          name="boxState"
                          onChange={this.getOut}
                          checked={formData.boxState}
                        />
                        <span className="lever" />
                        {intl.formatMessage({ id: 'app.label.conformity.true' })}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col s12">
                  <div className="input col s6">
                    <p className="labelCustom">
                      {intl.formatMessage({ id: 'app.label.colorStatusInversion' })}
                    </p>
                    <div className="row">
                      <div className="input col s6">
                        <select
                          className="select-wrapper"
                          onChange={this.handleChange}
                          defaultValue="0"
                          name="firstLight"
                        >
                          <option value="0" disabled />
                          {firstSelectStatus.map(status => (
                            <option key={status} value={status}>
                              {intl.formatMessage({ id: `app.color.${status}` })}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="input col s6">
                        <select
                          className="select-wrapper"
                          onChange={this.handleChange}
                          defaultValue="0"
                          name="secondLight"
                        >
                          <option value="0" disabled />
                          {secondSelectStatus.map(status => (
                            <option key={status} value={status}>
                              {intl.formatMessage({ id: `app.color.${status}` })}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="input  col s6">
                    <p className="labelCustom">
                      {intl.formatMessage({ id: 'app.label.generalState' })}
                    </p>
                    <div className="switch">
                      <label>
                        {intl.formatMessage({ id: 'app.label.conformity.false' })}
                        <input
                          type="checkbox"
                          name="containerState"
                          onChange={this.getOut}
                          checked={formData.containerState}
                        />
                        <span className="lever" />
                        {intl.formatMessage({ id: 'app.label.conformity.true' })}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row col s12">
                  <div className="input  col s6">
                    <p className="labelCustom">
                      {intl.formatMessage({ id: 'app.label.containerDesinfected' })}
                    </p>
                    <div className="switch">
                      <label>
                        {intl.formatMessage({ id: 'app.label.no' })}
                        <input
                          type="checkbox"
                          name="containerDesinfected"
                          onChange={this.handleChange}
                          checked={formData.containerDesinfected}
                        />
                        <span className="lever" />
                        {intl.formatMessage({ id: 'app.label.yes' })}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row col s12">
                  <div className="row col s12 divider" />
                </div>
                <div className="row col s12">
                  <div className="input col s12">
                    <label className="label" htmlFor="commentary">
                      {intl.formatMessage({ id: 'app.label.commentary' })}
                    </label>
                    <textarea
                      id="commentary"
                      className="materialize-textarea"
                      name="commentary"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CenterButton
          text={intl.formatMessage({ id: 'app.button.validate' })}
          onClick={changePart}
          disabled={disabledValidationBtn || badContainer}
        />
        {loading && <LoadingPage />}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(ReceivedVaccineNitrogen)))
