import React from 'react'
import { injectIntl } from 'react-intl'

const CongruentVaccinationPart = props => {
  const {
    intl,
    vaccinationParamId,
    congruentPartBlockValueMax,
    congruentPartBlockValueMin,
    congruentPartNoBlockValueMaxPercent,
    congruentPartNoBlockValueMaxValue,
    congruentPartNoBlockValueMin,
    handleChange
  } = props

  switch (vaccinationParamId) {
    case 3:
    case 4:
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="input col s6">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.ifTempLower' })}
                  &nbsp;
                </label>
                <input
                  name="congruentPartNoBlockValueMin"
                  type="number"
                  step="0.1"
                  value={congruentPartNoBlockValueMin}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    case 6:
    case 7:
      return (
        <div className="row">
          <div className="col s12 input">
            <div className="row">
              <div className="col s12">
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.ifQuantityVaccineUsebetween' })}
                      &nbsp;
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s6">
                    <input
                      className="col s3"
                      name="congruentPartBlockValueMin"
                      type="number"
                      step="0.1"
                      value={congruentPartBlockValueMin}
                      onChange={handleChange}
                    />
                    <span className="inputPercent labelCustom col s1">
                      {intl.formatMessage({ id: 'app.unit.percent' })}
                    </span>
                  </div>
                  <div className="col s6">
                    <input
                      className="col s3"
                      name="congruentPartBlockValueMax"
                      type="number"
                      step="0.1"
                      value={congruentPartBlockValueMax}
                      onChange={handleChange}
                    />
                    <span className="inputPercent labelCustom col s1">
                      {intl.formatMessage({ id: 'app.unit.percent' })}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="divider greyDivider" />
          </div>
        </div>
      )
    case 8:
      return (
        <div className="row">
          <div className="col s12 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.percentOfChickVaccinated' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col s3">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.percentOfChickMustBe' })}
                  &nbsp;
                </label>
                <input
                  name="congruentPartNoBlockValueMin"
                  type="number"
                  step="0.1"
                  value={congruentPartNoBlockValueMin}
                  onChange={handleChange}
                />
              </div>
              <div className="input col s3">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.andMaj' })}
                  &nbsp;
                </label>
                <input
                  name="congruentPartNoBlockValueMaxPercent"
                  type="number"
                  step="0.1"
                  value={congruentPartNoBlockValueMaxPercent}
                  onChange={handleChange}
                />
              </div>
              <div className="input col s6">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.NbChickOrderedMax' })}
                  &nbsp;
                </label>
                <input
                  name="congruentPartNoBlockValueMaxValue"
                  type="number"
                  step="0.1"
                  value={congruentPartNoBlockValueMaxValue}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    default:
      return null
  }
}

export default injectIntl(CongruentVaccinationPart)
