import React from 'react'
import { injectIntl } from 'react-intl'

class ContainerInformationsForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      availableContainerNumber: null,
      nitrogenLevelControl: null,
      nitrogenLastControl: null
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const { setDisabled } = this.props
    setDisabled(true)
  }

  componentDidUpdate() {
    const { setDisabled } = this.props
    const { availableContainerNumber, nitrogenLevelControl } = this.state
    if (
      availableContainerNumber !== null &&
      availableContainerNumber !== undefined &&
      nitrogenLevelControl !== null &&
      nitrogenLevelControl !== undefined &&
      nitrogenLevelControl !== ''
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  handleChange(e) {
    const {
      containers,
      receiverContainers,
      index,
      setDataToParent,
      setContainersSelected
    } = this.props
    const { nitrogenLevelControl, availableContainerNumber } = this.state
    const { value, name } = e.target

    if (name === `availableContainerNumber${index}`) {
      this.setState({ availableContainerNumber: value })
      setContainersSelected(value, index)

      receiverContainers.forEach(container => {
        if (container.id === parseInt(value)) {
          const selectedContainerTmp = {
            containerNumber: container.containerNumber,
            id: container.id,
            control: {
              nitrogenLevel: container.lastControl.nitrogenLevel,
              nitrogenLevelState: 1,
              nitrogenLevelControl
            }
          }
          containers[index] = selectedContainerTmp
          this.setState(
            {
              nitrogenLastControl: container.lastControl.nitrogenLevel
            },
            () => setDataToParent('containers', selectedContainerTmp)
          )
        }
      })

      if (
        nitrogenLevelControl !== null &&
        nitrogenLevelControl !== undefined &&
        nitrogenLevelControl !== ''
      ) {
        receiverContainers.forEach(container => {
          if (container.id === parseInt(value)) {
            const selectedContainerTmp = {
              containerNumber: container.containerNumber,
              id: container.id,
              control: {
                nitrogenLevel: container.lastControl.nitrogenLevel,
                nitrogenLevelState: 1,
                nitrogenLevelControl
              }
            }
            containers[index] = selectedContainerTmp
            this.setState(
              {
                nitrogenLastControl: container.lastControl.nitrogenLevel
              },
              () => setDataToParent('containers', selectedContainerTmp)
            )
          }
        })
      }
    }
    if (name === `nitrogenLevelControl${index}`) {
      this.setState({ nitrogenLevelControl: value })

      if (availableContainerNumber !== null && availableContainerNumber !== undefined) {
        receiverContainers.forEach(container => {
          if (container.id === parseInt(availableContainerNumber)) {
            const selectedContainerTmp = {
              containerNumber: container.containerNumber,
              id: container.id,
              control: {
                nitrogenLevel: value,
                nitrogenLevelState: 1
              }
            }
            containers[index] = selectedContainerTmp
            setDataToParent('containers', selectedContainerTmp)
          }
        })
      }
    }
  }

  render() {
    const { intl, receiverContainers, index, disabledForm, containersSelected } = this.props
    const { nitrogenLastControl } = this.state
    return (
      <div className="row">
        <div className="col s12">
          <div className="form frozenVaccine">
            <div className="row">
              <div className="col s12">
                <div className="col  s4 input">
                  <label htmlFor={`availableContainerNumber${index}`}>
                    {intl.formatMessage({ id: 'app.label.availableContainerNumber' })}
                  </label>
                  <select
                    className="select-wrapper"
                    defaultValue="0"
                    name={`availableContainerNumber${index}`}
                    onChange={this.handleChange}
                    disabled={disabledForm[index]}
                  >
                    <option value="0" disabled>
                      {intl.formatMessage({ id: 'app.label.selectContainer' })}
                    </option>
                    {receiverContainers.map(container => (
                      <option
                        value={container.id}
                        key={container.id}
                        disabled={!(containersSelected.indexOf(container.id) === -1)}
                      >
                        {container.containerNumber}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col s4 input">
                  <label>{intl.formatMessage({ id: 'app.label.nitrogenLevel' })}</label>
                  <input
                    type="text"
                    name={`nitrogenLevel${index}`}
                    value={nitrogenLastControl || ''}
                    disabled
                  />
                </div>
                <div className="col s4 input">
                  <label>{intl.formatMessage({ id: 'app.equipment.control.nitrogenLevel' })}</label>
                  <input
                    type="number"
                    name={`nitrogenLevelControl${index}`}
                    onChange={this.handleChange}
                    disabled={disabledForm[index]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(ContainerInformationsForm)
