import React from 'react'
import { injectIntl } from 'react-intl'

class Tr extends React.Component {
  setMelt(order) {
    const { intl } = this.props
    const result = []

    if (order === null) {
      return ''
    }

    order.vaccines.forEach(vaccine => {
      result.push(
        `${vaccine.speciality.name} ${intl.formatMessage({
          id: `app.label.doses.${vaccine.doseType}`
        })}`
      )
    })

    return result.join(' + ')
  }

  render() {
    const {
      group,
      getGenerationLabelByKey,
      getPrestationLabelByKey,
      getProductForOrderLabelByKey
    } = this.props
    const injMelt = this.setMelt(group.injectionOrder)
    const thinDMelt = this.setMelt(group.thinDropsOrder)
    const bigDMelt = this.setMelt(group.bigDropsOrder)

    return (
      <tr>
        <td>{getGenerationLabelByKey(group.generation)}</td>
        <td>{getProductForOrderLabelByKey(group.product)}</td>
        <td>{group.gender}</td>
        <td>{group.totalChicks}</td>
        <td>{injMelt}</td>
        <td>{thinDMelt}</td>
        <td>{bigDMelt}</td>
        <td>
          <ul>
            {group.prestations &&
              group.prestations.map(prestation => (
                <li key={prestation}>{getPrestationLabelByKey(prestation)}</li>
              ))}
          </ul>
        </td>
      </tr>
    )
  }
}

export default injectIntl(Tr)
