import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Filters from '../Filters/Filters'
import { ExportFile } from '../globalAction'
import RightButton from '../RightButton'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.onReportBtnClick = this.onReportBtnClick.bind(this)
  }

  onReportBtnClick() {
    const { history } = this.props
    history.push('/dailyReport/addReport')
  }

  render() {
    const {
      intl,
      inload,
      role,
      token,
      url,
      setStateToParent,
      filterByDate,
      totalItems
    } = this.props

    return (
      <div className="row pageHeader pageTitle" data-testid="injectedArray">
        <div className="col m5 filters">
          <div>
            <Filters
              token={token}
              setStateToParent={setStateToParent}
              url={url}
              role={role}
              filterType="noFilter"
              filterByDate={filterByDate}
              totalItems={totalItems}
              dateOnly
              infiniteLoader
              noFilterParam
              inLoad={() => inload}
              hasDate
              noActive
            />
          </div>
        </div>
        <div className="export col m5 flex">
          <div className="col m9">
            <RightButton
              text={intl.formatMessage({ id: 'app.button.addReport' })}
              noRow
              onClick={this.onReportBtnClick}
            />
          </div>
          <div className="col m3">
            <ExportFile data={{ role, token }} url="/api/daily_reports/export/xlsx" />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(Header)))
