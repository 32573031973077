import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import icons from '../../../../../../assets/icons/icons'
import PrestationsContext from '../../../../../../context/PrestationsContext'

class PrestationsBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      prestations: props.group.prestations
    }
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    const { setDataFromParent, group } = this.props
    setDataFromParent(group.prestations)
  }

  static contextType = PrestationsContext

  handleClick(e) {
    const { setDataFromParent } = this.props
    let { data } = this.props
    const { prestations } = this.state
    const { checked, value } = e.target

    if (prestations.includes(parseInt(value))) {
      const i = prestations.indexOf(parseInt(value))

      if (i > -1) {
        prestations.splice(i, 1)
      }
    }

    if (checked === true) {
      prestations.push(parseInt(value))
    }
    data = prestations
    setDataFromParent(data)
    this.setState({ prestations })
  }

  render() {
    const { intl } = this.props
    const { prestations } = this.state
    const { prestations: prestationsFromContext, getPrestationLabelByKey } = this.context
    return (
      <div className="row form custom-card">
        <div className="card-content">
          <div className="row card-block">
            <div className="col s4">
              <div className="flex">
                <img className="card-logo marginRight15" src={icons.yellowChick} alt="logo chick" />
                <span className="bold">{intl.formatMessage({ id: 'app.label.prestations' })}</span>
              </div>
            </div>
            <div className="col s8">
              <div className="row">
                {prestations.map(presta => (
                  <span key={presta} className="col s4">
                    {getPrestationLabelByKey(presta)}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
          <div className="row card-block">
            {prestationsFromContext.map(presta => (
              <label key={presta.value} className="col s3">
                <input
                  type="checkbox"
                  className="filled-in checkbox"
                  name="diluent"
                  checked={prestations.includes(Number(presta.value))}
                  onClick={this.handleClick}
                  value={presta.value}
                />
                <span>{presta.displayName ? presta.displayName : presta.name}</span>
              </label>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(PrestationsBlock))
