import React from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import Header from './partials/Header'
import PrestationsCheckBoxes from './partials/PrestationsCheckBoxes'

const Prestations = props => {
  const { onClick, prestations, gender } = props

  return (
    <div className="prestations row custom-card">
      <Header gender={gender} />
      <PrestationsCheckBoxes gender={gender} onClick={onClick} prestations={prestations} />
    </div>
  )
}

export default injectIntl(Prestations)

Prestations.propTypes = {
  onClick: PropTypes.func.isRequired,
  gender: PropTypes.string.isRequired
}
