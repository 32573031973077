import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import icons from '../../../assets/icons/icons'
import roles from '../../../config/userRoles.json'

const ContainerManagement = props => {
  const ContainersParts = [
    { icon: icons.containersList, name: 'listContainers', path: 'list', noOperator: false },
    { icon: icons.containerUseEnd, name: 'endOfUseContainer', path: 'endofuse', noOperator: true },
    {
      icon: icons.containerVaccineTransfer,
      name: 'vaccinationTransfer',
      path: 'transfer',
      noOperator: true
    },
    {
      icon: icons.containerNitrogenTransfer,
      name: 'nitrogenTransfer',
      path: 'nitrogenTransfer',
      noOperator: false
    }
  ]
  const { role } = props
  const { ROLE_OPERATOR } = roles
  return (
    <div className="row Container equipmentManagement">
      {ContainersParts.map(part =>
        part.noOperator && role[0] === ROLE_OPERATOR ? (
          <div key={part.name} />
        ) : (
          <div
            className={
              role[0] !== ROLE_OPERATOR && !part.noOperator
                ? 'col m4 containersManagementItem'
                : 'col m4'
            }
            key={part.name}
          >
            <Link
              to={{
                pathname: `/equipments/containers/${part.path}`,
                state: { part: part.name }
              }}
            >
              <div className="card center">
                <img src={part.icon} alt="icon container" className="responsive-img" />
                <p>
                  <FormattedMessage id={`app.part.${part.name}`} />
                </p>
              </div>
            </Link>
          </div>
        )
      )}
    </div>
  )
}
export default ContainerManagement
