import React from 'react'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import icons from '../../../../../../../../../assets/icons/icons'

const ProductBagCard = props => {
  const { actualPart, intl, preparation } = props
  const { planningOrder } = preparation
  const { productBag } = preparation.preparation

  return (
    <div className="row productBagCard">
      <div className="col s12">
        <div className="row">
          <div className="col s12">
            <div className="row flex">
              <span className="productBag-img">
                <img src={icons.productBag} alt="product bag logo" />
              </span>
              <span className="labelCustom">
                {intl.formatMessage({ id: 'app.label.productBag' })} {actualPart} /{' '}
                {planningOrder.orderOrderPreparations.length}
              </span>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
        <div className="row">
          <div className="col s12">
            <span className="labelCustom">{intl.formatMessage({ id: 'app.label.volume' })} :</span>{' '}
            {productBag.volume} {intl.formatMessage({ id: 'app.unit.volume' })}
          </div>
          <div className="col s12">
            <span className="labelCustom">
              {intl.formatMessage({ id: 'app.label.batchNumber' })}
            </span>{' '}
            {productBag.batchNumber}
          </div>
          <div className="col s12">
            <span className="labelCustom">
              {intl.formatMessage({ id: 'app.label.expirationDate' })} :
            </span>{' '}
            {moment(productBag.expirationDate).format('DD/MM/YYYY')}
          </div>
        </div>
        <div className="row">
          <div className="divider greyDivider" />
        </div>
        <div className="row">
          <div className="col s12">
            {productBag.doses} {intl.formatMessage({ id: 'app.unit.doses' })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(ProductBagCard)
