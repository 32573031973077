import React from 'react'
import { injectIntl } from 'react-intl'

const TextArea = props => {
  const { intl, handleChange, label, name, value1, value2 } = props

  return (
    <div className="row">
      <div className="col s12 input">
        {label !== 'none' && (
          <label htmlFor={name} className="labelParams">
            {intl.formatMessage({ id: label || 'app.label.alert' })}
          </label>
        )}
        <textarea
          className="materialize-textarea"
          name={name}
          value={value1 || value2}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

export default injectIntl(TextArea)
