import React from 'react'
import { FormattedMessage } from 'react-intl'
import HeadSingleHistory from './HeadSingleHistory'

export default function FridgeHistory(props) {
  const { history, operator, date, action, intl } = props

  return (
    <div className="Container equipmentHistory">
      <HeadSingleHistory operator={operator} date={date} action={action}>
        <div className="row historyContent">
          <div className="col s3">
            <p>
              <span className="label">
                <FormattedMessage id="app.label.name" />
                &nbsp;:
              </span>
              &nbsp;{history.action.fridgeName}
            </p>
          </div>
          <div className="col s3">
            <p>
              <span className="label">
                <FormattedMessage id="app.label.brand" />
                &nbsp;:
              </span>
              &nbsp;{history.action.fridgeBrand}
            </p>
          </div>
          <div className="col s3">
            <p>
              <span className="label">
                <FormattedMessage id="app.label.volume" />
                &nbsp;:
              </span>
              {` ${history.action.fridgeVolume} ${intl.formatMessage({
                id: 'app.unit.fridgeVolume'
              })}`}
            </p>
          </div>
          <div className="col s3">
            {history.action.disinfected && (
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.disinfected" />
                  &nbsp;:
                </span>
                &nbsp;{history.action.disinfected}
                <FormattedMessage id={`app.label.${history.action.disinfected}`} />
              </p>
            )}
          </div>
        </div>
      </HeadSingleHistory>
    </div>
  )
}
