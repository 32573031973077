import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import Header from '../../../components/Vaccination/Validation/List/Header'
import OrderGroupsList from '../../../components/Vaccination/Validation/List/OrderGroupsList'
import { api } from '../../../parameters'

class Validation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      groups: [],
      contentStatus: false,
      filters: [
        { category: 'order[number]', sortBy: ['asc', 'desc'] },
        { category: 'order[farmer]', sortBy: ['asc', 'desc'] },
        { category: 'order[hatchery]', sortBy: ['asc', 'desc'] }
      ],
      url: `${api}/api/planning_order_groups/vaccination/validation`
    }
    this.inLoad = this.inLoad.bind(this)
    this.getStateFromChild = this.getStateFromChild.bind(this)
  }

  componentDidMount() {
    const { token } = this.props
    const { url } = this.state

    Axios.get(url, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({
          loading: false,
          groups: res.data
        })
      })
      .catch(err => catchError(err.response))
  }

  getStateFromChild(data, infiniteLoader = false) {
    let { groups, contentStatus } = this.state
    if (infiniteLoader) {
      groups = [...groups, ...data]
    } else {
      groups = data
    }

    if (groups.length >= data.length) {
      contentStatus = 'noMoreResult'
    }
    this.setState({
      groups,
      loading: false,
      contentStatus
    })
  }

  inLoad(contentStatus) {
    this.setState({ contentStatus })
  }

  render() {
    const { loading, groups, contentStatus, filters, url } = this.state

    if (loading === true) {
      return <LoadingPage />
    }

    return (
      <div className="row Container VaccinationContainer validation">
        <Header
          inLoad={this.inLoad}
          filters={filters}
          url={url}
          setStateToParent={this.getStateFromChild}
        />
        <OrderGroupsList groups={groups} contentStatus={contentStatus} />
      </div>
    )
  }
}

const mapStatetoProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStatetoProps)(Validation))
