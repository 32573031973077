import M from 'materialize-css'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'

class TimePickerBlock extends React.Component {
  componentDidMount() {
    const { setDataToParent, label } = this.props

    if (label === 'controlHour') {
      M.Timepicker.init(document.querySelectorAll('.timepicker'), {
        twelveHour: false,
        autoClose: true,
        onSelect: (controlHour, controlMinutes) => {
          setDataToParent('controlHour', controlHour)
          setDataToParent('controlMinutes', controlMinutes)
        }
      })
    } else if (label === 'receptionTime') {
      const { getDataFromChild } = this.props
      M.Timepicker.init(document.querySelectorAll('.timepicker'), {
        twelveHour: false,
        autoClose: true,
        onSelect: (controlHour, controlMinutes) => {
          setDataToParent('controlHour', controlHour)
          setDataToParent('controlMinutes', controlMinutes)
          getDataFromChild(label, true)
        }
      })
    } else {
      M.Timepicker.init(document.querySelectorAll('.timepicker'), {
        twelveHour: false,
        autoClose: true,
        onSelect: (controlHour, controlMinutes) => {
          setDataToParent('controlHour', controlHour)
          setDataToParent('controlMinutes', controlMinutes)
        }
      })
    }
  }

  render() {
    const { intl, defaultValue, hourValue, minutesValue } = this.props
    let { label } = this.props
    if (label === null || label === undefined) {
      label = 'receiptHour'
    }

    return (
      <div className="input">
        <label htmlFor="timepicker">
          <span>{intl.formatMessage({ id: `app.label.${label}` })}</span>
        </label>
        <input
          type="text"
          className="timepicker"
          value={
            (hourValue &&
              minutesValue &&
              moment()
                .hour(hourValue)
                .minute(minutesValue)
                .format('HH:mm')) ||
            (defaultValue && moment().format('HH:mm'))
          }
          placeholder={intl.formatMessage({ id: 'app.label.hourFormat' })}
          readOnly
        />
      </div>
    )
  }
}

export default injectIntl(TimePickerBlock)
