import React from 'react'
import { injectIntl } from 'react-intl'

const Footer = props => {
  const { intl } = props

  return (
    <>
      <div id="certificateComment">{intl.formatMessage({ id: 'app.certificate.comment' })} : </div>
      <div id="certificateFooter">{intl.formatMessage({ id: 'app.certificate.signature' })}</div>
    </>
  )
}

export default injectIntl(Footer)
