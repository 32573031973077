import { injectIntl } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import store from '../../store'
import icons from '../../assets/icons/icons'
import AlertsContext from '../../context/AlertsContext'

class AlertsListPage extends React.Component {
  componentDidMount() {
    store.subscribe(() => this.forceUpdate())
    const { setSelectMode } = this.context
    setSelectMode(false)
  }

  setTypeRender(type) {
    let color = ''
    let icon = ''

    switch (type) {
      case 1:
      case 2:
        color = 'orange'
        icon = icons.nonBlockingAlert
        break
      case 3:
        color = 'red'
        icon = icons.blockingAlert
        break
      case 5:
      case 6:
      case 7:
      case 8:
        color = 'blue'
        icon = icons.blueInfo
        break
      default:
    }

    return {
      color,
      icon
    }
  }

  static contextType = AlertsContext

  render() {
    const { intl, notifications } = this.props
    const { selectMode, selectedAlerts, addAlert } = this.context
    if (notifications.length === 0) {
      return (
        <div className="alertsList">
          <div className="card alertItem">
            <div className="row alertHeader">
              <img src={icons.greenSuccess} alt="alert icon" className="col s1 icon" />
              <div className="col s10 alertContainer">
                {intl.formatMessage({ id: 'app.message.noAlert' })}
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="alertsList">
        {notifications &&
          notifications.map(alert => {
            const { color, icon } = this.setTypeRender(alert.type)
            const message = alert.message.split('##')[0]
            return (
              <div className="card alertItem flex" key={`alert-${alert.id}`}>
                <div style={{ width: '30px', height: '30px' }}>
                  {selectMode && (
                    <label>
                      <input
                        className="filled-in checkbox"
                        type="checkbox"
                        onChange={() => addAlert(alert.uuid)}
                        checked={selectedAlerts.includes(alert.uuid)}
                      />
                      <span>&nbsp;</span>
                    </label>
                  )}
                </div>
                <Link to={{ pathname: `alerts/${alert.uuid}` }} className="grow">
                  <div className="row">
                    <img src={icon} alt="alert icon" className="col s1 icon" />
                    <div className="col s10 alertContainer">
                      <span
                        className={`date ${
                          moment(alert.createdAt).format('DD-MM-YYYY') !==
                          moment().format('DD-MM-YYYY')
                            ? 'passedAlert'
                            : ''
                        }`}
                      >
                        {moment(alert.createdAt).format('DD-MM-YYYY - HH:mm')}
                      </span>
                      <div className={`title title-${color}`}>
                        {`${intl.formatMessage({ id: `app.alertCategory.${alert.category}` })} ${
                          alert.equipmentId ? alert.equipmentId : ''
                        }`}
                      </div>
                      <div className="message">{message}</div>
                    </div>
                    <div className="col s1 buttonContainer">
                      <span className="right">
                        <img src={icons.blueArrow} alt="blue arrow" />
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            )
          })}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    apiToken: state.auth.token,
    notifications: state.mercure.notifications
  }
}

export default withRouter(injectIntl(connect(mapStateToProps)(AlertsListPage)))
