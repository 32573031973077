import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import Axios from 'axios'
import moment from 'moment'
import { api } from '../../../../../../../../parameters'
import { catchError } from '../../../../../../../../actions'

class Tr extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      batch: {},
      loading: true
    }
  }

  componentDidMount() {
    const { number, token } = this.props

    Axios.get(`${api}/api/vaccines_batches?batchNumber=${number}`, {
      headers: { Authorization: token }
    })
      .then(res => {
        Axios.get(`${api}${res.data['hydra:member'][0]['@id']}`, {
          headers: { Authorization: token }
        })
          .then(result => this.setState({ batch: result.data, loading: false }))
          .catch(err => catchError(err.response))
      })
      .catch(err => catchError(err.response))
  }

  render() {
    const { number, quantity } = this.props
    const { batch, loading } = this.state

    if (loading === true) {
      return <tr />
    }

    const { expirationDate: exp, vaccineArticle: article } = batch
    const { speciality: spe } = article

    return (
      <tr>
        <td>{spe.name}</td>
        <td>{article.name}</td>
        <td>{number}</td>
        <td>{moment(exp).format('DD/MM/Y')}</td>
        <td>{quantity}</td>
      </tr>
    )
  }
}

const mapStatetoProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStatetoProps)(Tr))
