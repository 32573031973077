import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import { goBack } from '../../globalAction'
import BlockPart from '../BlockPart/BlockPart'
import NoBlockPart from '../NoBlockPart/NoBlockPart'
import ChangesDisplayNotification from './partials/ChangesDisplayNotification'

class AddParamStock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    M.AutoInit()
  }

  render() {
    const { data, intl, history } = this.props
    const { type, history: hist } = data
    const { action } = hist
    const { channels, volume, provider, conservation, article, description, productType } = action

    const pathGoBack = '/parameters/parameter-history'

    return (
      <div className="Container stockHistory">
        <div>
          <div className="pageTitle pageHeader row">
            <div className="col s12">
              <div
                role="button"
                className="flexInline pointer"
                onClick={() => goBack(pathGoBack, history)}
                onKeyPress={() => goBack(pathGoBack, history)}
                tabIndex={0}
              >
                <div className="goBackBtn">
                  <i role="button" tabIndex="0" className="material-icons">
                    chevron_left
                  </i>
                </div>
                <h5>
                  <span>
                    {intl.formatMessage({
                      id: `app.label.parameters.action.${type}`
                    })}
                  </span>
                </h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <div className="row">
                <div className="row form">
                  <div className="col s12">
                    <div className="row">
                      <div className="col s12">
                        <div className="row">
                          <h4>
                            {`${intl.formatMessage({
                              id: `app.label.productType.${(productType && productType[0]) ||
                                action.productType}`
                            })} ${action.provider} ${action.volume || ''}`}
                          </h4>
                          <h5>
                            <span>{intl.formatMessage({ id: 'app.label.modificationDone' })}</span>
                          </h5>
                        </div>
                        <div className="row params">
                          <div className="col s12">
                            <div className="row">
                              <div className="col s12">
                                <div className="row">
                                  <div className="col s12 input">
                                    <div className="row input">
                                      <div className="col s6">
                                        <label htmlFor="noBlockPartAlert" className="labelParams">
                                          {intl.formatMessage({ id: 'app.label.productType' })}
                                          &nbsp;:&nbsp;
                                        </label>
                                        <span>
                                          {intl.formatMessage({
                                            id: `app.label.productType.${productType}`
                                          })}
                                        </span>
                                      </div>
                                      {provider && (
                                        <div className="col s6">
                                          <label htmlFor="provider" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.provider' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>{provider}</span>
                                        </div>
                                      )}
                                    </div>
                                    <div className="row input">
                                      {volume && (
                                        <div className="col s6">
                                          <label htmlFor="volume" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.volume' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>{volume}</span>
                                        </div>
                                      )}
                                      {conservation && (
                                        <div className="col s6">
                                          <label htmlFor="conservation" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.conservation' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>
                                            {intl.formatMessage({
                                              id: `app.label.conservationMethod.${conservation}`
                                            })}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    <div className="row input">
                                      {article && (
                                        <div className="col s6">
                                          <label htmlFor="article" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.article' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>{article}</span>
                                        </div>
                                      )}
                                    </div>
                                    <div className="row input">
                                      {description && (
                                        <div className="col s12">
                                          <label htmlFor="description" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.description' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>{description}</span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="divider greyDivider" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s12">
                                <NoBlockPart
                                  productType={productType}
                                  action={action}
                                  alertNumber="1"
                                  history
                                />
                              </div>
                            </div>
                            {action.blockingAlert && (
                              <div className="row">
                                <div className="col s12">
                                  <BlockPart
                                    productType={productType}
                                    action={action}
                                    addParamProducts
                                    history
                                  />
                                </div>
                              </div>
                            )}
                            <div className="row">
                              <div className="col s12">
                                <NoBlockPart
                                  productType={productType}
                                  action={action}
                                  alertNumber="2"
                                  history
                                />
                              </div>
                            </div>
                            {channels !== undefined && <div className="divider greyDivider" />}
                            {channels !== undefined && (
                              <div className="row">
                                <div className="col s12">
                                  <div className="row">
                                    <div className="col s12">
                                      <p className="greyTitle">
                                        {intl.formatMessage({ id: 'app.label.notification' })}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col s12">
                                      <div className="row">
                                        <ChangesDisplayNotification
                                          value1={channels[0]}
                                          value2={channels[1]}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(withRouter(AddParamStock))
