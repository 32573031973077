/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import AsyncLoader from '../../../components/AsyncLoader'
import traceabilityProductTypes from '../../../config/traceabilityProductTypes'
import { SpanLink } from '../Components'
import { getEquipmentById } from '../ReverseRedirections'

function VaccineTransfer({ token, location, intl, history }) {
  const [page, setPage] = useState(1)
  const [state, setState] = useState({
    loading: true,
    items: null,
    contentStatus: false,
    totalItems: 0,
    batchNumber: null
  })

  const setLoading = loading => {
    setState({
      ...state,
      loading
    })
  }

  function loadMore() {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (state.totalItems > page * 30) {
        setPage(page + 1)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', loadMore)
  }, [])

  useEffect(() => {
    const pathName = location.pathname.split('/')
    const id = Number(pathName[pathName.length - 2])
    Axios.get(`${api}/api/batch_traceability/${id}/transfer?page=${page}`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const unknownValue = intl.formatMessage({ id: 'app.label.N/A' })
        const unit = intl.formatMessage({
          id:
            res.data.equipmentType === traceabilityProductTypes.VACCINES_BATCH
              ? 'app.unit.centimeter'
              : 'app.unit.temp'
        })
        const mappedData = [...res.data['hydra:member']].map(i => ({
          ...i,
          date: i.date ? moment(i.date).format('DD/MM/YYYY') : unknownValue,
          hour: i.date ? moment(i.date).format('HH:mm') : unknownValue,
          operator: i.operator ?? unknownValue,
          transferedQuantity: `${i.transferedQuantity} ${intl.formatMessage({
            id: 'app.unit.units'
          })}`,
          oldEquipmentId: i.oldEquipmentId ?? unknownValue,
          newEquipmentId: i.newEquipmentId ?? unknownValue,

          oldGiverEquipmentControlInstant: i.oldGiverEquipmentControl
            ? `${i.oldGiverEquipmentControl.instant}
        ${unit}`
            : unknownValue,
          oldGiverEquipmentControlMin: i.oldGiverEquipmentControl
            ? `${i.oldGiverEquipmentControl.min}
        ${unit}`
            : unknownValue,
          oldGiverEquipmentControlMax: i.oldGiverEquipmentControl
            ? `${i.oldGiverEquipmentControl.max}
        ${unit}`
            : unknownValue,
          newGiverEquipmentControlInstant: i.newGiverEquipmentControl
            ? `${i.newGiverEquipmentControl.instant}
        ${unit}`
            : unknownValue,
          newGiverEquipmentControlMin: i.newGiverEquipmentControl
            ? `${i.newGiverEquipmentControl.min}
        ${unit}`
            : unknownValue,
          newGiverEquipmentControlMax: i.newGiverEquipmentControl
            ? `${i.newGiverEquipmentControl.max}
        ${unit}`
            : unknownValue,
          oldCatcherEquipmentControlInstant: i.oldCatcherEquipmentControl
            ? `${i.oldCatcherEquipmentControl.instant}
        ${unit}`
            : unknownValue,
          oldCatcherEquipmentControlMin: i.oldCatcherEquipmentControl
            ? `${i.oldCatcherEquipmentControl.min}
        ${unit}`
            : unknownValue,
          oldCatcherEquipmentControlMax: i.oldCatcherEquipmentControl
            ? `${i.oldCatcherEquipmentControl.max}
        ${unit}`
            : unknownValue,
          newCatcherEquipmentControlInstant: i.newCatcherEquipmentControl
            ? `${i.newCatcherEquipmentControl.instant}
        ${unit}`
            : unknownValue,
          newCatcherEquipmentControlMin: i.newCatcherEquipmentControl
            ? `${i.newCatcherEquipmentControl.min}
        ${unit}`
            : unknownValue,
          newCatcherEquipmentControlMax: i.newCatcherEquipmentControl
            ? `${i.newCatcherEquipmentControl.max}
        ${unit}`
            : unknownValue,
          oldQuantity: `${i.oldQuantity} ${intl.formatMessage({
            id: 'app.unit.units'
          })}`,
          newQuantity: `${i.newQuantity} ${intl.formatMessage({
            id: 'app.unit.units'
          })}`
        }))
        setState({
          ...state,
          loading: false,
          items: state.items ? state.items.concat(mappedData) : mappedData,
          totalItems: res.data['hydra:totalItems'],
          contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult',
          batchNumber: res.data.speciality ?? res.data.batchNumber,
          equipmentType: res.data.equipmentType
        })
      })
      .catch(err => catchError(err))
  }, [page])

  return (
    <div className="row Container stockAvailibilityItem">
      <div className="col s12 Content">
        <div className="row pageHeader pageTitle itemHeader">
          <div
            onClick={() => history.goBack()}
            role="button"
            tabIndex="0"
            onKeyPress={() => history.goBack()}
            className="goBackBtn col s1"
          >
            <i className="material-icons">chevron_left</i>
          </div>
          {state.batchNumber !== null && (
            <h5 className="col s12">
              {state.batchNumber} - {intl.formatMessage({ id: 'app.title.vaccine_transfer' })}
            </h5>
          )}
        </div>
        {!state.loading ? (
          <div className="row">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.vaccine_or_product.vaccine_transfer.date'
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.vaccine_or_product.vaccine_transfer.hour'
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.vaccine_or_product.vaccine_transfer.operator_name'
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.vaccine_or_product.vaccine_transfer.donor_device'
                    })}
                  </th>
                  {state.equipmentType !== traceabilityProductTypes.TYPE_NITROGEN_CONTAINER && (
                    <th>
                      {intl.formatMessage({
                        id: 'app.label.vaccine_or_product.vaccine_transfer.donor_device_control'
                      })}
                    </th>
                  )}
                  {state.equipmentType === traceabilityProductTypes.TYPE_NITROGEN_CONTAINER && (
                    <th>
                      {intl.formatMessage({
                        id:
                          'app.label.vaccine_or_product.vaccine_transfer.donor_device_control_temp'
                      })}
                    </th>
                  )}
                  {state.equipmentType === traceabilityProductTypes.TYPE_NITROGEN_CONTAINER && (
                    <>
                      <th>
                        {intl.formatMessage({
                          id:
                            'app.label.vaccine_or_product.vaccine_transfer.donor_device_control_min'
                        })}
                      </th>
                      <th>
                        {intl.formatMessage({
                          id:
                            'app.label.vaccine_or_product.vaccine_transfer.donor_device_control_max'
                        })}
                      </th>
                    </>
                  )}
                  {state.equipmentType !== traceabilityProductTypes.TYPE_NITROGEN_CONTAINER && (
                    <th>
                      {intl.formatMessage({
                        id: 'app.label.vaccine_or_product.vaccine_transfer.donor_device_control'
                      })}
                    </th>
                  )}
                  <th>
                    {intl.formatMessage({
                      id:
                        'app.label.vaccine_or_product.vaccine_transfer.number_of_units_before_movement'
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id:
                        'app.label.vaccine_or_product.vaccine_transfer.number_of_units_during_movement'
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id:
                        'app.label.vaccine_or_product.vaccine_transfer.number_of_units_after_movement'
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.vaccine_or_product.vaccine_transfer.recipient_device'
                    })}
                  </th>
                  {state.equipmentType !== traceabilityProductTypes.TYPE_NITROGEN_CONTAINER && (
                    <th>
                      {intl.formatMessage({
                        id: 'app.label.vaccine_or_product.vaccine_transfer.recipient_device_control'
                      })}
                    </th>
                  )}
                  {state.equipmentType !== traceabilityProductTypes.TYPE_NITROGEN_CONTAINER && (
                    <th>
                      {intl.formatMessage({
                        id: 'app.label.vaccine_or_product.vaccine_transfer.recipient_device_control'
                      })}
                    </th>
                  )}
                  {state.equipmentType === traceabilityProductTypes.TYPE_NITROGEN_CONTAINER && (
                    <th>
                      {intl.formatMessage({
                        id:
                          'app.label.vaccine_or_product.vaccine_transfer.recipient_device_control_temp'
                      })}
                    </th>
                  )}
                  {state.equipmentType === traceabilityProductTypes.TYPE_NITROGEN_CONTAINER && (
                    <>
                      <th>
                        {intl.formatMessage({
                          id:
                            'app.label.vaccine_or_product.vaccine_transfer.recipient_device_control_min'
                        })}
                      </th>
                      <th>
                        {intl.formatMessage({
                          id:
                            'app.label.vaccine_or_product.vaccine_transfer.recipient_device_control_max'
                        })}
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {state.items.map(item => {
                  return (
                    <tr key={item.id}>
                      <td>{item.date}</td>
                      <td>{item.hour}</td>
                      <td>{item.operator}</td>
                      <td>
                        <SpanLink
                          value={item.oldEquipmentId}
                          setLoading={setLoading}
                          redirect={getEquipmentById}
                          token
                          history
                        />
                      </td>
                      <td>{item.oldGiverEquipmentControlInstant}</td>
                      {state.equipmentType === traceabilityProductTypes.TYPE_NITROGEN_CONTAINER && (
                        <>
                          <td> {item.oldGiverEquipmentControlMin}</td>
                          <td> {item.oldGiverEquipmentControlMax}</td>
                        </>
                      )}
                      {state.equipmentType !== traceabilityProductTypes.TYPE_NITROGEN_CONTAINER && (
                        <>
                          <td>{item.newGiverEquipmentControlInstant}</td>
                        </>
                      )}
                      <td>{item.oldQuantity}</td>
                      <td>{item.transferedQuantity}</td>
                      <td>{item.newQuantity}</td>
                      <td>
                        <SpanLink
                          value={item.newEquipmentId}
                          setLoading={setLoading}
                          redirect={getEquipmentById}
                          token
                          history
                        />
                      </td>
                      <td>{item.oldCatcherEquipmentControlInstant}</td>
                      {state.equipmentType === traceabilityProductTypes.TYPE_NITROGEN_CONTAINER && (
                        <>
                          <td> {item.oldCatcherEquipmentControlMin}</td>
                          <td> {item.oldCatcherEquipmentControlMax}</td>
                        </>
                      )}
                      {state.equipmentType !== traceabilityProductTypes.TYPE_NITROGEN_CONTAINER && (
                        <>
                          <td>{item.newCatcherEquipmentControlInstant}</td>
                        </>
                      )}
                    </tr>
                  )
                })}
              </tbody>
              <tfoot className="center">
                <tr>
                  <th colSpan="5">
                    <AsyncLoader status={state.contentStatus} />
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        ) : (
          <LoadingPage />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(VaccineTransfer)))
