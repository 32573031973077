import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { catchError } from '../../../actions'
import { api } from '../../../parameters'
import AsyncLoader from '../../AsyncLoader'
import Filters from '../../Filters/Filters'
import LoadingPage from '../../LoadingPage'
import ArticleExpDate from './partials/ArticleExpDate'
import ValenceContext from '../../../context/ValencesContext'

class StockParamsVaccineSprayable extends React.Component {
  constructor() {
    super()
    this.state = {
      contentStatus: false,
      vaccinesSpray: [],
      url: `${api}/api/vaccine_specialities?administrationMethod[]=1&administrationMethod[]=2`,
      filters: [
        { category: 'order[name]', sortBy: ['asc', 'desc'], catTranslationPrefix: 'speciality' },
        {
          category: 'valence',
          sortBy: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
          translationPrefix: 'valence'
        },
        {
          category: 'conservationMethod',
          sortBy: [0, 1],
          translationPrefix: 'conservationMethod'
        },
        {
          category: 'administrationMethod',
          sortBy: [1, 2],
          translationPrefix: 'administrationMethod'
        }
      ],
      loading: true
    }
    this.getStateFromChild = this.getStateFromChild.bind(this)
    this.inLoad = this.inLoad.bind(this)
  }

  componentDidMount() {
    const { token } = this.props

    Axios.get(`${api}/api/vaccine_specialities?administrationMethod[]=1&administrationMethod[]=2`, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({
          vaccinesSpray: res.data['hydra:member'],
          totalItems: res.data['hydra:totalItems'],
          contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult',
          loading: false
        })
      })
      .catch(err => catchError(err))
    M.AutoInit()
  }

  getStateFromChild(data, infiniteLoader = false) {
    let { vaccinesSpray, contentStatus } = this.state
    if (infiniteLoader) {
      vaccinesSpray = [...vaccinesSpray, ...data['hydra:member']]
    } else {
      vaccinesSpray = data['hydra:member']
    }

    if (vaccinesSpray.length >= data['hydra:totalItems']) {
      contentStatus = 'noMoreResult'
    }
    this.setState({
      vaccinesSpray,
      loading: false,
      contentStatus
    })
  }

  inLoad(contentStatus) {
    this.setState({ contentStatus })
  }

  static contextType = ValenceContext

  render() {
    const { intl, history, token, role } = this.props
    const { vaccinesSpray, loading, url, filters, contentStatus, totalItems } = this.state
    const { getValenceLabelByKey } = this.context

    if (loading === true) {
      return <LoadingPage />
    }

    return (
      <div>
        <div className="row">
          <div className="col s12">
            <div className="pageHeader row">
              <div>
                <Filters
                  token={token}
                  setStateToParent={this.getStateFromChild}
                  url={url}
                  role={role}
                  infiniteLoader
                  totalItems={totalItems}
                  sortCategory="order[name]"
                  sortBy="asc"
                  filters={filters}
                  inLoad={this.inLoad}
                  originalFilter
                />
              </div>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>{intl.formatMessage({ id: `app.label.speciality` })}</th>
                  <th>{intl.formatMessage({ id: `app.label.valence` })}</th>
                  <th>{intl.formatMessage({ id: `app.label.conservation` })}</th>
                  <th>{intl.formatMessage({ id: `app.label.administration` })}</th>
                  <th>{intl.formatMessage({ id: `app.label.minStock` })}</th>
                  <th>{intl.formatMessage({ id: `app.label.article` })}</th>
                  <th>{intl.formatMessage({ id: `app.label.expirationDate` })}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {vaccinesSpray.map(vaccine => (
                  <tr
                    key={vaccine.id}
                    onClick={() => {
                      history.push(`/parameters/stock/speciality/edit/${vaccine.id}`)
                    }}
                    className="pointer"
                  >
                    <td>{vaccine.name}</td>
                    <td>{getValenceLabelByKey(vaccine.valence)}</td>
                    <td>
                      {intl.formatMessage({
                        id: `app.label.conservationMethod.${vaccine.conservationMethod}`
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: `app.administrationMethod.${vaccine.administrationMethod}`
                      })}
                    </td>
                    <td>
                      {vaccine && vaccine.minimalStockAlert && vaccine.minimalStockAlert.value ? (
                        vaccine.minimalStockAlert.value
                      ) : (
                        <div className="noPadding blue-text">Ajouter</div>
                      )}
                    </td>
                    <td>
                      {vaccine.vaccineArticles && vaccine.vaccineArticles.length > 0 ? (
                        vaccine.vaccineArticles.map(vaccineArticle => (
                          <div key={vaccineArticle.name} className="noPadding col s12">
                            {vaccineArticle.name}
                          </div>
                        ))
                      ) : (
                        <div className="noPadding blue-text">Ajouter</div>
                      )}
                    </td>
                    <td>
                      {vaccine.vaccineArticles && vaccine.vaccineArticles.length > 0 ? (
                        vaccine.vaccineArticles.map((article, i) => (
                          <ArticleExpDate key={i} article={article} />
                        ))
                      ) : (
                        <div className="noPadding blue-text">Ajouter</div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot className="center">
                <tr>
                  <th colSpan="12">
                    <AsyncLoader status={contentStatus} />
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(StockParamsVaccineSprayable)))
