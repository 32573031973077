import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { catchError } from '../../../../actions'
import icons from '../../../../assets/icons/icons'
import { api } from '../../../../parameters'
import { validatedAction } from '../../../globalAction'

class VaccineApplicatorPart extends React.Component {
  constructor(props) {
    super(props)
    this.state = { applicators: [] }
    this.deleteApplicator = this.deleteApplicator.bind(this)
  }

  componentDidMount() {
    const { machine, token } = this.props

    Axios.get(`${api}/api/vaccine_applicators?machine=${machine}`, {
      headers: { Authorization: token }
    })
      .then(res => this.setState({ applicators: res.data['hydra:member'] }))
      .catch(err => catchError(err))
  }

  deleteApplicator(e) {
    const { token, intl } = this.props
    const { id } = e.target

    Swal.fire({
      title: intl.formatMessage({ id: 'app.alert.areYouSureTitle' }),
      text: intl.formatMessage({ id: 'app.alert.areYouSureText' }),
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: intl.formatMessage({ id: 'app.label.no' }),
      confirmButtonText: intl.formatMessage({ id: 'app.label.yes' }),
      customClass: {
        icon: 'swalErrorIcon',
        title: 'swalTitle',
        content: 'swalContentText',
        confirmButton: 'blueBtn',
        cancelButton: 'blueBtn'
      }
    }).then(result => {
      if (result.value) {
        Axios.delete(`${api}/api/vaccine_applicators/${id}`, { headers: { Authorization: token } })
          .then(() => validatedAction('', 0))
          .catch(err => catchError(err.response))
      }
    })
  }

  render() {
    const { intl, type } = this.props
    const { applicators } = this.state

    return (
      <div>
        <div className="row">
          <div className="col s12 greyTitle">
            {intl.formatMessage({ id: `app.title.vaccineApplicator.${type}` }).toUpperCase()}
          </div>
        </div>
        <div className="row">
          <div className="col s12 input">
            {applicators &&
              applicators.map(applicator => (
                <span key={applicator.id} className="col s2">
                  <label>
                    <span>{applicator.name}</span>
                    <span
                      role="button"
                      className="pointer"
                      id={applicator.id}
                      onClick={this.deleteApplicator}
                      onKeyPress={this.deleteApplicator}
                      tabIndex="0"
                    >
                      <img id={applicator.id} src={icons.redDustbin} alt="red dustbin icon" />
                    </span>
                  </label>
                </span>
              ))}
            <span className="col s2">
              <a
                className="flex col s12 btnAddVaccineApplicator blue-text waves-effect waves-light modal-trigger "
                href="#modalAddVaccineApplicator"
              >
                <span className="plusButtonVaccineApplicator">+</span>
                <span className="textButtonVaccineApplicator">
                  {intl.formatMessage({ id: `app.label.addVaccineApplicator.${type}` })}
                </span>
              </a>
            </span>
          </div>
        </div>
        <div className="divider greyDivider" />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(VaccineApplicatorPart))
