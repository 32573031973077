import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { catchError } from '../../../../actions'
import { api } from '../../../../parameters'
import LoadingPage from '../../../LoadingPage'
import AllValidatedMessage from '../AllValidatedMessage'
import FridgeControlItem from './partials/FridgeControlItem'

class FridgePart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      fridges: []
    }
    this.loadFridges = this.loadFridges.bind(this)
  }

  componentDidMount() {
    this.loadFridges()
  }

  loadFridges() {
    const { token } = this.props
    Axios.get(`${api}/api/fridges`, { headers: { Authorization: token } })
      .then(res => {
        const data = res.data['hydra:member']
        const fridges = []

        let lastControl
        data.forEach(el => {
          if (
            lastControl === undefined ||
            moment(el.lastControl.createdAt).format('DD/MM/YYYY') >
              moment(lastControl).format('DD/MM/YYYY')
          ) {
            lastControl = el.lastControl.createdAt
          }

          if (
            moment(el.lastControl.createdAt).format('L') !== moment().format('L') ||
            el.lastControl.minTemperature === null ||
            el.lastControl.maxTemperature === null ||
            el.lastControl.instantTemperature === null ||
            el.lastControl.valid === false
          ) {
            fridges.push(el)
          }
        })
        this.setState({ fridges, loading: false, lastControl })
      })
      .catch(err => catchError(err.response, false))
  }

  render() {
    const { fridges, loading, lastControl } = this.state
    const { loadControls } = this.props

    if (loading) {
      return <LoadingPage />
    }

    return (
      <div id="fridge">
        <div className="row tabHeader">
          <div className="col offset-s1 s10">
            <p>
              <FormattedMessage id="app.control.fridge.header" />
            </p>
          </div>
        </div>
        {fridges.length === 0 ? (
          <AllValidatedMessage lastControl={lastControl} id="app.equipment.fridgesAll" />
        ) : (
          <div className="row content">
            {fridges.map((fridge, i) => (
              <FridgeControlItem
                key={fridge.id}
                fridge={fridge}
                loadControls={loadControls}
                loadFridges={this.loadFridges}
                index={i}
              />
            ))}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(FridgePart)))
