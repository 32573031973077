import React from 'react'
import { injectIntl } from 'react-intl'

const NoBlockArticleHistoryPart = props => {
  const { intl, nonBlockingExpirationAlert } = props
  return (
    <div className="row">
      <div className="col s6 input">
        <div className="row">
          <div className="col s12">
            <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
              {intl.formatMessage({ id: 'app.label.before' })}
            </label>
          </div>
        </div>
        {nonBlockingExpirationAlert[0] && (
          <div className="row input">
            <div className="col s12">
              <label htmlFor="noBlockPartAlert" className="labelParams">
                {intl.formatMessage({ id: 'app.label.expirationDate' })}
                &nbsp;:&nbsp;
              </label>
              <span>{nonBlockingExpirationAlert[0] && nonBlockingExpirationAlert[0].value}</span>
            </div>
          </div>
        )}
        {nonBlockingExpirationAlert[0] && (
          <div className="row input">
            <div className="col s12">
              <label htmlFor="noBlockPartAlertStockAfter" className="labelParams">
                {intl.formatMessage({ id: 'app.label.alert' })}
                &nbsp;:&nbsp;
              </label>
              <span>{nonBlockingExpirationAlert[0] && nonBlockingExpirationAlert[0].message}</span>
            </div>
          </div>
        )}
      </div>
      <div className="col s6">
        <div className="row input">
          <div className="col s12">
            <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
              {intl.formatMessage({ id: 'app.label.after' })}
            </label>
          </div>
        </div>
        <div className="row input">
          <div className="col s12">
            <label htmlFor="noBlockPartAlert" className="labelParams">
              {intl.formatMessage({ id: 'app.label.expirationDate' })}
              &nbsp;:&nbsp;
            </label>
            <span>{nonBlockingExpirationAlert[1].value}</span>
          </div>
        </div>
        <div className="row input">
          <div className="col s12">
            <label htmlFor="noBlockPartAlertStockAfter" className="labelParams">
              {intl.formatMessage({ id: 'app.label.alert' })}
              &nbsp;:&nbsp;
            </label>
            <span>{nonBlockingExpirationAlert[1].message}</span>
          </div>
        </div>
      </div>
      <div className="divider greyDivider" />
    </div>
  )
}

export default injectIntl(NoBlockArticleHistoryPart)
