import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { catchError } from '../../../actions'
import { closePage, validatedAction } from '../../../components/globalAction'
import LoadingPage from '../../../components/LoadingPage'
import BlockPart from '../../../components/Parameters/BlockPart/BlockPart'
import NoBlockPart from '../../../components/Parameters/NoBlockPart/NoBlockPart'
import Notification from '../../../components/Parameters/Notification'
import { conservationMethods, products } from '../../../config/productConfig'
import { api, providers } from '../../../parameters'

class ProductsParamsItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      parameters: { blockingAlert: { value: {} }, nonBlockingAlert: {} },
      loading: true
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
  }

  componentDidMount() {
    const { match, token } = this.props

    if (match.params.id) {
      this.setState({ loading: true })
      Axios.get(`${api}/api/stock_parameters/${match.params.id}`, {
        headers: { Authorization: token }
      })
        .then(res => this.setState({ parameters: res.data, loading: false }))
        .catch(err => catchError(err.response))
    }

    M.AutoInit()
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  handleChange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleSubmit() {
    const { match, token } = this.props
    const {
      article,
      description,
      volume,
      conservation,
      provider,
      noBlockUnitsStock,
      noBlockPartAlertStock,
      noBlockPartAlertExpirationDate,
      noBlockExpirationDate,
      blockAlert,
      blockExpirationDate,
      hatchRespAlerts,
      vetAlerts,
      hatchingRespAlerts,
      operatorAlerts,
      channels,
      parameters,
      productType
    } = this.state
    const { nonBlockingAlert, blockingAlert } = parameters
    const { id } = match.params

    let formData = {}

    if (hatchRespAlerts) channels.push(hatchRespAlerts)
    if (vetAlerts) channels.push(vetAlerts)
    if (hatchingRespAlerts) channels.push(hatchingRespAlerts)
    if (operatorAlerts) channels.push(operatorAlerts)

    if (id) {
      formData = {
        productType: productType === undefined ? parameters.productType : parseInt(productType),
        provider: provider === undefined ? parameters.provider : provider,
        volume: parseInt(volume) || parameters.volume,
        conservation: conservation === undefined ? parameters.conservation : parseInt(conservation),
        article: article || parameters.article,
        description: description || parameters.description,
        conformityAlert: {},
        nonBlockingAlert: {
          type: 2,
          value: {
            stock: noBlockUnitsStock || nonBlockingAlert.value.stock,
            expirationDate: noBlockExpirationDate || nonBlockingAlert.value.expirationDate
          },
          message: {
            stock: noBlockPartAlertStock || nonBlockingAlert.message.stock,
            expirationDate:
              noBlockPartAlertExpirationDate || nonBlockingAlert.message.expirationDate
          }
        },
        blockingAlert: {
          type: 3,
          value: blockExpirationDate || blockingAlert.value,
          message: blockAlert || blockingAlert.message
        },
        channels
      }

      Axios.patch(`${api}/api/stock_parameters/${id}`, formData, {
        headers: { Authorization: token }
      })
        .then(() => {
          validatedAction('')
        })
        .catch(err => catchError(err.response))
    } else {
      formData = {
        productType: parseInt(productType),
        provider,
        volume: parseInt(volume),
        conservation: parseInt(conservation),
        article,
        description,
        conformityAlert: {},
        nonBlockingAlert: {
          type: 2,
          value: {
            stock: noBlockUnitsStock || null,
            expirationDate: noBlockExpirationDate || null
          },
          message: {
            stock: noBlockPartAlertStock || null,
            expirationDate: noBlockPartAlertExpirationDate || null
          }
        },
        blockingAlert: {
          type: 3,
          value: blockExpirationDate || null,
          message: blockAlert || null
        },
        channels
      }

      Axios.post(`${api}/api/stock_parameters`, formData, {
        headers: { Authorization: token }
      })
        .then(() => {
          validatedAction('')
        })
        .catch(err => catchError(err.response))
    }
  }

  render() {
    const { intl, match } = this.props
    const { parameters, loading, volume, description, article, conservation } = this.state
    const { blockingAlert, nonBlockingAlert, provider, productType } = parameters

    if (loading && match.params.id) return <LoadingPage />

    return (
      <div className="row Container params">
        <div className="col s12 content marginTop30">
          <div className="pageTitle pageHeader row col s12">
            <div className="row col s12">
              <div className="flex">
                <h5>
                  <span>{provider}</span>
                </h5>
              </div>
            </div>
            <div
              className="goBackBtn close right"
              role="button"
              tabIndex="0"
              onKeyPress={() => closePage(intl, false)}
              onClick={() => closePage(intl, false)}
            >
              <i className="material-icons">close</i>
            </div>
          </div>

          <div className="row col s12">
            <div className="row col s12 card">
              <div className="col s12 form">
                <form>
                  <div className="row">
                    <div className="col s12">
                      <p className="greyTitle">
                        {intl.formatMessage({ id: 'app.label.generalsInformations' })}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s6 input">
                      <label htmlFor="productType" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.productType' })}
                      </label>
                      <select
                        className="select-wrapper"
                        defaultValue={match.params.id ? productType : '0'}
                        name="productType"
                        id="productType"
                        onChange={this.handleChange}
                      >
                        <option value={match.params.id ? productType : '0'} disabled>
                          {match.params.id
                            ? intl.formatMessage({ id: `app.label.productType.${productType}` })
                            : intl.formatMessage({ id: `app.label.selectProducts` })}
                        </option>
                        {products.map(product => (
                          <option key={product.name} value={product.name}>
                            {intl.formatMessage({
                              id: `app.label.productType.${product.name}`
                            })}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col s6 input">
                      <label htmlFor="provider" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.provider' })}
                      </label>
                      <select
                        className="select-wrapper"
                        defaultValue={match.params.id ? provider : '0'}
                        name="provider"
                        onChange={this.handleChange}
                      >
                        <option value={match.params.id ? provider : '0'} disabled>
                          {match.params.id
                            ? provider
                            : intl.formatMessage({ id: `app.label.selectProvider` })}
                        </option>
                        {providers.map(providerElement => (
                          <option key={providerElement.name}>{providerElement.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s6 input">
                      <label htmlFor="volume" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.volume' })}
                      </label>
                      <input
                        type="number"
                        maxLength="2"
                        name="volume"
                        id="volume"
                        value={volume || parameters.volume}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col s6 input">
                      <label htmlFor="conservation" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.conservation' })}
                      </label>
                      <select
                        className="select-wrapper"
                        name="conservation"
                        id="conservation"
                        defaultValue={
                          match.params.id ? conservation || parameters.conservation : '0'
                        }
                        onChange={this.handleChange}
                        required
                      >
                        <option
                          value={match.params.id ? conservation || parameters.conservation : '0'}
                        >
                          {match.params.id && parameters.conservation !== null
                            ? intl.formatMessage({
                                id: `app.label.conservationMethod.${conservation ||
                                  parameters.conservation}`
                              })
                            : intl.formatMessage({
                                id: `app.label.selectConservationMethod`
                              })}
                        </option>

                        {conservationMethods.map(conservationMethod => (
                          <option key={conservationMethod.value} value={conservationMethod.value}>
                            {intl.formatMessage({
                              id: `app.label.conservationMethod.${conservationMethod.value}`
                            })}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s6 input">
                      <label htmlFor="article" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.article' })}
                      </label>
                      <input
                        type="text"
                        name="article"
                        id="article"
                        value={article || parameters.article}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12 input">
                      <label htmlFor="description" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.productDescription' })}
                      </label>
                      <textarea
                        className="materialize-textarea"
                        name="description"
                        value={description || parameters.description}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="divider greyDivider" />
                  <NoBlockPart
                    productType={productType}
                    alertNumber="1"
                    nonBlockingAlert={nonBlockingAlert}
                    setDataToParent={this.getDataFromChild}
                    editParamProducts={match.params.id}
                  />
                  <NoBlockPart
                    productType={productType}
                    alertNumber="2"
                    nonBlockingAlert={nonBlockingAlert}
                    setDataToParent={this.getDataFromChild}
                    editParamProducts={match.params.id}
                  />
                  <BlockPart
                    productType={productType}
                    blockingAlert={blockingAlert}
                    setDataToParent={this.getDataFromChild}
                    editParamProducts={match.params.id}
                  />
                  <Notification setDataToParent={this.getDataFromChild} parameters={parameters} />

                  <div className="row">
                    <div className="col offset-s5 s7 marginTop30">
                      <button type="button" className="btn blueBtn" onClick={this.handleSubmit}>
                        {intl.formatMessage({ id: 'app.label.save' })}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default connect(mapStateToProps)(injectIntl(ProductsParamsItem))
