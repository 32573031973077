import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { catchError } from '../../actions'
import icons from '../../assets/icons/icons'
import LoadingPage from '../../components/LoadingPage'
import roles from '../../config/userRoles.json'
import history from '../../history'
import { api } from '../../parameters'

class DayWithoutHatching extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    const { token } = this.props
    Axios.get(`${api}/api/no_hatching_periods?order[start]=desc`, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({ dayWithoutHatchingList: res.data['hydra:member'], loading: false })
      })
      .catch(err => catchError(err))
  }

  render() {
    const { intl, role } = this.props
    const { dayWithoutHatchingList, loading } = this.state

    const { ROLE_HATCHING_RESP, ROLE_HATCH_RESP } = roles

    if (loading) {
      return <LoadingPage />
    }

    return (
      <div className="Container equipmentHistory row">
        <div className="col s12">
          <div className="row pageHeader pageTitle itemHeader">
            <div
              onClick={() => history.goBack()}
              role="button"
              tabIndex="0"
              onKeyPress={() => history.goBack()}
              className="goBackBtn col s1"
            >
              <i className="material-icons">chevron_left</i>
            </div>
            <h5 className="col s9">{intl.formatMessage({ id: 'app.label.dayWithoutHatching' })}</h5>
            {(role.includes(ROLE_HATCHING_RESP) || role.includes(ROLE_HATCH_RESP)) && (
              <div>
                <Link to="/planning/day-without-hatching/add" className="btn blueBtnResponsive">
                  {intl.formatMessage({ id: 'app.label.add' })}
                </Link>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col s12">
              {dayWithoutHatchingList !== undefined && dayWithoutHatchingList.length !== 0 ? (
                dayWithoutHatchingList.map(item => (
                  <div key={item.end} className="row noMarginBot">
                    <div className="col s12 card cardDayWithoutHatching">
                      <div>
                        <div className="col s1">
                          <img src={icons.redDanger} alt="icon red danger" />
                        </div>
                        <div className="col s11">
                          <div>
                            {`${intl.formatMessage({ id: 'app.label.from' })} ${moment(
                              item.start
                            ).format('DD/MM/YYYY')} ${intl.formatMessage({
                              id: 'app.label.to'
                            })} ${moment(item.end).format('DD/MM/YYYY')}`}
                          </div>
                          <div>
                            {`${intl.formatMessage({ id: 'app.label.createdBy' })} ${
                              item.createdBy
                            }`}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div>Aucun jours sans éclosion</div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default withRouter(injectIntl(connect(mapStateToProps)(DayWithoutHatching)))
