import Axios from 'axios'
import M from 'materialize-css'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { catchError } from '../../../actions'
import CenterButton from '../../../components/CenterButton'
import DatepickerBlock from '../../../components/DatepickerBlock'
import { validatedAction } from '../../../components/globalAction'
import HeaderReception from '../../../components/StockManagement/OrderReception/partials/HeaderReception'
import ReserveContainerForm from '../../../components/StockManagement/OrderReception/ReserveContainerReception/ReserveContainerForm'
import TextareaBlock from '../../../components/TextareaBlock'
import TimePickerBlock from '../../../components/TimePickerBlock'
import { api, containerProviders } from '../../../parameters'

class ReserveContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      containerNumber: '',
      capacities: [],
      boxState: true,
      containerState: true,
      disinfected: true,
      disabled: false,
      controlHour: moment().hours()
    }
    this.getAllContainers = this.getAllContainers.bind(this)
    this.createContainerNumber = this.createContainerNumber.bind(this)
    this.selectProvider = this.selectProvider.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.verifyCheckedValue = this.verifyCheckedValue.bind(this)
    this.popAlert = this.popAlert.bind(this)
  }

  componentDidMount() {
    this.getAllContainers()
    M.CharacterCounter.init(document.getElementById('textarea1'))
  }

  getAllContainers() {
    const { token } = this.props
    Axios.get(`${api}/api/nitrogen_containers`, { headers: { Authorization: token } })
      .then(res => {
        const containersAlreadyUsed = []
        res.data['hydra:member'].forEach(container => {
          containersAlreadyUsed.push(container)
        })
        let containerNumber = this.createContainerNumber()
        while (containersAlreadyUsed.includes(containerNumber)) {
          containerNumber = this.createContainerNumber()
        }
        this.setState({ containerNumber })
      })
      .catch(err => catchError(err.response))
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  handleSubmit(e) {
    e.preventDefault()
    const { token, intl } = this.props
    const {
      capacity,
      selectedProvider,
      containerNumber,
      dateSelected,
      controlHour,
      controlMinutes,
      boxState,
      containerState,
      disinfected,
      comment,
      nitrogenLevel
    } = this.state
    if (
      !selectedProvider ||
      !capacity ||
      !containerNumber ||
      !boxState ||
      !containerState ||
      !disinfected ||
      !nitrogenLevel
    ) {
      return Swal.fire({
        type: 'error',
        title: intl.formatMessage({ id: 'app.label.error' }),
        text: intl.formatMessage({ id: 'app.label.emptyfields' }),
        customClass: {
          icon: 'swalErrorIcon',
          title: 'swalTitle',
          content: 'swalContentText',
          confirmButton: 'blueBtn'
        }
      })
    }
    let receptionDate = moment().format()
    if (dateSelected && controlHour && controlMinutes) {
      receptionDate = moment(dateSelected)
        .hour(controlHour)
        .minutes(controlMinutes)
        .format()
    }
    const data = {
      nitrogenContainer: {
        containerNumber,
        provider: selectedProvider.name,
        capacity,
        receptionDate
      },
      history: {
        boxState,
        containerState,
        boxStateNoConformityReason: null,
        containerStateNoConformityReason: null,
        disinfectedNoConformityReason: null,
        disinfected,
        comment: comment || '',
        createdAt: moment().format(),
        receptionDate
      },
      control: {
        nitrogenLevel,
        nitrogenLevelState: 1
      }
    }
    return Axios.post(`${api}/api/nitrogen_containers`, data, { headers: { Authorization: token } })
      .then(() =>
        validatedAction(intl.formatMessage({ id: 'app.message.receivedReserveContainer' }))
      )
      .catch(err => catchError(err.response))
  }

  selectProvider(e) {
    const { value } = e.target
    const selectedProvider = containerProviders[value]
    const capacities = selectedProvider.capacity
    return this.setState({ selectedProvider, capacities }, () =>
      M.FormSelect.init(document.querySelectorAll('select'), {})
    )
  }

  handleChange(e) {
    const { name, value, type, checked } = e.target

    if (type === 'checkbox') {
      this.setState({ [name]: checked })
      return this.verifyCheckedValue(e.target)
    }
    return this.setState({ [name]: value })
  }

  verifyCheckedValue(target) {
    const { name, checked } = target
    this.setState({ [name]: checked })

    switch (name) {
      case 'disinfected':
        if (!checked) {
          return this.popAlert('app.alert.containerNotDesinfected')
        }
        break
      case 'boxState':
        this.setState({ [name]: checked })
        if (!checked) {
          return this.popAlert('app.alert.tooDeteriorated')
        }
        break
      case 'containerState':
        this.setState({ [name]: checked })
        if (!checked) {
          return this.popAlert('app.alert.tooDeterioratedContainer')
        }
        break
      default:
        return true
    }

    return true
  }

  popAlert(text) {
    const { intl } = this.props
    Swal.fire({
      type: 'error',
      title: intl.formatMessage({ id: 'app.alert.error' }),
      text: intl.formatMessage({ id: text }),
      customClass: {
        icon: 'swalErrorIcon',
        title: 'swalTitle',
        content: 'swalContentText',
        confirmButton: 'blueBtn'
      }
    })
  }

  createContainerNumber() {
    const result = Math.floor(Math.random() * 9999)
    return result
  }

  render() {
    const {
      containerNumber,
      selectedProvider,
      capacities,
      disabled,
      disinfected,
      boxState,
      containerState,
      dateSelected
    } = this.state
    const { intl } = this.props

    return (
      <form autoComplete="off">
        <div className="row Container">
          <div className="col s12">
            <HeaderReception
              text={intl.formatMessage({ id: 'app.part.containerReserveReception' })}
            />
            <div className="row">
              <div className="col s12">
                <ReserveContainerForm
                  handleChange={this.handleChange}
                  selectedProvider={selectedProvider}
                  containerNumber={containerNumber}
                  capacities={capacities}
                  selectProvider={this.selectProvider}
                  disinfected={disinfected}
                  boxState={boxState}
                  parentState={this.state}
                  containerState={containerState}
                />
                <div className="row">
                  <div className="col s12">
                    <div className="form nitrogen">
                      <div className="row">
                        <div className="col s12">
                          <div className="col s3 input">
                            <DatepickerBlock
                              name="reception"
                              setDataFromChild={this.getDataFromChild}
                              label={intl.formatMessage({ id: 'app.label.receiptDate' })}
                              value={dateSelected && moment(dateSelected).format('DD/MM/YYYY')}
                            />
                          </div>
                          <div className="col s3 input">
                            <TimePickerBlock
                              name="timeControl"
                              setDataToParent={this.getDataFromChild}
                            />
                          </div>
                          <div className="col s3 input">
                            <label>
                              <span>{intl.formatMessage({ id: 'app.label.controlDate' })}</span>
                            </label>
                            <input type="text" value={moment().format('DD/MM/YYYY')} disabled />
                          </div>
                          <div className="col s3 input">
                            <label>
                              <span>{intl.formatMessage({ id: 'app.label.controlHour' })}</span>
                            </label>
                            <input type="text" value={moment().format('HH:mm')} disabled />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <div className="form nitrogen">
                      <div className="row">
                        <div className="col s12">
                          <TextareaBlock handleChange={this.handleChange} name="comment" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <CenterButton
                  text={intl.formatMessage({ id: 'app.button.validate' })}
                  onClick={this.handleSubmit}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token
})
export default injectIntl(connect(mapStateToProps)(ReserveContainer))
