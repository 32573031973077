import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import icons from '../../../assets/icons/icons'
import roles from '../../../config/userRoles.json'
import AsyncLoader from '../../AsyncLoader'
import PlanningTableTbody from './PlanningTablePartials/PlanningTableTbody'
import { ProductsForOrderContext, PrestationsContext } from '../../../context/contexts'

class PlanningTable extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    const {
      contentStatus,
      manageSelectedOrder,
      groups,
      intl,
      isPreparation,
      isRealisation,
      injectableVaccine,
      role,
      selectedDay,
      selectDay,
      selectWeek,
      selectedWeek,
      sprayableVaccine,
      vaccineType,
      vaccinesType,
      deleteOrders
    } = this.props
    const { ROLE_OPERATOR } = roles

    return (
      <div className="row">
        <div className="col s12">
          <table className="table stockTable width100">
            <thead>
              <tr>
                <th colSpan="12" className="thHeaderPlanning">
                  <span>
                    {!(
                      role.includes(ROLE_OPERATOR) &&
                      selectedDay.format('DD/MM') === moment().format('DD/MM')
                    ) ? (
                      <span
                        role="button"
                        onClick={
                          role.includes(ROLE_OPERATOR)
                            ? () => selectDay('before')
                            : () => selectWeek('before')
                        }
                        onKeyPress={
                          role.includes(ROLE_OPERATOR)
                            ? () => selectDay('before')
                            : () => selectWeek('before')
                        }
                        tabIndex={0}
                      >
                        <img
                          className="noPadding pointer"
                          src={icons.arrowWhiteLeft}
                          alt="white right arrow"
                        />
                      </span>
                    ) : (
                      <span />
                    )}
                    <span>
                      {!role.includes(ROLE_OPERATOR)
                        ? `${intl.formatMessage({
                            id: 'app.label.week'
                          })} ${selectedWeek}`
                        : `${intl.formatMessage({
                            id: 'app.label.day'
                          })} ${moment(selectedDay).format('DD/MM')}`}
                    </span>
                    {!(
                      role.includes(ROLE_OPERATOR) &&
                      selectedDay.format('DD/MM') ===
                        moment()
                          .add(1, 'days')
                          .format('DD/MM')
                    ) ? (
                      <span
                        role="button"
                        onClick={
                          !role.includes(ROLE_OPERATOR)
                            ? () => selectWeek('after')
                            : () => selectDay('after')
                        }
                        onKeyPress={
                          !role.includes(ROLE_OPERATOR)
                            ? () => selectWeek('after')
                            : () => selectDay('after')
                        }
                        tabIndex={0}
                      >
                        <img
                          className="pointer"
                          src={icons.arrowWhiteRight}
                          alt="white right arrow"
                        />
                      </span>
                    ) : (
                      <span />
                    )}
                  </span>
                </th>
              </tr>
              <tr className="sticky">
                <th>{intl.formatMessage({ id: 'app.label.orderNumber' })}</th>
                <th>{intl.formatMessage({ id: 'app.label.hatchingDay' })}</th>
                <th>{intl.formatMessage({ id: 'app.label.client' })}</th>
                {isPreparation && <th>{intl.formatMessage({ id: 'app.label.farmer' })}</th>}
                <th>{intl.formatMessage({ id: 'app.label.product' })}</th>
                <th>{intl.formatMessage({ id: 'app.label.gender' })}</th>
                <th>{intl.formatMessage({ id: 'app.label.chickQuantity' })}</th>
                {vaccinesType.includes(0) && (
                  <th>{intl.formatMessage({ id: 'app.label.injectedVaccine' })}</th>
                )}
                {vaccinesType.includes(1) && (
                  <th>{intl.formatMessage({ id: 'app.label.fineDrops' })}</th>
                )}
                {vaccinesType.includes(2) && (
                  <th>{intl.formatMessage({ id: 'app.label.bigDrops' })}</th>
                )}
                {!isPreparation && <th>{intl.formatMessage({ id: 'app.label.interventions' })}</th>}
                <th />
                <th />
              </tr>
            </thead>
            {groups &&
              groups.map((group, index) => {
                if (
                  (vaccinesType.includes(0) &&
                    group.vaccinesInjectable &&
                    group.vaccinesInjectable.length > 0) ||
                  (vaccinesType.includes(1) &&
                    group.vaccinesThinDrops &&
                    group.vaccinesThinDrops.length > 0) ||
                  (vaccinesType.includes(2) &&
                    group.vaccinesBigDrops &&
                    group.vaccinesBigDrops.length > 0 &&
                    group.totalChicks > 0)
                ) {
                  return (
                    <ProductsForOrderContext.Consumer>
                      {productsForOrderContext => (
                        <PrestationsContext.Consumer>
                          {prestationsContext => (
                            <PlanningTableTbody
                              key={group.id}
                              manageSelectedOrder={manageSelectedOrder}
                              group={group}
                              id={index}
                              index={index}
                              isPreparation={isPreparation}
                              isRealisation={isRealisation}
                              injectableVaccine={injectableVaccine}
                              vaccinesType={vaccinesType}
                              vaccineType={vaccineType}
                              sprayableVaccine={sprayableVaccine}
                              productsForOrderContext={productsForOrderContext}
                              prestationsContext={prestationsContext}
                              deleteOrders={deleteOrders}
                            />
                          )}
                        </PrestationsContext.Consumer>
                      )}
                    </ProductsForOrderContext.Consumer>
                  )
                }
                return null
              })}
            <tfoot className="center">
              <tr>
                <th colSpan="12">
                  <AsyncLoader status={contentStatus} />
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    )
  }
}

export default injectIntl(PlanningTable)
