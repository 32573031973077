import React from 'react'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'

const ValidateButton = props => {
  const { intl, number, show } = props

  return (
    <Link
      className={`btn blueBtn validateBtn ${show ? '' : 'hidden'}`}
      type="button"
      to={`/vaccination/validation/${btoa(unescape(encodeURIComponent(number)))}`}
    >
      {intl.formatMessage({ id: 'app.button.validateAndCertify' })}
    </Link>
  )
}

export default injectIntl(ValidateButton)
