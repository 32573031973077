import React, { createContext, useState, useEffect } from 'react'
import fetchItems from './fetchItems'
import getLabelByKey from './getLabelByKey'

const PrestationsContext = createContext({
  prestations: [],
  getPrestationLabelByName: () => '',
  fetchPrestations: () => {}
})

export const PrestationsProvider = ({ children }) => {
  const [prestations, setPrestations] = useState([])

  const fetchPrestations = () => {
    fetchItems('prestations', setPrestations)
  }

  useEffect(() => {
    fetchPrestations()
  }, [])

  const getPrestationLabelByKey = key => getLabelByKey(prestations, key)

  return (
    <PrestationsContext.Provider
      value={{
        prestations,
        getPrestationLabelByKey,
        fetchPrestations
      }}
    >
      {children}
    </PrestationsContext.Provider>
  )
}

export default PrestationsContext
