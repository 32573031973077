import M from 'materialize-css'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import icons from '../../../assets/icons/icons'
import { i18n } from '../../../parameters'

class DateFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.onSelectDate = this.onSelectDate.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    M.Datepicker.init(document.getElementById('filterDate'), {
      autoClose: true,
      format: 'dd/mm/yyyy',
      selectMonth: true,
      firstDay: 1,
      i18n,
      onSelect: dateSelected => {
        this.onSelectDate(dateSelected)
      }
    })
    const instanceFilterDate = M.Datepicker.getInstance(document.getElementById('filterDate'))
    this.setState({ instanceFilterDate })
  }

  onSelectDate(filterDate) {
    const { filterByDate, getDataFromChild } = this.props
    const { instanceFilterDate } = this.state

    getDataFromChild('page', 1)
    getDataFromChild('filterType', 'filterDate')
    filterByDate(filterDate)
    instanceFilterDate.gotoDate(new Date(filterDate))
    getDataFromChild('filterDate', filterDate)
  }

  handleClick() {
    const { activeFilter } = this.props
    activeFilter('filterDate', true)
  }

  render() {
    const { filterType, intl, filterDate } = this.props

    return (
      <>
        <div
          className={`input ${
            filterType !== 'filterDate' && filterType !== undefined ? 'displayNone' : ''
          }`}
        >
          <input
            type="text"
            className="datepicker"
            id="filterDate"
            value={filterDate && moment(filterDate).format('DD/MM/YYYY')}
            name="filterDate"
            readOnly
            placeholder={intl.formatMessage({ id: 'app.selectDate' })}
          />
          <img src={icons.blueCalendar} alt="calendar icon" />
        </div>
        {filterType !== 'filterDate' && filterType !== undefined && (
          <div
            className="icon-filter-date"
            onClick={this.handleClick}
            onKeyPress={this.handleClick}
            role="button"
            tabIndex="0"
          >
            <img src={icons.whiteCalendar} alt="calendar icon" />
          </div>
        )}
      </>
    )
  }
}

export default injectIntl(DateFilter)
