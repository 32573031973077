import moment from 'moment'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import HeadSingleHistory from './HeadSingleHistory'
import ValenceContext from '../../../../context/ValencesContext'

const listVaccineBatch = data => {
  const { getValenceLabelByKey } = useContext(ValenceContext)
  return (
    <div>
      {data.map(batch => (
        <div key={batch.batchNumber}>
          <div className="row">
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.speciality" />
                  &nbsp;:&nbsp;
                </span>
                {batch.speciality}
              </p>
            </div>
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.valence" />
                  &nbsp;:&nbsp;
                </span>
                {getValenceLabelByKey(batch.valence)}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.article" />
                  &nbsp;:&nbsp;
                </span>
                {batch.article}
              </p>
            </div>
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.transferedQuantity" />
                  &nbsp;:&nbsp;
                </span>
                {batch.movedUnits}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.batchesNumber" />
                  &nbsp;:&nbsp;
                </span>
                {`${batch.batchNumber}`}
              </p>
            </div>
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.expirationDate" />
                  &nbsp;:&nbsp;
                </span>
                {moment(batch.expirationDate).format('DD/MM/YYYY')}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default function VaccineTransfer(props) {
  const { history, operator, date, action, intl } = props
  const { vaccinesBatches, giver, catcher } = history.action

  return (
    <div className="Container equipmentHistory">
      <HeadSingleHistory operator={operator} date={date} action={action}>
        {listVaccineBatch(vaccinesBatches, intl)}
      </HeadSingleHistory>
      <div className="row historyContentContent">
        <div className="divider greyDivider" />
        <div className="col s12">
          <h5>
            <FormattedMessage id="app.label.giverContainer" />
          </h5>
          <div className="row">
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.containerId" />
                </span>
                {giver.containerNumber}
              </p>
            </div>
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.containerCapacity" />
                  :&nbsp;
                </span>
                {`${giver.containerCapacity} ${intl.formatMessage({
                  id: 'app.unit.containerCapacity'
                })}`}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.container" />
                  :&nbsp;
                </span>
                <FormattedMessage id={`app.equipment.containerType.${giver.containerType}`} />
              </p>
            </div>
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.prevNitrogenLevel" />
                  &nbsp;:&nbsp;
                </span>
                {`${giver.prevNitrogenLevel} ${intl.formatMessage({
                  id: 'app.unit.nitrogenLevel'
                })}`}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.provider" />
                  &nbsp;:&nbsp;
                </span>
                {giver.containerProvider}
              </p>
            </div>
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.newNitrogenLevel" />
                  :&nbsp;
                </span>
                {`${giver.newNitrogenLevel} ${intl.formatMessage({
                  id: 'app.unit.nitrogenLevel'
                })}`}
              </p>
            </div>
          </div>
        </div>
        <div className="divider greyDivider" />
        <div className="col s12">
          <h5>
            <FormattedMessage id="app.label.catcherContainer" />
          </h5>
          <div className="row">
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.containerId" />
                </span>
                {catcher.containerNumber}
              </p>
            </div>
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.containerCapacity" />
                  :&nbsp;
                </span>
                {`${catcher.containerCapacity} ${intl.formatMessage({
                  id: 'app.unit.containerCapacity'
                })}`}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.container" />
                  :&nbsp;
                </span>
                <FormattedMessage id={`app.equipment.containerType.${catcher.containerType}`} />
              </p>
            </div>
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.prevNitrogenLevel" />
                  &nbsp;:&nbsp;
                </span>
                {`${catcher.prevNitrogenLevel} ${intl.formatMessage({
                  id: 'app.unit.nitrogenLevel'
                })}`}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.provider" />
                  &nbsp;:&nbsp;
                </span>
                {catcher.containerProvider}
              </p>
            </div>
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.newNitrogenLevel" />
                  :&nbsp;
                </span>
                {`${catcher.newNitrogenLevel} ${intl.formatMessage({
                  id: 'app.unit.nitrogenLevel'
                })}`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
