import { FormattedMessage } from 'react-intl'
import React from 'react'
import HeadSingleHistory from './HeadSingleHistory'

export default function NitrogenTransfer(props) {
  const { history, operator, date, action, intl } = props
  const { givers, catchers } = history.action

  return (
    <div className="Container equipmentHistory">
      <HeadSingleHistory operator={operator} date={date} action={action} />
      <div className="row">
        <div className="col s12 white">
          <div className="row">
            <div className="col s12 historyContentContent">
              <h5>
                <FormattedMessage id="app.label.giverContainer" />
              </h5>
              {givers.map(giver => (
                <div className="row" key={giver.containerNumber}>
                  <div className="col s12">
                    <div className="row">
                      <div className="col l3">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.name" />
                            &nbsp;:
                          </span>
                          {` Container ${giver.containerNumber}`}
                        </p>
                      </div>
                      <div className="col l3">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.provider" />
                            &nbsp;:
                          </span>
                          {` ${giver.containerProvider}`}
                        </p>
                      </div>
                      <div className="col l3">
                        <p>
                          <span className="label">Type&nbsp;:</span>
                          {` Container ${intl.formatMessage({
                            id: `app.equipment.containerType.${giver.containerType}`
                          })}`}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col l3">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.prevNitrogenLevel" />
                            &nbsp;:
                          </span>
                          {` ${giver.prevNitrogenLevel} ${intl.formatMessage({
                            id: 'app.unit.nitrogenLevel'
                          })}`}
                        </p>
                      </div>
                      <div className="col l3">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.newNitrogenLevelAfterTransfer" />:
                          </span>
                          {` ${giver.newNitrogenLevel} ${intl.formatMessage({
                            id: 'app.unit.nitrogenLevel'
                          })}`}
                        </p>
                      </div>
                      <div className="col l3">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.containerTypeContainer" />:
                          </span>
                          {` ${giver.containerCapacity} ${intl.formatMessage({
                            id: 'app.unit.containerCapacity'
                          })}`}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="divider greyDivider" />
          </div>
          <div className="row">
            <div className="col s12 historyContentContent">
              <h5>
                <FormattedMessage id="app.label.catcherContainer" />
              </h5>
              {catchers.map(item => (
                <div className="row" key={item.containerNumber}>
                  <div className="col s12">
                    <div className="row">
                      <div className="col l3">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.name" />
                            &nbsp;:
                          </span>
                          {` Container ${item.containerNumber}`}
                        </p>
                      </div>
                      <div className="col l3">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.provider" />
                            &nbsp;:
                          </span>
                          {` ${item.containerProvider}`}
                        </p>
                      </div>
                      <div className="col l3">
                        <p>
                          <span className="label">Type&nbsp;:</span>
                          {` Container ${intl.formatMessage({
                            id: `app.equipment.containerType.${item.containerType}`
                          })}`}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col l3">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.prevNitrogenLevel" />
                            &nbsp;:
                          </span>
                          {` ${item.prevNitrogenLevel} ${intl.formatMessage({
                            id: 'app.unit.nitrogenLevel'
                          })}`}
                        </p>
                      </div>
                      <div className="col l3">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.newNitrogenLevelAfterTransfer" />:
                          </span>
                          {` ${item.newNitrogenLevel} ${intl.formatMessage({
                            id: 'app.unit.nitrogenLevel'
                          })}`}
                        </p>
                      </div>
                      <div className="col l3">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.containerTypeContainer" />:
                          </span>
                          {` ${item.containerCapacity} ${intl.formatMessage({
                            id: 'app.unit.containerCapacity'
                          })}`}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
