import React from 'react'
import { injectIntl } from 'react-intl'

const THead = props => {
  const { intl } = props

  return (
    <thead>
      <tr>
        <th>{intl.formatMessage({ id: 'app.label.generation' })}</th>
        <th>{intl.formatMessage({ id: 'app.label.product' })}</th>
        <th>{intl.formatMessage({ id: 'app.label.gender' })}</th>
        <th>{intl.formatMessage({ id: 'app.label.chicksQuantity' })}</th>
        <th>{intl.formatMessage({ id: 'app.label.injectedVaccines' })}</th>
        <th>{intl.formatMessage({ id: 'app.label.thinDropsVaccines' })}</th>
        <th>{intl.formatMessage({ id: 'app.label.bigDropsVaccines' })}</th>
        <th>{intl.formatMessage({ id: 'app.label.prestations' })}</th>
      </tr>
    </thead>
  )
}

export default injectIntl(THead)
