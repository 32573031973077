import React, { createContext, useState, useEffect } from 'react'
import Axios from 'axios'
import Swal from 'sweetalert2'
import { api } from '../parameters'
import store from '../store'

const AlertsContext = createContext({
  selectedAlerts: [],
  setSelectedAlerts: () => '',
  selectMode: false,
  setSelectMode: () => '',
  deleteSelectedOrders: () => '',
  addAlert: () => ''
})
export const AlertsProvider = ({ children }) => {
  const [selectedAlerts, setSelectedAlerts] = useState([])
  const [selectMode, setSelectMode] = useState(false)

  useEffect(() => {
    if (!selectMode && selectedAlerts.length > 0) {
      setSelectedAlerts([])
    }
  }, [selectMode])

  function deleteSelectedOrders(intl) {
    Swal.fire({
      title: intl.formatMessage({ id: 'app.label.areYouSureTitle' }),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({ id: 'app.label.closeAlerts' }),
      cancelButtonText: intl.formatMessage({ id: 'app.label.cancel' }),
      customClass: {
        icon: 'swalWarningIcon',
        title: 'swalTitle',
        content: 'swalContentText',
        confirmButton: 'redBtn',
        cancelButton: 'blueBtn'
      }
    }).then(() => {
      const { token } = store.getState().auth
      if (token) {
        Axios.delete(`${api}/api/alerts`, {
          headers: { Authorization: token },
          data: selectedAlerts.map(alert => {
            return {
              uuid: alert
            }
          })
        }).then(() => {
          Swal.fire({
            type: 'success',
            title: intl.formatMessage({ id: 'app.alert.success' }),
            text: intl.formatMessage({ id: 'app.message.closedAlerts' }),
            timer: 10000,
            customClass: {
              icon: 'swalSuccessIcon',
              title: 'swalTitle',
              content: 'swalContentText',
              confirmButton: 'blueBtn'
            }
          }).then(() => {
            setSelectMode(false)
          })
        })
      }
    })
  }

  function addAlert(uuid) {
    const filteredAlerts = selectedAlerts.filter(selectedUuid => selectedUuid !== uuid)
    const alreadyAdded = filteredAlerts.length !== selectedAlerts.length
    setSelectedAlerts(alreadyAdded ? filteredAlerts : [...selectedAlerts, uuid])
  }

  return (
    <AlertsContext.Provider
      value={{
        selectedAlerts,
        deleteSelectedOrders,
        selectMode,
        setSelectMode,
        addAlert
      }}
    >
      {children}
    </AlertsContext.Provider>
  )
}

export default AlertsContext
