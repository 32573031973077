import React from 'react'
import { injectIntl } from 'react-intl'
import CardHeader from './partials/CardHeader'

const AddPrepAsk = props => {
  const { hist, intl } = props
  const { action: data } = hist.history

  return (
    <div className="vaccinationHistory">
      <div className="row historyCard">
        <CardHeader date={hist.createdAt} operator={hist.firstName} />
        <div className="col s12 card" key={data.containerNumber}>
          <div className="row line">
            <div className="col s6">
              <span className="bold">{intl.formatMessage({ id: 'app.label.orderNumber' })}: </span>{' '}
              {hist.history.orderNumber}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default injectIntl(AddPrepAsk)
