import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { catchError } from '../../../../../../../../actions'
import { api } from '../../../../../../../../parameters'
import LoadingPage from '../../../../../../../LoadingPage'

class ReportProblemSelectNewProductBag extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const { getDataFromChild, preparation, token } = this.props
    const { volume } = preparation.preparation.productBag

    Axios.get(`${api}/api/product_bags?volume=${volume}`, { headers: { authorization: token } })
      .then(res => {
        this.setState({ newProductBags: res.data['hydra:member'], loading: false })
        getDataFromChild('disabledButton', true)
        M.AutoInit()
      })
      .catch(err => catchError(err))
  }

  handleChange(e) {
    const { name, value } = e.target
    const { getDataFromChild } = this.props

    const parseIntValue = parseInt(value)

    getDataFromChild(name, parseIntValue)
    getDataFromChild('disabledButton', false)
  }

  render() {
    const { intl } = this.props
    const { newProductBags, loading } = this.state

    if (loading) {
      return <LoadingPage />
    }

    return (
      <div className="row">
        <div className="col s12">
          <div className="form">
            <div className="card-content">
              <div className="card-block">
                <div className="row">
                  <div className="col s12 input">
                    <div className="col s6">
                      <label htmlFor="newProductBag" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.newProductBag' })}
                      </label>
                      <select
                        className="select-wrapper"
                        name="newProductBag"
                        defaultValue="0"
                        onChange={this.handleChange}
                        required
                      >
                        <option value="0" disabled>
                          {intl.formatMessage({ id: 'app.label.selectProductBag' })}
                        </option>
                        {newProductBags.map(newProductBag => (
                          <option key={newProductBag.id} value={newProductBag.id}>
                            {newProductBag.batchNumber}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(ReportProblemSelectNewProductBag))
