import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { catchError } from '../../../../actions'
import { renderNitrogenLevel } from '../../../../components/globalRenderActions'
import LoadingPage from '../../../../components/LoadingPage'
import StatePill from '../../../../components/StatePill'
import OutStockSubmitButton from '../../../../components/StockManagement/StockAvailibility/OutStock/OutStockSubmitButton'
import SwitchUnitField from '../../../../components/StockManagement/StockAvailibility/OutStock/SwitchUnitField'
import OutStockHeader from '../../../../components/StockManagement/StockAvailibility/OutStockHeader'
import { api } from '../../../../parameters'

class FrozenVaccineOutStock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      batch: [],
      loading: true,
      reason: null,
      showUnitsInput: true,
      toOutStock: 0,
      disabledSubBtn: true
    }

    this.handleReasonChange = this.handleReasonChange.bind(this)
    this.getStateFromChild = this.getStateFromChild.bind(this)
  }

  componentDidMount() {
    const { match, token } = this.props
    Axios.get(`${api}/api/container_vaccines/${match.params.id}`, {
      headers: {
        Authorization: token
      }
    })
      .then(res => {
        this.setState({ batch: res.data, loading: false })
      })
      .catch(err => catchError(err.response))
  }

  componentDidUpdate(_prevProps, prevState) {
    const { showUnitsInput, toOutStock } = this.state

    if (prevState.showUnitsInput !== showUnitsInput || prevState.toOutStock !== toOutStock) {
      if (showUnitsInput === false || (parseInt(toOutStock) !== 0 && toOutStock !== '')) {
        this.setState({ disabledSubBtn: false })
      } else {
        this.setState({ disabledSubBtn: true })
      }
    }
  }

  getStateFromChild(toOutStock) {
    this.setState({ toOutStock })
  }

  handleReasonChange(e) {
    this.setState({ reason: e.target.value })
  }

  render() {
    const { batch, loading, reason, showUnitsInput, toOutStock, disabledSubBtn } = this.state
    const { intl, match, token } = this.props
    const { nitrogenContainer, vaccinesBatch } = batch

    if (loading === true) {
      return <LoadingPage />
    }

    return (
      <div className="row Container stockAvailibilityItem">
        <div className="col s12 content">
          <OutStockHeader />
          <div className="speciality-description">
            <div className="bold">{}</div>
            <div>{}</div>
          </div>
          <div className="row cardContainer stockCard">
            <div className="col s12 card">
              <h6>
                {`${vaccinesBatch.vaccineArticle.speciality.name} - ${vaccinesBatch.vaccineArticle.name}`}
              </h6>
              <div className="divider greyDivider" />
              <div className="row description">
                <div className="col s6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({ id: `app.label.batchNumber` })}
                      {' : '}
                    </span>
                    {vaccinesBatch.batchNumber}
                  </p>
                </div>
                <div className="col s6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({ id: `app.label.expirationDate` })}
                      {' : '}
                    </span>
                    {moment(vaccinesBatch.expirationDate).format('DD/MM/YYYY')}
                    &nbsp;
                    <StatePill type="ExpirationDate" data={vaccinesBatch} />
                  </p>
                </div>
                <div className="col s6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({ id: `app.label.ampoulesNumber` })}
                      {' : '}
                    </span>
                    {batch.ampoulesNumber}
                  </p>
                </div>
                <div className="col s6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({ id: `app.label.containerNumber` })}
                      {' : '}
                    </span>
                    {nitrogenContainer.containerNumber}
                  </p>
                </div>
                <div className="col s6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({ id: `app.label.totalDosesNumber` })}
                      {' : '}
                    </span>
                    {batch.ampoulesNumber * vaccinesBatch.vaccineArticle.dosesNumber}
                    &nbsp;
                    <StatePill type="doses" data={vaccinesBatch} />
                  </p>
                </div>
                <div className="col s6">
                  <p>
                    <span className="bold">
                      {intl.formatMessage({ id: `app.label.nitrogenLevel` })}
                      {' : '}
                    </span>
                    {renderNitrogenLevel(nitrogenContainer.lastControl.nitrogenLevel, intl)}
                    &nbsp;
                    <StatePill type="ContainerNitrogenLevel" data={nitrogenContainer} />
                  </p>
                </div>
              </div>
              <div className="divider greyDivider" />
              <div className="form">
                <SwitchUnitField
                  show={showUnitsInput}
                  unitsNumber={batch.ampoulesNumber}
                  setStateToParent={this.getStateFromChild}
                />
              </div>
              <div className="divider greyDivider" />
              <div className="form">
                <div className="row">
                  <div className="col s12 input">
                    <label htmlFor="reason">
                      {intl.formatMessage({ id: 'app.label.outStockReason' })}
                    </label>
                    <textarea
                      id="reason"
                      className="materialize-textarea"
                      maxLength="300"
                      onChange={this.handleReasonChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <OutStockSubmitButton
            id={match.params.id}
            title={intl.formatMessage({ id: 'app.label.areYouSureVaccineStock' })}
            message="app.label.outStockVaccinesSuccess"
            reason={reason}
            token={token}
            toOutStock={toOutStock}
            urlType="container_vaccines"
            redirect={-2}
            disabled={disabledSubBtn}
          />
        </div>
      </div>
    )
  }
}

export default injectIntl(FrozenVaccineOutStock)
