import React from 'react'
import { injectIntl } from 'react-intl'

const PrepInfos = props => {
  const { customClass, prep, intl } = props
  const { bottle, fridgeDiluentBatch, fridgeDyeBatch, diluent, dye, productBag, vaccines } = prep

  const melt = {}
  vaccines.forEach(vacc => {
    melt[vacc.batchNumber] = vacc.quantity
  })
  return (
    <>
      <div className={`${customClass || 'col s4'} prepHistory`}>
        {productBag !== null && (
          <div className="col s12">
            <span className="bold">{intl.formatMessage({ id: 'app.label.productBag' })}: </span>{' '}
            {`${productBag.batchNumber} ${productBag.doses} ${intl.formatMessage({
              id: 'app.label.doses'
            })}`}
          </div>
        )}
        {bottle !== null && (
          <div className="col s12">
            <span className="bold">{intl.formatMessage({ id: 'app.label.bottle' })}: </span>{' '}
            {`${bottle} ${intl.formatMessage({
              id: 'app.label.doses'
            })}`}
          </div>
        )}
        <div className="col s12">
          <span className="bold">{intl.formatMessage({ id: 'app.label.vaccinesBatches' })}: </span>{' '}
          {Object.keys(melt).map(i => (
            <div key={i}>
              {i} x {melt[i]}
            </div>
          ))}
        </div>
        {fridgeDiluentBatch && (
          <div className="col s12">
            <span className="bold">{intl.formatMessage({ id: 'app.label.diluent' })}: </span>{' '}
            {fridgeDiluentBatch.fridge.name} - {fridgeDiluentBatch.diluentBatch.batchNumber}
          </div>
        )}
        {!fridgeDiluentBatch && diluent && (
          <div className="col s12">
            <span className="bold">{intl.formatMessage({ id: 'app.label.diluent' })}: </span>{' '}
            {diluent}
          </div>
        )}
        {fridgeDyeBatch && (
          <div className="col s12">
            <span className="bold">{intl.formatMessage({ id: 'app.label.dye' })}: </span>{' '}
            {fridgeDyeBatch.fridge.name} - {fridgeDyeBatch.dyeBatch.batchNumber} -{' '}
            {intl.formatMessage({
              id: `app.color.${fridgeDyeBatch.dyeBatch.color}`
            })}
          </div>
        )}
        {!fridgeDyeBatch && dye && (
          <div className="col s12">
            <span className="bold">{intl.formatMessage({ id: 'app.label.dye' })}: </span> {dye}
          </div>
        )}
        {prep.water && (
          <div className="col s12">
            <span className="bold">{intl.formatMessage({ id: 'app.label.addedWater' })}: </span>{' '}
            {prep.water} {intl.formatMessage({ id: 'app.unit.volume' })}
          </div>
        )}
      </div>
    </>
  )
}
export default injectIntl(PrepInfos)
