import React from 'react'
import Header from './partials/Header'
import PrepsTable from './partials/PrepsTable/PrepsTable'

const WaitedVaccines = props => {
  const { vaccines } = props

  return (
    <div className="waitedVaccines row custom-card">
      <Header />
      <PrepsTable vaccines={vaccines} />
    </div>
  )
}

export default WaitedVaccines
