import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { catchError } from '../../../actions'
import roles from '../../../config/userRoles.json'
import { api } from '../../../parameters'
import Filters from '../../Filters/Filters'
import { ExportFile } from '../../globalAction'
import StatePill from '../../StatePill'
import AddButton from './AddButton'
import OutStockAction from './OutStockAction'

class DyesPart extends React.Component {
  constructor() {
    super()
    this.state = {
      dyes: [],
      url: `${api}/api/fridge_dyes`,
      filters: [
        { category: 'order[dyeBatch.batchNumber]', sortBy: ['asc', 'desc'] },
        { category: 'order[dyeBatch.provider]', sortBy: ['asc', 'desc'] },
        { category: 'dyeBatch.color', sortBy: ['steril_blue', 'blue', 'red', 'purple'] },
        { category: 'order[vialsNumber]', sortBy: ['asc', 'desc'] },
        { category: 'order[dyeBatch.expirationDate]', sortBy: ['asc', 'desc'] },
        { category: 'order[fridge.name]', sortBy: ['asc', 'desc'] },
        { category: 'order[fridge.lastControl.instantTemperature]', sortBy: ['asc', 'desc'] },
        { category: 'order[fridge.lastControl.minTemperature]', sortBy: ['asc', 'desc'] },
        { category: 'order[fridge.lastControl.maxTemperature]', sortBy: ['asc', 'desc'] }
      ]
    }

    this.getStateFromChild = this.getStateFromChild.bind(this)
  }

  componentDidMount() {
    const { token } = this.props

    Axios.get(`${api}/api/fridge_dyes?order[dyeBatch.batchNumber]=asc&active=true`, {
      headers: {
        Authorization: token
      }
    })
      .then(res => {
        Axios.get(`${api}/api/fridges?active=true`, {
          headers: {
            Authorization: token
          }
        })
          .then(res2 => {
            const { filters } = this.state
            const fridges = []
            res2.data['hydra:member'].map(fridge => fridges.push(fridge.name))
            filters.push({ category: 'fridge.name', sortBy: fridges, toTranslate: false })

            this.setState({ filters, dyes: res.data['hydra:member'] })
          })
          .catch(err => catchError(err.response))
      })
      .catch(err => catchError(err.response))

    Axios.get(`${api}/api/stock_parameters?productType=1`, { headers: { Authorization: token } })
      .then(res =>
        this.setState({ minStock: res.data['hydra:member'][0].nonBlockingAlert.value.stock })
      )
      .catch(err => catchError(err))
  }

  getStateFromChild(dyes) {
    this.setState({ dyes })
  }

  render() {
    const { intl, token, role } = this.props
    const { dyes, filters, url, minStock } = this.state
    const { ROLE_OPERATOR } = roles

    return dyes.length !== 0 ? (
      <div>
        <div className="row pageHeader pageTitle">
          <div className="col m12 filters">
            <div>
              <Filters
                token={token}
                setStateToParent={this.getStateFromChild}
                url={url}
                role={role}
                filters={filters}
                inLoad={() => {}}
                hasDate={false}
              />
            </div>
          </div>
          <div className="export col m2">
            <div>
              <ExportFile data={{ role, token }} url="/api/fridge_dyes/export/xlsx" />
            </div>
            <div>
              {!role.includes(ROLE_OPERATOR) && <AddButton path="/stocks/order-received/dye" />}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            <table className="table stockTable">
              <thead>
                <tr>
                  <th>{intl.formatMessage({ id: 'app.label.batchesNumber' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.provider' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.color' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.vialsNumber' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.expirationDate' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.fridge' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.instantTemp' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.minTemp' })}</th>
                  <th>{intl.formatMessage({ id: 'app.label.maxTemp' })}</th>
                  {!role.includes(ROLE_OPERATOR) && (
                    <th>{intl.formatMessage({ id: 'app.label.actions' })}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {dyes &&
                  dyes.map((dye, i) => {
                    const key = `batch-${i}`
                    return (
                      <tr className="pointer" key={key}>
                        <td>{dye.dyeBatch.batchNumber}</td>
                        <td>{dye.dyeBatch.provider}</td>
                        <td>{dye.dyeBatch.color}</td>
                        <td>
                          <StatePill type="units" data={dye} minStock={minStock} />
                          {dye.vialsNumber}
                        </td>
                        <td>
                          <StatePill type="Parameter" data={dye.dyeBatch.expDateState} />
                          {moment(dye.dyeBatch.expirationDate).format('DD/MM/YYYY')}
                        </td>
                        <td>{dye.fridge.name}</td>
                        <td>
                          <StatePill type="FridgeInstantTemperature" data={dye.fridge} />
                          {`${dye.fridge.lastControl.instantTemperature} ${intl.formatMessage({
                            id: 'app.unit.fridgeTemp'
                          })}`}
                        </td>
                        <td>
                          <StatePill type="FridgeMinTemperature" data={dye.fridge} />
                          {`${dye.fridge.lastControl.minTemperature} ${intl.formatMessage({
                            id: 'app.unit.fridgeTemp'
                          })}`}
                        </td>
                        <td>
                          <StatePill type="FridgeMaxTemperature" data={dye.fridge} />
                          {`${dye.fridge.lastControl.maxTemperature} ${intl.formatMessage({
                            id: 'app.unit.fridgeTemp'
                          })}`}
                        </td>
                        {!role.includes(ROLE_OPERATOR) && (
                          <td>
                            <OutStockAction to={`/stocks/out-stock/dye/${dye.id}`} />
                          </td>
                        )}
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    ) : (
      <div className="card-vaccines">{intl.formatMessage({ id: 'app.message.noDye' })}</div>
    )
  }
}

export default injectIntl(DyesPart)
