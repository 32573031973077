import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Axios from 'axios'
import moment from 'moment'
import { api } from '../../../../../../../parameters'
import { catchError } from '../../../../../../../actions'
import icons from '../../../../../../../assets/icons/icons'
import LoadingPage from '../../../../../../LoadingPage'

class SpecialityBlockInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    const { batch, token, vaccineType } = this.props

    if (batch.conservation === 0) {
      Axios.get(`${api}/api/container_vaccines/${batch.batch}`, {
        headers: { Authorization: token }
      })
        .then(res => {
          this.setState({
            loading: false,
            vaccine: res.data,
            containerVaccineId: res.data.nitrogenContainer.containerNumber,
            labelForContainerVaccine: 'container'
          })
        })
        .catch(err => catchError(err.response))
    }

    if (batch.conservation === 1) {
      Axios.get(`${api}/api/fridge_vaccines/${batch.batch}`, {
        headers: { Authorization: token }
      })
        .then(res => {
          this.setState({
            loading: false,
            vaccine: res.data,
            containerVaccineId: res.data.fridge.name,
            labelForContainerVaccine: 'fridge'
          })
        })
        .catch(err => catchError(err.response))
    }

    let icon
    let altIcon

    if (vaccineType === 0) {
      icon = icons.injectedVaccine
      altIcon = 'logo injected vaccine'
    } else if (vaccineType === 1) {
      icon = icons.thinDropVaccine
      altIcon = 'logo thin drop vaccine'
    } else if (vaccineType === 2) {
      icon = icons.bigDropVaccine
      altIcon = 'logo big drop vaccine'
    }

    this.setState({ icon, altIcon })
  }

  render() {
    const { intl, batch, scanned } = this.props
    const {
      loading,
      vaccine,
      icon,
      altIcon,
      containerVaccineId,
      labelForContainerVaccine
    } = this.state

    if (loading === true) {
      return <LoadingPage />
    }

    const ampoulesLabel =
      vaccine.vialsNumber === undefined
        ? intl.formatMessage({ id: 'app.label.bulbs' })
        : intl.formatMessage({ id: 'app.label.vials' })
    const scannedMessage = vaccine.vialsNumber === undefined ? 'scannedAmpoule' : 'scannedVial'

    return (
      <div className="row col s12">
        <div className="col s12">
          <div className="custom-card col s12 noPadding">
            <div className="col s12 card-content">
              <div className="row card-block flex">
                <div className="col s6 em1-2">
                  <span className="flex">
                    <img className="card-logo" src={icon} alt={altIcon} />
                    {batch.dosesType === 2 && (
                      <img className="card-logo" src={icon} alt={altIcon} />
                    )}
                    <span className="labelCustom">
                      {intl.formatMessage({ id: 'app.label.speciality' })}{' '}
                    </span>
                    &nbsp;{vaccine.vaccinesBatch.vaccineArticle.speciality.name}
                  </span>
                </div>
                <div className="col s6">
                  <span className="labelCustom">
                    {intl.formatMessage({ id: 'app.label.article' })}{' '}
                  </span>
                  {vaccine.vaccinesBatch.vaccineArticle.name}
                </div>
              </div>
              <div className="divider greyDivider" />
              <div className="row card-block">
                <div className="col s6">
                  <span className="labelCustom">
                    {intl.formatMessage({ id: `app.label.${labelForContainerVaccine}` })}
                  </span>{' '}
                  {containerVaccineId}
                </div>
                <div className="col s6">
                  <span className="labelCustom">
                    {batch.quantity} {ampoulesLabel}{' '}
                  </span>
                  {vaccine.vaccinesBatch.vaccineArticle.dosesNumber} doses
                </div>
              </div>
              <div className="row card-block">
                <div className="col s6">
                  <span className="labelCustom">
                    {intl.formatMessage({ id: 'app.label.batchNumber' })}
                  </span>{' '}
                  {vaccine.vaccinesBatch.batchNumber}
                </div>
                <div className="col s6">
                  <span className="labelCustom">
                    {intl.formatMessage({ id: 'app.label.expirationDate' })}{' '}
                  </span>
                  {moment(vaccine.vaccinesBatch.expirationDate).format('DD/MM/YYYY')}
                </div>
              </div>

              {scanned === true && (
                <>
                  <div className="divider greyDivider" />
                  <div className="row card-block">
                    <div className="col s6 scannedAmpoule">
                      <img src={icons.greenCheck} alt="green check icon" />
                      {intl.formatMessage({ id: `app.message.${scannedMessage}` })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(SpecialityBlockInfo))
