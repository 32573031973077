import {
  INSTANT_NOTIF_ADD,
  INSTANT_NOTIF_REMOVE,
  LOGOUT,
  NOTIF_ADD,
  NOTIF_LIST,
  NOTIF_REMOVE
} from '../actions/types'

const MERCURE = {
  instantNotifications: [],
  notifications: []
}

export default (state = MERCURE, action) => {
  const notifs = state.notifications
  const instantNotifs = state.instantNotifications

  switch (action.type) {
    case LOGOUT:
      return {
        instantNotifications: [],
        notifications: []
      }
    case INSTANT_NOTIF_ADD:
      instantNotifs.unshift(action.notification)

      if (action.notification.type === 2) {
        document.querySelector('#blueFlash').style.display = 'block'
        setTimeout(() => {
          document.querySelector('#blueFlash').style.display = 'none'
        }, 100)
      }

      return {
        ...state,
        instantNotifications: instantNotifs
      }
    case INSTANT_NOTIF_REMOVE:
      instantNotifs.splice(action.i, 1)

      return {
        ...state,
        instantNotifications: instantNotifs
      }
    case NOTIF_ADD:
      notifs.unshift(action.notification)

      if (action.notification.type === 2) {
        document.querySelector('#orangeFlash').style.display = 'block'
        setTimeout(() => {
          document.querySelector('#orangeFlash').style.display = 'none'
        }, 100)
      }

      if (action.notification.type === 3) {
        document.querySelector('#redFlash').style.display = 'block'
        setTimeout(() => {
          document.querySelector('#redFlash').style.display = 'none'
        }, 100)
      }

      return {
        ...state,
        notifications: notifs
      }
    case NOTIF_LIST:
      return {
        ...state,
        notifications: action.notifications
      }
    case NOTIF_REMOVE:
      notifs.forEach((re, i) => {
        if (re.uuid === action.id) {
          notifs.splice(i, 1)
        }
      })

      return {
        ...state,
        notifications: notifs
      }
    default:
      break
  }

  return {
    instantNotifications: instantNotifs,
    notifications: notifs
  }
}
