import M from 'materialize-css'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import ContainerFridgeParamsListPart from '../../../components/Parameters/Equipment/ContainerFridgeParamsListPart'
import VaccinationParamsListPart from '../../../components/Parameters/Equipment/VaccinationParamsListPart'

class EquipmentParamsList extends React.Component {
  componentDidMount() {
    M.AutoInit()
  }

  render() {
    return (
      <div className="row Container params">
        <div className="col s12 paramsContent">
          <ul className="tabs">
            <li className="tab col s6">
              <a className="active" href="#container-fridge">
                <FormattedMessage id="app.label.containerAndFridge" />
              </a>
            </li>
            <li className="tab col s6">
              <a href="#vaccination">
                <FormattedMessage id="app.label.vaccination" />
              </a>
            </li>
            <li className="indicator" />
          </ul>
        </div>
        <div id="container-fridge" className="col s12 content marginTop30 active">
          <ContainerFridgeParamsListPart />
        </div>
        <div id="vaccination" className="col s12 content marginTop30">
          <VaccinationParamsListPart />
        </div>
      </div>
    )
  }
}

export default injectIntl(EquipmentParamsList)
