import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { catchError } from '../../../actions'
import icons from '../../../assets/icons/icons'
import { api } from '../../../parameters'
import AsyncLoader from '../../AsyncLoader'
import Filters from '../../Filters/Filters'
import LoadingPage from '../../LoadingPage'
import ValenceContext from '../../../context/ValencesContext'

class IncompatibilityPart extends React.Component {
  constructor() {
    super()
    this.state = {
      contentStatus: false,
      parameter: null,
      specialities: [],
      url: `${api}/api/vaccine_specialities`,
      filters: [
        { category: 'order[name]', sortBy: ['asc', 'desc'], catTranslationPrefix: 'speciality' },
        {
          category: 'valence',
          sortBy: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
          translationPrefix: 'valence'
        },
        {
          category: 'conservationMethod',
          sortBy: [0, 1],
          translationPrefix: 'conservationMethod'
        },
        {
          category: 'administrationMethod',
          sortBy: [0, 1, 2],
          translationPrefix: 'administrationMethod'
        }
      ],
      loading: true
    }
    this.getStateFromChild = this.getStateFromChild.bind(this)
    this.inLoad = this.inLoad.bind(this)
  }

  componentDidMount() {
    const { token } = this.props

    Axios.get(`${api}/api/vaccine_specialities`, {
      headers: {
        Authorization: token
      }
    })
      .then(res => {
        this.setState({
          specialities: res.data['hydra:member'],
          totalItems: res.data['hydra:totalItems'],
          contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult'
        })
      })
      .catch(err => catchError(err.response))

    Axios.get(`${api}/api/vaccination_parameters?paramId=0`, {
      headers: {
        Authorization: token
      }
    })
      .then(res => {
        this.setState({
          parameter: res.data['hydra:member'][0],
          loading: false
        })
      })
      .catch(err => catchError(err.response))
  }

  getStateFromChild(data, infiniteLoader = false) {
    let { specialities, contentStatus } = this.state
    if (infiniteLoader) {
      specialities = [...specialities, ...data['hydra:member']]
    } else {
      specialities = data['hydra:member']
    }

    if (specialities.length >= data['hydra:totalItems']) {
      contentStatus = 'noMoreResult'
    }
    this.setState({
      specialities,
      loading: false,
      contentStatus
    })
  }

  inLoad(contentStatus) {
    this.setState({ contentStatus })
  }

  static contextType = ValenceContext

  render() {
    const { intl, token, role } = this.props
    const { loading, parameter, specialities, url, filters, contentStatus, totalItems } = this.state
    const { getValenceLabelByKey } = this.context

    if (loading === true) {
      return <LoadingPage />
    }

    return (
      <div className="row">
        <div className="row">
          <div className="flex col m12">
            <div>
              <Filters
                token={token}
                setStateToParent={this.getStateFromChild}
                url={url}
                role={role}
                infiniteLoader
                totalItems={totalItems}
                filters={filters}
                inLoad={this.inLoad}
              />
            </div>
          </div>
        </div>
        <div className="row paramListItem paddingLeft1vw">
          <div className="col s12 card paramHeader">
            <p className="col s11 paramTitle">
              {intl.formatMessage({ id: 'app.label.vaccineIncompatibility' })}
            </p>
            <Link className="col s1" to={`/parameters/parameter-vaccination/edit/${parameter.id}`}>
              <img src={icons.blackPen} alt="black pen icon" />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            <table className="table">
              <thead>
                <tr>
                  <th>{intl.formatMessage({ id: 'app.label.speciality' })} </th>
                  <th>{intl.formatMessage({ id: 'app.label.articles' })} </th>
                  <th>{intl.formatMessage({ id: 'app.label.valence' })} </th>
                  <th>{intl.formatMessage({ id: 'app.label.conservation' })} </th>
                  <th>{intl.formatMessage({ id: 'app.label.administration' })} </th>
                  <th colSpan="2">{intl.formatMessage({ id: 'app.label.incompatibilities' })} </th>
                </tr>
              </thead>
              <tbody>
                {specialities.map(specialitity => (
                  <tr key={`speciality-${specialitity.id}`}>
                    <td>{specialitity.name}</td>
                    <td>
                      {specialitity.vaccineArticles &&
                        specialitity.vaccineArticles.map(vaccineArticle => (
                          <div
                            className="noPadding col s12"
                            key={`article-${vaccineArticle.dosesNumber}`}
                          >
                            {vaccineArticle.name}
                          </div>
                        ))}
                    </td>
                    <td>{getValenceLabelByKey(specialitity.valence)}</td>
                    <td>
                      {intl.formatMessage({
                        id: `app.label.conservationMethod.${specialitity.conservationMethod}`
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: `app.administrationMethod.${specialitity.administrationMethod}`
                      })}
                    </td>
                    <td>
                      {specialitity.incompatibilities &&
                        specialitity.incompatibilities.blocking.map(incompatibility => (
                          <div className="noPadding col s12" key={incompatibility.id}>
                            <i className="material-icons pill state-3 noPadding">brightness_1</i>
                            {incompatibility.name}
                          </div>
                        ))}
                      {specialitity.incompatibilities &&
                        specialitity.incompatibilities.nonBlocking.map(incompatibility => (
                          <div className="noPadding col s12" key={incompatibility.id}>
                            <i className="material-icons pill state-2 noPadding">brightness_1</i>
                            {incompatibility.name}
                          </div>
                        ))}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot className="center">
                <tr>
                  <th colSpan="12">
                    <AsyncLoader status={contentStatus} />
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default injectIntl(connect(mapStateToProps)(IncompatibilityPart))
