import React from 'react'
import { injectIntl } from 'react-intl'
import icons from '../../../../../../../../assets/icons/icons'

const BlockHeader = props => {
  const { intl } = props

  return (
    <div className="row card-block">
      <div className="col s6">
        <div className="flex">
          <img
            className="card-logo marginRight15 space5pixel"
            src={icons.productBag}
            alt="logo injectable vaccine"
          />
          <span className="labelCustom">{intl.formatMessage({ id: 'app.label.productBag' })}</span>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(BlockHeader)
