import React from 'react'
import { injectIntl } from 'react-intl'
import Header from './partials/Header'

const WaitedProducts = props => {
  const { intl, products } = props
  const { bottles, diluent, dye, pBags } = products

  return (
    <div className="waitedProducts row custom-card">
      <Header />
      {bottles === 0 ? (
        ''
      ) : (
        <>
          <div className="divider greyDivider" />
          <div className="col s12">
            {bottles} {intl.formatMessage({ id: 'app.label.waitedBottles' })}
          </div>
        </>
      )}
      {Object.values(pBags).length === 0 ? (
        ''
      ) : (
        <>
          <div className="divider greyDivider" />
          <div className="col s12">
            {Object.values(pBags).length} {intl.formatMessage({ id: 'app.label.waitedPBags' })}
          </div>
        </>
      )}
      {diluent === false ? (
        ''
      ) : (
        <>
          <div className="divider greyDivider" />
          <div className="col s12">{intl.formatMessage({ id: 'app.label.waitedDiluentUse' })}</div>
        </>
      )}
      {dye === '' || dye === null ? (
        ''
      ) : (
        <>
          <div className="divider greyDivider" />
          <div className="col s12">{intl.formatMessage({ id: 'app.label.waitedDyeUse' })}</div>
        </>
      )}
    </div>
  )
}

export default injectIntl(WaitedProducts)
