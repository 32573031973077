import React from 'react'
import { injectIntl } from 'react-intl'
import PrepInfos from './PrepInfos'

const OrderInfos = props => {
  const { intl, order, orderType, isCreation } = props
  let title = ''
  let prepLabel = ''
  const melt = []

  switch (orderType) {
    case 0:
      title = intl.formatMessage({ id: 'app.label.injectionOrder' })
      prepLabel = 'app.label.pBags'
      break
    case 1:
      title = intl.formatMessage({ id: 'app.label.thinDropsOrder' })
      prepLabel = 'app.label.cans'
      break
    case 2:
      title = intl.formatMessage({ id: 'app.label.bigDropsOrder' })
      prepLabel = 'app.label.cans'
      break
    default:
  }

  order.vaccines.forEach(vacc => {
    melt.push(
      `${vacc.speciality.name} ${intl.formatMessage({ id: `app.label.doses.${vacc.doseType}` })}`
    )
  })
  return (
    <div className="orderHistory">
      <div className="flex">
        <p className="title">{title}</p>
        {order.soonDeleted === true && <div className="red-tag">Supprimé</div>}
      </div>
      <div className="row line">
        <div className="col s6 bold">{melt.join(' + ')}</div>
        {!isCreation && (
          <div className="col s6">
            {`${order.orderOrderPreparations.length} ${intl.formatMessage({
              id: prepLabel
            })}`}
          </div>
        )}
      </div>
      {!isCreation && (
        <div className="row line">
          {order.orderOrderPreparations.map(prep => (
            <PrepInfos key={prep.id} prep={prep.preparation} />
          ))}
        </div>
      )}
    </div>
  )
}
export default injectIntl(OrderInfos)
