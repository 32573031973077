/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import { Translation } from '../Components'

function Request({ token, location, intl, history }) {
  const [state, setState] = useState({
    loading: true,
    items: null,
    batchNumber: null
  })

  const vaccineTypes = {
    injection: 0,
    spray: 1
  }

  const t = value => {
    const translationKey = 'app.label.order.request.'
    return <Translation id={`${translationKey}${value}`} />
  }

  useEffect(() => {
    const pathName = location.pathname.split('/')
    const id = Number(pathName[pathName.length - 2])
    Axios.get(`${api}/api/order_traceability/${id}/veterinary`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const mapper = (key, i) => {
          const unknownValue = intl.formatMessage({ id: 'app.label.N/A' })
          const steps = i.map(s => {
            return {
              date: s.date ? moment(s.date).format('DD/MM/YYYY') : unknownValue,
              hour: s.date ? moment(s.date).format('HH[h]mm') : unknownValue,
              operator: s.operator ?? unknownValue,
              answerDate: s.answerDate ? moment(s.answerDate).format('DD/MM/YYYY') : unknownValue,
              answerHour: s.answerDate ? moment(s.answerDate).format('HH[h]mm') : unknownValue,
              veterinary: s.veterinary ?? unknownValue,
              chicksConcerned: s.concerned ? t(s.concerned) : unknownValue,
              numberOfChicksConcerned: s.nbChicks ?? unknownValue,
              vaccineSpecialtyToEdit: s.oldSpeciality ?? unknownValue,
              shapsCode: s.shapsCode ?? unknownValue,
              newVaccineToAdd: s.newSpeciality ?? unknownValue,
              requestStatus:
                typeof s.status === 'boolean'
                  ? t(`request_status_${s.status ? 'ok' : 'nok'}`)
                  : unknownValue
            }
          })
          return {
            steps,
            key
          }
        }
        const items = []
        if (res.data['hydra:member'].length !== null) {
          for (const [key, value] of Object.entries(res.data['hydra:member'])) {
            items.push(mapper(key, value))
          }
        }
        if (items.length === 0) {
          items.push({})
        }
        setState({
          loading: false,
          items,
          batchNumber: res.data.currentNumber
        })
      })
      .catch(err => catchError(err))
  }, [])
  return (
    <div className="row Container stockAvailibilityItem">
      <div className="col s12 Content">
        <div className="row pageHeader pageTitle itemHeader">
          <div
            onClick={() => history.goBack()}
            role="button"
            tabIndex="0"
            onKeyPress={() => history.goBack()}
            className="goBackBtn col s1"
          >
            <i className="material-icons">chevron_left</i>
          </div>
          {state.batchNumber !== null && (
            <h5 className="col s12">
              {intl.formatMessage({ id: 'app.title.order' })} {state.batchNumber} -{' '}
              {intl.formatMessage({ id: 'app.title.request' })}
            </h5>
          )}
        </div>
        {!state.loading ? (
          <div className="row">
            {state.items.map(item => (
              <>
                <div>
                  {t('vaccine_edition')} {item.key}
                </div>
                <table className="table" style={{ marginBottom: '16px' }}>
                  <thead>
                    <tr>
                      <th>{t('date')}</th>
                      <th>{t('hour')}</th>
                      <th>{t('operator')}</th>
                      <th>{t('chicks_concerned')}</th>
                      <th>{t('number_of_chicks_concerned')}</th>
                      <th>{t('vaccine_specialty_to_edit')}</th>
                      <th>{t('shaps_code')}</th>
                      <th>{t('new_vaccine_to_add')}</th>
                      <th>{t('request_status')}</th>
                      <th>{t('veterinary')}</th>
                      <th>{t('veterinary_date')}</th>
                      <th>{t('veterinary_hour')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.steps?.map(step => (
                      <tr>
                        <td>{step.date}</td>
                        <td>{step.hour}</td>
                        <td>{step.operator}</td>
                        <td>{step.chicksConcerned}</td>
                        <td>{step.numberOfChicksConcerned}</td>
                        <td>{step.vaccineSpecialtyToEdit}</td>
                        <td>{step.shapsCode}</td>
                        <td>{step.newVaccineToAdd}</td>
                        <td>{step.requestStatus}</td>
                        <td>{step.veterinary}</td>
                        <td>{step.date}</td>
                        <td>{step.hour}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ))}
          </div>
        ) : (
          <LoadingPage />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(Request)))
