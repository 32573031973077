import Axios from 'axios'
import M from 'materialize-css'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { catchError } from '../../../../actions'
import { api } from '../../../../parameters'
import LoadingPage from '../../../LoadingPage'

class dyeForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dyes: [],
      dyesSelected: [],
      batchNumber: null,
      bulbsMove: null,
      loading: true
    }
    this.ampoulesNumberRef = React.createRef()
    this.handleSelect = this.handleSelect.bind(this)
    this.getDyesSelected = this.getDyesSelected.bind(this)
  }

  componentDidMount() {
    const { donorId, token } = this.props
    Axios.get(`${api}/api/fridge_dyes?fridge.id=${donorId}`, {
      headers: { Authorization: token }
    })
      .then(res =>
        this.setState({ dyes: res.data['hydra:member'], loading: false }, () => M.AutoInit())
      )
      .catch(err => catchError(err.response))
    this.getDyesSelected()
  }

  componentDidUpdate(prevProps, prevState) {
    const { setDisabled, setDyeDisabled } = this.props
    const { batchNumber, bulbsMove } = this.state
    if (prevState.batchNumber !== batchNumber || prevState.bulbsMove !== bulbsMove) {
      if (batchNumber !== null && bulbsMove !== null && bulbsMove !== '') {
        setDisabled(false)
        setDyeDisabled(false)
      } else {
        setDisabled(true)
        setDyeDisabled(true)
      }
    }
  }

  getDyesSelected() {
    const { dyes, index } = this.props
    const dyesSelected = []
    dyes.forEach(dye => {
      if (dye.id !== undefined && dyesSelected[index] === undefined) dyesSelected[index] = dye.id
    })
    this.setState({ dyesSelected })
  }

  handleSelect(e) {
    const { name, value } = e.target
    const { token, index, setDisabled, itemsToMove, setDyeDisabled } = this.props
    const { batchId, batchNumber, bulbsMove } = this.state
    switch (name) {
      case `batchNumber${index}`:
        this.setState({ batchNumber: value })
        setDisabled(true)
        this.ampoulesNumberRef.current.value = ''
        Axios.get(`${api}/api/fridge_dyes/${value}`, {
          headers: { Authorization: token }
        })
          .then(res => this.setState({ dyeSelected: res.data, batchId: value }, () => M.AutoInit()))
          .catch(err => catchError(err.response))
        this.getDyesSelected()
        break
      case `bulbsMove${index}`:
        this.setState({ bulbsMove: value })
        if (batchNumber !== null || bulbsMove !== null) {
          setDisabled(false)
          setDyeDisabled(false)
        } else {
          setDisabled(true)
          setDyeDisabled(true)
        }
        itemsToMove('dye', index, e, batchId)
        break
      default:
        break
    }
  }

  render() {
    const { index, intl, disabledDyesForm } = this.props
    const { dyes, dyeSelected, loading, dyesSelected } = this.state
    if (loading) {
      return (
        <div className="row">
          <div className="col s12">
            <LoadingPage />
          </div>
        </div>
      )
    }
    return (
      <div className="row">
        <div className="col s12">
          <h5>
            {intl.formatMessage({ id: 'app.label.dye' })} n°{index + 1}
          </h5>
          <div className="row">
            <div className="col m6 input">
              <label className="active" htmlFor={`batchNumber${index}`}>
                {intl.formatMessage({ id: 'app.label.batchesNumber' })}
              </label>
              <select
                className="select-wrapper"
                defaultValue="0"
                onChange={this.handleSelect}
                name={`batchNumber${index}`}
                id={`batchNumber${index}`}
                disabled={disabledDyesForm[index]}
              >
                <option value="0" />
                {dyes.map(
                  dye =>
                    dyesSelected.indexOf(dye.id) === -1 && (
                      <option key={dye.dyeBatch.batchNumber} value={dye.id}>
                        {dye.dyeBatch.batchNumber} -{' '}
                        {intl.formatMessage({
                          id: `app.color.${dye.dyeBatch.color}`
                        })}
                      </option>
                    )
                )}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col m3 input">
              <label htmlFor={`provider${index}`}>
                {intl.formatMessage({ id: 'app.label.provider' })}
              </label>
              <input
                disabled
                value={dyeSelected ? dyeSelected.dyeBatch.provider : ''}
                id={`provider${index}`}
                name={`provider${index}`}
                type="text"
              />
            </div>
            <div className="col m3 input">
              <label htmlFor={`expiration${index}`}>
                {intl.formatMessage({ id: 'app.label.expirationDate' })}
              </label>
              <input
                disabled
                value={
                  dyeSelected
                    ? moment(dyeSelected.dyeBatch.expirationDate).format('DD/MM/YYYY')
                    : ''
                }
                id={`expiration${index}`}
                name={`expiration${index}`}
                type="text"
              />
            </div>
            <div className="col m3 input">
              <label htmlFor={`unitNumber${index}`}>
                {intl.formatMessage({ id: 'app.label.bulbsNumber' })}
              </label>
              <input
                disabled
                value={dyeSelected ? dyeSelected.vialsNumber : ''}
                id={`unitNumber${index}`}
                name={`unitNumber${index}`}
                type="text"
              />
            </div>
            <div className="col m3 input">
              <label htmlFor={`bulbsMove${index}`}>
                {intl.formatMessage({ id: 'app.label.bulbsMove' })}
              </label>
              <input
                id={`bulbsMove${index}`}
                name={`bulbsMove${index}`}
                ref={this.ampoulesNumberRef}
                type="number"
                onChange={this.handleSelect}
                disabled={disabledDyesForm[index]}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(dyeForm)
