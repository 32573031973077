import React from 'react'
import { injectIntl } from 'react-intl'

const ClientsInformation = props => {
  const { intl, handleChange } = props
  return (
    <div>
      <div className="row">
        <div className="col s12">
          <p className="greyTitle">{intl.formatMessage({ id: 'app.label.clientInformations' })}</p>
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <div className="input col s6">
            <label htmlFor="clientName" className="labelParams">
              {intl.formatMessage({ id: 'app.label.clientName' })}*
            </label>
            <input name="clientName" type="text" onChange={handleChange} required />
          </div>
          <div className="input col s6">
            <label htmlFor="farmer" className="labelParams">
              {intl.formatMessage({ id: 'app.label.farmer' })}*
            </label>
            <input name="farmer" type="text" onChange={handleChange} required />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <div className="input col s6">
            <label htmlFor="address" className="labelParams">
              {intl.formatMessage({ id: 'app.label.address' })}
            </label>
            <textarea
              className="materialize-textarea"
              name="address"
              type="text"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(ClientsInformation)
