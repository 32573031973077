import React from 'react'
import { injectIntl } from 'react-intl'
import CardHeader from './partials/CardHeader'
import SmallPrepInfos from './partials/SmallPrepInfos'

const AddPrep = props => {
  const { hist, intl } = props
  const { action: data } = hist.history
  const { preps, reuses } = data

  return (
    <div className="vaccinationHistory">
      <div className="row historyCard">
        <CardHeader date={hist.createdAt} operator={hist.firstName} />
        <div className="col s12 card" key={data.containerNumber}>
          <div className="row line">
            <div className="col s6">
              <span className="bold">{intl.formatMessage({ id: 'app.label.orderNumber' })}: </span>{' '}
              {hist.history.orderNumber}
            </div>
          </div>
          <div className="divider greyDivider" />
          <div className="row line">
            <div className="col s12">
              <span className="bold">{intl.formatMessage({ id: 'app.label.addedPreps' })}: </span>
              {preps.length}
            </div>
          </div>

          <div className="col s12">
            {preps.map(prep => (
              <SmallPrepInfos key={prep.id} prep={prep} />
            ))}
          </div>

          <div className="divider greyDivider" />
          <div className="row line">
            <div className="col s12">
              <span className="bold">{intl.formatMessage({ id: 'app.label.reusedPreps' })}: </span>
              {reuses.length}
            </div>
          </div>

          <div className="col s12">
            {reuses.map(reuse => (
              <div className="col s4 prepHistory">{reuse[0]}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
export default injectIntl(AddPrep)
