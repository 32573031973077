import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { catchError } from '../../../../../actions'
import ShapsCodesContext from '../../../../../context/ShapsCodesContext'
import { administrations, api } from '../../../../../parameters'
import VaccineForm from './partials/VaccineForm'

class OrderInformations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleChange = this.handleChange.bind(this)
    this.addVaccine = this.addVaccine.bind(this)
    this.getDataVaccineFromChild = this.getDataVaccineFromChild.bind(this)
    this.removeOrder = this.removeOrder.bind(this)
    this.initShapsCodes = this.initShapsCodes.bind(this)
  }

  componentDidMount() {
    const { token } = this.props
    Axios.get(`${api}/api/vaccine_specialities`, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({ specialities: res.data['hydra:member'] })
        M.FormSelect.init(document.querySelectorAll('select'))
      })
      .catch(err => catchError(err))
  }

  componentDidUpdate() {
    M.FormSelect.init(document.querySelectorAll('select'))
  }

  getDataVaccineFromChild(name, value, index) {
    const { getDataFromChild, orders, orderIndex } = this.props

    if (name === 'speciality') {
      orders[orderIndex].vaccines[index].speciality = value
    } else if (name === 'doseType') {
      orders[orderIndex].vaccines[index].doseType = parseInt(value)
    }
    getDataFromChild('orders', orders)
  }

  static contextType = ShapsCodesContext

  handleChange(e) {
    const { name, value } = e.target
    const { getDataFromChild, orderIndex, orders } = this.props

    if (name === 'administration') {
      orders[orderIndex].administration = value
      this.initShapsCodes(value)
      this.setState({ selectShaps: true })
    } else if (name === 'shapsCode') {
      orders[orderIndex].shapsCode = value
    }

    getDataFromChild('orders', orders)

    this.setState({}, () => M.FormSelect.init(document.querySelectorAll('select')))
  }

  initShapsCodes(administration) {
    const { shapsCodes } = this.context
    this.setState({
      selectCodes: shapsCodes.filter(
        shapsCode => shapsCode.administrationMethod === parseInt(administration)
      )
    })
  }

  addVaccine() {
    const { getDataFromChild, orders, orderIndex } = this.props

    orders[orderIndex].vaccines.push({
      id: orders[orderIndex].vaccines.length,
      speciality: '',
      doseType: ''
    })
    getDataFromChild('orders', orders)

    this.setState({}, () => M.FormSelect.init(document.querySelectorAll('select')))
  }

  removeOrder(index) {
    const { orders, getDataFromChild, orderIndex } = this.props

    orders[orderIndex].vaccines.splice(index, 1)
    getDataFromChild('orders', orders)

    this.setState({}, () => M.FormSelect.init(document.querySelectorAll('select')))
  }

  render() {
    const { intl, orderIndex, orders } = this.props
    const { administration, selectCodes, selectShaps, specialities } = this.state

    return (
      <div className="row card-order">
        <div className="col s12">
          <div className="row">
            <div className="input col s6">
              <label htmlFor="administration" className="labelParams">
                {intl.formatMessage({ id: 'app.filter.administrationMethod' })}*
              </label>
              <select
                className="select-wrapper"
                name="administration"
                defaultValue="5"
                onChange={this.handleChange}
                required
              >
                <option value="5" disabled>
                  {intl.formatMessage({ id: 'app.label.selectAdministrationMethod' })}
                </option>
                {administrations &&
                  administrations.map((administrationToSelect, i) => (
                    <option key={i} value={administrationToSelect.name}>
                      {intl.formatMessage({
                        id: `app.administrationMethod.${administrationToSelect.name}`
                      })}
                    </option>
                  ))}
              </select>
            </div>
            <div className="input col s6">
              <label htmlFor="shapsCode" className="labelParams">
                {intl.formatMessage({ id: 'app.label.shapsCode' })}*
              </label>
              <select
                className="select-wrapper"
                name="shapsCode"
                defaultValue="0"
                onChange={this.handleChange}
                required
                disabled={selectShaps !== true}
              >
                <option value="0" disabled>
                  {intl.formatMessage({ id: 'app.label.selectShapsCode' })}
                </option>
                {selectCodes &&
                  selectCodes.map(selectCode => (
                    <option key={selectCode.name} value={selectCode.name}>
                      {selectCode.displayName ? selectCode.displayName : selectCode.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          {orders &&
            orders[orderIndex].vaccines.map((vaccine, index) => (
              <VaccineForm
                key={index}
                administration={administration}
                specialities={specialities}
                removeOrder={this.removeOrder}
                index={index}
                orders={orders}
                orderIndex={orderIndex}
                getDataVaccineFromChild={this.getDataVaccineFromChild}
              />
            ))}
          <div className="row">
            <div className="col s12">
              <span className="col s6">
                <span
                  className="col s12 blue-text btnAddArticle"
                  role="button"
                  onClick={this.addVaccine}
                  onKeyPress={this.addVaccine}
                  tabIndex={0}
                >
                  <span className="plusButtonArticle pointer">+</span>
                  <span className="textButtonArticle pointer">
                    {intl.formatMessage({ id: 'app.label.addVaccine' })}
                  </span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(OrderInformations))
