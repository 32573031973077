import M from 'materialize-css'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { i18n } from '../parameters'

class DatepickerBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = { dateSelected: props.dateSelected }
  }

  componentDidMount() {
    const { setDataFromChild, name, number } = this.props

    if (name === 'hatchingDate') {
      M.Datepicker.init(document.getElementById(name), {
        autoClose: true,
        selectMonth: true,
        format: 'dd/mm/yyyy',
        firstDay: 1,
        i18n,
        onSelect: date => {
          this.setState({ dateSelected: moment(date).format('DD/MM/YYYY') })
          setDataFromChild('dateSelected', moment(date).format())
          setDataFromChild('dateSelectedWeek', moment(date).format('GGGG_W'))
        }
      })
    } else if (number) {
      M.Datepicker.init(document.getElementById(name), {
        autoClose: true,
        format: 'dd/mm/yyyy',
        selectMonth: true,
        firstDay: 1,
        i18n,
        onSelect: date => setDataFromChild(`dateSelected${number}`, date)
      })
    } else if (name === 'lastControl' || name === 'askedDate') {
      M.Datepicker.init(document.getElementById(name), {
        autoClose: true,
        format: 'dd/mm/yyyy',
        selectMonth: true,
        setDefaultDate: name === 'lastControl',
        defaultDate: new Date(moment().format()),
        firstDay: 1,
        i18n,
        onSelect: date => setDataFromChild(name, date)
      })
    } else if (name === 'receptionDate') {
      const { getDataFromChild } = this.props
      M.Datepicker.init(document.getElementById(name), {
        autoClose: true,
        format: 'dd/mm/yyyy',
        selectMonth: true,
        setDefaultDate: name === 'lastControl',
        defaultDate: new Date(moment().format()),
        firstDay: 1,
        i18n,
        onSelect: date => {
          getDataFromChild(name, true)
          setDataFromChild(name, date)
        }
      })
    } else {
      M.Datepicker.init(document.getElementById(name), {
        autoClose: true,
        format: 'dd/mm/yyyy',
        selectMonth: true,
        firstDay: 1,
        i18n,
        onSelect: date => {
          this.setState({ dateSelected: moment(date).format('DD/MM/YYYY') })
          setDataFromChild('dateSelected', date)
        }
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { minDate, number, name, setDataFromChild } = this.props

    if (prevProps.minDate !== minDate && number) {
      M.Datepicker.init(document.getElementById(name), {
        autoClose: true,
        format: 'dd/mm/yyyy',
        selectMonth: true,
        firstDay: 1,
        minDate,
        i18n,
        onSelect: date => setDataFromChild(`dateSelected${number}`, date)
      })
    }
  }

  render() {
    const { customClass, defaultValue, intl, label, name, required, disabled, value } = this.props
    const { dateSelected } = this.state
    let defaultDate = moment()

    if (defaultValue !== true) {
      defaultDate = defaultValue
    }

    return (
      <div className="input">
        <label htmlFor={name}>
          {label}
          {required && '*'}
        </label>

        <input
          type="text"
          className={`datepicker ${customClass && customClass}`}
          id={name}
          name={name}
          value={
            (dateSelected && dateSelected) ||
            (value && value) ||
            (defaultValue ? defaultDate.format('DD/MM/YYYY') : '')
          }
          placeholder={intl.formatMessage({ id: 'app.label.dateFormat' })}
          disabled={disabled}
          readOnly
          required
        />
      </div>
    )
  }
}

export default injectIntl(DatepickerBlock)
