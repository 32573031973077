import React, { useContext } from 'react'
import hatcheryLogo from '../../../../../assets/images/hatchery_logo.png'
import HatcheryContext from '../../../../../context/HatcheryContext'
import HubbardLogo from '../../../../../assets/images/hubbard_logo.png'

const HatcheryInfos = () => {
  const { hatchery } = useContext(HatcheryContext)
  const aviagen = process.env.REACT_APP_COMPANY === 'aviagen'
  return (
    <div id="hatcheryInfos">
      <img
        className="hatcheryLogo"
        src={aviagen ? hatcheryLogo : HubbardLogo}
        alt="hatchery logo"
      />
      <div className="address">
        {hatchery.address.map((line, i) => (
          <div key={i}>
            {line}
            <br />
          </div>
        ))}
      </div>
    </div>
  )
}

export default HatcheryInfos
