import React from 'react'
import { injectIntl } from 'react-intl'

const DamagedBagPb = props => {
  const { changes, intl } = props

  return (
    <>
      <div className="col s6">
        <span className="bold">{intl.formatMessage({ id: 'app.label.newProductBag' })}: </span>
        {changes.newPBag}
      </div>
      <div className="col s6">
        <span className="bold">{intl.formatMessage({ id: 'app.label.oldProductBag' })}: </span>
        {changes.oldPBag}
      </div>
    </>
  )
}
export default injectIntl(DamagedBagPb)
