import React from 'react'
import { injectIntl } from 'react-intl'

const Footer = props => {
  const { intl } = props

  return (
    <footer id="footer" className="row">
      <span id="footerLinks">
        <a href="https://privacy.passeportpoussins.fr" target="_blank" rel="noreferrer">
          {intl.formatMessage({ id: 'app.footer.privacy' })}
        </a>
      </span>
      <span id="trademarkDisclamer">
        {intl.formatMessage({ id: 'app.footer.trademarkDisclamer' })}
      </span>
    </footer>
  )
}

export default injectIntl(Footer)
