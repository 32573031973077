import Axios from 'axios'
import emailValidator from 'email-validator'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Swal from 'sweetalert2'
import { catchError } from '../../actions'
import LoadingPage from '../../components/LoadingPage'
import rolesAccess from '../../config/userRoles.json'
import { api, roles } from '../../parameters'

class UserCRUD extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {
        firstname: '',
        lastname: '',
        pin: '',
        email: props.email,
        roles: []
      },
      loading: true
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.goBack = this.goBack.bind(this)
  }

  componentDidMount() {
    M.AutoInit()
    const { match, token } = this.props
    const { user } = this.state
    if (match.params.id) {
      Axios.get(`${api}/api/users/${match.params.id}`, {
        headers: { Authorization: token }
      })
        .then(res => {
          const { data } = res
          this.setState({ user: data, loading: false })
        })
        .catch(err => catchError(err.response))
    } else {
      this.setState({ user: { ...user, roles: roles[0].role }, loading: false })
    }
  }

  componentDidUpdate() {
    M.AutoInit()
  }

  handleChange(e) {
    const { name, value } = e.target
    const { user } = this.state
    const { email } = this.props
    const { ROLE_OPERATOR } = rolesAccess

    if (name === 'roles') {
      if (roles === ROLE_OPERATOR) {
        return this.setState({ user: { ...user, email, roles: [value] } })
      }
      return this.setState({ user: { ...user, roles: [value] } })
    }
    return this.setState({ user: { ...user, [name]: value } })
  }

  async handleSubmit() {
    const { user } = this.state
    const { token, match, intl } = this.props
    let title = ''
    if (user.pin.length === 4 && emailValidator.validate(user.email)) {
      try {
        if (match.params.id) {
          await Axios.put(`${api}/api/users/${match.params.id}`, user, {
            headers: {
              Authorization: token
            }
          })
          title = intl.formatMessage({ id: 'app.label.userUpdate' })
        } else {
          await Axios.post(`${api}/api/users`, user, {
            headers: {
              Authorization: token
            }
          })
          title = intl.formatMessage({ id: 'app.label.userCreated' })
        }
        Swal.fire({
          type: 'success',
          title,
          text: `${intl.formatMessage({ id: `app.${user.roles[0]}` })} - ${user.firstname}
        ${user.lastname}`,
          timer: 3000,
          customClass: {
            icon: 'swalSuccessIcon',
            title: 'swalTitle',
            content: 'swalContentText',
            confirmButton: 'blueBtn'
          }
        }).then(() => this.goBack())
      } catch (err) {
        catchError(err.response)
      }
    } else {
      const messages = []
      if (user.pin.length !== 4) {
        messages.push(intl.formatMessage({ id: 'app.alert.pinTooShort' }))
      }
      if (emailValidator.validate(user.email) === false) {
        messages.push(intl.formatMessage({ id: 'app.alert.emailIncorrect' }))
      }

      let messagesString = ''
      messages.forEach(message => {
        messagesString += `${message} <br/>`
      })

      Swal.fire({
        type: 'error',
        title: intl.formatMessage({ id: 'app.alert.error' }),
        html: messagesString,
        customClass: {
          icon: 'swalErrorIcon',
          title: 'swalTitle',
          content: 'swalContentText',
          confirmButton: 'blueBtn'
        }
      })
    }
  }

  handleDelete(id) {
    const { token, history, intl } = this.props
    Swal.fire({
      title: 'Êtes-vous sûr ?',
      text: "L'utilisateur une fois supprimé, ne pourra pas être récupéré",
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: intl.formatMessage({ id: 'app.label.cancel' }),
      customClass: {
        icon: 'swalWarningIcon',
        title: 'swalTitle',
        content: 'swalContentText',
        confirmButton: 'blueBtn',
        cancelButton: 'blueBtn'
      }
    }).then(result => {
      if (result.value) {
        Axios.delete(`${api}/api/users/${id}`, {
          headers: {
            Authorization: token
          }
        })
          .then(() => {
            Swal.fire({
              title: intl.formatMessage({ id: 'app.label.removed' }),
              text: "L'utilisateur a été supprimé avec succès",
              type: 'success',
              showCancelButton: false,
              customClass: {
                icon: 'swalSuccessIcon',
                title: 'swalTitle',
                content: 'swalContentText',
                confirmButton: 'blueBtn'
              }
            })
            history.push('/users')
          })
          .catch(err => catchError(err.response))
      }
    })
  }

  goBack() {
    const { history, intl } = this.props
    history.push({
      pathname: '/users',
      state: { name: intl.formatMessage({ id: `app.part.user` }), icon: 'person' }
    })
  }

  render() {
    const { user, loading } = this.state
    const { match, intl } = this.props
    const { ROLE_SUPER_ADMIN } = roles

    return (
      <div className="userEdit Container">
        {loading ? (
          <LoadingPage />
        ) : (
          <div className="row">
            <div className="col s12">
              <div className="row ">
                <div className="col offset-m1 m10">
                  <div className="pageTitle pageHeader">
                    <h5>
                      {match.params.id
                        ? intl.formatMessage({ id: `app.label.editUser` })
                        : intl.formatMessage({ id: `app.label.addUser` })}
                    </h5>

                    <div
                      className="goBackBtn"
                      role="button"
                      onClick={this.goBack}
                      onKeyPress={this.goBack}
                      tabIndex="0"
                    >
                      <i className="material-icons">close</i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=" col offset-s1 s10">
                  <div className="form">
                    <div className="row">
                      <div className="input col offset-m1 m5">
                        <label htmlFor="first_name">
                          {intl.formatMessage({ id: 'app.user.firstname' })}
                        </label>
                        <input
                          id="first_name"
                          type="text"
                          name="firstname"
                          value={user.firstname ? user.firstname : ''}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      {user.roles[0] === ROLE_SUPER_ADMIN ? (
                        <div className="input col m5">
                          <label htmlFor="role">
                            {intl.formatMessage({ id: 'app.user.userType' })}
                          </label>
                          <input
                            disabled
                            value={intl.formatMessage({ id: `app.${user.roles[0]}` })}
                            id="role"
                            type="text"
                          />
                        </div>
                      ) : (
                        <div className="input col m5">
                          <label htmlFor="user_role">
                            {intl.formatMessage({ id: 'app.user.userType' })}
                          </label>
                          <select
                            className="select-wrapper"
                            id="user_role"
                            name="roles"
                            defaultValue={user.roles[0] || 'select'}
                            onChange={this.handleChange}
                          >
                            <option value="select">
                              {intl.formatMessage({ id: 'app.label.select' })}
                            </option>
                            {roles.map(item => (
                              <option value={item.role} key={item.role}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="input col offset-m1 m5">
                        <label htmlFor="last_name">
                          {intl.formatMessage({ id: 'app.user.lastname' })}
                        </label>
                        <input
                          id="last_name"
                          type="text"
                          name="lastname"
                          value={user.lastname ? user.lastname : ''}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="input col m5">
                        <label htmlFor="pin">{intl.formatMessage({ id: 'app.user.pin' })}</label>
                        <input
                          id="pin"
                          type="number"
                          name="pin"
                          pattern="^-?[0-9]\d*\.?\d*$"
                          value={user.pin}
                          onChange={this.handleChange}
                          maxLength="4"
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="input col offset-m1 m10">
                        <label htmlFor="email">
                          {intl.formatMessage({ id: 'app.user.email' })}
                        </label>
                        <input
                          id="email"
                          type="email"
                          className="validate"
                          name="email"
                          value={user.email}
                          onChange={this.handleChange}
                          required
                        />
                        <span
                          className="helper-text"
                          data-error="Email incorrect"
                          data-success="Email correct"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="buttons">
                <div role="button">
                  {match.params.id && (
                    <button
                      type="button"
                      className="btn redBtn"
                      onClick={() => this.handleDelete(match.params.id)}
                    >
                      {intl.formatMessage({ id: 'app.label.removeUser' })}
                    </button>
                  )}
                  <button type="submit" className="btn blueBtn" onClick={this.handleSubmit}>
                    {intl.formatMessage({ id: 'app.label.save' })}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    email: state.auth.email
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(UserCRUD)))
