import React from 'react'
import { injectIntl } from 'react-intl'
import CardHeader from './partials/CardHeader'

const EditPrepVaccines = props => {
  const { hist, intl } = props
  const { action: data } = hist.history

  return (
    <div className="vaccinationHistory">
      <div className="row historyCard">
        <CardHeader date={hist.createdAt} operator={hist.firstName} />
        <div className="col s12 card" key={data.containerNumber}>
          <div className="row line">
            <div className="col s12">
              <span className="bold">{intl.formatMessage({ id: 'app.label.orderNumber' })}: </span>{' '}
              {hist.history.orderNumber}
            </div>
          </div>
          <div className="row line">
            <div className="col s12">
              <span className="bold">{intl.formatMessage({ id: 'app.label.newShapsCode' })}: </span>{' '}
              {data.shapsCode}
            </div>
          </div>
          {data.changes.map(change => (
            <div key={change.id} className="row line">
              <p className="col s6">
                <span className="bold">{intl.formatMessage({ id: 'app.label.oldVaccine' })}: </span>{' '}
                {change.old}
              </p>
              <p className="col s6">
                <span className="bold">{intl.formatMessage({ id: 'app.label.newVaccine' })}: </span>{' '}
                {change.new}
              </p>
            </div>
          ))}
          {hist.type === 15 && (
            <div className="row line">
              <p className="col offset-s5 s2 bold red-text">
                {intl.formatMessage({ id: 'app.word.declined' })}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default injectIntl(EditPrepVaccines)
