import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { injectIntl } from 'react-intl'
import PrestationsContext from '../../../../../../context/PrestationsContext'

const PrestationsCheckBoxes = props => {
  const { onClick, prestations, gender } = props
  const { getPrestationLabelByKey } = useContext(PrestationsContext)
  return (
    <div className="prestationsList form">
      {prestations.map(presta => (
        <label className="col s3" key={presta}>
          <input
            type="checkbox"
            className="filled-in checkbox"
            name={gender}
            value={presta}
            onClick={onClick}
          />
          <span>{getPrestationLabelByKey(presta)}</span>
        </label>
      ))}
    </div>
  )
}

export default injectIntl(PrestationsCheckBoxes)

PrestationsCheckBoxes.propTypes = {
  onClick: PropTypes.func.isRequired
}
