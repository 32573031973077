import React from 'react'
import { injectIntl } from 'react-intl'

const SharedPreps = props => {
  const { intl, orderNumber, sharedPreps } = props

  return (
    <div className="sharedPreps row custom-card">
      <div className="sharedPrepsHeader">
        <div>{intl.formatMessage({ id: 'app.label.sharedPreps' })}</div>
      </div>
      {sharedPreps[1] === undefined
        ? ''
        : Object.keys(sharedPreps[1]).map(number => (
            <>
              <div className="divider greyDivider" />
              <div className="col s12">
                {sharedPreps[1][number].partage}{' '}
                {intl.formatMessage({ id: 'app.label.sharedPBagsWith' })} {number}{' '}
                {number === orderNumber
                  ? `(${intl.formatMessage({ id: 'app.label.sameOrderOtherProduct' })})`
                  : ''}
              </div>
            </>
          ))}
      {sharedPreps[2] === undefined
        ? ''
        : Object.keys(sharedPreps[2]).map(number => (
            <>
              <div className="divider greyDivider" />
              <div className="col s12">
                {sharedPreps[2][number].partage}{' '}
                {intl.formatMessage({ id: 'app.label.sharedBottlesWith' })} {number}{' '}
                {number === orderNumber
                  ? `(${intl.formatMessage({ id: 'app.label.sameOrderOtherProduct' })})`
                  : ''}
              </div>
            </>
          ))}
    </div>
  )
}

export default injectIntl(SharedPreps)
