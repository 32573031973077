import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'

class Notification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hatchRespAlerts: false,
      vetAlerts: false,
      hatchingRespAlerts: false,
      operatorAlerts: false
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const { parameters } = this.props
    const { channels } = parameters

    this.setState({ channels })

    if (channels) {
      channels.forEach(channel => {
        this.setState({ [channel]: true })
      })
    }

    M.AutoInit()
  }

  handleChange(e) {
    const { setDataToParent } = this.props
    const { channels } = this.state
    const { name, checked } = e.target

    const channelsToSend = channels || []

    this.setState({ [name]: checked })
    if (checked) channelsToSend.push(name)
    else {
      const index = channelsToSend.indexOf(name)
      channelsToSend.splice(index, 1)
    }

    this.setState({ channels: channelsToSend })

    setDataToParent('channels', channelsToSend)
  }

  render() {
    const { intl } = this.props
    const { hatchRespAlerts, vetAlerts, hatchingRespAlerts, operatorAlerts } = this.state
    return (
      <div>
        <div className="row">
          <div className="col s12">
            <p className="greyTitle">{intl.formatMessage({ id: 'app.label.notification' })}</p>
          </div>
        </div>
        <div className="row">
          <div className="col s12 input">
            <div className="row">
              <div className="input col s3">
                <label htmlFor="sendNotifTo" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.sendNotifTo' })}
                  &nbsp;:
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label className="col s6">
                  <input
                    type="checkbox"
                    className="filled-in checkbox"
                    name="hatchRespAlerts"
                    checked={hatchRespAlerts}
                    onClick={this.handleChange}
                    readOnly
                  />
                  <span>{intl.formatMessage({ id: 'app.label.hatchResp' })}</span>
                </label>
                <label className="col s6">
                  <input
                    type="checkbox"
                    className="filled-in checkbox"
                    name="vetAlerts"
                    checked={vetAlerts}
                    onClick={this.handleChange}
                    readOnly
                  />
                  <span>{intl.formatMessage({ id: 'app.label.veterinary' })}</span>
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label className="col s6">
                  <input
                    type="checkbox"
                    className="filled-in checkbox"
                    name="hatchingRespAlerts"
                    checked={hatchingRespAlerts}
                    onClick={this.handleChange}
                    readOnly
                  />
                  <span>{intl.formatMessage({ id: 'app.label.hatchingResp' })}</span>
                </label>
                <label className="col s6">
                  <input
                    name="operatorAlerts"
                    type="checkbox"
                    className="filled-in checkbox"
                    checked={operatorAlerts}
                    onClick={this.handleChange}
                    readOnly
                  />
                  <span>{intl.formatMessage({ id: 'app.label.operator' })}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(Notification)
