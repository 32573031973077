import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import M from 'materialize-css'
import Axios from 'axios'
import { api } from '../../../../parameters'
import { catchError } from '../../../../actions'
import { validatedAction } from '../../../globalAction'

class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    M.AutoInit()
    this.handleSubmitModal = this.handleSubmitModal.bind(this)
    this.handlechange = this.handlechange.bind(this)
  }

  handlechange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleSubmitModal() {
    const { id, token } = this.props
    const { articleName, dosesNumber } = this.state

    const formData = {
      name: articleName,
      dosesNumber: parseInt(dosesNumber),
      speciality: `${api}/api/vaccine_specialities/${id}`
    }

    Axios.post(`${api}/api/vaccine_articles`, formData, { headers: { Authorization: token } })
      .then(() => {
        validatedAction('', 0)
      })
      .catch(err => catchError(err.response))
  }

  render() {
    const { intl } = this.props
    return (
      <div>
        <div id="modalAddArticle" className="modal">
          <div className="modal-content">
            <div className="row">
              <div className="col s12">
                <h2 className="swal2-title swalTitle">
                  {intl.formatMessage({ id: 'app.label.addAnArticle' })}
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <form>
                  <div className="form noCard">
                    <div className="row">
                      <div className="col s12 input">
                        <label htmlFor="articleName" className="labelParams">
                          {intl.formatMessage({ id: 'app.label.articleName' })}
                          <input
                            name="articleName"
                            type="text"
                            onChange={this.handlechange}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col s12 input">
                        <label htmlFor="dosesNumber" className="labelParams">
                          {intl.formatMessage({ id: 'app.label.dosesNumber' })}
                          <input
                            name="dosesNumber"
                            type="number"
                            onChange={this.handlechange}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col s12 btnSwalAddArticle">
                        <button
                          className="modal-close btn blueBtn"
                          type="button"
                          onClick={this.handleSubmitModal}
                        >
                          {intl.formatMessage({ id: 'app.button.validate' })}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(Modal))
