import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import Swal from 'sweetalert2'
import { catchError } from '../../../actions'
import icons from '../../../assets/icons/icons'
import { api } from '../../../parameters'
import { validatedAction } from '../../globalAction'
import LoadingPage from '../../LoadingPage'
import NoVaccineApplicator from './partials/NoVaccineApplicator'

class InjectionForm extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      toControl: false,
      newNeedles: false,
      needlesType: 20,
      volumeNeedle: false,
      rinsePrimeMachines: false,
      needles: ['null']
    }
    this.handleChange = this.handleChange.bind(this)
    this.needleChange = this.needleChange.bind(this)
    this.loadNeedles = this.loadNeedles.bind(this)
  }

  componentDidMount() {
    const { token } = this.props

    Axios.get(`${api}/api/equipment_parameters?paramId=7`, {
      headers: { Authorization: token }
    })
      .then(result => {
        this.setState({
          paramId: result.data['hydra:member'][0].id
        })
      })
      .catch(err => catchError(err.response))
    this.loadNeedles()
  }

  loadNeedles() {
    const { isControl, token } = this.props

    Axios.get(`${api}/api/vaccine_applicators?machine=0`, {
      headers: { Authorization: token }
    }).then(result => {
      if (result.data['hydra:member'].length !== 0)
        this.setState({ needles: result.data['hydra:member'] })

      Axios.get(`${api}/api/vaccination_machines/last_control/Injection`, {
        headers: { Authorization: token }
      })
        .then(res => {
          const { date } = res.data

          if (moment(date).format('WW') !== moment().format('WW')) {
            this.setState({ toControl: true })
          } else {
            isControl(0)
          }

          this.setState({ loading: false })
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.setState({ toControl: true })
          }
        })
      this.setState({ loading: false })
    })
  }

  handleChange(e) {
    const { checked, name, value, type } = e.target
    const { intl } = this.props
    if (type === 'checkbox') {
      if (!checked) {
        Swal.fire({
          type: 'error',
          title: intl.formatMessage({ id: 'app.alert.error' }),
          text: intl.formatMessage({ id: `app.alert.${name}` }),
          customClass: {
            icon: 'swalErrorIcon',
            title: 'swalTitle',
            content: 'swalContentText',
            confirmButton: 'blueBtn'
          }
        })
      }
      return this.setState({ [name]: checked })
    }
    return this.setState({ [name]: value })
  }

  needleChange(i, e) {
    const { name, value, id } = e.target
    const { needles } = this.state
    const needle = {
      id: parseInt(id),
      name,
      volume: parseFloat(value)
    }
    needles[i] = needle
    this.setState({ needles })
  }

  handleSubmit(e) {
    e.preventDefault()
    const { token, isControl } = this.props
    const { newNeedles, rinsePrimeMachines, needlesType, needles } = this.state

    const data = {
      control: {
        name: 'Injection',
        newNeedles,
        rinsePrimeMachines,
        needlesType,
        needles
      }
    }

    Axios.post(`${api}/api/vaccination_machines/controls/injection`, data, {
      headers: { Authorization: token }
    })
      .then(() => {
        this.loadNeedles()
        validatedAction('', 0, '#vaccination')
        isControl(0)
        this.setState({ toControl: false })
      })
      .catch(err => catchError(err.response, false))
  }

  render() {
    const { intl } = this.props
    const {
      newNeedles,
      volumeNeedle,
      rinsePrimeMachines,
      loading,
      needles,
      toControl,
      paramId
    } = this.state

    if (loading) {
      return <LoadingPage />
    }

    if (needles && needles.includes('null')) {
      return <NoVaccineApplicator type={0} path={`/parameters/equipment/edit/${paramId}`} />
    }

    if (!toControl) {
      return null
    }

    return (
      <div className="row vaccineCard">
        <div className="col m10 offset-m1 content">
          <form className="card vaccination form" onSubmit={this.handleSubmit.bind(this)}>
            <div className="row CardEquipmentTopTitle">
              <img
                src={icons.injectedVaccine}
                alt="injected vaccine icon"
                className="CardEquipmentTopIcon col m2"
              />
              <div className="col m10">
                <h5>
                  <FormattedMessage id="app.equipment.injected" />
                </h5>
                <p>
                  <FormattedMessage id="app.label.lastControlDate" />
                </p>
              </div>
            </div>
            <div className="divider greyDivider" />
            <div className="row">
              <div className="col m6">
                <div className="switch">
                  <p className="switchLabel">
                    <FormattedMessage id="app.equipment.question.newNeedle" />
                  </p>
                  <label>
                    <FormattedMessage id="app.label.no" />
                    <input
                      type="checkbox"
                      name="newNeedles"
                      value={newNeedles}
                      onChange={this.handleChange}
                    />
                    <span className="lever" />
                    <FormattedMessage id="app.label.yes" />
                  </label>
                </div>
              </div>
              <div className="col m6 radio">
                <p className="radioLabel">
                  <FormattedMessage id="app.equipment.question.typeNeedle" />
                </p>
                <div className="row">
                  <label className="col m2">
                    <input
                      type="radio"
                      name="needlesType"
                      value="20"
                      onChange={this.handleChange}
                      defaultChecked
                    />
                    <span>20g</span>
                  </label>
                  <label className="col offset-m2 m8">
                    <input type="radio" name="needlesType" />
                    <span className="vaccinationCustomCheckSpan">
                      <div className="vaccinationInput">
                        <input
                          id="needlesType"
                          type="text"
                          name="needlesType"
                          placeholder="Autre"
                          onChange={this.handleChange}
                        />
                        {intl.formatMessage({ id: 'app.unit.needleType' })}
                      </div>
                    </span>
                  </label>
                </div>
              </div>
              <div className="col m1" />
            </div>
            <div className="row">
              <div className="col m6">
                <div className="switch">
                  <p className="switchLabel">
                    <FormattedMessage id="app.equipment.question.rinsePrime" />
                  </p>
                  <label>
                    <FormattedMessage id="app.label.no" />
                    <input
                      type="checkbox"
                      name="rinsePrimeMachines"
                      value={rinsePrimeMachines}
                      onChange={this.handleChange}
                    />
                    <span className="lever" />
                    <FormattedMessage id="app.label.yes" />
                  </label>
                </div>
              </div>
            </div>
            <div className="divider greyDivider" />
            <div className="row">
              <div className="col m6">
                <div className="switch">
                  <p className="switchLabel">
                    <FormattedMessage id="app.equipment.question.volumeNeedle" />
                  </p>
                  <label>
                    {intl.formatMessage({ id: 'app.label.notVerified' })}
                    <input
                      type="checkbox"
                      name="volumeNeedle"
                      value={volumeNeedle}
                      onChange={this.handleChange}
                    />
                    <span className="lever" />
                    {intl.formatMessage({ id: 'app.label.verified' })}
                  </label>
                </div>
              </div>
            </div>
            {volumeNeedle && (
              <div className="row">
                <p className="vaccinationInputsFieldLabel">
                  <FormattedMessage id="app.equipment.question.IMInjection" />
                </p>
                {needles &&
                  needles.map((needle, i) => (
                    <div className="input col m3" key={needle.id}>
                      <label htmlFor={needle.name}>{needle.name}</label>
                      <input
                        id={needle.id}
                        type="float"
                        name={needle.name}
                        onChange={e => this.needleChange(i, e)}
                      />
                    </div>
                  ))}
              </div>
            )}
            <div className="row">
              <div className="col offset-m4 m4 center">
                <input
                  type="submit"
                  className="btn blueBtn"
                  value="Valider"
                  disabled={!volumeNeedle || !rinsePrimeMachines || !newNeedles}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default injectIntl(InjectionForm)
