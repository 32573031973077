import React from 'react'
import Header from './partials/Header'
import ShowInfos from './partials/ShowInfos'
import EditInfos from './partials/EditInfos'
import LoadingPage from '../../../../LoadingPage'

class ChicksInfos extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      freeChicks: 0,
      inEdit: false,
      loading: true,
      total: 0
    }

    this.setFreeChicks = this.setFreeChicks.bind(this)
    this.toggleEditClick = this.toggleEditClick.bind(this)
  }

  componentDidMount() {
    this.initTotals()
  }

  getSnapshotBeforeUpdate(prevProps) {
    const { females, males } = this.props
    const total = females + males
    if (females !== prevProps.females || males !== prevProps.males) {
      this.setState({ total })
    }
  }

  setFreeChicks(freeChicks) {
    this.setState({ freeChicks })
  }

  initTotals() {
    const { orderFemales, orderMales } = this.props
    const total = orderFemales + orderMales

    this.setState({ loading: false, total })
  }

  toggleEditClick() {
    const { inEdit } = this.state

    this.setState({ inEdit: !inEdit })
  }

  render() {
    const { females, males, orderFemales, orderMales, setFemales, setMales } = this.props
    const { freeChicks, inEdit, loading, total } = this.state

    if (loading === true) {
      return <LoadingPage />
    }

    return (
      <div className="chicksInfos row custom-card">
        <Header inEdit={inEdit} onClick={this.toggleEditClick} total={total} />
        <ShowInfos
          females={females}
          freeChicks={freeChicks}
          inEdit={inEdit}
          males={males}
          orderFemales={orderFemales}
          orderMales={orderMales}
          onClick={this.toggleEditClick}
        />
        <EditInfos
          orderFemales={orderFemales}
          freeChicks={freeChicks}
          inEdit={inEdit}
          orderMales={orderMales}
          toggleEdit={this.toggleEditClick}
          setFemales={setFemales}
          setFreeChicks={this.setFreeChicks}
          setMales={setMales}
        />
      </div>
    )
  }
}

export default ChicksInfos
