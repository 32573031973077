import React from 'react'
import { injectIntl } from 'react-intl'
import { exiprationDateDays } from '../../../parameters'
import StatePill from '../../StatePill'
import BlockHistoryPart from './BlockHistoryPart/BlockHistoryPart'
import BlockArticlePart from './partials/BlockArticlePart'
import BlockEquipmentPart from './partials/BlockEquipmentPart'
import BlockVaccinationPart from './partials/BlockVaccinationPart'

class BlockPart extends React.Component {
  constructor(props) {
    super(props)
    this.state = { editParamProducts: props.editParamProducts }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const {
      nonBlockingAlert,
      blockingAlert,
      action,
      history,
      productType,
      vaccinationParamId,
      articleId,
      article,
      setDataToParent
    } = this.props
    const { editParamProducts } = this.state

    if (nonBlockingAlert !== undefined && nonBlockingAlert.value !== undefined) {
      this.setState({ blockPartNoBlockValueMax: nonBlockingAlert.value.max })
      setDataToParent('blockPartNoBlockValueMax', nonBlockingAlert.value.max)
    }

    if (blockingAlert !== undefined && blockingAlert !== []) {
      if (vaccinationParamId === 1) {
        if (blockingAlert.value.includes(0)) {
          this.setState({ vaccinBlock: true })
        }
        if (blockingAlert.value.includes(1)) {
          this.setState({ productBagBlock: true })
        }
        if (blockingAlert.value.includes(2)) {
          this.setState({ diluentBlock: true })
        }
        if (blockingAlert.value.includes(3)) {
          this.setState({ dyeBlock: true })
        }
      }
      this.setState({
        blockValueMin: blockingAlert.value.min,
        blockValueMax: blockingAlert.value.max,
        blockAlert: blockingAlert.message
      })
      setDataToParent('blockValueMin', blockingAlert.value.min)
      setDataToParent('blockValueMax', blockingAlert.value.max)
    } else if (articleId && !history) {
      const { blockingExpirationAlert } = article
      this.setState({
        blockAlert: blockingExpirationAlert === null ? '' : blockingExpirationAlert.message
      })
    } else if (editParamProducts === undefined && history === undefined) {
      this.setState({ blockAlert: null })
    } else if (action && action.changes) {
      const { changes } = action
      const blockingAlert2 = changes.blockingAlert

      if (blockingAlert2 && !productType && !history) {
        this.setState({
          blockValueMinBefore: blockingAlert2[0].value.min,
          blockValueMaxBefore: blockingAlert2[0].value.max,
          blockAlertBefore: blockingAlert2[0].message,
          blockValueMinAfter: blockingAlert2[1].value.min,
          blockValueMaxAfter: blockingAlert2[1].value.max,
          blockAlertAfter: blockingAlert2[1].message
        })
      } else if (productType && blockingAlert2 && !history) {
        this.setState({
          blockAlertBefore: blockingAlert2[0].message,
          blockAlertAfter: blockingAlert2[1].message
        })
      } else if (blockingAlert2) {
        this.setState({
          blockValueMinBefore: blockingAlert2[0].value.min,
          blockValueMaxBefore: blockingAlert2[0].value.max,
          blockAlertBefore: blockingAlert2[0].message,
          blockValueMinAfter: blockingAlert2[1].value.min,
          blockValueMaxAfter: blockingAlert2[1].value.max,
          blockAlertAfter: blockingAlert2[1].message
        })
      }
    }
  }

  handleChange(e) {
    const { setDataToParent } = this.props
    const { name, value, type, checked } = e.target

    if (type === 'checkbox') {
      this.setState({ [name]: checked })
      setDataToParent(name, checked)
    } else {
      this.setState({ [name]: value })
      setDataToParent(name, value)
    }
  }

  render() {
    const {
      paramId,
      intl,
      vaccinationParamId,
      blockingAlert,
      history,
      action,
      articleId,
      addParamProducts,
      article,
      congruentPartValueMin,
      congruentPartBlockValueMin,
      congruentPartBlockValueMax
    } = this.props
    const {
      blockValueMin,
      blockValueMax,
      blockAlert,
      blockValueMinAfter,
      blockValueMinBefore,
      vaccinBlock,
      productBagBlock,
      diluentBlock,
      dyeBlock,
      blockValueMaxAfter,
      blockValueMaxBefore,
      blockAlertBefore,
      blockAlertAfter,
      editParamProducts,
      blockPartNoBlockValueMax
    } = this.state

    let renderBlock = null
    if (!articleId) {
      if (!history) {
        renderBlock = (
          <div className="row">
            <div className="col s6 input">
              <div className="row">
                <div className="input col s6">
                  <label htmlFor="blockExpirationDate" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.expirationDate' })}
                  </label>
                  <select
                    className="select-wrapper"
                    name="blockExpirationDate"
                    defaultValue={editParamProducts ? blockingAlert.value : '0'}
                    onChange={this.handleChange}
                    required
                  >
                    <option value={editParamProducts ? blockingAlert.value : '0'} disabled>
                      {editParamProducts
                        ? blockingAlert.value
                        : intl.formatMessage({ id: 'app.label.selectDay' })}
                    </option>

                    {exiprationDateDays.map(day => (
                      <option key={day.name} value={day.name}>
                        {day.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col s12 input">
                  <label htmlFor="blockAlert" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.alert' })}
                  </label>
                  <textarea
                    className="materialize-textarea"
                    name="blockAlert"
                    type="text"
                    value={blockAlert}
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="divider greyDivider" />
          </div>
        )
      } else {
        const { changes } = action

        renderBlock = (
          <div className="row">
            <div className="col s6 input">
              {!addParamProducts && (
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.before' })}
                    </label>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="input col s12">
                  <label htmlFor="blockValueMinBefore" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.expirationDate' })}
                    &nbsp;:&nbsp;
                  </label>
                  <span>
                    {changes && changes.blockingAlert && changes.blockingAlert[0]
                      ? changes.blockingAlert[0].value
                      : action.blockingAlert.value}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <label htmlFor="blockAlertBefore" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.alert' })}
                    &nbsp;:&nbsp;
                  </label>
                  <span>
                    {changes && changes.blockingAlert && changes.blockingAlert[0]
                      ? changes.blockingAlert[0].message
                      : action.blockingAlert.message}
                  </span>
                </div>
              </div>
            </div>
            {!addParamProducts && (
              <div className="col s6 input">
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.after' })}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="input col s12">
                    <label htmlFor="blockValueMinBefore" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.expirationDate' })}
                      &nbsp;:&nbsp;
                    </label>
                    <span>
                      {changes && changes.blockingAlert && changes.blockingAlert[1].value}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="blockAlertAfter" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.alert' })}
                      &nbsp;:&nbsp;
                    </label>
                    <span>
                      {changes && changes.blockingAlert && changes.blockingAlert[1].message}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      }
    } else if (articleId) {
      if (history) {
        renderBlock = <BlockHistoryPart action={action} articleId={articleId} />
      } else {
        renderBlock = (
          <BlockArticlePart
            history={history}
            article={article}
            handleChange={this.handleChange}
            exiprationDateDays={exiprationDateDays}
            blockAlert={blockAlert}
            action={action}
          />
        )
      }
    } else {
      renderBlock = (
        <div className="row">
          <div className="col s12 input">
            <div className="row">
              <div className="input col s12">
                <label htmlFor="blockValueMinBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.expirationDate' })}
                  &nbsp;:&nbsp;
                </label>
                <span>{action.blockingAlert.value}</span>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="blockAlertBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;:&nbsp;
                </label>
                <span>{action.blockingAlert.message}</span>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    }

    if (paramId !== undefined) {
      renderBlock = (
        <BlockEquipmentPart
          paramId={paramId}
          blockAlert={blockAlert}
          blockAlertAfter={blockAlertAfter}
          blockAlertBefore={blockAlertBefore}
          blockValueMax={blockValueMax}
          blockValueMaxAfter={blockValueMaxAfter}
          blockValueMaxBefore={blockValueMaxBefore}
          blockValueMin={blockValueMin}
          blockValueMinAfter={blockValueMinAfter}
          blockValueMinBefore={blockValueMinBefore}
          congruentPartValueMin={congruentPartValueMin}
          history={history}
          handleChange={this.handleChange}
          action={action}
        />
      )
    } else if (vaccinationParamId !== undefined) {
      renderBlock = (
        <BlockVaccinationPart
          vaccinationParamId={vaccinationParamId}
          history={history}
          blockAlert={blockAlert}
          blockAlertAfter={blockAlertAfter}
          blockAlertBefore={blockAlertBefore}
          blockPartNoBlockValueMax={blockPartNoBlockValueMax}
          blockingAlert={blockingAlert}
          handleChange={this.handleChange}
          action={action}
          vaccinBlock={vaccinBlock}
          productBagBlock={productBagBlock}
          diluentBlock={diluentBlock}
          dyeBlock={dyeBlock}
          exiprationDateDays={exiprationDateDays}
          congruentPartBlockValueMin={congruentPartBlockValueMin}
          congruentPartBlockValueMax={congruentPartBlockValueMax}
        />
      )
    }
    return (
      <div>
        <div className="row">
          <p>
            <StatePill type="blockTitleParams" />
            <span className="redTitle">
              {intl.formatMessage({ id: 'app.label.alertBlockTitleParams' })}
            </span>
          </p>
        </div>
        {renderBlock}
      </div>
    )
  }
}

export default injectIntl(BlockPart)
