import React, { useContext } from 'react'
import { injectIntl } from 'react-intl'
import icons from '../../../../../../../../../assets/icons/icons'
import ValencesContext from '../../../../../../../../../context/ValencesContext'

const BlockHeader = props => {
  const { intl, dosesType, administration, speciality, valence, canReload, reload } = props
  const { getValenceLabelByKey } = useContext(ValencesContext)
  let icon

  switch (administration) {
    case 0:
      icon = icons.injectedVaccine
      break
    case 1:
      icon = icons.thinDropVaccine
      break
    case 2:
      icon = icons.bigDropVaccine
      break
    default:
      break
  }

  return (
    <div className="row card-block flex">
      <div className="col s6">
        <div className="flex">
          <img
            className="card-logo marginRight15 space5pixel"
            src={icon}
            alt="logo injectable vaccine"
          />
          {dosesType === 2 && (
            <img
              className="card-logo marginRight15 space5pixel"
              src={icon}
              alt="logo injectable vaccine"
            />
          )}
          <span className="labelCustom space5pixel">{speciality}</span>
          <span> {intl.formatMessage({ id: `app.label.doses.${dosesType}` })}</span>
        </div>
      </div>
      <div className="col s5">{getValenceLabelByKey(valence)}</div>
      {canReload && (
        <button type="button" className="btn redoBtn" onClick={reload}>
          <i className="material-icons">replay</i>
        </button>
      )}
    </div>
  )
}

export default injectIntl(BlockHeader)
