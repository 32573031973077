import Axios from 'axios'
import M from 'materialize-css'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { catchError } from '../../../../actions'
import { api } from '../../../../parameters'
import VaccineInformationsForm from './VaccineInformationsForm'

class VaccineInformations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      vaccines: [{}],
      specialities: [],
      totalDoses: [],
      disabledForm: [],
      numbers: []
    }
    this.handleChangeDate = this.handleChangeDate.bind(this)
    this.loadSpecialities = this.loadSpecialities.bind(this)
    this.loadBatchNumbers = this.loadBatchNumbers.bind(this)
    this.getVaccineInfos = this.getVaccineInfos.bind(this)
    this.addVaccin = this.addVaccin.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    this.loadSpecialities()
    this.loadBatchNumbers()
  }

  getVaccineInfos(name, value, index) {
    const { setDataToParent } = this.props
    const { vaccines } = this.state
    const vaccin = vaccines[index]
    vaccin[name] = value
    return this.setState({ vaccines }, () => setDataToParent('vaccinesBatches', vaccines))
  }

  handleChangeDate(selectedDate, index) {
    const { vaccines } = this.state
    const vaccin = vaccines[index]
    vaccin.expirationDate = moment(selectedDate).format()

    this.setState({ vaccines })
  }

  loadBatchNumbers() {
    const { token } = this.props

    Axios.get(`${api}/api/vaccines_batches/batch_numbers`, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({ numbers: res.data }, () => M.AutoInit())
      })
      .catch(err => catchError(err.response))
  }

  loadSpecialities() {
    const { token, conservationMethod } = this.props

    Axios.get(`${api}/api/vaccine_specialities?conservationMethod=${conservationMethod}`, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({ specialities: res.data['hydra:member'] }, () => M.AutoInit())
      })
      .catch(err => catchError(err.response))
  }

  addVaccin() {
    const { vaccines, disabledForm } = this.state
    const { setDisabled } = this.props

    vaccines.push({})
    disabledForm.push(true)
    this.setState({ vaccines }, M.AutoInit())
    setDisabled(true)
  }

  handleClick() {
    const { changePart } = this.props
    const { numbers, vaccines } = this.state
    let swalNumber = false
    let swalDate = false
    let swalSpeNumber = false
    const numbersToEdit = []

    vaccines.forEach(vaccine => {
      if (moment().isSameOrAfter(vaccines[0].expirationDate) === true) {
        swalDate = true
      }

      for (const number in numbers) {
        if (vaccine.batchNumber === number) {
          if (vaccine.speciality !== numbers[number]) {
            swalSpeNumber = true
          }

          if (swalNumber === false) {
            swalNumber = true
          }

          numbersToEdit.push(vaccine.batchNumber)
        }
      }
    })

    if (swalSpeNumber === true) {
      this.fireSpecialitySwal(numbersToEdit)
    } else if (swalNumber === true) {
      this.fireBatchNumberSwal(swalDate, numbersToEdit)
    } else if (swalDate === true) {
      this.fireExpDateSwal(numbersToEdit)
    } else {
      changePart()
    }
  }

  fireSpecialitySwal(numbersToEdit) {
    const { intl } = this.props

    Swal.fire({
      type: 'warning',
      text: intl.formatMessage({ id: 'app.swal.message.specialityBatchNumber' }, { numbersToEdit }),
      confirmButtonText: intl.formatMessage({ id: 'app.label.ok' }),
      customClass: {
        icon: 'swalOrangeIcon',
        content: 'swalContentText',
        confirmButton: 'blueBtn'
      }
    })
  }

  fireBatchNumberSwal(swalDate, numbersToEdit) {
    const { intl, changePart } = this.props

    Swal.fire({
      type: 'warning',
      text: intl.formatMessage({ id: 'app.swal.message.existingBatchNumber' }, { numbersToEdit }),
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({ id: 'app.label.yes' }),
      cancelButtonText: intl.formatMessage({ id: 'app.label.no' }),
      customClass: {
        icon: 'swalOrangeIcon',
        content: 'swalContentText',
        confirmButton: 'blueBtn',
        cancelButton: 'redBtn'
      }
    }).then(res => {
      if (swalDate === true) {
        this.fireExpDateSwal(numbersToEdit)
      } else if (res.value === true) {
        changePart()
      }
    })
  }

  fireExpDateSwal(numbersToEdit) {
    const { intl } = this.props

    Swal.fire({
      type: 'warning',
      text: intl.formatMessage({ id: 'app.swal.message.expirationDateVaccine' }, { numbersToEdit }),
      confirmButtonText: intl.formatMessage({ id: 'app.label.ok' }),
      customClass: {
        icon: 'swalOrangeIcon',
        content: 'swalContentText',
        confirmButton: 'blueBtn'
      }
    })
  }

  render() {
    const { vaccines, specialities, totalDoses, disabledForm } = this.state
    const { conservationMethod, intl, token, disabled, setDisabled } = this.props

    return (
      <div>
        <div className="row">
          <div className="col s12">
            {vaccines.map((vaccin, i) => (
              <VaccineInformationsForm
                key={vaccin}
                intl={intl}
                specialities={specialities}
                getVaccineInfos={this.getVaccineInfos}
                handleChangeDate={this.handleChangeDate}
                index={i}
                token={token}
                vaccin={vaccin}
                totalDoses={totalDoses}
                disabled={disabled}
                setDisabled={setDisabled}
                disabledForm={disabledForm}
                conservation={conservationMethod}
              />
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col offset-s4 s1">
            <button
              type="button"
              className="btn blueBtn"
              onClick={this.addVaccin}
              disabled={disabled}
            >
              {intl.formatMessage({ id: 'app.label.add' })}
            </button>
          </div>
          <div className="col offset-s1 s1">
            <button
              type="button"
              className="btn blueBtn"
              onClick={this.handleClick}
              disabled={disabled}
            >
              {intl.formatMessage({ id: 'app.button.validate' })}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token
})

export default injectIntl(connect(mapStateToProps)(VaccineInformations))
