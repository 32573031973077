module.exports = [
  {
    label: 'app.label.opaqueOrYellowProductBag',
    value: 0
  },
  {
    label: 'app.label.piercedProductBag',
    value: 1
  },
  {
    label: 'app.label.alreadyThawedBulb',
    value: 2
  },
  {
    label: 'app.label.defrostTimeHigherThan1m30',
    value: 3
  },
  {
    label: 'app.label.brokenBulb',
    value: 4
  },
  {
    label: 'app.label.reversedBulb',
    value: 5
  },
  {
    label: 'app.label.bulbBadConservation',
    value: 6
  },
  {
    label: 'app.label.differentVaccine',
    value: 7
  },
  {
    label: 'app.label.errorChoosingBulb/BatchNumber',
    value: 8
  },
  {
    label: 'app.label.errorChoosingBulb/NotTheRightConditioning',
    value: 9
  },
  {
    label: 'app.label.flaskBadConservation',
    value: 12
  },
  {
    label: 'app.label.brokenFlask',
    value: 13
  },
  {
    label: 'app.label.reversedFlask',
    value: 14
  },
  {
    label: 'app.label.differentVaccine',
    value: 15
  },
  {
    label: 'app.label.errorChoiceFlask/batchNumber',
    value: 16
  },
  {
    label: 'app.label.errorChoiceFlask/badConditionnement',
    value: 17
  }
]
