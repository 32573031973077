import Axios from 'axios'
import M from 'materialize-css'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { catchError } from '../../../../../../actions'
import { api } from '../../../../../../parameters'
import CenterButton from '../../../../../CenterButton'
import PreparationsToReuseItem from './partials/PreparationsToReuseItem'

class PreparationsToReuse extends React.Component {
  constructor(props) {
    super()
    this.state = {
      loading: true,
      preparations: [],
      reusePreparations: [],
      resTotal: props.total
    }
    this.getDataFromChild = this.getDataFromChild.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.setTotal = this.setTotal.bind(this)
  }

  componentDidMount() {
    const { order, token } = this.props

    let dateFilter
    let sequenceFilter
    let group

    if (order.injectionGroup !== null) {
      dateFilter = 'orderOrderPreparations.planningOrder.injectionGroup.hatchingDate'
      sequenceFilter = 'orderOrderPreparations.planningOrder.injectionGroup.sequence'
      group = order.injectionGroup
    } else if (order.thinDropsGroup !== null) {
      dateFilter = 'orderOrderPreparations.planningOrder.thinDropsGroup.hatchingDate'
      sequenceFilter = 'orderOrderPreparations.planningOrder.thinDropsGroup.sequence'
      group = order.thinDropsGroup
    } else if (order.bigDropsGroup !== null) {
      dateFilter = 'orderOrderPreparations.planningOrder.bigDropsGroup.hatchingDate'
      sequenceFilter = 'orderOrderPreparations.planningOrder.bigDropsGroup.sequence'
      group = order.bigDropsGroup
    }

    Axios.get(`${api}${group}`, {
      headers: { Authorization: token }
    }).then(res => {
      this.getPreps(dateFilter, sequenceFilter, res.data.sequence - 1)
    })
  }

  getPreps(dateFilter, sequenceFilter, prevSeq) {
    const { getDataFromChild, hDate, order, token } = this.props
    let { resTotal } = this.state
    const { reusePreparations } = this.state
    const options = { dismissible: false }
    const dateToApi = moment(hDate)
    const dateToApiAfter = dateToApi.format('YYYY-MM-DD')
    const vaccines = order.vaccines.map(function(d) {
      return d.speciality.name
    })
    const dateToApiBefore = moment(dateToApi.format())
      .add(1, 'd')
      .format('YYYY-MM-DD')

    Axios.get(
      `${api}/api/order_preparations?vaccineShapsCode=${
        order.vaccineShapsCode
      }&${dateFilter}[strictly_before]=${dateToApiBefore}&${dateFilter}[after]=${dateToApiAfter}&${sequenceFilter}=${prevSeq}&reuse=true&vaccines.speciality[]=${vaccines.join(
        '&vaccines.speciality[]='
      )}`,
      { headers: { Authorization: token } }
    )
      .then(res => {
        const preparations = res.data['hydra:member']

        let modal
        if (preparations.length > 0) {
          getDataFromChild('modalIsClose', false)
          M.Modal.init(document.getElementById('reuseModal'), options)
          modal = M.Modal.getInstance(document.getElementById('reuseModal'))
        } else {
          getDataFromChild('modalIsClose', true)
        }

        preparations.forEach(prep => {
          resTotal -= prep.dosesLeft
          reusePreparations.push(prep.id)
        })

        this.setState({
          preparations,
          resTotal,
          modal,
          loading: false
        })
      })
      .catch(err => catchError(err.response))
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  setTotal(resTotal) {
    this.setState({ resTotal })
  }

  handleClick() {
    const { data, getDataFromChild, setTotal, setPBagNeededDoses, injectedReuse } = this.props
    const { modal, resTotal, reusePreparations } = this.state

    data.reusePreparations = reusePreparations
    setTotal(resTotal)
    if (injectedReuse) {
      setPBagNeededDoses(resTotal)
    }
    getDataFromChild('modalIsClose', true)
    getDataFromChild('result', data)
    modal.close()
    modal.destroy()
  }

  render() {
    const { intl, setStateToParent, modalIsClose } = this.props
    const { preparations, reusePreparations, modal, resTotal, loading } = this.state

    if (!loading && modal !== undefined && modalIsClose === false && reusePreparations.length > 0) {
      modal.open()
    }

    return (
      <div id="reuseModal" className="modal">
        <div className="modal-content">
          <div className="flex flex-center title">
            <span className="labelCustom blue-text">
              {intl.formatMessage({ id: 'app.message.reuseModal' })}
            </span>
          </div>
          <div>
            {preparations.map((prep, index) => (
              <PreparationsToReuseItem
                key={prep.id}
                prep={prep}
                reusePreparations={reusePreparations}
                setStateToParent={setStateToParent}
                getDataFromChild={this.getDataFromChild}
                toogleDosesLeftForTotal={this.toogleDosesLeftForTotal}
                total={resTotal}
                setTotal={this.setTotal}
                index={index}
              />
            ))}
          </div>
          <CenterButton
            text={intl.formatMessage({ id: 'app.button.validate' })}
            onClick={this.handleClick}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(PreparationsToReuse))
