import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { catchError } from '../../actions'
import DocumentList from '../../components/Documentation/DocumentList/DocumentList'
import Header from '../../components/Documentation/Header'
import LoadingPage from '../../components/LoadingPage'
import { api } from '../../parameters'

class Documentation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      documents: [],
      url: `${api}/api/documents`,
      filters: [{ category: 'category', sortBy: [0, 1, 2], translationPrefix: 'docCategory' }]
    }
    this.getStateFromChild = this.getStateFromChild.bind(this)
  }

  componentDidMount() {
    const { url } = this.state
    const { token } = this.props

    Axios.get(url, {
      headers: {
        Authorization: token
      }
    })
      .then(res => {
        this.setState({
          documents: res.data['hydra:member'],
          loading: false
        })
      })
      .catch(err => catchError(err.response))
  }

  getStateFromChild(data) {
    this.setState({
      documents: data,
      loading: false
    })
  }

  render() {
    const { token, role } = this.props
    const { loading, url, filters, documents } = this.state

    if (loading) {
      return <LoadingPage />
    }

    return (
      <div className="Container row">
        <Header
          filters={filters}
          url={url}
          token={token}
          role={role}
          getStateFromChild={this.getStateFromChild}
        />
        <DocumentList token={token} documents={documents} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    role: state.auth.role,
    token: state.auth.token
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(Documentation)))
