import Axios from 'axios'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { catchError } from '../../../actions'
import ValidateButton from '../../../components/Button/ValidateButton'
import LoadingPage from '../../../components/LoadingPage'
import ChicksInfos from '../../../components/Vaccination/Validation/GroupValidation/ChicksInfos/ChicksInfos'
import GeneralInfos from '../../../components/Vaccination/Validation/GroupValidation/GeneralInfos'
import Prestations from '../../../components/Vaccination/Validation/GroupValidation/Interventions/Prestations'
import WaitedProducts from '../../../components/Vaccination/Validation/GroupValidation/WaitedProducts/WaitedProducts'
import WaitedVaccines from '../../../components/Vaccination/Validation/GroupValidation/WaitedVaccines/WaitedVaccines'
import SharedPreps from '../../../components/Vaccination/Validation/GroupValidation/SharedPreps'
import { api } from '../../../parameters'
import roles from '../../../config/userRoles.json'

class GroupValidation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checkedPrestations: {
        male: [],
        female: []
      },
      females: 0,
      femaleGroups: [],
      femalePrestations: [],
      groups: [],
      loading: true,
      males: 0,
      maleGroups: [],
      malePrestations: [],
      orderFemales: 0,
      orderMales: 0,
      products: {
        bottles: 0,
        diluent: false,
        dye: '',
        pBags: {}
      },
      sharedPreps: {},
      vaccines: {},
      prepIds: []
    }

    this.handleCheckPrestation = this.handleCheckPrestation.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setFemales = this.setFemales.bind(this)
    this.setMales = this.setMales.bind(this)
  }

  componentDidMount() {
    const { match, token } = this.props
    const { number } = match.params

    Axios.get(`${api}/api/planning_order_groups/number/${number}`, {
      headers: { Authorization: token }
    })
      .then(res => {
        res.data.forEach(group => {
          if (group.injectionOrder !== null) this.initAllProducts(group.injectionOrder)
          if (group.thinDropsOrder !== null) this.initAllProducts(group.thinDropsOrder)
          if (group.bigDropsOrder !== null) this.initAllProducts(group.bigDropsOrder)
        })
        this.initTotals(res.data)
        this.setState({ groups: res.data, loading: false })
      })
      .catch(err => catchError(err.response))
  }

  setFemales(females) {
    this.setState({ females })
  }

  setMales(males) {
    this.setState({ males })
  }

  initTotals(groups) {
    const { femaleGroups, maleGroups } = this.state
    let {
      females,
      males,
      orderFemales,
      orderMales,
      femalePrestations,
      malePrestations
    } = this.state
    const totalByGeneration = {}

    for (const group of groups) {
      if (totalByGeneration[group.generation] === undefined) {
        totalByGeneration[group.generation] = {}
      }

      if (totalByGeneration[group.generation][group.product] === undefined) {
        totalByGeneration[group.generation][group.product] = {}
      }

      if (totalByGeneration[group.generation][group.product][group.gender] === undefined) {
        totalByGeneration[group.generation][group.product][group.gender] = {}
        totalByGeneration[group.generation][group.product][group.gender].id = group.id
        totalByGeneration[group.generation][group.product][group.gender].totalChicks =
          group.totalChicks
        totalByGeneration[group.generation][group.product][group.gender].prestations =
          group.prestations
        totalByGeneration[group.generation][group.product][group.gender].gender = group.gender
      }
    }

    for (const totalByProduct of Object.values(totalByGeneration)) {
      for (const totalByGender of Object.values(totalByProduct)) {
        for (const group of Object.values(totalByGender)) {
          if (group.gender === 'M') {
            orderMales += group.totalChicks
            males += group.totalChicks
            malePrestations = malePrestations.concat(group.prestations)

            if (maleGroups.indexOf(group.id) === -1) {
              maleGroups.push(group.id)
            }
          } else {
            orderFemales += group.totalChicks
            females += group.totalChicks
            femalePrestations = femalePrestations.concat(group.prestations)

            if (femaleGroups.indexOf(group.id) === -1) {
              femaleGroups.push(group.id)
            }
          }
        }
      }
    }

    this.setState({
      femaleGroups,
      females,
      femalePrestations,
      maleGroups,
      males,
      malePrestations,
      orderFemales,
      orderMales
    })
  }

  initAllProducts(order) {
    const { prepIds } = this.state

    order.orderOrderPreparations.forEach(prep => {
      if (!prepIds.includes(prep.preparation.id)) {
        this.initVaccine(prep.preparation.vaccines)
        this.initProducts(prep.preparation)
        this.initSharedPreps(prep.preparation)
      }

      prepIds.push(prep.preparation.id)
    })
  }

  initVaccine(prepVaccines) {
    const { vaccines } = this.state

    prepVaccines.forEach(vaccine => {
      if (vaccines[vaccine.batchNumber] === undefined) {
        vaccines[vaccine.batchNumber] = {
          units: 0,
          doses: 999
        }
      }

      vaccines[vaccine.batchNumber].units += vaccine.quantity
    })

    this.setState({ vaccines })
  }

  initProducts(prep) {
    const { products } = this.state

    if (prep.bottle !== null) {
      products.bottles += 1
    }

    if (prep.productBag !== null) {
      products.pBags[prep.productBag.batchNumber] = prep.productBag.doses
    }

    if (products.dye === '') {
      if (prep.fridgeDyeBatch !== null) {
        products.dye = prep.fridgeDyeBatch
      }
    }

    if (products.diluent === false) {
      if (prep.fridgeDiluentBatch !== null) {
        products.diluent = true
      }
    }

    this.setState({ products })
  }

  initSharedPreps(prep) {
    const { sharedPreps } = this.state
    const passedNumbers = []
    if (prep.orderOrderPreparations.length > 1) {
      prep.orderOrderPreparations.forEach(orderPrep => {
        if (typeof orderPrep !== 'string') {
          let orderNumber = ''

          if (orderPrep.planningOrder.injectionGroup !== null) {
            orderNumber = orderPrep.planningOrder.injectionGroup.number
          }

          if (orderPrep.planningOrder.thinDropsGroup !== null) {
            orderNumber = orderPrep.planningOrder.thinDropsGroup.number
          }

          if (orderPrep.planningOrder.bigDropsGroup !== null) {
            orderNumber = orderPrep.planningOrder.bigDropsGroup.number
          }

          if (!passedNumbers.includes(orderNumber)) {
            if (sharedPreps[prep.type] === undefined) {
              sharedPreps[prep.type] = {}
            }
            if (sharedPreps[prep.type][orderNumber] === undefined) {
              sharedPreps[prep.type][orderNumber] = {
                partage: 0,
                qr: prep.qr
              }
            }

            sharedPreps[prep.type][orderNumber].partage += 1
            passedNumbers.push(orderNumber)
          }
        }
      })
    }

    this.setState({ sharedPreps })
  }

  handleCheckPrestation(e) {
    const { checkedPrestations } = this.state
    const { checked, name } = e.target
    let { value } = e.target
    value = parseInt(value)

    if (checked === true && checkedPrestations[name].indexOf(value) === -1) {
      checkedPrestations[name].push(value)
    }

    if (checked === false && checkedPrestations[name].indexOf(value) !== -1) {
      checkedPrestations[name].splice(checkedPrestations[name].indexOf(value), 1)
    }

    this.setState({ checkedPrestations })
  }

  handleSubmit() {
    const { history, match, token } = this.props
    const {
      checkedPrestations,
      femaleGroups,
      females,
      maleGroups,
      males,
      sharedPreps,
      vaccines,
      products
    } = this.state
    const { number } = match.params
    const dye = products.dye !== '' && products.dye !== null
    const bottle = products.bottles !== 0
    const diluent = products.diluent !== false

    Axios.post(
      `${api}/api/vaccination/certify/${number}`,
      {
        checkedPrestations,
        femaleGroups,
        females,
        maleGroups,
        males,
        sharedPreps,
        vaccines,
        dye,
        bottle,
        diluent,
        pbags: products.pBags
      },
      { headers: { Authorization: token } }
    )
      .then(res => history.push(`/vaccination/certificate/${res.data}`))
      .catch(err => catchError(err.response))
  }

  render() {
    const { role } = this.props
    const {
      females,
      groups,
      loading,
      males,
      orderFemales,
      orderMales,
      femalePrestations,
      malePrestations,
      products,
      sharedPreps,
      vaccines
    } = this.state
    const { ROLE_OPERATOR } = roles

    if (loading === true) {
      return <LoadingPage />
    }

    return (
      <div className="row Container VaccinationContainer validation">
        <GeneralInfos group={groups[0]} />
        <ChicksInfos
          females={females}
          groups={groups}
          males={males}
          orderFemales={orderFemales}
          orderMales={orderMales}
          setFemales={this.setFemales}
          setMales={this.setMales}
        />
        <WaitedVaccines vaccines={vaccines} />
        <WaitedProducts products={products} />
        {Object.keys(sharedPreps).length > 0 && (
          <SharedPreps sharedPreps={sharedPreps} orderNumber={groups[0].number} />
        )}
        <Prestations
          onClick={this.handleCheckPrestation}
          prestations={femalePrestations}
          gender="female"
        />
        <Prestations
          onClick={this.handleCheckPrestation}
          prestations={malePrestations}
          gender="male"
        />
        {!role.includes(ROLE_OPERATOR) && <ValidateButton onClick={this.handleSubmit} />}
      </div>
    )
  }
}

const mapStatetoProps = state => {
  return {
    role: state.auth.role,
    token: state.auth.token
  }
}

export default withRouter(connect(mapStatetoProps)(GroupValidation))
