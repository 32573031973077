import React from 'react'
import { injectIntl } from 'react-intl'
import DatepickerBlock from '../../DatepickerBlock'

class GeneralsInformations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      number: props.number
    }
  }

  componentDidMount() {
    const { getDataFromChild } = this.props
    const { number } = this.state
    getDataFromChild('number', number)
  }

  render() {
    const { intl, handleChange, getDataFromChild, dateSelected } = this.props
    const { number } = this.state
    return (
      <div>
        <div className="row">
          <div className="col s12">
            <p className="greyTitle">
              {intl.formatMessage({ id: 'app.label.generalsInformations' })}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            <div className="input col s6">
              <label htmlFor="number" className="labelParams">
                {intl.formatMessage({ id: 'app.label.orderNumberTemporary' })}*
              </label>
              <input name="number" type="text" value={number} disabled />
            </div>
            <div className="input col s6">
              <label htmlFor="hatchery" className="labelParams">
                {intl.formatMessage({ id: 'app.label.hatchery' })}*
              </label>
              <input name="hatchery" type="text" onChange={handleChange} required />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            <div className="input col s6">
              <DatepickerBlock
                setDataFromChild={getDataFromChild}
                dateSelected={dateSelected}
                name="hatchingDate"
                customClass="weekPicker"
                label={intl.formatMessage({ id: 'app.label.hatchingDay' })}
                required
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(GeneralsInformations)
