import { FormattedMessage } from 'react-intl'
import React from 'react'

export default function ReceiptConformity(props) {
  const { typeConformity, conformity, reason, divSize } = props
  let conformityVerified
  let reasonMessage
  if (conformity != null) {
    conformityVerified = (
      <div className={`col ${divSize}`}>
        <span className="label">
          <FormattedMessage id={`${typeConformity}`} />
          &nbsp;:&nbsp;
        </span>
        <FormattedMessage id="app.label.conformity.true" />
      </div>
    )
    reasonMessage = (
      <div>
        <span className="label">
          <FormattedMessage id="app.label.noConformityReason" />
          &nbsp;:&nbsp;
        </span>
        {reason}
      </div>
    )

    if (conformity === false) {
      conformityVerified = (
        <div className={`col ${divSize}`}>
          <span className="label">
            <FormattedMessage id={`${typeConformity}`} />
            &nbsp;:&nbsp;
          </span>
          <FormattedMessage id="app.label.conformity.false" />
          {reason != null && <span>{reasonMessage}</span>}
        </div>
      )
    }
  }
  return <span>{conformityVerified}</span>
}
