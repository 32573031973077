import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { catchError } from '../../actions'
import { closePage, validatedAction } from '../../components/globalAction'
import ChickInformations from '../../components/Planning/partials/ChickInformations'
import ClientsInformations from '../../components/Planning/partials/ClientsInformations'
import GeneralsInformations from '../../components/Planning/partials/GeneralsInformations'
import OrderInformations from '../../components/Planning/partials/partials/OrderInformations/OrderInformations'
import PrestationsContext from '../../context/PrestationsContext'
import { api } from '../../parameters'

class AddOrder extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orders: [
        {
          administration: '',
          shapsCode: '',
          vaccines: [{ id: 0, speciality: '', doseType: '' }]
        }
      ],
      prestationsSelected: [],
      numbers: []
    }
    this.addOrder = this.addOrder.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.generateNumber = this.generateNumber.bind(this)
    this.formValidation = this.formValidation.bind(this)
  }

  componentDidMount() {
    const { token } = this.props
    const { numbers } = this.state
    Axios.get(`${api}/api/planning_order_groups`, { headers: { Authorization: token } }).then(
      res => {
        res.data['hydra:member'].forEach(group => {
          if (!numbers.includes(group.number)) {
            numbers.push(group.number)
          }
        })
        this.setState({ numbers })
      }
    )

    const number = this.generateNumber()
    this.setState({ number })
  }

  componentDidUpdate(prevProps, prevState) {
    const { orders } = this.state

    if (prevState.orders !== orders) {
      this.formValidation()
    }
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value }, () => this.formValidation())
  }

  static contextType = PrestationsContext

  generateNumber() {
    const { numbers } = this.state
    const random = `P${Math.floor(1000 + Math.random() * 9000)}`

    if (numbers.includes(random)) {
      return this.generateNumber()
    }

    return random
  }

  addOrder() {
    const { orders } = this.state
    orders.push({
      administration: '',
      totalChicks: '',
      vaccines: [{ speciality: '', doseType: '' }]
    })
    this.setState({ orders }, () => this.formValidation())
  }

  formValidation() {
    const {
      address,
      clientName,
      dateSelected,
      farmer,
      hatchery,
      number,
      orders,
      product,
      totalChicks
    } = this.state

    let orderIsInvalid = false

    for (let i = 0; i < orders.length; i += 1) {
      const order = orders[i]

      if (
        order.administration === '' ||
        order.shapsCode === undefined ||
        !Number.isInteger(parseFloat(order.shapsCode))
      ) {
        orderIsInvalid = true
        break
      }

      for (let idx = 0; idx < order.vaccines.length; idx += 1) {
        const vaccine = order.vaccines[idx]

        if (!Number.isInteger(parseFloat(vaccine.doseType) || vaccine.speciality === '')) {
          orderIsInvalid = true
          break
        }
      }
    }

    let formIsValid =
      orderIsInvalid ||
      address === undefined ||
      address === '' ||
      clientName === undefined ||
      clientName === '' ||
      farmer === undefined ||
      farmer === '' ||
      hatchery === undefined ||
      hatchery === '' ||
      product === undefined ||
      product === '' ||
      number === undefined ||
      number === '' ||
      dateSelected === undefined ||
      dateSelected === '' ||
      totalChicks === undefined ||
      !Number.isInteger(parseFloat(totalChicks)) ||
      (orders[0].vaccines[0] !== undefined &&
        (orders[0].vaccines[0].speciality === '' || orders[0].vaccines[0].doseType === ''))

    orders.forEach(order => {
      formIsValid =
        order.administration === '' || order.shapsCode === '' || order.vaccines[0] === undefined

      order.vaccines.forEach(vaccine => {
        formIsValid = vaccine.speciality === '' || vaccine.doseType === ''
      })
    })

    this.setState({ formIsValid })
  }

  handleChange(e) {
    const { prestationsSelected } = this.state
    const { name, checked, type, value } = e.target

    if (type === 'checkbox') {
      if (checked) {
        prestationsSelected.push(parseInt(value))
      } else {
        const prestationIndex = prestationsSelected.indexOf(parseInt(value))
        prestationsSelected.splice(prestationIndex, 1)
      }

      this.setState({ prestationsSelected })
    } else {
      this.setState({ [name]: value }, () => this.formValidation())
    }
  }

  handleSubmit() {
    const { token } = this.props
    const {
      address,
      clientName,
      dateSelected,
      dateSelectedWeek,
      farmer,
      generation,
      hatchery,
      number,
      orders,
      prestationsSelected,
      product,
      totalChicks,
      gender
    } = this.state
    const { prestations } = this.context
    let injectionOrder = null
    let thinDropsOrder = null
    let bigDropsOrder = null

    orders.forEach(order => {
      for (let i = 0; i < order.vaccines.length; i += 1) {
        const vaccine = order.vaccines[i]
        delete vaccine.id
      }
      if (parseInt(order.administration) === 0) {
        injectionOrder = {
          prestations,
          administration: parseInt(order.administration),
          vaccines: order.vaccines,
          vaccineShapsCode: order.shapsCode
        }
      }
      if (parseInt(order.administration) === 1) {
        thinDropsOrder = {
          prestations,
          administration: parseInt(order.administration),
          vaccines: order.vaccines,
          vaccineShapsCode: order.shapsCode
        }
      }
      if (parseInt(order.administration) === 2) {
        bigDropsOrder = {
          prestations,
          administration: parseInt(order.administration),
          vaccines: order.vaccines,
          vaccineShapsCode: order.shapsCode
        }
      }
    })

    const formData = {
      address,
      bigDropsOrder,
      clientName,
      farmer,
      generation: parseInt(generation),
      hatchery,
      hatchingWeek: dateSelectedWeek,
      hatchingDate: dateSelected,
      injectionOrder,
      number,
      product: parseInt(product),
      prestations: prestationsSelected,
      thinDropsOrder,
      totalChicks: parseInt(totalChicks),
      gender
    }

    Axios.post(`${api}/api/planning_order_groups`, formData, { headers: { Authorization: token } })
      .then(() => validatedAction(''))
      .catch(err => catchError(err.response))
  }

  render() {
    const { intl } = this.props
    const { orders, dateSelected, number, formIsValid } = this.state
    const { prestations } = this.context

    return (
      <div className=" row Container params">
        <div className="col s12 content">
          <div className="row center marginTop30">
            <div className="col s11">
              <h5 className="labelCustom">{intl.formatMessage({ id: 'app.label.addNewOrder' })}</h5>
            </div>
            <div className="col s1">
              <div
                className="goBackBtn right"
                role="button"
                tabIndex="0"
                onKeyPress={() => closePage(intl, false)}
                onClick={() => closePage(intl, false)}
              >
                <i className="material-icons">close</i>
              </div>
            </div>
          </div>
          <div className="row col s12 card">
            <div className="row col s12">
              <div className="col s12 form">
                <form>
                  {number && (
                    <GeneralsInformations
                      handleChange={this.handleChange}
                      dateSelected={dateSelected}
                      number={number}
                      getDataFromChild={this.getDataFromChild}
                    />
                  )}
                  <div className="row">
                    <div className="divider greyDivider" />
                  </div>
                  <ClientsInformations handleChange={this.handleChange} />
                  <div className="row">
                    <div className="divider greyDivider" />
                  </div>
                  <ChickInformations handleChange={this.handleChange} />
                  <div className="row">
                    <div className="divider greyDivider" />
                  </div>
                  <div className="row">
                    <div className="col s12">
                      <p className="greyTitle">
                        {intl.formatMessage({ id: 'app.label.orderInformations' })}
                      </p>
                    </div>
                  </div>
                  {orders.map((order, orderIndex) => (
                    <OrderInformations
                      key={orderIndex}
                      orderIndex={orderIndex}
                      orders={orders}
                      getDataFromChild={this.getDataFromChild}
                    />
                  ))}
                  {orders.length < 3 && (
                    <div className="row">
                      <div className="col s12">
                        <span className="col s6">
                          <span
                            className="col s12 blue-text btnAddArticle"
                            role="button"
                            onClick={this.addOrder}
                            onKeyPress={this.addOrder}
                            tabIndex={0}
                          >
                            <span className="plusButtonArticle pointer">+</span>
                            <span className="textButtonArticle pointer">
                              {intl.formatMessage({ id: 'app.label.addMethodsAdministration' })}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="divider greyDivider" />
                  </div>
                  <div className="row">
                    <div className="col s12">
                      <p className="greyTitle">
                        {intl.formatMessage({ id: 'app.label.prestationInformations' })}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      {prestations.map((prestation, i) => (
                        <div key={i} className="input col s3">
                          <label className="col s12">
                            <input
                              value={prestation.value}
                              type="checkbox"
                              className="filled-in checkbox"
                              onChange={this.handleChange}
                            />
                            <span>
                              {prestation.displayName ? prestation.displayName : prestation.name}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="row">
                    <button
                      type="button"
                      className="btn blueBtn col offset-m5 m2 validationBtn"
                      onClick={this.handleSubmit}
                      disabled={formIsValid}
                    >
                      {intl.formatMessage({ id: 'app.label.save' })}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(AddOrder)))
