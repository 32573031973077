import M from 'materialize-css'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import DiluentsPart from '../../../components/StockManagement/StockAvailibility/DiluentsPart'
import DyesPart from '../../../components/StockManagement/StockAvailibility/DyesPart'
import InjectedVaccinesPart from '../../../components/StockManagement/StockAvailibility/InjectedVaccinesPart'
import ProductBagsPart from '../../../components/StockManagement/StockAvailibility/ProductBagsPart'
import SprayedVaccinesPart from '../../../components/StockManagement/StockAvailibility/SprayedVaccinesPart'

export default class StockAvailibility extends React.Component {
  componentDidMount() {
    M.AutoInit()
  }

  render() {
    const { token, role } = this.props

    return (
      <div className="row Container stockManagement">
        <div className="col s12 stockManagementContent">
          <ul className="tabs">
            <li className="tab col s3">
              <a className="active" href="#injectableVaccine">
                <FormattedMessage id="app.label.injectedVaccines" />
              </a>
            </li>
            <li className="tab col s2">
              <a href="#nebulizableVaccine">
                <FormattedMessage id="app.label.sprayedVaccines" />
              </a>
            </li>
            <li className="tab col s2">
              <a href="#botaBag">
                <FormattedMessage id="app.label.productBags" />
              </a>
            </li>
            <li className="tab col s2">
              <a href="#dyes">
                <FormattedMessage id="app.label.dyes" />
              </a>
            </li>
            <li className="tab col s3">
              <a href="#diluent">
                <FormattedMessage id="app.label.diluents" />
              </a>
            </li>
            <li className="indicator" />
          </ul>
        </div>
        <div id="injectableVaccine" className="col s12 content marginTop30 active">
          <InjectedVaccinesPart role={role} token={token} />
        </div>
        <div id="nebulizableVaccine" className="col s12 content marginTop30">
          <SprayedVaccinesPart role={role} token={token} />
        </div>
        <div id="botaBag" className="col s12 content marginTop30">
          <ProductBagsPart role={role} token={token} />
        </div>
        <div id="dyes" className="col s12 content marginTop30">
          <DyesPart role={role} token={token} />
        </div>
        <div id="diluent" className="col s12 content marginTop30">
          <DiluentsPart role={role} token={token} />
        </div>
      </div>
    )
  }
}
