import React from 'react'

const LoadingPage = props => {
  const { message } = props

  return (
    <div className="centerPage loaderContainer content">
      <div className="loader" />
      <div className="message">{message}</div>
    </div>
  )
}

export default LoadingPage
