import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { catchError } from '../../../../../../actions'
import { api, mercure } from '../../../../../../parameters'
import CenterButton from '../../../../../CenterButton'
import LoadingPage from '../../../../../LoadingPage'
import AddWaterBlock from '../../SprayableVaccinePart/ScanSprayableVaccine/partials/AddWaterBlock'
import BlockInfo from './partials/BlockInfo'
import SpecialityBlockInfo from './partials/SpecialityBlockInfo'

class VaccineScan extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      disabledScanBtn: false,
      loading: true
    }

    this.handleScan = this.handleScan.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleValidate = this.handleValidate.bind(this)
    this.connectMercure = this.connectMercure.bind(this)
    this.setDisabledScanBtn = this.setDisabledScanBtn.bind(this)
  }

  componentDidMount() {
    const { token } = this.props

    M.AutoInit()
    Axios.get(`${api}/api/devices?active=true`, { headers: { Authorization: token } })
      .then(res => {
        if (res.data['hydra:member'][0] !== undefined) {
          this.setState({ device: res.data['hydra:member'][0].guid })
        } else {
          this.setState({ disabledScanBtn: true })
        }

        this.setState({
          devices: res.data['hydra:member'],
          loading: false
        })
      })
      .catch(err => catchError(err.response))
  }

  setDisabledScanBtn(disabledScanBtn) {
    this.setState({ disabledScanBtn })
  }

  connectMercure() {
    const { preparation } = this.props
    const { device } = this.state
    let u = new URL(mercure)

    u.searchParams.append('topic', `scan-${device}`)

    const es = new EventSource(u)
    es.onmessage = e => {
      u = undefined

      if (parseInt(e.data) === parseInt(preparation.preparation.id)) {
        this.setState({
          disabledScanBtn: true,
          inScan: false,
          loading: false,
          scanned: true
        })
        es.close()
      }
    }
  }

  handleScan() {
    const { token, preparation } = this.props
    const { device } = this.state

    const result = {
      guid: device,
      preparation: preparation.id
    }

    Axios.patch(`${api}/api/realisation/push_notification`, result, {
      headers: { Authorization: token }
    })
      .then(() => {
        this.setState({ inScan: true, loading: true })
        this.connectMercure()
      })
      .catch(err => catchError(err.response))

    // this.setState({ inScan: true, loading: true })
    // this.connectMercure()
  }

  handleChange(e) {
    const { value } = e.target

    if (value !== 0) {
      this.setState({ device: value, disabledScanBtn: false })
    }
  }

  handleValidate() {
    const { changePagePart, preparation, token } = this.props

    Axios.patch(
      `${api}/api/vaccination/realisation/update_date/${preparation.id}`,
      {},
      {
        headers: { Authorization: token }
      }
    )
      .then(() => changePagePart())
      .catch(err => catchError(err.response, false))
  }

  render() {
    const { getDataFromChild, intl, preparation: prep, prepId, vaccineType, water } = this.props
    const { devices, disabledScanBtn, inScan, loading, scanned, device } = this.state
    const { preparation } = prep
    const { vaccines } = preparation
    const vaccineTypeToUrl = vaccineType || 0

    if (loading === true) {
      return (
        <LoadingPage
          message={inScan === true ? intl.formatMessage({ id: 'app.message.waitingScan' }) : ''}
        />
      )
    }
    return (
      <div>
        <BlockInfo
          vaccines={vaccines}
          bottle={preparation.bottle}
          productBag={preparation.productBag}
        />
        {preparation.vaccines.map(batch => (
          <SpecialityBlockInfo
            key={batch.id}
            batch={batch}
            scanned={scanned}
            vaccineType={vaccineType}
          />
        ))}
        {vaccineType
          ? (vaccineType === 1 || vaccineType === 2) && (
              <AddWaterBlock
                getDataFromChild={getDataFromChild}
                setDisabledScanBtn={this.setDisabledScanBtn}
                water={water}
              />
            )
          : null}
        <div className="row">
          <div className="input col s6 offset-s3">
            <label htmlFor="device" className="labelParams">
              {intl.formatMessage({ id: 'app.label.selectDevice' })}
            </label>
            <select
              className="select-wrapper browser-default"
              name="device"
              defaultValue={device === undefined ? 0 : device}
              required
              onChange={this.handleChange}
              disabled={scanned}
            >
              {devices.map(dev => (
                <option key={dev.guid} value={dev.guid}>
                  {dev.name === null ? dev.guid : dev.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <CenterButton
          text={intl.formatMessage({
            id: `app.label.${vaccineType === 0 ? 'scanAmpoules' : 'scanVials'}`
          })}
          onClick={this.handleScan}
          disabled={disabledScanBtn}
        />
        <div className="row">
          <div className="col s12 flex flex-center">
            <Link
              className="btn blueBtn space5pixel"
              to={`/vaccination/realisation/vaccine/${prepId}/${vaccineTypeToUrl}/signal-problem`}
            >
              {intl.formatMessage({ id: 'app.label.signalProblem' })}
            </Link>
            <button
              type="button"
              className="btn blueBtn"
              onClick={this.handleValidate}
              disabled={!scanned}
            >
              {intl.formatMessage({ id: 'app.button.validate' })}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(VaccineScan))
