import React from 'react'
import { injectIntl } from 'react-intl'

function ColumnItem(props) {
  const { items, intl } = props

  return (
    <div className="listProduct">
      {items !== null ? (
        <span className="listProduct">
          {items.map(item => (
            <div key={item} className="listProduct">
              {item}
            </div>
          ))}
        </span>
      ) : (
        <span className="listProduct">
          <div className="listProduct">{intl.formatMessage({ id: 'app.label.N/A' })}</div>
        </span>
      )}
    </div>
  )
}

export default injectIntl(ColumnItem)
