import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import StatePill from '../../../../StatePill'

class WaterBathControl extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    const { name, value } = e.target
    const { getDataFromChild } = this.props

    getDataFromChild(name, parseFloat(value))
    if (value === '') {
      getDataFromChild('disabledWaterBathPart', true)
    } else {
      getDataFromChild('disabledWaterBathPart', false)
    }
  }

  render() {
    const { intl, data, lastControl } = this.props

    return (
      <div className="row">
        <div className="col s12">
          <div className="row">
            <div className="col s12">
              <div className="custom-card">
                <div className="card-header">
                  <div className="col s6 bold">
                    {intl.formatMessage({ id: 'app.label.waterBatchControl' })}
                  </div>
                  {data !== undefined ? (
                    <div className="col s6 bold">
                      <StatePill type="waterBathControl" data={data} />
                      {intl.formatMessage({ id: 'app.label.lastControlDate' })}{' '}
                      {moment(lastControl).format('DD/MM/YYYY')}
                    </div>
                  ) : (
                    <div className="col s6 bold blueText">
                      {intl.formatMessage({ id: 'app.message.firstBathControl' })}
                    </div>
                  )}
                </div>
                <div className="card-content">
                  <div className="col s12 form">
                    <div className="col s6 input">
                      <label htmlFor="temperature" className="label">
                        {intl.formatMessage({ id: 'app.label.temperature' })}
                      </label>
                      <input
                        name="temperature"
                        type="number"
                        step="0.1"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(WaterBathControl))
