import React from 'react'
import { injectIntl } from 'react-intl'

const NoBlockStockHistoryPart = props => {
  const { minimalStockAlert, intl } = props

  return (
    <div className="row">
      <div className="col s6 input">
        <div className="row">
          <div className="col s12">
            <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
              {intl.formatMessage({ id: 'app.label.before' })}
            </label>
          </div>
        </div>
        <div className="row input">
          <div className="col s12">
            <label htmlFor="noBlockPartAlert" className="labelParams">
              {intl.formatMessage({ id: 'app.label.minStock' })}
              &nbsp;:&nbsp;
            </label>
            <span>{minimalStockAlert[0].value}</span>
          </div>
        </div>
        <div className="row input">
          <div className="col s12">
            <label htmlFor="noBlockPartAlertStockAfter" className="labelParams">
              {intl.formatMessage({ id: 'app.label.alert' })}
              &nbsp;:&nbsp;
            </label>
            <span>{minimalStockAlert[0].message}</span>
          </div>
        </div>
      </div>
      <div className="col s6">
        <div className="row input">
          <div className="col s12">
            <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
              {intl.formatMessage({ id: 'app.label.after' })}
            </label>
          </div>
        </div>
        <div className="row input">
          <div className="col s12">
            <label htmlFor="noBlockPartAlert" className="labelParams">
              {intl.formatMessage({ id: 'app.label.minStock' })}
              &nbsp;:&nbsp;
            </label>
            <span>{minimalStockAlert[1].value}</span>
          </div>
        </div>
        <div className="row input">
          <div className="col s12">
            <label htmlFor="noBlockPartAlertStockAfter" className="labelParams">
              {intl.formatMessage({ id: 'app.label.alert' })}
              &nbsp;:&nbsp;
            </label>
            <span>{minimalStockAlert[1].message}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(NoBlockStockHistoryPart)
