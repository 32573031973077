import React from 'react'
import { injectIntl } from 'react-intl'
import TextArea from '../../partials/TextArea'

const NoBlockAlertNumberPart = props => {
  const {
    intl,
    noBlockExpirationDateAfter,
    noBlockExpirationDateBefore,
    noBlockPartAlertExpirationDateAfter,
    noBlockPartAlertStock,
    history,
    alertNumber,
    noBlockUnitsStock,
    noBlockUnitsStockAfter,
    noBlockUnitsStockBefore,
    noBlockPartAlertStockBefore,
    noBlockPartAlertStockAfter,
    nonBlockingAlert,
    noBlockPartAlertExpirationDate,
    noBlockPartAlertExpirationDateBefore,
    editParamProducts,
    exiprationDateDays,
    handleChange,
    action
  } = props

  switch (alertNumber) {
    case '1':
      if (!history) {
        return (
          <div className="row">
            <div className="col s6 input">
              <div className="row">
                <div className="input col s6">
                  <label htmlFor="noBlockUnitsStock" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.minStock' })}
                  </label>
                  <input
                    name="noBlockUnitsStock"
                    type="number"
                    value={noBlockUnitsStock}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <TextArea
                name="noBlockPartAlertStock"
                value1={noBlockPartAlertStock}
                handleChange={handleChange}
              />
            </div>
            <div className="divider greyDivider" />
          </div>
        )
      }
      if (action && action.changes) {
        return (
          <div className="row">
            <div className="col s6 input">
              <div className="row">
                <div className="col s12">
                  <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.before' })}
                  </label>
                </div>
              </div>
              <div className="row input">
                <div className="col s12">
                  <label htmlFor="noBlockPartAlert" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.minStock' })}
                    &nbsp;:&nbsp;
                  </label>
                  <span>
                    {noBlockUnitsStockBefore ||
                      (action.changes &&
                        action.changes.nonBlockingAlert &&
                        action.changes.nonBlockingAlert[0].value.stock)}
                  </span>
                </div>
              </div>
              <div className="row input">
                <div className="col s12">
                  <label htmlFor="noBlockPartAlertStockAfter" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.alert' })}
                    &nbsp;:&nbsp;
                  </label>
                  <span>
                    {noBlockPartAlertStockBefore ||
                      (action.changes &&
                        action.changes.nonBlockingAlert &&
                        action.changes.nonBlockingAlert[0].message.stock)}
                  </span>
                </div>
              </div>
            </div>
            <div className="col s6">
              <div className="row input">
                <div className="col s12">
                  <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.after' })}
                  </label>
                </div>
              </div>
              <div className="row input">
                <div className="col s12">
                  <label htmlFor="noBlockPartAlert" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.minStock' })}
                    &nbsp;:&nbsp;
                  </label>
                  <span>
                    {noBlockUnitsStockAfter ||
                      (action.changes &&
                        action.changes.nonBlockingAlert &&
                        action.changes.nonBlockingAlert[1].value.stock)}
                  </span>
                </div>
              </div>
              <div className="row input">
                <div className="col s12">
                  <label htmlFor="noBlockPartAlertStockAfter" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.alert' })}
                    &nbsp;:&nbsp;
                  </label>
                  <span>
                    {noBlockPartAlertStockAfter || action.changes.nonBlockingAlert[1].message.stock}
                  </span>
                </div>
              </div>
            </div>
            <div className="divider greyDivider" />
          </div>
        )
      }
      return (
        <div className="row">
          <div className="col s12 input">
            <div className="row input">
              <div className="col s12">
                <label htmlFor="noBlockPartAlert" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.minStock' })}
                  &nbsp;:&nbsp;
                </label>
                <span>{action.nonBlockingAlert.value.stock}</span>
              </div>
            </div>
            <div className="row input">
              <div className="col s12">
                <label htmlFor="noBlockPartAlertStockAfter" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;:&nbsp;
                </label>
                <span>{action.nonBlockingAlert.message.stock}</span>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    default:
      if (!history) {
        return (
          <div className="row">
            <div className="col s6 input">
              <div className="row">
                <div className="input col s6">
                  <label htmlFor="noBlockExpirationDate" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.expirationDate' })}
                  </label>
                  <select
                    className="select-wrapper"
                    name="noBlockExpirationDate"
                    defaultValue={editParamProducts ? nonBlockingAlert.value.expirationDate : '0'}
                    onChange={handleChange}
                  >
                    <option
                      value={editParamProducts ? nonBlockingAlert.value.expirationDate : '0'}
                      disabled
                    >
                      {editParamProducts
                        ? nonBlockingAlert.value.expirationDate
                        : intl.formatMessage({ id: 'app.label.selectDay' })}
                    </option>

                    {exiprationDateDays.map(day => (
                      <option key={day.name} value={day.name}>
                        {day.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <TextArea
                name="noBlockPartAlertExpirationDate"
                value1={noBlockPartAlertExpirationDate}
                handleChange={handleChange}
              />
            </div>
            <div className="divider greyDivider" />
          </div>
        )
      }
      if (action && action.changes) {
        return (
          <div className="row">
            <div className="col s6 input">
              <div className="row">
                <div className="col s12">
                  <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.before' })}
                  </label>
                </div>
              </div>
              <div className="row input">
                <div className="col s12">
                  <label htmlFor="noBlockPartAlert" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.expirationDate' })}
                    &nbsp;:&nbsp;
                  </label>
                  <span>
                    {noBlockExpirationDateBefore ||
                      (action.changes &&
                        action.changes.nonBlockingAlert &&
                        action.changes.nonBlockingAlert[0].value.expirationDate)}
                  </span>
                </div>
              </div>
              <div className="row input">
                <div className="col s12">
                  <label htmlFor="noBlockPartAlertExpirationDateBefore" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.alert' })}
                    &nbsp;:&nbsp;
                  </label>
                  <span>
                    {noBlockPartAlertExpirationDateBefore ||
                      (action.changes &&
                        action.changes.nonBlockingAlert &&
                        action.changes.nonBlockingAlert[0].message.expirationDate)}
                  </span>
                </div>
              </div>
            </div>
            <div className="col s6">
              <div className="row input">
                <div className="col s12">
                  <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.after' })}
                  </label>
                </div>
              </div>
              <div className="row input">
                <div className="col s12">
                  <label htmlFor="noBlockPartAlert" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.expirationDate' })}
                    &nbsp;:&nbsp;
                  </label>
                  <span>
                    {noBlockExpirationDateAfter ||
                      (action.changes &&
                        action.changes.nonBlockingAlert &&
                        action.changes.nonBlockingAlert[1].value.expirationDate)}
                  </span>
                </div>
              </div>
              <div className="row input">
                <div className="col s12">
                  <label htmlFor="noBlockPartAlertExpirationDateBefore" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.alert' })}
                    &nbsp;:&nbsp;
                  </label>
                  <span>
                    {noBlockPartAlertExpirationDateAfter ||
                      (action.changes &&
                        action.changes.nonBlockingAlert &&
                        action.changes.nonBlockingAlert[1].message.expirationDate)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )
      }
      return (
        <div className="row">
          <div className="col s12 input">
            <div className="row input">
              <div className="col s12">
                <label htmlFor="noBlockPartAlert" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.expirationDate' })}
                  &nbsp;:&nbsp;
                </label>
                <span>{action.nonBlockingAlert.value.expirationDate}</span>
              </div>
            </div>
            <div className="row input">
              <div className="col s12">
                <span className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;:&nbsp;
                </span>
                <span>{action.nonBlockingAlert.message.expirationDate}</span>
              </div>
            </div>
          </div>
        </div>
      )
  }
}

export default injectIntl(NoBlockAlertNumberPart)
