import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { catchError } from '../../../../../actions/index'
import { api } from '../../../../../parameters'
import CenterButton from '../../../../CenterButton'
import FridgeInformationsForm from './FridgeInformationsForm'

class FridgeInformation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fridgeId: '',
      fridgesSelected: [],
      fridgesList: []
    }
    this.getDataFromChild = this.getDataFromChild.bind(this)
  }

  componentDidMount() {
    const { token } = this.props
    Axios.get(`${api}/api/fridges?active=true`, { headers: { Authorization: token } })
      .then(res => this.setState({ fridgesList: res.data['hydra:member'] }, () => M.AutoInit()))
      .catch(err => catchError(err.response))
  }

  getDataFromChild(name, value) {
    const { setDataToParent } = this.props
    const fridgeId = value
    this.setState({ fridgeId }, () => setDataToParent('fridgeId', fridgeId))
  }

  render() {
    const { fridgesList, fridgesSelected, fridgeId } = this.state
    const { token, changePart, intl } = this.props
    return (
      <div>
        <div className="row">
          <div className="col s12">
            <FridgeInformationsForm
              fridgesList={fridgesList}
              token={token}
              setDataToParent={this.getDataFromChild}
              fridgesSelected={fridgesSelected}
            />
          </div>
        </div>
        <CenterButton
          text={intl.formatMessage({ id: 'app.button.validate' })}
          onClick={changePart}
          disabled={fridgeId === ''}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token
})
export default injectIntl(connect(mapStateToProps)(FridgeInformation))
