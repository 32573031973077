import React from 'react'
import Axios from 'axios'
import M from 'materialize-css'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import Swal from 'sweetalert2'
import { catchError } from '../../../actions'
import { validatedAction } from '../../../components/globalAction'
import NitrogenLevel from '../../../components/StockManagement/OrderReception/NitrogenReception/NitrogenLevel'
import HeaderReception from '../../../components/StockManagement/OrderReception/partials/HeaderReception'
import OrderConformity from '../../../components/StockManagement/OrderReception/partials/OrderConformity'
import ProgressBarReception from '../../../components/StockManagement/OrderReception/partials/ProgressBarReception'
import VaccineInformations from '../../../components/StockManagement/OrderReception/partials/VaccineInformations'
import { api } from '../../../parameters'
import globalHistory from '../../../history'

class Nitrogen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      receiverContainers: [],
      part: 1,
      progress: 10,
      isOther: false,
      displayPart: true,
      disabled: true,
      formData: {
        history: {
          nameConformity: true,
          quantityConformity: true,
          batchNumberConformity: true,
          expirationDateConformity: true,
          nameNoConformityReason: null,
          quantityNoConformityReason: null,
          batchNumberNoConformityReason: null,
          expirationDateNoConformityReason: null,
          comment: ''
        }
      }
    }
    this.changePart = this.changePart.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getChildInformation = this.getChildInformation.bind(this)
    this.getHistoryValueFromChild = this.getHistoryValueFromChild.bind(this)
    this.setDisplayPart = this.setDisplayPart.bind(this)
    this.setDisabled = this.setDisabled.bind(this)
  }

  componentDidMount() {
    const { token } = this.props
    Axios.get(`${api}/api/nitrogen_containers?active=true`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const receiverContainers = res.data['hydra:member']
        this.setState(
          {
            receiverContainers
          },
          () => M.AutoInit()
        )
      })
      .catch(err => catchError(err.response))
  }

  setDisplayPart(displayPart) {
    this.setState({ displayPart }, () => M.AutoInit())
  }

  getChildInformation(name, value) {
    const { formData } = this.state
    this.setState({ formData: { ...formData, [name]: value } })
  }

  getHistoryValueFromChild(name, value) {
    const { formData } = this.state
    this.setState({ formData: { ...formData, history: { ...formData.history, [name]: value } } })
  }

  getContent() {
    const { part, isOther, formData, receiverContainers, disabled } = this.state
    const { receptionType, container } = formData
    switch (part) {
      case 1:
        return (
          <NitrogenLevel
            isOther={isOther}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            changePart={this.changePart}
            setDataToParent={this.getChildInformation}
            receiverContainers={receiverContainers}
            setDisplayPart={this.setDisplayPart}
            disabled={disabled}
            setDisabled={this.setDisabled}
          />
        )
      case 2:
        return (
          <VaccineInformations
            handleChange={this.handleChange}
            changePart={this.changePart}
            setDataToParent={this.getChildInformation}
            conservationMethod={0}
            disabled={disabled}
            setDisabled={this.setDisabled}
          />
        )
      case 3:
        return (
          <OrderConformity
            handleSubmit={this.handleSubmit}
            handleChange={this.handleChange}
            setDataToParent={this.getChildInformation}
            getDataFromChild={this.getHistoryValueFromChild}
            receptionType={receptionType}
            needNitrogenLevelControl
            disabled={disabled}
            formData={formData}
            setDisabled={this.setDisabled}
            receiverContainers={container}
          />
        )
      default:
        return <div />
    }
  }

  setDisabled(disabled) {
    this.setState({ disabled })
  }

  changePart() {
    const { part, progress } = this.state
    this.setState({ part: part + 1, progress: progress + 34 })
  }

  handleChange(e) {
    const { name, value, type, checked } = e.target
    const { formData } = this.state
    if (type === 'checkbox') {
      return this.setState({ [name]: checked })
    }
    if (name === 'capacity' && value === '') {
      return this.setState({ isOther: true })
    }
    if (name === 'receptionType') {
      if (value === '2') {
        return this.setState({ displayPart: false })
      }
      return this.setState({ displayPart: true })
    }
    return this.setState({ formData: { ...formData, [name]: value } })
  }

  handleSubmit(_e, stopped = false) {
    const { formData } = this.state
    const { token, intl } = this.props
    const { history, container, vaccinesBatches, nitrogenLevelControl } = formData
    const { id } = container
    formData.stopped = stopped

    const receptionDate = moment(formData.dateSelected)
      .hour(formData.controlHour)
      .minutes(formData.controlMinutes)
      .format()

    const vaccinesBatchesToSubmit = []

    vaccinesBatches.forEach((vaccine, index) => {
      vaccinesBatchesToSubmit[index] = {
        ampoulesNumber: vaccine.ampoulesNumber,
        batchNumber: vaccine.batchNumber,
        expirationDate: moment().format(),
        receptionDate,
        vaccineArticle: vaccine.vaccineArticle
      }
    })

    history.receptionDate = receptionDate

    const formDataToSubmit = {
      container: {
        id,
        control: {
          nitrogenLevel: nitrogenLevelControl,
          nitrogenLevelState: 1
        }
      },
      history,
      vaccinesBatches: vaccinesBatchesToSubmit
    }
    Axios.post(`${api}/api/vaccines_batches/frozen_plus_nitrogen`, formDataToSubmit, {
      headers: { Authorization: token }
    })
      .then(() => {
        if (stopped === true) {
          Swal.fire({
            type: 'info',
            title: intl.formatMessage({ id: 'app.title.stoppedReception' }),
            text: intl.formatMessage({ id: 'app.message.stoppedReception' }),
            customClass: {
              icon: 'swalInfoIcon',
              title: 'swalTitle',
              content: 'swalContentText',
              confirmButton: 'blueBtn'
            }
          }).then(() => globalHistory.goBack())
        } else {
          validatedAction(intl.formatMessage({ id: 'app.alert.success' }))
        }
      })
      .catch(err => catchError(err.response))
  }

  render() {
    const { progress, part, displayPart } = this.state
    const { intl } = this.props
    return (
      <div className="Container row">
        <div className="col s12">
          <HeaderReception text={intl.formatMessage({ id: 'app.part.nitrogenReceipt' })} />
          {displayPart && <ProgressBarReception progress={progress} part={part} nitrogenPart />}
          {this.getContent()}
        </div>
      </div>
    )
  }
}

export default injectIntl(Nitrogen)
