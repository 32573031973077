import React from 'react'
import icons from '../../../assets/icons/icons'

const CurrentPageIndicator = props => {
  let icon = ''
  let name = ''
  const { location, intl } = props
  if (location.pathname.includes('/equipments/containers')) {
    icon = icons.blueEquipment
    name = `${intl.formatMessage({ id: 'app.part.equipment' })} •  ${intl.formatMessage({
      id: `app.part.containers`
    })}`
  } else if (location.pathname.includes('/equipments/management')) {
    icon = icons.blueEquipment
    name = `${intl.formatMessage({ id: 'app.part.equipment' })} •  ${intl.formatMessage({
      id: 'app.part.controlEquipment'
    })}`
  } else if (location.pathname.includes('/equipments/fridges')) {
    icon = icons.blueEquipment
    name = `${intl.formatMessage({ id: 'app.part.equipment' })} •  ${intl.formatMessage({
      id: 'app.part.fridges'
    })}`
  } else if (location.pathname.includes('/equipments/equipment-history')) {
    icon = icons.blueEquipment
    name = `${intl.formatMessage({ id: 'app.part.equipment' })} •  ${intl.formatMessage({
      id: 'app.part.history'
    })}`
  } else if (location.pathname.includes('/user')) {
    icon = icons.blueUser
    name = intl.formatMessage({ id: 'app.part.user' })
  } else if (location.pathname.includes('/planning')) {
    icon = icons.blueCalendar
    name = 'Planning'
  } else if (location.pathname.includes('/stocks/stock-availibility')) {
    icon = icons.blueStock
    name = `${intl.formatMessage({ id: 'app.part.stock' })} •  ${intl.formatMessage({
      id: 'app.part.stockAvailibility'
    })}`
  } else if (location.pathname.includes('/stocks/order-received')) {
    icon = icons.blueStock
    name = `${intl.formatMessage({ id: 'app.part.stock' })} •  ${intl.formatMessage({
      id: 'app.part.orderReceived'
    })}`
  } else if (location.pathname.includes('/stocks/out-stock')) {
    icon = icons.blueStock
    name = `${intl.formatMessage({ id: 'app.part.stock' })} •  ${intl.formatMessage({
      id: 'app.part.outStock'
    })}`
  } else if (location.pathname.includes('/stocks/stock-history')) {
    icon = icons.blueStock
    name = `${intl.formatMessage({ id: 'app.part.stock' })} •  ${intl.formatMessage({
      id: 'app.part.history'
    })}`
  } else if (location.pathname.includes('/alerts')) {
    icon = icons.blueBell
    name = `${intl.formatMessage({ id: 'app.part.alerts' })}`
  } else if (location.pathname.includes('/parameters/equipment')) {
    icon = icons.blueParam
    name = `${intl.formatMessage({ id: 'app.part.parameters' })} •  ${intl.formatMessage({
      id: 'app.part.equipmentParams'
    })}`
  } else if (location.pathname.includes('/parameters/stock')) {
    icon = icons.blueParam
    name = `${intl.formatMessage({ id: 'app.part.parameters' })} •  ${intl.formatMessage({
      id: 'app.part.stockParams'
    })}`
  } else if (location.pathname.includes('/parameters/parameter-vaccination')) {
    icon = icons.blueParam
    name = `${intl.formatMessage({ id: 'app.part.parameters' })} •  ${intl.formatMessage({
      id: 'app.part.vaccinationParams'
    })}`
  } else if (location.pathname.includes('/parameters/parameter-history')) {
    icon = icons.blueParam
    name = `${intl.formatMessage({ id: 'app.part.parameters' })} •  ${intl.formatMessage({
      id: 'app.part.history'
    })}`
  } else if (location.pathname.includes('/parameters')) {
    icon = icons.blueParam
    name = `${intl.formatMessage({ id: 'app.part.parameters' })}`
  } else if (location.pathname.includes('/stock_parameters/add')) {
    icon = icons.blueParam
    name = `${intl.formatMessage({ id: 'app.part.parameters' })} • ${intl.formatMessage({
      id: 'app.part.addStockParameters'
    })}`
  } else if (location.pathname.includes('/vaccination')) {
    icon = icons.blueChick
    name = `${intl.formatMessage({ id: 'app.part.vaccination' })}`
  } else if (location.pathname.includes('/dailyReport')) {
    icon = icons.blueCheck
    name = `${intl.formatMessage({ id: 'app.part.dailyReport' })}`
  } else if (location.pathname.includes('/documentation')) {
    icon = icons.blueDocumentation
    name = `${intl.formatMessage({ id: 'app.part.documentation' })}`
  } else if (location.pathname.includes('/history')) {
    icon = icons.blueHistory
    name = `${intl.formatMessage({ id: 'app.part.history' })}`
  } else if (location.pathname.includes('/traceability/vaccine_or_product')) {
    icon = icons.blueTraceability
    name = `${intl.formatMessage({ id: 'app.part.traceability' })} • ${intl.formatMessage({
      id: 'app.part.traceability_vaccine_or_product'
    })}`
  } else if (location.pathname.includes('/traceability/order')) {
    icon = icons.blueTraceability
    name = `${intl.formatMessage({ id: 'app.part.traceability' })} • ${intl.formatMessage({
      id: 'app.part.traceability_order'
    })}`
  } else if (location.pathname.includes('/traceability/equipment')) {
    icon = icons.blueTraceability
    name = `${intl.formatMessage({ id: 'app.part.traceability' })} • ${intl.formatMessage({
      id: 'app.part.traceability_equipment'
    })}`
  } else {
    icon = icons.blueHouse
    name = 'Accueil'
  }

  return (
    <div className="topBarPart">
      <img src={icon} alt="icon" />
      <p>{name}</p>
    </div>
  )
}

export default CurrentPageIndicator
