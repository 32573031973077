import React from 'react'
import { injectIntl } from 'react-intl'
import StatePill from '../../../StatePill'
import TextArea from '../../partials/TextArea'
import NoBlockHistoryPart from '../NoBlockHistoryPart/NoBlockHistoryPart'

const NoBlockVaccinationPart = props => {
  const {
    intl,
    handleChange,
    history,
    vaccinationParamId,
    action,
    noBlockPartAlert,
    noBlockPartAlertMax,
    noBlockPartAlertMin,
    vaccin,
    productBag,
    diluent,
    dye,
    congruentPartNoBlockValueMaxValue,
    congruentPartNoBlockValueMin,
    congruentPartNoBlockValueMaxPercent,
    blockPartNoBlockValueMax
  } = props

  if (history) {
    return <NoBlockHistoryPart action={action} vaccinationParamId={vaccinationParamId} />
  }

  switch (vaccinationParamId) {
    case 0:
      return (
        <div>
          <TextArea
            label="app.label.mixingVaccinesNoForbidden"
            name="noBlockPartAlert"
            value1={noBlockPartAlert}
            handleChange={handleChange}
          />
          <div className="divider greyDivider" />
        </div>
      )

    case 1:
      return (
        <div className="row">
          <div className="col s12">
            <div className="row input">
              <div className="col s12">
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.useDiffProductOrVaccine' })}
                      &nbsp;
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label className="col s3">
                      <input
                        type="checkbox"
                        className="filled-in checkbox"
                        name="vaccin"
                        checked={vaccin}
                        onClick={handleChange}
                        readOnly
                      />
                      <span>{intl.formatMessage({ id: 'app.label.vaccin' })}</span>
                    </label>
                    <label className="col s3">
                      <input
                        type="checkbox"
                        className="filled-in checkbox"
                        name="productBag"
                        checked={productBag}
                        onClick={handleChange}
                        readOnly
                      />
                      <span>{intl.formatMessage({ id: 'app.label.productBag' })}</span>
                    </label>
                    <label className="col s3">
                      <input
                        type="checkbox"
                        className="filled-in checkbox"
                        name="diluent"
                        checked={diluent}
                        onClick={handleChange}
                        readOnly
                      />
                      <span>{intl.formatMessage({ id: 'app.label.diluent' })}</span>
                    </label>
                    <label className="col s3">
                      <input
                        type="checkbox"
                        className="filled-in checkbox"
                        name="dye"
                        checked={dye}
                        onClick={handleChange}
                        readOnly
                      />
                      <span>{intl.formatMessage({ id: 'app.label.dye' })}</span>
                    </label>
                  </div>
                </div>
                <TextArea
                  label="none"
                  name="noBlockPartAlert"
                  value1={noBlockPartAlert}
                  handleChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )

    case 2:
      return (
        <div>
          <TextArea
            name="noBlockPartAlert"
            value1={noBlockPartAlert}
            handleChange={handleChange}
            label="app.label.batchAlert"
          />
          <div className="divider greyDivider" />
        </div>
      )

    case 8:
      return (
        <div className="row input">
          <div className="col s12">
            <div className="row">
              <div className="col s12">
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockValueMin" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.ifVaccinatedChickPercent' })}
                      &nbsp;
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="input col s6">
                    <label htmlFor="noBlockValueMin" className="labelParams">
                      {intl.formatMessage({
                        id: 'app.label.ifVaccinatedChickPercentLowerIncluded'
                      })}
                      &nbsp;
                    </label>
                    <input type="number" value={congruentPartNoBlockValueMin} disabled />
                  </div>
                </div>
              </div>
            </div>
            <TextArea
              name="noBlockPartAlertMin"
              value1={noBlockPartAlertMin}
              handleChange={handleChange}
            />
          </div>
          <div className="col s12 input">
            <div className="row">
              <p>
                <StatePill type="noBlockTitleParams" />
                <span className="orangeTitle">
                  {intl.formatMessage({ id: 'app.label.alertNoBlockTitleParams' })}
                </span>
              </p>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="noBlockValueMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.ifVaccinatedChickPercent' })}
                  &nbsp;
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input col s3">
                <label htmlFor="noBlockIfVaccinatedChickPercentHigher" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.ifVaccinatedChickPercentHigherIncluded' })}
                  &nbsp;
                </label>
                <input type="number" value={congruentPartNoBlockValueMaxPercent} disabled />
              </div>
              <div className="input col s3">
                <label htmlFor="noBlockNbChickOrdered" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.NbChickOrdered' })}
                  &nbsp;
                </label>
                <input type="number" value={congruentPartNoBlockValueMaxValue} disabled />
              </div>
            </div>
            <TextArea
              name="noBlockPartAlertMax"
              value1={noBlockPartAlertMax}
              handleChange={handleChange}
            />
          </div>
          <div className="divider greyDivider" />
        </div>
      )

    default:
      return (
        <div className="row">
          <div className="col s12 input">
            <div className="row">
              <div className="col">
                <label htmlFor="noBlockValueMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.ifTimeBeetweenIncluded' })}
                  &nbsp;
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input col s3">
                <input type="number" value={congruentPartNoBlockValueMin} disabled />
              </div>
              <div className="input col s3">
                <input
                  name="blockPartNoBlockValueMax"
                  type="number"
                  value={blockPartNoBlockValueMax}
                  disabled
                />
              </div>
            </div>
            <TextArea
              name="noBlockPartAlert"
              value1={noBlockPartAlert}
              handleChange={handleChange}
            />
          </div>
          <div className="divider greyDivider" />
        </div>
      )
  }
}

export default injectIntl(NoBlockVaccinationPart)
