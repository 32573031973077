import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import { goBack } from '../../globalAction'
import BlockPart from '../BlockPart/BlockPart'
import NoBlockPart from '../NoBlockPart/NoBlockPart'
import ValenceContext from '../../../context/ValencesContext'

class AddAndEditArticle extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    M.AutoInit()
  }

  static contextType = ValenceContext

  render() {
    const { data, intl, history } = this.props
    const { type, history: hist } = data
    const { action } = hist
    const { changes, name } = action
    const { valence, administration, conservation, dosesNumber } = changes || action
    const { getValenceLabelByKey } = this.context
    const pathGoBack = '/parameters/parameter-history'

    return (
      <div className="Container stockHistory">
        <div>
          <div className="pageTitle pageHeader row">
            <div className="col s12">
              <div
                role="button"
                className="flexInline pointer"
                onClick={() => goBack(pathGoBack, history)}
                onKeyPress={() => goBack(pathGoBack, history)}
                tabIndex={0}
              >
                <div className="goBackBtn">
                  <i role="button" tabIndex="0" className="material-icons">
                    chevron_left
                  </i>
                </div>
                <h5>
                  <span>
                    {intl.formatMessage({
                      id: `app.label.parameters.action.${type}`
                    })}
                  </span>
                </h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <div className="row">
                <div className="row form">
                  <div className="col s12">
                    <div className="row">
                      <div className="col s12">
                        <div className="row">
                          <h4>
                            {`${action.speciality} - ${action.name} (${
                              action.dosesNumber
                            } ${intl.formatMessage({
                              id: 'app.label.doses'
                            })})`}
                          </h4>
                          {type === 3 && (
                            <h5>
                              <span>
                                {intl.formatMessage({ id: 'app.label.modificationDone' })}
                              </span>
                            </h5>
                          )}
                        </div>
                        <div className="row params">
                          <div className="col s12">
                            <div className="row">
                              <div className="col s12">
                                <div className="row">
                                  <div className="col s6 input">
                                    {changes && (
                                      <div className="row">
                                        <div className="col s12">
                                          <label
                                            htmlFor="congruentPartTempBetweenMin"
                                            className="labelParams"
                                          >
                                            {intl.formatMessage({ id: 'app.label.before' })}
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                    <div className="row input">
                                      {((changes && changes.name !== undefined) ||
                                        action.name !== undefined) && (
                                        <div className="col s6">
                                          <label htmlFor="noBlockPartAlert" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.name' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>{name}</span>
                                        </div>
                                      )}
                                      {((changes && changes.valence !== undefined) ||
                                        action.valence !== undefined) && (
                                        <div className="col s6">
                                          <label htmlFor="noBlockPartAlert" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.valence' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>
                                            {getValenceLabelByKey(
                                              changes && changes.valence !== undefined
                                                ? valence[0].toString()
                                                : action.valence
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    <div className="row input">
                                      {((changes && changes.administration !== undefined) ||
                                        action.administration !== undefined) && (
                                        <div className="col s6">
                                          <label htmlFor="administration" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.administration' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>
                                            {intl.formatMessage({
                                              id: `app.administrationMethod.${
                                                changes && changes.administration !== undefined
                                                  ? administration[0].toString()
                                                  : action.administration
                                              }`
                                            })}
                                          </span>
                                        </div>
                                      )}
                                      {((changes && changes.conservation !== undefined) ||
                                        action.conservation !== undefined) && (
                                        <div className="col s6">
                                          <label htmlFor="conservation" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.conservation' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>
                                            {intl.formatMessage({
                                              id: `app.label.conservationMethod.${
                                                changes && changes.conservation !== undefined
                                                  ? conservation[0].toString()
                                                  : action.conservation
                                              }`
                                            })}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    <div className="row input">
                                      {((changes && changes.dosesNumber !== undefined) ||
                                        action.dosesNumber !== undefined) && (
                                        <div className="col s6">
                                          <label htmlFor="noBlockPartAlert" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.dosesNumber' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>
                                            {changes && changes.dosesNumber
                                              ? dosesNumber[0]
                                              : action.dosesNumber}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {changes && (
                                    <div className="col s6">
                                      <div className="row input">
                                        <div className="col s12">
                                          <label
                                            htmlFor="congruentPartTempBetweenMin"
                                            className="labelParams"
                                          >
                                            {intl.formatMessage({ id: 'app.label.after' })}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="row input">
                                        <div className="col s6">
                                          <label htmlFor="noBlockPartAlert" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.name' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>{name}</span>
                                        </div>
                                        <div className="col s6">
                                          <label htmlFor="noBlockPartAlert" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.valence' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>{getValenceLabelByKey(action.valence)}</span>
                                        </div>
                                      </div>
                                      <div className="row input">
                                        <div className="col s6">
                                          <label htmlFor="noBlockPartAlert" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.administration' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>
                                            {intl.formatMessage({
                                              id: `app.administrationMethod.${action.administration}`
                                            })}
                                          </span>
                                        </div>
                                        <div className="col s6">
                                          <label htmlFor="noBlockPartAlert" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.conservation' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>
                                            {intl.formatMessage({
                                              id: `app.label.conservationMethod.${action.conservation}`
                                            })}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="row input">
                                        <div className="col s6">
                                          <label htmlFor="noBlockPartAlert" className="labelParams">
                                            {intl.formatMessage({ id: 'app.label.dosesNumber' })}
                                            &nbsp;:&nbsp;
                                          </label>
                                          <span>{action.dosesNumber}</span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {changes && <div className="divider greyDivider" />}
                                </div>
                              </div>
                            </div>
                            <div>
                              {changes && changes.nonBlockingExpirationAlert && (
                                <div className="row">
                                  <div className="col s12">
                                    <NoBlockPart articleId action={action} history />
                                  </div>
                                </div>
                              )}
                              {changes && changes.blockingExpirationAlert && (
                                <div className="row">
                                  <div className="col s12">
                                    <BlockPart articleId action={action} history />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(withRouter(AddAndEditArticle))
