import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import icons from '../../assets/icons/icons'

class LastAlert extends React.Component {
  constructor(props) {
    super(props)
    this.state = { alert: null }
    this.getAlert = this.getAlert.bind(this)
  }

  componentDidMount() {
    this.getAlert()
  }

  getAlert() {
    const { cat, equipmentId, notifs, type } = this.props

    if (notifs === '') {
      return
    }

    notifs.reverse()
    notifs.forEach(el => {
      const elementId = type === 'int' ? parseInt(el.equipmentId) : el.equipmentId

      if (elementId === equipmentId && cat.includes(el.category)) {
        this.setState({
          alert: { id: el.uuid, message: el.message, type: el.type, category: el.category }
        })
      }
    })
  }

  render() {
    const { intl } = this.props
    const { alert } = this.state

    return (
      alert &&
      alert.type && (
        <div className="row equipmentDescription alertPart">
          <Link to={{ pathname: `/alerts/${alert.id}` }}>
            <div className="col s12">
              <div className="alertBlockHead">
                <img src={icons.whiteBell} className={`alert-icon bg-${alert.type}`} alt="icon" />
                <h5 className={`title ${alert.type === 3 ? 'title-red' : 'title-orange'}`}>
                  {intl.formatMessage({ id: `app.alertCategory.${alert.category}` })}
                </h5>
              </div>
              <p>{alert.message}</p>
            </div>
          </Link>
        </div>
      )
    )
  }
}

const mapStateToProps = state => {
  return {
    notifs: state.mercure.notifications
  }
}

export default injectIntl(connect(mapStateToProps)(LastAlert))
