import React from 'react'
import { injectIntl } from 'react-intl'

const OrderChickInfoBlock = props => {
  const { intl, group, preparation, textHeader2 } = props

  return (
    <div className="row col s12">
      <div className="col s12">
        <div className="custom-card card col s12 noPadding">
          <div className="col s12 card-header">
            <div className="col s6 bold em1-2">
              {intl.formatMessage({ id: 'app.label.ORDER' })} {group.number}
            </div>
            <div className="col s6 bold">
              {intl.formatMessage({ id: 'app.label.clientfr319' })} {textHeader2}
            </div>
          </div>
          <div className="col s12 card-content">
            <div className="card-block row">
              <div className="col s6">
                {group.totalChicks} {intl.formatMessage({ id: 'app.label.chicks' })}
              </div>
              {((group && group.boxesNumber) ||
                (preparation && preparation.planningOrder.boxesNumber)) && (
                <div className="col s6">
                  {group ? group.boxesNumber : preparation.planningOrder.boxesNumber}{' '}
                  {intl.formatMessage({ id: 'app.label.boxes' })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(OrderChickInfoBlock)
