import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import Axios from 'axios'
import M from 'materialize-css'
import { catchError } from '../../../../../../../actions'
import { api } from '../../../../../../../parameters'
import { pBagVolumes } from '../../../../../../../config/productConfig'
import VolumeSelector from './partials/VolumeSelector'
import PBagBatchSelector from './partials/PBagBatchSelector'
import BlockHeader from './partials/BlockHeader'
import LoadingPage from '../../../../../../LoadingPage'

class PBagBlock extends React.Component {
  constructor() {
    super()
    this.state = {
      selectedBatches: [{}]
    }

    this.setBatches = this.setBatches.bind(this)
    this.handleVolumeChange = this.handleVolumeChange.bind(this)
    this.setTotalQuantity = this.setTotalQuantity.bind(this)
    this.setSelectedBatches = this.setSelectedBatches.bind(this)
    this.setNeededQuantity = this.setNeededQuantity.bind(this)
    this.handleReinit = this.handleReinit.bind(this)
  }

  componentDidMount() {
    const { neededDoses, volume, setTotalNeededDoses } = this.props

    this.setState({
      totalQuantity: Math.ceil(neededDoses / volume),
      neededQuantity: Math.ceil(neededDoses / volume)
    })
    setTotalNeededDoses(Math.ceil(neededDoses / volume) * volume)
    M.AutoInit()
  }

  componentDidUpdate(prevProps, prevState) {
    const { neededDoses, volume, setTotalNeededDoses } = this.props
    const { neededQuantity } = this.state

    if (prevState.neededQuantity !== neededQuantity) {
      this.setState({ loading: true }, () => {
        this.setState({
          totalQuantity: Math.ceil(neededDoses / volume)
        })
        setTotalNeededDoses(Math.ceil(neededDoses / volume) * volume)
        this.setBatches()
      })
    }

    if (prevProps.volume !== volume) {
      this.setState({ loading: true }, () => {
        this.setState({
          totalQuantity: Math.ceil(neededDoses / volume),
          neededQuantity: Math.ceil(neededDoses / volume)
        })
        setTotalNeededDoses(Math.ceil(neededDoses / volume) * volume)
        this.setBatches()
      })
    }

    M.AutoInit()
  }

  setBatches() {
    const { token, volume, hatchingDate } = this.props
    const { neededQuantity, selectedBatches } = this.state
    let volumeToFind

    pBagVolumes.forEach(pBagConfig => {
      if (pBagConfig.doses === volume) {
        volumeToFind = pBagConfig.value
      }
    })

    Axios.get(
      `${api}/api/product_bags?expirationDate[after]=${hatchingDate}&order[expirationDate]=asc&volume=${volumeToFind}`,
      { headers: { Authorization: token } }
    )
      .then(res => {
        let totalUnits = 0
        res.data['hydra:member'].forEach(batch => {
          totalUnits += batch.units
        })

        if (neededQuantity <= totalUnits) {
          const batches = res.data['hydra:member']

          selectedBatches.forEach(selectedBatch => {
            batches.forEach((batch, i) => {
              if (selectedBatch.batch !== undefined && batch.id === selectedBatch.batch.id) {
                batches.splice(i, 1)
              }
            })
          })

          this.setState({
            batches,
            loading: false
          })
        } else {
          this.setState({ batches: [], loading: false })
        }
      })
      .catch(err => catchError(err.response))
  }

  setTotalQuantity(totalQuantity) {
    this.setState({ totalQuantity })
  }

  setNeededQuantity(neededQuantity) {
    this.setState({ neededQuantity })
  }

  setSelectedBatches(selectedBatches) {
    const { result, setResult } = this.props
    result.productBags = selectedBatches

    this.setState({ selectedBatches })
    setResult(result)
  }

  handleVolumeChange(volume) {
    const { setVolume } = this.props
    const { totalQuantity } = this.state

    setVolume(parseInt(volume))
    this.setSelectedBatches([{}])
    this.setNeededQuantity(totalQuantity)
  }

  handleReinit() {
    const {
      neededDoses,
      volume,
      setShowVaccines,
      setTotalNeededDoses,
      enableSubmit,
      setResult
    } = this.props

    setResult({})

    setShowVaccines(false)
    this.setState(
      {
        totalQuantity: Math.ceil(neededDoses / volume),
        neededQuantity: Math.ceil(neededDoses / volume),
        selectedBatches: [{}]
      },
      () => {
        setTotalNeededDoses(Math.ceil(neededDoses / volume) * volume)
        enableSubmit(false)
        this.setBatches()
      }
    )
  }

  render() {
    const { neededDoses, volume, setShowVaccines } = this.props
    const { totalQuantity, selectedBatches, neededQuantity, batches, loading } = this.state

    if (loading === true) {
      return <LoadingPage />
    }

    return (
      <div className="row custom-card form">
        <div className="card-content">
          <BlockHeader />
          <div className="divider greyDivider" />
          <VolumeSelector
            volume={volume}
            setVolume={this.handleVolumeChange}
            setTotalQuantity={this.setTotalQuantity}
            neededDoses={neededDoses}
            totalQuantity={totalQuantity}
            selectedBatches={selectedBatches}
            handleReinit={this.handleReinit}
            disabled={selectedBatches[0].batch !== undefined}
          />
          {batches &&
            selectedBatches.map((selectedBatch, i) => (
              <PBagBatchSelector
                key={selectedBatch.id}
                volume={volume}
                neededQuantity={neededQuantity}
                selectedBatches={selectedBatches}
                i={i}
                setSelectedBatches={this.setSelectedBatches}
                disabled={selectedBatch.disabled}
                setShowVaccines={setShowVaccines}
                setNeededQuantity={this.setNeededQuantity}
                batches={batches}
              />
            ))}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(PBagBlock))
