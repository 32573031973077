const sortIncompatibilities = (array, arrayToSearch) => {
  const meltArray = []
  const arrayToSearchIds = []

  arrayToSearch.forEach(el => {
    arrayToSearchIds.push(el.id)
  })

  array.nonBlocking.forEach(speciality => {
    let specialitiesToPush

    if (arrayToSearchIds.includes(speciality.id)) {
      const indexToFind = arrayToSearchIds.indexOf(speciality.id)
      specialitiesToPush = arrayToSearch[indexToFind]
    }

    delete specialitiesToPush.incompatibilities
    delete specialitiesToPush.minimalStockAlert
    delete specialitiesToPush.vaccineArticles

    meltArray.push(specialitiesToPush)
  })

  array.blocking.forEach(speciality => {
    let specialitiesToPush

    if (arrayToSearchIds.includes(speciality.id)) {
      const indexToFind = arrayToSearchIds.indexOf(speciality.id)
      specialitiesToPush = arrayToSearch[indexToFind]
    }

    delete specialitiesToPush.incompatibilities
    delete specialitiesToPush.minimalStockAlert
    delete specialitiesToPush.vaccineArticles

    meltArray.push(specialitiesToPush)
  })

  return meltArray
}

export default sortIncompatibilities
