import React from 'react'
import { injectIntl } from 'react-intl'
import MoreInfosTable from './MoreInfosTable/MoreInfosTable'

const MoreInfos = props => {
  const { group, show } = props

  return (
    <div className={`moreInfos ${show ? '' : 'hidden'}`}>
      <MoreInfosTable group={group} />
    </div>
  )
}

export default injectIntl(MoreInfos)
