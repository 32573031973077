import { FormattedMessage } from 'react-intl'
import React, { useContext } from 'react'
import moment from 'moment'
import HeadSingleHistoryStock from './HeadSingleHistoryStock'
import FridgeBlock from './FridgeBlock'
import ValenceContext from '../../../../context/ValencesContext'

const RefrigeratedVaccineDestock = props => {
  const { history, operator, date, type } = props
  const { action: data } = history
  const { vaccinesBatch, fridge } = data
  const { getValenceLabelByKey } = useContext(ValenceContext)

  return (
    <div className="Container stockHistory">
      <HeadSingleHistoryStock operator={operator} date={date} action={type}>
        <div className="row historyCard">
          <div className="col s12 card">
            <div className="row">
              <FridgeBlock fridge={fridge} />
              <div className="col s12">
                <div className="row">
                  <h5>
                    <FormattedMessage id="app.label.vaccineDestock" />
                  </h5>
                </div>
                <div className="row">
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.batchesNumber" />
                        &nbsp;:&nbsp;
                      </span>
                      {vaccinesBatch.batchNumber}
                    </p>
                  </div>
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.vaccinesNumber" />
                        &nbsp;:&nbsp;
                      </span>
                      {data.toOutStock}
                    </p>
                  </div>
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.expirationDate" />
                        &nbsp;:&nbsp;
                      </span>
                      {moment(vaccinesBatch.expirationDate).format('DD/MM/YYYY')}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.speciality" />
                        &nbsp;:&nbsp;
                      </span>
                      {vaccinesBatch.speciality}
                    </p>
                  </div>
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.article" />
                        &nbsp;:&nbsp;
                      </span>
                      {vaccinesBatch.article}
                    </p>
                  </div>
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.valence" />
                        &nbsp;:&nbsp;
                      </span>
                      {getValenceLabelByKey(vaccinesBatch.valence)}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.conservationMethod" />
                        &nbsp;:&nbsp;
                      </span>
                      <FormattedMessage
                        id={`app.label.conservationMethod.${vaccinesBatch.conservationMethod}`}
                      />
                    </p>
                  </div>
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.bulbsNumber" />
                        &nbsp;:&nbsp;
                      </span>
                      {vaccinesBatch.newStorageUnits}
                    </p>
                  </div>
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.dosesNumberRest" />
                        &nbsp;:&nbsp;
                      </span>
                      {vaccinesBatch.newStorageDoses}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.reason" />
                        &nbsp;:&nbsp;
                      </span>
                      {data.reason}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HeadSingleHistoryStock>
    </div>
  )
}

export default RefrigeratedVaccineDestock
