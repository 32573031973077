import React from 'react'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Planning from '../../../components/Planning/Planning'
import { goBack } from '../../../components/globalAction'

class InjectableVaccine extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      vaccinesType: [0],
      vaccineType: 0
    }
  }

  render() {
    const { intl, token, role, history } = this.props
    const { vaccineType, vaccinesType } = this.state
    const pathGoBack = '/vaccination/realisation'

    return (
      <div className="row Container stockAvailibilityItem">
        <div className="col s12 content">
          <div className="row pageHeader pageTitle itemHeader">
            <div
              onClick={() => goBack(pathGoBack, history)}
              role="button"
              tabIndex="0"
              onKeyPress={() => goBack(pathGoBack, history)}
              className="goBackBtn col s1"
            >
              <i className="material-icons">chevron_left</i>
            </div>
            <h5 className="col s12">
              {intl.formatMessage({ id: 'app.label.realisation' })}&nbsp;
              {intl.formatMessage({ id: 'app.part.injectableVaccines' })}
            </h5>
          </div>
          <Planning
            token={token}
            role={role}
            injectableVaccine
            isRealisation
            vaccineType={vaccineType}
            vaccinesType={vaccinesType}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(InjectableVaccine)))
