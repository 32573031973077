import React, { useContext } from 'react'
import { injectIntl } from 'react-intl'
import { GenerationsContext, ProductsForOrderContext } from '../../../../../context/contexts'

const GroupInfos = props => {
  const { group, intl, isCreation } = props
  const { getGenerationLabelByKey } = useContext(GenerationsContext)
  const { getProductForOrderLabelByKey } = useContext(ProductsForOrderContext)

  return (
    <>
      <div className="row line">
        <div className="col s6">
          <span className="bold">{intl.formatMessage({ id: 'app.label.client' })}: </span>{' '}
          {group.clientName}
        </div>
        <div className="col s6">
          <span className="bold">{intl.formatMessage({ id: 'app.label.address' })}: </span>{' '}
          {group.address}
        </div>
      </div>
      <div className="row line">
        <div className="col s6">
          <span className="bold">{intl.formatMessage({ id: 'app.label.farmer' })}: </span>{' '}
          {group.farmer}
        </div>
        <div className="col s6">
          <span className="bold">{intl.formatMessage({ id: 'app.label.hatchery' })}: </span>{' '}
          {group.hatchery}
        </div>
      </div>
      <div className="row line">
        <div className="col s6">
          <span className="bold">{intl.formatMessage({ id: 'app.label.product' })}: </span>{' '}
          {getProductForOrderLabelByKey(group.product)}
        </div>
      </div>
      {!isCreation && (
        <div className="row line">
          <div className="col s6">
            <span className="bold">{intl.formatMessage({ id: 'app.label.boxesNumber' })}: </span>{' '}
            {group.boxesNumber}
          </div>
          <div className="col s6">
            <span className="bold">
              {intl.formatMessage({ id: 'app.label.chicksNumberPerBox' })}:{' '}
            </span>{' '}
            {group.chicksPerBox}
          </div>
        </div>
      )}
      <div className="row line">
        <div className="col s6">
          <span className="bold">{intl.formatMessage({ id: 'app.label.chicksNumber' })}: </span>{' '}
          {group.totalChicks}
        </div>
        <div className="col s6">
          <span className="bold">{intl.formatMessage({ id: 'app.label.generation' })}: </span>{' '}
          {getGenerationLabelByKey(group.generation)}
        </div>
      </div>
    </>
  )
}
export default injectIntl(GroupInfos)
