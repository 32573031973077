import React from 'react'
import { injectIntl } from 'react-intl'

const SmallPrepInfos = props => {
  const { prep, intl } = props
  const { bottle, fridgeDiluentBatch, fridgeDyeBatch, diluent, dye, pBag, vaccines } = prep

  const melt = {}

  vaccines.forEach(vacc => {
    vacc.batches.forEach(batch => {
      if (melt[batch.batch] === undefined) {
        melt[batch.batch] = 0
      }

      melt[batch.batch] += 1
    })
  })

  return (
    <>
      <div className="col s4 prepHistory">
        {pBag && (
          <div className="col s12">
            <span className="bold">{intl.formatMessage({ id: 'app.label.productBag' })}: </span>{' '}
            {pBag}
          </div>
        )}
        {bottle && (
          <div className="col s12">
            <span className="bold">{intl.formatMessage({ id: 'app.label.bottle' })}: </span>{' '}
            {`${bottle} ${intl.formatMessage({
              id: 'app.label.doses'
            })}`}
          </div>
        )}
        <div className="col s12">
          <span className="bold">{intl.formatMessage({ id: 'app.label.vaccinesBatches' })}: </span>{' '}
          {Object.keys(melt).map(i => (
            <div key={i}>
              {i} x {melt[i]}
            </div>
          ))}
        </div>
        {fridgeDiluentBatch && (
          <div className="col s12">
            <span className="bold">{intl.formatMessage({ id: 'app.label.diluent' })}: </span>{' '}
            {fridgeDiluentBatch.fridge.name} - {fridgeDiluentBatch.diluentBatch.batchNumber}
          </div>
        )}
        {!fridgeDiluentBatch && diluent && (
          <div className="col s12">
            <span className="bold">{intl.formatMessage({ id: 'app.label.diluent' })}: </span>{' '}
            {diluent}
          </div>
        )}
        {fridgeDyeBatch.length !== 0 && (
          <div className="col s12">
            <span className="bold">{intl.formatMessage({ id: 'app.label.dye' })}: </span>{' '}
            {fridgeDyeBatch.fridge.name} - {fridgeDyeBatch.dyeBatch.batchNumber} -{' '}
            {intl.formatMessage({
              id: `app.color.${fridgeDyeBatch.dyeBatch.color}`
            })}
          </div>
        )}
        {!fridgeDyeBatch && dye && (
          <div className="col s12">
            <span className="bold">{intl.formatMessage({ id: 'app.label.dye' })}: </span> {dye}
          </div>
        )}
      </div>
    </>
  )
}
export default injectIntl(SmallPrepInfos)
