import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'
import icons from '../../../../assets/icons/icons'

const NoVaccineApplicator = props => {
  const { intl, path, type } = props
  let icon
  let titleTranslation
  let messageTranslation = 'messageControlNozzle'

  switch (type) {
    case 0:
      icon = icons.injectedVaccine
      titleTranslation = 'injected'
      messageTranslation = 'messageControlNeedle'
      break
    case 1:
      icon = icons.thinDropVaccine
      titleTranslation = 'fineDrops'
      break
    case 2:
      icon = icons.bigDropVaccine
      titleTranslation = 'bigDrops'
      break
    default:
  }

  return (
    <div className="row vaccineCard">
      <div className="col m10 offset-m1 content">
        <form className="card vaccination form">
          <div className="row CardEquipmentTopTitle">
            <img src={icon} alt="injected vaccine icon" className="CardEquipmentTopIcon col m2" />
            <div className="col m10">
              <h5>
                <FormattedMessage id={`app.equipment.${titleTranslation}`} />
              </h5>
              <p>
                <FormattedMessage id="app.label.lastControlDate" />
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <div className=" divider greyDivider" />
            </div>
          </div>
          <div className="row">
            <div className="col offset-s1 s10 messageControlNeedle">
              {intl.formatMessage({ id: `app.label.${messageTranslation}` })}
              <Link to={{ pathname: path }}>
                {intl.formatMessage({ id: 'app.label.linkControlNeedle' })}
              </Link>
              .
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default withRouter(injectIntl(NoVaccineApplicator))
