import React from 'react'
import VaccineBlock from './VaccineBlock/VaccineBlock'

const VaccinesBlock = props => {
  const { order, neededDoses, volume, result, setResult, hatchingDate } = props

  return (
    <>
      {order.vaccines.map((vaccine, i) => (
        <VaccineBlock
          key={i}
          vaccine={vaccine}
          neededDoses={neededDoses * vaccine.doseType}
          volume={volume}
          result={result}
          setResult={setResult}
          hatchingDate={hatchingDate}
        />
      ))}
    </>
  )
}

export default VaccinesBlock
