import React from 'react'
import { injectIntl } from 'react-intl'

const ProcessStateSpan = props => {
  const { intl, processState } = props

  return (
    <span className={`planningProcessState noPadding ${processState === 6 ? 'blue-text' : ''}`}>
      {intl.formatMessage({ id: `app.processState.${processState}` })}
    </span>
  )
}

export default injectIntl(ProcessStateSpan)
