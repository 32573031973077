import React from 'react'
import { injectIntl } from 'react-intl'
import TextArea from '../../partials/TextArea'
import CenterButton from '../../../CenterButton'
import icons from '../../../../assets/icons/icons'

const NoBlockDoseNumberPart = props => {
  const { intl, handleChange, doses, dosesMessage } = props

  const getDosesEvent = () => {
    return {
      target: {
        name: 'doses',
        value: doses
      }
    }
  }

  const preventMinus = e => {
    if (e.code === 'Minus') {
      e.preventDefault()
    }
  }

  const deleteDose = indexToDelete => {
    const event = getDosesEvent()
    event.target.value = doses.filter((_, index) => index !== indexToDelete)
    handleChange(event)
  }

  return (
    <div>
      <TextArea
        name="noBlockPartAlert"
        value1={dosesMessage}
        handleChange={handleChange}
        label="app.label.doseNumberAlert"
      />
      <div className="divider greyDivider" />
      <table className="table">
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: 'app.label.prepared_dose_number' })}</th>
            <th>{intl.formatMessage({ id: 'app.label.min_limit' })}</th>
            <th>{intl.formatMessage({ id: 'app.label.max_limit' })}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {doses.map((dose, i) => {
            return (
              <tr key={i}>
                <td>
                  <input
                    name="doseNumber"
                    type="number"
                    onChange={e => {
                      const event = getDosesEvent()
                      event.target.value[i].doseNumber = Number(e.target.value)
                      handleChange(event)
                    }}
                    min="0"
                    onKeyPress={preventMinus}
                    value={dose.doseNumber}
                  />
                </td>
                <td>
                  <input
                    name="minLimit"
                    type="number"
                    onChange={e => {
                      const event = getDosesEvent()
                      event.target.value[i].min = {
                        limit: Number(e.target.value)
                      }
                      handleChange(event)
                    }}
                    min="0"
                    onKeyPress={preventMinus}
                    value={dose.min ? dose.min.limit : null}
                  />
                </td>
                <td>
                  <input
                    name="maxLimit"
                    type="number"
                    onChange={e => {
                      const event = getDosesEvent()
                      event.target.value[i].max = {
                        limit: Number(e.target.value)
                      }
                      handleChange(event)
                    }}
                    min="0"
                    onKeyPress={preventMinus}
                    value={dose.max ? dose.max.limit : null}
                  />
                </td>
                <td>
                  <div
                    onClick={() => deleteDose(i)}
                    role="button"
                    tabIndex="0"
                    onKeyPress={() => deleteDose(i)}
                    className="pointer"
                  >
                    <img
                      src={icons.whiteDelete}
                      alt="delete orders"
                      style={{ marginLeft: '5px', filter: 'invert(1)' }}
                    />
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="marginTop20">
        <CenterButton
          text={intl.formatMessage({ id: 'app.label.add' })}
          onClick={() => {
            const event = getDosesEvent()
            event.target.value = [
              ...event.target.value,
              {
                doseNumber: null,
                min: null,
                max: null
              }
            ]
            handleChange(event)
          }}
        />
      </div>
    </div>
  )
}

export default injectIntl(NoBlockDoseNumberPart)
