import React from 'react'
import { injectIntl } from 'react-intl'
import DatepickerBlock from '../../../../../../../DatepickerBlock'

const DatePickerPart = props => {
  const { intl, getDataFromChild } = props

  return (
    <div className="row custom-card form">
      <div className="card-content">
        <div className="row input col s6 card-block">
          <DatepickerBlock
            setDataFromChild={getDataFromChild}
            name="expectedDateOfVaccination"
            label={intl.formatMessage({ id: 'app.label.requestVaccinationDate' })}
          />
        </div>
      </div>
    </div>
  )
}

export default injectIntl(DatePickerPart)
