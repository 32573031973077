import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import Swal from 'sweetalert2'
import { catchError } from '../../../actions'
import icons from '../../../assets/icons/icons'
import { api } from '../../../parameters'
import { validatedAction } from '../../globalAction'
import LoadingPage from '../../LoadingPage'
import NoVaccineApplicator from './partials/NoVaccineApplicator'

class BigDropsForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      toControl: false,
      sprayUniformity: false,
      rinsePrimeMachines: false,
      nebulisationCOCC: false,
      alertMsg: '',
      nozzles: ['null']
    }
    this.handleChange = this.handleChange.bind(this)
    this.nozzleChange = this.nozzleChange.bind(this)
    this.loadNozzles = this.loadNozzles.bind(this)
  }

  componentDidMount() {
    const { token } = this.props
    Axios.get(`${api}/api/equipment_parameters?paramId=10`, { headers: { Authorization: token } })
      .then(res => {
        this.setState({
          alertMsg: res.data['hydra:member'][0].blockingAlert.message
        })
        Axios.get(`${api}/api/equipment_parameters?paramId=9`, {
          headers: { Authorization: token }
        })
          .then(result => {
            this.setState({
              paramId: result.data['hydra:member'][0].id
            })
          })
          .catch(err => catchError(err.response))
      })
      .catch(err => catchError(err.response))
    this.loadNozzles()
  }

  loadNozzles() {
    const { isControl, token } = this.props

    Axios.get(`${api}/api/vaccine_applicators?machine=2`, {
      headers: { Authorization: token }
    })
      .then(result => {
        if (result.data['hydra:member'].length !== 0)
          this.setState({ nozzles: result.data['hydra:member'] })

        Axios.get(`${api}/api/vaccination_machines/last_control/COCC`, {
          headers: { Authorization: token }
        })
          .then(res => {
            const { date } = res.data

            if (moment(date).format('WW') !== moment().format('WW')) {
              this.setState({ toControl: true })
            } else {
              isControl(2)
            }

            this.setState({ loading: false })
          })
          .catch(err => {
            if (err.response.status === 404) {
              this.setState({ toControl: true })
            }
          })

        this.setState({ loading: false })
      })
      .catch(err => catchError(err.response))
  }

  handleSubmit(e) {
    e.preventDefault()
    const { token, isControl } = this.props
    const { sprayUniformity, rinsePrimeMachines, nozzles } = this.state
    const data = {
      control: {
        name: 'COCC',
        sprayUniformity,
        rinsePrimeMachines,
        nozzles
      }
    }

    Axios.post(`${api}/api/vaccination_machines/controls/spray`, data, {
      headers: { Authorization: token }
    })
      .then(() => {
        this.loadNozzles()
        validatedAction('', 0, '#vaccination')
        isControl(2)
        this.setState({ toControl: false })
      })
      .catch(err => catchError(err.response, false))
  }

  nozzleChange(index, e) {
    const { name, value, id } = e.target
    const { nozzles } = this.state
    const nozzle = {
      id: parseInt(id),
      name,
      volume: parseFloat(value)
    }

    nozzles[index] = nozzle
    this.setState({ nozzles })
  }

  handleChange(e) {
    const { checked, name, value, type } = e.target
    const { intl } = this.props
    const { alertMsg } = this.state

    if (type === 'checkbox') {
      if (!checked) {
        let text = intl.formatMessage({ id: `app.alert.${name}` })

        if (name === 'sprayUniformity') {
          text = alertMsg
        }

        Swal.fire({
          type: 'error',
          title: intl.formatMessage({ id: 'app.alert.error' }),
          text,
          customClass: {
            icon: 'swalErrorIcon',
            title: 'swalTitle',
            content: 'swalContentText',
            confirmButton: 'blueBtn'
          }
        })
      }

      return this.setState({ [name]: checked })
    }

    return this.setState({ [name]: value })
  }

  render() {
    const { intl } = this.props
    const {
      sprayUniformity,
      rinsePrimeMachines,
      nebulisationCOCC,
      loading,
      nozzles,
      toControl,
      paramId
    } = this.state

    if (loading) {
      return <LoadingPage />
    }

    if (nozzles && nozzles.includes('null')) {
      return <NoVaccineApplicator type={2} path={`/parameters/equipment/edit/${paramId}`} />
    }

    if (!toControl) {
      return null
    }

    return (
      <div className="row vaccineCard">
        <div className="col m10 offset-m1 content">
          <form className="card vaccination form" onSubmit={this.handleSubmit.bind(this)}>
            <div className="row CardEquipmentTopTitle">
              <img
                src={icons.bigDropVaccine}
                alt="injected vaccine icon"
                className="CardEquipmentTopIcon col m2"
              />
              <div className="col m10">
                <h5>
                  <FormattedMessage id="app.equipment.bigDrops" />
                </h5>
                <p>
                  <FormattedMessage id="app.label.lastControlDate" />
                </p>
              </div>
            </div>
            <div className="divider greyDivider" />
            <div className="row">
              <div className="row">
                <div className="col m6">
                  <div className="switch">
                    <p className="switchLabel">
                      <FormattedMessage id="app.equipment.question.uniformitySpray" />
                    </p>
                    <label htmlFor="sprayUnif">
                      {intl.formatMessage({ id: 'app.label.no' })}
                      <input
                        id="sprayUnif"
                        type="checkbox"
                        name="sprayUniformity"
                        value={sprayUniformity}
                        onChange={this.handleChange}
                      />
                      <span className="lever" />
                      {intl.formatMessage({ id: 'app.label.yes' })}
                    </label>
                  </div>
                </div>
                <div className="col m6">
                  <div className="switch">
                    <p className="switchLabel">
                      <FormattedMessage id="app.equipment.question.rinsePrime" />
                    </p>
                    <label htmlFor="rinseMachines">
                      {intl.formatMessage({ id: 'app.label.no' })}
                      <input
                        id="rinseMachines"
                        type="checkbox"
                        name="rinsePrimeMachines"
                        value={rinsePrimeMachines}
                        onChange={this.handleChange}
                      />
                      <span className="lever" />
                      {intl.formatMessage({ id: 'app.label.yes' })}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col m6">
                  <div className="switch">
                    <p className="switchLabel">
                      <FormattedMessage id="app.equipment.question.nebulisationCOCC" />
                    </p>
                    <label htmlFor="COCCnebul">
                      {intl.formatMessage({ id: 'app.label.no' })}
                      <input
                        id="COCCnebul"
                        type="checkbox"
                        name="nebulisationCOCC"
                        value={nebulisationCOCC}
                        onChange={this.handleChange}
                      />
                      <span className="lever" />
                      {intl.formatMessage({ id: 'app.label.yes' })}
                    </label>
                  </div>
                </div>
              </div>
              <div className="divider greyDivider" />
              {nebulisationCOCC && (
                <div className="row">
                  <div className="col m12">
                    <p className="vaccinationInputsFieldLabel">
                      <FormattedMessage id="app.equipment.question.BICocc" />
                    </p>
                  </div>
                  {nozzles &&
                    nozzles.map((nozzle, i) => (
                      <div key={nozzle.id} className="input col s3">
                        <label htmlFor={nozzle.id}>{nozzle.name}</label>
                        <input
                          id={nozzle.id}
                          type="text"
                          name={nozzle.name}
                          onChange={e => this.nozzleChange(i, e)}
                        />
                      </div>
                    ))}
                </div>
              )}
            </div>
            <div className="row">
              <div className="col offset-m4 m4 center">
                <input
                  type="submit"
                  className="btn blueBtn"
                  value="Valider"
                  disabled={!sprayUniformity || !rinsePrimeMachines || !nebulisationCOCC}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default injectIntl(BigDropsForm)
