import HatcheryAviagen from './hatcheryAviagen.json'
import HatcheryHubbard from './hatcheryHubbard.json'

const Configs = {
  Hatchery: 'hatchery'
}

const getConfig = config => {
  const aviagen = process.env.REACT_APP_COMPANY === 'aviagen'
  if (config === Configs.Hatchery) return aviagen ? HatcheryAviagen : HatcheryHubbard
  return null
}
export { Configs, getConfig }
