import React from 'react'
import { injectIntl } from 'react-intl'

const THead = props => {
  const { intl } = props

  return (
    <thead>
      <tr>
        <th>{intl.formatMessage({ id: 'app.label.speciality' })}</th>
        <th>{intl.formatMessage({ id: 'app.label.article' })}</th>
        <th>{intl.formatMessage({ id: 'app.label.batchNumber' })}</th>
        <th>{intl.formatMessage({ id: 'app.label.expirationDate' })}</th>
        <th>{intl.formatMessage({ id: 'app.label.ampoulesOrVialsNumber' })}</th>
      </tr>
    </thead>
  )
}

export default injectIntl(THead)
