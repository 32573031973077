import React from 'react'
import { injectIntl } from 'react-intl'

function Translation({ intl, id }) {
  return (
    <>
      {intl.formatMessage({
        id
      })}
    </>
  )
}

export default injectIntl(Translation)
