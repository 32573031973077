import moment from 'moment'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import icons from '../../../../assets/icons/icons'
import GenerationsContext from '../../../../context/GenerationsContext'

class TableDropDown extends Component {
  constructor(props) {
    super(props)
    this.handleRedirected = this.handleRedirected.bind(this)
  }

  handleRedirected(path) {
    const { history } = this.props
    history.push(path)
  }

  render() {
    const { group, id, intl, isPreparation, prestationsContext } = this.props
    const { clientName, farmer, hatchingDate, generation, prestations } = group
    const { getGenerationLabelByKey } = this.context
    const { getPrestationLabelByKey } = prestationsContext
    return (
      <tr className="hidden" id={`tr${id}`}>
        <td colSpan="12">
          <div className={`${isPreparation ? 'col s7' : 'col s12'}`}>
            <div className={`row noPadding ${isPreparation ? '' : 'marginTop20'}`}>
              {hatchingDate && (
                <div className="col s4 noPadding">
                  <span className="bold">
                    {intl.formatMessage({ id: 'app.label.hatchingWeek' })}
                  </span>
                  &nbsp;/&nbsp;
                  <span className="font-weight-normal noPadding">
                    {moment(hatchingDate).format('W')}
                  </span>
                </div>
              )}
              {generation !== null && (
                <div className="col s4 noPadding">
                  <span className="bold">{intl.formatMessage({ id: 'app.label.generation' })}</span>
                  &nbsp;/&nbsp;
                  <span className="font-weight-normal noPadding">
                    {getGenerationLabelByKey(generation)}
                  </span>
                </div>
              )}
            </div>
            <div className="row noPadding">
              {!isPreparation && (
                <>
                  {clientName && (
                    <div className="col s4 noPadding">
                      <span className="bold">{intl.formatMessage({ id: 'app.label.client' })}</span>
                      &nbsp;/&nbsp;
                      <span className="font-weight-normal noPadding">{clientName}</span>
                    </div>
                  )}
                  {farmer !== null && (
                    <div className="col s4 noPadding">
                      <span className="bold">{intl.formatMessage({ id: 'app.label.farmer' })}</span>
                      &nbsp;/&nbsp;
                      <span className="font-weight-normal noPadding">{farmer}</span>
                    </div>
                  )}
                </>
              )}
            </div>
            {isPreparation && (
              <div className="row noPadding">
                <div className="col s12 noPadding">
                  {intl.formatMessage({ id: 'app.label.interventions' })}
                  &nbsp;
                  {prestations &&
                    prestations.map(prestation => (
                      <div key={prestation} className="font-weight-normal noPadding">
                        {getPrestationLabelByKey(prestation)}
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
          {isPreparation && (
            <div className="col s5 flex noPadding marginTop20">
              <div
                className="col s2 noPadding pointer"
                role="button"
                onClick={() =>
                  this.handleRedirected(`/vaccination/preparation/edit-vaccine/${group.id}`)
                }
                onKeyPress={() =>
                  this.handleRedirected(`/vaccination/preparation/edit-vaccine/${group.id}`)
                }
                tabIndex={0}
              >
                <img className="editBtn" src={icons.bluePenCircle} alt="Pen logo" />
              </div>
              <div className="col s8 noPadding">
                <div
                  className="btn blueBtn"
                  role="button"
                  onClick={() =>
                    this.handleRedirected(`/vaccination/preparation/process/${group.id}`)
                  }
                  onKeyPress={() =>
                    this.handleRedirected(`/vaccination/preparation/process/${group.id}`)
                  }
                  tabIndex={0}
                >
                  <span className="noPadding">
                    {intl.formatMessage({ id: 'app.label.prepareVaccine' })}
                  </span>
                </div>
              </div>
            </div>
          )}
        </td>
      </tr>
    )
  }
}

export default injectIntl(withRouter(TableDropDown))
TableDropDown.contextType = GenerationsContext
