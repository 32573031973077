import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'
import icons from '../../assets/icons/icons'
import { logoutUser, getAllNotifs, catchError } from '../../actions'
import store from '../../store'
import { addNotification, removeNotification } from '../NotificationManager'
import CurrentPageIndicator from './partials/CurrentPageIndicator'
import LoadingPage from '../LoadingPage'
import AlertsContext from '../../context/AlertsContext'

class TopBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    const { token } = this.props

    getAllNotifs(token)
      .then(res => this.setState({ loading: res.loading }))
      .catch(err => catchError(err.response))
    addNotification(token)
    removeNotification()
    store.subscribe(() => this.forceUpdate())
  }

  static contextType = AlertsContext

  render() {
    const { location, username, intl, notifications } = this.props
    const { loading } = this.state
    const { selectMode, setSelectMode, deleteSelectedOrders } = this.context
    const isAlertsList = location.pathname === '/alerts'
    if (loading === true) {
      return <LoadingPage />
    }

    return (
      <div className="topBar row">
        <div className="col offset-m2 offset-l2 l6 m5">
          <CurrentPageIndicator location={location} intl={intl} />
        </div>
        <div className="col l3 flex">
          {isAlertsList && (
            <button
              type="button"
              className="btn redBtn"
              onClick={() => {
                if (!selectMode) {
                  setSelectMode(true)
                } else {
                  deleteSelectedOrders(intl)
                }
              }}
            >
              {intl.formatMessage({
                id: !selectMode ? 'app.label.selectAlerts' : 'app.label.closeAlerts'
              })}
            </button>
          )}
        </div>
        <div className="col l4 m5">
          <ul className="topBarUl">
            <li className="topBarUlLi">
              <p>{username}</p>
            </li>
            <li className="topBarUlLi">
              <Link to={{ pathname: '/alerts' }} className="topBarAlerts">
                <img src={icons.blackBell} alt="black bell icon" id="topBarBell" />{' '}
                {notifications.length !== 0 ? (
                  <span id="redAlertsTag" className="redAlertsTag">
                    {notifications.length}
                  </span>
                ) : (
                  <span className="greyAlertsTag">{notifications.length}</span>
                )}
              </Link>
            </li>
            <li className="topBarUlLi">
              <div
                role="button"
                className="logout topBarNavItem"
                onClick={() => logoutUser()}
                onKeyPress={() => logoutUser()}
                tabIndex="0"
              >
                <img src={icons.blueLogout} alt="icon logout" id="logoutIcon" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    notifications: state.mercure.notifications
  }
}

export default injectIntl(withRouter(connect(mapStateToProps, { logoutUser })(TopBar)))
