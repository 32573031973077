import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { catchError } from '../../../../../actions'
import { api } from '../../../../../parameters'
import FridgeInformationsForm from './FridgeInformationForm'

class FridgeInformations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fridgesList: [{}],
      fridgeSelected: [],
      disabledForm: [],
      fridgeListSelected: [],
      fridges: []
    }
    this.getAllFridge = this.getAllFridge.bind(this)
    this.getChildData = this.getChildData.bind(this)
    this.setFridgeListSelected = this.setFridgeListSelected.bind(this)
    this.addFridge = this.addFridge.bind(this)
  }

  componentDidMount() {
    this.getAllFridge()
  }

  setFridgeListSelected(fridgeId, index) {
    const { fridgeListSelected } = this.state
    const fridgeListSelectedTmp = fridgeListSelected
    fridgeListSelectedTmp[index] = parseInt(fridgeId)
    this.setState({ fridgeListSelected: fridgeListSelectedTmp })
  }

  getChildData(name, value, index) {
    const { fridgeSelected, fridgesList } = this.state
    const { setDataToParent } = this.props
    if (name === 'selectFridge') {
      fridgeSelected[index] = { id: parseFloat(value) }
      return this.setState({ fridgeSelected }, () =>
        setDataToParent('fridgeSelected', fridgeSelected)
      )
    }
    fridgesList[index][name] = value
    return this.setState({ fridgesList }, () => setDataToParent('fridgeSelected', fridgeSelected))
  }

  getAllFridge() {
    const { token, setDataToParent } = this.props
    Axios.get(`${api}/api/fridges`, { headers: { Authorization: token } })
      .then(res => {
        const fridges = res.data['hydra:member']
        setDataToParent('fridges', fridges)
        this.setState({ fridges }, () => M.AutoInit())
      })
      .catch(err => catchError(err.response))
  }

  addFridge() {
    const { fridgesList, disabledForm } = this.state
    if (fridgesList.length > 0) {
      disabledForm.push(true)
    }
    fridgesList.push({})
    this.setState({ fridgesList, disabledForm }, () => M.AutoInit())
  }

  render() {
    const { fridgesList, fridges, fridgeSelected, fridgeListSelected, disabledForm } = this.state
    const { token, changePart, intl } = this.props
    return (
      <div className="row">
        <div className="col s12">
          <div className="row">
            <div className="col s12">
              {fridgesList.map((_item, i) => (
                <FridgeInformationsForm
                  key={`fridge${i}`}
                  index={i}
                  fridges={fridges}
                  token={token}
                  fridgeSelected={fridgeSelected}
                  fridgeListSelected={fridgeListSelected}
                  setFridgeListSelected={this.setFridgeListSelected}
                  setDataToParent={this.getChildData}
                  disabledForm={disabledForm}
                />
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col offset-s5 s5">
              <div className="btnBlockPart">
                <button
                  type="button"
                  className="btn blueBtn"
                  onClick={changePart}
                  disabled={fridgeSelected.length === 0}
                >
                  {intl.formatMessage({ id: 'app.label.next' })}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token
})

export default injectIntl(connect(mapStateToProps)(FridgeInformations))
