import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { catchError } from '../../../actions'
import icons from '../../../assets/icons/icons'
import { closePage, messageInfo } from '../../../components/globalAction'
import LoadingPage from '../../../components/LoadingPage'
import VaccineForm from '../../../components/Vaccination/Preparation/VaccineForm'
import OrderChickInfoBlock from '../../../components/Vaccination/Realisation/partials/OrderChickInfoBlock'
import ShapsCodesContext from '../../../context/ShapsCodesContext'
import history from '../../../history'
import { api } from '../../../parameters'

class EditVaccines extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      group: {},
      loading: true,
      result: { changes: [] },
      vaccines: [{}],
      administrationMethod: null
    }

    this.changeVaccineType = this.changeVaccineType.bind(this)
    this.editOtherVaccine = this.editOtherVaccine.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeShapsCode = this.handleChangeShapsCode.bind(this)
  }

  componentDidMount() {
    const { match, token } = this.props
    const { result } = this.state
    const { id } = match.params

    Axios.get(`${api}/api/planning_order_groups/${id}`, { headers: { Authorization: token } })
      .then(res => {
        const injectableVaccines = []
        const thinDropsVaccines = []
        const bigDropsVaccines = []
        if (res.data.injectionOrder !== null) {
          res.data.injectionOrder.vaccines.forEach(vaccine => {
            injectableVaccines.push(vaccine)
          })
          this.setState({
            administrationMethod: 0
          })
        }

        if (res.data.thinDropsOrder !== null) {
          res.data.thinDropsOrder.vaccines.forEach(vaccine => {
            thinDropsVaccines.push(vaccine)
          })
          this.setState({
            administrationMethod: 1
          })
        }

        if (res.data.bigDropsOrder !== null) {
          res.data.bigDropsOrder.vaccines.forEach(vaccine => {
            bigDropsVaccines.push(vaccine)
          })
          this.setState({
            administrationMethod: 2
          })
        }

        result.orderNumber = res.data.number
        result.client = res.data.clientName
        result.product = res.data.product

        this.setState({
          bigDropsVaccines,
          group: res.data,
          injectableVaccines,
          loading: false,
          result,
          thinDropsVaccines
        })
      })
      .catch(err => catchError(err.response))
  }

  getDataFromChild(data, index) {
    const { result } = this.state
    result.changes[index] = data

    this.setState({ result })
  }

  static contextType = ShapsCodesContext

  changeVaccineType(e) {
    const { bigDropsVaccines, injectableVaccines, thinDropsVaccines } = this.state
    const { name, value } = e.target

    let vaccinesByAdminMethod = []
    let speciality = 0

    if (value === '1') {
      vaccinesByAdminMethod = injectableVaccines
      speciality = 0
      this.setState({
        administrationMethod: 0
      })
    } else if (value === '2') {
      vaccinesByAdminMethod = thinDropsVaccines
      speciality = 1
      this.setState({
        administrationMethod: 1
      })
    } else {
      vaccinesByAdminMethod = bigDropsVaccines
      speciality = 2
      this.setState({
        administrationMethod: 2
      })
    }

    this.setState({ [name]: value, vaccinesByAdminMethod, speciality }, () => M.AutoInit())
  }

  editOtherVaccine() {
    const { vaccines } = this.state

    vaccines.push({})
    this.setState({ vaccines }, () => M.AutoInit())
  }

  handleSubmit() {
    const { intl, token } = this.props
    const { result } = this.state

    Axios.post(`${api}/api/planning_order_groups/edit_vaccines/alert_vet`, result, {
      headers: { Authorization: token }
    })
      .then(() => messageInfo(intl.formatMessage({ id: 'app.label.askEditToVet' })))
      .catch(err => catchError(err.response, 0))
  }

  handleChangeShapsCode(e) {
    const { result } = this.state
    const { value } = e.target
    const parseIntValue = parseInt(value)

    result.shapsCode = parseIntValue

    this.setState({ result }, () => M.FormSelect.init(document.querySelectorAll('select')))
  }

  render() {
    const { intl } = this.props
    const {
      injectableVaccines,
      thinDropsVaccines,
      bigDropsVaccines,
      group,
      loading,
      speciality,
      vaccinesByAdminMethod,
      vaccines,
      vaccineType,
      administrationMethod
    } = this.state
    const { shapsCodes } = this.context

    if (loading) {
      return <LoadingPage />
    }

    return (
      <div className="Container row">
        <div className="col s12">
          <div className="pageHeader pageTitle">
            <div className="flex col m12">
              <h5 className="col m12">{intl.formatMessage({ id: 'app.label.editVaccines' })}</h5>
            </div>
            <div className="col">
              <div
                role="button"
                tabIndex="0"
                onKeyPress={() => history.goBack()}
                onClick={() => closePage(intl)}
                className="goBackBtn"
              >
                <i className="material-icons">close</i>
              </div>
            </div>
          </div>
          <div className="row col s12">
            <div className="flex col s12">
              <span>
                <img src={icons.redDanger} alt="red danger logo" />
              </span>
              <span className="red-text ">
                {intl.formatMessage({ id: 'app.label.changeOfVaccineMustBeValidatedByVet' })}
              </span>
            </div>
          </div>
          <OrderChickInfoBlock group={group} textHeader2={group.clientName} />
          <div className="row col s12">
            <div className="col s12">
              <div className="card col s12 noPadding">
                <div className="col s12">
                  {injectableVaccines.length > 0 && (
                    <p className="col s4 radio">
                      <label htmlFor="vaccineType1">
                        <input
                          name="vaccineType"
                          value="1"
                          id="vaccineType1"
                          type="radio"
                          onClick={this.changeVaccineType}
                        />
                        <span className="labelCustom">
                          {intl.formatMessage({ id: 'app.label.injectedVaccine' })}
                        </span>
                      </label>
                    </p>
                  )}
                  {thinDropsVaccines.length > 0 && (
                    <p className="col s4 radio">
                      <label htmlFor="vaccineType2">
                        <input
                          name="vaccineType"
                          value="2"
                          id="vaccineType2"
                          type="radio"
                          onClick={this.changeVaccineType}
                        />
                        <span className="labelCustom">
                          {intl.formatMessage({ id: 'app.label.fineDrops' })}
                        </span>
                      </label>
                    </p>
                  )}
                  {bigDropsVaccines.length > 0 && (
                    <p className="col s4 radio">
                      <label htmlFor="vaccineType3">
                        <input
                          name="vaccineType"
                          value="3"
                          id="vaccineType3"
                          type="radio"
                          onClick={this.changeVaccineType}
                        />
                        <span className="labelCustom">
                          {intl.formatMessage({ id: 'app.label.bigDrops' })}
                        </span>
                      </label>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row col s12">
            <div className="col s12">
              {vaccineType && (
                <div className="col s12 form">
                  <div className="input col s6">
                    <label htmlFor="shapsCode" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.newShapsCode' })}*
                    </label>
                    <select
                      className="select-wrapper"
                      name="shapsCode"
                      defaultValue="0"
                      onChange={this.handleChangeShapsCode}
                    >
                      <option value="0" disabled>
                        {intl.formatMessage({ id: 'app.label.selectShapsCode' })}
                      </option>
                      {shapsCodes &&
                        shapsCodes
                          .filter(
                            shapsCode => shapsCode.administrationMethod === administrationMethod
                          )
                          .map(shapsCode => (
                            <option key={shapsCode.id} value={shapsCode.name}>
                              {`${shapsCode.id} - ${shapsCode.name}`}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
              )}
              {vaccineType &&
                vaccines.map((vaccine, i) => (
                  <VaccineForm
                    key={i}
                    index={i}
                    setDataToParent={this.getDataFromChild}
                    speciality={speciality}
                    vaccine={vaccine}
                    vaccinesByAdminMethod={vaccinesByAdminMethod}
                    selectCodes={shapsCodes.filter(
                      shapsCode => shapsCode.administrationMethod === administrationMethod
                    )}
                  />
                ))}
              {vaccineType && (
                <div className="row">
                  <div className="col s12 center marginTop20">
                    <button
                      className="btn blueBtn marginRight10"
                      type="button"
                      onClick={this.editOtherVaccine}
                    >
                      {intl.formatMessage({ id: 'app.label.editOtherVaccin' })}
                    </button>
                    <button className="btn blueBtn" type="button" onClick={this.handleSubmit}>
                      {intl.formatMessage({ id: 'app.button.validate' })}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default withRouter(injectIntl(connect(mapStateToProps)(EditVaccines)))
