import React from 'react'
import BlockItem from '../../../../../../../components/Vaccination/EndRealisation/BlockItem'

const HeaderProductBagPart = props => {
  const { prep } = props

  return <BlockItem orderNumberDisplay prep={prep} />
}

export default HeaderProductBagPart
