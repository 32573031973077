import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import roles from '../../../config/userRoles.json'

function OutStockButton(props) {
  const { intl, to, role } = props
  const { ROLE_HATCHING_RESP, ROLE_HATCH_RESP } = roles

  if (role.includes(ROLE_HATCHING_RESP) || role.includes(ROLE_HATCH_RESP)) {
    return (
      <Link to={to} className="btn blueBtnResponsive">
        {intl.formatMessage({ id: 'app.label.outStock' })}
      </Link>
    )
  }
  return null
}

const mapStateToProps = state => {
  return {
    role: state.auth.role
  }
}

export default injectIntl(connect(mapStateToProps)(OutStockButton))
