import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { catchError } from '../../../actions'
import icons from '../../../assets/icons/icons'
import CenterButton from '../../../components/CenterButton'
import { closePage, validatedAction } from '../../../components/globalAction'
import LoadingPage from '../../../components/LoadingPage'
import NoBlockPart from '../../../components/Parameters/NoBlockPart/NoBlockPart'
import Notification from '../../../components/Parameters/Notification'
import AlerteDoses from '../../../components/Parameters/Stock/partials/AlerteDoses'
import Modal from '../../../components/Parameters/Stock/partials/Modal'
import IncompatibilityTable from '../../../components/Parameters/VeterinaryPart/IncompatibilityTable'
import { conservationMethods } from '../../../config/productConfig'
import roles from '../../../config/userRoles.json'
import { administrations, api, valences } from '../../../parameters'
import ValenceContext from '../../../context/ValencesContext'

class VaccinesParamsItem extends React.Component {
  constructor() {
    super()
    this.state = {
      articles: [],
      articlesModified: [],
      disabledList: [],
      loading: true
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
    this.deleteArticle = this.deleteArticle.bind(this)
  }

  componentDidMount() {
    const { token, match, role } = this.props
    const { id } = match.params
    const { ROLE_VET } = roles

    Axios.get(`${api}/api/vaccine_specialities/${id}`, {
      headers: { Authorization: token }
    })
      .then(res =>
        this.setState(
          {
            specialityData: res.data,
            name: res.data.name,
            nameBeforeEdit: res.data.name,
            valence: res.data.valence,
            conservation: res.data.conservationMethod,
            administration: res.data.administrationMethod,
            articles: res.data.vaccineArticles,
            descContent: res.data.descContent,
            channels: res.data.channels,
            incompatibilities: res.data.incompatibilities || { blocking: [], nonBlocking: [] },
            parameters: { channels: res.data.channels }
          },
          () => {
            if (role.includes(ROLE_VET)) {
              Axios.get(`${api}/api/vaccine_specialities`, {
                headers: {
                  Authorization: token
                }
              })
                .then(res2 => {
                  const specialities = res2.data['hydra:member']

                  specialities.forEach((spe, i) => {
                    if (spe.id === parseInt(id)) {
                      specialities.splice(i, 1)
                    }
                  })

                  this.setState({ specialities, loading: false }, () => M.AutoInit())
                })
                .catch(err2 => catchError(err2.response))
            } else {
              this.setState({ loading: false }, () => M.AutoInit())
            }
          }
        )
      )
      .catch(err => catchError(err.response))
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  handleChange(e) {
    const { name, value, type, checked } = e.target
    if (type === 'checkbox') {
      this.setState({ [name]: checked })
    } else {
      if (name === 'administration') {
        this.setState({ incompatibilities: { blocking: [], nonBlocking: [] } })
      }
      this.setState({ [name]: value })
    }
  }

  handleSubmit() {
    const { match, token } = this.props
    const {
      administration,
      articles,
      articlesModified,
      channels,
      conservation,
      descContent,
      incompatibilities,
      hatchingRespAlerts,
      hatchRespAlerts,
      name,
      noBlockUnitsStock,
      noBlockPartAlertStock,
      operatorAlerts,
      specialityData,
      vetAlerts,
      valence
    } = this.state
    const { id } = match.params

    let formData = {}

    if (hatchRespAlerts) channels.push(hatchRespAlerts)
    if (vetAlerts) channels.push(vetAlerts)
    if (hatchingRespAlerts) channels.push(hatchingRespAlerts)
    if (operatorAlerts) channels.push(operatorAlerts)

    formData = {
      name: name || specialityData.name,
      valence: valence === undefined ? specialityData.valence : parseInt(valence),
      conservationMethod:
        conservation === undefined ? specialityData.conservationMethod : parseInt(conservation),
      administrationMethod:
        administration === undefined ? specialityData.conservationMethod : parseInt(administration),
      descTitle: '',
      descContent: descContent || specialityData.descContent,
      minimalStockAlert: {
        type: 2,
        value: noBlockUnitsStock || specialityData.minimalStockAlert.value || '',
        message: noBlockPartAlertStock || specialityData.minimalStockAlert.message || ''
      },
      incompatibilities,
      channels
    }

    const formDataArticle = [{}]

    articles.forEach((article, index) => {
      if (articlesModified.includes(index)) {
        formDataArticle[index] = {
          name: article.name,
          dosesNumber: article.dosesNumber,
          speciality: `${api}/api/vaccine_specialities/${specialityData.id}`,
          minimalStockAlert: specialityData.minimalStockAlertAlert,
          nonBlockingExpirationAlert: {
            type: '2',
            value:
              article.noBlockExpirationDate ||
              (article.nonBlockingExpirationAlert ? article.nonBlockingExpirationAlert.value : ''),
            message:
              article.noBlockPartAlertExpirationDate ||
              (article.nonBlockingExpirationAlert ? article.nonBlockingExpirationAlert.message : '')
          },
          blockingExpirationAlert: {
            type: '3',
            value:
              article.blockExpirationDate || article.blockingExpirationAlert
                ? article.blockingExpirationAlert.value
                : '',
            message:
              article.blockAlert || article.blockingExpirationAlert
                ? article.blockingExpirationAlert.message
                : ''
          }
        }
        Axios.patch(`${api}/api/vaccine_articles/${articles[index].id}`, formDataArticle[index], {
          headers: { Authorization: token }
        })
          .then()
          .catch(err => catchError(err.response))
      }
    })

    Axios.patch(`${api}/api/vaccine_specialities/${id}`, formData, {
      headers: { Authorization: token }
    })
      .then(() => {
        validatedAction('')
      })
      .catch(err => catchError(err.response))
  }

  deleteArticle(e) {
    const { token, intl } = this.props
    const { id } = e.target

    Swal.fire({
      title: intl.formatMessage({ id: 'app.alert.areYouSureTitle' }),
      text: intl.formatMessage({ id: 'app.alert.deleteArticleVaccine' }),
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: intl.formatMessage({ id: 'app.label.no' }),
      confirmButtonText: intl.formatMessage({ id: 'app.label.yes' }),
      customClass: {
        icon: 'swalErrorIcon',
        title: 'swalTitle',
        content: 'swalContentText',
        confirmButton: 'blueBtn',
        cancelButton: 'blueBtn'
      }
    }).then(result => {
      if (result.value) {
        Axios.delete(`${api}/api/vaccine_articles/${id}`, { headers: { Authorization: token } })
          .then(() => validatedAction('', 0))
          .catch(err => catchError(err.response))
      }
    })
  }

  static contextType = ValenceContext

  render() {
    const { intl, match, role } = this.props
    const {
      administration,
      articles,
      articlesModified,
      loading,
      descContent,
      incompatibilities,
      name,
      nameBeforeEdit,
      parameters,
      specialityData,
      disabledList,
      specialities,
      valence
    } = this.state
    const { params } = match
    const { id } = params
    const { getValenceLabelByKey } = this.context
    const { ROLE_VET } = roles

    if (loading) {
      return <LoadingPage />
    }

    return (
      <div className="row Container params">
        <div className="col s12 content marginTop30">
          <div className="pageTitle pageHeader row col s12">
            <div className="row col s12">
              <div className="flex">
                <h5>
                  <span>{specialityData && specialityData.name}</span>
                </h5>
              </div>
            </div>
            <div
              className="goBackBtn close right"
              role="button"
              tabIndex="0"
              onKeyPress={() => closePage(intl, false)}
              onClick={() => closePage(intl, false)}
            >
              <i className="material-icons">close</i>
            </div>
          </div>

          <div className="row col s12">
            <div className="row col s12 card">
              <div className="col s12 form">
                <form>
                  <div className="row">
                    <div className="col s12">
                      <p className="greyTitle">
                        {intl.formatMessage({ id: 'app.label.generalsInformations' })}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s6 input">
                      <label htmlFor="speciality" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.speciality' })}
                        <input
                          type="text"
                          name="name"
                          value={specialityData && (name || specialityData.name)}
                          onChange={this.handleChange}
                          disabled={!role.includes(ROLE_VET)}
                        />
                      </label>
                    </div>
                    <div className="col s6 input">
                      <label htmlFor="valence" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.valence' })}
                      </label>
                      <select
                        className="select-wrapper"
                        defaultValue={valence}
                        name="valence"
                        onChange={this.handleChange}
                        disabled={!role.includes(ROLE_VET)}
                      >
                        <option value={specialityData && specialityData.valence} disabled>
                          {getValenceLabelByKey(
                            specialityData && (valence || specialityData.valence)
                          )}
                        </option>
                        {valences.map(oneValence => (
                          <option key={oneValence.name} value={oneValence.name}>
                            {getValenceLabelByKey(oneValence.name)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s6 input">
                      <label htmlFor="conservation" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.conservation' })}
                      </label>
                      <select
                        className="select-wrapper"
                        name="conservation"
                        defaultValue={specialityData && specialityData.conservationMethod}
                        onChange={this.handleChange}
                        disabled={!role.includes(ROLE_VET)}
                      >
                        <option
                          value={specialityData && specialityData.conservationMethod}
                          disabled
                        >
                          {intl.formatMessage({
                            id: `app.label.conservationMethod.${specialityData &&
                              specialityData.conservationMethod}`
                          })}
                        </option>
                        {conservationMethods.map(conservationMethod => (
                          <option key={conservationMethod.value} value={conservationMethod.value}>
                            {intl.formatMessage({
                              id: `app.label.conservationMethod.${conservationMethod.value}`
                            })}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col s6 input">
                      <label htmlFor="administration" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.administration' })}
                      </label>
                      <select
                        className="select-wrapper"
                        name="administration"
                        defaultValue={specialityData && specialityData.administrationMethod}
                        onChange={this.handleChange}
                        disabled={!role.includes(ROLE_VET)}
                      >
                        <option
                          value={specialityData && specialityData.administrationMethod}
                          disabled
                        >
                          {intl.formatMessage({
                            id: `app.administrationMethod.${specialityData &&
                              specialityData.administrationMethod}`
                          })}
                        </option>
                        {administrations.map(administrationMethod => (
                          <option key={administrationMethod.name} value={administrationMethod.name}>
                            {intl.formatMessage({
                              id: `app.administrationMethod.${administrationMethod.name}`
                            })}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12 input">
                      <label htmlFor="volume" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.articles' })}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12 input">
                      {articles &&
                        articles.map(article => (
                          <span key={article.id} className="col s2">
                            <label>
                              <span>{article.name}</span>
                              <span
                                role="button"
                                className="pointer"
                                id={article.id}
                                onClick={this.deleteArticle}
                                onKeyPress={this.deleteArticle}
                                tabIndex="0"
                              >
                                <img
                                  id={article.id}
                                  src={icons.redDustbin}
                                  alt="red dustbin icon"
                                />
                              </span>
                            </label>
                          </span>
                        ))}
                      <span className="col s2">
                        <a
                          className="flex col s12 btnAddArticle blue-text waves-effect waves-light modal-trigger "
                          href="#modalAddArticle"
                        >
                          <span className="plusButtonArticle">+</span>
                          <span className="textButtonArticle">
                            {intl.formatMessage({ id: 'app.label.addAnArticle' })}
                          </span>
                        </a>
                      </span>
                      <Modal id={id} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12 input">
                      <label htmlFor="descContent" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.specialityDescription' })}
                      </label>
                      <textarea
                        className="materialize-textarea"
                        name="descContent"
                        value={descContent}
                        onChange={this.handleChange}
                        required
                        disabled={!role.includes(ROLE_VET)}
                      />
                    </div>
                  </div>
                  {!role.includes(ROLE_VET) ? (
                    <div>
                      <div className="divider greyDivider" />
                      <NoBlockPart
                        stockId={id}
                        setDataToParent={this.getDataFromChild}
                        specialityData={specialityData}
                      />
                      {articles &&
                        articles.map((article, index) => (
                          <AlerteDoses
                            key={index}
                            specialityDataId={specialityData.id}
                            article={article}
                            articles={articles}
                            articlesModified={articlesModified}
                            disabledList={disabledList}
                            getDataFromChild={this.getDataFromChild}
                            index={index}
                          />
                        ))}
                      {parameters && (
                        <Notification
                          setDataToParent={this.getDataFromChild}
                          parameters={parameters}
                        />
                      )}
                      <CenterButton
                        text={intl.formatMessage({ id: 'app.label.save' })}
                        onClick={this.handleSubmit}
                        disabled={disabledList.includes(true)}
                      />
                    </div>
                  ) : (
                    <IncompatibilityTable
                      id={id}
                      role={role}
                      name={nameBeforeEdit}
                      administrationData={administration}
                      specialities={specialities}
                      incompatibilities={incompatibilities}
                      setDataToParent={this.getDataFromChild}
                    />
                  )}
                  {role.includes(ROLE_VET) && (
                    <div className="row">
                      <div className="col offset-s5 s7 marginTop30">
                        <button type="button" className="btn blueBtn" onClick={this.handleSubmit}>
                          {intl.formatMessage({ id: 'app.label.edit' })}
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default injectIntl(connect(mapStateToProps)(VaccinesParamsItem))
