import M from 'materialize-css'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { i18n } from '../../../../parameters'
import CenterButton from '../../../CenterButton'

class OrderConformity extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      nameConformity: true,
      quantityConformity: true,
      batchNumberConformity: true,
      expirationDateConformity: true,
      nameNoConformityReason: null,
      quantityNoConformityReason: null,
      batchNumberNoConformityReason: null,
      expirationDateNoConformityReason: null,
      datepicker: '',
      timepicker: '',
      nitrogenLevelControl: '',
      disabled: true
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleChangeDate = this.handleChangeDate.bind(this)
    this.handleChangeHour = this.handleChangeHour.bind(this)
    this.validateForm = this.validateForm.bind(this)
  }

  componentDidMount() {
    const { containerNumber, getDataFromChild } = this.props
    const {
      nameConformity,
      quantityConformity,
      batchNumberConformity,
      expirationDateConformity,
      nameNoConformityReason,
      quantityNoConformityReason,
      batchNumberNoConformityReason,
      expirationDateNoConformityReason
    } = this.state

    M.Datepicker.init(document.querySelectorAll('.datepicker'), {
      autoClose: true,
      format: 'dd/mm/yyyy',
      selectMonth: true,
      firstDay: 1,
      i18n,
      onSelect: dateSelected => this.handleChangeDate('dateSelected', dateSelected)
    })
    M.Timepicker.init(document.querySelectorAll('.timepicker'), {
      twelveHour: false,
      autoClose: true,
      onSelect: (hourSelected, minuteSelected) =>
        this.handleChangeHour(hourSelected, minuteSelected)
    })
    this.setState({ nitrogenLevelControl: containerNumber })
    getDataFromChild('nameConformity', nameConformity)
    getDataFromChild('quantityConformity', quantityConformity)
    getDataFromChild('batchNumberConformity', batchNumberConformity)
    getDataFromChild('expirationDateConformity', expirationDateConformity)
    getDataFromChild('nameNoConformityReason', nameNoConformityReason)
    getDataFromChild('quantityNoConformityReason', quantityNoConformityReason)
    getDataFromChild('batchNumberNoConformityReason', batchNumberNoConformityReason)
    getDataFromChild('expirationDateNoConformityReason', expirationDateNoConformityReason)
  }

  validateForm() {
    const { needNitrogenLevelControl } = this.props
    const { datepicker, timepicker, nitrogenLevelControl } = this.state

    const disabled =
      datepicker === '' ||
      timepicker === '' ||
      nitrogenLevelControl === '' ||
      (needNitrogenLevelControl && !Number.isInteger(parseFloat(nitrogenLevelControl)))

    this.setState({ disabled })
  }

  handleChangeDate(name, value) {
    const { setDataToParent } = this.props
    setDataToParent(name, value)
    this.setState({ datepicker: value }, () => this.validateForm())
  }

  handleChangeHour(hourSelected, minuteSelected) {
    const { setDataToParent } = this.props
    setDataToParent('controlHour', hourSelected)
    setDataToParent('controlMinutes', minuteSelected)
    this.setState({ timepicker: hourSelected }, () => this.validateForm())
  }

  handleChange(e) {
    const { name, type, checked } = e.target
    let { value } = e.target
    const { setDataToParent } = this.props
    if (Number.isInteger(parseInt(value))) {
      value = parseInt(value)
    }

    if (type === 'checkbox') {
      this.setState({ [name]: checked }, () => this.validateForm())
      setDataToParent(name, checked)
    } else {
      this.setState({ [name]: value }, () => this.validateForm())
      setDataToParent(name, value)
    }
  }

  render() {
    const {
      intl,
      handleSubmit,
      receiverContainers,
      needNitrogenLevelControl,
      productBagPart,
      dyePart,
      diluentPart
    } = this.props
    const {
      disabled,
      nameConformity,
      quantityConformity,
      batchNumberConformity,
      expirationDateConformity
    } = this.state

    let label1 = intl.formatMessage({ id: 'app.label.vaccineNameOrdered' })
    let label2 = intl.formatMessage({ id: 'app.label.vaccineQuantityOrdered' })
    let label3 = intl.formatMessage({ id: 'app.label.bulbsLabelCongruent' })
    let nameConformityLabel = intl.formatMessage({ id: 'app.label.nameConformityDetails' })
    let quantityConformityLabel = intl.formatMessage({ id: 'app.label.quantityConformityDetails' })

    if (productBagPart) {
      label1 = intl.formatMessage({ id: 'app.label.productBagOrdered' })
      label2 = intl.formatMessage({ id: 'app.label.productBagOrderedQty' })
      label3 = intl.formatMessage({ id: 'app.label.batchNumberConformityDetails' })
      nameConformityLabel = intl.formatMessage({ id: 'app.label.pBagNameConformityDetails' })
      quantityConformityLabel = intl.formatMessage({
        id: 'app.label.pBagQuantityConformityDetails'
      })
    } else if (dyePart) {
      label1 = intl.formatMessage({ id: 'app.label.dyeNameOrdered' })
      label2 = intl.formatMessage({ id: 'app.label.dyeQuantityOrdered' })
      label3 = intl.formatMessage({ id: 'app.label.dyeBatchNumberOrdered' })
      nameConformityLabel = intl.formatMessage({ id: 'app.label.dyeNameConformityDetails' })
      quantityConformityLabel = intl.formatMessage({ id: 'app.label.dyeQuantityConformityDetails' })
    } else if (diluentPart) {
      label1 = intl.formatMessage({ id: 'app.label.diluentNameOrdered' })
      label2 = intl.formatMessage({ id: 'app.label.diluentQuantityOrdered' })
      label3 = intl.formatMessage({ id: 'app.label.diluentBatchNumberOrdered' })
      nameConformityLabel = intl.formatMessage({ id: 'app.label.diluentNameConformityDetails' })
      quantityConformityLabel = intl.formatMessage({
        id: 'app.label.diluentQuantityConformityDetails'
      })
    }

    return (
      <div>
        <div className="row">
          <div className="col s12">
            <div className="form frozenVaccine">
              <div className="row">
                <div className="col s12">
                  <div className="row col s12">
                    <div className="col s6 input">
                      <p className="labelCustom">{label1}</p>
                      <div className="switch">
                        <label>
                          {intl.formatMessage({ id: 'app.label.notCongruent' })}
                          <input
                            type="checkbox"
                            name="nameConformity"
                            onChange={this.handleChange}
                            checked={nameConformity}
                          />
                          <span className="lever" />
                          {intl.formatMessage({ id: 'app.label.congruent' })}
                        </label>
                      </div>
                    </div>
                    <div className="col s6 input">
                      <p className="labelCustom">{label2}</p>
                      <div className="switch">
                        <label>
                          {intl.formatMessage({ id: 'app.label.notCongruent' })}
                          <input
                            type="checkbox"
                            name="quantityConformity"
                            onChange={this.handleChange}
                            checked={quantityConformity}
                          />
                          <span className="lever" />
                          {intl.formatMessage({ id: 'app.label.congruent' })}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row col s12">
                    <div className="col s6 input">
                      <p className="labelCustom">{label3}</p>
                      <div className="switch">
                        <label>
                          {intl.formatMessage({ id: 'app.label.notCongruent' })}

                          <input
                            type="checkbox"
                            name="batchNumberConformity"
                            onChange={this.handleChange}
                            checked={batchNumberConformity}
                          />
                          <span className="lever" />
                          {intl.formatMessage({ id: 'app.label.congruent' })}
                        </label>
                      </div>
                    </div>
                    <div className="col s6 input">
                      <p className="labelCustom">
                        {intl.formatMessage({ id: 'app.label.expirationDateCongruent' })}
                      </p>
                      <div className="switch">
                        <label>
                          {intl.formatMessage({ id: 'app.label.notCongruent' })}
                          <input
                            type="checkbox"
                            name="expirationDateConformity"
                            onChange={this.handleChange}
                            checked={expirationDateConformity}
                          />
                          <span className="lever" />
                          {intl.formatMessage({ id: 'app.label.congruent' })}
                        </label>
                      </div>
                    </div>
                  </div>
                  {!nameConformity && (
                    <div className="row col s12">
                      <div className="col s12 input">
                        <label htmlFor="nameNoConformityReason">{nameConformityLabel}</label>
                        <textarea
                          id="nameNoConformityReason"
                          name="nameNoConformityReason"
                          className="materialize-textarea"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col offset-s4 input">
                        <div className="flex">
                          <button
                            type="button"
                            className="btn redBtn"
                            onClick={() => handleSubmit(this, true)}
                          >
                            {intl.formatMessage({ id: 'app.label.stopConformity' })}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {!quantityConformity && (
                    <div className="row col s12">
                      <div className="col s12 input">
                        <label htmlFor="quantityNoConformityReason">
                          {quantityConformityLabel}
                        </label>
                        <textarea
                          id="quantityNoConformityReason"
                          name="quantityNoConformityReason"
                          className="materialize-textarea"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  )}
                  {!batchNumberConformity && (
                    <div className="row col s12">
                      <div className="col s12 input">
                        <label htmlFor="batchNumberNoConformityReason">
                          {intl.formatMessage({
                            id: 'app.label.batchNumberConformityDetails'
                          })}
                        </label>
                        <textarea
                          id="batchNumberNoConformityReason"
                          name="batchNumberNoConformityReason"
                          className="materialize-textarea"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  )}
                  {!expirationDateConformity && (
                    <div className="col s12">
                      <div className="col s12 input">
                        <label htmlFor="expirationDateNoConformityReason">
                          {intl.formatMessage({
                            id: 'app.label.expirationDateConformityDetails'
                          })}
                        </label>
                        <textarea
                          id="expirationDateNoConformityReason"
                          name="expirationDateNoConformityReason"
                          className="materialize-textarea"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            <div className="form frozenVaccine">
              <div className="row">
                <div className="col s12 input">
                  <div className="col s12">
                    <div className="col s3 input">
                      <label htmlFor="datepicker">
                        <span>{intl.formatMessage({ id: 'app.label.receiptDate' })}</span>
                      </label>
                      <input
                        type="text"
                        name="datepicker"
                        className="datepicker"
                        placeholder={intl.formatMessage({ id: 'app.label.dateFormat' })}
                      />
                    </div>
                    <div className="col s3 input">
                      <label htmlFor="timepicker">
                        <span>{intl.formatMessage({ id: 'app.label.receiptHour' })}</span>
                      </label>
                      <input
                        type="text"
                        name="timepicker"
                        className="timepicker"
                        placeholder={intl.formatMessage({ id: 'app.label.hourFormat' })}
                      />
                    </div>
                    <div className="col s3 input">
                      <label htmlFor="controlDate">
                        <span>{intl.formatMessage({ id: 'app.label.controlDate' })}</span>
                      </label>
                      <input
                        type="text"
                        name="controlDate"
                        value={moment().format('DD/MM/YYYY')}
                        disabled
                      />
                    </div>
                    <div className="col s3 input">
                      <label htmlFor="controlHours">
                        <span>{intl.formatMessage({ id: 'app.label.controlHour' })}</span>
                      </label>
                      <input
                        type="text"
                        name="controlHours"
                        value={moment().format('HH:mm')}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {needNitrogenLevelControl && (
          <div className="row">
            <div className="col s12">
              <div className="form frozenVaccine">
                <div className="row">
                  <div className="col s12">
                    <div className="col s6 input">
                      <label htmlFor="containerNumber">
                        {intl.formatMessage({ id: 'app.equipment.containerNumber' })}
                      </label>
                      <input
                        name="containerNumber"
                        value={receiverContainers.containerNumber}
                        disabled
                      />
                    </div>
                    <div className="col s6 input">
                      <label htmlFor="nitrogenLevelControl">
                        {intl.formatMessage({ id: 'app.label.nitrogenLevelAfterTransfer' })}
                      </label>
                      <input
                        type="number"
                        name="nitrogenLevelControl"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <CenterButton
          text={intl.formatMessage({ id: 'app.button.validate' })}
          onClick={handleSubmit}
          disabled={disabled}
        />
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(OrderConformity)))
