import Axios from 'axios'
import M from 'materialize-css'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { catchError } from '../../../../actions'
import { api } from '../../../../parameters'
import LoadingPage from '../../../LoadingPage'

class diluentForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      batches: [],
      diluentsSelected: [],
      batchNumber: null,
      bulbsMove: null,
      loading: true
    }
    this.ampoulesNumberRef = React.createRef()
    this.handleSelect = this.handleSelect.bind(this)
    this.getDiluentsSelected = this.getDiluentsSelected.bind(this)
  }

  componentDidMount() {
    const { donorId, token } = this.props
    Axios.get(`${api}/api/fridge_diluents?fridge.id=${donorId}`, {
      headers: { Authorization: token }
    })
      .then(res =>
        this.setState({ batches: res.data['hydra:member'], loading: false }, () => M.AutoInit())
      )
      .catch(err => catchError(err.response))
    this.getDiluentsSelected()
  }

  componentDidUpdate(prevProps, prevState) {
    const { diluents, setDisabled, setDiluentDisabled } = this.props
    const { batchNumber, bulbsMove } = this.state
    if (prevProps.diluents !== diluents) {
      this.getDiluentsSelected()
    }
    if (prevState.batchNumber !== batchNumber || prevState.bulbsMove !== bulbsMove) {
      if (batchNumber !== null && bulbsMove !== null && bulbsMove !== '') {
        setDisabled(false)
        setDiluentDisabled(false)
      } else {
        setDisabled(true)
        setDiluentDisabled(true)
      }
    }
  }

  getDiluentsSelected() {
    const { diluents, index } = this.props
    const diluentsSelected = []
    diluents.forEach(diluent => {
      if (diluent.id !== undefined && diluentsSelected[index] === undefined)
        diluentsSelected[index] = diluent.id
    })
    this.setState({ diluentsSelected })
  }

  handleSelect(e) {
    const { name, value } = e.target
    const { token, index, setDiluentDisabled, itemsToMove } = this.props
    const { batchId } = this.state
    switch (name) {
      case `batchNumber${index}`:
        this.setState({ batchNumber: value, bulbsMove: null })
        setDiluentDisabled(true)
        this.ampoulesNumberRef.current.value = ''
        Axios.get(`${api}/api/fridge_diluents/${value}`, {
          headers: { Authorization: token }
        })
          .then(res =>
            this.setState({ batchSelected: res.data, batchId: value }, () => M.AutoInit())
          )
          .catch(err => catchError(err.response))
        this.getDiluentsSelected()
        break
      case `bulbsMove${index}`:
        this.setState({ bulbsMove: value })
        itemsToMove('diluent', index, e, batchId)
        break
      default:
        break
    }
  }

  render() {
    const { index, intl, disabledDiluentsForm } = this.props
    const { batches, batchSelected, loading, diluentsSelected } = this.state
    if (loading) {
      return (
        <div className="row">
          <div className="col s12">
            <LoadingPage />
          </div>
        </div>
      )
    }

    return (
      <div>
        <div className="row">
          <div className="col s12">
            <h5>
              {intl.formatMessage({ id: 'app.label.diluent' })} n°{index + 1}
            </h5>
            <div className="row">
              <div className="col m6 input">
                <label htmlFor={`batchNumber${index}`}>
                  {intl.formatMessage({ id: 'app.label.batchesNumber' })}
                </label>
                <select
                  className="select-wrapper"
                  defaultValue="0"
                  onChange={this.handleSelect}
                  name={`batchNumber${index}`}
                  id={`batchNumber${index}`}
                  disabled={disabledDiluentsForm[index]}
                >
                  <option value="0" disabled />
                  {batches.map(
                    batch =>
                      diluentsSelected.indexOf(batch.id) === -1 && (
                        <option key={batch.diluentBatch.batchNumber} value={batch.id}>
                          {batch.diluentBatch.batchNumber}
                        </option>
                      )
                  )}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col m3 input">
                <label htmlFor={`provider${index}`}>
                  {intl.formatMessage({ id: 'app.label.provider' })}
                </label>
                <input
                  disabled
                  value={batchSelected ? batchSelected.diluentBatch.provider : ''}
                  name={`provider${index}`}
                  id={`provider${index}`}
                  type="text"
                />
              </div>
              <div className="col m3 input">
                <label htmlFor={`expiration${index}`}>
                  {intl.formatMessage({ id: 'app.label.expirationDate' })}
                </label>
                <input
                  disabled
                  value={
                    batchSelected
                      ? moment(batchSelected.diluentBatch.expirationDate).format('DD/MM/YYYY')
                      : ''
                  }
                  id={`expiration${index}`}
                  name={`expiration${index}`}
                  type="text"
                />
              </div>
              <div className="col m3 input">
                <label htmlFor={`unitNumber${index}`}>
                  {intl.formatMessage({ id: 'app.label.bulbsNumber' })}
                </label>
                <input
                  disabled
                  value={batchSelected ? batchSelected.unitsNumber : ''}
                  id={`unitNumber${index}`}
                  name={`unitNumber${index}`}
                  type="text"
                />
              </div>
              <div className="col m3 input">
                <label htmlFor={`bulbsMove${index}`}>
                  {intl.formatMessage({ id: 'app.label.bulbsMove' })}
                </label>
                <input
                  id={`bulbsMove${index}`}
                  name={`bulbsMove${index}`}
                  ref={this.ampoulesNumberRef}
                  type="number"
                  onChange={this.handleSelect}
                  disabled={disabledDiluentsForm[index]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(diluentForm)
