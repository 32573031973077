import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { catchError } from '../../../actions'
import AsyncLoader from '../../../components/AsyncLoader'
import Filters from '../../../components/Filters/Filters'
import roles from '../../../config/userRoles.json'
import { api } from '../../../parameters'

class ParamsHistoryList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      historyList: [],
      url: `${api}/api/parameter_histories`,
      loading: true,
      contentStatus: false,
      filters: [
        { category: 'orderDate', sortBy: ['asc', 'desc'] },
        {
          category: 'orderFirstName',
          sortBy: ['asc', 'desc']
        }
      ]
    }
    this.inLoad = this.inLoad.bind(this)
    this.getStateFromChild = this.getStateFromChild.bind(this)
    this.filterByDate = this.filterByDate.bind(this)
  }

  componentDidMount() {
    const { role, token } = this.props
    const { filters, url } = this.state
    const { ROLE_VET } = roles

    if (role.includes(ROLE_VET)) {
      filters.push({
        category: 'type',
        sortBy: [0, 1, 2, 3],
        translationPrefix: 'actionType'
      })
    } else {
      filters.push({
        category: 'type',
        sortBy: [0, 1, 2, 3, 4, 5, 6, 7, 8],
        translationPrefix: 'actionType'
      })
    }

    Axios.get(`${url}?orderDate=desc`, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({
          filters,
          loading: false,
          historyList: res.data['hydra:member'],
          totalItems: res.data['hydra:totalItems'],
          contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult'
        })
      })
      .catch(err => catchError(err))
  }

  getStateFromChild(data, infiniteLoader = false) {
    let { historyList, contentStatus } = this.state
    if (infiniteLoader) {
      historyList = [...historyList, ...data['hydra:member']]
    } else {
      historyList = data['hydra:member']
    }
    if (historyList.length >= data['hydra:totalItems']) {
      contentStatus = 'noMoreResult'
    }

    this.setState({
      historyList,
      loading: false,
      contentStatus
    })
  }

  filterByDate(date) {
    const { token } = this.props
    const dateToApi = moment(date).format('YYYY-MM-DD')
    const url = `${api}/api/parameter_histories?dateFilter=${dateToApi}`

    Axios.get(url, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({
          loading: false,
          historyList: res.data['hydra:member']
        })
      })
      .catch(err => catchError(err))
  }

  inLoad(contentStatus) {
    this.setState({ contentStatus })
  }

  render() {
    const { intl, history, token, role } = this.props
    const { url, filters, loading, historyList, contentStatus, totalItems } = this.state
    const { ROLE_VET } = roles
    let historyListToRender = []

    if (role.includes(ROLE_VET)) {
      historyList.forEach((item, idx) => {
        if (item.type < 3) {
          historyListToRender[idx] = item
        }
      })
    } else {
      historyListToRender = historyList
    }

    return (
      <div className="row Container stockHistory">
        <div className="col s12 Content paramsContent">
          <div className="row pageHeader pageTitle">
            <h5 className="col m5">
              {intl.formatMessage({ id: 'app.label.history.stock_parameters' })}
            </h5>
            <div className="filters col m6">
              <div>
                <Filters
                  token={token}
                  setStateToParent={this.getStateFromChild}
                  url={url}
                  infiniteLoader
                  filterByDate={this.filterByDate}
                  totalItems={totalItems}
                  hasDate
                  filters={filters}
                  role={role}
                  inLoad={this.inLoad}
                />
              </div>
            </div>
          </div>
          {!loading && (
            <div className="row">
              <table className="table">
                <thead>
                  <tr>
                    <th>{intl.formatMessage({ id: 'app.label.date&hour' })} </th>
                    <th>{intl.formatMessage({ id: 'app.label.actionType' })} </th>
                    <th>{intl.formatMessage({ id: 'app.label.name' })} </th>
                    <th>{intl.formatMessage({ id: 'app.label.operator' })} </th>
                  </tr>
                </thead>
                <tbody>
                  {historyListToRender.map(item => {
                    let paramName = ''

                    switch (item.type) {
                      case 0:
                      case 1:
                        paramName = item.history.action.name
                        break
                      case 2:
                      case 3:
                      case 8:
                        paramName = `${item.history.action.speciality} ${
                          item.history.action.dosesNumber
                        } ${intl.formatMessage({
                          id: 'app.label.doses'
                        })}`
                        break
                      case 4:
                        paramName = intl.formatMessage({
                          id: `app.title.equipmentParam${item.history.action.paramId}`
                        })
                        break
                      case 5:
                      case 6:
                        paramName = `${intl.formatMessage({
                          id: `app.label.productType.${item.history.action.productType}`
                        })} ${item.history.action.provider} ${item.history.action.volume || ''}`
                        break
                      case 7:
                        paramName = intl.formatMessage({
                          id: `app.title.vaccinationParam.${item.history.action.paramId}`
                        })
                        break
                      default:
                    }

                    return (
                      <tr
                        key={item.id}
                        style={item.outdated ? { background: '#c1c7d0' } : {}}
                        onClick={() => {
                          history.push({
                            pathname: `/parameters/parameter-history/single/${item.id}`
                          })
                        }}
                        className="pointer"
                      >
                        <td>{moment(item.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                        <td>
                          {intl.formatMessage({
                            id: `app.label.parameters.action.${item.type}`
                          })}
                        </td>
                        <td>{paramName}</td>
                        <td>{item.firstName}</td>
                      </tr>
                    )
                  })}
                </tbody>
                <tfoot className="center">
                  <tr>
                    <th colSpan="5">
                      <AsyncLoader status={contentStatus} />
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(ParamsHistoryList)))
