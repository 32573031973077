import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import AsyncLoader from '../../AsyncLoader'
import StatePill from '../../StatePill'
import TdOperator from './partials/TdOperator'

class TableReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { intl, contentStatus, dailyReports } = this.props

    return (
      <table className="table">
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: 'app.label.date' })}</th>
            <th>{intl.formatMessage({ id: 'app.label.timing' })}</th>
            <th>{intl.formatMessage({ id: 'app.label.chicksOrdered' })}</th>
            <th>{intl.formatMessage({ id: 'app.label.chickVaccined' })}</th>
            <th>{intl.formatMessage({ id: 'app.label.dyes' })}</th>
            <th>{intl.formatMessage({ id: 'app.label.quantity' })}</th>
            <th>{intl.formatMessage({ id: 'app.label.diluents' })}</th>
            <th>{intl.formatMessage({ id: 'app.label.quantity' })}</th>
            <th>{intl.formatMessage({ id: 'app.label.controller' })}</th>
          </tr>
        </thead>
        {dailyReports &&
          dailyReports.map(dailyReport => (
            <tbody key={dailyReport.id}>
              <td>{moment(dailyReport.createdAt).format('DD/MM/YYYY')}</td>
              <td>{moment(dailyReport.createdAt).format('HH:mm')}</td>
              <td>{dailyReport.orderedChicks}</td>
              <td>
                <StatePill pillState={dailyReport.chicksDeltaState} type="report" />
                {dailyReport.vaccinatedChicks}
              </td>
              <td>
                {dailyReport.dyes &&
                  dailyReport.dyes.map(dye => (
                    <div key={dye.id}>
                      {dye.batchNumber && dye.fridge
                        ? `${dye.batchNumber} - ${dye.fridge} - ${intl.formatMessage({
                            id: `app.color.${dye.color}`
                          })}`
                        : intl.formatMessage({ id: `app.color.${dye.color}` })}
                    </div>
                  ))}
              </td>
              <td>
                {dailyReport.dyes.map(dye => (
                  <div key={dye.color}>{dye.quantity}</div>
                ))}
              </td>
              <td>
                {dailyReport.dills &&
                  dailyReport.dills.map(diluent => (
                    <div key={diluent.id}>
                      {diluent.batchNumber && diluent.fridge
                        ? `${diluent.batchNumber} - ${diluent.fridge}`
                        : intl.formatMessage({ id: `app.label.N/A` })}
                    </div>
                  ))}
                {dailyReport.dills === null && (
                  <div>{intl.formatMessage({ id: `app.label.N/A` })}</div>
                )}
              </td>
              <td>
                {dailyReport.dills &&
                  dailyReport.dills.map(diluent => <div key={diluent.id}>{diluent.quantity}</div>)}
                {dailyReport.dills === null && <div>0</div>}
              </td>
              <TdOperator operator={dailyReport.operator} />
            </tbody>
          ))}
        <tfoot className="center">
          <tr>
            <th colSpan="12">
              <AsyncLoader status={contentStatus} />
            </th>
          </tr>
        </tfoot>
      </table>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(TableReport))
