import React, { createContext, useState, useEffect } from 'react'
import fetchItems from './fetchItems'

const ShapsCodesContext = createContext({
  shapsCodes: [],
  fetchShapsCodes: () => {}
})

export const ShapsCodesProvider = ({ children }) => {
  const [shapsCodes, setShapsCodes] = useState([])

  const fetchShapsCodes = () => {
    fetchItems('shaps_codes', setShapsCodes)
  }

  useEffect(() => {
    fetchShapsCodes()
  }, [])

  return (
    <ShapsCodesContext.Provider
      value={{
        shapsCodes,
        fetchShapsCodes
      }}
    >
      {children}
    </ShapsCodesContext.Provider>
  )
}

export default ShapsCodesContext
