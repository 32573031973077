import React from 'react'
import { injectIntl } from 'react-intl'

const VaccineApplicatorBlock = props => {
  const { intl, action } = props
  const { changes } = action
  const { after, before } = changes

  return (
    <div className="row applicatorsParamHistory">
      <span className="col s12 bold applicatorsParamHistoryTitle">
        {intl.formatMessage({ id: 'app.label.applicators' })}
      </span>
      <div className="col s6">
        <div className="col s12">
          <label htmlFor="congruentPartTempBetweenMin" className="applicatorsParamHistoryLabel">
            {intl.formatMessage({ id: 'app.label.before' })}
          </label>
        </div>
        {before.map(el => (
          <div className="col s3">{el}</div>
        ))}
      </div>
      <div className="col s6">
        <div className="col s12">
          <label htmlFor="congruentPartTempBetweenMin" className="applicatorsParamHistoryLabel">
            {intl.formatMessage({ id: 'app.label.after' })}
          </label>
        </div>
        {Object.values(after).map(el => (
          <div className="col s3">{el}</div>
        ))}
      </div>
    </div>
  )
}

export default injectIntl(VaccineApplicatorBlock)
