import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { catchError } from '../../../actions'
import CenterButton from '../../../components/CenterButton'
import { validatedAction } from '../../../components/globalAction'
import LoadingPage from '../../../components/LoadingPage'
import BlockPart from '../../../components/Parameters/BlockPart/BlockPart'
import CongruentPart from '../../../components/Parameters/CongruentPart/CongruentPart'
import ModalAddVaccineApplicator from '../../../components/Parameters/Equipment/partials/ModalAddVaccineApplicator'
import VaccineApplicatorPart from '../../../components/Parameters/Equipment/partials/VaccineApplicatorPart'
import NoBlockPart from '../../../components/Parameters/NoBlockPart/NoBlockPart'
import Notification from '../../../components/Parameters/Notification'
import { api } from '../../../parameters'

class EquipmentParamsItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isMachine: false, loading: true, machine: null, param: null, type: null }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
    M.AutoInit()
  }

  componentDidMount() {
    const { match, token } = this.props
    const { id } = match.params

    Axios.get(`${api}/api/equipment_parameters/${id}`, { headers: { Authorization: token } })
      .then(res => {
        this.setState({ loading: false, param: res.data })

        switch (res.data.paramId) {
          case 7:
            this.setState({ isMachine: true, machine: 0, type: 0 })
            break
          case 8:
            this.setState({ isMachine: true, machine: 1, type: 1 })
            break
          case 9:
            this.setState({ isMachine: true, machine: 2, type: 1 })
            break
          default:
        }

        this.setState({ channels: res.data.channels })
      })
      .catch(err => catchError(err))
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  handleSubmit() {
    const { match, token } = this.props
    const { id } = match.params
    const {
      congruentPartValueMin,
      congruentPartValueMax,
      noBlockPartAlert,
      noBlockPartAlertMin,
      noBlockPartAlertMax,
      blockValueMin,
      blockValueMax,
      blockAlert,
      hatchRespAlerts,
      vetAlerts,
      hatchingRespAlerts,
      operatorAlerts,
      channels,
      param
    } = this.state
    const { blockingAlert, conformityAlert, nonBlockingAlert, paramId } = param
    let formData = {}

    if (hatchRespAlerts) channels.push(hatchRespAlerts)
    if (vetAlerts) channels.push(vetAlerts)
    if (hatchingRespAlerts) channels.push(hatchingRespAlerts)
    if (operatorAlerts) channels.push(operatorAlerts)

    switch (paramId) {
      case 0:
      case 6:
        formData = {
          conformityAlert: {
            type: conformityAlert.type,
            message: conformityAlert.message,
            value: {
              min: congruentPartValueMin || conformityAlert.value.min,
              max: congruentPartValueMax || conformityAlert.value.max
            }
          },
          nonBlockingAlert: {
            type: nonBlockingAlert.type,
            message: noBlockPartAlert || nonBlockingAlert.message,
            value: nonBlockingAlert.value
          },
          blockingAlert: {
            type: blockingAlert.type,
            message: blockAlert || blockingAlert.message,
            value: {
              min: blockValueMin || blockingAlert.value.min,
              max: blockValueMax || blockingAlert.value.max
            }
          },
          channels
        }
        break
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        formData = {
          conformityAlert: {
            type: conformityAlert.type,
            message: conformityAlert.message,
            value: {
              min: congruentPartValueMin || conformityAlert.value.min,
              max: congruentPartValueMax || conformityAlert.value.max
            }
          },
          nonBlockingAlert: {
            type: nonBlockingAlert.type,
            message: {
              min: noBlockPartAlertMin || nonBlockingAlert.message.min,
              max: noBlockPartAlertMax || nonBlockingAlert.message.max
            },
            value: nonBlockingAlert.value
          },
          blockingAlert: {
            type: blockingAlert.type,
            message: blockAlert || blockingAlert.message,
            value: {
              min: blockValueMin || blockingAlert.value.min
            }
          },
          channels
        }
        break
      case 7:
        formData = {
          conformityAlert: {
            type: conformityAlert.type,
            message: conformityAlert.message,
            value: {
              min: congruentPartValueMin || conformityAlert.value.min,
              max: congruentPartValueMax || conformityAlert.value.max
            }
          },
          nonBlockingAlert: {
            type: nonBlockingAlert.type,
            message: noBlockPartAlert || nonBlockingAlert.message,
            value: nonBlockingAlert.value
          },
          blockingAlert: {
            type: blockingAlert.type,
            message: blockAlert || blockingAlert.message,
            value: nonBlockingAlert.value
          },
          channels
        }
        break
      case 8:
      case 9:
        formData = {
          conformityAlert: {
            type: conformityAlert.type,
            message: conformityAlert.message,
            value: {
              min: congruentPartValueMin || conformityAlert.value.min,
              max: congruentPartValueMax || conformityAlert.value.max
            }
          },
          nonBlockingAlert: {
            type: nonBlockingAlert.type,
            message: noBlockPartAlert || nonBlockingAlert.message,
            value: nonBlockingAlert.value
          },
          blockingAlert: {
            type: blockingAlert.type,
            message: blockAlert || blockingAlert.message,
            value: { min: blockValueMin || blockingAlert.value.min }
          },
          channels
        }
        break
      case 10:
        formData = {
          conformityAlert: {
            type: conformityAlert.type,
            message: conformityAlert.message,
            value: conformityAlert.value
          },
          nonBlockingAlert: {
            type: nonBlockingAlert.type,
            message: nonBlockingAlert.message,
            value: nonBlockingAlert.value
          },
          blockingAlert: {
            type: blockingAlert.type,
            message: blockAlert || blockingAlert.message,
            value: { min: blockValueMin || blockingAlert.value }
          },
          channels
        }
        break
      default:
        break
    }

    Axios.patch(`${api}/api/equipment_parameters/${id}`, formData, {
      headers: { Authorization: token }
    })
      .then(() => {
        validatedAction('')
      })
      .catch(err => catchError(err.response))
  }

  render() {
    const { intl, match } = this.props
    const {
      congruentPartValueMin,
      congruentPartValueMax,
      blockValueMin,
      blockValueMax,
      isMachine,
      loading,
      machine,
      param,
      type
    } = this.state

    if (loading === true) {
      return <LoadingPage />
    }

    let goBackRender = (
      <Link className="goBackBtn close right" to="/parameters/equipment#container-fridge">
        <i className="material-icons">close</i>
      </Link>
    )

    if (match.params.id > 5) {
      goBackRender = (
        <Link className="goBackBtn close right" to="/parameters/equipment#vaccination">
          <i className="material-icons">close</i>
        </Link>
      )
    }

    const { blockingAlert, conformityAlert, nonBlockingAlert, paramId } = param

    return (
      <div className="row Container params">
        <div className="col s12 content marginTop30">
          <div className="pageTitle pageHeader row col s12">
            <div className="row col s12">
              <div className="flex">
                <h5>
                  <span>{intl.formatMessage({ id: `app.title.equipmentParam${paramId}` })}</span>
                </h5>
              </div>
            </div>
            {goBackRender}
          </div>

          <div className="row col s12">
            <div className="row col s12 card">
              <div className="col s12 form">
                <form>
                  <div className="row">
                    <div className="col s12">
                      <p className="greyTitle">
                        {intl.formatMessage({ id: 'app.label.generalsInformations' })}
                      </p>
                    </div>
                  </div>
                  <CongruentPart
                    paramId={paramId}
                    conformityAlert={conformityAlert}
                    setDataToParent={this.getDataFromChild}
                  />
                  <NoBlockPart
                    paramId={paramId}
                    nonBlockingAlert={nonBlockingAlert}
                    conformityAlert={conformityAlert}
                    setDataToParent={this.getDataFromChild}
                    congruentPartValueMin={congruentPartValueMin}
                    congruentPartValueMax={congruentPartValueMax}
                    blockValueMin={blockValueMin}
                    blockValueMax={blockValueMax}
                  />
                  <BlockPart
                    paramId={paramId}
                    blockingAlert={blockingAlert}
                    setDataToParent={this.getDataFromChild}
                    congruentPartValueMin={congruentPartValueMin}
                  />
                  {isMachine && <VaccineApplicatorPart type={type} machine={machine} />}
                  <Notification setDataToParent={this.getDataFromChild} parameters={param} />
                  <CenterButton
                    text={intl.formatMessage({ id: 'app.label.save' })}
                    onClick={this.handleSubmit}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
        <ModalAddVaccineApplicator type={type} machine={machine} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(EquipmentParamsItem)))
