/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import productTypes from '../../../config/productTypes'
import { WithUnit, SpanLink } from '../Components'
import { getVaccineOrProductByBatchNumber, getEquipmentById } from '../ReverseRedirections'

function Vaccines({ token, location, intl, history }) {
  const [state, setState] = useState({
    loading: true,
    items: null,
    batchNumber: null
  })

  const setLoading = loading => {
    setState({
      ...state,
      loading
    })
  }

  const vaccineTypes = {
    injection: 0,
    spray: 1
  }

  useEffect(() => {
    const pathName = location.pathname.split('/')
    const id = Number(pathName[pathName.length - 2])
    Axios.get(`${api}/api/order_traceability/${id}/vaccine`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const mapper = i => {
          const unknownValue = intl.formatMessage({ id: 'app.label.N/A' })
          return {
            ...i,
            vialNumber: {
              text: i.ampoules ?? unknownValue,
              unit: 'units'
            },
            article: i.article ?? unknownValue,
            batchNumber: i.batchNumber ?? unknownValue,
            expirationDate: i.expirationDate
              ? moment(i.expirationDate).format('DD/MM/YYYY')
              : unknownValue,
            nitrogenLevelStart: {
              text:
                i.conservationMethod === productTypes.VACCINES_BATCH && i.control
                  ? i.control
                  : unknownValue,
              unit: 'centimeter'
            },
            nitrogenLevelEnd: {
              text: i.endDayControl ?? unknownValue,
              unit: 'centimeter'
            },
            temperatureMin: {
              text: i.controlMin ?? unknownValue,
              unit: 'temp'
            },
            temperatureMax: {
              text: i.controlMax ?? unknownValue,
              unit: 'temp'
            },
            temperatureInstant: {
              text:
                i.conservationMethod === productTypes.TYPE_NITROGEN_CONTAINER && i.control
                  ? i.control
                  : unknownValue,
              unit: 'temp'
            },
            specialty: i.speciality ?? unknownValue,
            storageDevice: i.equipmentId ?? unknownValue
          }
        }

        const items = []
        items[vaccineTypes.injection] = []
        items[vaccineTypes.spray] = []
        if (res.data['hydra:member'].spray !== undefined) {
          Object.entries(res.data['hydra:member'].spray).forEach(i => {
            items[vaccineTypes.spray].push(mapper(i[1]))
          })
        }
        if (res.data['hydra:member'].injection !== undefined) {
          Object.entries(res.data['hydra:member'].injection).forEach(i => {
            items[vaccineTypes.injection].push(mapper(i[1]))
          })
        }
        setState({
          loading: false,
          items,
          batchNumber: res.data.currentNumber
        })
      })
      .catch(err => catchError(err))
  }, [])

  return (
    <div className="row Container stockAvailibilityItem">
      <div className="col s12 Content">
        <div className="row pageHeader pageTitle itemHeader">
          <div
            onClick={() => history.goBack()}
            role="button"
            tabIndex="0"
            onKeyPress={() => history.goBack()}
            className="goBackBtn col s1"
          >
            <i className="material-icons">chevron_left</i>
          </div>
          {state.batchNumber !== null && (
            <h5 className="col s12">
              {intl.formatMessage({ id: 'app.title.order' })} {state.batchNumber} -{' '}
              {intl.formatMessage({ id: 'app.title.vaccines' })}
            </h5>
          )}
        </div>
        {!state.loading ? (
          <div className="row">
            {state.items.map((vaccineType, key) => {
              return (
                vaccineType.length > 0 && (
                  <>
                    <div>
                      {intl.formatMessage({
                        id:
                          key === vaccineTypes.injection
                            ? 'app.label.order.vaccines.injectable_vaccine'
                            : 'app.label.order.vaccines.spray_vaccine'
                      })}
                    </div>
                    <table className="table" style={{ marginBottom: '16px' }}>
                      <thead>
                        <tr>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.vaccines.specialty'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.vaccines.article'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.vaccines.batchNumber'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.vaccines.expiration_date'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.vaccines.vial_number'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.vaccines.storage_device'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.vaccines.nitrogen_level_start'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.vaccines.nitrogen_level_end'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.vaccines.temperature_min'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.vaccines.temperature_max'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.vaccines.temperature_instant'
                            })}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {vaccineType.map(item => {
                          return (
                            <tr>
                              <td>{item.specialty}</td>
                              <td>{item.article}</td>
                              <td>
                                <SpanLink
                                  value={item.batchNumber}
                                  setLoading={setLoading}
                                  redirect={getVaccineOrProductByBatchNumber}
                                  token
                                  history
                                />
                              </td>
                              <td>{item.expirationDate}</td>
                              <td>
                                <WithUnit {...item.vialNumber} />
                              </td>
                              <td>
                                <SpanLink
                                  value={item.storageDevice}
                                  setLoading={setLoading}
                                  redirect={getEquipmentById}
                                  token
                                  history
                                />
                              </td>
                              <td>
                                <WithUnit {...item.nitrogenLevelStart} />
                              </td>
                              <td>
                                <WithUnit {...item.nitrogenLevelEnd} />
                              </td>
                              <td>
                                <WithUnit {...item.temperatureMin} />
                              </td>
                              <td>
                                <WithUnit {...item.temperatureMax} />
                              </td>
                              <td>
                                <WithUnit {...item.temperatureInstant} />
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </>
                )
              )
            })}
          </div>
        ) : (
          <LoadingPage />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(Vaccines)))
