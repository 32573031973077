import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.onBackClick = this.onBackClick.bind(this)
  }

  onBackClick() {
    const { history } = this.props

    history.push('/history')
  }

  render() {
    const { intl, type } = this.props

    return (
      <div className="row pageHeader pageTitle" data-testid="injectedArray">
        <div className="col m12">
          <div>
            <div className="flex">
              <div
                role="button"
                className="pointer goBackBtn space5pixel"
                onKeyDown={this.onBackClick}
                onClick={this.onBackClick}
                tabIndex={0}
              >
                <i role="button" tabIndex="0" className="material-icons">
                  chevron_left
                </i>
              </div>
              <div
                role="button"
                className="pointer flex"
                onKeyDown={this.onBackClick}
                onClick={this.onBackClick}
                tabIndex={0}
              >
                <h5 className="flex">
                  {intl.formatMessage({ id: `app.vaccination.history.action.${type}` })}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(Header)))
