/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react'
import Axios from 'axios'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import AsyncLoader from '../../../components/AsyncLoader'
import PrestationsContext from '../../../context/PrestationsContext'

function Interventions({ token, location, intl, history }) {
  const [state, setState] = useState({
    loading: true,
    items: null,
    contentStatus: false,
    orderNumber: null
  })
  const { getPrestationLabelByKey } = useContext(PrestationsContext)

  useEffect(() => {
    const pathName = location.pathname.split('/')
    const id = Number(pathName[pathName.length - 2])
    Axios.get(`${api}/api/order_traceability/${id}/prestation`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const mapper = i => {
          const interventions = []
          for (const [key, p] of Object.entries(i.prestations)) {
            interventions.push({
              intervention: getPrestationLabelByKey(key),
              male: intl.formatMessage({
                id: `app.label.order.interventions.${p.male ? 'yes' : 'no'}`
              }),
              female: intl.formatMessage({
                id: `app.label.order.interventions.${p.female ? 'yes' : 'no'}`
              }),
              hybrid: intl.formatMessage({
                id: `app.label.order.interventions.${p.hybrid ? 'yes' : 'no'}`
              })
            })
          }
          return interventions
        }
        const items = []
        Object.entries(res.data['hydra:member']).forEach(i => {
          items.push(...mapper(i[1]))
        })
        setState({
          ...state,
          loading: false,
          items,
          orderNumber: res.data.currentNumber
        })
      })
      .catch(err => catchError(err))
  }, [])

  return (
    <div className="row Container stockAvailibilityItem">
      <div className="col s12 Content">
        <div className="row pageHeader pageTitle itemHeader">
          <div
            onClick={() => history.goBack()}
            role="button"
            tabIndex="0"
            onKeyPress={() => history.goBack()}
            className="goBackBtn col s1"
          >
            <i className="material-icons">chevron_left</i>
          </div>
          {state.orderNumber !== null && (
            <h5 className="col s12">
              {intl.formatMessage({ id: 'app.title.order' })} {state.orderNumber} -{' '}
              {intl.formatMessage({ id: 'app.title.interventions' })}
            </h5>
          )}
        </div>
        {!state.loading ? (
          <div className="row">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    {intl.formatMessage({ id: 'app.label.order.interventions.intervention' })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.order.interventions.male'
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.order.interventions.female'
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.order.interventions.hybrid'
                    })}
                  </th>
                </tr>
              </thead>
              <tbody>
                {state.items.map(item => {
                  return (
                    <tr key={item.id}>
                      <td>{item.intervention}</td>
                      <td>{item.male}</td>
                      <td>{item.female}</td>
                      <td>{item.hybrid}</td>
                    </tr>
                  )
                })}
              </tbody>
              <tfoot className="center">
                <tr>
                  <th colSpan="5">
                    <AsyncLoader status={state.contentStatus} />
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        ) : (
          <LoadingPage />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(Interventions)))
