import { FormattedMessage } from 'react-intl'
import React from 'react'
import moment from 'moment'
import HeadSingleHistory from './HeadSingleHistory'

export default function ContainerControl(props) {
  const { history, operator, date, action, intl } = props

  return (
    <div className="Container equipmentHistory">
      <HeadSingleHistory operator={operator} date={date} action={action}>
        <div className="historyContent row">
          <div className="row">
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.containerId" />
                </span>
                {history.action.nitrogenContainer.containerNumber}
              </p>
            </div>
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.equipment.lastControl" />
                  &nbsp;:&nbsp;
                </span>
                {moment(history.action.containerLastControlDate).format('DD/MM/YYYY')}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.container" />
                  :&nbsp;
                </span>
                {intl.formatMessage({
                  id: `app.equipment.containerType.${history.action.nitrogenContainer.containerType}`
                })}
              </p>
            </div>
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.prevNitrogenLevel" />
                  &nbsp;:&nbsp;
                </span>
                {`${history.action.nitrogenContainer.prevNitrogenLevel} 
                ${intl.formatMessage({ id: 'app.unit.nitrogenLevel' })}`}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col s6">
              <p>
                <span className="label">
                  <FormattedMessage id="app.label.newNitrogenLevel" />
                  :&nbsp;
                </span>
                {`${history.action.nitrogenContainer.giverNitrogenLevel} 
                ${intl.formatMessage({ id: 'app.unit.nitrogenLevel' })}`}
              </p>
            </div>
          </div>
        </div>
      </HeadSingleHistory>
    </div>
  )
}
