import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import Axios from 'axios'
import { api } from '../../../parameters'
import LoadingPage from '../../../components/LoadingPage'
import Header from '../../../components/History/HistorySingleView/Header'
import PlanningImport from '../../../components/History/HistorySingleView/partials/PlanningImport'
import OrderNumberEdit from '../../../components/History/HistorySingleView/partials/OrderNumberEdit'
import NoHatchPeriodAdd from '../../../components/History/HistorySingleView/partials/NoHatchPeriodAdd'
import EditPrepVaccines from '../../../components/History/HistorySingleView/partials/EditPrepVaccines'
import OrderPreparation from '../../../components/History/HistorySingleView/partials/OrderPreparation'
import AddPrep from '../../../components/History/HistorySingleView/partials/AddPrep'
import RemovePrep from '../../../components/History/HistorySingleView/partials/RemovePrep'
import OrderRealisation from '../../../components/History/HistorySingleView/partials/OrderRealisation'
import OrderEndReal from '../../../components/History/HistorySingleView/partials/OrderEndReal'
import Certificate from '../../../components/History/HistorySingleView/partials/Certificate'
import ProblemReport from '../../../components/History/HistorySingleView/partials/ProblemReport/ProblemReport'
import AddPrepAsk from '../../../components/History/HistorySingleView/partials/AddPrepAsk'
import { catchError } from '../../../actions'
import Validation from '../../../components/History/HistorySingleView/partials/Validation'

class HistorySingleView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
    this.getContent = this.getContent.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
  }

  componentDidMount() {
    const { match, token } = this.props
    const { id } = match.params

    Axios.get(`${api}/api/vaccination_histories/${id}`, { headers: { Authorization: token } })
      .then(res => {
        this.setState({ history: res.data, loading: false })
      })
      .catch(err => catchError(err))
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  getContent() {
    const { history } = this.state
    switch (history.type) {
      case 0:
        return <PlanningImport hist={history} />
      case 1:
        return <OrderNumberEdit hist={history} />
      case 2:
        return <NoHatchPeriodAdd hist={history} />
      case 3:
      case 4:
      case 15:
        return <EditPrepVaccines hist={history} />
      case 5:
        return <OrderPreparation hist={history} />
      case 6:
        return <AddPrep hist={history} />
      case 7:
      case 13:
        return <AddPrepAsk hist={history} />
      case 8:
        return <RemovePrep hist={history} />
      case 9:
        return <ProblemReport hist={history} />
      case 10:
        return <OrderRealisation hist={history} />
      case 11:
        return <OrderEndReal hist={history} />
      case 12:
        return <Certificate hist={history} />
      case 14:
      case 16:
        return <OrderPreparation hist={history} isCreation />
      case 17:
        return <OrderPreparation hist={history} isPlanning />
      case 18:
        return <Validation hist={history} />
      default:
        return <Certificate hist={history} />
    }
  }

  render() {
    const { history, loading } = this.state
    if (loading === true) {
      return <LoadingPage />
    }

    return (
      <div className="Container row">
        <div className="col m12">
          <Header type={history.type} />
          {this.getContent()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(HistorySingleView))
