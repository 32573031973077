import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import icons from '../../../assets/icons/icons'
import roles from '../../../config/userRoles.json'

const FridgeManagement = props => {
  const { role } = props
  const { ROLE_OPERATOR } = roles
  let ContainersParts = []

  if (role.includes(ROLE_OPERATOR)) {
    ContainersParts = [{ icon: icons.fridgesList, name: 'listFridge', path: 'list' }]
  } else {
    ContainersParts = [
      { icon: icons.fridgesList, name: 'listFridge', path: 'list' },
      { icon: icons.fridgeProductsTransfer, name: 'transferFridge', path: 'transfer' },
      { icon: icons.fridgeAdd, name: 'newFridge', path: 'new' }
    ]
  }

  return (
    <div className="row Container equipmentManagement">
      {ContainersParts.map(part => (
        <div className="col m4" key={part.name}>
          <Link
            to={{
              pathname: `/equipments/fridges/${part.path}`,
              state: { part: part.name }
            }}
          >
            <div className="card center">
              <img src={part.icon} alt="icon container" className="responsive-img" />
              <p>
                <FormattedMessage id={`app.part.${part.name}`} />
              </p>
            </div>
          </Link>
        </div>
      ))}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(FridgeManagement)
