import React from 'react'
import { injectIntl } from 'react-intl'
import Axios from 'axios'
import Swal from 'sweetalert2'
import { api } from '../../../../parameters'
import { catchError } from '../../../../actions'
import { validatedAction } from '../../../globalAction'
import history from '../../../../history'

class OutStockSubmitButton extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async handleSubmit() {
    const { id, intl, message, reason, redirect, title, token, toOutStock, urlType } = this.props
    Swal.fire({
      title,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({ id: 'app.label.yes' }),
      cancelButtonText: intl.formatMessage({ id: 'app.label.no' }),
      customClass: {
        icon: 'swalWarningIcon',
        title: 'swalTitle',
        confirmButton: 'blueBtn',
        cancelButton: 'blueBtn'
      }
    }).then(res => {
      if (res.dismiss === undefined) {
        Axios.patch(
          `${api}/api/${urlType}/${id}/out_stock`,
          {
            reason,
            toOutStock
          },
          {
            headers: { Authorization: token }
          }
        )
          .then(() => validatedAction(intl.formatMessage({ id: message }), redirect))
          .catch(err => catchError(err.response))
      } else if (res.dismiss === 'backdrop') {
        return null
      } else {
        history.goBack()
      }
    })
  }

  render() {
    const { intl, disabled } = this.props

    return (
      <button
        className="btn blueBtn col offset-s5 s2"
        type="submit"
        onClick={this.handleSubmit}
        disabled={disabled}
      >
        {intl.formatMessage({ id: 'app.button.validate' })}
      </button>
    )
  }
}

export default injectIntl(OutStockSubmitButton)
