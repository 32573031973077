import React from 'react'
import { injectIntl } from 'react-intl'
import logo from '../../../../../assets/images/light_logo.svg'
import hatcheryLogo from '../../../../../assets/images/light_hatchery_logo.png'
import HubbardLogo from '../../../../../assets/images/light_hubbard_logo.png'

const Header = props => {
  const { intl } = props
  const aviagen = process.env.REACT_APP_COMPANY === 'aviagen'
  return (
    <div id="certificateHeader">
      <img className="logo" src={logo} alt="passeport poussin logo" />
      <div className="title">{intl.formatMessage({ id: 'app.certificate.header' })}</div>
      <img
        className="hatcheryLogo"
        src={aviagen ? hatcheryLogo : HubbardLogo}
        alt="hatchery logo"
      />
    </div>
  )
}

export default injectIntl(Header)
