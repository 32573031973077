export const renderNitrogenLevel = (nitrogenLevel, intl) => {
  return nitrogenLevel === null
    ? 'N/A'
    : `${nitrogenLevel} ${intl.formatMessage({ id: 'app.unit.nitrogenLevel' })}`
}

export const renderTemperature = (temperature, intl) => {
  return temperature === null
    ? 'N/A'
    : `${temperature} ${intl.formatMessage({ id: 'app.unit.fridgeTemp' })}`
}
