import Axios from 'axios'
import M from 'materialize-css'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { catchError } from '../../../../actions'
import { api } from '../../../../parameters'
import { validatedAction } from '../../../globalAction'
import LoadingPage from '../../../LoadingPage'
import ContainerInformationsForm from './ContainerInformationsForm'

class ReceivedNitrogenOnly extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      containers: [{}],
      disabledForm: [],
      containersSelected: []
    }
    this.addContainer = this.addContainer.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setContainersSelected = this.setContainersSelected.bind(this)
  }

  componentDidMount() {
    M.AutoInit()
  }

  setContainersSelected(containerId, index) {
    const { containersSelected } = this.state
    containersSelected[index] = parseInt(containerId)
    this.setState({ containersSelected })
  }

  handleSubmit() {
    const { token } = this.props
    const { containers } = this.state
    const history = {
      receptionDate: moment().format()
    }
    const formData = {
      containers,
      history
    }
    Axios.post(`${api}/api/nitrogen`, formData, {
      headers: { Authorization: token }
    })
      .then(() => validatedAction())
      .catch(err => catchError(err.response))
  }

  addContainer() {
    const { containers, disabledForm } = this.state
    if (containers.length > 0) {
      disabledForm.push(true)
    }
    containers.push({})
    this.setState({ containers, disabledForm }, () => M.AutoInit())
  }

  render() {
    const { intl, receiverContainers, setDataToParent, disabled, setDisabled } = this.props
    const { containers, loading, disabledForm, containersSelected } = this.state
    return (
      <div>
        {containers.map((_container, index) => (
          <ContainerInformationsForm
            key={`container${index}`}
            containers={containers}
            intl={intl}
            receiverContainers={receiverContainers}
            setDataToParent={setDataToParent}
            index={index}
            setDisabled={setDisabled}
            disabledForm={disabledForm}
            containersSelected={containersSelected}
            setContainersSelected={this.setContainersSelected}
          />
        ))}
        <div className="row">
          <div className="col offset-s4 s1">
            <button
              type="button"
              className="btn blueBtn"
              onClick={this.addContainer}
              disabled={disabled}
            >
              {intl.formatMessage({ id: 'app.label.add' })}
            </button>
          </div>
          <div className="col offset-s1 s1">
            <button
              type="button"
              className="btn blueBtn"
              onClick={this.handleSubmit}
              disabled={disabled}
            >
              {intl.formatMessage({ id: 'app.button.validate' })}
            </button>
          </div>
        </div>
        {loading && <LoadingPage />}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token
})

export default injectIntl(connect(mapStateToProps)(ReceivedNitrogenOnly))
