import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

function SpanLink({ value, displayedValue = value, setLoading, redirect, token, history }) {
  return (
    <div
      style={{ display: 'inline-block', color: '#009fe3', fontWeight: 'bold', cursor: 'pointer' }}
      role="button"
      tabIndex="0"
      onKeyPress={() => redirect(token, history, value, setLoading)}
      onClick={() => redirect(token, history, value, setLoading)}
    >
      {displayedValue}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default connect(mapStateToProps)(withRouter(SpanLink))
