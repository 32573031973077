import React from 'react'
import icons from '../../../assets/icons/icons'
import ModalMoreActionRealisation from './ModalMoreActionRealisation'

class PlanningLastTd extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { clicked, group, vaccineType, isRealisation } = this.props

    let orderToUse = group.injectionOrder

    if (group.thinDropsOrder !== null && vaccineType === 1) {
      orderToUse = group.thinDropsOrder
    } else if (group.bigDropsOrder !== null && vaccineType === 2) {
      orderToUse = group.bigDropsOrder
    }

    let content = (
      <div className="col s12 pointer">
        <img
          className="pointerEventsNone"
          src={clicked ? icons.blueArrowTop : icons.blueArrowBottom}
          alt="icon blue arrow bottom"
        />
      </div>
    )

    if (isRealisation) {
      content = (
        <div className="col s12">
          <a
            className="col s12 btnAddVaccineApplicator blue-text waves-effect waves-light modal-trigger"
            href={`#modalMoreActionRealisation${group.id}`}
          >
            <img src={icons.moreActionBlack} alt="more action" />
          </a>
          <ModalMoreActionRealisation
            groupId={group.id}
            orderId={orderToUse.id}
            vaccineType={vaccineType}
          />
        </div>
      )
    }

    return <>{content}</>
  }
}

export default PlanningLastTd
