import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

const Home = props => {
  const { username, intl } = props
  return (
    <div className="Container home">
      <div className="homeHeader center">
        <div className="welcome">
          <FormattedMessage id="app.welcome" values={{ what: 'react-intl', username }} />
        </div>
        <div className="header-msg">{intl.formatMessage({ id: 'app.home.header' })}</div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.authenticated,
    username: state.auth.username
  }
}

export default injectIntl(connect(mapStateToProps)(Home))
