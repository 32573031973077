import React from 'react'
import roles from '../../../config/userRoles.json'
import ModalToEditOrderNumber from '../ModalToEditOrderNumber'

class TdOrderNumber extends React.Component {
  constructor() {
    super()
    this.state = {}
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    const { checked } = e.target
    const { manageSelectedOrder, group } = this.props

    manageSelectedOrder(checked, group)
  }

  render() {
    const {
      dayClass,
      checkbox,
      id,
      isEditable,
      isPreparation,
      isRealisation,
      number,
      onClick,
      redirected,
      role,
      group
    } = this.props
    const { ROLE_HATCH_RESP, ROLE_HATCHING_RESP } = roles
    return (
      <td
        className={`${checkbox === true ? 'input' : ''} ${onClick ? 'pointer' : ''} ${dayClass}`}
        onClick={
          !isEditable
            ? (onClick && isPreparation === false) || isRealisation === true
              ? redirected
              : null
            : null
        }
        onKeyPress={
          !isEditable
            ? (onClick && isPreparation === false) || isRealisation === true
              ? redirected
              : null
            : null
        }
        tabIndex={isEditable ? 0 : undefined}
        role="gridcell"
      >
        {((role.includes(ROLE_HATCH_RESP) || role.includes(ROLE_HATCHING_RESP)) && isEditable) ||
        (!group.injectionProcessState &&
          !group.thinDropsProcessState &&
          !group.bigDropsProcessState) ? (
          <>
            {checkbox === true && (
              <label className={`col s4 ${checkbox === true && 'noPadding'}`}>
                <input
                  className="filled-in checkbox"
                  type="checkbox"
                  name="quantityConformity"
                  onChange={this.handleChange}
                />
                <span />
              </label>
            )}
            <a
              className="editOrderNumber waves-effect waves-light modal-trigger"
              href={`#editOrderNumber${id}`}
            >
              {number}
            </a>
            <ModalToEditOrderNumber number={number} id={id} />
          </>
        ) : (
          <span style={{ padding: '10px' }}>{number}</span>
        )}
      </td>
    )
  }
}

export default TdOrderNumber
