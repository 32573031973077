import React from 'react'
import { FormattedMessage } from 'react-intl'
import icons from '../../../assets/icons/icons'

const InfoMessage = props => {
  const { id } = props
  return (
    <div className="row">
      <div className="col offset-m1 m10 card">
        <div className="validationMsg">
          <img className="validationCircle" src={icons.bleuInfo} alt="green success icon" />
          <div className="validationMsgText">
            <p className="title">
              <FormattedMessage id={id} />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoMessage
