import React from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import PreviousProductBagCard from './partials/PreviousProductBagCard'
import ProductBagCard from './partials/ProductBagCard'

class ProductBagsBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      actualPart,
      changePagePart,
      preparation,
      previousPreparation,
      prepId,
      intl
    } = this.props
    return (
      <>
        <div className="row">
          <div
            className={`col s12 flex ${
              previousPreparation !== undefined ? 'flexSpaceAround' : 'flex-center'
            }`}
          >
            {previousPreparation !== undefined && (
              <PreviousProductBagCard previousPreparation={previousPreparation} />
            )}
            <ProductBagCard preparation={preparation} actualPart={actualPart} />
          </div>
        </div>
        <div className="row">
          <div className="col s12 flex flex-center">
            <Link
              className="btn blueBtn space5pixel"
              to={`/vaccination/realisation/injected/${prepId}/signal-productbag-problem`}
            >
              {intl.formatMessage({ id: 'app.label.signalProblem' })}
            </Link>
            <button type="button" className="btn blueBtn" onClick={changePagePart}>
              {intl.formatMessage({ id: 'app.button.validate' })}
            </button>
          </div>
        </div>
      </>
    )
  }
}

export default injectIntl(ProductBagsBlock)
