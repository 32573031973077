import React, { createContext, useState, useEffect } from 'react'
import fetchItems from './fetchItems'
import getLabelByKey from './getLabelByKey'

const GenerationsContext = createContext({
  generations: [],
  getGenerationLabelByKey: () => '',
  fetchGenerations: () => {}
})

export const GenerationsProvider = ({ children }) => {
  const [generations, setGenerations] = useState([])

  const fetchGenerations = () => {
    fetchItems('generations', setGenerations)
  }

  useEffect(() => {
    fetchGenerations()
  }, [])

  const getGenerationLabelByKey = key => getLabelByKey(generations, key)

  return (
    <GenerationsContext.Provider
      value={{
        generations,
        getGenerationLabelByKey,
        fetchGenerations
      }}
    >
      {children}
    </GenerationsContext.Provider>
  )
}

export default GenerationsContext
