import React from 'react'
import { injectIntl } from 'react-intl'

class ChangesDisplayNotification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hatchResp: false,
      veterinary: false,
      hatchingResp: false,
      operator: false,
      hatchRespChanged: false,
      veterinaryChanged: false,
      hatchingRespChanged: false,
      operatorChanged: false
    }
  }

  componentDidMount() {
    const { value1, value2 } = this.props
    value1.forEach(element => {
      if (element === 'hatchRespAlerts') this.setState({ hatchResp: true })
      if (element === 'vetAlerts') this.setState({ veterinary: true })
      if (element === 'hatchingRespAlerts') this.setState({ hatchingResp: true })
      if (element === 'operatorAlerts') this.setState({ operator: true })
    })

    value2.forEach(element => {
      if (element === 'hatchRespAlerts') this.setState({ hatchRespChanged: true })
      if (element === 'vetAlerts') this.setState({ veterinaryChanged: true })
      if (element === 'hatchingRespAlerts') this.setState({ hatchingRespChanged: true })
      if (element === 'operatorAlerts') this.setState({ operatorChanged: true })
    })
  }

  render() {
    const { intl } = this.props
    const {
      hatchResp,
      veterinary,
      hatchingResp,
      operator,
      hatchRespChanged,
      veterinaryChanged,
      hatchingRespChanged,
      operatorChanged
    } = this.state

    return (
      <div className="col s12">
        <span className="col s6">
          <div className="row">
            <div className="col s12">
              <span className="labelCustom">{intl.formatMessage({ id: 'app.label.before' })} </span>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <div className="row">
                <div className="col s12">
                  <label className="col s6">
                    <input
                      name="hatchResp"
                      type="checkbox"
                      className="filled-in checkbox"
                      checked={hatchResp}
                    />
                    <span>{intl.formatMessage({ id: 'app.label.hatchResp' })}</span>
                  </label>
                  <label className="col s6">
                    <input
                      name="veterinary"
                      type="checkbox"
                      className="filled-in checkbox"
                      checked={veterinary}
                    />
                    <span>{intl.formatMessage({ id: 'app.label.veterinary' })}</span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <label className="col s6">
                    <input
                      name="hatchingResp"
                      type="checkbox"
                      className="filled-in checkbox"
                      checked={hatchingResp}
                    />
                    <span>{intl.formatMessage({ id: 'app.label.hatchingResp' })}</span>
                  </label>
                  <label className="col s6">
                    <input
                      name="operator"
                      type="checkbox"
                      className="filled-in checkbox"
                      checked={operator}
                    />
                    <span>{intl.formatMessage({ id: 'app.label.operator' })}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </span>
        <span className="col s6">
          <div className="row">
            <div className="col s12">
              <span className="labelCustom">{intl.formatMessage({ id: 'app.label.after' })} </span>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <div className="row">
                <div className="col s12">
                  <label className="col s6">
                    <input
                      name="hatchResp"
                      type="checkbox"
                      className="filled-in checkbox"
                      checked={hatchRespChanged}
                    />
                    <span>{intl.formatMessage({ id: 'app.label.hatchResp' })}</span>
                  </label>
                  <label className="col s6">
                    <input
                      name="veterinary"
                      type="checkbox"
                      className="filled-in checkbox"
                      checked={veterinaryChanged}
                    />
                    <span>{intl.formatMessage({ id: 'app.label.veterinary' })}</span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <label className="col s6">
                    <input
                      name="hatchingResp"
                      type="checkbox"
                      className="filled-in checkbox"
                      checked={hatchingRespChanged}
                    />
                    <span>{intl.formatMessage({ id: 'app.label.hatchingResp' })}</span>
                  </label>
                  <label className="col s6">
                    <input
                      name="operator"
                      type="checkbox"
                      className="filled-in checkbox"
                      checked={operatorChanged}
                    />
                    <span>{intl.formatMessage({ id: 'app.label.operator' })}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </span>
      </div>
    )
  }
}

export default injectIntl(ChangesDisplayNotification)
