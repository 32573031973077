import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const LinksPage = props => {
  const { itemsPerLine, items, operator } = props
  let colClass = 'm12'

  switch (itemsPerLine) {
    case 1:
      colClass = 'm12'
      break
    case 2:
      colClass = 'm6'
      break
    case 3:
      colClass = 'm4'
      break
    default:
  }

  return (
    <div className="Container">
      <div className="row">
        <div className="col s12">
          <div className="row">
            {operator && <div className={`col ${colClass} offset-col${colClass}`} />}
            {items.map(item => (
              <div className={`col ${colClass} vaccinationManagementItem`} key={item.name}>
                <Link
                  to={{
                    pathname: `${item.path}`,
                    state: { item: item.name }
                  }}
                >
                  <div className="card center">
                    <img src={item.icon} alt="icon container" className="responsive-img" />
                    <p>
                      <FormattedMessage id={`app.part.${item.name}`} />
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LinksPage
