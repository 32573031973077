import React from 'react'
import { injectIntl } from 'react-intl'
import TextArea from '../../partials/TextArea'
import BlockHistoryPart from '../BlockHistoryPart/BlockHistoryPart'

const BlockEquipmentPart = props => {
  const {
    intl,
    paramId,
    blockAlert,
    blockAlertAfter,
    blockAlertBefore,
    blockValueMax,
    blockValueMaxAfter,
    blockValueMaxBefore,
    blockValueMin,
    blockValueMinAfter,
    blockValueMinBefore,
    congruentPartValueMin,
    history,
    handleChange,
    action
  } = props

  if (!history) {
    switch (paramId) {
      case 0:
      case 6:
        return (
          <div className="row">
            <div className="col s12 input">
              <div className="row">
                <div className="input col s3">
                  <label htmlFor="blockValueMin" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.ifLowerTempIncluded' })}
                    &nbsp;
                  </label>
                  <input
                    name="blockValueMin"
                    type="number"
                    step="0.1"
                    value={blockValueMin || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className="input col s3">
                  <label htmlFor="blockValueMax" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.ifHigherTempIncluded' })}
                    &nbsp;
                  </label>
                  <input
                    name="blockValueMax"
                    type="number"
                    step="0.1"
                    value={blockValueMax || ''}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <label htmlFor="blockAlert" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.alert' })}
                    &nbsp;
                  </label>
                  <textarea
                    className="materialize-textarea"
                    name="blockAlert"
                    value={blockAlert || ''}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="divider greyDivider" />
          </div>
        )
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return (
          <div className="row">
            <div className="col s12 input">
              <div className="row">
                <div className="input col s3">
                  <label htmlFor="blockValueMin" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.ifLowerNitrogenIncluded' })}
                    &nbsp;
                  </label>
                  <input
                    name="blockValueMin"
                    type="number"
                    value={blockValueMin || ''}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col s6">
                  <label htmlFor="blockAlert" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.alert' })}
                    &nbsp;
                  </label>
                  <textarea
                    className="materialize-textarea"
                    name="blockAlert"
                    value={blockAlert || ''}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="divider greyDivider" />
          </div>
        )
      case 8:
      case 9:
        return (
          <div className="row">
            <div className="col s12 input">
              <div className="row">
                <div className="input col s3">
                  <label htmlFor="blockValueMin" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.ifLowerVolumeIncluded' })}
                    &nbsp;
                  </label>
                  <input
                    name="blockValueMin"
                    type="number"
                    value={blockValueMin || ''}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <label htmlFor="blockAlert" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.alert' })}
                    &nbsp;
                  </label>
                  <textarea
                    className="materialize-textarea"
                    name="blockAlert"
                    value={blockAlert || ''}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="divider greyDivider" />
          </div>
        )
      case 7:
        return (
          <div className="row">
            <div className="col s12 input">
              <div className="row">
                <div className="col s3">
                  <label htmlFor="blockAlert" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.ifLowerVolumeIncluded' })}
                    &nbsp;
                  </label>
                  <input
                    className="materialize-textarea"
                    name="blockAlert"
                    type="number"
                    value={congruentPartValueMin || ''}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <TextArea
                    label={intl.formatMessage({ id: 'app.label.alertNitrogenLevelHigher' })}
                    name="blockAlert"
                    value1={blockAlert || ''}
                    handleChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="divider greyDivider" />
          </div>
        )
      case 10:
        return (
          <div className="row">
            <div className="col s12 input">
              <div className="row">
                <div className="col s12">
                  <label htmlFor="blockAlert" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.alert' })}
                    &nbsp;
                  </label>
                  <textarea
                    className="materialize-textarea"
                    name="blockAlert"
                    value={blockAlert || ''}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="divider greyDivider" />
          </div>
        )
      default:
        return null
    }
  }
  return (
    <BlockHistoryPart
      paramId={paramId}
      action={action}
      blockAlertAfter={blockAlertAfter}
      blockAlertBefore={blockAlertBefore}
      blockValueMaxBefore={blockValueMaxBefore}
      blockValueMinBefore={blockValueMinBefore}
      blockValueMaxAfter={blockValueMaxAfter}
      blockValueMinAfter={blockValueMinAfter}
    />
  )
}

export default injectIntl(BlockEquipmentPart)
