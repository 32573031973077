import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { connect } from 'react-redux'
import { catchError } from '../../../../../actions'
import { api } from '../../../../../parameters'
import CenterButton from '../../../../CenterButton'
import LoadingPage from '../../../../LoadingPage'

class ReceivedVaccineOnly extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      receiverContainers: [],
      loading: true
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const { nitrogen, token } = this.props
    let filters = ''

    if (!nitrogen) {
      filters = '&type[]=1&type[]=2'
    }

    Axios.get(`${api}/api/nitrogen_containers?active=true${filters}`, {
      headers: { Authorization: token }
    })
      .then(res =>
        this.setState({ receiverContainers: res.data['hydra:member'], loading: false }, () =>
          M.AutoInit()
        )
      )
      .catch(err => catchError(err.response))
  }

  handleChange(e) {
    const { setDataToParent, setDisabled } = this.props
    const { receiverContainers } = this.state
    const { value, name } = e.target
    if (name === 'availableContainerNumber') {
      if (value !== null && value !== undefined) {
        setDisabled(false)
      } else {
        setDisabled(true)
      }
    }
    receiverContainers.forEach(container => {
      if (container.id === parseInt(value)) {
        const selectedContainerTmp = {
          containerNumber: container.containerNumber,
          id: container.id,
          control: {
            nitrogenLevel: container.lastControl.nitrogenLevel,
            nitrogenLevelState: 1
          }
        }
        this.setState(
          {
            selectedContainer: selectedContainerTmp
          },
          () => {
            setDataToParent('container', selectedContainerTmp)
          }
        )
      }
    })
  }

  render() {
    const { intl, changePart, disabled } = this.props
    const { selectedContainer, receiverContainers, loading } = this.state
    return (
      <>
        <div className="row">
          <div className="col s12">
            <div className="form nitrogen">
              <div className="row">
                <div className="col s12">
                  <div className="col s6 input">
                    <label htmlFor="availableContainerNumber">
                      {intl.formatMessage({ id: 'app.label.availableContainerNumber' })}
                    </label>
                    <select
                      className="select-wrapper"
                      defaultValue="select"
                      name="availableContainerNumber"
                      onChange={this.handleChange}
                    >
                      <option value="select" disabled>
                        {intl.formatMessage({ id: 'app.label.selectContainer' })}
                      </option>
                      {receiverContainers.map(container => (
                        <option value={container.id} key={container.id}>
                          {container.containerNumber}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col s6 input">
                    <label htmlFor="nitrogelLevel">
                      {intl.formatMessage({ id: 'app.label.nitrogenLevel' })}
                    </label>
                    <input
                      type="text"
                      name="nitrogelLevel"
                      value={selectedContainer ? selectedContainer.control.nitrogenLevel : ''}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CenterButton
          text={intl.formatMessage({ id: 'app.button.validate' })}
          onClick={changePart}
          disabled={disabled}
        />
        {loading && <LoadingPage />}
      </>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token
})

export default connect(mapStateToProps)(ReceivedVaccineOnly)
