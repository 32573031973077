import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import AddAndEditArticle from '../../../components/Parameters/History/AddAndEditArticle'
import AddAndEditSpeciality from '../../../components/Parameters/History/AddAndEditSpeciality'
import AddParamStock from '../../../components/Parameters/History/AddParamStock'
import EditParamEquipment from '../../../components/Parameters/History/EditParamEquipment'
import EditParamStock from '../../../components/Parameters/History/EditParamStock'
import EditParamVaccination from '../../../components/Parameters/History/EditParamVaccination'
import { api } from '../../../parameters'

class SingleHistoryParams extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    const { match, token } = this.props
    const { id } = match.params

    Axios.get(`${api}/api/parameter_histories/${id}`, { headers: { Authorization: token } })
      .then(res => this.setState({ history: res.data, loading: false }))
      .catch(err => catchError(err.response))
  }

  render() {
    const { history, loading } = this.state

    if (loading === true) {
      return <LoadingPage />
    }

    const { type } = history

    switch (type) {
      case 0:
      case 1:
        return <AddAndEditSpeciality data={history} />
      case 2:
      case 3:
      case 8:
        return <AddAndEditArticle data={history} />
      case 4:
        return <EditParamEquipment data={history} />
      case 5:
        return <AddParamStock data={history} />
      case 6:
        return <EditParamStock data={history} />
      case 7:
        return <EditParamVaccination data={history} />
      default:
        return (
          <div>
            <LoadingPage />
          </div>
        )
    }
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(SingleHistoryParams))
