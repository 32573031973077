import React from 'react'
import { injectIntl } from 'react-intl'

const AsyncLoader = props => {
  const { status, intl } = props
  switch (status) {
    case 'inLoad':
      return (
        <div className="center tableFootMessage">
          <div className="loader" />
        </div>
      )
    case 'noMoreResult':
      return (
        <div className="center tableFootMessage">
          {intl.formatMessage({ id: 'app.label.allResultAreDisplay' })}
        </div>
      )
    default:
      return (
        <div className="center tableFootMessage">
          <i className="material-icons">arrow_downward</i>{' '}
          {intl.formatMessage({ id: 'app.label.scrollToView' })}
        </div>
      )
  }
}

export default injectIntl(AsyncLoader)
