import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import { closePage } from '../../../globalAction'

const HeaderReception = props => {
  const { text, history, intl } = props
  return (
    <div className="row">
      <div className="flex flexSpaceBetween col s12">
        <div>
          <div className="pageTitle">
            <h5>{text}</h5>
          </div>
        </div>
        <div>
          <div
            role="button"
            tabIndex="0"
            onKeyPress={() => history.goBack()}
            onClick={() => closePage(intl)}
            className="goBackBtn"
          >
            <i className="material-icons">close</i>
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(withRouter(HeaderReception))
