import React from 'react'
import { injectIntl } from 'react-intl'

const NoBlockVaccinationHistoryPart = props => {
  const { intl, action, vaccinationParamId } = props

  switch (vaccinationParamId) {
    case 0:
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.before' })}
                </label>
              </div>
            </div>
            <div className="row input">
              <div className="col s12">
                <label htmlFor="noBlockPartAlertExpirationDateBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;:&nbsp;
                </label>
                <span>
                  {action.changes.nonBlockingAlert[0].message || action.nonBlockingAlert.message}
                </span>
              </div>
            </div>
          </div>
          <div className="col s6">
            <div className="row input">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.after' })}
                </label>
              </div>
            </div>
            <div className="row input">
              <div className="col s12">
                <label htmlFor="noBlockPartAlertExpirationDateBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;:&nbsp;
                </label>
                <span>{action.nonBlockingAlert.message}</span>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    case 1:
      return (
        <div className="row">
          <div className="col s12">
            <div className="row input">
              <div className="col s6">
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.before' })}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.useDiffProductOrVaccine' })}
                      &nbsp;:
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label className="col s3">
                      <input
                        type="checkbox"
                        className="filled-in checkbox"
                        name="vaccin"
                        checked={action.changes.nonBlockingAlert[0].value.includes(0) || false}
                      />
                      <span>{intl.formatMessage({ id: 'app.label.vaccin' })}</span>
                    </label>
                    <label className="col s3">
                      <input
                        type="checkbox"
                        className="filled-in checkbox"
                        name="productBag"
                        checked={action.changes.nonBlockingAlert[0].value.includes(1) || false}
                      />
                      <span>{intl.formatMessage({ id: 'app.label.productBag' })}</span>
                    </label>
                    <label className="col s3">
                      <input
                        type="checkbox"
                        className="filled-in checkbox"
                        checked={action.changes.nonBlockingAlert[0].value.includes(2) || false}
                      />
                      <span>{intl.formatMessage({ id: 'app.label.diluent' })}</span>
                    </label>
                    <label className="col s3">
                      <input
                        type="checkbox"
                        className="filled-in checkbox"
                        checked={action.changes.nonBlockingAlert[0].value.includes(3) || false}
                      />
                      <span>{intl.formatMessage({ id: 'app.label.dye' })}</span>
                    </label>
                  </div>
                </div>
                <div className="row input">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlertStockAfter" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.alert' })}
                      &nbsp;:&nbsp;
                    </label>
                    <span>
                      {action.changes.nonBlockingAlert[0].message ||
                        action.nonBlockingAlert.message}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col s6">
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.after' })}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.useDiffProductOrVaccine' })}
                      &nbsp;:
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label className="col s3">
                      <input
                        type="checkbox"
                        className="filled-in checkbox"
                        name="vaccin"
                        checked={action.nonBlockingAlert.value.includes(0) || false}
                      />
                      <span>{intl.formatMessage({ id: 'app.label.vaccin' })}</span>
                    </label>
                    <label className="col s3">
                      <input
                        type="checkbox"
                        className="filled-in checkbox"
                        name="productBag"
                        checked={action.nonBlockingAlert.value.includes(1) || false}
                      />
                      <span>{intl.formatMessage({ id: 'app.label.productBag' })}</span>
                    </label>
                    <label className="col s3">
                      <input
                        type="checkbox"
                        className="filled-in checkbox"
                        checked={action.nonBlockingAlert.value.includes(2) || false}
                      />
                      <span>{intl.formatMessage({ id: 'app.label.diluent' })}</span>
                    </label>
                    <label className="col s3">
                      <input
                        type="checkbox"
                        className="filled-in checkbox"
                        checked={action.nonBlockingAlert.value.includes(3) || false}
                      />
                      <span>{intl.formatMessage({ id: 'app.label.dye' })}</span>
                    </label>
                  </div>
                </div>
                <div className="row input">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlertStockAfter" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.alert' })}
                      &nbsp;:&nbsp;
                    </label>
                    <span>{action.nonBlockingAlert.message}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    case 2:
      return (
        <div className="row">
          <div className="col s12">
            <div className="row input">
              <div className="col s6">
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.before' })}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.alert' })}
                      &nbsp;:&nbsp;
                    </label>
                    <span>
                      {action.changes.nonBlockingAlert[0].message ||
                        action.nonBlockingAlert.message}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col s6">
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.after' })}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.alert' })}
                      &nbsp;:&nbsp;
                    </label>
                    <span>{action.nonBlockingAlert.message}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    case 8:
      return (
        <div className="row">
          <div className="col s12">
            <div className="row input">
              <div className="col s6">
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.before' })}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.ifVaccinatedChickPercent' })}
                      &nbsp;:&nbsp;
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({
                        id: 'app.label.ifVaccinatedChickPercentLower'
                      })}
                      &nbsp;:&nbsp;
                    </label>
                    <span>
                      {action.changes.nonBlockingAlert[0].value.min ||
                        action.nonBlockingAlert.value.min}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.alert' })}
                      &nbsp;:&nbsp;
                    </label>
                    <span>
                      {action.changes.nonBlockingAlert[0].message.min ||
                        action.nonBlockingAlert.message.min}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col s6">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({
                        id: 'app.label.ifVaccinatedChickPercentHigher'
                      })}
                      &nbsp;:&nbsp;
                    </label>
                    <span>
                      {action.changes.nonBlockingAlert[0].value.max.percent ||
                        action.nonBlockingAlert.value.max.percent}
                    </span>
                  </div>
                  <div className="col s6">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.NbChickOrdered' })}
                      &nbsp;:&nbsp;
                    </label>
                    <span>
                      {action.changes.nonBlockingAlert[0].value.max.value ||
                        action.nonBlockingAlert.value.max.value}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.alert' })}
                      &nbsp;:&nbsp;
                    </label>
                    <span>
                      {action.changes.nonBlockingAlert[0].message.max ||
                        action.nonBlockingAlert.message.max}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col s6">
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.after' })}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.ifVaccinatedChickPercent' })}
                      &nbsp;:&nbsp;
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({
                        id: 'app.label.ifVaccinatedChickPercentLower'
                      })}
                      &nbsp;:&nbsp;
                    </label>
                    <span>{action.nonBlockingAlert.value.min}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.alert' })}
                      &nbsp;:&nbsp;
                    </label>
                    <span>{action.nonBlockingAlert.message.min}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col s6">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({
                        id: 'app.label.ifVaccinatedChickPercentHigher'
                      })}
                      &nbsp;:&nbsp;
                    </label>
                    <span>{action.nonBlockingAlert.value.max.percent}</span>
                  </div>
                  <div className="col s6">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.NbChickOrdered' })}
                      &nbsp;:&nbsp;
                    </label>
                    <span>{action.nonBlockingAlert.value.max.value}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.alert' })}
                      &nbsp;:&nbsp;
                    </label>
                    <span>{action.nonBlockingAlert.message.max}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    default:
      return (
        <div className="row">
          <div className="col s12">
            <div className="row input">
              <div className="col s6">
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.before' })}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.ifTimeBeetween' })}
                      &nbsp;:&nbsp;
                    </label>
                    <span>
                      {`${action.changes.nonBlockingAlert[0].value.min ||
                        action.nonBlockingAlert.value.min}
                              ${intl.formatMessage({ id: 'app.label.and' })} 
                              ${action.changes.nonBlockingAlert[0].value.max ||
                                action.nonBlockingAlert.value.max} `}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.alert' })}
                      &nbsp;:&nbsp;
                    </label>
                    <span>
                      {action.changes.nonBlockingAlert[0].message ||
                        action.nonBlockingAlert.message}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col s6">
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.after' })}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.ifTimeBeetween' })}
                      &nbsp;:&nbsp;
                    </label>
                    <span>
                      {`${action.nonBlockingAlert.value.min}
                              ${intl.formatMessage({ id: 'app.label.and' })} 
                              ${action.nonBlockingAlert.value.max} `}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.alert' })}
                      &nbsp;:&nbsp;
                    </label>
                    <span>{action.nonBlockingAlert.message}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
  }
}

export default injectIntl(NoBlockVaccinationHistoryPart)
