/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import { WithUnit, Translation, SpanLink } from '../Components'
import { getVaccineOrProductByBatchNumber, getEquipmentById } from '../ReverseRedirections'

function Products({ token, location, intl, history }) {
  const [state, setState] = useState({
    loading: true,
    items: null,
    batchNumber: null
  })

  const translationKey = 'app.label.order.products.'

  const t = value => {
    return <Translation id={`${translationKey}${value}`} />
  }

  const headers = [
    ['provider', 'expiration_date', 'volume', 'batchNumber'],
    [
      'provider',
      'batchNumber',
      'expiration_date',
      'storage_device',
      'temperature_min',
      'temperature_max',
      'temperature_instant'
    ],
    [
      'provider',
      'color',
      'batchNumber',
      'expiration_date',
      'storage_device',
      'temperature_min',
      'temperature_max',
      'temperature_instant'
    ]
  ]

  useEffect(() => {
    const pathName = location.pathname.split('/')
    const id = Number(pathName[pathName.length - 2])
    Axios.get(`${api}/api/order_traceability/${id}/product`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const productBagMapper = i => {
          const unknownValue = intl.formatMessage({ id: 'app.label.N/A' })
          const result = {
            provider: i.provider ?? unknownValue,
            expiration_date: i.expirationDate
              ? moment(i.expirationDate).format('DD/MM/YYYY')
              : unknownValue,
            volume: <WithUnit text={i.volume ?? unknownValue} unit="productBagVolume" />,
            batchNumber: i.key ?? unknownValue
          }
          return Object.entries(result)
        }
        const diluentOrDyeMapper = (i, withColor) => {
          const unknownValue = intl.formatMessage({ id: 'app.label.N/A' })
          const color = withColor
            ? {
                color: i.color ? intl.formatMessage({ id: `app.color.${i.color}` }) : unknownValue
              }
            : {}
          return Object.entries({
            provider: i.provider ?? unknownValue,
            ...color,
            batchNumber: i.batchNumber ?? unknownValue,
            expirationDate: i.expirationDate
              ? moment(i.expirationDate).format('DD/MM/YYYY')
              : unknownValue,
            storageDevice: i.equipmentId ?? unknownValue,
            temperatureMin: (
              <WithUnit text={i.lastControl?.minTemperature ?? unknownValue} unit="temp" />
            ),
            temperatureMax: (
              <WithUnit text={i.lastControl?.maxTemperature ?? unknownValue} unit="temp" />
            ),
            temperatureInstant: (
              <WithUnit text={i.lastControl?.instantTemperature ?? unknownValue} unit="temp" />
            )
          })
        }
        const items = [[], [], []]
        if (res.data['hydra:member'].productBag !== undefined) {
          Object.entries(res.data['hydra:member'].productBag).forEach(i => {
            items[0].push(productBagMapper({ key: i[0], ...i[1] }))
          })
        }
        if (res.data['hydra:member'].diluent !== undefined) {
          Object.entries(res.data['hydra:member'].diluent).forEach(i => {
            items[1].push(diluentOrDyeMapper({ ...i[1] }, false))
          })
        }
        if (res.data['hydra:member'].dye !== undefined) {
          Object.entries(res.data['hydra:member'].dye).forEach(i => {
            items[2].push(diluentOrDyeMapper({ ...i[1] }, true))
          })
        }
        setState({
          loading: false,
          items,
          batchNumber: res.data.currentNumber
        })
      })
      .catch(err => catchError(err))
  }, [])

  return (
    <div className="row Container stockAvailibilityItem">
      <div className="col s12 Content">
        <div className="row pageHeader pageTitle itemHeader">
          <div
            onClick={() => history.goBack()}
            role="button"
            tabIndex="0"
            onKeyPress={() => history.goBack()}
            className="goBackBtn col s1"
          >
            <i className="material-icons">chevron_left</i>
          </div>
          {state.batchNumber !== null && (
            <h5 className="col s12">
              {intl.formatMessage({ id: 'app.title.order' })} {state.batchNumber} -{' '}
              {intl.formatMessage({ id: 'app.title.products' })}
            </h5>
          )}
        </div>
        {!state.loading ? (
          <div className="row">
            {state.items.map((productType, key) => {
              return (
                productType.length > 0 && (
                  <>
                    <div>
                      {intl.formatMessage({
                        id: `app.label.order.products.${key}`
                      })}
                    </div>
                    <table className="table" style={{ marginBottom: '16px' }}>
                      <thead>
                        <tr>
                          {headers[key].map(header => (
                            <th>{t(header)}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {productType.map(item => {
                          return (
                            <tr>
                              {item.map(cell => {
                                return !['batchNumber', 'storageDevice'].includes(cell[0]) ? (
                                  <td>{cell[1]}</td>
                                ) : (
                                  <td>
                                    <SpanLink
                                      value={cell[1]}
                                      setLoading={loading => {
                                        setState({
                                          ...state,
                                          loading
                                        })
                                      }}
                                      redirect={
                                        cell[0] === 'batchNumber'
                                          ? getVaccineOrProductByBatchNumber
                                          : getEquipmentById
                                      }
                                      token
                                      history
                                    />
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </>
                )
              )
            })}
          </div>
        ) : (
          <LoadingPage />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(Products)))
