import React from 'react'
import { injectIntl } from 'react-intl'

const CheckboxBlock = props => {
  const { handleChange, intl } = props
  return (
    <div className="row custom-card form">
      <div className="card-content">
        <div className="row input card-block">
          <div className="col s6">
            <label>
              <input
                type="checkbox"
                className="filled-in checkbox"
                name="reservedVaccine"
                onChange={handleChange}
              />
              <span className="labelCustom">
                {intl.formatMessage({ id: 'app.label.reserveVaccine' })}
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(CheckboxBlock)
