import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { catchError } from '../../../actions'
import icons from '../../../assets/icons/icons'
import { api } from '../../../parameters'
import AllValidatedMessage from './AllValidatedMessage'
import BigDrops from './BigDropsForm'
import FineDrops from './FineDropsForm'
import InfoMessage from './InfoMessage'
import InjectionForm from './InjectionsForm'

class VaccinationPart extends React.Component {
  constructor() {
    super()
    this.state = {
      Injection: true,
      BIND: true,
      COCC: true,
      isControled: []
    }

    this.getLastControl = this.getLastControl.bind(this)
    this.isControl = this.isControl.bind(this)
  }

  componentDidMount() {
    const { token } = this.props
    this.getLastControl()
    Axios.get(`${api}/api/no_hatching_periods`, { headers: { Authorization: token } })
      .then(res => {
        const noHatchingDays = res.data['hydra:member']
        let noHatchingDay = false
        let index = 0

        while (index < noHatchingDays.length) {
          if (moment().isBetween(noHatchingDays[index].start, noHatchingDays[index].end)) {
            noHatchingDay = true
          }
          index += 1
        }
        this.setState({ noHatchingDay })
      })
      .catch(err => catchError(err))
  }

  getLastControl() {
    const { token } = this.props
    const machines = ['Injection', 'BIND', 'COCC']

    let lastControl

    machines.forEach(machine => {
      Axios.get(`${api}/api/vaccination_machines/last_control/${machine}`, {
        headers: { Authorization: token }
      })
        .then(res => {
          const { date } = res.data

          if (
            lastControl === undefined ||
            moment(date).format('DD/MM/YYYY') > moment(lastControl).format('DD/MM/YYYY')
          ) {
            lastControl = date
          }

          if (moment(date).format('WW') !== moment().format('WW')) {
            return this.setState({ [machine]: true, lastControl })
          }

          return this.setState({ [machine]: false, lastControl })
        })
        .catch(() => {})
    })
  }

  getContent() {
    const { Injection, BIND, COCC, noHatchingDay, isControled, lastControl } = this.state
    const { token, history } = this.props

    if (isControled.includes(0) && isControled.includes(1) && isControled.includes(2)) {
      return <AllValidatedMessage lastControl={lastControl} id="app.equipment.vaccinationAll" />
    }

    return (
      <>
        {!noHatchingDay ? (
          <div>
            {Injection && (
              <div>
                <InjectionForm isControl={this.isControl} token={token} history={history} />
              </div>
            )}
            {BIND && (
              <div>
                <FineDrops isControl={this.isControl} token={token} history={history} />
              </div>
            )}
            {COCC && (
              <div>
                <BigDrops isControl={this.isControl} token={token} history={history} />
              </div>
            )}
            {!Injection && !BIND && !COCC && (
              <div>
                <div className="row">
                  <div className="col offset-m1 m10 card">
                    <div className="validationMsg">
                      <img
                        className="validationCircle"
                        src={icons.greenSuccess}
                        alt="green success icon"
                      />
                      <div className="validationMsgText">
                        <p className="title">
                          <FormattedMessage id="app.equipment.vaccinationAll" />
                        </p>
                        <FormattedMessage id="app.label.lastControlDate" />{' '}
                        {moment().format('DD/MM/YYYY')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <InfoMessage id="app.label.noHachingMessage" />
        )}
      </>
    )
  }

  isControl(machineNumber) {
    const { isControled } = this.state

    if (!isControled.includes(machineNumber)) {
      isControled.push(machineNumber)
    }

    this.setState({ isControled })
  }

  render() {
    return (
      <div className="row">
        <div className="col s12">
          <div id="vaccination">{this.getContent()}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default withRouter(connect(mapStateToProps)(VaccinationPart))
