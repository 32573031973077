/* eslint-disable prefer-destructuring */
import Axios from 'axios'
import M from 'materialize-css'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import Swal from 'sweetalert2'
import { catchError } from '../../actions'
import icons from '../../assets/icons/icons'
import { closePage, validatedAction } from '../../components/globalAction'
import LoadingPage from '../../components/LoadingPage'
import roles from '../../config/userRoles.json'
import { api } from '../../parameters'

class AlertsListPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      alert: {},
      loading: true
    }

    this.handleDelete = this.handleDelete.bind(this)
    this.setButton = this.setButton.bind(this)
    this.setMessage = this.setMessage.bind(this)
    this.setTypeRender = this.setTypeRender.bind(this)
  }

  componentDidMount() {
    const { apiToken, match } = this.props
    Axios.get(`${api}/api/alerts/${match.params.id}`, {
      headers: {
        Authorization: apiToken
      }
    })
      .then(res => {
        this.setState({ alert: res.data, loading: false })
        this.setTypeRender()
        this.setMessage()
        this.setButton()
      })
      .catch(err => catchError(err.response))

    M.AutoInit()
  }

  setTypeRender() {
    const { alert } = this.state
    const { type } = alert
    let color = ''
    let icon = ''

    switch (type) {
      case 1:
      case 2:
        color = 'orange'
        icon = icons.nonBlockingAlert
        break
      case 3:
        color = 'red'
        icon = icons.blockingAlert
        break
      case 5:
      case 6:
      case 7:
      case 8:
        color = 'blue'
        icon = icons.blueInfo
        break
      default:
    }

    this.setState({ color, icon })
  }

  setButton() {
    const { intl, role } = this.props
    const { alert, linkId } = this.state
    const { ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_VET } = roles
    let button = ''

    if (
      ((role.includes(ROLE_HATCH_RESP) || role.includes(ROLE_HATCHING_RESP)) && alert.type === 3) ||
      alert.type === 2
    ) {
      button = (
        <button type="button" onClick={() => this.handleDelete(alert.uuid)} className="btn redBtn">
          {intl.formatMessage({ id: 'app.label.closeAlert' })}
        </button>
      )
    }

    if (
      (role.includes(ROLE_VET) && alert.type === 5) ||
      ((role.includes(ROLE_HATCH_RESP) || role.includes(ROLE_HATCHING_RESP)) && alert.type === 6)
    ) {
      button = (
        <Link to={`/check/${alert.uuid}/${linkId}`}>
          <button type="button" className="btn blueBtn">
            {intl.formatMessage({ id: 'app.button.check' })}
          </button>
        </Link>
      )
    }

    if ((role.includes(ROLE_HATCH_RESP) || role.includes(ROLE_HATCHING_RESP)) && alert.type === 7) {
      button = (
        <>
          <Link to={linkId}>
            <button type="button" className="btn blueBtn marginRight10">
              {intl.formatMessage({ id: 'app.label.addPreparation' })}
            </button>
          </Link>
          <button
            type="button"
            onClick={() => this.handleDelete(alert.uuid)}
            className="btn redBtn"
          >
            {intl.formatMessage({ id: 'app.label.closeAlert' })}
          </button>
        </>
      )
    }

    if ((role.includes(ROLE_HATCH_RESP) || role.includes(ROLE_HATCHING_RESP)) && alert.type === 8) {
      button = (
        <>
          <Link to={linkId}>
            <button type="button" className="btn blueBtn marginRight10">
              {intl.formatMessage({ id: 'app.label.removePreparation' })}
            </button>
          </Link>
          <button
            type="button"
            onClick={() => this.handleDelete(alert.uuid)}
            className="btn redBtn"
          >
            {intl.formatMessage({ id: 'app.label.closeAlert' })}
          </button>
        </>
      )
    }

    this.setState({ button })
  }

  setMessage() {
    const { alert } = this.state
    let { message } = alert
    let linkId = null
    let finalLinkId = ''

    switch (alert.type) {
      case 5:
      case 6:
        linkId = message.split('##')[1]
        message = message.split('##')[0]
        break
      case 7:
        finalLinkId = `/vaccination/realisation/add-preparation/${message.split('##')[1]}/${
          message.split('##')[2]
        }`
        message = message.split('##')[0]
        linkId = finalLinkId
        break
      case 8:
        finalLinkId = `/vaccination/realisation/remove-preparation/${message.split('##')[1]}/${
          message.split('##')[3]
        }/${message.split('##')[2]}`
        message = message.split('##')[0]
        linkId = finalLinkId
        break
      default:
    }

    this.setState({ linkId, message })
  }

  handleDelete(uuid) {
    const { apiToken, intl } = this.props

    Swal.fire({
      title: intl.formatMessage({ id: 'app.alert.areYouSureTitle' }),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({ id: 'app.label.closeAlert' }),
      customClass: {
        icon: 'swalErrorIcon',
        title: 'swalTitle',
        content: 'swalContentText',
        confirmButton: 'redBtn',
        cancelButton: 'blueBtn'
      }
    }).then(result => {
      if (result.value) {
        Axios.delete(`${api}/api/alert/${uuid}`, {
          headers: {
            Authorization: apiToken
          }
        })
          .then(() => {
            validatedAction(intl.formatMessage({ id: 'app.message.closedAlert' }))
          })
          .catch(err => catchError(err.response))
      }
    })
  }

  render() {
    const { intl } = this.props
    const { alert, button, color, icon, loading, message } = this.state

    if (loading === true) {
      return <LoadingPage />
    }

    return (
      <div className="alertSingle">
        <div className="card alertItem">
          <div
            className="col s1 right close"
            role="button"
            tabIndex="0"
            onKeyPress={() => closePage(intl, false)}
            onClick={() => closePage(intl, false)}
          >
            <i className="material-icons">close</i>
          </div>
          <div className="row alertHeader">
            <img src={icon} alt="alert icon" className="col s2 icon" />
            <div className="col s9 alertContainer">
              <div className={`title title-${color}`}>
                {`${intl.formatMessage({
                  id: `app.alertCategory.${alert.category}`
                })} ${alert.equipmentId ? alert.equipmentId : ''}`}
              </div>
            </div>
          </div>
          <div className="row alertContent">
            <span className="createdAt">
              {`${intl.formatMessage({
                id: 'app.message.alertCreatedAt'
              })} ${moment(alert.createdAt).format('DD-MM-YYYY - HH:mm')} ${intl.formatMessage({
                id: 'app.word.by'
              })} ${alert.byUsername}`}
            </span>
            <div className="message">{message}</div>
          </div>
          {button}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    apiToken: state.auth.token,
    role: state.auth.role
  }
}

export default withRouter(injectIntl(connect(mapStateToProps)(AlertsListPage)))
