import { FormattedMessage } from 'react-intl'
import React from 'react'
import moment from 'moment'
import HeadSingleHistoryStock from './HeadSingleHistoryStock'
import FridgeBlock from './FridgeBlock'

const DiluentsDestock = props => {
  const { history, operator, date, type } = props
  const { action: data } = history
  const { diluentsBatch, fridge } = data

  return (
    <div className="Container stockHistory">
      <HeadSingleHistoryStock operator={operator} date={date} action={type}>
        <div className="row historyCard">
          <div className="col s12 card">
            <div className="row">
              <div className="col s12">
                <FridgeBlock fridge={fridge} />
                <div className="row">
                  <h5>
                    <FormattedMessage id="app.label.diluentDestock" />
                  </h5>
                </div>
                <div className="col s12 row" key={diluentsBatch.batchNumber}>
                  <div className="row">
                    <div className="col s4">
                      <p>
                        <span className="label">
                          <FormattedMessage id="app.label.batchesNumber" />
                          &nbsp;:&nbsp;
                        </span>
                        {diluentsBatch.batchNumber}
                      </p>
                    </div>
                    <div className="col s4">
                      <p>
                        <span className="label">
                          <FormattedMessage id="app.label.DiluentsNumber" />
                          &nbsp;:&nbsp;
                        </span>
                        {data.toOutStock}
                      </p>
                    </div>
                    <div className="col s4">
                      <p>
                        <span className="label">
                          <FormattedMessage id="app.label.expirationDate" />
                          &nbsp;:&nbsp;
                        </span>
                        {moment(diluentsBatch.expirationDate).format('DD/MM/YYYY')}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s4">
                      <p>
                        <span className="label">
                          <FormattedMessage id="app.label.provider" />
                          &nbsp;:&nbsp;
                        </span>
                        {diluentsBatch.provider}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s4">
                      <p>
                        <span className="label">
                          <FormattedMessage id="app.label.totalRemainingForFridgeDiluentBatch" />
                          &nbsp;:&nbsp;
                        </span>
                        {diluentsBatch.newStorageUnits}
                      </p>
                    </div>
                    <div className="col s4">
                      <p>
                        <span className="label">
                          <FormattedMessage id="app.label.totalRemainingForDiluentBatch" />
                          &nbsp;:&nbsp;
                        </span>
                        {diluentsBatch.newTotalUnits}
                      </p>
                    </div>
                  </div>
                  {data.reason && (
                    <div className="row">
                      <div className="col s12">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.reason" />
                            &nbsp;:&nbsp;
                          </span>
                          {data.reason}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </HeadSingleHistoryStock>
    </div>
  )
}

export default DiluentsDestock
