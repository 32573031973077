import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { catchError } from '../../../actions'
import { api } from '../../../parameters'
import ValenceContext from '../../../context/ValencesContext'

class VaccineForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      result: {},
      valence: ''
    }
    this.handleVaccineChange = this.handleVaccineChange.bind(this)
    this.handleSpecialityChange = this.handleSpecialityChange.bind(this)
    this.handleDoseTypeChange = this.handleDoseTypeChange.bind(this)
    this.setNewsSpecialitiesByValence = this.setNewsSpecialitiesByValence.bind(this)
  }

  componentDidMount() {
    M.AutoInit()
  }

  setNewsSpecialitiesByValence(id) {
    const { speciality, token } = this.props

    Axios.get(`${api}/api/vaccine_specialities/${id}`, { headers: { Authorization: token } })
      .then(res => {
        const { valence } = res.data

        Axios.get(
          `${api}/api/vaccine_specialities?administrationMethod=${speciality}&valence=${valence}`,
          {
            headers: { Authorization: token }
          }
        )
          .then(res2 => {
            this.setState({ specialities: res2.data['hydra:member'] }, () => M.AutoInit())
          })
          .catch(err => catchError(err.response))

        this.setState({ valence }, () => M.AutoInit())
      })
      .catch(err => catchError(err.response))
  }

  static contextType = ValenceContext

  handleVaccineChange(e) {
    const { index, setDataToParent, vaccinesByAdminMethod } = this.props
    const { result } = this.state
    let { value } = e.target

    value = parseInt(value)
    let spec

    vaccinesByAdminMethod.forEach(vaccine => {
      if (value === vaccine.id) {
        spec = vaccine.speciality.id
      }
    })

    const parseSpec = parseInt(spec)
    result.vaccineToEditId = value

    setDataToParent(result, index)
    this.setNewsSpecialitiesByValence(parseSpec)

    M.AutoInit()
  }

  handleSpecialityChange(e) {
    const { value } = e.target
    const { index, setDataToParent } = this.props
    const { result } = this.state
    result.newSpeciality = parseInt(value)

    setDataToParent(result, index)
  }

  handleDoseTypeChange(e) {
    const { value } = e.target
    const { index, setDataToParent } = this.props
    const { result } = this.state
    result.newDoseType = parseInt(value)

    setDataToParent(result, index)
    this.setState({ newDoseType: parseInt(value) })
  }

  render() {
    const { vaccine, intl, vaccinesByAdminMethod } = this.props
    const { newDoseType, result, specialities, valence } = this.state
    const { getValenceLabelByKey } = this.context

    return (
      <div key={vaccine.speciality} className="form col s12 noPadding">
        <div className="col s12">
          <div className="row paddingTop20">
            <div className="col s12">
              <span className="labelCustom ">
                {intl.formatMessage({ id: 'app.label.chooseVaccine' })}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="divider greyDivider" />
          </div>
          <div className="row">
            <div className="col s12">
              <div className="input col s6">
                <label htmlFor="speciality" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.selectVaccine' })}
                </label>
                <select
                  className="select-wrapper"
                  name="speciality"
                  defaultValue={vaccine && vaccine.speciality ? vaccine.speciality : '0'}
                  onChange={this.handleVaccineChange}
                  required
                >
                  <option value="0" disabled>
                    {intl.formatMessage({ id: 'app.label.selectVaccine' })}
                  </option>
                  {vaccinesByAdminMethod &&
                    vaccinesByAdminMethod.map(vac => (
                      <option value={vac.id}>
                        {vac.speciality.name}{' '}
                        {intl.formatMessage({
                          id: `app.label.doses.${vac.doseType}`
                        })}
                      </option>
                    ))}
                </select>
              </div>
              <div className="input col s3">
                <label htmlFor="noBlockValueMin" className="labelParams">
                  {intl.formatMessage({
                    id: 'app.label.valence'
                  })}
                </label>
                <input type="text" disabled value={getValenceLabelByKey(valence)} />
              </div>
            </div>
          </div>
          {result.vaccineToEditId && (
            <>
              <div className="row">
                <div className="divider greyDivider" />
              </div>
              <div className="row">
                <div className="col s12">
                  <div className="input col s6">
                    <label htmlFor="newSpeciality" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.chooseNewSpeciality' })}
                    </label>
                    <select
                      className="select-wrapper"
                      name="newSpeciality"
                      defaultValue={
                        vaccine && vaccine.newSpeciality ? vaccine.newSpeciality : 'select'
                      }
                      onChange={this.handleSpecialityChange}
                      required
                    >
                      <option value="select" disabled>
                        {intl.formatMessage({ id: 'app.label.selectSpeciality' })}
                      </option>
                      {specialities &&
                        specialities.map(speciality => (
                          <option key={speciality.id} value={speciality.id}>
                            {speciality.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="input col s6">
                    <label htmlFor="doseType" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.selectDose' })}
                    </label>
                    <select
                      className="select-wrapper"
                      name="doseType"
                      defaultValue={newDoseType || '0'}
                      onChange={this.handleDoseTypeChange}
                      required
                    >
                      <option value="0" disabled>
                        {intl.formatMessage({ id: 'app.label.selectDose' })}
                      </option>
                      <option value="1">{intl.formatMessage({ id: 'app.label.doses.1' })}</option>
                      <option value="2">{intl.formatMessage({ id: 'app.label.doses.2' })}</option>
                    </select>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default withRouter(injectIntl(connect(mapStateToProps)(VaccineForm)))
