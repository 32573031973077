import React from 'react'
import { injectIntl } from 'react-intl'
import { doses } from '../../../../../../parameters'

class VaccineForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    const { name, value } = e.target
    const { getDataVaccineFromChild, index } = this.props

    if (name === 'speciality') {
      const valueToSend = `/api/vaccine_specialities/${value}`
      getDataVaccineFromChild(name, valueToSend, index)
    } else if (name === 'doseType') {
      getDataVaccineFromChild(name, value, index)
    }
  }

  render() {
    const { index, orderIndex, orders, specialities, intl, removeOrder } = this.props

    return (
      <div className="row flex">
        <div className="input col s6">
          <label htmlFor="speciality" className="labelParams">
            {intl.formatMessage({ id: 'app.label.speciality' })}*
          </label>
          <select
            className="select-wrapper"
            name="speciality"
            defaultValue="0"
            onChange={this.handleChange}
            disabled={!orders[orderIndex].administration}
            required
          >
            <option value="0" disabled>
              {intl.formatMessage({ id: 'app.label.selectSpeciality' })}
            </option>
            {specialities &&
              specialities.map(speciality => {
                if (
                  speciality.administrationMethod === parseInt(orders[orderIndex].administration)
                ) {
                  return (
                    <option key={speciality.id} value={speciality.id}>
                      {speciality.name}
                    </option>
                  )
                }
                return null
              })}
          </select>
        </div>
        <div className="input col s5">
          <label htmlFor="doseType" className="labelParams">
            {intl.formatMessage({ id: 'app.label.doses' })}*
          </label>
          <select
            className="select-wrapper"
            name="doseType"
            defaultValue="0"
            onChange={this.handleChange}
            disabled={!orders[orderIndex].administration}
            required
          >
            <option value="0" disabled>
              {intl.formatMessage({ id: 'app.label.selectDose' })}
            </option>
            {doses.map(dose => (
              <option key={dose.id} value={dose.id}>
                {intl.formatMessage({ id: `app.label.doses.${dose.id}` })}
              </option>
            ))}
          </select>
        </div>
        <div className="col s1">
          <div
            role="button"
            className="pointer"
            tabIndex="0"
            onKeyPress={() => removeOrder(index)}
            onClick={() => removeOrder(index)}
          >
            <i className="material-icons">close</i>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(VaccineForm)
