import React from 'react'
import { injectIntl } from 'react-intl'
import StatePill from '../../../StatePill'

const ArticleExpDate = props => {
  const { article, intl } = props

  return (
    <div className="noPadding">
      {article.blockingExpirationAlert !== null &&
      article.nonBlockingExpirationAlert !== null &&
      article.nonBlockingExpirationAlert.value !== '' &&
      article.blockingExpirationAlert.value !== '' ? (
        <span className="noPadding">
          <StatePill
            type="articleExpDateNonBlocking"
            data={article.nonBlockingExpirationAlert && article.nonBlockingExpirationAlert.value}
          />
          <span>
            {`${article.nonBlockingExpirationAlert &&
              article.nonBlockingExpirationAlert.value} ${intl.formatMessage({
              id: 'app.label.days'
            })}`}
          </span>
          &nbsp;
          <StatePill
            type="articleExpDateBlocking"
            data={article.blockingExpirationAlert && article.blockingExpirationAlert.value}
          />
          <span>
            {`${article.blockingExpirationAlert &&
              article.blockingExpirationAlert.value} ${intl.formatMessage({
              id: 'app.label.days'
            })}`}
          </span>
        </span>
      ) : (
        <div className="noPadding blue-text">Ajouter</div>
      )}
    </div>
  )
}

export default injectIntl(ArticleExpDate)
