import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import Axios from 'axios'
import moment from 'moment'
import M from 'materialize-css'
import { catchError } from '../../../../../../../../actions'
import { api } from '../../../../../../../../parameters'
import LoadingPage from '../../../../../../../LoadingPage'

class PBagBatchSelector extends React.Component {
  constructor() {
    super()

    this.state = {
      loading: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const { batches } = this.props
    this.setState({ resBatch: batches[0] })

    M.AutoInit()
  }

  componentDidUpdate(prevProps) {
    const { batches } = this.props

    M.AutoInit()

    if (prevProps.batches !== batches) {
      this.setState({ resBatch: batches[0] })
    }
  }

  handleChange(e) {
    const { token } = this.props
    const { value: id } = e.target

    this.setState({ loading: true }, () =>
      Axios.get(`${api}/api/product_bags/${id}`, { headers: { Authorization: token } })
        .then(res => {
          this.setState({ resBatch: res.data, loading: false })
        })
        .catch(err => catchError(err.response))
    )
  }

  handleSubmit() {
    const {
      neededQuantity,
      selectedBatches,
      i,
      setSelectedBatches,
      setShowVaccines,
      setNeededQuantity
    } = this.props
    const { resBatch } = this.state
    const quantity = neededQuantity <= resBatch.units ? neededQuantity : resBatch.units

    selectedBatches[i] = {
      batch: resBatch,
      quantity,
      disabled: true
    }

    if (neededQuantity > resBatch.units) {
      selectedBatches[i + 1] = {}
    } else {
      setShowVaccines(true)
    }

    setNeededQuantity(neededQuantity - quantity)
    setSelectedBatches(selectedBatches)
  }

  renderBatchId() {
    const { selectedBatches, i } = this.props
    const { resBatch } = this.state

    if (selectedBatches[i].batch !== undefined) {
      return selectedBatches[i].batch.id
    }

    return resBatch.id
  }

  renderBatchNumber() {
    const { selectedBatches, i } = this.props
    const { resBatch } = this.state

    if (selectedBatches[i].batch !== undefined) {
      return selectedBatches[i].batch.batchNumber
    }

    return resBatch.batchNumber
  }

  renderTotalQuantity() {
    const { selectedBatches, i } = this.props
    const { resBatch } = this.state

    if (selectedBatches[i].batch !== undefined) {
      return selectedBatches[i].batch.units
    }

    return resBatch.units
  }

  renderQuantity() {
    const { neededQuantity, selectedBatches, i } = this.props
    const { resBatch } = this.state

    if (selectedBatches[i].batch !== undefined) {
      return selectedBatches[i].quantity
    }

    return neededQuantity <= resBatch.units ? neededQuantity : resBatch.units
  }

  renderExpDate() {
    const { selectedBatches, i } = this.props
    const { resBatch } = this.state

    if (selectedBatches[i].batch !== undefined) {
      return selectedBatches[i].batch.expirationDate
    }

    return resBatch.expirationDate
  }

  render() {
    const { intl, disabled, batches, neededQuantity } = this.props
    const { loading, resBatch } = this.state

    if (loading === true || (batches.length > 0 && resBatch === undefined)) {
      return <LoadingPage />
    }

    if (batches.length === 0 && neededQuantity > 0) {
      return (
        <div className="row card-block blue-text center bold">
          {intl.formatMessage({ id: 'app.message.noProductBagToUse' })}
        </div>
      )
    }

    return (
      <div className="row card-block pbagSelectorRow">
        <div className="input col l3">
          <label htmlFor="batch">{intl.formatMessage({ id: `app.label.batchNumber` })}</label>
          <select
            className="select-wrapper"
            name="batch"
            defaultValue={this.renderBatchId()}
            onChange={this.handleChange}
            disabled={disabled}
          >
            {disabled !== true ? (
              batches.map(batch => (
                <option key={batch.id} value={batch.id}>
                  {batch.batchNumber}
                </option>
              ))
            ) : (
              <option>{this.renderBatchNumber()}</option>
            )}
          </select>
        </div>
        <div className="input col l2">
          <label htmlFor="quantity">{intl.formatMessage({ id: `app.label.totalQuantity` })}</label>
          <input type="number" disabled name="quantity" value={this.renderTotalQuantity()} />
        </div>
        <div className="input col l2">
          <label htmlFor="quantity">{intl.formatMessage({ id: `app.label.neededQuantity` })}</label>
          <input type="number" disabled name="quantity" value={this.renderQuantity()} />
        </div>
        <div className="col l2 input">
          <label htmlFor="datepicker">
            <span>{intl.formatMessage({ id: 'app.label.expirationDate' })}</span>
          </label>
          <input
            type="text"
            name="datepicker"
            className="datepicker"
            value={moment(this.renderExpDate()).format('DD/MM/YYYY')}
            disabled
          />
        </div>
        <div className="col l3">
          {disabled !== true && (
            <button
              type="button"
              className="btn blueBtn"
              onClick={this.handleSubmit}
              disabled={disabled}
            >
              {intl.formatMessage({ id: 'app.button.select' })}
            </button>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(PBagBatchSelector))
