import React from 'react'
import Axios from 'axios'
import { injectIntl } from 'react-intl'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import FridgeHistory from '../../../components/EquipmentManagement/History/SingleHistory/FridgeCD'
import VaccineTransfer from '../../../components/EquipmentManagement/History/SingleHistory/VaccineTransferHistory'
import ContainerControl from '../../../components/EquipmentManagement/History/SingleHistory/ContainerControl'
import FridgeControl from '../../../components/EquipmentManagement/History/SingleHistory/FridgeControl'
import InjectionControl from '../../../components/EquipmentManagement/History/SingleHistory/InjectionControl'
import SprayControl from '../../../components/EquipmentManagement/History/SingleHistory/SprayControl'
import ContainerUseEnd from '../../../components/EquipmentManagement/History/SingleHistory/ContainerUseEnd'
import NitrogenTransfer from '../../../components/EquipmentManagement/History/SingleHistory/NitrogenTransfer'
import ProductsTransfer from '../../../components/EquipmentManagement/History/SingleHistory/ProductsTransfer'
import InactivateFridge from '../../../components/EquipmentManagement/History/SingleHistory/InactivateFridge'
import BathControl from '../../../components/EquipmentManagement/History/SingleHistory/BathControl'

class SingleHistory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    const { token, match } = this.props
    const { id } = match.params

    Axios.get(`${api}/api/equipment_histories/${id}`, {
      headers: { Authorization: token }
    })
      .then(res => this.setState({ history: res.data, loading: false }))
      .catch(err => catchError(err.response))
  }

  render() {
    const { history, loading } = this.state
    const { intl } = this.props

    if (loading === true) {
      return <LoadingPage />
    }

    const { type: actionType, createdAt, firstName, history: historyDetail } = history

    switch (actionType) {
      case 0:
        return (
          <ContainerControl
            history={historyDetail}
            operator={firstName}
            date={createdAt}
            action={actionType}
            intl={intl}
          />
        )
      case 1:
        return (
          <FridgeControl
            history={historyDetail}
            operator={firstName}
            date={createdAt}
            action={actionType}
            intl={intl}
          />
        )
      case 2:
        return (
          <InjectionControl
            history={historyDetail}
            operator={firstName}
            date={createdAt}
            action={actionType}
            intl={intl}
          />
        )
      case 3:
        return (
          <SprayControl
            history={historyDetail}
            operator={firstName}
            date={createdAt}
            action={actionType}
            intl={intl}
          />
        )
      case 4:
      case 5:
        return (
          <ContainerUseEnd
            history={historyDetail}
            operator={firstName}
            date={createdAt}
            action={actionType}
            intl={intl}
          />
        )
      case 6:
        return (
          <VaccineTransfer
            history={historyDetail}
            operator={firstName}
            date={createdAt}
            action={actionType}
            intl={intl}
          />
        )
      case 7:
        return (
          <NitrogenTransfer
            history={historyDetail}
            operator={firstName}
            date={createdAt}
            action={actionType}
            intl={intl}
          />
        )
      case 8:
      case 9:
      case 10:
      case 11:
        return (
          <ProductsTransfer
            history={historyDetail}
            operator={firstName}
            date={createdAt}
            action={actionType}
            intl={intl}
          />
        )
      case 12:
        return (
          <FridgeHistory
            history={historyDetail}
            operator={firstName}
            date={createdAt}
            action={actionType}
            intl={intl}
          />
        )
      case 13:
        return (
          <InactivateFridge
            history={historyDetail}
            operator={firstName}
            date={createdAt}
            action={actionType}
            intl={intl}
          />
        )
      case 14:
        return (
          <BathControl
            history={historyDetail}
            operator={firstName}
            date={createdAt}
            action={actionType}
            intl={intl}
          />
        )
      default:
        return (
          <div>
            <LoadingPage />
          </div>
        )
    }
  }
}

export default injectIntl(SingleHistory)
