import React from 'react'
import { injectIntl } from 'react-intl'

const OrderInfosBlock = props => {
  const { group, intl, headerText, total } = props

  return (
    <div className="row custom-card">
      <div className="card-header">
        <div className="col s6 em1-2">
          <span className="bold">
            {intl.formatMessage({ id: 'app.label.order' }).toUpperCase()} {group.number}
          </span>
        </div>
        <div className="col s6">
          <span className="bold">{intl.formatMessage({ id: `app.label.${headerText}` })}</span>
        </div>
      </div>
      <div className="card-content">
        <div className="card-block row">
          <div className="col s6">
            <span className="labelCustom">
              {total} {intl.formatMessage({ id: 'app.label.chicks' })}
            </span>
          </div>
          <div className="col s6">
            <span className="labelCustom">
              {group.boxesNumber} {intl.formatMessage({ id: 'app.label.boxes' })}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(OrderInfosBlock)
