import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { injectIntl } from 'react-intl'
import history from '../../../history'
import Planning from '../../../components/Planning/Planning'

class Preparation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      vaccinesType: [0, 1, 2]
    }

    this.getDataFromChild = this.getDataFromChild.bind(this)
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  render() {
    const { intl, token, role } = this.props
    const { vaccinesType } = this.state

    return (
      <div className="row Container stockAvailibilityItem">
        <div className="col s12 content">
          <div className="row pageHeader pageTitle itemHeader">
            <div
              onClick={() => history.goBack()}
              role="button"
              tabIndex="0"
              onKeyPress={() => history.goBack()}
              className="goBackBtn col s1"
            >
              <i className="material-icons">chevron_left</i>
            </div>
            <h5 className="col s12">{intl.formatMessage({ id: 'app.part.preparation' })}</h5>
          </div>
          <Planning
            token={token}
            role={role}
            getDataFromChild={this.getDataFromChild}
            isPreparation
            vaccinesType={vaccinesType}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(Preparation)))
