import moment from 'moment'
import React from 'react'

const StatePill = props => {
  const { type, data, minStock, pillState, specialityData } = props
  const renderStatePill = (
    state = null,
    congruent = false,
    nonBlocking = false,
    blocking = false,
    optionalClass = null
  ) => {
    let stateClass = 'state-0'

    if (state !== null) stateClass = `state-${state}`
    if (congruent === true) stateClass = 'congruent'
    if (nonBlocking === true) stateClass = 'non-blocking'
    if (blocking === true) stateClass = 'blocking'

    return (
      <i className={`material-icons pills ${stateClass} noPadding ${optionalClass}`}>
        brightness_1
      </i>
    )
  }

  let resData = data

  if (type === 'ExpirationDate') {
    if (data.resData !== undefined) {
      resData = data.dyeBatch
    } else if (data.resData !== undefined) {
      resData = data.diluentBatch
    }
  }

  switch (type) {
    case 'FridgeMinTemperature':
      return renderStatePill(data.lastControl.minTemperatureState)
    case 'FridgeMaxTemperature':
      return renderStatePill(data.lastControl.maxTemperatureState)
    case 'FridgeInstantTemperature':
      return renderStatePill(data.lastControl.instantTemperatureState)
    case 'ContainerDate':
    case 'FridgeDate':
      return moment(data.lastControl.createdAt).isAfter(moment().format('L'))
        ? renderStatePill(null, true)
        : renderStatePill(null, false, false, true)
    case 'ContainerNitrogenLevel':
      return renderStatePill(data.lastControl.nitrogenLevelState)
    case 'Parameter':
      return renderStatePill(data)
    case 'ExpirationDate':
      if (resData.expDateState === 0) {
        return renderStatePill(true)
      }

      if (resData.expDateState === 3) {
        return renderStatePill(null, false, false, true)
      }

      if (resData.expDateState === 2) {
        return renderStatePill(null, false, true)
      }

      return renderStatePill(null, true)
    case 'articleExpDateBlocking':
      return renderStatePill(null, null, null, true)
    case 'articleExpDateNonBlocking':
      return renderStatePill(null, null, true)
    case 'doses':
      if (specialityData) {
        const { minimalStockAlert } = specialityData
        if (!minimalStockAlert) {
          return renderStatePill(null, true)
        }
        if (parseInt(minimalStockAlert.value) <= data) {
          return renderStatePill(null, true)
        }

        return renderStatePill(null, false, true)
      }
      return renderStatePill(null, true)
    case 'conformityTitleParams':
      return renderStatePill(null, true, false, false, 'pillSizeTitle')
    case 'noBlockTitleParams':
      return renderStatePill(null, false, false, false, 'pillSizeTitle orangeTitle')
    case 'blockTitleParams':
      return renderStatePill(null, false, false, false, 'pillSizeTitle redTitle')
    case 'unitsProductBag':
      if (minStock <= data.units) {
        return renderStatePill(null, true)
      }
      return renderStatePill(null, false, true)
    case 'units':
      if (minStock <= data.vialsNumber) {
        return renderStatePill(null, true)
      }
      return renderStatePill(null, false, true)
    case 'waterBathControl':
      return renderStatePill(data.history.action.state)
    case 'report':
      if (pillState === 0) {
        return renderStatePill(null, true)
      }

      return renderStatePill(null, false, true)
    default:
      return <div>Default</div>
  }
}

export default StatePill
