import React from 'react'
import OrderChickInfoBlock from '../../../partials/OrderChickInfoBlock'
import ProductBagsBlock from './partials/ProductBagsBlock/ProductBagsBlock'

class ProductBagsPart extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  getGroup() {
    const { preparation } = this.props
    const { injectionGroup, bigDropsGroup, thinDropsGroup } = preparation.planningOrder

    if (injectionGroup !== null) {
      return injectionGroup
    }

    if (bigDropsGroup !== null) {
      return bigDropsGroup
    }

    if (thinDropsGroup !== null) {
      return thinDropsGroup
    }

    return null
  }

  render() {
    const {
      actualPart,
      changePagePart,
      preparation,
      partsTotal,
      previousPreparation,
      prepId
    } = this.props
    return (
      <div>
        <OrderChickInfoBlock
          preparation={preparation}
          textHeader2={preparation.clientName}
          group={this.getGroup()}
        />
        <ProductBagsBlock
          prepId={prepId}
          actualPart={actualPart}
          partsTotal={partsTotal}
          preparation={preparation}
          previousPreparation={previousPreparation}
          changePagePart={changePagePart}
        />
      </div>
    )
  }
}

export default ProductBagsPart
