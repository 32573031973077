import React from 'react'
import { injectIntl } from 'react-intl'
import { messageInfo } from '../../../../../../../globalAction'

class InjectableVaccineRadio extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    const { value } = e.target
    const { getDataFromChild, intl } = this.props

    const parseIntValue = parseInt(value)

    let message

    switch (parseIntValue) {
      case 1:
      case 2:
      case 6:
        message = intl.formatMessage({ id: 'app.message.vaccineHasNotBeenStroredCorrectly' })
        break
      case 7:
        message = intl.formatMessage({ id: 'app.message.vaccineSelectedIsNotTheGood' })
        break
      default:
        message = intl.formatMessage({ id: 'app.message.vaccineCantBeUsed' })
        break
    }

    messageInfo(message, 'none')

    getDataFromChild('type', parseIntValue)
  }

  render() {
    const { intl } = this.props
    return (
      <div className="card-content">
        <div className="card-block">
          <div className="row">
            <label className="col s6" htmlFor="type2">
              <input
                id="type2"
                className="checkbox"
                name="type"
                value={2}
                type="radio"
                onChange={this.handleChange}
              />
              <span className="labelCustom">
                {intl.formatMessage({ id: 'app.label.alreadyThawedBulb' })}
              </span>
            </label>
            <label className="col s6" htmlFor="type3">
              <input
                id="type3"
                name="type"
                className="checkbox"
                value={3}
                type="radio"
                onChange={this.handleChange}
              />
              <span className="labelCustom">
                {intl.formatMessage({ id: 'app.label.defrostTimeHigherThan1m30' })}
              </span>
            </label>
          </div>
          <div className="row">
            <label className="col s6" htmlFor="type4">
              <input
                id="type4"
                name="type"
                className="checkbox"
                value={4}
                type="radio"
                onChange={this.handleChange}
              />
              <span className="labelCustom">
                {intl.formatMessage({ id: 'app.label.brokenBulb' })}
              </span>
            </label>
            <label className="col s6" htmlFor="type5">
              <input
                id="type5"
                name="type"
                className="checkbox"
                value={5}
                type="radio"
                onChange={this.handleChange}
              />
              <span className="labelCustom">
                {intl.formatMessage({ id: 'app.label.reversedBulb' })}
              </span>
            </label>
          </div>
          <div className="row">
            <label className="col s6" htmlFor="type6">
              <input
                id="type6"
                name="type"
                className="checkbox"
                value={6}
                type="radio"
                onChange={this.handleChange}
              />
              <span className="labelCustom">
                {intl.formatMessage({ id: 'app.label.bulbBadConservation' })}
              </span>
            </label>
            <label className="col s6" htmlFor="type7">
              <input
                id="type7"
                name="type"
                className="checkbox"
                value={7}
                type="radio"
                onChange={this.handleChange}
              />
              <span className="labelCustom">
                {intl.formatMessage({ id: 'app.label.differentVaccine' })}
              </span>
            </label>
          </div>
          <div className="row">
            <label className="col s6" htmlFor="type8">
              <input
                id="type8"
                name="type"
                className="checkbox"
                value={8}
                type="radio"
                onChange={this.handleChange}
              />
              <span className="labelCustom">
                {intl.formatMessage({ id: 'app.label.errorChoosingBulb/BatchNumber' })}
              </span>
            </label>
            <label className="col s6" htmlFor="type9">
              <input
                id="type9"
                name="type"
                className="checkbox"
                value={9}
                type="radio"
                onChange={this.handleChange}
              />
              <span className="labelCustom">
                {intl.formatMessage({
                  id: 'app.label.errorChoosingBulb/NotTheRightConditioning'
                })}
              </span>
            </label>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(InjectableVaccineRadio)
