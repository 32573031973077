import React from 'react'
import { injectIntl } from 'react-intl'
import TextArea from '../../partials/TextArea'
import NoBlockHistoryPart from '../NoBlockHistoryPart/NoBlockHistoryPart'

const NoBlockStockPart = props => {
  const {
    history,
    specialityData,
    intl,
    stockId,
    action,
    noBlockUnitsStock,
    noBlockPartAlertStock,
    handleChange
  } = props

  if (!history) {
    const { minimalStockAlert } = specialityData
    if (minimalStockAlert) {
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="input col s6">
                <label htmlFor="noBlockUnitsStock" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.minStock' })}
                </label>
                <input
                  name="noBlockUnitsStock"
                  type="number"
                  value={noBlockUnitsStock || minimalStockAlert.value}
                  onChange={handleChange}
                />
              </div>
            </div>
            <TextArea
              name="noBlockPartAlertStock"
              value1={noBlockPartAlertStock}
              value2={minimalStockAlert.message}
              handleChange={handleChange}
            />
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    }
  }
  const { changes } = action
  const { minimalStockAlert } = changes
  return <NoBlockHistoryPart minimalStockAlert={minimalStockAlert} stockId={stockId} />
}

export default injectIntl(NoBlockStockPart)
