import moment from 'moment'
import React, { useContext } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import HeadSingleHistoryStock from './HeadSingleHistoryStock'
import ValenceContext from '../../../../context/ValencesContext'

const FrozenVaccinesDestock = props => {
  const { history, operator, date, intl, type } = props
  const { action: data } = history
  const { vaccinesBatch, nitrogenContainer } = data
  const { getValenceLabelByKey } = useContext(ValenceContext)
  return (
    <div className="Container stockHistory">
      <HeadSingleHistoryStock operator={operator} date={date} action={type}>
        <div className="row historyCard">
          <div className="col s12 card">
            <div className="row">
              <div className="col s12">
                <div className="row">
                  <h5>
                    <FormattedMessage id="app.label.container" />
                  </h5>
                </div>
                <div className="row">
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.containerId" />
                      </span>
                      {nitrogenContainer.containerNumber}
                    </p>
                  </div>
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.container" />
                        :&nbsp;
                      </span>
                      <FormattedMessage
                        id={`app.equipment.containerType.${nitrogenContainer.containerType}`}
                      />
                    </p>
                  </div>
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.provider" />
                        &nbsp;:&nbsp;
                      </span>
                      {nitrogenContainer.containerProvider}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.containerCapacity" />
                      </span>
                      :&nbsp;
                      {`${nitrogenContainer.containerCapacity} ${intl.formatMessage({
                        id: 'app.unit.containerCapacity'
                      })}`}
                    </p>
                  </div>
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.prevNitrogenLevel" />
                        &nbsp;:&nbsp;
                      </span>
                      {`${nitrogenContainer.prevNitrogenLevel} ${intl.formatMessage({
                        id: 'app.unit.nitrogenLevel'
                      })}`}
                    </p>
                  </div>
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.newNitrogenLevel" />
                        :&nbsp;
                      </span>
                      {`${nitrogenContainer.newNitrogenLevel} ${intl.formatMessage({
                        id: 'app.unit.nitrogenLevel'
                      })}`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col s12">
                <div className="row">
                  <h5>
                    <FormattedMessage id="app.label.vaccineDestock" />
                  </h5>
                </div>
                <div className="row">
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.batchesNumber" />
                        &nbsp;:&nbsp;
                      </span>
                      {vaccinesBatch.batchNumber}
                    </p>
                  </div>
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.vaccinesNumber" />
                        &nbsp;:&nbsp;
                      </span>
                      {data.toOutStock}
                    </p>
                  </div>
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.expirationDate" />
                        &nbsp;:&nbsp;
                      </span>
                      {moment(vaccinesBatch.expirationDate).format('DD/MM/YYYY')}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.speciality" />
                        &nbsp;:&nbsp;
                      </span>
                      {vaccinesBatch.speciality}
                    </p>
                  </div>
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.article" />
                        &nbsp;:&nbsp;
                      </span>
                      {vaccinesBatch.article}
                    </p>
                  </div>
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.valence" />
                        &nbsp;:&nbsp;
                      </span>
                      {getValenceLabelByKey(vaccinesBatch.valence)}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.conservationMethod" />
                        &nbsp;:&nbsp;
                      </span>
                      <FormattedMessage
                        id={`app.label.conservationMethod.${vaccinesBatch.conservationMethod}`}
                      />
                    </p>
                  </div>
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.bulbsNumber" />
                        {' : '}
                      </span>
                      {vaccinesBatch.newStorageUnits}
                    </p>
                  </div>
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.dosesNumberRest" />
                        &nbsp;:&nbsp;
                      </span>
                      {vaccinesBatch.newStorageDoses}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.reason" />
                        &nbsp;:&nbsp;
                      </span>
                      {data.reason}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HeadSingleHistoryStock>
    </div>
  )
}

export default injectIntl(FrozenVaccinesDestock)
