import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import HatcheryContext from '../../../../../../context/HatcheryContext'

class ChickNumberBlock extends React.Component {
  constructor() {
    super()
    this.state = {
      hiddenBoxPart: true
    }
    this.handleNumberChange = this.handleNumberChange.bind(this)
  }

  componentDidMount() {
    const { hatchery } = this.context
    this.setData(hatchery.chicksPerBox)

    M.AutoInit()
  }

  setData(chicksNbPerBox) {
    const { chicksNb, data, setStateToParent } = this.props
    const rest = chicksNb % chicksNbPerBox
    const boxNb = (chicksNb - rest) / chicksNbPerBox
    const restBoxNb = rest === 0 ? 0 : 1
    data.chicksPerBox = chicksNbPerBox
    data.boxesNumber = rest === 0 ? boxNb : boxNb + 1
    let hiddenBoxPart = false

    if (Number.isNaN(chicksNbPerBox)) {
      hiddenBoxPart = true
    }

    this.setState({
      boxNb,
      chicksNbPerBox,
      hiddenBoxPart,
      restBoxNb,
      roundRest: rest
    })
    setStateToParent(hiddenBoxPart, data)
  }

  static contextType = HatcheryContext

  handleNumberChange(e) {
    const { value } = e.target

    this.setData(parseInt(value))
  }

  render() {
    const { chicksNb, intl } = this.props
    const { boxNb, chicksNbPerBox, hiddenBoxPart, roundRest, restBoxNb } = this.state

    return (
      <>
        <div className="row grey-text">
          {chicksNb} {intl.formatMessage({ id: 'app.label.chicksToVaccinate' })}
        </div>
        <div className="row custom-card form">
          <div className="card-content">
            <div className="card-block col s6 input">
              <label htmlFor="chicksPerBox">
                {intl.formatMessage({ id: 'app.label.chicksNumberPerBox' })}
              </label>
              <input type="number" value={chicksNbPerBox} onChange={this.handleNumberChange} />
            </div>
            {hiddenBoxPart === false && (
              <>
                <div className="divider greyDivider" />
                <div className="card-block col s6 input">
                  <div className="bold labelCustom">
                    {boxNb} {intl.formatMessage({ id: 'app.label.boxesOf' })} {chicksNbPerBox}{' '}
                    {intl.formatMessage({ id: 'app.label.chicks' })}
                  </div>
                  {roundRest !== 0 && (
                    <div className="bold labelCustom">
                      {restBoxNb} {intl.formatMessage({ id: 'app.label.boxOf' })} {roundRest}{' '}
                      {intl.formatMessage({ id: 'app.label.chicks' })}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    )
  }
}

export default injectIntl(ChickNumberBlock)
