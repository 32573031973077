import React from 'react'
import icons from '../../../../../assets/icons/icons'
import SprayedVaccinesStep from './SprayedVaccinesStep'

class BigDropsVaccinesStep extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    const { changePart, group, total, isAdding, addSubmit, hatchingDate } = this.props

    return (
      <SprayedVaccinesStep
        changePart={changePart}
        group={group}
        headerText="bigDropsVaccines"
        icon={icons.bigDropVaccine}
        order={group.bigDropsOrder}
        vaccineType={2}
        total={total}
        addSubmit={addSubmit}
        isAdding={isAdding}
        hatchingDate={hatchingDate}
      />
    )
  }
}

export default BigDropsVaccinesStep
