import React from 'react'
import { injectIntl } from 'react-intl'

const ProblemTitle = props => {
  const { intl, type } = props
  let title = ''

  switch (type) {
    case 0:
      title = 'app.label.opaqueOrYellowProductBag'
      break
    case 1:
      title = 'app.label.piercedProductBag'
      break
    case 2:
      title = 'app.label.alreadyThawedBulb'
      break
    case 3:
      title = 'app.label.defrostTimeHigherThan1m30'
      break
    case 4:
      title = 'app.label.brokenBulb'
      break
    case 5:
      title = 'app.label.reversedBulb'
      break
    case 6:
      title = 'app.label.bulbBadConservation'
      break
    case 7:
    case 15:
      title = 'app.label.differentVaccine'
      break
    case 8:
      title = 'app.label.errorChoosingBulb/BatchNumber'
      break
    case 9:
      title = 'app.label.errorChoosingBulb/NotTheRightConditioning'
      break
    case 12:
      title = 'app.label.flaskBadConservation'
      break
    case 13:
      title = 'app.label.brokenFlask'
      break
    case 14:
      title = 'app.label.reversedFlask'
      break
    case 16:
      title = 'app.label.errorChoiceFlask/batchNumber'
      break
    case 17:
      title = 'app.label.errorChoiceFlask/badConditionnement'
      break
    default:
  }

  return <div className="pbTitle">{intl.formatMessage({ id: title })}</div>
}
export default injectIntl(ProblemTitle)
