import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { catchError } from '../../actions'
import CenterButton from '../../components/CenterButton'
import BlockChickOrdered from '../../components/DailyReport/AddReport/BlockChickOrdered'
import BlockFlask from '../../components/DailyReport/AddReport/BlockFlask/BlockFlask'
import BlockDiluentFlask from '../../components/DailyReport/AddReport/BlockDiluentFlask/BlockDiluentFlask'
import Header from '../../components/DailyReport/AddReport/Header'
import { validatedAction } from '../../components/globalAction'
import { api } from '../../parameters'

class AddReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dyes: [{}],
      diluents: [{}],
      disabledButtonDiluent: [true],
      disabledButtonDye: [true],
      dataToSend: {}
    }
    this.getDataFromChild = this.getDataFromChild.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  handleSubmit() {
    const { token } = this.props
    const { dyes, diluents, dataToSend } = this.state

    dataToSend.dyes = dyes
    dataToSend.dills = diluents

    Axios.post(`${api}/api/daily_reports`, dataToSend, { headers: { Authorization: token } })
      .then(() => validatedAction())
      .catch(err => catchError(err.response))
  }

  render() {
    const { intl } = this.props
    const { dataToSend, disabledButtonDiluent, disabledButtonDye, dyes, diluents } = this.state

    const disabledValidateButton = false

    return (
      <div className="Container row">
        <div className="col s12">
          <Header />
          <BlockChickOrdered dataToSend={dataToSend} getDataFromChild={this.getDataFromChild} />
          <BlockFlask
            disabledButton={disabledButtonDye}
            dataToSend={dataToSend}
            dyes={dyes}
            getDataFromChild={this.getDataFromChild}
          />
          <BlockDiluentFlask
            disabledButton={disabledButtonDiluent}
            dataToSend={dataToSend}
            diluents={diluents}
            getDataFromChild={this.getDataFromChild}
          />
          <CenterButton
            text={intl.formatMessage({ id: 'app.button.validate' })}
            disabled={disabledValidateButton}
            onClick={this.handleSubmit}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(AddReport))
