import Axios from 'axios'
import { Modal } from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { displayErrorMessage } from '../../actions'
import { api } from '../../parameters'
import CenterButton from '../CenterButton'
import { validatedAction } from '../globalAction'

class ModalToEditOrderNumber extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orderNumber: props.number
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const { id } = this.props
    Modal.init(document.getElementById(`editOrderNumber${id}`))
  }

  handleChange(e) {
    const { name, value } = e.target

    this.setState({ [name]: value })
  }

  handleSubmit() {
    const { orderNumber } = this.state
    const { number, token } = this.props

    Axios.patch(
      `${api}/api/planning_order_groups/${number}/rename`,
      {
        number: orderNumber
      },
      {
        headers: { Authorization: token }
      }
    )
      .then(() => validatedAction('', 0))
      .catch(err => {
        return displayErrorMessage(err.response.data.error.message)
      })
  }

  render() {
    const { intl, id, number } = this.props
    const { orderNumber } = this.state

    return (
      <div>
        <div
          id={`editOrderNumber${id}`}
          className="modal editOrderNumberModal"
          style={{ top: '50%' }}
        >
          <div className="modal-content">
            <div className="row">
              <div className="col s12">
                <h2 className="swal2-title swalTitle">
                  {intl.formatMessage({ id: 'app.label.titleEditOrderNumber' })}&nbsp;
                  {number}
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <form>
                  <div className="form noCard">
                    <div className="row ">
                      <div className="col s12 input">
                        <div className="input">
                          <label htmlFor="orderNumber" className="labelParams">
                            {intl.formatMessage({ id: 'app.label.newOrderNumber' })}
                          </label>
                          <input
                            name="orderNumber"
                            type="text"
                            value={orderNumber !== undefined ? orderNumber : number}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <CenterButton
                      text={intl.formatMessage({ id: 'app.label.save' })}
                      className="btn blueBtn col validationBtn"
                      onClick={this.handleSubmit}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(ModalToEditOrderNumber))
