import React from 'react'
import { injectIntl } from 'react-intl'

const ReportProblemOrderBlockInfo = props => {
  const { intl, preparation } = props
  const { planningOrder } = preparation
  const { vaccines, productBag, bottle } = preparation.preparation
  const { injectionGroup, bigDropsGroup, thinDropsGroup } = planningOrder
  let containerLabel = 'productBag'
  const containerBatchNumber = productBag ? productBag.batchNumber : ''
  let bottleToDisplay

  if (bottle !== null) {
    containerLabel = 'can'
    bottleToDisplay = bottle !== null ? bottle : ''
  }

  const melt = []
  vaccines.forEach(vaccine => {
    melt.push(vaccine.speciality)
  })

  const getCardContent = (
    <div className="card-block row">
      <div className="col s6">{melt.join(' + ')}</div>
      <div className="col s6">
        {intl.formatMessage({ id: `app.label.${containerLabel}` })} {containerBatchNumber}
        {bottleToDisplay}
      </div>
    </div>
  )

  let group

  if (injectionGroup !== null) {
    group = injectionGroup
  } else if (thinDropsGroup !== null) {
    group = thinDropsGroup
  } else {
    group = bigDropsGroup
  }

  const clientNameToDisplay = group.clientName
  const boxesNumberToDisplay = group.boxesNumber
  const { totalChicks, number } = group

  return (
    <div className="row">
      <div className="col s12">
        <div className="custom-card card">
          <div className="card-header">
            <div className="col s6 em1-2">
              <span className="bold">
                {intl.formatMessage({ id: 'app.label.order' }).toUpperCase()} {number}
              </span>
            </div>
            <div className="col s6">
              <span className="bold">{intl.formatMessage({ id: 'app.label.client' })}</span>{' '}
              {clientNameToDisplay}
            </div>
          </div>
          <div className="card-content">
            <div className="card-block row">
              <div className="col s6">
                {totalChicks} {intl.formatMessage({ id: 'app.label.chicks' })}
              </div>
              <div className="col s6">
                {boxesNumberToDisplay} {intl.formatMessage({ id: 'app.label.boxes' })}
              </div>
            </div>
            {getCardContent}
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(ReportProblemOrderBlockInfo)
