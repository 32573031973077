import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { catchError } from '../../../actions'
import roles from '../../../config/userRoles.json'
import { api } from '../../../parameters'
import ValenceContext from '../../../context/ValencesContext'

class IncompatibilityTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      incompatibilities:
        props.incompatibilities === undefined
          ? { blocking: [], nonBlocking: [] }
          : props.incompatibilities
    }
    this.handleBlockChange = this.handleBlockChange.bind(this)
    this.handleNoBlockChange = this.handleNoBlockChange.bind(this)
    this.handleChangeCheck = this.handleChangeCheck.bind(this)
  }

  componentDidMount() {
    const { history, token } = this.props
    if (history !== undefined) {
      Axios.get(`${api}/api/vaccine_specialities`, {
        headers: {
          Authorization: token
        }
      })
        .then(res => {
          this.setState({ specialitiesData: res.data['hydra:member'] })
        })
        .catch(err => catchError(err.response))
    }
  }

  handleBlockChange(e) {
    const { id, name, checked } = e.target
    const { setDataToParent } = this.props
    const { incompatibilities } = this.state

    const parseIntId = parseInt(id)

    if (checked) {
      incompatibilities.blocking.push({ id: parseIntId, name })
      incompatibilities.nonBlocking.forEach((el, i) => {
        if (el.name === name) {
          incompatibilities.nonBlocking.splice(i, 1)
        }
      })
      this.setState({ incompatibilities })
      setDataToParent('incompatibilities', incompatibilities)
    } else {
      incompatibilities.blocking.forEach((el, i) => {
        if (el.name === name) {
          incompatibilities.blocking.splice(i, 1)
        }
      })

      this.setState({ incompatibilities })
      setDataToParent('incompatibilities', incompatibilities)
    }
  }

  handleNoBlockChange(e) {
    const { id, name, checked } = e.target
    const { setDataToParent } = this.props
    const { incompatibilities } = this.state

    const parseIntId = parseInt(id)

    if (checked) {
      incompatibilities.nonBlocking.push({ id: parseIntId, name })
      incompatibilities.blocking.forEach((el, i) => {
        if (el.name === name) {
          incompatibilities.blocking.splice(i, 1)
        }
      })
      this.setState({ incompatibilities })
      setDataToParent('incompatibilities', incompatibilities)
    } else {
      incompatibilities.nonBlocking.forEach((el, i) => {
        if (el.name === name) {
          incompatibilities.nonBlocking.splice(i, 1)
        }
      })
      this.setState({ incompatibilities })
      setDataToParent('incompatibilities', incompatibilities)
    }
  }

  handleChangeCheck(array, object) {
    let res = false

    for (let i = 0; i < array.length; i += 1) {
      if (array[i].id === object.id) {
        res = true
        break
      }
    }

    return res
  }

  static contextType = ValenceContext

  render() {
    const {
      administrationData,
      history,
      incompatibilities,
      intl,
      name,
      role,
      specialities
    } = this.props
    const { specialitiesData } = this.state
    const { ROLE_VET } = roles
    const { getValenceLabelByKey } = this.context

    const specialitiesForIncompatibility = []

    if (history === undefined) {
      if (role.includes(ROLE_VET) && specialities) {
        specialities.forEach((speciality, idx) => {
          if (parseInt(administrationData) === 1 || parseInt(administrationData) === 2) {
            if (
              (speciality.name !== name && speciality.administrationMethod === 1) ||
              speciality.administrationMethod === 2
            ) {
              specialitiesForIncompatibility[idx] = speciality
            }
          } else if (
            speciality.name !== name &&
            speciality.administrationMethod === parseInt(administrationData)
          ) {
            specialitiesForIncompatibility[idx] = speciality
          }
        })
      }
    } else if (specialitiesData) {
      specialitiesData.forEach((speciality, idx) => {
        if (speciality.name !== name) {
          specialitiesForIncompatibility[idx] = speciality
        }
      })
    }

    return (
      <div className="row">
        <div className="col s12">
          <table className="table">
            <thead>
              <tr>
                <th>{intl.formatMessage({ id: 'app.label.speciality' })} </th>
                <th>{intl.formatMessage({ id: 'app.label.sickness' })} </th>
                <th>{intl.formatMessage({ id: 'app.label.blocking' })} </th>
                <th>{intl.formatMessage({ id: 'app.label.noBlocking' })} </th>
              </tr>
            </thead>
            <tbody>
              {specialitiesForIncompatibility &&
                specialitiesForIncompatibility.map(speciality => (
                  <tr key={`speciality-${speciality.id}`}>
                    <td>{speciality.name}</td>
                    <td>{getValenceLabelByKey(speciality.valence)}</td>
                    <td>
                      <label>
                        {history === undefined ? (
                          <input
                            id={speciality.id}
                            name={`${speciality.name}`}
                            type="checkbox"
                            className="filled-in checkbox"
                            checked={
                              incompatibilities &&
                              this.handleChangeCheck(incompatibilities.blocking, {
                                id: speciality.id,
                                name: speciality.name
                              })
                            }
                            onChange={history === undefined && this.handleBlockChange}
                          />
                        ) : (
                          <input
                            id={speciality.id}
                            name={`${speciality.name}`}
                            type="checkbox"
                            className="filled-in checkbox"
                            checked={
                              incompatibilities &&
                              this.handleChangeCheck(incompatibilities.blocking, {
                                id: speciality.id,
                                name: speciality.name
                              })
                            }
                          />
                        )}
                        <span />
                      </label>
                    </td>
                    <td className="input">
                      <label>
                        {history === undefined ? (
                          <input
                            id={speciality.id}
                            name={`${speciality.name}`}
                            type="checkbox"
                            className="filled-in checkbox"
                            checked={
                              incompatibilities &&
                              this.handleChangeCheck(incompatibilities.nonBlocking, {
                                id: speciality.id,
                                name: speciality.name
                              })
                            }
                            onChange={history === undefined && this.handleNoBlockChange}
                          />
                        ) : (
                          <input
                            id={speciality.id}
                            name={`${speciality.name}`}
                            type="checkbox"
                            className="filled-in checkbox"
                            checked={
                              incompatibilities &&
                              this.handleChangeCheck(incompatibilities.nonBlocking, {
                                id: speciality.id,
                                name: speciality.name
                              })
                            }
                          />
                        )}

                        <span />
                      </label>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default injectIntl(connect(mapStateToProps)(IncompatibilityTable))
