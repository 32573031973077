import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { catchError } from '../../../../actions'
import Filters from '../../../../components/Filters/Filters'
import { ExportFile } from '../../../../components/globalAction'
import { renderNitrogenLevel } from '../../../../components/globalRenderActions'
import LoadingPage from '../../../../components/LoadingPage'
import StatePill from '../../../../components/StatePill'
import { api } from '../../../../parameters'

class ContainerList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      containers: [],
      url: `${api}/api/nitrogen_containers`,
      loading: true,
      filters: [
        { category: 'order[id]', sortBy: ['asc', 'desc'] },
        { category: 'type', sortBy: [0, 1, 2], translationPrefix: 'containerType' },
        {
          category: 'order[lastControl.createdAt]',
          sortBy: ['asc', 'desc']
        }
      ]
    }
    this.inLoad = this.inLoad.bind(this)
    this.getStateFromChild = this.getStateFromChild.bind(this)
    this.filterByDate = this.filterByDate.bind(this)
  }

  componentDidMount() {
    const { token } = this.props
    const { url } = this.state

    Axios.get(`${url}?order[containerNumber]=asc`, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({
          loading: false,
          containers: res.data['hydra:member']
        })
      })
      .catch(err => catchError(err))
  }

  getStateFromChild(containers) {
    this.setState({ containers, loading: false })
  }

  filterByDate(date) {
    const { token } = this.props
    const dateToApi = moment(date)

    const dateToApiAfter = dateToApi.format('YYYY-MM-DD')
    const dateToApiBefore = moment(dateToApi.format())
      .add(1, 'd')
      .format('YYYY-MM-DD')

    const url = `${api}/api/nitrogen_containers?lastControl.createdAt[before]=${dateToApiBefore}&lastControl.createdAt[after]=${dateToApiAfter}`
    Axios.get(url, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({
          loading: false,
          containers: res.data['hydra:member']
        })
      })
      .catch(err => catchError(err))
  }

  inLoad(loading) {
    this.setState({ loading })
  }

  render() {
    const { history, intl, token, role } = this.props
    const { containers, filters, url, loading } = this.state
    return (
      <div className="row Container containersManagement">
        <div className="col s12 content">
          <div className="row pageHeader pageTitle">
            <div
              onClick={() => history.goBack()}
              role="button"
              tabIndex="0"
              onKeyPress={() => history.goBack()}
              className="goBackBtn col m1"
            >
              <i className="material-icons">chevron_left</i>
            </div>
            <h5 className="col m4">
              <FormattedMessage id="app.part.listContainers" />
            </h5>
            <div className="filters col m12 flex flexSpaceBetween">
              <div>
                <Filters
                  token={token}
                  setStateToParent={this.getStateFromChild}
                  url={url}
                  sortCategory="order[containerNumber]"
                  sortBy="asc"
                  role={role}
                  hasDate
                  noActive
                  filterByDate={this.filterByDate}
                  filters={filters}
                  inLoad={this.inLoad}
                />
              </div>
              <div>
                <ExportFile
                  data={this.props}
                  name="containers_list.xlsx"
                  url="/api/nitrogen_containers/export/xlsx"
                />
              </div>
            </div>
          </div>
          <div className="row">
            {!loading ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="app.equipment.containerNumber" />
                    </th>
                    <th>
                      <FormattedMessage id="app.equipment.type" />
                    </th>
                    <th>
                      <FormattedMessage id="app.equipment.lastControl.state" />
                    </th>
                    <th>
                      <FormattedMessage id="app.equipment.lastControl" />
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {containers.map(container => (
                    <tr
                      key={container.id}
                      onClick={() =>
                        history.push({
                          pathname: `/equipments/containers/single/${container.id}`,
                          state: { part: 'listContainers' }
                        })
                      }
                    >
                      <td> {container.containerNumber} </td>
                      <td>{intl.formatMessage({ id: `app.container.${container.type}` })}</td>
                      <td>
                        <StatePill type="ContainerNitrogenLevel" data={container} />
                        {renderNitrogenLevel(container.lastControl.nitrogenLevel, intl)}
                      </td>
                      <td>
                        {container.lastControl.nitrogenLevel === null ? (
                          <>
                            <StatePill type="ContainerNitrogenLevel" data={container} />
                            {renderNitrogenLevel(container.lastControl.nitrogenLevel, intl)}
                          </>
                        ) : (
                          <>
                            <StatePill type="ContainerDate" data={container} />
                            {moment(container.lastControl.createdAt).format('DD/MM/YYYY')}
                          </>
                        )}
                      </td>
                      <td>
                        <i className="material-icons arrow-right">keyboard_arrow_right</i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <LoadingPage />
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(injectIntl(ContainerList))
