import React from 'react'
import { injectIntl } from 'react-intl'
import InputSelectNewVaccine from './partials/InputSelectNewVaccine/InputSelectNewVaccine'

class ReportProblemSelectNewVaccine extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { getDataFromChild } = this.props

    getDataFromChild('disabledButton', true)
  }

  render() {
    const {
      disabledList,
      getDataFromChild,
      newVaccinesToSubmit,
      type,
      vaccinesSelected
    } = this.props

    return (
      <div className="row">
        <div className="col s12">
          <div className="form">
            <div className="card-content">
              <div className="card-block">
                {vaccinesSelected.map((vaccineSelected, index) => (
                  <InputSelectNewVaccine
                    key={index}
                    disabledList={disabledList}
                    getDataFromChild={getDataFromChild}
                    index={index}
                    vaccineSelected={vaccineSelected}
                    newVaccinesToSubmit={newVaccinesToSubmit}
                    type={type}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(ReportProblemSelectNewVaccine)
