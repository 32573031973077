import React from 'react'
import { Switch } from 'react-router'
import { BrowserRouter, Route } from 'react-router-dom'
import App from './App'
import notFound from './components/404'
import NoAuthRoute from './components/Auth/NoAuthRoute'
import RequireAuth from './components/Auth/require-auth'
import userRoles from './config/userRoles.json'
import AlertItemPage from './pages/Alerts/AlertItemPage'
import AlertsListPage from './pages/Alerts/AlertsListPage'
import AddReport from './pages/DailyReport/AddReport'
import DailyReport from './pages/DailyReport/DailyReport'
import Documentation from './pages/Documentation/Documentation'
import DocumentImport from './pages/Documentation/DocumentImport'
import ContainerEndOfUse from './pages/EquipmentManagement/ContainerManagement/ContainerEndOfUse'
import ContainerManagement from './pages/EquipmentManagement/ContainerManagement/ContainerManagement'
import ContainerItem from './pages/EquipmentManagement/ContainerManagement/ContainersList/ContainerItem'
import ContainerList from './pages/EquipmentManagement/ContainerManagement/ContainersList/ContainerList'
import NitrogenTransfer from './pages/EquipmentManagement/ContainerManagement/NitrogenTransfer'
import ContainerTransfer from './pages/EquipmentManagement/ContainerManagement/VaccinesTransfer'
import EquipmentControl from './pages/EquipmentManagement/EquipmentControl'
import FridgeCreate from './pages/EquipmentManagement/FridgeManagement/FridgeCreate'
import FridgeManagement from './pages/EquipmentManagement/FridgeManagement/FridgeManagement'
import FridgeList from './pages/EquipmentManagement/FridgeManagement/FridgesList/FridgeList'
import FridgeSingleView from './pages/EquipmentManagement/FridgeManagement/FridgesList/FridgeSingleView'
import FridgeTransfer from './pages/EquipmentManagement/FridgeManagement/ProductsTransfer'
import EquipementHistory from './pages/EquipmentManagement/History/HistoriesList'
import SingleHistory from './pages/EquipmentManagement/History/SingleHistory'
import History from './pages/History/History'
import HistorySingleView from './pages/History/HistorySingleView/HistorySingleView'
import Home from './pages/Home'
import Login from './pages/Login'
import EquipmentParamsItem from './pages/Parameters/Equipment/EquipmentParamItem'
import EquipmentParamsList from './pages/Parameters/Equipment/EquipmentParamsList'
import ParamsHistoryList from './pages/Parameters/History/HistoriesList'
import SingleHistoryParams from './pages/Parameters/History/SingleHistory'
import ProductsParamsItem from './pages/Parameters/Stock/ProductsParamsItem'
import StockParamsList from './pages/Parameters/Stock/StockParamsList'
import VaccinesParamsItem from './pages/Parameters/Stock/VaccinesParamsItem'
import VaccinationParamItem from './pages/Parameters/Vaccination/VaccinationParamItem'
import VaccinationParamsList from './pages/Parameters/Vaccination/VaccinationParamsList'
import StockHistory from './pages/StockManagement/History/HistoriesList'
import SingleHistoryStock from './pages/StockManagement/History/SingleHistory'
import DiluentReception from './pages/StockManagement/OrderReception/DiluentReception'
import DyeReception from './pages/StockManagement/OrderReception/DyeReception'
import FrozenVaccine from './pages/StockManagement/OrderReception/FrozenVaccineReception'
import Nitrogen from './pages/StockManagement/OrderReception/NitrogenReception'
import OrderReceivedMain from './pages/StockManagement/OrderReception/OrderReception'
import ProductBag from './pages/StockManagement/OrderReception/ProductBagReception'
import RefrigeratedVaccine from './pages/StockManagement/OrderReception/RefrigeratedVaccineReception'
import ReserveContainer from './pages/StockManagement/OrderReception/ReserveContainerReception'
import FrozenVaccineItem from './pages/StockManagement/StockAvailibility/FrozenVaccineItem'
import DiluentOutStock from './pages/StockManagement/StockAvailibility/OutStock/DiluentOutStock'
import DyeOutStock from './pages/StockManagement/StockAvailibility/OutStock/DyeOutStock'
import FrozenVaccineOutStock from './pages/StockManagement/StockAvailibility/OutStock/FrozenVaccineOutStock'
import ProductBagOutStock from './pages/StockManagement/StockAvailibility/OutStock/ProductBagOutStock'
import RefrigeratedVaccineOutStock from './pages/StockManagement/StockAvailibility/OutStock/RefrigeratedVaccineOutStock'
import RefrigeratedVaccineItem from './pages/StockManagement/StockAvailibility/RefrigeratedVaccineItem'
import StockAvailibility from './pages/StockManagement/StockAvailibility/StockAvailibility'
import UserCRUD from './pages/UserManagement/UserCRUD'
import UserList from './pages/UserManagement/UsersList'
import EndRealisation from './pages/Vaccination/EndRealisation/EndRealisation'
import SinglePart from './pages/Vaccination/EndRealisation/partials/SinglePart'
import EditVaccines from './pages/Vaccination/Preparation/EditVaccines'
import Preparation from './pages/Vaccination/Preparation/Preparation'
import PreparationProcess from './pages/Vaccination/Preparation/PreparationProcess'
import AddPreparation from './pages/Vaccination/Realisation/AddPreparation'
import InjectableVaccine from './pages/Vaccination/Realisation/InjectableVaccine'
import RealisationProcess from './pages/Vaccination/Realisation/partials/RealisationProcess'
import RemovePreparation from './pages/Vaccination/Realisation/RemovePreparation'
import ReportProblem from './pages/Vaccination/Realisation/partials/ReportProblem'
import ReportProductBagProblem from './pages/Vaccination/Realisation/partials/ReportProblemProductBag'
import RealisationHome from './pages/Vaccination/Realisation/RealisationHome'
import SprayableVaccine from './pages/Vaccination/Realisation/SprayableVaccine'
import Vaccination from './pages/Vaccination/Vaccination'
import Certificate from './pages/Vaccination/Validation/Certificate'
import GroupValidation from './pages/Vaccination/Validation/GroupValidation'
import Validation from './pages/Vaccination/Validation/Validation'
import AddVaccine from './pages/VeterinaryPart/AddVaccine'
import Incompatibilities from './pages/VeterinaryPart/Incompatibilities'
import Verification from './pages/VeterinaryPart/Verification'
import AddOrder from './pages/WeekPlanning/AddOrder'
import AddDayWithoutHatching from './pages/WeekPlanning/NoHatchingDayAdd'
import DayWithoutHatching from './pages/WeekPlanning/NoHatchingDaysList'
import PlanningPage from './pages/WeekPlanning/PlanningPage'
import ShapsCsvFileImport from './pages/WeekPlanning/ShapsCsvFileImport'
import OrderList from './pages/Traceability/Order/List'
import OrderSingle from './pages/Traceability/Order/Single'
import Vaccines from './pages/Traceability/Order/Vaccines'
import Products from './pages/Traceability/Order/Products'
import PreparationsProduced from './pages/Traceability/Order/PreparationsProduced'
import Share from './pages/Traceability/Order/Share'
import Interventions from './pages/Traceability/Order/Interventions'
import Request from './pages/Traceability/Order/Request'
import Comments from './pages/Traceability/Order/Comments'
import VaccineOrProductList from './pages/Traceability/VaccineOrProduct/List'
import VaccineOrProductSingle from './pages/Traceability/VaccineOrProduct/Single'
import OrdersInvolved from './pages/Traceability/VaccineOrProduct/OrdersInvolved'
import ProblemsEncountered from './pages/Traceability/VaccineOrProduct/ProblemsEncountered'
import Reception from './pages/Traceability/VaccineOrProduct/Reception'
import StockWithdrawal from './pages/Traceability/VaccineOrProduct/StockWithdrawal'
import StorageDevice from './pages/Traceability/VaccineOrProduct/StorageDevice'
import VaccineTransfer from './pages/Traceability/VaccineOrProduct/VaccineTransfer'
import EquipmentList from './pages/Traceability/Equipment/List'
import EquipmentSingle from './pages/Traceability/Equipment/Single'
import EquipmentNitrogenTransfer from './pages/Traceability/Equipment/NitrogenTransfer'
import EquipmentEquipmentControl from './pages/Traceability/Equipment/EquipmentControl'
import EquipmentOrdersInvolved from './pages/Traceability/Equipment/OrdersInvolved'
import EquipmentVaccineTransfer from './pages/Traceability/Equipment/VaccineTransfer'

const Router = () => {
  const { ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_VET, ROLE_OPERATOR, ROLE_DEV } = userRoles
  const defaultAccessRole = [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR]
  const devAndDfaultAccessRole = [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR, ROLE_DEV]
  const allAccessRole = [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR, ROLE_VET]
  const devAndAllAccessRole = [
    ROLE_HATCH_RESP,
    ROLE_HATCHING_RESP,
    ROLE_OPERATOR,
    ROLE_VET,
    ROLE_DEV
  ]
  const RespAccess = [ROLE_HATCHING_RESP, ROLE_HATCH_RESP]

  return (
    <BrowserRouter>
      <App>
        <Switch>
          <NoAuthRoute exact path="/login" component={Login} />
          <Route exact path="/" component={RequireAuth(Home, devAndAllAccessRole)} />
          <Route
            exact
            path="/planning"
            component={RequireAuth(PlanningPage, devAndDfaultAccessRole)}
          />
          <Route exact path="/users" component={RequireAuth(UserList, [ROLE_HATCH_RESP])} />
          <Route
            exact
            path="/users/edit/:id"
            component={RequireAuth(UserCRUD, [ROLE_HATCH_RESP])}
          />
          <Route exact path="/users/create" component={RequireAuth(UserCRUD, [ROLE_HATCH_RESP])} />
          <Route
            exact
            path="/equipments/management"
            component={RequireAuth(EquipmentControl, defaultAccessRole)}
          />
          <Route
            exact
            path="/equipments/containers"
            component={RequireAuth(ContainerManagement, defaultAccessRole)}
          />
          <Route
            exact
            path="/equipments/containers/list"
            component={RequireAuth(ContainerList, defaultAccessRole)}
          />
          <Route
            exact
            path="/equipments/containers/transfer"
            component={RequireAuth(ContainerTransfer, RespAccess)}
          />
          <Route
            exact
            path="/equipments/containers/nitrogenTransfer"
            component={RequireAuth(NitrogenTransfer, defaultAccessRole)}
          />
          <Route
            exact
            path="/equipments/containers/single/:id"
            component={RequireAuth(ContainerItem, defaultAccessRole)}
          />
          <Route
            exact
            path="/equipments/containers/endofuse"
            component={RequireAuth(ContainerEndOfUse, RespAccess)}
          />
          <Route
            exact
            path="/equipments/fridges"
            component={RequireAuth(FridgeManagement, defaultAccessRole)}
          />
          <Route
            exact
            path="/equipments/fridges/list"
            component={RequireAuth(FridgeList, defaultAccessRole)}
          />
          <Route
            exact
            path="/equipments/fridges/single/:id"
            component={RequireAuth(FridgeSingleView, defaultAccessRole)}
          />
          <Route
            exact
            path="/equipments/fridges/transfer"
            component={RequireAuth(FridgeTransfer, RespAccess)}
          />
          <Route
            exact
            path="/equipments/fridges/new"
            component={RequireAuth(FridgeCreate, RespAccess)}
          />
          <Route
            exact
            path="/equipments/equipment-history"
            component={RequireAuth(EquipementHistory, devAndDfaultAccessRole)}
          />
          <Route
            exact
            path="/equipments/equipment-history/single/:id"
            component={RequireAuth(SingleHistory, devAndDfaultAccessRole)}
          />
          <Route
            exact
            path="/stocks/stock-availibility"
            component={RequireAuth(StockAvailibility, defaultAccessRole)}
          />
          <Route
            exact
            path="/stocks/stock-availibility/injected-vaccine/frozen/:id"
            component={RequireAuth(FrozenVaccineItem, defaultAccessRole)}
          />
          <Route
            exact
            path="/stocks/stock-availibility/sprayed-vaccine/frozen/:id"
            component={RequireAuth(FrozenVaccineItem, defaultAccessRole)}
          />
          <Route
            exact
            path="/stocks/stock-availibility/injected-vaccine/refrigerated/:id"
            component={RequireAuth(RefrigeratedVaccineItem, defaultAccessRole)}
          />
          <Route
            exact
            path="/stocks/stock-availibility/sprayed-vaccine/refrigerated/:id"
            component={RequireAuth(RefrigeratedVaccineItem, defaultAccessRole)}
          />
          <Route
            exact
            path="/stocks/out-stock/product-bag/:id"
            component={RequireAuth(ProductBagOutStock, RespAccess)}
          />
          <Route
            exact
            path="/stocks/out-stock/dye/:id"
            component={RequireAuth(DyeOutStock, RespAccess)}
          />
          <Route
            exact
            path="/stocks/out-stock/diluent/:id"
            component={RequireAuth(DiluentOutStock, RespAccess)}
          />
          <Route
            exact
            path="/stocks/out-stock/frozen-vaccine/:id"
            component={RequireAuth(FrozenVaccineOutStock, RespAccess)}
          />
          <Route
            exact
            path="/stocks/out-stock/refrigerated-vaccine/:id"
            component={RequireAuth(RefrigeratedVaccineOutStock, [
              ROLE_HATCH_RESP,
              ROLE_HATCHING_RESP
            ])}
          />
          <Route
            exact
            path="/stocks/order-received"
            component={RequireAuth(OrderReceivedMain, RespAccess)}
          />
          <Route
            exact
            path="/stocks/order-received/frozen-vaccine"
            component={RequireAuth(FrozenVaccine, RespAccess)}
          />
          <Route
            exact
            path="/stocks/order-received/refrigerated-vaccine"
            component={RequireAuth(RefrigeratedVaccine, RespAccess)}
          />
          <Route
            exact
            path="/stocks/order-received/container-reserve"
            component={RequireAuth(ReserveContainer, RespAccess)}
          />
          <Route
            exact
            path="/stocks/out-stock/refrigerated-vaccine/:id"
            component={RequireAuth(RefrigeratedVaccineOutStock, [
              ROLE_HATCH_RESP,
              ROLE_HATCHING_RESP
            ])}
          />
          <Route
            exact
            path="/stocks/order-received/product-bag"
            component={RequireAuth(ProductBag, RespAccess)}
          />
          <Route
            exact
            path="/stocks/order-received/dye"
            component={RequireAuth(DyeReception, RespAccess)}
          />
          <Route
            exact
            path="/stocks/order-received/diluent"
            component={RequireAuth(DiluentReception)}
          />
          <Route
            exact
            path="/stocks/stock-history"
            component={RequireAuth(StockHistory, devAndDfaultAccessRole)}
          />
          <Route
            exact
            path="/stocks/stock-history/single/:id"
            component={RequireAuth(SingleHistoryStock, devAndDfaultAccessRole)}
          />
          <Route
            exact
            path="/stocks/order-received/nitrogen"
            component={RequireAuth(Nitrogen, RespAccess)}
          />
          <Route exact path="/stocks/order-received/nitrogen" component={RequireAuth(Nitrogen)} />
          <Route
            exact
            path="/parameters/equipment"
            component={RequireAuth(EquipmentParamsList, allAccessRole)}
          />
          <Route
            exact
            path="/parameters/equipment#vaccination"
            component={RequireAuth(EquipmentParamsList, allAccessRole)}
          />
          <Route
            exact
            path="/parameters/equipment/edit/:id"
            component={RequireAuth(EquipmentParamsItem)}
          />
          <Route
            exact
            path="/parameters/stock"
            component={RequireAuth(StockParamsList, allAccessRole)}
          />
          <Route
            exact
            path="/parameters/parameter-vaccination"
            component={RequireAuth(VaccinationParamsList, allAccessRole)}
          />
          <Route
            exact
            path="/parameters/stock/products/edit/:id"
            component={RequireAuth(ProductsParamsItem, allAccessRole)}
          />
          <Route
            exact
            path="/parameters/parameter-vaccination/edit/:id"
            component={RequireAuth(VaccinationParamItem, allAccessRole)}
          />
          <Route
            exact
            path="/parameters/parameter-history"
            component={RequireAuth(ParamsHistoryList, devAndAllAccessRole)}
          />
          <Route
            exact
            path="/parameters/stock/speciality/edit/:id"
            component={RequireAuth(VaccinesParamsItem, allAccessRole)}
          />
          <Route
            exact
            path="/stock_parameters/add"
            component={RequireAuth(ProductsParamsItem, allAccessRole)}
          />
          <Route
            exact
            path="/parameters/parameter-history/single/:id"
            component={RequireAuth(SingleHistoryParams, devAndAllAccessRole)}
          />
          <Route
            exact
            path="/parameters/incompatibilities"
            component={RequireAuth(Incompatibilities, [ROLE_VET])}
          />
          <Route
            exact
            path="/parameters/incompatibilities/vet/edit/:id"
            component={RequireAuth(VaccinesParamsItem, [ROLE_VET])}
          />
          <Route
            exact
            path="/parameters/add-vaccine"
            component={RequireAuth(AddVaccine, [ROLE_VET])}
          />
          <Route
            exact
            path="/check/:uuid/:id"
            component={RequireAuth(Verification, [ROLE_VET, ROLE_HATCHING_RESP, ROLE_HATCH_RESP])}
          />
          <Route exact path="/alerts" component={RequireAuth(AlertsListPage)} />
          <Route exact path="/alerts/:id" component={RequireAuth(AlertItemPage)} />
          <Route exact path="/planning/addOrder" component={RequireAuth(AddOrder, RespAccess)} />
          <Route
            exact
            path="/planning/import/shaps-csv"
            component={RequireAuth(ShapsCsvFileImport, RespAccess)}
          />
          <Route
            exact
            path="/planning/day-without-hatching"
            component={RequireAuth(DayWithoutHatching, devAndDfaultAccessRole)}
          />
          <Route
            exact
            path="/planning/day-without-hatching/add"
            component={RequireAuth(AddDayWithoutHatching, RespAccess)}
          />
          <Route
            exact
            path="/vaccination"
            component={RequireAuth(Vaccination, defaultAccessRole)}
          />
          <Route
            exact
            path="/vaccination/preparation"
            component={RequireAuth(Preparation, RespAccess)}
          />
          <Route
            exact
            path="/vaccination/realisation"
            component={RequireAuth(RealisationHome, defaultAccessRole)}
          />
          <Route
            exact
            path="/vaccination/preparation/process/:id"
            component={RequireAuth(PreparationProcess, defaultAccessRole)}
          />
          <Route
            exact
            path="/vaccination/preparation/edit-vaccine/:id"
            component={RequireAuth(EditVaccines, defaultAccessRole)}
          />
          <Route
            exact
            path="/vaccination/realisation/injected"
            component={RequireAuth(InjectableVaccine, defaultAccessRole)}
          />
          <Route
            exact
            path="/vaccination/realisation/injected/process/:id"
            component={RequireAuth(RealisationProcess, defaultAccessRole)}
          />
          <Route
            exact
            path="/vaccination/realisation/vaccine/:id/:vaccineType/signal-problem"
            component={RequireAuth(ReportProblem, defaultAccessRole)}
          />
          <Route
            exact
            path="/vaccination/realisation/injected/:id/signal-productbag-problem"
            component={RequireAuth(ReportProductBagProblem, defaultAccessRole)}
          />
          <Route
            exact
            path="/vaccination/realisation/add-preparation/:id/:vaccineType"
            component={RequireAuth(AddPreparation, [
              ROLE_HATCH_RESP,
              ROLE_HATCHING_RESP,
              ROLE_OPERATOR
            ])}
          />
          <Route
            exact
            path="/vaccination/realisation/remove-preparation/:id/:vaccineType/:preparations"
            component={RequireAuth(RemovePreparation, defaultAccessRole)}
          />
          <Route
            exact
            path="/vaccination/realisation/sprayable-vaccine/:vaccineType"
            component={RequireAuth(SprayableVaccine, defaultAccessRole)}
          />
          <Route
            exact
            path="/vaccination/realisation/sprayable-vaccine/:vaccineType/:id"
            component={RequireAuth(RealisationProcess, defaultAccessRole)}
          />
          <Route
            exact
            path="/dailyReport"
            component={RequireAuth(DailyReport, [
              ROLE_HATCH_RESP,
              ROLE_HATCHING_RESP,
              ROLE_OPERATOR,
              ROLE_DEV
            ])}
          />
          <Route
            exact
            path="/dailyReport/addReport"
            component={RequireAuth(AddReport, [ROLE_HATCH_RESP, ROLE_HATCHING_RESP, ROLE_OPERATOR])}
          />
          <Route exact path="/history" component={RequireAuth(History, devAndDfaultAccessRole)} />
          <Route
            exact
            path="/history/:id"
            component={RequireAuth(HistorySingleView, devAndDfaultAccessRole)}
          />
          <Route
            exact
            path="/documentation"
            component={RequireAuth(Documentation, devAndAllAccessRole)}
          />
          <Route
            exact
            path="/documentation/import"
            component={RequireAuth(DocumentImport, allAccessRole)}
          />
          <Route
            exact
            path="/vaccination/end-realisation"
            component={RequireAuth(EndRealisation, defaultAccessRole)}
          />
          <Route
            exact
            path="/vaccination/end-realisation/:id"
            component={RequireAuth(SinglePart, defaultAccessRole)}
          />
          <Route
            exact
            path="/vaccination/validation"
            component={RequireAuth(Validation, defaultAccessRole)}
          />
          <Route
            exact
            path="/vaccination/validation/:number"
            component={RequireAuth(GroupValidation, defaultAccessRole)}
          />
          <Route
            exact
            path="/vaccination/certificate/:id"
            component={RequireAuth(Certificate, defaultAccessRole)}
          />
          <Route
            path="/traceability/order/list"
            component={RequireAuth(OrderList, devAndAllAccessRole)}
          />
          <Route
            exact
            path="/traceability/order/single/:id"
            component={RequireAuth(OrderSingle, defaultAccessRole)}
          />
          <Route
            exact
            path="/traceability/order/single/:id/vaccines"
            component={RequireAuth(Vaccines, defaultAccessRole)}
          />
          <Route
            exact
            path="/traceability/order/single/:id/products"
            component={RequireAuth(Products, defaultAccessRole)}
          />
          <Route
            exact
            path="/traceability/order/single/:id/preparations_produced"
            component={RequireAuth(PreparationsProduced, defaultAccessRole)}
          />
          <Route
            exact
            path="/traceability/order/single/:id/share"
            component={RequireAuth(Share, defaultAccessRole)}
          />
          <Route
            exact
            path="/traceability/order/single/:id/interventions"
            component={RequireAuth(Interventions, defaultAccessRole)}
          />
          <Route
            exact
            path="/traceability/order/single/:id/request"
            component={RequireAuth(Request, defaultAccessRole)}
          />
          <Route
            exact
            path="/traceability/order/single/:id/comments"
            component={RequireAuth(Comments, defaultAccessRole)}
          />
          <Route
            path="/traceability/vaccine_or_product/list"
            component={RequireAuth(VaccineOrProductList, devAndAllAccessRole)}
          />
          <Route
            exact
            path="/traceability/vaccine_or_product/single/:id"
            component={RequireAuth(VaccineOrProductSingle, defaultAccessRole)}
          />
          <Route
            exact
            path="/traceability/vaccine_or_product/single/:id/orders_involved"
            component={RequireAuth(OrdersInvolved, defaultAccessRole)}
          />
          <Route
            exact
            path="/traceability/vaccine_or_product/single/:id/problems_encountered"
            component={RequireAuth(ProblemsEncountered, defaultAccessRole)}
          />
          <Route
            exact
            path="/traceability/vaccine_or_product/single/:id/reception"
            component={RequireAuth(Reception, defaultAccessRole)}
          />
          <Route
            exact
            path="/traceability/vaccine_or_product/single/:id/stock_withdrawal"
            component={RequireAuth(StockWithdrawal, defaultAccessRole)}
          />
          <Route
            exact
            path="/traceability/vaccine_or_product/single/:id/storage_device"
            component={RequireAuth(StorageDevice, defaultAccessRole)}
          />
          <Route
            exact
            path="/traceability/vaccine_or_product/single/:id/vaccine_transfer"
            component={RequireAuth(VaccineTransfer, defaultAccessRole)}
          />
          <Route
            path="/traceability/equipment/list"
            component={RequireAuth(EquipmentList, devAndAllAccessRole)}
          />
          <Route
            exact
            path="/traceability/equipment/single/:id"
            component={RequireAuth(EquipmentSingle, defaultAccessRole)}
          />
          <Route
            exact
            path="/traceability/equipment/single/:id/equipment_control"
            component={RequireAuth(EquipmentEquipmentControl, defaultAccessRole)}
          />
          <Route
            exact
            path="/traceability/equipment/single/:id/nitrogen_transfer"
            component={RequireAuth(EquipmentNitrogenTransfer, defaultAccessRole)}
          />
          <Route
            exact
            path="/traceability/equipment/single/:id/orders_involved"
            component={RequireAuth(EquipmentOrdersInvolved, defaultAccessRole)}
          />
          <Route
            exact
            path="/traceability/equipment/single/:id/vaccine_transfer"
            component={RequireAuth(EquipmentVaccineTransfer, defaultAccessRole)}
          />
          <Route component={notFound} />
        </Switch>
      </App>
    </BrowserRouter>
  )
}

export default Router
