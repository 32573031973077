/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import AsyncLoader from '../../../components/AsyncLoader'
import productTypes from '../../../config/productTypes'
import { SpanLink } from '../Components'
import { getEquipmentById } from '../ReverseRedirections'
import traceabilityProductTypes from '../../../config/traceabilityProductTypes'

function Reception({ token, location, intl, history }) {
  const [page, setPage] = useState(1)
  const [state, setState] = useState({
    loading: true,
    items: null,
    contentStatus: false,
    totalItems: 0,
    batchType: null,
    equipmentType: null,
    batchNumber: null
  })

  function loadMore() {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (state.totalItems > page * 30) {
        setPage(page + 1)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', loadMore)
  }, [])

  useEffect(() => {
    const pathName = location.pathname.split('/')
    const id = Number(pathName[pathName.length - 2])
    Axios.get(`${api}/api/batch_traceability/${id}/reception?page=${page}`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const unknownValue = intl.formatMessage({ id: 'app.label.N/A' })
        const { equipmentType, batchType } = res.data
        const mappedData = [...res.data['hydra:member']].map(i => {
          return {
            ...i,
            doses_or_volume:
              equipmentType !== null
                ? i.doses
                  ? `${i.doses} ${intl.formatMessage({ id: 'app.unit.doses' })}`
                  : unknownValue
                : i.volume
                ? `${i.volume} ${intl.formatMessage({ id: 'app.unit.volume' })}`
                : unknownValue,
            controlInstant: i.control
              ? `${i.control.instant}
            ${intl.formatMessage({
              id:
                equipmentType === productTypes.VACCINES_BATCH
                  ? 'app.unit.centimeter'
                  : 'app.unit.temp'
            })}`
              : unknownValue,
            controlMin: i.control
              ? `${i.control.min}
            ${intl.formatMessage({
              id:
                equipmentType === productTypes.VACCINES_BATCH
                  ? 'app.unit.centimeter'
                  : 'app.unit.temp'
            })}`
              : unknownValue,
            controlMax: i.control
              ? `${i.control.max}
            ${intl.formatMessage({
              id:
                equipmentType === productTypes.VACCINES_BATCH
                  ? 'app.unit.centimeter'
                  : 'app.unit.temp'
            })}`
              : unknownValue,
            date: i.date ? moment(i.date).format('DD/MM/YYYY') : unknownValue,
            hour: i.date ? moment(i.date).format('HH:mm') : unknownValue,
            units: i.units
              ? `${i.units} ${intl.formatMessage({ id: 'app.unit.units' })}`
              : unknownValue,
            equipmentId: i.equipmentId ?? unknownValue,
            operator: i.operator ?? unknownValue,
            color: i.color ?? unknownValue,
            conformityError: !Array.isArray(i.conformityError) ? i.conformityError : null,
            visible: false
          }
        })
        setState({
          ...state,
          loading: false,
          items: state.items ? state.items.concat(mappedData) : mappedData,
          totalItems: res.data['hydra:totalItems'],
          contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult',
          batchType,
          equipmentType,
          batchNumber: res.data.speciality ?? res.data.batchNumber
        })
      })
      .catch(err => catchError(err))
  }, [page])

  return (
    <div className="row Container stockAvailibilityItem">
      <div className="col s12 Content">
        <div className="row pageHeader pageTitle itemHeader">
          <div
            onClick={() => history.goBack()}
            role="button"
            tabIndex="0"
            onKeyPress={() => history.goBack()}
            className="goBackBtn col s1"
          >
            <i className="material-icons">chevron_left</i>
          </div>
          {state.batchNumber !== null && (
            <h5 className="col s12">
              {state.batchNumber} - {intl.formatMessage({ id: 'app.title.reception' })}
            </h5>
          )}
        </div>
        {!state.loading ? (
          <div className="row">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    {intl.formatMessage({ id: 'app.label.vaccine_or_product.reception.date' })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.vaccine_or_product.reception.hour'
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.vaccine_or_product.reception.operator'
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.vaccine_or_product.reception.units_number'
                    })}
                  </th>
                  {[productTypes.VACCINES_BATCH, productTypes.PRODUCT_BAG].includes(
                    state.batchType
                  ) && (
                    <th>
                      {intl.formatMessage({
                        id:
                          state.equipmentType !== null
                            ? 'app.label.vaccine_or_product.reception.dose_number'
                            : 'app.label.vaccine_or_product.reception.volume_number'
                      })}
                    </th>
                  )}
                  {![productTypes.PRODUCT_BAG].includes(state.batchType) && (
                    <>
                      <th>
                        {intl.formatMessage({
                          id: 'app.label.vaccine_or_product.reception.storage_device'
                        })}
                      </th>
                      <th>
                        {intl.formatMessage({
                          id:
                            state.equipmentType !== productTypes.TYPE_NITROGEN_CONTAINER
                              ? 'app.label.vaccine_or_product.reception.nitrogen_level'
                              : 'app.label.vaccine_or_product.reception.temperature_instant'
                        })}
                      </th>
                      {state.equipmentType === traceabilityProductTypes.TYPE_NITROGEN_CONTAINER && (
                        <>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.vaccine_or_product.reception.temperature_min'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.vaccine_or_product.reception.temperature_max'
                            })}
                          </th>
                        </>
                      )}
                    </>
                  )}
                  <th>
                    {intl.formatMessage({
                      id: 'app.label.vaccine_or_product.reception.conformity'
                    })}
                  </th>
                  {state.batchType === productTypes.DYE_BATCH && (
                    <th>
                      {intl.formatMessage({
                        id: 'app.label.vaccine_or_product.reception.color'
                      })}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {state.items.map((item, key) => {
                  return (
                    <>
                      <tr
                        onClick={() => {
                          if (item.conformityError != null) {
                            const updatedItems = state.items
                            updatedItems[key] = {
                              ...item,
                              visible: !item.visible
                            }
                            setState({ ...state, items: updatedItems })
                          }
                        }}
                      >
                        <td>{item.date}</td>
                        <td>{item.hour}</td>
                        <td>{item.operator}</td>
                        <td>{item.units}</td>
                        {[productTypes.VACCINES_BATCH, productTypes.PRODUCT_BAG].includes(
                          state.batchType
                        ) && <td>{item.doses_or_volume}</td>}
                        {![productTypes.PRODUCT_BAG].includes(state.batchType) && (
                          <>
                            <td>
                              <SpanLink
                                value={item.equipmentId}
                                setLoading={loading => {
                                  setState({ ...state, loading })
                                }}
                                redirect={getEquipmentById}
                                token
                                history
                              />
                            </td>
                            <td>{item.controlInstant}</td>
                            {state.equipmentType ===
                              traceabilityProductTypes.TYPE_NITROGEN_CONTAINER && (
                              <>
                                <td> {item.controlMin}</td>
                                <td> {item.controlMax}</td>
                              </>
                            )}
                          </>
                        )}
                        <td>
                          {intl.formatMessage({
                            id: `app.label.vaccine_or_product.reception.${
                              item.conformityError !== null ? 'not_compliant' : 'compliant'
                            }`
                          })}
                          {item.conformityError != null && (
                            <i className="material-icons arrow-right">keyboard_arrow_down</i>
                          )}
                        </td>
                        {state.batchType === productTypes.DYE_BATCH && <td>{item.color}</td>}
                      </tr>
                      {item.visible &&
                        Object.entries(item.conformityError).map(entry => (
                          <tr>
                            <td colSpan={11}>
                              <span className="bold">
                                {intl.formatMessage({
                                  id: `app.label.vaccine_or_product.reception.${entry[0]}`
                                })}
                              </span>
                              <br />
                              {intl.formatMessage({
                                id: 'app.label.vaccine_or_product.reception.reason'
                              })}
                              {' : '}
                              {entry[1] ? entry[1] : intl.formatMessage({ id: 'app.label.N/A' })}
                            </td>
                          </tr>
                        ))}
                    </>
                  )
                })}
              </tbody>
              <tfoot className="center">
                <tr>
                  <th colSpan="5">
                    <AsyncLoader status={state.contentStatus} />
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        ) : (
          <LoadingPage />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(Reception)))
