import React from 'react'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import { closePage } from '../../globalAction'

const Header = props => {
  const { intl } = props
  return (
    <div className="row pageHeader pageTitle">
      <h5 className="col m11">
        {intl.formatMessage({ id: 'app.label.addDailyControl' })}
        {' - '}
        {moment().format('DD/MM/YYYY')}
      </h5>
      <div className="col s1">
        <div
          className="goBackBtn"
          role="button"
          tabIndex="0"
          onKeyPress={() => closePage(intl)}
          onClick={() => closePage(intl)}
        >
          <i className="material-icons">close</i>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Header)
