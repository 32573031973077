import React, { useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { loginUser, catchError } from '../actions'
import logo from '../assets/images/login/logo.svg'
import bgWaveTop from '../assets/images/login/bg_top_right.svg'
import bgWaveBottom from '../assets/images/login/bg_bottom_left.svg'
import LoadingPage from '../components/LoadingPage'
import GenerationsContext from '../context/GenerationsContext'
import PrestationsContext from '../context/PrestationsContext'
import ShapsCodesContext from '../context/ShapsCodesContext'
import ValencesContext from '../context/ValencesContext'
import ProductsForOrderContext from '../context/ProductsForOrderContext'

function Login(props) {
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { history, loginUser: propsLog, errorMessage } = props
  const { fetchGenerations } = useContext(GenerationsContext)
  const { fetchPrestations } = useContext(PrestationsContext)
  const { fetchShapsCodes } = useContext(ShapsCodesContext)
  const { fetchValences } = useContext(ValencesContext)
  const { fetchProductsForOrder } = useContext(ProductsForOrderContext)

  function clearPassword() {
    setPassword('')
    setError('')
  }

  function handleSubmit() {
    setLoading(true)
    setError('')

    propsLog(password)
      .then(() => {
        fetchGenerations()
        fetchPrestations()
        fetchShapsCodes()
        fetchValences()
        fetchProductsForOrder()
        history.push('/')
      })
      .catch(err => {
        const conditionalError = err.response ? err.response.data.error.message : err.message
        catchError(err.response)
        setLoading(false)
        setError(conditionalError)
        setPassword('')
      })
  }

  useEffect(() => {
    if (password.length === 4) {
      handleSubmit()
    }
  }, [password])

  const handleChangePassword = e => {
    const { value } = e.target
    setPassword(value)
  }

  const handleClickPassword = number => {
    if (password.length <= 3) {
      setPassword(password + number)
    }
  }

  const numbersList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]

  return (
    <div className="login">
      <div id="wave-top">
        <img src={bgWaveTop} alt="wave top" />
      </div>
      <div id="wave-bottom">
        <img src={bgWaveBottom} alt="wave bottom" />
      </div>
      <div className={errorMessage || error ? 'alert alert-on red' : 'alert off'}>
        {errorMessage || error || ''}
      </div>
      <div className="row">
        <div className="col m4 offset-m4 s12">
          <img src={logo} alt="logo" className="loginLogo" />
        </div>
      </div>
      <div className="row">
        <div className="col m6 offset-m3">
          <h5 className="center loginPasswordLabel">Veuillez saisir votre code</h5>
        </div>
      </div>
      <div className="row">
        <div className="col m4 offset-m4">
          <div className="loginPassword">
            <form autoComplete="off">
              <input
                type="password"
                value={password}
                maxLength="4"
                onChange={handleChangePassword}
                className="loginInput"
              />
            </form>
            <i
              role="button"
              tabIndex="0"
              className="material-icons loginClearBtn"
              onClick={clearPassword}
              onKeyPress={clearPassword}
            >
              keyboard_backspace
            </i>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col m6 offset-m3">
          {numbersList.map(number => (
            <div className="loginKeyContainer" key={number}>
              <div
                className="loginKey"
                role="presentation"
                onClick={() => handleClickPassword(number)}
                key={number.toString()}
              >
                {number}
              </div>
            </div>
          ))}
        </div>
      </div>
      {loading && (
        <div className="row">
          <div className="col offset-s4 s4 center">
            <LoadingPage />
          </div>
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.error,
    message: state.auth.message,
    isAuthenticated: state.auth.authenticated
  }
}
export default withRouter(connect(mapStateToProps, { loginUser })(Login))
