import React from 'react'
import { injectIntl } from 'react-intl'
import AsyncLoader from '../../AsyncLoader'
import HistoryItem from './partials/HistoryItem'

class HistoryList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { histories, intl, contentStatus } = this.props

    return (
      <table className="table">
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: `app.label.date` })}</th>
            <th>{intl.formatMessage({ id: `app.label.actionType` })}</th>
            <th>{intl.formatMessage({ id: `app.label.preparation` })}</th>
            <th>{intl.formatMessage({ id: `app.label.orderNumber` })}</th>
            <th>{intl.formatMessage({ id: `app.label.operator` })}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {histories.map((history, i) => (
            <HistoryItem key={i} hist={history} />
          ))}
        </tbody>
        <tfoot className="center">
          <tr>
            <th colSpan="12">
              <AsyncLoader status={contentStatus} />
            </th>
          </tr>
        </tfoot>
      </table>
    )
  }
}

export default injectIntl(HistoryList)
