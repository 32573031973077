import M from 'materialize-css'
import 'materialize-css/dist/css/materialize.min.css'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import './assets/scss/App.scss'
import Footer from './components/Footer'
import SideBar from './components/Menus/SideBar'
import TopBar from './components/Menus/TopBar'
import Cookies from './components/Cookies'

class App extends Component {
  componentDidMount() {
    M.AutoInit()
  }

  render() {
    const { username, role, authenticated, children } = this.props

    return (
      <div className="App">
        <div id="redFlash" />
        <div id="orangeFlash" />
        <div id="blueFlash" />
        {authenticated && (
          <div>
            <SideBar role={role} />
            <TopBar username={username} />
          </div>
        )}
        <div>{children}</div>
        <Footer />
        <Cookies />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.authenticated,
    role: state.auth.role,
    user: state.auth.user,
    username: state.auth.username
  }
}

export default withRouter(connect(mapStateToProps)(App))
