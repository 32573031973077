import React from 'react'

const ConformitiesListView = props => {
  const {
    blocking,
    blockingMaxCongruent,
    blockingMinCongruent,
    conformity,
    nonBlocking,
    unit,
    vaccinationParamId
  } = props

  if (vaccinationParamId === 6 || vaccinationParamId === 7) {
    return (
      <div className="conformityListView">
        {blocking && blocking.min && (
          <div className="conformityRange">
            <span className="line blockingLine" />
            <span className="bubble blocking">
              {blocking.min} {unit}
            </span>
          </div>
        )}
        {nonBlocking && nonBlocking.min && (
          <div className="conformityRange">
            <span className="line nonBlockingLine" />
            <span className="bubble nonBlocking">
              {nonBlocking.min} {unit}
            </span>
          </div>
        )}
        {blocking && blocking.max && (
          <div className="conformityRange">
            <span className="line congruentLine" />
            <span className="bubble blocking">
              {blocking.max} {unit}
            </span>
            <span className="line blockingLine" />
          </div>
        )}
      </div>
    )
  }

  if (vaccinationParamId === 3 || vaccinationParamId === 4) {
    return (
      <div className="conformityListView">
        {blocking && blocking.min && (
          <div className="conformityRange">
            <span className="line blockingLine" />
            <span className="bubble blocking">
              {blocking.min} {unit}
            </span>
          </div>
        )}
        {nonBlocking && nonBlocking.min && (
          <div className="conformityRange">
            <span className="line congruentLine" />
            <span className="bubble nonBlocking">
              {nonBlocking.min} {unit}
            </span>
          </div>
        )}
        {conformity && conformity.min && (
          <div className="conformityRange">
            <span className={`line ${blockingMinCongruent ? 'blockingLine' : 'nonBlockingLine'}`} />
            <span className="bubble congruent">
              {conformity.min} {unit}
            </span>
          </div>
        )}
        {conformity && conformity.max && (
          <div className="conformityRange">
            <span className="line congruentLine" />
            <span className="bubble congruent">
              {conformity.max} {unit}
            </span>
            <span className={`line ${blockingMaxCongruent ? 'blockingLine' : 'nonBlockingLine'}`} />
          </div>
        )}
        {nonBlocking && nonBlocking.max && (
          <div className="conformityRange">
            <span className="line nonBlockingLine" />
            <span className="bubble nonBlocking">
              {nonBlocking.max} {unit}
            </span>
            <span className="line blockingLine" />
          </div>
        )}
        {blocking && blocking.max && (
          <div className="conformityRange">
            <span className="line nonBlockingLine" />
            <span className="bubble blocking">
              {blocking.max} {unit}
            </span>
            <span className="line blockingLine" />
          </div>
        )}
      </div>
    )
  }

  if (vaccinationParamId !== 8) {
    return (
      <div className="conformityListView">
        {blocking && blocking.min && (
          <div className="conformityRange">
            <span className="line blockingLine" />
            <span className="bubble blocking">
              {blocking.min} {unit}
            </span>
          </div>
        )}
        {nonBlocking && nonBlocking.min && (
          <div className="conformityRange">
            <span className="line nonBlockingLine" />
            <span className="bubble nonBlocking">
              {nonBlocking.min} {unit}
            </span>
          </div>
        )}
        {conformity && conformity.min && (
          <div className="conformityRange">
            <span className={`line ${blockingMinCongruent ? 'blockingLine' : 'nonBlockingLine'}`} />
            <span className="bubble congruent">
              {conformity.min} {unit}
            </span>
          </div>
        )}
        {conformity && conformity.max && (
          <div className="conformityRange">
            <span className="line congruentLine" />
            <span className="bubble congruent">
              {conformity.max} {unit}
            </span>
            <span className={`line ${blockingMaxCongruent ? 'blockingLine' : 'nonBlockingLine'}`} />
          </div>
        )}
        {nonBlocking && nonBlocking.max && (
          <div className="conformityRange">
            <span className="line nonBlockingLine" />
            <span className="bubble nonBlocking">
              {nonBlocking.max} {unit}
            </span>
            <span className="line nonBlockingLine" />
          </div>
        )}
        {blocking && blocking.max && (
          <div className="conformityRange">
            <span className="line nonBlockingLine" />
            <span className="bubble blocking">
              {blocking.max} {unit}
            </span>
            <span className="line blockingLine" />
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="conformityListView">
      {nonBlocking && nonBlocking.min && (
        <div className="conformityRange">
          <span className="line nonBlockingLine" />
          <span className="bubble nonBlocking">
            {nonBlocking.min} {unit}
          </span>
        </div>
      )}
      {nonBlocking && nonBlocking.max.percent && (
        <div className="conformityRange">
          <span className="line congruentLine" />
          <span className="bubble nonBlocking">
            {nonBlocking.max.percent} {unit}
          </span>
          <span className="line nonBlockingLine" />
        </div>
      )}
    </div>
  )
}

export default ConformitiesListView
