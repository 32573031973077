module.exports = {
  documentCategories: [
    {
      name: 'Protocole',
      value: 0
    },
    {
      name: 'Procédure',
      value: 1
    },
    {
      name: 'Mode opératoire',
      value: 2
    }
  ]
}
