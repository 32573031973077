import React from 'react'
import { injectIntl } from 'react-intl'

class QuantityBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { handleChange, intl, part } = this.props
    return (
      <div className="row">
        <div className="col s12">
          <div className="form col s12 noPadding">
            <div className="col s12 card-content">
              <div className="row marginTop20">
                <div className="col s6 input">
                  <label>{intl.formatMessage({ id: 'app.label.dosesQuantity' })}</label>
                  <input
                    type="number"
                    name="dosesQuantity"
                    onChange={handleChange}
                    disabled={part >= 1}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(QuantityBlock)
