import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import BlockItem from '../../../components/Vaccination/EndRealisation/BlockItem'
import { api } from '../../../parameters'

class EndRealisation extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      preps: []
    }
  }

  componentDidMount() {
    const { token } = this.props

    Axios.get(
      `${api}/api/order_preparations?scannedQr=true&state[]=2&state[]=4&order[expirationDate]=asc`,
      {
        headers: { Authorization: token }
      }
    )
      .then(res =>
        this.setState({
          loading: false,
          preps: res.data['hydra:member']
        })
      )
      .catch(err => catchError(err.response))
  }

  render() {
    const { intl } = this.props
    const { loading, preps } = this.state

    if (loading === true) {
      return <LoadingPage />
    }

    if (preps.length === 0) {
      return (
        <div className="row Container VaccinationContainer endRealisation">
          <div className="col s12">
            <div className="custom-card noPreparation">
              {intl.formatMessage({ id: 'app.message.noMorePreparation' })}
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="row Container VaccinationContainer endRealisation">
        <div className="col s12">
          {preps.map(prep => (
            <Link key={prep.id} to={`/vaccination/end-realisation/${prep.id}`}>
              <BlockItem prep={prep} arrowLogo orderNumberDisplay />
            </Link>
          ))}
        </div>
      </div>
    )
  }
}

const mapStatetoProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStatetoProps)(EndRealisation))
