import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import Swal from 'sweetalert2'
import { catchError } from '../../../actions'
import { validatedAction } from '../../../components/globalAction'
import ContainerInformations from '../../../components/StockManagement/OrderReception/FrozenVaccineReception/ContainerInformations/ContainerInformations'
import HeaderReception from '../../../components/StockManagement/OrderReception/partials/HeaderReception'
import OrderConformity from '../../../components/StockManagement/OrderReception/partials/OrderConformity'
import ProgressBarReception from '../../../components/StockManagement/OrderReception/partials/ProgressBarReception'
import VaccineInformations from '../../../components/StockManagement/OrderReception/partials/VaccineInformations'
import { api } from '../../../parameters'
import globalHistory from '../../../history'

class FrozenVaccine extends React.Component {
  constructor() {
    super()
    this.state = {
      part: 1,
      progress: 10,
      isOther: false,
      disabled: true,
      formData: {
        container: { container: { containerNumber: '' } },
        history: { comment: '' }
      }
    }

    this.changePart = this.changePart.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setDisabled = this.setDisabled.bind(this)
    this.getChildInformation = this.getChildInformation.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
  }

  getChildInformation(name, value, inForm = true) {
    const { formData } = this.state
    if (!inForm) return this.setState({ [name]: value })
    return this.setState({ formData: { ...formData, [name]: value } })
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  getContent() {
    const { part, isOther, receptionType, disabled, formData } = this.state
    const { container } = formData

    switch (part) {
      case 1:
        return (
          <ContainerInformations
            isOther={isOther}
            handleChange={this.handleChange}
            changePart={this.changePart}
            setDataToParent={this.getChildInformation}
            disabled={disabled}
            setDisabled={this.setDisabled}
            history={formData.history}
          />
        )
      case 2:
        return (
          <VaccineInformations
            handleChange={this.handleChange}
            changePart={this.changePart}
            setDataToParent={this.getChildInformation}
            conservationMethod={0}
            disabled={disabled}
            setDisabled={this.setDisabled}
          />
        )
      case 3:
        return (
          <OrderConformity
            handleSubmit={this.handleSubmit}
            handleChange={this.handleChange}
            setDataToParent={this.getChildInformation}
            getDataFromChild={this.getDataFromChild}
            receptionType={receptionType}
            needNitrogenLevelControl
            disabled={disabled}
            formData={formData}
            setDisabled={this.setDisabled}
            receiverContainers={container}
          />
        )
      default:
        return <div />
    }
  }

  setDisabled(disabled) {
    this.setState({ disabled })
  }

  changePart() {
    const { part, progress } = this.state

    this.setState({ part: part + 1, progress: progress + 34 })
    this.setDisabled(true)
  }

  handleSubmit(_e, stopped = false) {
    const { formData, receptionType } = this.state
    const { token, intl } = this.props

    let url = '/api/vaccines_batches/frozen'
    formData.stopped = stopped
    let receptionDate = moment().format()
    const { container, history, vaccinesBatches, nitrogenLevelControl } = formData
    const {
      containerNumber,
      provider,
      capacity,
      firstLight,
      secondLight,
      containerDesinfected,
      commentary
    } = container

    receptionDate = formData.date || receptionDate
    if (receptionType === 1) {
      const newContainer = {}

      if (history) {
        history.firstLight = firstLight
        history.secondLight = secondLight
      }

      history.disinfected = containerDesinfected || true
      history.boxState = true
      history.containerState = true
      newContainer.container = {
        containerNumber,
        provider,
        capacity,
        receptionDate
      }
      history.comment = commentary

      newContainer.control = {
        nitrogenLevel: nitrogenLevelControl,
        nitrogenLevelState: 1
      }

      url = '/api/vaccines_batches/frozen_plus_container'

      if (stopped) {
        newContainer.container.receptionDate = receptionDate
      } else if (formData.dateSelected) {
        receptionDate = moment(formData.dateSelected)
          .hour(formData.controlHour)
          .minutes(formData.controlMinutes)
          .format()
        newContainer.container.receptionDate = receptionDate
      } else {
        newContainer.container.receptionDate = receptionDate
      }

      vaccinesBatches.forEach(vaccine => {
        const vacc = vaccine
        vacc.receptionDate = receptionDate
        if (!vacc.expirationDate) vacc.expirationDate = moment().format()
      })

      formData.vaccinesBatches = vaccinesBatches
      formData.container = newContainer
      delete formData.controlHour
      delete formData.controlMinutes
      delete formData.dateSelected
      delete formData.nitrogenLevelControl
    } else {
      receptionDate =
        formData.dateSelected && formData.controlHour && formData.controlMinutes
          ? moment(formData.dateSelected)
              .hour(formData.controlHour)
              .minutes(formData.controlMinutes)
              .format()
          : receptionDate
      vaccinesBatches.forEach(vaccine => {
        const vacc = vaccine
        vacc.receptionDate = moment(formData.dateSelected).format() || receptionDate
        if (!vacc.expirationDate) vacc.expirationDate = moment().format()
      })

      if (history) {
        history.firstLight = ''
        history.secondLight = ''
      }

      let control = {}

      control = {
        nitrogenLevel: nitrogenLevelControl,
        nitrogenLevelState: 1
      }

      formData.container.control = control

      delete formData.controlHour
      delete formData.controlMinutes
      delete formData.dateSelected
      delete formData.nitrogenLevelControl
    }

    history.nameConformity = formData.nameConformity
    history.quantityConformity = formData.quantityConformity
    history.batchNumberConformity = formData.batchNumberConformity
    history.expirationDateConformity = formData.expirationDateConformity
    history.nameNoConformityReason = formData.nameNoConformityReason
    history.quantityNoConformityReason = formData.quantityNoConformityReason
    history.batchNumberNoConformityReason = formData.batchNumberNoConformityReason
    history.expirationDateNoConformityReason = formData.expirationDateNoConformityReason
    history.receptionDate = receptionDate
    Axios.post(`${api}${url}`, formData, {
      headers: { Authorization: token }
    })
      .then(() => {
        if (stopped === true) {
          Swal.fire({
            type: 'info',
            title: intl.formatMessage({ id: 'app.title.stoppedReception' }),
            text: intl.formatMessage({ id: 'app.message.stoppedReception' }),
            customClass: {
              icon: 'swalInfoIcon',
              title: 'swalTitle',
              content: 'swalContentText',
              confirmButton: 'blueBtn'
            }
          }).then(() => globalHistory.goBack())
        } else {
          validatedAction(intl.formatMessage({ id: 'app.message.successFrozenVaccineContainer' }))
        }
      })
      .catch(err => catchError(err.response))
  }

  render() {
    const { progress, part } = this.state
    const { intl } = this.props

    return (
      <div className="Container row">
        <div className="col s12">
          <HeaderReception text={intl.formatMessage({ id: 'app.part.frozenVaccinReceipt' })} />
          <ProgressBarReception progress={progress} part={part} />
          {this.getContent()}
        </div>
      </div>
    )
  }
}

export default injectIntl(FrozenVaccine)
