import React from 'react'
import { injectIntl } from 'react-intl'

const BlockInfo = props => {
  const { productBag, bottle, intl, vaccines } = props

  const melt = []

  vaccines.forEach(vaccine => {
    melt.push(vaccine.speciality)
  })

  let dosesDisplay
  let label

  if (productBag !== null) {
    const { doses } = productBag
    label = 'productBag'
    dosesDisplay = doses
  } else if (bottle !== null) {
    dosesDisplay = bottle
    label = 'can'
  }

  return (
    <div className="row col s12">
      <div className="col s12">
        <div className="custom-card col s12 noPadding">
          <div className="col s12 card-header">
            <div className="col s6 bold em1-2">{melt.join(' + ')}</div>
            <div className="col s6 bold">
              {intl.formatMessage({ id: `app.label.${label}` })} {dosesDisplay}{' '}
              {intl.formatMessage({ id: 'app.unit.doses' })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(BlockInfo)
