import moment from 'moment'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import HeadSingleHistory from './HeadSingleHistory'

export default function InjectionControl(props) {
  const { history, operator, date, action, intl } = props

  return (
    <div className="Container equipmentHistory">
      <HeadSingleHistory operator={operator} date={date} action={action}>
        <div className="row">
          <div className="col s6">
            <p>
              <span className="label">
                <FormattedMessage id="app.label.name" />
                &nbsp;:&nbsp;
              </span>
              {intl.formatMessage({ id: 'app.label.injection' })}
            </p>
          </div>
          <div className="col s6">
            <p>
              <span className="label">
                <FormattedMessage id="app.equipment.lastControl" />
                &nbsp;:&nbsp;
              </span>
              {moment(history.action.lastControlDate).format('DD/MM/YYYY')}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col s6">
            <p>
              <span className="label">
                <FormattedMessage id="app.equipment.question.typeNeedle" />
                &nbsp;:&nbsp;
              </span>
              {`${history.action.needlesType}  ${intl.formatMessage({
                id: 'app.unit.needleType'
              })}`}
            </p>
          </div>
          <div className="col s6">
            <p>
              <span className="label">
                <FormattedMessage id="app.control.vaccine.newNeedles" />
                &nbsp;:&nbsp;
              </span>
              <FormattedMessage id={`app.label.${history.action.newNeedles}`} />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            <p>
              <span className="label">
                <FormattedMessage id="app.control.vaccine.rinsePrime" />
                &nbsp;:&nbsp;
              </span>
              <FormattedMessage id={`app.label.${history.action.rinsePrimeMachines}`} />
            </p>
          </div>
        </div>
        <div className="row">
          {history.action.needles.map(
            needle =>
              needle.name && (
                <div key={needle.name} className="col s3">
                  <p>
                    <span className="label">
                      <FormattedMessage id="app.label.name" />
                      &nbsp;:&nbsp;
                    </span>
                    {needle.name}
                  </p>
                  <p className="paddingBot20">
                    <span className="label">
                      <FormattedMessage id="app.label.volume" />
                      &nbsp;:&nbsp;
                    </span>
                    {`${needle.volume} ${intl.formatMessage({ id: 'app.unit.needleVolume' })}`}
                  </p>
                </div>
              )
          )}
        </div>
      </HeadSingleHistory>
    </div>
  )
}
