import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import Axios from 'axios'
import moment from 'moment'
import M from 'materialize-css'
import { catchError } from '../../../../../../../../../actions'
import { api } from '../../../../../../../../../parameters'

class VaccBatchSelector extends React.Component {
  constructor() {
    super()
    this.state = {}

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const { batches, conservation } = this.props
    let apiName

    switch (conservation) {
      case 0:
        apiName = 'container_vaccines'
        break
      case 1:
        apiName = 'fridge_vaccines'
        break
      default:
        break
    }

    this.setState({ resBatch: batches[0], apiName })

    M.AutoInit()
  }

  componentDidUpdate(prevProps) {
    const { batches } = this.props

    M.AutoInit()

    if (prevProps.batches !== batches) {
      this.setState({ resBatch: batches[0] })
    }
  }

  handleChange(e) {
    const { token } = this.props
    const { apiName } = this.state
    const { value: id } = e.target

    Axios.get(`${api}/api/${apiName}/${id}`, { headers: { Authorization: token } })
      .then(res => {
        this.setState({ resBatch: res.data })
      })
      .catch(err => catchError(err.response))
  }

  handleSubmit() {
    const {
      conservation,
      selectedBatches,
      i,
      setSelectedBatches,
      setNeededDoses,
      neededDoses
    } = this.props
    const { resBatch } = this.state
    const neededQuantity = neededDoses / resBatch.vaccinesBatch.vaccineArticle.dosesNumber
    const units = conservation === 0 ? resBatch.ampoulesNumber : resBatch.vialsNumber
    const quantity = neededQuantity <= units ? Math.ceil(neededQuantity) : Math.ceil(units)

    selectedBatches[i] = {
      batch: resBatch,
      quantity,
      disabled: true
    }

    if (neededQuantity > units) {
      selectedBatches[i + 1] = {}
    }

    setNeededDoses(neededDoses - quantity * resBatch.vaccinesBatch.vaccineArticle.dosesNumber)
    setSelectedBatches(selectedBatches)
  }

  renderBatchNumber(vaccineBatch) {
    const { conservation, intl, selectedBatches, i } = this.props
    const batch = selectedBatches[i].batch ? selectedBatches[i].batch : vaccineBatch
    const number = batch.vaccinesBatch.batchNumber
    let container

    switch (conservation) {
      case 0:
        container = `${intl.formatMessage({ id: 'app.label.container' })} ${
          batch.nitrogenContainer.containerNumber
        }`
        break
      case 1:
        container = `${intl.formatMessage({ id: 'app.label.fridge' })} ${batch.fridge.name}`
        break
      default:
        break
    }

    return `${number} - ${container}`
  }

  renderArticle() {
    const { resBatch } = this.state

    return resBatch && resBatch.vaccinesBatch.vaccineArticle.name
  }

  renderExpDate() {
    const { resBatch } = this.state

    return resBatch && resBatch.vaccinesBatch.expirationDate
  }

  renderTotalAmpoulesLabel() {
    const { conservation } = this.props

    switch (conservation) {
      case 0:
        return 'totalAmpoulesNumber'
      case 1:
      case 2:
        return 'totalVialsNumber'
      default:
        return ''
    }
  }

  renderTotalAmpoulesNumber() {
    const { conservation } = this.props
    const { resBatch } = this.state

    switch (conservation) {
      case 0:
        return resBatch && resBatch.ampoulesNumber
      case 1:
      case 2:
        return resBatch && resBatch.vialsNumber
      default:
        return ''
    }
  }

  renderTotalDoses() {
    const { conservation } = this.props
    const { resBatch } = this.state

    switch (conservation) {
      case 0:
        return (
          resBatch && resBatch.ampoulesNumber * resBatch.vaccinesBatch.vaccineArticle.dosesNumber
        )
      case 1:
      case 2:
        return resBatch && resBatch.vialsNumber * resBatch.vaccinesBatch.vaccineArticle.dosesNumber
      default:
        return ''
    }
  }

  renderNeededQuantity() {
    const { conservation, selectedBatches, i, neededDoses } = this.props
    const { resBatch } = this.state

    if (selectedBatches[i].batch !== undefined) {
      return selectedBatches[i].quantity
    }

    if (resBatch !== undefined) {
      const units = conservation === 0 ? resBatch.ampoulesNumber : resBatch.vialsNumber
      const neededQuantity = neededDoses / resBatch.vaccinesBatch.vaccineArticle.dosesNumber

      return neededQuantity <= units ? Math.ceil(neededQuantity) : Math.ceil(units)
    }

    return ''
  }

  renderNeededDoses() {
    const { conservation, neededDoses } = this.props
    const { resBatch } = this.state

    if (resBatch !== undefined) {
      const units = conservation === 0 ? resBatch.ampoulesNumber : resBatch.vialsNumber
      const neededQuantity = neededDoses / resBatch.vaccinesBatch.vaccineArticle.dosesNumber
      const quantity = neededQuantity <= units ? Math.ceil(neededQuantity) : Math.ceil(units)

      return quantity * resBatch.vaccinesBatch.vaccineArticle.dosesNumber
    }

    return ''
  }

  render() {
    const { intl, disabled, batches, neededDoses } = this.props

    if (batches.length === 0 && (neededDoses > 0 || neededDoses === undefined)) {
      return (
        <div className="row card-block blue-text center bold">
          {intl.formatMessage({ id: 'app.message.noVaccineToUse' })}
        </div>
      )
    }

    return (
      <div className="row card-block">
        {disabled !== true ? (
          <>
            <div className="row">
              <div className="input col l4">
                <label htmlFor="batch">{intl.formatMessage({ id: `app.label.batchNumber` })}</label>
                <select className="select-wrapper" name="batch" onChange={this.handleChange}>
                  {batches.map(batch => (
                    <option key={batch.id} value={batch.id}>
                      {this.renderBatchNumber(batch)}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input col l4">
                <label htmlFor="article">{intl.formatMessage({ id: `app.label.article` })}</label>
                <input type="text" disabled name="article" value={this.renderArticle()} />
              </div>
              <div className="col l4 input">
                <label htmlFor="datepicker">
                  <span>{intl.formatMessage({ id: 'app.label.expirationDate' })}</span>
                </label>
                <input
                  type="text"
                  name="datepicker"
                  className="datepicker"
                  value={moment(this.renderExpDate()).format('DD/MM/YYYY')}
                  disabled
                />
              </div>
            </div>
            <div className="row flex">
              <div className="input col l4">
                <label htmlFor="totalAmpoulesNumber">
                  {intl.formatMessage({ id: `app.label.${this.renderTotalAmpoulesLabel()}` })}
                </label>
                <input
                  type="number"
                  disabled
                  name="totalAmpoulesNumber"
                  value={this.renderTotalAmpoulesNumber()}
                />
              </div>
              <div className="input col l4">
                <label htmlFor="totalDoses">
                  {intl.formatMessage({ id: `app.label.totalDosesNumber` })}
                </label>
                <input type="number" disabled name="totalDoses" value={this.renderTotalDoses()} />
              </div>

              <div className="col l4 center">
                <button type="button" className="btn blueBtn" onClick={this.handleSubmit}>
                  {intl.formatMessage({ id: 'app.button.select' })}
                </button>
              </div>
            </div>
            <div className="row">
              <div className="input col l4">
                <label htmlFor="neededQuantity">
                  {intl.formatMessage({ id: `app.label.neededQuantity` })}
                </label>
                <input
                  type="number"
                  disabled
                  name="neededQuantity"
                  value={this.renderNeededQuantity()}
                />
              </div>
              <div className="input col l4">
                <label htmlFor="neededDoses">
                  {intl.formatMessage({ id: `app.label.neededDosesNumber` })}
                </label>
                <input type="number" disabled name="neededDoses" value={this.renderNeededDoses()} />
              </div>
            </div>
          </>
        ) : (
          <div className="row">
            <div className="input col l3">
              <label htmlFor="batch">{intl.formatMessage({ id: `app.label.batchNumber` })}</label>
              <select className="select-wrapper" name="batch" disabled>
                <option>{this.renderBatchNumber(undefined)}</option>
              </select>
            </div>
            <div className="input col l3">
              <label htmlFor="article">{intl.formatMessage({ id: `app.label.article` })}</label>
              <input type="text" disabled name="article" value={this.renderArticle()} />
            </div>
            <div className="col l3 input">
              <label htmlFor="datepicker">
                <span>{intl.formatMessage({ id: 'app.label.expirationDate' })}</span>
              </label>
              <input
                type="text"
                name="datepicker"
                className="datepicker"
                value={moment(this.renderExpDate()).format('DD/MM/YYYY')}
                disabled
              />
            </div>
            <div className="row">
              <div className="input col l3">
                <label htmlFor="neededQuantity">
                  {intl.formatMessage({ id: `app.label.neededQuantity` })}
                </label>
                <input
                  type="number"
                  disabled
                  name="neededQuantity"
                  value={this.renderNeededQuantity()}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(VaccBatchSelector))
