import Axios from 'axios'
import { saveAs } from 'file-saver'
import React from 'react'
import Swal from 'sweetalert2'
import { catchError, removeInstantNotif } from '../actions'
import icons from '../assets/icons/icons'
import roles from '../config/userRoles.json'
import history from '../history'
import { api } from '../parameters'
import store from '../store'

const { ROLE_HATCH_RESP } = roles

const disableF5 = e => {
  if ((e.which || e.keyCode) === 116) {
    e.preventDefault()
  }
}

const disableCtrl = e => {
  if ((e.which || e.keyCode) === 82) {
    e.preventDefault()
    e.stopPropagation()
  }
}

export const disableBrowserActions = e => {
  disableF5(e)
  disableCtrl(e)
}

export const closePage = (intl, swal = true) => {
  if (swal === true) {
    Swal.fire({
      title: intl.formatMessage({ id: 'app.label.areYouSureTitle' }),
      text: intl.formatMessage({ id: 'app.label.areYouSureText' }),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({ id: 'app.label.yes' }),
      cancelButtonText: intl.formatMessage({ id: 'app.label.no' }),
      customClass: {
        icon: 'swalWarningIcon',
        title: 'swalTitle',
        content: 'swalContentText',
        confirmButton: 'blueBtn',
        cancelButton: 'blueBtn'
      }
    }).then(res => res.value && history.goBack())
  } else {
    history.goBack()
  }
}

export const goBack = (path, historyObject) => {
  historyObject.push(path)
}

export const BackToPrevious = props => {
  const { text } = props
  return (
    <div className="flex col m5">
      <div
        onClick={() => history.goBack()}
        role="button"
        tabIndex="0"
        onKeyPress={() => history.goBack()}
        className="goBackBtn col m1"
      >
        <i className="material-icons">chevron_left</i>
      </div>
      <h5 className="col m11">{text}</h5>
    </div>
  )
}

export const messageInfo = (message, redirectTo = null) => {
  const swallMessage = message
  Swal.fire({
    type: 'info',
    text: swallMessage,
    timer: 10000,
    customClass: {
      icon: 'swalSuccessIcon',
      title: 'swalTitle',
      content: 'swalContentText',
      confirmButton: 'blueBtn'
    }
  }).then(() => {
    if (redirectTo !== 'none') {
      if (redirectTo === null) return history.goBack()

      return history.go(redirectTo)
    }
    return null
  })
}

export const validatedAction = (message, redirectTo = null, pushTo = null) => {
  const swallMesssage = message
  const { instantNotifications } = store.getState().mercure

  if (instantNotifications.length === 0) {
    Swal.fire({
      type: 'success',
      title: 'Opération réussie',
      text: swallMesssage,
      timer: 10000,
      customClass: {
        icon: 'swalSuccessIcon',
        title: 'swalTitle',
        content: 'swalContentText',
        confirmButton: 'blueBtn'
      }
    }).then(() => {
      if (redirectTo === null) return history.goBack()
      if (pushTo !== null) return history.push(pushTo)
      return history.go(redirectTo)
    })
  } else {
    Swal.fire({
      type: 'info',
      text: instantNotifications[0].message,
      timer: 10000
    }).then(() => {
      removeInstantNotif(0)
      validatedAction(message, redirectTo)
    })
  }
}

export const exportFileAction = (url, token) => {
  Axios.get(`${api}${url}`, {
    headers: { Authorization: token },
    responseType: 'blob'
  })
    .then(res => {
      const name = res.headers['attachment-filename']
      saveAs(res.data, name)
    })
    .catch(err => catchError(err.response))
}

export const ExportFile = props => {
  const { data, url } = props
  const { token, role } = data
  const traceability = window.location.href.includes('/traceability')
  if (role.includes(ROLE_HATCH_RESP)) {
    return (
      <div
        className="btnExport space5pixel"
        onClick={() => exportFileAction(url, token)}
        onKeyPress={() => exportFileAction(url, token)}
        role="button"
        tabIndex="0"
        style={traceability ? { width: '75px', height: '75px' } : {}}
      >
        <img
          src={icons.whiteExport}
          alt="export icon"
          style={traceability ? { width: '50px' } : {}}
        />
      </div>
    )
  }
  return <div />
}
