import React from 'react'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import icons from '../../../../../../assets/icons/icons'

const GeneralInfos = props => {
  const { group, intl, number, onClick, showMore } = props

  return (
    <div
      className="generalInfos row pointer"
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    >
      <div className="col s2 number">{number}</div>
      <div className="col s9 infos">
        <div className="col s6">
          <span className="label">{intl.formatMessage({ id: 'app.label.hatchingWeek' })}</span>{' '}
          {group.hatchingWeek.split('_')[1]}
        </div>
        <div className="col s6">
          <span className="label">{intl.formatMessage({ id: 'app.label.hatchery' })}</span>{' '}
          {group.hatchery}
        </div>
        <div className="col s6">
          <span className="label">{intl.formatMessage({ id: 'app.label.farmer' })}</span>{' '}
          {group.farmer}
        </div>
        <div className="col s6">
          <span className="label">{intl.formatMessage({ id: 'app.label.hatchingDate' })}</span>{' '}
          {moment(group.hatchingDate).format('DD/MM/Y')}
        </div>
      </div>
      <div className="col s1 number">
        <img src={showMore ? icons.blueArrowTop : icons.blueArrowBottom} alt="blue bottom arrow" />
      </div>
    </div>
  )
}

export default injectIntl(GeneralInfos)
