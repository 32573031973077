import React from 'react'
import { injectIntl } from 'react-intl'
import icons from '../../../../../../assets/icons/icons'

const Header = props => {
  const { inEdit, intl, onClick, total } = props

  return (
    <div className="chicksInfosHeader">
      <div className="col s6">{`${total} ${intl.formatMessage({ id: 'app.label.chicks' })}`}</div>
      <div className="col s1 offset-s5">
        <div
          className={`editBtn ${inEdit === true ? 'hidden' : ''}`}
          onClick={onClick}
          onKeyPress={onClick}
          role="button"
          tabIndex={0}
        >
          <img src={icons.bluePenCircle} alt="blue pen icon" />
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Header)
