import React from 'react'
import { injectIntl } from 'react-intl'
import M from 'materialize-css'

class EditInfos extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      females: props.orderFemales,
      freeChicks: 0,
      males: props.orderMales
    }

    this.handleFemalesEdit = this.handleFemalesEdit.bind(this)
    this.handleMalesEdit = this.handleMalesEdit.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    M.AutoInit()
  }

  handleMalesEdit(e) {
    let { value: males } = e.target
    const { orderFemales, orderMales } = this.props
    const { females } = this.state

    males = parseInt(males)

    const freeChicks =
      males - orderMales + females - orderFemales > 0
        ? males - orderMales + females - orderFemales
        : 0
    this.setState({ freeChicks, males })
  }

  handleFemalesEdit(e) {
    let { value: females } = e.target
    const { orderFemales, orderMales } = this.props
    const { males } = this.state

    females = parseInt(females)

    const freeChicks =
      males - orderMales + females - orderFemales > 0
        ? males - orderMales + females - orderFemales
        : 0
    this.setState({ freeChicks, females })
  }

  handleSubmit() {
    const { setFemales, setFreeChicks, setMales, toggleEdit } = this.props
    const { females, freeChicks, males } = this.state

    setFemales(females)
    setFreeChicks(freeChicks)
    setMales(males)
    toggleEdit()
  }

  render() {
    const { inEdit, intl, orderFemales, orderMales, toggleEdit } = this.props
    const { females, freeChicks, males } = this.state

    return (
      <div className={`form ${inEdit !== true ? 'hidden' : ''}`}>
        <div className="col s6 input">
          <label htmlFor="waitedMales">{intl.formatMessage({ id: 'app.label.waitedMales' })}</label>
          <input name="waitedMales" type="number" value={orderMales} disabled />
        </div>
        <div className="col s6 input">
          <label htmlFor="males">{intl.formatMessage({ id: 'app.label.realMales' })}</label>
          <input name="males" type="number" value={males} onChange={this.handleMalesEdit} />
        </div>
        <div className="col s6 input">
          <label htmlFor="waitedFemales">
            {intl.formatMessage({ id: 'app.label.waitedFemales' })}
          </label>
          <input name="waitedFemales" type="number" value={orderFemales} disabled />
        </div>
        <div className="col s6 input">
          <label htmlFor="females">{intl.formatMessage({ id: 'app.label.realFemales' })}</label>
          <input name="females" type="number" value={females} onChange={this.handleFemalesEdit} />
        </div>
        <div className="divider greyDivider" />
        <div className="col s6 input">
          <label htmlFor="freeChicks">
            {intl.formatMessage({ id: 'app.label.addFreeChicks' })}
          </label>
          <input name="freeChicks" type="number" value={freeChicks} disabled />
        </div>
        <div className="col s4 offset-s4 buttons">
          <button className="btn redBtn" type="button" onClick={toggleEdit}>
            {intl.formatMessage({ id: 'app.label.cancel' })}
          </button>
          <button
            className="btn blueBtn"
            type="button"
            onClick={this.handleSubmit}
            disabled={Number.isNaN(males) || Number.isNaN(females)}
          >
            {intl.formatMessage({ id: 'app.button.validate' })}
          </button>
        </div>
      </div>
    )
  }
}

export default injectIntl(EditInfos)
