module.exports = [
  1000,
  2000,
  2500,
  4000,
  5000,
  6000,
  7500,
  8000,
  10000,
  11000,
  12000,
  13000,
  14000,
  15000
]
