import { FormattedMessage } from 'react-intl'
import React from 'react'
import moment from 'moment'
import HeadSingleHistoryStock from './HeadSingleHistoryStock'
import FridgeBlock from './FridgeBlock'

const DyesDestock = props => {
  const { history, operator, date, type } = props
  const { action: data } = history
  const { dyesBatch, fridge } = data

  return (
    <div className="Container stockHistory">
      <HeadSingleHistoryStock operator={operator} date={date} action={type}>
        <div className="row historyCard">
          <div className="col s12 card">
            <div className="row">
              <div className="col s12">
                <div className="row">
                  <FridgeBlock fridge={fridge} />
                  <div className="row">
                    <h5>
                      <FormattedMessage id="app.label.dyesDestock" />
                    </h5>
                  </div>
                  <div className="col s12 row" key={dyesBatch.batchNumber}>
                    <div className="row">
                      <div className="col s4">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.batchesNumber" />
                            &nbsp;:&nbsp;
                          </span>
                          {dyesBatch.batchNumber}
                        </p>
                      </div>
                      <div className="col s4">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.DyesNumber" />
                            &nbsp;:&nbsp;
                          </span>
                          {data.toOutStock}
                        </p>
                      </div>
                      <div className="col s4">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.expirationDate" />
                            &nbsp;:&nbsp;
                          </span>
                          {moment(dyesBatch.expirationDate).format('DD/MM/YYYY')}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col s4">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.color" />
                            &nbsp;:&nbsp;
                          </span>
                          {dyesBatch.color}
                        </p>
                      </div>
                      <div className="col s4">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.provider" />
                            &nbsp;:&nbsp;
                          </span>
                          {dyesBatch.provider}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col s4">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.totalRemainingForFridgeDyeBatch" />
                            &nbsp;:&nbsp;
                          </span>
                          {dyesBatch.newStorageUnits}
                        </p>
                      </div>
                      <div className="col s4">
                        <p>
                          <span className="label">
                            <FormattedMessage id="app.label.totalRemainingForDyeBatch" />
                            &nbsp;:&nbsp;
                          </span>
                          {dyesBatch.newTotalUnits}
                        </p>
                      </div>
                    </div>
                    {data.reason && (
                      <div className="row">
                        <div className="col s12">
                          <p>
                            <span className="label">
                              <FormattedMessage id="app.label.reason" />
                              &nbsp;:&nbsp;
                            </span>
                            {data.reason}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HeadSingleHistoryStock>
    </div>
  )
}

export default DyesDestock
