export const pBagVolumes = [
  {
    label: '200mL (1000 doses)',
    value: 200,
    doses: 1000
  },
  {
    label: '400mL (2000 doses)',
    value: 400,
    doses: 2000
  },
  {
    label: '800mL (4000 doses)',
    value: 800,
    doses: 4000
  },
  {
    label: '1000mL (5000 doses)',
    value: 1000,
    doses: 5000
  }
]

export const conservationMethods = [
  {
    value: 0
  },
  {
    value: 1
  },
  {
    value: 2
  }
]

export const products = [{ name: 0 }, { name: 1 }, { name: 2 }]
