import React from 'react'
import { injectIntl } from 'react-intl'

const BlockHistoryPart = props => {
  const {
    intl,
    paramId,
    action,
    blockAlertAfter,
    blockAlertBefore,
    blockValueMaxBefore,
    blockValueMinBefore,
    blockValueMinAfter,
    blockValueMaxAfter,
    articleId
  } = props

  if (articleId) {
    const { changes } = action
    const { blockingExpirationAlert } = changes
    return (
      <div className="row">
        <div className="col s6 input">
          <div className="row">
            <div className="col s12">
              <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                {intl.formatMessage({ id: 'app.label.before' })}
              </label>
            </div>
          </div>
          {blockingExpirationAlert[0] && (
            <div className="row">
              <div className="input col s12">
                <label htmlFor="blockValueMinBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.expirationDate' })}
                  &nbsp;:&nbsp;
                </label>
                <span>{blockingExpirationAlert[0] && blockingExpirationAlert[0].value}</span>
              </div>
            </div>
          )}
          {blockingExpirationAlert[0] && (
            <div className="row">
              <div className="col s12">
                <label htmlFor="blockAlertBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;:&nbsp;
                </label>
                <span>{blockingExpirationAlert[0] && blockingExpirationAlert[0].message}</span>
              </div>
            </div>
          )}
        </div>
        <div className="col s6 input">
          <div className="row">
            <div className="col s12">
              <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                {intl.formatMessage({ id: 'app.label.after' })}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="input col s12">
              <label htmlFor="blockValueMinBefore" className="labelParams">
                {intl.formatMessage({ id: 'app.label.expirationDate' })}
                &nbsp;:&nbsp;
              </label>
              <span>{blockingExpirationAlert[1].value}</span>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <label htmlFor="blockAlertAfter" className="labelParams">
                {intl.formatMessage({ id: 'app.label.alert' })}
                &nbsp;:&nbsp;
              </label>
              <span>{blockingExpirationAlert[1].message}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  switch (paramId) {
    case 0:
    case 6:
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.before' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input col s6">
                <label htmlFor="blockValueMinBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.ifLowerTemp' })}
                  &nbsp;:&nbsp;
                </label>
                <span>
                  {` ${blockValueMinBefore || action.blockingAlert.value.min}${intl.formatMessage({
                    id: 'app.unit.fridgeTemp'
                  })}`}
                </span>
              </div>
              <div className="input col s6">
                <label htmlFor="blockValueMax" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.ifHigherTemp' })}
                  &nbsp;:&nbsp;
                </label>
                <span>
                  {`${blockValueMaxBefore || action.blockingAlert.value.max}${intl.formatMessage({
                    id: 'app.unit.fridgeTemp'
                  })}`}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="blockAlertBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;:
                </label>
                <span>{blockAlertBefore || action.blockingAlert.message}</span>
              </div>
            </div>
          </div>
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.after' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input col s6">
                <label htmlFor="blockValueMinBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.ifLowerTemp' })}
                  &nbsp;:&nbsp;
                </label>
                <span>
                  {` ${blockValueMinAfter || action.blockingAlert.value.min}${intl.formatMessage({
                    id: 'app.unit.fridgeTemp'
                  })}`}
                </span>
              </div>
              <div className="input col s6">
                <label htmlFor="blockValueMax" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.ifHigherTemp' })}
                  &nbsp;:&nbsp;
                </label>
                <span>
                  {`${blockValueMaxAfter || action.blockingAlert.value.max}${intl.formatMessage({
                    id: 'app.unit.fridgeTemp'
                  })}`}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="blockAlertAfter" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;:
                </label>
                <span>{blockAlertAfter || action.blockingAlert.message}</span>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.before' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input col s12">
                <label htmlFor="blockValueMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.ifLowerNitrogen' })}
                  &nbsp;:&nbsp;
                </label>
                <span>
                  {`${blockValueMinBefore || action.blockingAlert.value.min} ${intl.formatMessage({
                    id: 'app.unit.nitrogenLevel'
                  })} `}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="blockAlert" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;:&nbsp;
                </label>
                <span>{blockAlertBefore || action.blockingAlert.message}</span>
              </div>
            </div>
          </div>
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.after' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input col s12">
                <label htmlFor="blockValueMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.ifLowerNitrogen' })}
                  &nbsp;:&nbsp;
                </label>
                <span>
                  {`${blockValueMinAfter || action.blockingAlert.value.min} ${intl.formatMessage({
                    id: 'app.unit.nitrogenLevel'
                  })} `}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="blockAlert" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;:&nbsp;
                </label>
                <span>{blockAlertAfter || action.blockingAlert.message}</span>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    case 8:
    case 9:
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.before' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input col s12">
                <label htmlFor="blockValueMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.ifLowerVolume' })}
                  &nbsp;:&nbsp;
                </label>
                <span>{blockValueMinBefore || action.blockingAlert.value.min}</span>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="blockAlert" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;:&nbsp;
                </label>
                <span>{blockAlertBefore || action.blockingAlert.message}</span>
              </div>
            </div>
          </div>
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.after' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input col s12">
                <label htmlFor="blockValueMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.ifLowerVolume' })}
                  &nbsp;:&nbsp;
                </label>
                <span>{blockValueMinAfter || action.blockingAlert.value.min}</span>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="blockAlert" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;:&nbsp;
                </label>
                <span>{blockAlertAfter || action.blockingAlert.message}</span>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    case 7:
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.before' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="blockAlertBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;: &nbsp;
                </label>
                <span>{blockAlertBefore || action.blockingAlert.message}</span>
              </div>
            </div>
          </div>
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.after' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="blockAlertAfter" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;: &nbsp;
                </label>
                <span>{blockAlertAfter || action.blockingAlert.message}</span>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    case 10:
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.before' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="blockAlertBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;: &nbsp;
                </label>
                <span>{blockAlertBefore || action.blockingAlert.message}</span>
              </div>
            </div>
          </div>
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.after' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="blockAlertAfter" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;: &nbsp;
                </label>
                <span>{blockAlertAfter || action.blockingAlert.message}</span>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    default:
      return null
  }
}

export default injectIntl(BlockHistoryPart)
