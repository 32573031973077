import React from 'react'
import { injectIntl } from 'react-intl'

class SwitchUnitField extends React.Component {
  constructor(props) {
    super(props)
    this.state = { show: true }

    this.handleUnitsChange = this.handleUnitsChange.bind(this)
    this.handleAllCheck = this.handleAllCheck.bind(this)
  }

  setUnitsInput(show, units) {
    const { intl } = this.props

    if (show === true) {
      return (
        <div className="col s6 input" id="unitsRow">
          <label htmlFor="units">
            {intl.formatMessage({ id: 'app.label.unitsNumberToOutStock' })}
          </label>
          <input
            id="units"
            name="units"
            type="number"
            max={units}
            min="0"
            onChange={this.handleUnitsChange}
          />
        </div>
      )
    }

    return null
  }

  handleUnitsChange(e) {
    const { setStateToParent } = this.props

    return setStateToParent(e.target.value)
  }

  handleAllCheck(e) {
    const { checked } = e.target
    const { setStateToParent, unitsNumber } = this.props

    if (checked === true) {
      this.setState({ show: false })
      return setStateToParent(unitsNumber)
    }

    this.setState({ show: true })
    return setStateToParent(0)
  }

  render() {
    const { intl, unitsNumber } = this.props
    const { show } = this.state

    return (
      <div className="row">
        {this.setUnitsInput(show, unitsNumber)}
        <div className="switch col s6">
          <p className="label">{intl.formatMessage({ id: 'app.label.allUnitsOutStock' })}</p>
          <label>
            {intl.formatMessage({ id: `app.label.no` })}
            <input type="checkbox" name="all" onChange={this.handleAllCheck} />
            <span className="lever" />
            {intl.formatMessage({ id: `app.label.yes` })}
          </label>
        </div>
      </div>
    )
  }
}

export default injectIntl(SwitchUnitField)
