import Axios from 'axios'
import M from 'materialize-css'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { catchError } from '../../../../actions'
import { api, i18n } from '../../../../parameters'
import { DosesCalculator } from '../../../Calculator'
import LoadingPage from '../../../LoadingPage'

class VaccineInformationsForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      ampoulesNumber: null,
      expirationDate: null,
      batchNumber: null,
      speciality: null,
      vaccineArticle: 'select',
      totalDosesNumber: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeDate = this.handleChangeDate.bind(this)
  }

  componentDidMount() {
    const { index } = this.props
    M.AutoInit()
    M.Datepicker.init(document.getElementById(index), {
      autoClose: true,
      format: 'dd/mm/yyyy',
      selectMonth: true,
      firstDay: 1,
      i18n,
      onSelect: dateSelected => this.handleChangeDate(dateSelected, index)
    })
  }

  componentDidUpdate() {
    const { setDisabled } = this.props
    const { ampoulesNumber, expirationDate, batchNumber, speciality, vaccineArticle } = this.state
    if (
      speciality !== null &&
      vaccineArticle !== null &&
      batchNumber !== null &&
      batchNumber !== undefined &&
      batchNumber !== '' &&
      expirationDate !== undefined &&
      expirationDate !== null &&
      expirationDate !== '' &&
      ampoulesNumber !== null &&
      ampoulesNumber !== '' &&
      ampoulesNumber !== undefined
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  handleChangeDate(dateSelected, index) {
    const { handleChangeDate } = this.props
    this.setState({ expirationDate: moment(dateSelected).format('DD/MM/YYYY') })
    handleChangeDate(dateSelected, index)
  }

  handleChange(e) {
    const { name, value } = e.target
    const { articles, dosesNumber } = this.state
    const { getVaccineInfos, index } = this.props
    switch (name) {
      case 'speciality':
        this.setState(
          {
            [name]: value,
            vaccineArticle: 'select',
            batchNumber: '',
            expirationDate: '',
            ampoulesNumber: '',
            totalDosesNumber: '',
            loading: true
          },
          () => this.loadArticles()
        )
        getVaccineInfos('speciality', parseInt(value), index)
        break
      case 'vaccineArticle':
        articles.forEach(el => {
          if (el.id === parseInt(value)) {
            this.setState({ dosesNumber: el.dosesNumber })
          }
          getVaccineInfos('vaccineArticle', value, index)
        })
        this.setState({ [name]: value })
        break
      case 'ampoulesNumber':
        this.setState(
          { [name]: value, totalDosesNumber: DosesCalculator(value, dosesNumber) },
          () => getVaccineInfos(name, value, index)
        )
        break
      default:
        this.setState({ [name]: value }, () => getVaccineInfos(name, value, index))
    }
  }

  loadArticles() {
    const { token } = this.props
    const { speciality } = this.state
    Axios.get(`${api}/api/vaccine_articles?speciality.id=${speciality}`, {
      headers: { Authorization: token }
    })
      .then(res =>
        this.setState(
          { articles: res.data['hydra:member'], loading: false, vaccineArticle: 'select' },
          () => M.AutoInit()
        )
      )
      .catch(err => catchError(err.response))
  }

  render() {
    const {
      articles,
      loading,
      speciality,
      totalDosesNumber,
      batchNumber,
      expirationDate,
      ampoulesNumber
    } = this.state
    let { vaccineArticle } = this.state
    const { conservation, intl, index, specialities, disabledForm } = this.props

    if (loading === false && articles !== undefined && articles.length === 0) {
      vaccineArticle = 'noArticle'
    }

    return (
      <div className="row">
        <div className="col s12">
          <div className="form nitrogen">
            <div className="row">
              <div className="col s12">
                {loading === false ? (
                  <div className="col s6 input">
                    <label htmlFor="speciality">
                      {intl.formatMessage({ id: 'app.label.speciality' })}
                    </label>
                    <select
                      className="select-wrapper"
                      name="speciality"
                      defaultValue="0"
                      value={speciality}
                      onChange={this.handleChange}
                      disabled={disabledForm[index]}
                    >
                      <option value="0" disabled />
                      {specialities.map(specialityData => (
                        <option key={specialityData.id} value={specialityData.id}>
                          {specialityData.name}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <LoadingPage />
                )}
                <div className="col s6 input">
                  <label htmlFor="vaccineArticle">
                    {intl.formatMessage({ id: 'app.label.article' })}
                  </label>
                  <select
                    className="select-wrapper"
                    name="vaccineArticle"
                    defaultValue={vaccineArticle || 'select'}
                    onChange={this.handleChange}
                    disabled={
                      disabledForm[index] ||
                      speciality === null ||
                      (articles !== undefined && articles.length === 0)
                    }
                  >
                    <option value="select" disabled>
                      {intl.formatMessage({ id: 'app.label.select' })}
                    </option>
                    {(articles === undefined || articles.length === 0) && (
                      <option value="noArticle" disabled>
                        {intl.formatMessage({ id: 'app.label.noArticlesForThisSpeciality' })}
                      </option>
                    )}
                    {articles !== undefined &&
                      articles.map(article => (
                        <option key={article.id} value={article.id}>
                          {article.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col s12">
                <div className="col s3 input">
                  <label htmlFor="batchNumber">
                    {intl.formatMessage({ id: 'app.label.batchesNumber' })}
                  </label>
                  <input
                    htmlFor="batchNumber"
                    type="text"
                    name="batchNumber"
                    value={batchNumber}
                    onChange={this.handleChange}
                    disabled={
                      disabledForm[index] || speciality === null || vaccineArticle === 'noArticle'
                    }
                  />
                </div>
                <div className="col s3 input">
                  <label>{intl.formatMessage({ id: 'app.label.expirationDate' })}</label>
                  <input
                    type="text"
                    id={index}
                    name="expirationDate"
                    value={expirationDate}
                    onChange={this.handleChange}
                    placeholder={intl.formatMessage({ id: 'app.label.dateFormat' })}
                    disabled={
                      disabledForm[index] || speciality === null || vaccineArticle === 'noArticle'
                    }
                  />
                </div>
                <div className="col s3 input">
                  <label htmlFor="ampoulesNumber">
                    {conservation === 1
                      ? intl.formatMessage({ id: 'app.label.vialsTotalNumber' })
                      : intl.formatMessage({ id: 'app.label.totalAmpoulesNumber' })}
                  </label>
                  <input
                    id="ampoulesNumber"
                    type="number"
                    min="0"
                    name="ampoulesNumber"
                    onChange={this.handleChange}
                    value={ampoulesNumber}
                    disabled={
                      disabledForm[index] || speciality === null || vaccineArticle === 'noArticle'
                    }
                  />
                </div>
                <div className="col s3 input">
                  <label htmlFor="dosesNumber">
                    {intl.formatMessage({ id: 'app.label.totalDosesNumber' })}
                  </label>
                  <input
                    id="totalDosesNumber"
                    type="text"
                    name="totalDosesNumber"
                    value={Number.isNaN(totalDosesNumber) ? '' : totalDosesNumber}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(VaccineInformationsForm)
