const distributeVaccinesInOneContainerVaccine = (
  vaccineOrders,
  vaccinesOrdersList,
  dosesContainer
) => {
  let vaccines = {}

  vaccineOrders.forEach(vacc => {
    let dosesTmp = 0
    const neededDoses = dosesContainer * vacc.doseType

    while (dosesTmp < neededDoses && vaccinesOrdersList[vacc.id].length > 0) {
      if (vaccines[vacc.id] === undefined) {
        vaccines[vacc.id] = []
      }

      const idList = []

      vaccines[vacc.id].forEach(vaccine => {
        if (vaccine) {
          idList.push(vaccine.id)
        }
      })

      let i = 0
      let vaccine1
      if (vaccinesOrdersList[vacc.id].length > 0) {
        while (vaccine1 === undefined) {
          const { doses } = vaccinesOrdersList[vacc.id][i]
          if (neededDoses - dosesTmp === doses) {
            vaccine1 = vaccinesOrdersList[vacc.id][i]
            break
          } else if (neededDoses - dosesTmp > doses) {
            vaccine1 = vaccinesOrdersList[vacc.id][i]
            break
          }
          i += 1
        }

        if (vaccine1 && idList.includes(vaccine1.id)) {
          for (let idx = 0; idx < vaccines[vacc.id].length; idx += 1) {
            const vaccine2 = vaccines[vacc.id][idx]

            if (vaccine2.id === vaccine1.id) {
              vaccine2.quantity += 1

              const vaccineRemoved = vaccinesOrdersList[vacc.id].splice(i, 1)

              dosesTmp += vaccineRemoved[0].doses
            }
          }
        } else {
          const vaccineToPush = vaccinesOrdersList[vacc.id].splice(i, 1)

          if (vaccineToPush === undefined) {
            vaccines = false
            break
          }
          vaccines[vacc.id].push(vaccineToPush[0])
          dosesTmp += vaccineToPush[0].doses
        }
      }
    }
  })

  return vaccines
}

export default distributeVaccinesInOneContainerVaccine
