import React from 'react'
import { injectIntl } from 'react-intl'

const FrozenVaccPb = props => {
  const { changes, intl } = props

  return (
    <>
      {changes.map(change => (
        <div key={change.id}>
          <div className="col s6">
            <span className="bold">{intl.formatMessage({ id: 'app.label.oldBatchNumber' })}: </span>
            {change.old}
          </div>
          <div className="col s6">
            <span className="bold">{intl.formatMessage({ id: 'app.label.newBatchNumber' })}: </span>
            {change.new}
          </div>
          {change.oldState?.nitrogenContainer?.containerNumber && (
            <div className="col s6">
              <span className="bold">
                {intl.formatMessage({ id: 'app.label.oldEquipmentId' })}:{' '}
              </span>
              {change.oldState.nitrogenContainer.containerNumber}
            </div>
          )}
          {change.newState?.nitrogenContainer?.containerNumber && (
            <div className="col s6">
              <span className="bold">
                {intl.formatMessage({ id: 'app.label.newEquipmentId' })}:{' '}
              </span>
              {change.newState.nitrogenContainer.containerNumber}
            </div>
          )}
        </div>
      ))}
    </>
  )
}
export default injectIntl(FrozenVaccPb)
