import { FormattedMessage, injectIntl } from 'react-intl'
import React from 'react'
import moment from 'moment'
import HeadSingleHistoryStock from './HeadSingleHistoryStock'
import ReceiptConformity from './ReceiptConformity'

const ContainerReceipt = props => {
  const { history, operator, date, intl, type } = props
  const { action: data } = history

  return (
    <div className="Container stockHistory">
      <HeadSingleHistoryStock operator={operator} date={date} action={type}>
        <div className="row historyCard">
          <div className="col s12 card" key={data.containerNumber}>
            <div className="row">
              <div className="col s12">
                <div className="row">
                  <h5>
                    <FormattedMessage id="app.label.container" />
                  </h5>
                </div>
                <div className="row">
                  <div className="col s6">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.containerId" />
                      </span>
                      {data.containerNumber}
                    </p>
                  </div>
                  <div className="col s6">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.provider" />
                        &nbsp;:&nbsp;
                      </span>
                      {data.provider}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col s4">
                    <span className="label">
                      <FormattedMessage id="app.label.reception_date" />
                      &nbsp;:&nbsp;
                    </span>
                    {moment(data.receptionDate).format('DD/MM/YYYY HH:mm')}
                  </div>
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.containerCapacity" />
                        :&nbsp;
                      </span>
                      {`${data.capacity} ${intl.formatMessage({
                        id: 'app.unit.containerCapacity'
                      })}`}
                    </p>
                  </div>
                  <div className="col s4">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.nitrogenLevel" />
                        &nbsp;:&nbsp;
                      </span>
                      {`${data.nitrogenLevel} ${intl.formatMessage({
                        id: 'app.unit.nitrogenLevel'
                      })}`}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <ReceiptConformity
                    divSize="s4"
                    typeConformity="app.label.containerState"
                    conformity={data.containerState}
                    reason={data.containerNoConformityReason}
                  />
                  <ReceiptConformity
                    divSize="s4"
                    typeConformity="app.label.boxState"
                    conformity={data.boxState}
                    reason={data.boxStateNoConformityReason}
                  />
                  <ReceiptConformity
                    divSize="s4"
                    typeConformity="app.label.disinfected"
                    conformity={data.disinfected}
                    reason={data.disinfectedNoConformityReason}
                  />
                </div>
                <div className="row">
                  <div className="col s6">
                    <p>
                      <span className="label">
                        <FormattedMessage id="app.label.commentary" />
                        &nbsp;:&nbsp;
                      </span>
                      {history.comment}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HeadSingleHistoryStock>
    </div>
  )
}
export default injectIntl(ContainerReceipt)
