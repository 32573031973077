import React from 'react'
import { injectIntl } from 'react-intl'

const CongruentEquipmentHistoryPart = props => {
  const {
    intl,
    paramId,
    congruentPartValueMaxAfter,
    congruentPartValueMaxBefore,
    congruentPartValueMinAfter,
    congruentPartValueMinBefore
  } = props

  switch (paramId) {
    case 0:
    case 6:
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.before' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.temperatureBetween' })}
                  &nbsp;:&nbsp;
                </label>
                <span>
                  {congruentPartValueMinBefore}
                  {`${intl.formatMessage({ id: 'app.unit.fridgeTemp' })} 
                  ${intl.formatMessage({ id: 'app.label.and' })} 
                  ${congruentPartValueMaxBefore}
                  ${intl.formatMessage({ id: 'app.unit.fridgeTemp' })} `}
                </span>
              </div>
            </div>
          </div>
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.after' })}
                </label>
              </div>
            </div>
            <span className="row">
              <div className="input col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.temperatureBetween' })}
                  &nbsp;:&nbsp;
                </label>
                <span>
                  {congruentPartValueMinAfter}
                  {`${intl.formatMessage({ id: 'app.unit.fridgeTemp' })} 
                  ${intl.formatMessage({ id: 'app.label.and' })} 
                  ${congruentPartValueMaxAfter}
                  ${intl.formatMessage({ id: 'app.unit.fridgeTemp' })} `}
                </span>
              </div>
            </span>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.before' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.nitrogenLevelBetween' })}
                  &nbsp;
                </label>
                <span>
                  {congruentPartValueMinBefore}
                  {`${intl.formatMessage({ id: 'app.unit.nitrogenLevel' })} 
                  ${intl.formatMessage({ id: 'app.label.and' })} 
                  ${congruentPartValueMaxBefore}
                  ${intl.formatMessage({ id: 'app.unit.nitrogenLevel' })} `}
                </span>
              </div>
            </div>
          </div>
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.after' })}
                </label>
              </div>
            </div>
            <span className="row">
              <div className="input col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.nitrogenLevelBetween' })}
                  &nbsp;
                </label>
                <span>
                  {congruentPartValueMinAfter}
                  {`${intl.formatMessage({ id: 'app.unit.nitrogenLevel' })} 
                  ${intl.formatMessage({ id: 'app.label.and' })} 
                  ${congruentPartValueMaxAfter}
                  ${intl.formatMessage({ id: 'app.unit.nitrogenLevel' })} `}
                </span>
              </div>
            </span>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    case 7:
    case 8:
    case 9:
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.before' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input col s6">
                <label htmlFor="congruentPartInjectionBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.injectionBetween' })}
                  &nbsp;:&nbsp;
                </label>
                <span>
                  {`${congruentPartValueMinBefore}${intl.formatMessage({
                    id: 'app.unit.needleVolume'
                  })} ${intl.formatMessage({
                    id: 'app.label.and'
                  })} ${congruentPartValueMaxBefore}${intl.formatMessage({
                    id: 'app.unit.needleVolume'
                  })}`}
                </span>
              </div>
            </div>
          </div>
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.after' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input col s6">
                <label htmlFor="congruentPartInjectionBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.injectionBetween' })}
                  &nbsp;:&nbsp;
                </label>
                <span>
                  {`${congruentPartValueMinAfter}${intl.formatMessage({
                    id: 'app.unit.needleVolume'
                  })} ${intl.formatMessage({
                    id: 'app.label.and'
                  })} ${congruentPartValueMaxAfter}${intl.formatMessage({
                    id: 'app.unit.needleVolume'
                  })}`}
                </span>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    default:
      return null
  }
}

export default injectIntl(CongruentEquipmentHistoryPart)
