import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { injectIntl } from 'react-intl'

const Cookies = props => {
  const { intl } = props

  return (
    <CookieConsent
      location="top"
      cookieSecurity
      enableDeclineButton
      overlay
      containerClasses="cookieBar"
      overlayClasses="overlayCookie"
      buttonClasses="btn blueBtn acceptCookieBtn"
      declineButtonClasses="btn redBtn declineCookieBtn"
      buttonText={intl.formatMessage({ id: 'app.cookies.acceptBtnText' })}
      declineButtonText={intl.formatMessage({ id: 'app.cookies.declineBtnText' })}
    >
      {intl.formatMessage({ id: 'app.cookies.barTitle' })}
      <br />
      <small>{intl.formatMessage({ id: 'app.cookies.barMessage' })}</small>
    </CookieConsent>
  )
}

export default injectIntl(Cookies)
