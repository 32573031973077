import Axios from 'axios'
import React from 'react'
import { connect } from 'react-redux'
import { catchError } from '../../../../actions'
import { api } from '../../../../parameters'
import LoadingPage from '../../../LoadingPage'

class TdOperator extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    const { operator, token } = this.props
    Axios.get(`${api}${operator}`, { headers: { Authorization: token } })
      .then(res => {
        this.setState({ operator: res.data.firstname, loading: false })
      })
      .catch(err => catchError(err.response))
  }

  render() {
    const { operator, loading } = this.state
    if (loading) {
      return <LoadingPage />
    }

    return <td>{operator}</td>
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default connect(mapStateToProps)(TdOperator)
