module.exports = {
  dosesLeftListProductBag: [
    {
      doseNumber: 250
    },
    {
      doseNumber: 500
    },
    {
      doseNumber: 750
    },
    {
      doseNumber: 1000
    },
    {
      doseNumber: 1250
    },
    {
      doseNumber: 1500
    },
    {
      doseNumber: 1750
    },
    {
      doseNumber: 2000
    },
    {
      doseNumber: 2500
    },
    {
      doseNumber: 3000
    },
    {
      doseNumber: 3500
    }
  ]
}
