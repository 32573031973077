import React from 'react'
import { FormattedMessage } from 'react-intl'
import HeadSingleHistory from './HeadSingleHistory'

export default function InactivateFridge(props) {
  const { history, operator, date, action, intl } = props

  return (
    <div className="Container equipmentHistory">
      <HeadSingleHistory operator={operator} date={date} action={action}>
        <div className="row">
          <div className="col s4">
            <p>
              <span className="label">
                <FormattedMessage id="app.label.name" />
                &nbsp;:
              </span>
              {` ${history.action.fridgeName}`}
            </p>
          </div>
          <div className="col s4">
            <p>
              <span className="label">
                <FormattedMessage id="app.label.brand" />
                &nbsp;:
              </span>
              {` ${history.action.fridgeBrand}`}
            </p>
          </div>
          <div className="col s4">
            <p>
              <span className="label">
                <FormattedMessage id="app.label.volume" />
                &nbsp;:
              </span>
              {` ${history.action.fridgeVolume} ${intl.formatMessage({
                id: 'app.unit.fridgeVolume'
              })}`}
            </p>
          </div>
        </div>
      </HeadSingleHistory>
    </div>
  )
}
