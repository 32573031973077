import moment from 'moment'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

export default function HeadSingleHistoryStock(props) {
  const { date, operator, action, children } = props
  return (
    <div>
      <div className="pageTitle pageHeader row">
        <div className="col s12">
          <Link to="/stocks/stock-history">
            <div className="flex">
              <div className="goBackBtn">
                <i role="button" tabIndex="0" className="material-icons">
                  chevron_left
                </i>
              </div>
              <h5>
                <FormattedMessage id={`app.label.action.${action}`} />
              </h5>
            </div>
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <div className="row singleHistoryHeader">
            <div className="col m4">
              <p>
                <span>
                  <FormattedMessage id="app.label.operationDate" />
                  &nbsp;:&nbsp;
                </span>
                {moment(date).format('DD/MM/YYYY HH:mm')}
              </p>
            </div>
            <div className="col offset-m4 m4">
              <p>
                <span>
                  <FormattedMessage id="app.label.user" />
                  :&nbsp;
                </span>
                {operator}
              </p>
            </div>
          </div>
          <div className="row historyContent">{children}</div>
        </div>
      </div>
    </div>
  )
}
