import React, { useState, useRef } from 'react'
import { Sketch } from '@uiw/react-color'
import icons from '../assets/icons/icons'
import useOnClickOutside from '../hooks/useOnClickOutside'

const ColorPicker = ({ hex, setHex }) => {
  const [displayPalette, setDisplayPalette] = useState(false)
  const ref = useRef()
  const [innerHex, setInnerHex] = useState(hex)

  useOnClickOutside(ref, () => {
    setDisplayPalette(false)
  })

  return (
    <div style={{ position: 'relative' }} ref={ref}>
      <div
        role="button"
        tabIndex="0"
        onClick={event => {
          event.stopPropagation()
          setDisplayPalette(!displayPalette)
        }}
        onKeyPress={event => {
          event.stopPropagation()
          setDisplayPalette(!displayPalette)
        }}
      >
        <img
          src={icons.palette}
          style={{ width: '24px', height: '24px' }}
          className="pointer"
          alt="palette icon"
        />
      </div>
      {displayPalette && (
        <div
          style={{ position: 'absolute', right: 50, zIndex: 2, top: -15 }}
          className="flex flex-column"
        >
          <Sketch
            color={hex}
            onChange={color => {
              setInnerHex(color.hex)
            }}
          />
          <button
            type="button"
            className="btn blueBtn marginTop20"
            onClick={() => {
              setHex(innerHex)
              setDisplayPalette(false)
            }}
          >
            Valider
          </button>
        </div>
      )}
    </div>
  )
}

export default ColorPicker
