import React, { useState, useEffect, useContext } from 'react'
import Axios from 'axios'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import GenerationsContext from '../../../context/GenerationsContext'
import { ExportFile } from '../../../components/globalAction'

function Single({ token, location, intl, history, role }) {
  const [item, setItem] = useState(null)
  const id = Number(location.pathname.split('/').pop())
  const { getGenerationLabelByKey } = useContext(GenerationsContext)

  useEffect(() => {
    const endPoints = [
      `${api}/api/order_traceabilities?id=${id}`,
      `${api}/api/order_traceability/${id}/certification`
    ]
    Promise.all(
      endPoints.map(endPoint => Axios.get(endPoint, { headers: { Authorization: token } }))
    )
      .then(([{ data: general }, { data: certification }]) => {
        const unknownValue = intl.formatMessage({ id: 'app.label.N/A' })
        // flattenCertification
        const f = certification['hydra:member'].pop()
        setItem(
          [...general['hydra:member']]
            .map(i => ({
              ...i,
              batchNumber: i.currentNumber ?? unknownValue,
              hatchingDate: i.firstOccurrenceDate
                ? moment(i.firstOccurrenceDate).format('DD/MM/YYYY')
                : unknownValue,
              orderNumber: i.currentNumber ?? unknownValue,
              customer: i.clientName ?? unknownValue,
              breeder: i.farmer ?? unknownValue,
              generation:
                i.generation !== null ? getGenerationLabelByKey(i.generation) : unknownValue,
              certification: f?.date ? moment(f.date).format('DD/MM/YYYY HH:mm') : unknownValue,
              operator: f?.operator ?? unknownValue,
              numberOfChicksDelivered: f?.deliveredChicks ?? unknownValue,
              numberOfFreeChicks: f?.freeChicks ?? unknownValue,
              maleNumber: `${f?.maleChicks ?? 0} ${intl.formatMessage({
                id: 'app.label.order.single.male_number'
              })}`,
              detail:
                f?.detail !== undefined
                  ? {
                      vaccinePathInjection: `${f.detail.injection?.realisation ??
                        0} ${intl.formatMessage({
                        id: 'app.label.order.single.outputs'
                      })} ${f.detail.injection?.share ?? 0} ${intl.formatMessage({
                        id: 'app.label.order.single.shared'
                      })}`,
                      vaccinePathThinDrops: `${f.detail.thin?.realisation ??
                        0} ${intl.formatMessage({
                        id: 'app.label.order.single.outputs'
                      })} ${f.detail.thin?.share ?? 0} ${intl.formatMessage({
                        id: 'app.label.order.single.shared'
                      })}`,
                      vaccinePathBigDrops: `${f.detail.big?.realisation ?? 0} ${intl.formatMessage({
                        id: 'app.label.order.single.outputs'
                      })} ${f.detail.big?.share ?? 0} ${intl.formatMessage({
                        id: 'app.label.order.single.shared'
                      })}`
                    }
                  : null,
              femaleNumber: `${f?.femaleChicks ?? 0} ${intl.formatMessage({
                id: 'app.label.order.single.female_number'
              })}`
            }))
            .shift()
        )
      })
      .catch(err => catchError(err))
  }, [])

  const categories = [
    {
      label: 'vaccines',
      path: 'vaccines',
      excludedProductTypes: []
    },
    {
      label: 'products',
      path: 'products',
      excludedProductTypes: []
    },
    {
      label: 'preparations_produced',
      path: 'preparations_produced',
      excludedProductTypes: []
    },
    {
      label: 'share',
      path: 'share',
      excludedProductTypes: []
    },
    {
      label: 'interventions',
      path: 'interventions',
      excludedProductTypes: []
    },
    {
      label: 'request',
      path: 'request',
      excludedProductTypes: []
    },
    {
      label: 'comments',
      path: 'comments',
      excludedProductTypes: []
    }
  ]
  return !item ? (
    <LoadingPage />
  ) : (
    <div className="row Container stockAvailibilityItem traceabilitySingle">
      <div className="col s12 content">
        <div className="row pageHeader pageTitle itemHeader">
          <div
            onClick={() => history.goBack()}
            role="button"
            tabIndex="0"
            onKeyPress={() => history.goBack()}
            className="goBackBtn col s1"
          >
            <i className="material-icons">chevron_left</i>
          </div>
          <h5 className="col s12">
            {item.batchNumber
              ? `${intl.formatMessage({ id: 'app.label.order.single.order' })} ${item.batchNumber}`
              : ''}
          </h5>
          <div className="col m1">
            <ExportFile data={{ role, token }} url={`/api/order_traceability/${id}/export/xlsx`} />
          </div>
        </div>
        <div className="row content">
          <div className="col s5 card">
            <div className="pageTitle">
              <h5>{intl.formatMessage({ id: 'app.label.order.single.order' })}</h5>
            </div>
            <div className="divider greyDivider" />
            <div className="row description col m12">
              <p>
                <span className="label">
                  {intl.formatMessage({
                    id: 'app.label.order.single.hatching_date'
                  })}
                  &nbsp;:&nbsp;
                </span>
                {item.hatchingDate}
              </p>
              <p>
                <span className="label">
                  {intl.formatMessage({
                    id: 'app.label.order.single.order_number'
                  })}
                  &nbsp;:&nbsp;
                </span>
                {item.orderNumber}
              </p>
              <div className="divider greyDivider" />
              <p>
                <span className="label">
                  {intl.formatMessage({ id: 'app.label.order.single.customer' })}
                  &nbsp;:&nbsp;
                </span>
                {item.customer}
              </p>
              <p>
                <span className="label">
                  {intl.formatMessage({
                    id: 'app.label.order.single.breeder'
                  })}
                  &nbsp;:&nbsp;
                </span>
                {item.breeder}
              </p>
              <p>
                <span className="label">
                  {intl.formatMessage({ id: 'app.label.order.single.generation' })}
                  &nbsp;:&nbsp;
                </span>
                {item.generation}
              </p>
              <div className="divider greyDivider" />
              <p>
                <span className="label">
                  {intl.formatMessage({
                    id: 'app.label.order.single.certification'
                  })}
                  &nbsp;:&nbsp;
                </span>
                {item.certification}
              </p>
              <p>
                <span className="label">
                  {intl.formatMessage({
                    id: 'app.label.order.single.operator'
                  })}
                  &nbsp;:&nbsp;
                </span>
                {item.operator}
              </p>
            </div>
          </div>
          <div className="col s5 offset-s1 card">
            <div className="pageTitle">
              <h5>{intl.formatMessage({ id: 'app.label.order.single.vaccination' })}</h5>
            </div>
            <div className="divider greyDivider" />
            <div className="row description col m12">
              <p>
                <span className="label">
                  {intl.formatMessage({
                    id: 'app.label.order.single.number_of_chicks_delivered'
                  })}
                  &nbsp;:&nbsp;
                </span>
                {item.numberOfChicksDelivered}
              </p>
              <p>
                <span className="label">
                  {intl.formatMessage({
                    id: 'app.label.order.single.number_of_free_chicks'
                  })}
                  &nbsp;:&nbsp;
                </span>
                {item.numberOfFreeChicks}
              </p>
              <div className="divider greyDivider" />
              <p>
                <span className="label">{item.maleNumber}</span>
              </p>
              <p>
                <span className="label">{item.femaleNumber}</span>
              </p>
              <div className="divider greyDivider" />
              {item.detail !== null && (
                <>
                  <p>
                    <span className="label">
                      {intl.formatMessage({
                        id: 'app.label.order.single.vaccine_path_injection'
                      })}
                      &nbsp;:&nbsp;
                    </span>
                    {item.detail.vaccinePathInjection}
                  </p>
                  <p>
                    <span className="label">
                      {intl.formatMessage({
                        id: 'app.label.order.single.vaccine_path_thin_drops'
                      })}
                      &nbsp;:&nbsp;
                    </span>
                    {item.detail.vaccinePathThinDrops}
                  </p>
                  <p>
                    <span className="label">
                      {intl.formatMessage({
                        id: 'app.label.order.single.vaccine_path_big_drops'
                      })}
                      &nbsp;:&nbsp;
                    </span>
                    {item.detail.vaccinePathBigDrops}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <table className="table">
          <tbody>
            {categories.map((category, index) => (
              <tr
                key={index}
                onClick={() => {
                  history.push({
                    pathname: `/traceability/order/single/${id}/${category.path}`
                  })
                }}
                className="pointer"
              >
                <td className="label">
                  {intl.formatMessage({
                    id: `app.label.order.single.${category.label}`
                  })}
                </td>
                <td>
                  <i className="material-icons arrow-right">keyboard_arrow_right</i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(Single)))
