import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { catchError } from '../../actions'
import Header from '../../components/History/Header'
import HistoryList from '../../components/History/HistoryList/HistoryList'
import LoadingPage from '../../components/LoadingPage'
import { api } from '../../parameters'

class History extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contentStatus: false,
      filters: [
        { category: 'orderDate', sortBy: ['asc', 'desc'] },
        {
          category: 'type',
          translationPrefix: 'vaccinationType',
          sortBy: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
        },
        {
          category: 'orderFirstName',
          sortBy: ['asc', 'desc']
        }
      ],
      histories: [],
      loading: true,
      url: `${api}/api/vaccination_histories`
    }
    this.inLoad = this.inLoad.bind(this)
    this.filterByDate = this.filterByDate.bind(this)
    this.getStateFromChild = this.getStateFromChild.bind(this)
  }

  componentDidMount() {
    const { token } = this.props
    const { url, filters } = this.state

    Axios.get(`${api}/api/users`, { headers: { Authorization: token } })
      .then(res => {
        const tableOperatorId = []
        res.data['hydra:member'].forEach((operator, index) => {
          tableOperatorId[index] = [operator.id, operator.firstname]
        })
        filters.push({
          category: 'operator',
          sortBy: tableOperatorId,
          toTranslate: false,
          operator: true
        })
        this.setState({ filters })
      })
      .catch(err => catchError(err.response))

    Axios.get(`${url}?orderDate=desc`, {
      headers: { Authorization: token }
    })
      .then(res =>
        this.setState({
          histories: res.data['hydra:member'],
          totalItems: res.data['hydra:totalItems'],
          loading: false
        })
      )
      .catch(err => catchError(err.response))
  }

  getStateFromChild(data, infiniteLoader = false) {
    let { histories, contentStatus } = this.state
    if (infiniteLoader) {
      histories = [...histories, ...data['hydra:member']]
    } else {
      histories = data['hydra:member']
    }

    if (histories.length >= data['hydra:totalItems']) {
      contentStatus = 'noMoreResult'
    }
    this.setState({
      histories,
      loading: false,
      contentStatus
    })
  }

  filterByDate(date) {
    const { token } = this.props

    const dateToApi = moment(date).format('YYYY-MM-DD')

    const url = `${api}/api/vaccination_histories?dateFilter=${dateToApi}`

    Axios.get(url, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({
          loading: false,
          histories: res.data['hydra:member']
        })
      })
      .catch(err => catchError(err))
  }

  inLoad(contentStatus) {
    this.setState({ contentStatus })
  }

  render() {
    const { histories, loading, url, contentStatus, filters, totalItems } = this.state

    if (loading === true) {
      return <LoadingPage />
    }

    return (
      <div className="Container row">
        <div className="col m12 l12">
          <Header
            url={url}
            filters={filters}
            totalItems={totalItems}
            filterByDate={this.filterByDate}
            inLoad={this.inLoad}
            getStateFromChild={this.getStateFromChild}
          />
          <HistoryList histories={histories} contentStatus={contentStatus} />
        </div>
      </div>
    )
  }
}

export default History
