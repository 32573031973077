import moment from 'moment'
import QRCode from 'qrcode.react'
import React from 'react'
import { injectIntl } from 'react-intl'
import { ProductsForOrderContext } from '../../../../../../../context/contexts'

class TagToPrint extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  setGroup(order) {
    const { planningOrder } = order
    if (planningOrder.injectionGroup !== null) {
      return planningOrder.injectionGroup
    }

    if (planningOrder.thinDropsGroup !== null) {
      return planningOrder.thinDropsGroup
    }

    if (planningOrder.bigDropsGroup !== null) {
      return planningOrder.bigDropsGroup
    }

    return null
  }

  static contextType = ProductsForOrderContext

  render() {
    const { intl, action, toPrint, sequence } = this.props
    const { preparation, orders } = action
    const { getProductForOrderLabelByKey } = this.context

    return (
      <div id="tag" className={toPrint === true ? 'toPrint' : ''}>
        <section className="row" id="date">
          <p className="col s1">
            <span className="tagNumber">{sequence || preparation.sequence}</span>
          </p>
          <p className="col s5">
            <span className="bold">{intl.formatMessage({ id: 'app.label.preparationDate' })}</span>
            <br /> {moment(preparation.firstScannedAt).format('DD/MM/YYYY HH:mm')}
          </p>
          <p className="col s5">
            <span className="bold">
              {intl.formatMessage({ id: 'app.label.preparationPrevisionalEndDate' })}
            </span>
            <br /> {moment(preparation.expirationDate).format('DD/MM/YYYY HH:mm')}
          </p>
        </section>
        <section className="row" id="vaccine">
          <p className="col s12">
            {preparation.vaccines.map((vaccine, i) => (
              <>
                <span key={vaccine.id} className="bold">
                  {vaccine.speciality}
                </span>{' '}
                {intl.formatMessage({ id: `app.label.doses.${vaccine.doseType}` })}{' '}
                {i === preparation.vaccines.length - 1 ? '' : '+ '}
              </>
            ))}
          </p>
        </section>
        {orders && (
          <section className="row" id="ordersQR">
            <div id="orders" className="col s6">
              {Object.entries(orders).map(([key, order]) => {
                const orderArray = !Array.isArray(order) ? [order] : order
                return orderArray.map(o => {
                  const group = this.setGroup(o)
                  return (
                    <section key={key} className="order">
                      <p>
                        <span className="bold">
                          {intl.formatMessage({ id: 'app.label.orderNumber' })}
                        </span>{' '}
                        {group.number}
                      </p>
                      <p>
                        <span className="bold">
                          {intl.formatMessage({ id: 'app.label.product' })}
                        </span>{' '}
                        {getProductForOrderLabelByKey(group.product)}
                      </p>
                      <p>
                        <span className="bold">
                          {intl.formatMessage({ id: 'app.label.client' })}
                        </span>{' '}
                        {group.clientName}
                      </p>
                    </section>
                  )
                })
              })}
            </div>
            <section className="col s6 center" id="qr">
              <div id="qr-code">{preparation.qr}</div>
              <QRCode value={preparation.qr} size={toPrint ? 130 : 260} renderAs="svg" />
            </section>
          </section>
        )}
      </div>
    )
  }
}

export default injectIntl(TagToPrint)
