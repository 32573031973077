import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { catchError } from '../../../../../actions'
import icons from '../../../../../assets/icons/icons'
import { api } from '../../../../../parameters'
import { validatedAction } from '../../../../globalAction'
import { renderTemperature } from '../../../../globalRenderActions'
import StatePill from '../../../../StatePill'

class FridgeControlItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(e) {
    const { value, name } = e.target

    this.setState({ [name]: parseFloat(value) })
  }

  handleSubmit(e) {
    e.preventDefault()
    const { fridge, loadFridges, token } = this.props
    const { minTemperature, maxTemperature, instantTemperature } = this.state

    const data = {
      control: {
        fridge: fridge.id,
        minTemperature,
        maxTemperature,
        instantTemperature
      }
    }

    Axios.post(`${api}/api/fridges/controls`, data, {
      headers: { Authorization: token }
    })
      .then(() => {
        loadFridges()
        validatedAction('', 0, '#fridge')
      })
      .catch(err => catchError(err.response, false))
  }

  render() {
    const { intl, fridge, loadControls } = this.props

    return (
      <div className="col m6">
        <div className="card">
          <div className="CardEquipmentTop">
            <img src={icons.fridge} alt="container icon" className="CardEquipmentTopIcon" />
            <div className="CardEquipmentTopTitle">
              <h5>{fridge.name}</h5>
            </div>
            <i
              role="button"
              tabIndex="0"
              className="material-icons CardEquipmentTopControls modal-trigger"
              href="#modal1"
              onClick={() => loadControls(fridge)}
              onKeyPress={() => loadControls(fridge)}
            >
              <img src={icons.blueHourglass} alt="hourglass icon" />
            </i>
            {fridge.lastControl.valid === false && <img src={icons.redDanger} alt="not valid" />}
          </div>
          <div className="divider greyDivider" />
          <div className="CardEquipmentControls">
            <p>
              <b>{intl.formatMessage({ id: 'app.equipment.lastControl' })}</b>
              {` ${moment(fridge.lastControl.createdAt).format('DD/MM/YYYY')}`}
            </p>
            <p>
              <b>{intl.formatMessage({ id: 'app.equipment.previousMinT' })}</b>
              &nbsp;{renderTemperature(fridge.lastControl.minTemperature, intl)}&nbsp;
              <StatePill type="FridgeMinTemperature" data={fridge} />
            </p>
            <p>
              <b>{intl.formatMessage({ id: 'app.equipment.previousMaxT' })}</b>
              &nbsp;{renderTemperature(fridge.lastControl.maxTemperature, intl)}&nbsp;
              <StatePill type="FridgeMaxTemperature" data={fridge} />
            </p>
            <p>
              <b>{intl.formatMessage({ id: 'app.equipment.previousInstantT' })}</b>
              &nbsp;{renderTemperature(fridge.lastControl.instantTemperature, intl)}
              &nbsp;
              <StatePill type="FridgeInstantTemperature" data={fridge} />
            </p>
          </div>
          <div className="divider greyDivider" />
          {fridge.alert && <div className="CardEquipmentAlert bottomBorder" />}
          <form className="CardEquipmentTopInput form row" onSubmit={this.handleSubmit}>
            <div className="input col m6">
              <label htmlFor="minTemperature">
                {intl.formatMessage({ id: 'app.equipment.lastControl.minTemperature' })}
              </label>
              <input
                id="minTemperature"
                name="minTemperature"
                type="number"
                step="0.1"
                onChange={e => this.handleChange(e)}
              />
            </div>
            <div className="input col m6">
              <label htmlFor="maxTemperature">
                {intl.formatMessage({ id: 'app.equipment.lastControl.maxTemperature' })}
              </label>
              <input
                id="maxTemperature"
                name="maxTemperature"
                type="number"
                step="0.1"
                onChange={e => this.handleChange(e)}
              />
            </div>
            <div className="input col m12">
              <label htmlFor="instantTemperature">
                {intl.formatMessage({ id: 'app.equipment.lastControl.instantTemperature' })}
              </label>
              <input
                id="instantTemperature"
                name="instantTemperature"
                type="number"
                step="0.1"
                onChange={e => this.handleChange(e)}
              />
            </div>
            <div className="row">
              <div className="col offset-m4 m4 center">
                <input type="submit" className="btn blueBtn" value="Valider" />
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(FridgeControlItem)))
