import React, { useContext } from 'react'
import { injectIntl } from 'react-intl'
import ValencesContext from '../../../../context/ValencesContext'

const TableIncompatibilities = props => {
  const { incompatibilities, intl } = props
  const { getValenceLabelByKey } = useContext(ValencesContext)
  if (incompatibilities.length === 0) {
    return (
      <div className="row VaccinationContainer endRealisation">
        <div className="col s12">
          <div className="custom-card flex flex-center noPreparation">
            {intl.formatMessage({ id: 'app.message.noIncompatibilities' })}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="row">
      <p className="associatedVaccines">
        {intl.formatMessage({ id: 'app.label.stockIncompability' })}
      </p>
      <table className="table">
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: `app.label.speciality` })}</th>
            <th>{intl.formatMessage({ id: `app.label.valence` })}</th>
            <th>{intl.formatMessage({ id: `app.label.conservation` })}</th>
            <th>{intl.formatMessage({ id: `app.label.administration` })}</th>
          </tr>
        </thead>
        <tbody>
          {incompatibilities.map(incompatibility => (
            <tr key={incompatibility.name}>
              <td>{incompatibility.name}</td>
              <td>{getValenceLabelByKey(incompatibility.valence)}</td>
              <td>
                {intl.formatMessage({
                  id: `app.label.conservationMethod.${incompatibility.conservationMethod}`
                })}
              </td>
              <td>
                {intl.formatMessage({
                  id: `app.label.administrationMethod.${incompatibility.administrationMethod}`
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default injectIntl(TableIncompatibilities)
