import { combineReducers } from 'redux'
import authReducer from './auth_reducer'
import mercureReducer from './mercure_reducer'
import realisationReducer from './realisation_reducer'

const rootReducer = combineReducers({
  auth: authReducer,
  mercure: mercureReducer,
  realisation: realisationReducer
})

export default rootReducer
