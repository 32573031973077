import React from 'react'
import { injectIntl } from 'react-intl'
import DocumentItem from './partials/DocumentItem'

const DocumentList = props => {
  const { intl, documents } = props

  if (documents.length === 0) {
    return (
      <div className="row dailyRContainer">
        <div className="col s12">
          <div className="custom-card">{intl.formatMessage({ id: 'app.message.noDocument' })}</div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="row">
        <div className="col m12">
          {documents.map((doc, i) => (
            <DocumentItem key={doc.id} doc={doc} i={i} />
          ))}
        </div>
      </div>
    </>
  )
}

export default injectIntl(DocumentList)
