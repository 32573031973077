import React from 'react'
import { injectIntl } from 'react-intl'
import icons from '../../../../../../../assets/icons/icons'

class PreparationsToReuseItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleChange = this.handleChange.bind(this)
    this.toogleDosesLeftForTotal = this.toogleDosesLeftForTotal.bind(this)
  }

  toogleDosesLeftForTotal(action, prep) {
    const { setTotal } = this.props
    let { total } = this.props

    if (action === 1) {
      total += prep.dosesLeft
    } else if (action === 2) {
      total -= prep.dosesLeft
    }

    setTotal(total)
  }

  handleChange(e) {
    const { checked } = e.target
    const { prep, reusePreparations, getDataFromChild } = this.props

    if (checked) {
      const index = reusePreparations.indexOf(prep.id)
      reusePreparations.splice(index, 1)
      this.toogleDosesLeftForTotal(1, prep)
    } else {
      reusePreparations.push(prep.id)
      this.toogleDosesLeftForTotal(2, prep)
    }
    getDataFromChild('reusePreparations', reusePreparations)
  }

  render() {
    const { intl, prep } = this.props

    return (
      <div className="row card custom-card">
        <div className="row card-content">
          <div className="row card-block">
            <div className="flex">
              <div className="flex col s6">
                <img
                  className="card-logo marginRight15 space5pixel"
                  src={prep.productBag ? icons.productBag : icons.bottle}
                  alt={prep.productBag ? 'logo productBag' : 'logo bottle'}
                />
                <span className="labelCustom">{prep.qr}</span>{' '}
              </div>
              <div className="col s6">
                <div>
                  {prep.orderOrderPreparations[0].planningOrder.vaccines.map((vaccine, i) => (
                    <>
                      <span key={vaccine.id} className="labelCustom">
                        {vaccine.speciality.name}
                      </span>{' '}
                      {intl.formatMessage({ id: `app.label.doses.${vaccine.doseType}` })}{' '}
                      {i === prep.orderOrderPreparations[0].planningOrder.vaccines.length - 1
                        ? ''
                        : '+ '}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
          <div className="row card-block">
            <div className="col s12">
              <label>
                <input
                  className="filled-in checkbox"
                  type="checkbox"
                  name="dontUse"
                  onClick={this.handleChange}
                />
                <span className="labelCustom">
                  {intl.formatMessage({ id: 'app.label.dontUse' })}
                </span>
              </label>
            </div>
          </div>
          <div className="divider greyDivider" />
          <div className="card-block">
            {prep.productBag && (
              <div className="col s4">
                <span className="labelCustom">
                  {intl.formatMessage({ id: 'app.label.batchNumber' })}
                </span>{' '}
                {prep.productBag.batchNumber}
              </div>
            )}
            <div className="col s4">
              <span className="labelCustom">
                {intl.formatMessage({ id: 'app.label.doseLeftNumber' })}
              </span>{' '}
              {prep.dosesLeft}
            </div>
            <div className="col s4">
              <span className="labelCustom">
                {intl.formatMessage({ id: 'app.label.totalDosesNumber' })}
              </span>{' '}
              {prep.productBag ? prep.productBag.doses : prep.bottle}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(PreparationsToReuseItem)
