import React from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

const ClientInfos = props => {
  const { client, intl } = props

  return (
    <div id="clientInfos" className="customCard">
      <div className="customCardHeader">
        {intl.formatMessage({ id: 'app.certificate.client' })} {client.name}
      </div>
      <div className="customCardContent row">
        <div className="col s6">
          <div className="bold">{intl.formatMessage({ id: 'app.certificate.farmerName' })}</div>
          <div>{client.farmer}</div>
        </div>
        <div className="col s6">
          <div className="bold">
            {intl.formatMessage({ id: 'app.certificate.shippingAddress' })}
          </div>
          <div>{client.shippingAddress}</div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(ClientInfos)

ClientInfos.propTypes = {
  client: PropTypes.shape({
    address: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
}
