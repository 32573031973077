import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { catchError } from '../../../../../actions'
import { api } from '../../../../../parameters'

class FridgeInformationForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fridgeInformations: {
        lastControl: { minTemperature: '', maxTemperature: '', instantTemperature: '' }
      }
    }
    this.handleChange = this.handleChange.bind(this)
    this.getFridgeInformations = this.getFridgeInformations.bind(this)
  }

  componentDidMount() {
    M.AutoInit()
  }

  getFridgeInformations(id) {
    const { token, setDataToParent } = this.props
    Axios.get(`${api}/api/fridges/${id}`, { headers: { Authorization: token } })
      .then(res => this.setState({ fridgeInformations: res.data }, () => setDataToParent('id', id)))
      .catch(err => catchError(err.response))
  }

  handleChange(e) {
    const { name, value } = e.target
    const { setDataToParent } = this.props
    if (name === 'fridge') {
      return this.getFridgeInformations(value)
    }
    return this.setState({ [name]: value }, () => setDataToParent(name, value))
  }

  render() {
    const { intl, fridgesList, fridgesSelected } = this.props
    const { fridgeInformations } = this.state
    const { lastControl } = fridgeInformations
    return (
      <div className="row">
        <div className="col s12">
          <div className="form refrigeratedVaccine">
            <div className="row">
              <div className="col s12">
                <div className="col s3 input">
                  <label htmlFor="fridge">{intl.formatMessage({ id: 'app.label.fridge' })}</label>
                  <select
                    className="select-wrapper"
                    name="fridge"
                    defaultValue="0"
                    onChange={this.handleChange}
                  >
                    <option value="0" disabled />
                    {fridgesList.map(fridge =>
                      !fridgesSelected.includes(fridge.id) ? (
                        <option key={fridge.id} value={fridge.id}>
                          {fridge.name}
                        </option>
                      ) : (
                        <option key={fridge.id} value={fridge.id} disabled>
                          {fridge.name}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className="col s3 input">
                  <label>
                    {intl.formatMessage({ id: 'app.equipment.lastControl.minTemperature' })}
                  </label>
                  <input type="number" step="0.1" value={lastControl.minTemperature} disabled />
                </div>
                <div className="col s3 input">
                  <label>
                    {intl.formatMessage({ id: 'app.equipment.lastControl.maxTemperature' })}
                  </label>
                  <input type="number" step="0.1" value={lastControl.maxTemperature} disabled />
                </div>
                <div className="col s3 input">
                  <label>
                    {intl.formatMessage({ id: 'app.equipment.lastControl.instantTemperature' })}
                  </label>
                  <input type="number" step="0.1" value={lastControl.instantTemperature} disabled />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(FridgeInformationForm)
