import M from 'materialize-css'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { pBagVolumes } from '../../../../../config/productConfig'
import { providers } from '../../../../../parameters'
import DatepickerBlock from '../../../../DatepickerBlock'

class ProductBagInformationForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedProvider: '',
      batchNumber: '',
      volume: '',
      dateSelected: '',
      units: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.selectProvider = this.selectProvider.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.getDate = this.getDate.bind(this)
  }

  componentDidMount() {
    M.FormSelect.init(document.querySelectorAll('select'))
  }

  getDate(name, value) {
    const { index, getDataFromChild } = this.props
    this.setState({ [name]: value }, () => {
      this.validateForm()
      getDataFromChild('expirationDate', moment(value).format(), index)
    })
  }

  selectProvider(e) {
    const { value } = e.target
    const { index, getDataFromChild } = this.props
    const selectedProvider = providers[value]
    this.setState({ selectedProvider }, () => {
      this.validateForm()
      M.FormSelect.init(document.querySelectorAll('select'), {})
    })
    getDataFromChild('provider', selectedProvider.name, index)
  }

  validateForm() {
    const { index, setDisabled } = this.props
    const { selectedProvider, batchNumber, dateSelected, units, volume } = this.state

    const disabled =
      selectedProvider === '' ||
      batchNumber === '' ||
      volume === '' ||
      dateSelected === '' ||
      units === ''

    setDisabled(disabled, index)
  }

  handleChange(e) {
    const { index, getDataFromChild } = this.props
    const { name, value } = e.target

    if (name === 'volume') {
      return this.setState({ [name]: value }, () => {
        let dosesTmp

        pBagVolumes.forEach(volume => {
          const { doses } = volume
          if (volume.value === parseInt(value)) {
            dosesTmp = doses
          }
        })

        this.validateForm()
        getDataFromChild(name, value, index)
        getDataFromChild('doses', dosesTmp, index)
      })
    }
    return this.setState({ [name]: value }, () => {
      this.validateForm()
      getDataFromChild(name, value, index)
    })
  }

  render() {
    const { intl, index } = this.props
    const { selectedProvider } = this.state
    return (
      <div className="row col s12">
        <div className="col s12 form">
          <div className="row">
            <div className="col s6 input">
              <label htmlFor="provider">{intl.formatMessage({ id: 'app.label.provider' })}</label>
              <select
                className="select-wrapper"
                defaultValue="0"
                name="provider"
                id="provider"
                onChange={this.selectProvider}
              >
                <option value="0" disabled>
                  {intl.formatMessage({ id: 'app.label.selectProvider' })}
                </option>
                {providers.map((provider, i) => (
                  <option value={i} key={provider.name}>
                    {provider.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col s3 input">
              <label htmlFor="batchNumber">
                {intl.formatMessage({ id: 'app.label.batchesNumber' })}
              </label>
              <input type="text" id="batchNumber" name="batchNumber" onChange={this.handleChange} />
            </div>
            <div className="col s3 input">
              <div>
                <label htmlFor="volume">{intl.formatMessage({ id: 'app.label.volume' })}</label>
                <select
                  className="select-wrapper"
                  id="volume"
                  defaultValue="0"
                  name="volume"
                  onChange={this.handleChange}
                  disabled={!selectedProvider}
                >
                  <option value="0" disabled>
                    {intl.formatMessage({ id: 'app.label.selectVolume' })}
                  </option>
                  {pBagVolumes.map(volume => (
                    <option value={volume.value} key={volume.value}>
                      {volume.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col s6 input">
              <DatepickerBlock
                setDataFromChild={this.getDate}
                name={index}
                label={intl.formatMessage({ id: 'app.label.expirationDate' })}
              />
            </div>
            <div className="col s6 input">
              <label htmlFor="units">{intl.formatMessage({ id: 'app.label.unities' })}</label>
              <input type="number" min={0} id="units" name="units" onChange={this.handleChange} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(ProductBagInformationForm)
