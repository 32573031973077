import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { catchError } from '../../actions'
import icons from '../../assets/icons/icons'
import AsyncLoader from '../../components/AsyncLoader'
import Filters from '../../components/Filters/Filters'
import LoadingPage from '../../components/LoadingPage'
import { api } from '../../parameters'
import ValenceContext from '../../context/ValencesContext'

class Incompatibilities extends React.Component {
  constructor() {
    super()
    this.state = {
      contentStatus: false,
      specialities: [],
      url: `${api}/api/vaccine_specialities`,
      filters: [
        { category: 'order[name]', sortBy: ['asc', 'desc'], catTranslationPrefix: 'speciality' },
        {
          category: 'valence',
          sortBy: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
          translationPrefix: 'valence'
        },
        {
          category: 'conservationMethod',
          sortBy: [0, 1],
          translationPrefix: 'conservationMethod'
        },
        {
          category: 'administrationMethod',
          sortBy: [0, 1, 2],
          translationPrefix: 'administrationMethod'
        }
      ],
      loading: true
    }
    this.getStateFromChild = this.getStateFromChild.bind(this)
    this.inLoad = this.inLoad.bind(this)
    this.clickToEdit = this.clickToEdit.bind(this)
  }

  componentDidMount() {
    const { token } = this.props
    Axios.get(`${api}/api/vaccine_specialities`, {
      headers: {
        Authorization: token
      }
    })
      .then(res => {
        this.setState({
          specialities: res.data['hydra:member'],
          totalItems: res.data['hydra:totalItems'],
          contentStatus: res.data['hydra:totalItems'] > 30 ? false : ' noMoreResult',
          loading: false
        })
      })
      .catch(err => catchError(err.response))
  }

  getStateFromChild(data, infiniteLoader = false) {
    let { specialities, contentStatus } = this.state
    if (infiniteLoader) {
      specialities = [...specialities, ...data['hydra:member']]
    } else {
      specialities = data['hydra:member']
    }

    if (specialities.length >= data['hydra:totalItems']) {
      contentStatus = 'noMoreResult'
    }
    this.setState({
      specialities,
      loading: false,
      contentStatus
    })
  }

  static contextType = ValenceContext

  inLoad(contentStatus) {
    this.setState({ contentStatus })
  }

  clickToEdit(id) {
    const { history } = this.props
    history.push(`/parameters/incompatibilities/vet/edit/${id}`)
  }

  render() {
    const { intl, token, role } = this.props
    const { contentStatus, specialities, url, filters, loading, totalItems } = this.state
    const { getValenceLabelByKey } = this.context

    if (loading) return <LoadingPage />

    return (
      <div className="row Container params">
        <div className="col s12 content marginTop30">
          <div className="row">
            <div className="flex col s12">
              <div>
                <Filters
                  token={token}
                  setStateToParent={this.getStateFromChild}
                  url={url}
                  role={role}
                  totalItems={totalItems}
                  sortBy="asc"
                  infiniteLoader
                  sortCategory="order[name]"
                  filters={filters}
                  inLoad={this.inLoad}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <table className="table width100">
                <thead>
                  <tr>
                    <th>{intl.formatMessage({ id: 'app.label.speciality' })} </th>
                    <th>{intl.formatMessage({ id: 'app.label.articles' })} </th>
                    <th>{intl.formatMessage({ id: 'app.label.valence' })} </th>
                    <th>{intl.formatMessage({ id: 'app.label.conservation' })} </th>
                    <th>{intl.formatMessage({ id: 'app.label.administration' })} </th>
                    <th>{intl.formatMessage({ id: 'app.label.incompatibilities' })} </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {specialities.map(speciality => (
                    <tr key={`speciality-${speciality.id}`}>
                      <td>{speciality.name}</td>
                      <td>
                        {speciality.vaccineArticles &&
                          speciality.vaccineArticles.map(vaccineArticle => (
                            <div
                              className="noPadding col s12"
                              key={`article-${vaccineArticle.dosesNumber}`}
                            >
                              {vaccineArticle.name}
                            </div>
                          ))}
                      </td>
                      <td>{getValenceLabelByKey(speciality.valence)}</td>
                      <td>
                        {intl.formatMessage({
                          id: `app.label.conservationMethod.${speciality.conservationMethod}`
                        })}
                      </td>
                      <td>
                        {intl.formatMessage({
                          id: `app.administrationMethod.${speciality.administrationMethod}`
                        })}
                      </td>
                      <td>
                        <span className="noPadding col s12">
                          {speciality.incompatibilities &&
                            speciality.incompatibilities.blocking.map(incompatibility => (
                              <div className="noPadding col s12" key={incompatibility.id}>
                                <i className="material-icons pill state-3 noPadding">
                                  brightness_1
                                </i>
                                {incompatibility.name}
                              </div>
                            ))}
                          {speciality.incompatibilities &&
                            speciality.incompatibilities.nonBlocking.map(incompatibility => (
                              <div className="noPadding col s12" key={incompatibility.id}>
                                <i className="material-icons pill state-2 noPadding">
                                  brightness_1
                                </i>
                                {incompatibility.name}
                              </div>
                            ))}
                        </span>
                      </td>
                      <td>
                        <span
                          role="button"
                          tabIndex={0}
                          onKeyDown={() => this.clickToEdit(speciality.id)}
                          className="pointer col s1"
                          onClick={() => this.clickToEdit(speciality.id)}
                        >
                          <img src={icons.blackPen} alt="black pen icon" />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot className="center">
                  <tr>
                    <th colSpan="12">
                      <AsyncLoader status={contentStatus} />
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default withRouter(injectIntl(connect(mapStateToProps)(Incompatibilities)))
