import arrowWhiteRight from './arrow_white_right.svg'
import arrowWhiteLeft from './arrow_white_left.svg'
import bigDropVaccine from './big_drop_vaccine.svg'
import blackBell from './black_bell.svg'
import blackPen from './black_pen.svg'
import blackStock from './stock-black.svg'
import blockingAlert from './blocking_alert.svg'
import blueArrow from './blue_arrow.svg'
import blueArrowBottom from './blue_arrow_bottom.svg'
import blueArrowTop from './blue_arrow_top.svg'
import blueBell from './blue_bell.svg'
import blueCalendar from './blue_calendar.svg'
import blueCheck from './blue_check.svg'
import blueChick from './blue_chick.svg'
import blueDocumentation from './blue_documentation.svg'
import blueDropdownArrow from './blue_dropdown_arrow.svg'
import blueEquipment from './blue_equipment.svg'
import blueGrip from './blue_grip.svg'
import blueHistory from './blue_history.svg'
import blueHourglass from './blue_hourglass.svg'
import blueHouse from './blue_house.svg'
import blueImport from './blue_import.svg'
import blueInfo from './blue_info.svg'
import blueLogout from './blue_logout.svg'
import blueParam from './blue_param.svg'
import bluePenCircle from './blue_pen_circle.svg'
import blueRightArrow from './blue_right_arrow.svg'
import blueStock from './stock-blue.svg'
import blueSuccess from './blue_success.svg'
import blueUser from './blue_user.svg'
import blueTraceability from './blue_traceability.svg'
import bottle from './bottle.svg'
import blueWater from './blueWater.svg'
import chick from './chick.svg'
import container from './container.svg'
import containerNitrogenTransfer from './container_nitrogen_transfer.svg'
import containerReserve from './containerReserve.svg'
import containerUseEnd from './container_use_end.svg'
import containerVaccineTransfer from './container_vaccine_transfer.svg'
import containersList from './containers_list.svg'
import diluent from './diluent.svg'
import dye from './dye.svg'
import endRealisation from './end-realisation.svg'
import fridge from './fridge.svg'
import fridgeAdd from './fridge_add.svg'
import fridgeProductsTransfer from './fridge_products_transfer.svg'
import fridgesList from './fridges_list.svg'
import frozenVaccine from './frozenVaccine.svg'
import greenCheck from './green_check.svg'
import greenSuccess from './green_success.svg'
import greyCalendar from './grey_calendar.svg'
import injectedVaccine from './injected_vaccine.svg'
import moreActionBlack from './more_action_black.svg'
import moreActionWhite from './more_action_white.svg'
import nitrogen from './nitrogen.svg'
import nonBlockingAlert from './non_blocking_alert.svg'
import orangeBorderWarning from './orange_border_danger.svg'
import preparation from './preparation.svg'
import productBag from './productBag.svg'
import realisation from './realisation.svg'
import redDanger from './red_danger.svg'
import redDustbin from './red-dustbin.svg'
import refresh from './refresh.svg'
import refrigeratedVaccine from './refrigeratedVaccine.svg'
import thinDropVaccine from './thin_drop_vaccine.svg'
import validationCertification from './validation_certification.svg'
import whiteBell from './bell_white.svg'
import whiteCalendar from './white_calendar.svg'
import whiteCheck from './white_check.svg'
import whiteChick from './white_chick.svg'
import whiteDocFile from './white_doc_file.svg'
import whiteDocumentation from './white_documentation.svg'
import whiteDragAndDrop from './white_drag_and_drop.svg'
import whiteEquipment from './white_equipment.svg'
import whiteExcelFile from './white_excel_file.svg'
import whiteExport from './white_export.svg'
import whiteHistory from './white_history.svg'
import whiteImport from './white_import.svg'
import whiteParam from './white_param.svg'
import whitePdfFile from './white_pdf_file.svg'
import whitePlus from './white_plus.svg'
import whiteStock from './stock-white.svg'
import whiteUser from './white_user.svg'
import whiteVideoFile from './white_video_file.svg'
import whiteTraceability from './white_traceability.svg'
import yellowCalendar from './yellow_calendar.svg'
import yellowCheck from './yellow_check.svg'
import yellowChick from './yellow_chick.svg'
import yellowDocumentation from './yellow_documentation.svg'
import yellowEquipment from './yellow_equipment.svg'
import yellowHistory from './yellow_history.svg'
import yellowParam from './yellow_param.svg'
import yellowStock from './stock-yellow.svg'
import yellowUser from './yellow_user.svg'
import yellowTraceability from './yellow_traceability.svg'
import palette from './palette.svg'
import whiteDelete from './white_delete.svg'

export default {
  arrowWhiteRight,
  arrowWhiteLeft,
  bigDropVaccine,
  blackBell,
  blackPen,
  blackStock,
  blockingAlert,
  blueArrow,
  blueArrowBottom,
  blueArrowTop,
  blueBell,
  blueCalendar,
  blueCheck,
  blueChick,
  blueDocumentation,
  blueDropdownArrow,
  blueEquipment,
  blueGrip,
  blueHistory,
  blueHourglass,
  blueHouse,
  blueImport,
  blueInfo,
  blueLogout,
  blueParam,
  bluePenCircle,
  blueRightArrow,
  blueStock,
  blueSuccess,
  blueUser,
  blueTraceability,
  bottle,
  blueWater,
  chick,
  container,
  containerNitrogenTransfer,
  containerUseEnd,
  containerVaccineTransfer,
  containerReserve,
  containersList,
  diluent,
  dye,
  endRealisation,
  fridge,
  fridgeAdd,
  fridgeProductsTransfer,
  fridgesList,
  frozenVaccine,
  greenCheck,
  greenSuccess,
  greyCalendar,
  injectedVaccine,
  moreActionBlack,
  moreActionWhite,
  nitrogen,
  nonBlockingAlert,
  orangeBorderWarning,
  realisation,
  redDanger,
  redDustbin,
  refresh,
  preparation,
  productBag,
  refrigeratedVaccine,
  thinDropVaccine,
  validationCertification,
  whiteBell,
  whiteCalendar,
  whiteCheck,
  whiteChick,
  whiteDocFile,
  whiteDocumentation,
  whiteDragAndDrop,
  whiteEquipment,
  whiteExcelFile,
  whiteExport,
  whiteHistory,
  whiteImport,
  whiteParam,
  whitePdfFile,
  whitePlus,
  whiteStock,
  whiteUser,
  whiteVideoFile,
  whiteTraceability,
  yellowCalendar,
  yellowCheck,
  yellowChick,
  yellowDocumentation,
  yellowEquipment,
  yellowHistory,
  yellowParam,
  yellowStock,
  yellowUser,
  yellowTraceability,
  palette,
  whiteDelete
}
