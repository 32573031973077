import React from 'react'

const RightButton = props => {
  const { text, noRow, onClick, disabled } = props
  return (
    <div className={noRow === undefined ? 'row' : ''}>
      <div className="flex">
        <div className="col s12 flex flexRight">
          <button type="button" className="btn blueBtn" onClick={onClick} disabled={disabled}>
            {text}
          </button>
        </div>
      </div>
    </div>
  )
}

export default RightButton
