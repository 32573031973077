import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { catchError } from '../../../../../actions'
import icons from '../../../../../assets/icons/icons'
import { api } from '../../../../../parameters'
import { validatedAction } from '../../../../globalAction'
import { renderNitrogenLevel } from '../../../../globalRenderActions'
import StatePill from '../../../../StatePill'

class ContainerControlItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(e) {
    const { name, value } = e.target
    this.setState({ [name]: parseInt(value) })
  }

  handleSubmit(e) {
    e.preventDefault()
    const { container, loadContainers, token } = this.props
    const { nitrogenLevel } = this.state

    const data = {
      control: {
        nitrogenContainer: container.id,
        nitrogenLevel
      }
    }

    Axios.post(`${api}/api/nitrogen_containers/controls`, data, {
      headers: { Authorization: token }
    })
      .then(() => {
        loadContainers()
        validatedAction('', 0, '#containers')
      })
      .catch(err => catchError(err.response, false))
  }

  render() {
    const { intl, container, loadControls } = this.props
    return (
      <div className="col m6">
        <div className="card">
          <div className="CardEquipmentTop">
            <img src={icons.container} alt="container icon" className="CardEquipmentTopIcon" />
            <div className="CardEquipmentTopTitle">
              <h5>Container {container.containerNumber}</h5>
              <p>{intl.formatMessage({ id: `app.container.${container.type}` })} </p>
            </div>
            <div
              role="button"
              tabIndex="0"
              className="material-icons CardEquipmentTopControls modal-trigger"
              href="#modal1"
              onClick={() => loadControls(container)}
              onKeyPress={() => loadControls(container)}
            >
              <img src={icons.blueHourglass} alt="hourglass icon" />
            </div>
          </div>
          <div className="divider greyDivider" />
          <div className="CardEquipmentControls">
            <p>
              <span className="cardLabel">
                {intl.formatMessage({ id: 'app.label.lastControlDate' })}
              </span>
              {` ${moment(container.lastControl.createdAt).format('DD/MM/YYYY')}`}
            </p>
            <p>
              <span className="cardLabel">
                {intl.formatMessage({ id: 'app.label.prevNitrogenLevel' })}
              </span>
              &nbsp;{renderNitrogenLevel(container.lastControl.nitrogenLevel, intl)}&nbsp;
              <StatePill type="ContainerNitrogenLevel" data={container} />
            </p>
          </div>
          <div className="divider greyDivider" />
          {container.alert && <div className="CardEquipmentAlert bottomBorder" />}
          <form className="form" onSubmit={this.handleSubmit}>
            <div className="input">
              <label htmlFor="nitrogenLevel" className="label">
                {intl.formatMessage({ id: 'app.equipment.control.nitrogenLevel' })}
              </label>
              <input type="number" step="0.1" name="nitrogenLevel" onChange={this.handleChange} />
            </div>
            <div className="row">
              <div className="col offset-m4 m4 center">
                <button type="submit" className="btn blueBtn">
                  {intl.formatMessage({ id: 'app.button.validate' })}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(ContainerControlItem))
