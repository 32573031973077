import React from 'react'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import DatepickerBlock from '../../../DatepickerBlock'
import TimePickerBlock from '../../../TimePickerBlock'

class ReturnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      generalState: true,
      filledDeliveryForm: true,
      returnedInOriginBox: true
    }

    this.handleCheck = this.handleCheck.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  handleCheck(e) {
    const { name, checked } = e.target
    const { getDataFromChild } = this.props

    if (checked === true) {
      const comment = `${name}Comment`

      getDataFromChild(comment, '')
    }

    return this.setState({ [name]: checked }, () => getDataFromChild(name, checked))
  }

  handleChange(e) {
    const { name, value } = e.target
    const { getDataFromChild } = this.props

    this.setState({ [name]: value })

    return getDataFromChild(name, value)
  }

  handleDateChange(name, value) {
    const { getDataFromChild } = this.props

    this.setState({ [name]: value })
    return getDataFromChild(name, value)
  }

  render() {
    const { intl } = this.props
    const {
      generalState,
      filledDeliveryForm,
      returnedInOriginBox,
      askedDate,
      lastControl,
      controlHour,
      controlMinutes
    } = this.state

    return (
      <div className="returnContainerForm">
        <div className="row">
          <div className="col m6">
            <p> {intl.formatMessage({ id: `app.label.generalState` })}</p>
            <div className="switch">
              <label htmlFor="genState">
                {intl.formatMessage({ id: `app.label.no` })}
                <input
                  id="genState"
                  type="checkbox"
                  name="generalState"
                  onChange={this.handleCheck}
                  checked={generalState}
                />
                <span className="lever" />
                {intl.formatMessage({ id: `app.label.yes` })}
              </label>
            </div>
            {generalState === false && (
              <div className="row col s10">
                <div className="input">
                  <label className="label" htmlFor="generalStateComment">
                    {intl.formatMessage({ id: 'app.label.commentary' })}
                  </label>
                  <textarea
                    id="commentary"
                    className="materialize-textarea"
                    name="generalStateComment"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="col m6">
            <p> {intl.formatMessage({ id: `app.label.originCarton` })}</p>
            <div className="switch">
              <label htmlFor="filledDeliv">
                {intl.formatMessage({ id: `app.label.no` })}
                <input
                  id="filledDeliv"
                  type="checkbox"
                  name="filledDeliveryForm"
                  onChange={this.handleCheck}
                  checked={filledDeliveryForm}
                />
                <span className="lever" />
                {intl.formatMessage({ id: `app.label.yes` })}
              </label>
            </div>
            {filledDeliveryForm === false && (
              <div className="row col s10">
                <div className="input">
                  <label className="label" htmlFor="filledDeliveryFormComment">
                    {intl.formatMessage({ id: 'app.label.commentary' })}
                  </label>
                  <textarea
                    id="commentary"
                    className="materialize-textarea"
                    name="filledDeliveryFormComment"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col m6">
            <p> {intl.formatMessage({ id: `app.label.formOrder` })}</p>
            <div className="switch">
              <label htmlFor="origBox">
                {intl.formatMessage({ id: `app.label.no` })}
                <input
                  id="origBox"
                  type="checkbox"
                  name="returnedInOriginBox"
                  onChange={this.handleCheck}
                  checked={returnedInOriginBox}
                />
                <span className="lever" />
                {intl.formatMessage({ id: `app.label.yes` })}
              </label>
            </div>
            {returnedInOriginBox === false && (
              <div className="row col s10">
                <div className="input">
                  <label className="label" htmlFor="returnedInOriginBoxComment">
                    {intl.formatMessage({ id: 'app.label.commentary' })}
                  </label>
                  <textarea
                    id="commentary"
                    className="materialize-textarea"
                    name="returnedInOriginBoxComment"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col m4">
            <DatepickerBlock
              setDataFromChild={this.handleDateChange}
              name="askedDate"
              value={askedDate && moment(askedDate).format('DD/MM/YYYY')}
              label={intl.formatMessage({ id: 'app.label.requestDate' })}
            />
          </div>
          <div className="col m4">
            <DatepickerBlock
              setDataFromChild={this.handleDateChange}
              name="lastControl"
              value={lastControl && moment(lastControl).format('DD/MM/YYYY')}
              defaultValue
              label={intl.formatMessage({ id: 'app.label.controlDate' })}
            />
          </div>
          <div className="col m4">
            <TimePickerBlock
              setDataToParent={this.handleDateChange}
              defaultValue
              hourValue={controlHour}
              minutesValue={controlMinutes}
              label="controlHour"
            />
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(ReturnContainer)
