import React from 'react'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import CardHeader from './partials/CardHeader'

const NoHatchPeriodAdd = props => {
  const { hist, intl } = props
  const { action: data } = hist.history

  return (
    <div className="vaccinationHistory">
      <div className="row historyCard">
        <CardHeader date={hist.createdAt} operator={hist.firstName} />
        <div className="col s12 card" key={data.containerNumber}>
          <div className="row line">
            <p className="col s6">
              <span className="bold">{intl.formatMessage({ id: 'app.label.startDate' })}: </span>{' '}
              {moment(data.start).format('DD/MM/YYYY')}
            </p>
            <p className="col s6">
              <span className="bold">{intl.formatMessage({ id: 'app.label.endDate' })}: </span>{' '}
              {moment(data.end).format('DD/MM/YYYY')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default injectIntl(NoHatchPeriodAdd)
