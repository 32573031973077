import React from 'react'
import { injectIntl } from 'react-intl'
import HeaderCanPart from './partials/HeaderCanPart'
import SinglePartRadios from '../SinglePartRadios'

class CanPart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.getDataFromChild = this.getDataFromChild.bind(this)
  }

  getDataFromChild(name, value) {
    if (name === 'batchesVaccinated') {
      this.setState({ [name]: parseInt(value) })
    } else {
      this.setState({ [name]: value })
    }
  }

  render() {
    const { prep, prepId } = this.props

    return (
      <>
        <HeaderCanPart prep={prep} />
        <SinglePartRadios container="bottle" prepId={prepId} />
      </>
    )
  }
}

export default injectIntl(CanPart)
