import React from 'react'
import { injectIntl } from 'react-intl'
import icons from '../../../../../assets/icons/icons'
import ReceivedVaccineNitrogen from './ReceivedVaccineNitrogen'
import ReceivedVaccineOnly from './ReceivedVaccineOnly'

class ContainerInformations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      receptionType: 0
    }
    this.changeType = this.changeType.bind(this)
  }

  getContent() {
    const { receptionType } = this.state
    const {
      intl,
      isOther,
      handleChange,
      changePart,
      setDataToParent,
      setDisabled,
      disabled,
      history
    } = this.props
    switch (receptionType) {
      case 1:
        return (
          <ReceivedVaccineNitrogen
            setDataToParent={setDataToParent}
            intl={intl}
            isOther={isOther}
            changePart={changePart}
            disabled={disabled}
            historyData={history}
          />
        )
      case 2:
        return (
          <ReceivedVaccineOnly
            handleChange={handleChange}
            intl={intl}
            changePart={changePart}
            setDataToParent={setDataToParent}
            setDisabled={setDisabled}
            disabled={disabled}
          />
        )
      default:
        return false
    }
  }

  changeType(e) {
    const { value } = e.target
    const { setDataToParent } = this.props
    const receptionType = parseInt(value)
    this.setState({ receptionType }, () => setDataToParent('receptionType', receptionType, false))
  }

  render() {
    const { receptionType } = this.state
    const { intl } = this.props
    return (
      <div className="row">
        <div className="col s12">
          <div className="row">
            <div className="col s12">
              <div className="form">
                <div className="row">
                  <div className="col s12">
                    <p className="col s6 radio">
                      <label htmlFor="receptionType1">
                        <input
                          name="receptionType"
                          value="1"
                          id="receptionType1"
                          type="radio"
                          onClick={this.changeType}
                        />
                        <span>
                          {intl.formatMessage({ id: 'app.label.container&VaccineReception' })}
                        </span>
                      </label>
                    </p>
                    <p className="col s6 radio">
                      <label htmlFor="receptionType2">
                        <input
                          name="receptionType"
                          value="2"
                          id="receptionType2"
                          type="radio"
                          onClick={this.changeType}
                        />
                        <span>{intl.formatMessage({ id: 'app.label.onlyVaccineReception' })}</span>
                      </label>
                    </p>
                  </div>
                </div>
                {receptionType ? (
                  <div className="row">
                    <div className="col s12 redMsg">
                      <img src={icons.redDanger} alt="red danger icon" />
                      <p className="red-text">
                        {intl.formatMessage({ id: 'app.part.nitrogenTransfer.text2' })}
                      </p>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="row hidden">
                  <div className="col s12">Alert</div>
                </div>
              </div>
            </div>
          </div>
          {this.getContent()}
        </div>
      </div>
    )
  }
}

export default injectIntl(ContainerInformations)
