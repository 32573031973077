import { AUTH_USER, AUTH_ERROR, LOGOUT } from '../actions/types'

const INITIAL_STATE = {
  authenticated: false,
  role: [],
  username: '',
  email: '',
  token: '',
  error: ''
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        authenticated: true,
        role: action.role,
        username: action.username,
        email: action.email,
        token: action.token
      }
    case LOGOUT:
      return {
        authenticated: false,
        error: action.error,
        role: [],
        token: '',
        username: '',
        email: ''
      }
    case AUTH_ERROR:
      return {
        ...state,
        error: action.payload
      }
    default:
      break
  }
  return state
}
