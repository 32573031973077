import React from 'react'
import { injectIntl } from 'react-intl'
import THead from './partials/THead'
import Tr from './partials/Tr'

const PrepsTable = props => {
  const { vaccines } = props

  return (
    <table className="prepsTable table width100">
      <THead />
      <tbody>
        {Object.keys(vaccines).map(number => (
          <Tr key={number} number={number} quantity={vaccines[number].units} />
        ))}
      </tbody>
    </table>
  )
}

export default injectIntl(PrepsTable)
