import React from 'react'
import { injectIntl } from 'react-intl'
import icons from '../../assets/icons/icons'

const DragAndDropBtn = props => {
  const { onClick } = props

  return (
    <div
      role="button"
      className="btnExport space5pixel"
      onClick={onClick}
      tabIndex="0"
      onKeyPress={onClick}
    >
      <img src={icons.whiteDragAndDrop} alt="drag and drop logo" />
    </div>
  )
}

export default injectIntl(DragAndDropBtn)
