import React, { useContext } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { PrestationsContext, ProductsForOrderContext } from '../../../../../context/contexts'

const ChicksInfos = props => {
  const { females, intl, males } = props
  const { getPrestationLabelByKey } = useContext(PrestationsContext)
  const { getProductForOrderLabelByKey } = useContext(ProductsForOrderContext)
  let title = ''
  let chicks

  if (females !== undefined) {
    chicks = females
    title = 'females'
  }

  if (males !== undefined) {
    chicks = males
    title = 'males'
  }

  return (
    <div className="customCard chicksInfos">
      <div className="customCardHeader">
        {intl.formatMessage({ id: `app.certificate.${title}` })}
      </div>
      <div className="customCardContent row">
        <div className="col s6">
          <span className="bold">
            {intl.formatMessage({ id: `app.certificate.orderNumber` })} :
          </span>{' '}
          {chicks.orderNumber}
        </div>
        <div className="col s6">
          <span className="bold">
            {intl.formatMessage({ id: `app.certificate.chicksNumber` })} :
          </span>{' '}
          {chicks.chicksNumber}
        </div>
        <div className="col s6">
          <span className="bold">
            {intl.formatMessage({ id: `app.certificate.boxesNumber` })} :
          </span>{' '}
          {chicks.boxesNumber} {intl.formatMessage({ id: `app.certificate.boxesOf` })}{' '}
          {chicks.chicksPerBox} {intl.formatMessage({ id: `app.certificate.chicks` })}
        </div>
        <div className="col s6">
          <span className="bold">{intl.formatMessage({ id: `app.certificate.product` })} :</span>{' '}
          {chicks.products.map(
            (product, i) =>
              `${getProductForOrderLabelByKey(product)}${
                i !== chicks.products.length - 1 ? ' + ' : ''
              }`
          )}
        </div>
        <div className="col s12">
          <span className="bold">
            {intl.formatMessage({ id: `app.certificate.realisedPrestations` })} :
          </span>{' '}
          {chicks.prestations.map(
            (prestation, i) =>
              `${getPrestationLabelByKey(prestation)}${
                i !== chicks.prestations.length - 1 ? ' + ' : ''
              }`
          )}
        </div>
      </div>
    </div>
  )
}

export default injectIntl(ChicksInfos)

ChicksInfos.propTypes = {
  females: PropTypes.shape({
    boxesNumber: PropTypes.number.isRequired,
    chicksNumber: PropTypes.number.isRequired,
    orderNumber: PropTypes.string.isRequired,
    prestations: PropTypes.arrayOf(PropTypes.number),
    products: PropTypes.arrayOf(PropTypes.number)
  }),
  males: PropTypes.shape({
    boxesNumber: PropTypes.number.isRequired,
    chicksNumber: PropTypes.number.isRequired,
    orderNumber: PropTypes.string.isRequired,
    prestations: PropTypes.arrayOf(PropTypes.number),
    products: PropTypes.arrayOf(PropTypes.number)
  })
}

ChicksInfos.defaultProps = {
  females: undefined,
  males: undefined
}
