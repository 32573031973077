import React from 'react'
import { Link } from 'react-router-dom'

function TdLink({ children, url }) {
  return (
    <td style={{ height: '50px', padding: '0px' }}>
      <Link
        style={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          display: 'flex'
        }}
        to={url}
      >
        <div style={{ padding: '5px' }}>{children}</div>
      </Link>
    </td>
  )
}

export default TdLink
