import React from 'react'
import { injectIntl } from 'react-intl'

const CongruentEquipmentPart = props => {
  const { intl, paramId, congruentPartValueMax, congruentPartValueMin, handleChange } = props

  switch (paramId) {
    case 0:
    case 6:
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="input col s6">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.temperatureBetweenIncluded' })}
                  &nbsp;
                </label>
                <input
                  name="congruentPartValueMin"
                  type="number"
                  step="0.1"
                  value={congruentPartValueMin || ''}
                  onChange={handleChange}
                />
              </div>
              <div className="input col s6">
                <div className="labelParams">&nbsp;</div>
                <input
                  name="congruentPartValueMax"
                  type="number"
                  step="0.1"
                  value={congruentPartValueMax || ''}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="input col s6">
                <label htmlFor="congruentPartValueMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.ifNitrogenLevelBetweenIncluded' })}
                  &nbsp;
                </label>
                <input
                  name="congruentPartValueMin"
                  type="number"
                  value={congruentPartValueMin || ''}
                  onChange={handleChange}
                />
              </div>
              <div className="col s6">
                <div className="labelParams">&nbsp;</div>
              </div>
              <div className="col s6">
                <input
                  type="number"
                  value={congruentPartValueMax || ''}
                  name="congruentPartValueMax"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    case 7:
    case 8:
    case 9:
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="input col s6">
                <label htmlFor="congruentPartInjectionBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.injectionBetweenIncluded' })}
                  &nbsp;
                </label>
                <input
                  name="congruentPartValueMin"
                  type="number"
                  step="0.01"
                  value={congruentPartValueMin || ''}
                  onChange={handleChange}
                />
              </div>
              <div className="input col s6">
                <div className="labelParams">&nbsp;</div>
                <input
                  name="congruentPartValueMax"
                  type="number"
                  step="0.01"
                  value={congruentPartValueMax || ''}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    default:
      return null
  }
}

export default injectIntl(CongruentEquipmentPart)
