import React, { createContext, useState, useEffect } from 'react'
import Axios from 'axios'
import Swal from 'sweetalert2'
import { api } from '../parameters'
import store from '../store'
import { validatedAction } from '../components/globalAction'

const PlanningContext = createContext({
  ordersList: [],
  setOrdersList: () => '',
  updateColor: () => '',
  deleteOrder: () => ''
})

export const PlanningProvider = ({ children }) => {
  const [ordersList, setOrdersList] = useState([])

  useEffect(() => {
    setOrdersList([])
  }, [])

  function updateColor(orderId, color) {
    const { token } = store.getState().auth
    if (token) {
      Axios.patch(
        `${api}/api/planning_order_groups/${orderId}/color`,
        { color },
        {
          headers: { Authorization: token }
        }
      ).then(() => {
        if (ordersList) {
          setOrdersList(
            ordersList.map(orderFromList => {
              if (orderFromList.id === orderId) {
                return {
                  ...orderFromList,
                  color
                }
              }
              return orderFromList
            })
          )
        }
      })
    }
  }

  function deleteOrder(subOrderId, intl) {
    Swal.fire({
      title: intl.formatMessage({ id: 'app.label.areYouSureDeleteOrder' }),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({ id: 'app.label.true' }),
      cancelButtonText: intl.formatMessage({ id: 'app.label.false' }),
      customClass: {
        icon: 'swalWarningIcon',
        title: 'swalTitle',
        content: 'swalContentText',
        confirmButton: 'redBtn',
        cancelButton: 'blueBtn'
      }
    }).then(() => {
      const { token } = store.getState().auth
      if (token) {
        Axios.delete(`${api}/api/planning_order/${subOrderId}`, {
          headers: { Authorization: token }
        }).then(() => {
          validatedAction('', 0)
        })
      }
    })
  }

  return (
    <PlanningContext.Provider
      value={{
        ordersList,
        setOrdersList,
        updateColor,
        deleteOrder
      }}
    >
      {children}
    </PlanningContext.Provider>
  )
}

export default PlanningContext
