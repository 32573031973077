import React from 'react'

export default function NitrogenTransferBlock(props) {
  const {
    id,
    type,
    data,
    allContainers,
    i,
    selectContainer,
    addNitrogenLevel,
    selectedContainer,
    intl
  } = props
  return (
    <div key={id}>
      <div className="row">
        <div className="col input m4">
          <label htmlFor={i} className="active">
            {intl.formatMessage({ id: `app.label.${type}Number` })}
          </label>
          <select
            className="select-wrapper"
            defaultValue={data.id || 'select'}
            onChange={e => selectContainer(i, type, e)}
          >
            <option value="select" disabled>
              {intl.formatMessage({ id: 'app.label.select' })}{' '}
            </option>
            {allContainers.map(container =>
              !selectedContainer.includes(container.id) ? (
                <option key={container.id} value={container.id}>
                  {`Container ${container.containerNumber}`}
                </option>
              ) : (
                <option key={container.id} value={container.id} disabled>
                  {`Container ${container.containerNumber}`}
                </option>
              )
            )}
          </select>
        </div>
        <div className="col input m3">
          <label className="active" htmlFor="nitrogenLvl">
            {intl.formatMessage({ id: 'app.label.nitrogenLevel' })}
          </label>
          <input
            id="nitrogenLvl"
            type="number"
            step="0.1"
            value={data.nitrogenLevel || 0}
            disabled
          />
        </div>
        <div className="col input m5">
          <label className="active" htmlFor="nitrogenLvlAfter">
            {intl.formatMessage({ id: 'app.label.nitrogenLevelAfterTransfer' })}
          </label>
          <input
            id="nitrogenLvlAfter"
            type="number"
            step="0.1"
            min="0"
            max={data.nitrogenLevel}
            onChange={e => addNitrogenLevel(i, type, e)}
          />
        </div>
      </div>
    </div>
  )
}
