export const AUTH_USER = 'auth_user'
export const UNAUTH_USER = 'unauth_user'
export const AUTH_ERROR = 'auth_error'
export const LOGOUT = 'logout'

export const NOTIF_ADD = 'notif_add'
export const NOTIF_LIST = 'notif_list'
export const NOTIF_REMOVE = 'notif_remove'

export const INSTANT_NOTIF_ADD = 'instant_notif_add'
export const INSTANT_NOTIF_REMOVE = 'instant_notif_remove'

export const NEXT_PAGE = 'next_page'
export const PREVIOUS_PAGE = 'previous_page'
export const TARGET_PAGE = 'target_page'
