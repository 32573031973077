import Axios from 'axios'
import M from 'materialize-css'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { catchError } from '../../../../../actions'
import dyeColors from '../../../../../config/dyeColors'
import { api } from '../../../../../parameters'

class DyeSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dyeSelect: '',
      batcheNumber: '',
      flaskQuantity: NaN
    }
    this.handleChange = this.handleChange.bind(this)
    this.getDyeBatchNumbers = this.getDyeBatchNumbers.bind(this)
    this.batchesNumbersRef = React.createRef()
  }

  componentDidMount() {
    M.AutoInit()
  }

  getDyeBatchNumbers(dyeName) {
    const { token, dyes, disabledButton, index, getDataFromChild } = this.props
    this.setState({
      batcheNumber: '',
      flaskQuantity: NaN
    })
    disabledButton[index] = true
    getDataFromChild('disabledButton', disabledButton)
    Axios.get(`${api}/api/fridge_dyes?dyeBatch.color=${dyeName}`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const resData = res.data['hydra:member']
        if (resData.length > 0) {
          dyes[index].id = resData[0].id
          this.setState({
            batchesNumbers: resData,
            expirationDate: resData[0].dyeBatch.expirationDate,
            batcheNumber: resData[0].id
          })
        } else {
          this.setState({
            batchesNumbers: '',
            expirationDate: ''
          })
        }
        M.FormSelect.init(document.getElementsByName(this.batchesNumbersRef.current.name))
      })
      .catch(err => catchError(err.response))
  }

  handleChange(e) {
    const { name, value } = e.target
    const {
      getDataFromChildFromGrandParent,
      getDataFromChild,
      dyes,
      index,
      disabledButton
    } = this.props
    const { dyeSelect, batcheNumber, flaskQuantity } = this.state

    const parseIntValue = parseInt(value)
    this.setState({ [name]: value })

    if (name === 'dyeSelect') {
      this.getDyeBatchNumbers(value)
    } else if (name === 'batcheNumber') {
      dyes[index].id = parseIntValue
      if (dyeSelect && value && !Number.isNaN(flaskQuantity)) {
        disabledButton[index] = false
      } else {
        disabledButton[index] = true
      }
    } else if (name === 'flaskQuantity') {
      dyes[index].quantity = parseIntValue
      if (dyeSelect && batcheNumber && !Number.isNaN(parseIntValue)) {
        disabledButton[index] = false
      } else {
        disabledButton[index] = true
      }
    }

    getDataFromChildFromGrandParent('disabledButton', disabledButton)

    getDataFromChild('dyes', dyes)
  }

  render() {
    const { intl, disabledBlock } = this.props
    const { batchesNumbers, expirationDate, flaskQuantity } = this.state

    return (
      <div className="row">
        <div className="input col m3">
          <label htmlFor="dyeSelect">{intl.formatMessage({ id: `app.label.color` })}</label>
          <select
            className="select-wrapper"
            name="dyeSelect"
            defaultValue="0"
            onChange={this.handleChange}
            disabled={disabledBlock}
          >
            <option value="0" disabled>
              {intl.formatMessage({ id: 'app.label.selectDye' })}
            </option>
            {dyeColors.all.map(dyeColor => (
              <option key={dyeColor.value} value={dyeColor.value}>
                {dyeColor.value}
              </option>
            ))}
          </select>
        </div>
        <div className="input col m3">
          <label htmlFor="chickVaccinated">
            {intl.formatMessage({ id: `app.label.batchNumber` })}
          </label>
          <select
            className="select-wrapper"
            ref={this.batchesNumbersRef}
            name="batcheNumber"
            type="number"
            onChange={this.handleChange}
            disabled={disabledBlock}
          >
            {batchesNumbers &&
              batchesNumbers.map(batchNumber => (
                <option key={batchNumber.id} value={batchNumber.id}>
                  {batchNumber.fridge.name} - {batchNumber.dyeBatch.batchNumber}
                </option>
              ))}
          </select>
        </div>
        <div className="input col m3">
          <label htmlFor="expirationDate">
            {intl.formatMessage({ id: `app.label.expirationDate` })}
          </label>
          <input
            name="expirationDate"
            type="text"
            value={expirationDate ? moment(expirationDate).format('DD/MM/YYYY') : ''}
            disabled
          />
        </div>
        <div className="input col m3">
          <label htmlFor="flaskQuantity">
            {intl.formatMessage({ id: `app.label.flaskQuantity` })}
          </label>
          <input
            name="flaskQuantity"
            type="number"
            value={flaskQuantity}
            onChange={this.handleChange}
            disabled={disabledBlock}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(DyeSelect))
