import React from 'react'
import { injectIntl } from 'react-intl'
import icons from '../../../../../../../assets/icons/icons'

class AddWaterBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      water: props.water
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const { setDisabledScanBtn, water } = this.props

    if (water === undefined || water === 0 || water.length === 0) {
      setDisabledScanBtn(true)
    }
  }

  handleChange(e) {
    const { name, value } = e.target
    const { getDataFromChild, setDisabledScanBtn } = this.props

    this.setState({ [name]: value })
    getDataFromChild(name, parseInt(value))

    if (value === '') {
      setDisabledScanBtn(true)
    } else {
      setDisabledScanBtn(false)
    }
  }

  render() {
    const { intl } = this.props
    const { water } = this.state

    return (
      <div className="row col s12">
        <div className="col s12">
          <div className="custom-card card">
            <div className="form card-content">
              <div className="row card-block">
                <div className="col s6 flex">
                  <img className="card-logo" src={icons.blueWater} alt="logo blue water" />
                  <span className="bold labelCustom">
                    {intl.formatMessage({ id: 'app.label.addWater' })}
                  </span>
                </div>
              </div>
              <div className="divider greyDivider" />
              <div className="row card-block">
                <div className="col s6 input">
                  <label htmlFor="water" className="labelCustom">
                    {intl.formatMessage({ id: 'app.label.waterQuantity' })}
                  </label>
                  <input
                    name="water"
                    type="number"
                    value={water && water}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(AddWaterBlock)
