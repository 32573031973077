import React, { useContext } from 'react'
import { injectIntl } from 'react-intl'
import CardHeader from './partials/CardHeader'
import { PrestationsContext, ProductsForOrderContext } from '../../../../context/contexts'

const Validation = props => {
  const { hist, intl } = props
  const { action: data } = hist.history

  const Box = ({ type, box }) => {
    const { getPrestationLabelByKey } = useContext(PrestationsContext)
    const { getProductForOrderLabelByKey } = useContext(ProductsForOrderContext)
    const products = box.products.map(product => getProductForOrderLabelByKey(product)).join(', ')
    const prestations = box.prestations
      .map(prestation => getPrestationLabelByKey(prestation))
      .join(', ')
    return (
      <div className="orderHistory">
        <div className="title">{intl.formatMessage({ id: `app.label.${type}` })}</div>
        <div className="row line">
          <div className="col s6">
            <span className="bold">{intl.formatMessage({ id: 'app.label.orderNumber' })}: </span>{' '}
            {box.orderNumber}
          </div>
          <div className="col s6">
            <span className="bold">{intl.formatMessage({ id: 'app.label.chicksNumber' })}: </span>{' '}
            {box.chicksNumber}
          </div>
          <div className="col s6">
            <span className="bold">{intl.formatMessage({ id: 'app.label.boxesNumber' })}: </span>{' '}
            {box.boxesNumber}
          </div>
          <div className="col s6">
            <span className="bold">{intl.formatMessage({ id: 'app.label.product' })}: </span>{' '}
            {products}
          </div>
          <div className="col s6">
            <span className="bold">
              {intl.formatMessage({ id: 'app.label.realisedPrestations' })}:{' '}
            </span>{' '}
            {prestations}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="vaccinationHistory">
      <div className="row historyCard">
        <CardHeader date={hist.createdAt} operator={hist.firstName} />
        <div className="col s12 card" key={data.containerNumber}>
          {!Array.isArray(data.male) && (
            <>
              <Box type="males" box={data.male} />
            </>
          )}
          {!Array.isArray(data.female) && (
            <>
              <Box type="females" box={data.female} />
            </>
          )}
          {Object.values(data.vaccines).length > 0 && (
            <div className="orderHistory">
              <div className="title">{intl.formatMessage({ id: `app.label.usedVaccines` })}</div>

              {Object.entries(data.vaccines).map((vaccine, key) => {
                return (
                  <div key={key}>
                    {key > 0 && <div className="divider greyDivider" />}
                    <div className="row line">
                      <div className="col s6">
                        <span className="bold">
                          {intl.formatMessage({ id: 'app.label.speciality' })}:{' '}
                        </span>{' '}
                        {vaccine[1].specialityName}
                      </div>
                      <div className="col s6">
                        <span className="bold">
                          {intl.formatMessage({ id: 'app.label.article' })}:{' '}
                        </span>{' '}
                        {vaccine[1].articleName}
                      </div>
                    </div>
                    <div className="row line">
                      <div className="col s6">
                        <span className="bold">
                          {intl.formatMessage({ id: 'app.label.batchNumber' })}:{' '}
                        </span>{' '}
                        {vaccine[0]}
                      </div>
                      <div className="col s6">
                        <span className="bold">
                          {intl.formatMessage({ id: 'app.label.expirationDate' })}:{' '}
                        </span>{' '}
                        {vaccine[1].expirationDate}
                      </div>
                    </div>
                    <div className="row line">
                      <div className="col s6">
                        <span className="bold">
                          {intl.formatMessage({ id: 'app.label.ampoulesOrVialsNumberUsed' })}:{' '}
                        </span>{' '}
                        {vaccine[1].units}
                      </div>
                      <div className="col s6">
                        <span className="bold">
                          {intl.formatMessage({ id: 'app.label.usedDosesTotal' })}:{' '}
                        </span>{' '}
                        {vaccine[1].doses}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
          {Object.values(data.sharedPreps).length > 0 && (
            <div className="orderHistory">
              <div className="title">{intl.formatMessage({ id: `app.label.sharedPreps` })}</div>
              {Object.values(data.sharedPreps).map(wrapper => {
                return Object.entries(wrapper).map((sharedPrep, key) => {
                  return (
                    <div key={key}>
                      {key > 0 && <div className="divider greyDivider" />}
                      <div className="row line">
                        <div className="col s6">
                          <span className="bold">{sharedPrep[0]}: </span> {sharedPrep[1].partage}
                        </div>
                        <div className="col s6">
                          <span className="bold">
                            {intl.formatMessage({ id: 'app.label.qrNumber' })}:{' '}
                          </span>{' '}
                          {sharedPrep[1].qr}
                        </div>
                      </div>
                    </div>
                  )
                })
              })}
            </div>
          )}
          {data.bottle && (
            <>
              <div className="divider greyDivider" />
              <div className="col s12 marginTop20 marginBot20">
                {intl.formatMessage({ id: 'app.label.waitedBottles' })}
              </div>
            </>
          )}
          {Object.values(data.pbags).length > 0 && (
            <>
              <div className="divider greyDivider" />
              <div className="col s12 marginTop20 marginBot20">
                <span className="bold">
                  {Object.values(data.pbags).length}{' '}
                  {intl.formatMessage({ id: 'app.label.waitedPBags' })}
                </span>
                {': '}
                {Object.entries(data.pbags)
                  .map(pbag => pbag[0])
                  .join(', ')}
              </div>
            </>
          )}
          {data.diluent && (
            <>
              <div className="divider greyDivider" />
              <div className="col s12 marginTop20 marginBot20">
                {intl.formatMessage({ id: 'app.label.waitedDiluentUse' })}
              </div>
            </>
          )}
          {data.dye && (
            <>
              <div className="divider greyDivider" />
              <div className="col s12 marginTop20 marginBot20">
                {intl.formatMessage({ id: 'app.label.waitedDyeUse' })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
export default injectIntl(Validation)
