import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { catchError } from '../../../actions'
import icons from '../../../assets/icons/icons'
import BatchForm from '../../../components/EquipmentManagement/ContainerManagement/VaccinesTransfer/batchForm'
import { closePage, validatedAction } from '../../../components/globalAction'
import LoadingPage from '../../../components/LoadingPage'
import { api } from '../../../parameters'

class ContainerTransfer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      giverContainers: [],
      receiverContainers: [],
      batches: [],
      containerVaccinesBatchesSelected: [],
      setLastControl: false,
      isValidate: false,
      disabled: false,
      disabledForm: []
    }
    this.selectGiverContainer = this.selectGiverContainer.bind(this)
    this.selectReceiverContainers = this.selectReceiverContainers.bind(this)
    this.batchContainerToState = this.batchContainerToState.bind(this)
    this.addBatch = this.addBatch.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmitAll = this.handleSubmitAll.bind(this)
    this.setDisabled = this.setDisabled.bind(this)
    this.setContainerVaccinesBatchesSelected = this.setContainerVaccinesBatchesSelected.bind(this)
  }

  componentDidMount() {
    const { token } = this.props
    Axios.get(`${api}/api/nitrogen_containers?type[]=1&type[]=2&active=true`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const containers = res.data['hydra:member']
        const giverContainers = []
        containers.forEach(container => {
          if (container.type === 1) {
            giverContainers.push(container)
          }
        })
        this.setState(
          {
            receiverContainers: res.data['hydra:member'],
            giverContainers,
            loading: false
          },
          () => M.AutoInit()
        )
      })
      .catch(err => catchError(err.response))
  }

  setDisabled(disabled) {
    this.setState({ disabled })
  }

  setContainerVaccinesBatchesSelected(containerVaccinesBatchesSelected) {
    this.setState({ containerVaccinesBatchesSelected })
  }

  handleChange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  selectGiverContainer(e) {
    const { value } = e.target
    const { giverContainers, selectedContainer } = this.state
    let selected = {}
    giverContainers.forEach(container => {
      if (container.id === parseInt(value)) selected = container
    })
    if (!selectedContainer) this.addBatch()
    this.setState({ selectedContainer: selected })
  }

  selectReceiverContainers(e) {
    const { value } = e.target
    const { receiverContainers } = this.state
    let selectedReceiverContainers = {}
    receiverContainers.forEach(container => {
      if (container.id === parseInt(value)) {
        selectedReceiverContainers = container
      }
    })
    this.setState({ selectedReceiverContainers })
  }

  addBatch() {
    const { batches, disabledForm } = this.state
    if (batches.length > 0) {
      disabledForm.push(true)
    }
    batches.push({})
    this.setDisabled(true)
    this.setState({ batches, disabledForm })
  }

  batchContainerToState(state, index) {
    const { batches } = this.state
    batches[index] = state
    this.setState({ batches })
  }

  handleSubmit() {
    const {
      batches,
      selectedReceiverContainers,
      selectedContainer,
      newNitrogenLevelGiver,
      newNitrogenLevelCatcher
    } = this.state
    const { token } = this.props
    const data = {
      giver: {
        id: selectedContainer.id,
        newNitrogenLevel: newNitrogenLevelGiver,
        newNitrogenLevelState: 1
      },
      catcher: {
        id: selectedReceiverContainers.id,
        newNitrogenLevel: newNitrogenLevelCatcher,
        newNitrogenLevelState: 1
      },
      containerVaccinesBatches: batches
    }
    Axios.post(`${api}/api/container_vaccines/transfer/vaccines`, data, {
      headers: { Authorization: token }
    })
      .then(() => validatedAction(''))
      .catch(err => catchError(err.response))
  }

  handleSubmitAll() {
    const {
      containerVaccinesBatchesSelected,
      selectedReceiverContainers,
      selectedContainer,
      newNitrogenLevelGiver,
      newNitrogenLevelCatcher
    } = this.state
    const { token } = this.props
    const data = {
      giver: {
        id: selectedContainer.id,
        newNitrogenLevel: newNitrogenLevelGiver,
        newNitrogenLevelState: 1
      },
      catcher: {
        id: selectedReceiverContainers.id,
        newNitrogenLevel: newNitrogenLevelCatcher,
        newNitrogenLevelState: 1
      },
      containerVaccinesBatches: containerVaccinesBatchesSelected
    }
    Axios.post(`${api}/api/container_vaccines/transfer/vaccines`, data, {
      headers: { Authorization: token }
    })
      .then(() => validatedAction(''))
      .catch(err => catchError(err))
  }

  render() {
    const { intl, token } = this.props
    const {
      giverContainers,
      receiverContainers,
      selectedReceiverContainers,
      loading,
      selectedContainer,
      batches,
      isValidate,
      setLastControl,
      disabled,
      disabledForm
    } = this.state
    if (loading) return <LoadingPage />
    if (setLastControl) {
      return (
        <div className="Container containersManagement">
          <div className="form row">
            <div className="col m6 input">
              <label className="active label" htmlFor="container1">
                {intl.formatMessage({ id: 'app.equipment.containerNumber' })}
              </label>
              <input
                disabled
                value={`Container ${selectedContainer.containerNumber}`}
                id="container1"
                type="text"
              />
            </div>
            <div className="col m6 input">
              <label className="active label" htmlFor="nitrogiver">
                {intl.formatMessage({ id: 'app.label.nitrogenLevel' })}
              </label>
              <input
                id="nitrogiver"
                type="text"
                name="newNitrogenLevelGiver"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form row">
            <div className="col m6 input">
              <label className="active label" htmlFor="container1">
                {intl.formatMessage({ id: 'app.equipment.containerNumber' })}
              </label>
              <input
                disabled
                value={`Container ${selectedReceiverContainers.containerNumber}`}
                id="container1"
                type="text"
              />
            </div>
            <div className="col m6 input">
              <label className="active label" htmlFor="container1">
                {intl.formatMessage({ id: 'app.label.nitrogenLevel' })}
              </label>
              <input
                id="container1"
                type="number"
                step="0.1"
                name="newNitrogenLevelCatcher"
                onChange={this.handleChange}
              />
            </div>
          </div>
          {disabled ? (
            <div className="row">
              <div className="col offset-s5 s2">
                <br />
                <button type="button" onClick={this.handleSubmitAll} className="btn blueBtn center">
                  {intl.formatMessage({ id: 'app.button.validate' })}
                </button>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col offset-s5 s2">
                <br />
                <button type="button" onClick={this.handleSubmit} className="btn blueBtn center">
                  {intl.formatMessage({ id: 'app.button.validate' })}
                </button>
              </div>
            </div>
          )}
        </div>
      )
    }
    return (
      <div className="Container containersManagement">
        <div className="content">
          <div className="pageHeader pageTitle row">
            <h5 className="col m11">
              {intl.formatMessage({ id: 'app.label.vaccinationTransfer' })}
            </h5>
            <div
              className="right goBackBtn col m1"
              role="button"
              tabIndex="0"
              onKeyPress={() => closePage(intl)}
              onClick={() => closePage(intl)}
            >
              <i className="material-icons">close</i>
            </div>
          </div>
          <div className="redMsg">
            <img src={icons.redDanger} alt="red danger icon" />
            <p className="red-text">
              {intl.formatMessage({ id: 'app.part.nitrogenTransfer.text2' })}
            </p>
          </div>
          <div className="form">
            <div className="row">
              <div className="input col m6">
                <label className="active label" htmlFor="giverNumber">
                  {intl.formatMessage({ id: 'app.label.nitrogenDonorNumber' })}
                </label>
                <select
                  className="select-wrapper"
                  id="giverNumber"
                  onChange={this.selectGiverContainer}
                  defaultValue="0"
                >
                  <option value="0" disabled>
                    {intl.formatMessage({ id: `app.label.selectContainer` })}
                  </option>
                  {giverContainers.map(container => (
                    <option key={container.id} value={container.id}>
                      Container {container.containerNumber}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input col m6">
                <label className="active label" htmlFor="nitrolvl">
                  {intl.formatMessage({ id: 'app.label.nitrogenLevel' })}
                </label>
                <input
                  disabled
                  value={selectedContainer ? selectedContainer.lastControl.nitrogenLevel : ''}
                  id="nitrolvl"
                  type="number"
                  step="0.1"
                />
              </div>
            </div>
            {selectedContainer &&
              batches.map((batch, i) => (
                <BatchForm
                  id={selectedContainer.id}
                  key={batch}
                  batches2={batches}
                  token={token}
                  index={i}
                  batchContainerToState={this.batchContainerToState}
                  setDisabled={this.setDisabled}
                  setBatches={this.setBatches}
                  setContainerVaccinesBatchesSelected={this.setContainerVaccinesBatchesSelected}
                  disabledForm={disabledForm}
                />
              ))}
            {isValidate && (
              <div className="row">
                <div className="catcherForm">
                  <div className="input col m6">
                    <label htmlFor="catcher" className="active">
                      {intl.formatMessage({ id: `app.label.nitrogenTakerNumber` })}
                    </label>
                    <select
                      className="select-wrapper"
                      id="catcher"
                      defaultValue="0"
                      onChange={this.selectReceiverContainers}
                    >
                      <option value="0" disabled />
                      {receiverContainers.map(
                        container =>
                          selectedContainer.id !== container.id &&
                          (container.type === 1 || container.type === 2) &&
                          container.lastControl.nitrogenLevelState === 1 && (
                            <option key={container.id} value={container.id}>
                              {`Container ${container.containerNumber}`}
                            </option>
                          )
                      )}
                    </select>
                  </div>
                  <div className="input col m6">
                    <label htmlFor="nitrogenLevelReceiver" className="active">
                      {intl.formatMessage({ id: `app.label.nitrogenLevel` })}
                    </label>
                    <input
                      disabled
                      value={
                        selectedReceiverContainers !== null &&
                        selectedReceiverContainers !== undefined
                          ? selectedReceiverContainers.lastControl.nitrogenLevel
                          : ''
                      }
                      id="nitrogenLevelReceiver"
                      type="number"
                      step="0.1"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col m12">
              <div className="flex flex-column flex-center">
                {selectedContainer && (
                  <>
                    {!isValidate && (
                      <button
                        type="button"
                        className="btn transparentBtn addBtn"
                        onClick={this.addBatch}
                        onKeyPress={this.addBatch}
                        disabled={disabled}
                      >
                        {intl.formatMessage({ id: `app.label.add` })}
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn blueBtn validationBtn"
                      onClick={
                        isValidate
                          ? () => this.setState({ setLastControl: true }, () => M.AutoInit())
                          : () => this.setState({ isValidate: true }, () => M.AutoInit())
                      }
                      onKeyPress={() => this.setState({ isValidate: true }, () => M.AutoInit())}
                    >
                      {intl.formatMessage({ id: `app.button.validate` })}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default injectIntl(ContainerTransfer)
