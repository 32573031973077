import React from 'react'
import { Link } from 'react-router-dom'
import icons from '../../../assets/icons/icons'

export default function OutStockAction(props) {
  const { to } = props

  return (
    <Link to={to}>
      <img src={icons.redDustbin} alt="red dustbin icon" />
    </Link>
  )
}
