import React from 'react'
import { injectIntl } from 'react-intl'
import icons from '../../../../../../assets/icons/icons'
import AddButton from '../../../../../StockManagement/StockAvailibility/AddButton'

const ShowInfos = props => {
  const { females, freeChicks, inEdit, intl, males, onClick, orderFemales, orderMales } = props

  return (
    <div className={`${inEdit === true ? 'hidden' : ''}`}>
      <div className="col s6">
        {`${males} ${intl.formatMessage({
          id: 'app.label.chicksMales'
        })}`}
      </div>
      <div className="col s6">
        {`${females} ${intl.formatMessage({
          id: 'app.label.chicksFemales'
        })}`}
      </div>
      {males !== 0 && males !== orderMales ? (
        <div className="warningMessage col s12">
          <img src={icons.orangeBorderWarning} alt="orange danger icon" />
          {intl.formatMessage({ id: 'app.message.warningMales' })}
        </div>
      ) : (
        ''
      )}
      {females !== 0 && females !== orderFemales ? (
        <div className="warningMessage col s12">
          <img src={icons.orangeBorderWarning} alt="orange danger icon" />
          {intl.formatMessage({ id: 'app.message.warningFemales' })}
        </div>
      ) : (
        ''
      )}
      <div className="divider greyDivider" />
      <div
        className="col s12 addFreeChicks pointer"
        onClick={onClick}
        onKeyPress={onClick}
        role="textbox"
        tabIndex={0}
      >
        {freeChicks === 0 ? (
          <>
            <AddButton />
            {intl.formatMessage({ id: 'app.label.addFreeChicks' })}
          </>
        ) : (
          <>{`${freeChicks} ${intl.formatMessage({ id: 'app.label.freeChicks' })}`}</>
        )}
      </div>
    </div>
  )
}

export default injectIntl(ShowInfos)
