import React from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

const CardHeader = props => {
  const { date, operator } = props

  return (
    <div className="singleHistoryHeader">
      <div className="col m4">
        <p>
          <span>
            <FormattedMessage id="app.label.operationDate" />
          </span>
          &nbsp;:&nbsp;{moment(date).format('DD/MM/YYYY HH:mm')}
        </p>
      </div>
      <div className="col offset-m4 m4">
        <p>
          <span>
            <FormattedMessage id="app.label.user" />
          </span>
          :&nbsp;{operator}
        </p>
      </div>
    </div>
  )
}
export default CardHeader
