import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { catchError } from '../../../../actions'
import { api } from '../../../../parameters'
import LoadingPage from '../../../LoadingPage'
import AllValidatedMessage from '../AllValidatedMessage'
import ContainerControlItem from './partials/ContainerControlItem'

class ContainersPart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      containers: [],
      loading: true
    }

    this.loadContainers = this.loadContainers.bind(this)
    this.getLastControl = this.getLastControl.bind(this)
  }

  componentDidMount() {
    this.loadContainers()
  }

  getLastControl() {
    const { token } = this.props

    Axios.get(
      `${api}/api/nitrogen_containers?active=true&order[lastControl.createdAt]=desc&items=1`,
      {
        headers: { Authorization: token }
      }
    ).then(result => {
      const [resData] = result.data['hydra:member']

      if (resData !== undefined) {
        this.setState({ lastControl: resData.lastControl.createdAt })
      }
    })
  }

  loadContainers() {
    const { token } = this.props
    Axios.get(`${api}/api/nitrogen_containers?active=true&needControl=true&type=1`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const data = res.data['hydra:member']
        const containers = []
        let lastControl

        data.forEach(el => {
          if (
            lastControl === undefined ||
            moment(el.lastControl.createdAt).format('DD/MM/YYYY') >
              moment(lastControl).format('DD/MM/YYYY')
          ) {
            lastControl = el.lastControl.createdAt
          }

          if (el.lastControl.state !== 0 && el.type !== 0) {
            containers.push(el)
          }
        })

        if (data.length === 0) {
          this.getLastControl()
        }

        this.setState({ containers, loading: false, lastControl })
      })
      .catch(err => catchError(err.response, false))
  }

  render() {
    const { containers, loading, lastControl } = this.state
    const { loadControls, location } = this.props
    const { hash, pathname } = location

    if (loading === true) {
      return <LoadingPage />
    }

    return (
      <div id="containers" className="col s12">
        <div className="row tabHeader">
          <div className="col offset-s1 s10">
            <p>
              <FormattedMessage id="app.control.container.header" />
            </p>
          </div>
        </div>
        {containers.length === 0 &&
        (hash === '#containers' || (pathname === '/equipments/management' && hash === '')) ? (
          <AllValidatedMessage lastControl={lastControl} id="app.equipment.containerAll" />
        ) : (
          <div className="row content">
            {containers.map((container, i) => (
              <ContainerControlItem
                key={container.id}
                container={container}
                index={i}
                loadControls={loadControls}
                loadContainers={this.loadContainers}
              />
            ))}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(ContainersPart)))
