import React from 'react'
import moment from 'moment'
import Td from '../../Table/Td'
import StatePill from '../../StatePill'
import { renderNitrogenLevel, renderTemperature } from '../../globalRenderActions'

const VaccineBatch = props => {
  const { batch, intl, path, vaccine } = props
  const to =
    undefined !== batch.nitrogenContainer
      ? `${path}/frozen/${batch.id}`
      : `${path}/refrigerated/${batch.id}`
  return (
    <tr className="pointer">
      <Td to={to}>{vaccine.batchNumber}</Td>
      <Td to={to}>{vaccine.vaccineArticle.speciality.name}</Td>
      <Td to={to}>
        {intl.formatMessage({
          id: `app.administrationMethod.${vaccine.vaccineArticle.speciality.administrationMethod}`
        })}
      </Td>
      <Td to={to}>{vaccine.vaccineArticle.name}</Td>
      <Td to={to}>
        {undefined !== batch.nitrogenContainer ? batch.ampoulesNumber : batch.vialsNumber}
      </Td>
      <Td to={to}>
        {undefined !== batch.nitrogenContainer
          ? batch.ampoulesNumber * vaccine.vaccineArticle.dosesNumber
          : batch.vialsNumber * vaccine.vaccineArticle.dosesNumber}
      </Td>
      <Td to={to}>
        <StatePill type="ExpirationDate" data={vaccine} />
        {moment(vaccine.expirationDate).format('DD/MM/YYYY')}
      </Td>
      <Td to={to}>
        {undefined !== batch.nitrogenContainer
          ? batch.nitrogenContainer.containerNumber
          : batch.fridge.name}
      </Td>
      <Td to={to}>
        <div className="col s7">
          {undefined !== batch.nitrogenContainer ? (
            <span className="noPadding">
              <StatePill type="ContainerNitrogenLevel" data={batch.nitrogenContainer} />
              {renderNitrogenLevel(batch.nitrogenContainer.lastControl.nitrogenLevel, intl)}
            </span>
          ) : (
            <span className="noPadding">
              <StatePill type="FridgeInstantTemperature" data={batch.fridge} />
              {renderTemperature(batch.fridge.lastControl.instantTemperature, intl)}
            </span>
          )}
        </div>
        <div className="col s5">
          <i className="material-icons arrow-right">keyboard_arrow_right</i>
        </div>
      </Td>
    </tr>
  )
}

export default VaccineBatch
