import React from 'react'
import { injectIntl } from 'react-intl'
import TextArea from '../../partials/TextArea'
import NoBlockHistoryPart from '../NoBlockHistoryPart/NoBlockHistoryPart'

const NoBlockArticlePart = props => {
  const {
    history,
    article,
    intl,
    exiprationDateDays,
    noBlockPartAlertExpirationDate,
    action,
    articleId,
    handleChange
  } = props

  if (!history) {
    const { nonBlockingExpirationAlert } = article
    return (
      <div className="row">
        <div className="col s6 input">
          <div className="row">
            <div className="input col s6">
              <label htmlFor="noBlockExpirationDate" className="labelParams">
                {intl.formatMessage({ id: 'app.label.expirationDate' })}
              </label>
              <select
                className="select-wrapper"
                name="noBlockExpirationDate"
                defaultValue={nonBlockingExpirationAlert ? nonBlockingExpirationAlert.value : '0'}
                onChange={handleChange}
                required
              >
                <option
                  value={nonBlockingExpirationAlert ? nonBlockingExpirationAlert.value : '0'}
                  disabled
                >
                  {nonBlockingExpirationAlert && nonBlockingExpirationAlert.value !== ''
                    ? nonBlockingExpirationAlert.value
                    : intl.formatMessage({ id: 'app.label.selectDay' })}
                </option>

                {exiprationDateDays.map(day => (
                  <option key={day.name} value={day.name}>
                    {day.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <TextArea
            name="noBlockPartAlertExpirationDate"
            value1={noBlockPartAlertExpirationDate}
            handleChange={handleChange}
          />
        </div>
        <div className="divider greyDivider" />
      </div>
    )
  }
  const { changes } = action
  const { nonBlockingExpirationAlert } = changes
  return (
    <NoBlockHistoryPart
      nonBlockingExpirationAlert={nonBlockingExpirationAlert}
      articleId={articleId}
    />
  )
}

export default injectIntl(NoBlockArticlePart)
