import * as Sentry from '@sentry/browser'
import Axios from 'axios'
import jwtDecode from 'jwt-decode'
import Swal from 'sweetalert2'
import pjson from '../../package.json'
import history from '../history'
import { api } from '../parameters'
import store from '../store'
import {
  AUTH_USER,
  INSTANT_NOTIF_ADD,
  INSTANT_NOTIF_REMOVE,
  LOGOUT,
  NOTIF_ADD,
  NOTIF_LIST,
  NOTIF_REMOVE
} from './types'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://fd9731d343a34d72a761a4f9fbd837e5@sentry.io/1808723',
    environment: 'dev',
    release: pjson.version
  })
}

export const loginUser = pin => dispatch => {
  return new Promise((resolve, reject) => {
    Axios.post(`${api}/api/login`, { pin })
      .then(res => {
        const { token } = res.data
        const tokenData = jwtDecode(token)

        localStorage.setItem('token', token)
        dispatch({
          type: AUTH_USER,
          token,
          username: tokenData.username,
          email: tokenData.email,
          role: tokenData.roles
        })
        return resolve('Loged')
      })
      .catch(err => reject(err))
  })
}

export const logoutUser = (error = null) => {
  history.push({ pathname: '/login', state: { error } })
  store.dispatch({
    type: LOGOUT,
    error
  })

  return localStorage.clear()
}

export const displayErrorMessage = errorMessage => {
  return Swal.fire({
    type: 'error',
    title: 'Erreurs',
    showCancelButton: false,
    text: errorMessage,
    customClass: {
      icon: 'swalErrorIcon',
      title: 'swalTitle',
      content: 'swalContentText',
      confirmButton: 'blueBtn'
    }
  })
}

export const catchError = (error, goBack = true) => {
  if (!error || !error.data) return
  const { data } = error
  const { code, message } = data.error || data
  switch (code) {
    case 401:
      return logoutUser('Votre avez été déconnecté')
    case 403:
      return history.push('/')
    case 404:
      return Swal.fire({
        type: 'error',
        title: 'Erreurs',
        showCancelButton: false,
        text: message,
        customClass: {
          icon: 'swalErrorIcon',
          title: 'swalTitle',
          content: 'swalContentText',
          confirmButton: 'blueBtn'
        }
      }).then(() => {
        if (goBack) history.goBack()
      })
    case 409:
      return Swal.fire({
        type: 'error',
        title: 'Erreur',
        text: message,
        customClass: {
          icon: 'swalErrorIcon',
          title: 'swalTitle',
          content: 'swalContentText',
          confirmButton: 'blueBtn'
        }
      })
    default:
      Sentry.withScope(scope => {
        Object.keys(error).forEach(key => {
          scope.setExtra(key, error[key])
        })
        Sentry.captureException(error)
      })
      return Swal.fire({
        type: 'error',
        title: 'Erreur',
        text: message,
        customClass: {
          icon: 'swalErrorIcon',
          title: 'swalTitle',
          content: 'swalContentText',
          confirmButton: 'blueBtn'
        }
      })
  }
}

export const addInstantNotif = notification => {
  store.dispatch({
    type: INSTANT_NOTIF_ADD,
    notification
  })
}

export const removeInstantNotif = i => {
  store.dispatch({
    type: INSTANT_NOTIF_REMOVE,
    i
  })
}

export const addNotif = notification => {
  if (store.getState().mercure.notifications === '') {
    store.getState().mercure.notifications = []
  }

  if (
    store.getState().mercure.notifications.find(el => el.uuid === notification.uuid) === undefined
  ) {
    store.dispatch({
      type: NOTIF_ADD,
      notification
    })
  }
}

export const removeNotif = id => {
  store.dispatch({
    type: NOTIF_REMOVE,
    id
  })
}

export const getAllNotifs = token => {
  return new Promise((resolve, reject) => {
    let loading = true
    const tokenData = jwtDecode(token)
    const topics = tokenData.mercureTopics.join()

    Axios.get(`${api}/api/alerts?channels=${topics}`, { headers: { Authorization: token } })
      .then(res => {
        store.dispatch({
          type: NOTIF_LIST,
          notifications: res.data
        })

        loading = false

        return resolve({ loading })
      })
      .catch(err => reject(err))
  })
}
