import moment from 'moment'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import icons from '../../../assets/icons/icons'

const AllValidatedMessage = props => {
  const { id, lastControl } = props

  return (
    <div className="row">
      <div className="col offset-m1 m10 card">
        <div className="validationMsg">
          <img className="validationCircle" src={icons.greenSuccess} alt="green success icon" />
          <div className="validationMsgText">
            <p className="title">
              <FormattedMessage id={id} />
            </p>
            {lastControl && (
              <>
                <FormattedMessage id="app.label.lastControlDate" />{' '}
                {moment(lastControl).format('DD/MM/YYYY')}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllValidatedMessage
