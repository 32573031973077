import React from 'react'
import { withRouter } from 'react-router'
import { injectIntl } from 'react-intl'
import moment from 'moment'

const HistoryItem = props => {
  const { hist, intl } = props

  const onTrClick = histId => {
    const { history } = props

    history.push(`/history/${histId}`)
  }

  return (
    <tr
      className="pointer"
      style={hist.outdated ? { background: '#c1c7d0' } : {}}
      onClick={() => onTrClick(hist.id)}
    >
      <td className="bold">{moment(hist.createdAt).format('DD/MM/YYYY HH:mm')}</td>
      <td>{intl.formatMessage({ id: `app.vaccination.history.action.${hist.type}` })}</td>
      <td>{hist.history.action.preparation ? hist.history.action.preparation.qr : ''}</td>
      <td>{hist.history.orderNumber === null ? 'N/A' : hist.history.orderNumber}</td>
      <td>{hist.firstName}</td>
      <td>
        <i className="material-icons arrow-right">keyboard_arrow_right</i>
      </td>
    </tr>
  )
}

export default injectIntl(withRouter(HistoryItem))
