import React from 'react'
import { injectIntl } from 'react-intl'
import { messageInfo } from '../../../../../../../globalAction'

class SprayableVaccineRadio extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    const { name, value } = e.target
    const { getDataFromChild, intl } = this.props

    let message
    const parseIntValue = parseInt(value)

    switch (parseIntValue) {
      case 12:
        message = intl.formatMessage({ id: 'app.message.vaccineHasNotBeenStroredCorrectly' })
        break
      case 15:
        message = intl.formatMessage({ id: 'app.message.vaccineSelectedIsNotTheGood' })
        break
      default:
        message = intl.formatMessage({ id: 'app.message.vaccineCantBeUsed' })
        break
    }

    messageInfo(message, 'none')

    getDataFromChild(name, parseInt(value))
  }

  render() {
    const { intl } = this.props
    return (
      <div className="card-content">
        <div className="card-block">
          <div className="row">
            <label className="col s6" htmlFor="type13">
              <input
                id="type13"
                name="type"
                className="checkbox"
                value={13}
                type="radio"
                onChange={this.handleChange}
              />
              <span className="labelCustom">
                {intl.formatMessage({ id: 'app.label.brokenFlask' })}
              </span>
            </label>
            <label className="col s6" htmlFor="type15">
              <input
                id="type15"
                name="type"
                className="checkbox"
                value={15}
                type="radio"
                onChange={this.handleChange}
              />
              <span className="labelCustom">
                {intl.formatMessage({ id: 'app.label.differentFlask' })}
              </span>
            </label>
          </div>
          <div className="row">
            <label className="col s6" htmlFor="type14">
              <input
                id="type14"
                name="type"
                className="checkbox"
                value={14}
                type="radio"
                onChange={this.handleChange}
              />
              <span className="labelCustom">
                {intl.formatMessage({ id: 'app.label.reversedFlask' })}
              </span>
            </label>
            <label className="col s6" htmlFor="type16">
              <input
                id="type16"
                name="type"
                className="checkbox"
                value={16}
                type="radio"
                onChange={this.handleChange}
              />
              <span className="labelCustom">
                {intl.formatMessage({ id: 'app.label.errorChoiceFlask/batchNumber' })}
              </span>
            </label>
          </div>
          <div className="row">
            <label className="col s6" htmlFor="type12">
              <input
                id="type12"
                name="type"
                className="checkbox"
                value={12}
                type="radio"
                onChange={this.handleChange}
              />
              <span className="labelCustom">
                {intl.formatMessage({ id: 'app.label.flaskBadConservation' })}
              </span>
            </label>
            <label className="col s6" htmlFor="type17">
              <input
                id="type17"
                name="type"
                className="checkbox"
                value={17}
                type="radio"
                onChange={this.handleChange}
              />
              <span className="labelCustom">
                {intl.formatMessage({ id: 'app.label.errorChoiceFlask/badConditionnement' })}
              </span>
            </label>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(SprayableVaccineRadio)
