import React from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

const Vaccines = props => {
  const { intl, vaccines } = props

  return (
    <div className="customCard chicksInfos">
      <div className="customCardHeader">
        {intl.formatMessage({ id: `app.certificate.usedVaccines` })}
      </div>
      <div className="customCardContent row">
        {vaccines.map((vaccine, i) => (
          <div key={i} className="col s12">
            {vaccine}
          </div>
        ))}
      </div>
    </div>
  )
}

export default injectIntl(Vaccines)

Vaccines.propTypes = {
  vaccines: PropTypes.arrayOf(PropTypes.string).isRequired
}
