import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { providers } from '../../../../../parameters'
import DatepickerBlock from '../../../../DatepickerBlock'

class DiluentReceptionPart extends React.Component {
  componentDidMount() {
    M.FormSelect.init(document.querySelectorAll('select'))
  }

  render() {
    const { intl, index, handleChange, getDate, fridgeIndex } = this.props
    return (
      <form>
        <div className="col s12 row">
          <div className="col s6 input">
            <label htmlFor="provider">{intl.formatMessage({ id: 'app.label.provider' })}</label>
            <select
              className="select-wrapper"
              defaultValue="0"
              name="provider"
              onChange={e => handleChange(e)}
            >
              <option value="0" disabled>
                {intl.formatMessage({ id: 'app.label.selectDiluent' })}
              </option>
              {providers.map(provider => (
                <option key={provider.name}>{provider.name}</option>
              ))}
            </select>
          </div>
          <div className="col s6 input">
            <label htmlFor="batchNumber">
              {intl.formatMessage({ id: 'app.label.batchNumber' })}
            </label>
            <input type="text" name="batchNumber" onChange={e => handleChange(e, index)} required />
          </div>
          <div className="col s6 input">
            <label> {intl.formatMessage({ id: 'app.label.expirationDate' })}</label>
            <DatepickerBlock
              name={`fridge${fridgeIndex}index${index}`}
              setDataFromChild={(name, value) => getDate(name, value, fridgeIndex, index)}
              placeholder={intl.formatMessage({ id: 'app.label.dateFormat' })}
            />
          </div>
          <div className="col s6 input">
            <label> {intl.formatMessage({ id: 'app.label.unitsNumber' })}</label>
            <input
              type="number"
              name="unitsNumber"
              required
              onChange={e => handleChange(e, index)}
            />
          </div>
        </div>
      </form>
    )
  }
}

export default injectIntl(DiluentReceptionPart)
