import React from 'react'
import { injectIntl } from 'react-intl'

const ValidateButton = props => {
  const { intl, onClick } = props

  return (
    <div className="center validateBtn">
      <button className="btn blueBtn" type="submit" onClick={onClick}>
        {intl.formatMessage({ id: 'app.button.validate' })}
      </button>
    </div>
  )
}

export default injectIntl(ValidateButton)
