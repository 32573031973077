import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import Axios from 'axios'
import { api } from '../../../../parameters'
import CanPart from './partials/CanPart/CanPart'
import ProductBagPart from './partials/ProductBagPart/ProductBagPart'
import LoadingPage from '../../../../components/LoadingPage'
import { catchError } from '../../../../actions'

class SinglePart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    const { match, token } = this.props
    const { id } = match.params

    Axios.get(`${api}/api/order_preparations/${id}`, { headers: { Authorization: token } })
      .then(res =>
        this.setState({
          loading: false,
          prep: res.data
        })
      )
      .catch(err => catchError(err.response))
  }

  getContent() {
    const { match } = this.props
    const { prep } = this.state
    const { id } = match.params

    if (prep.bottle !== null) {
      return <CanPart prep={prep} prepId={id} />
    }

    return <ProductBagPart prep={prep} prepId={id} />
  }

  render() {
    const { loading } = this.state

    if (loading === true) {
      return <LoadingPage />
    }

    return (
      <div className="row Container VaccinationContainer endRealisation">
        <div className="col s12">{this.getContent()}</div>
      </div>
    )
  }
}

const mapStatetoProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStatetoProps)(SinglePart))
