import React, { forwardRef } from 'react'
import Header from './partials/Header'
import HatcheryInfos from './partials/HatcheryInfos'
import CertificateInfos from './partials/CertificateInfos'
import ClientInfos from './partials/ClientInfos'
import ChicksInfos from './partials/ChicksInfos'
import Vaccines from './partials/Vaccines'
import Footer from './partials/Footer'

const CertificateToPrint = forwardRef((props, ref) => {
  const { certificate, toPrint } = props
  const { client, females, males, vaccines } = certificate

  return (
    <div className={`certificate ${toPrint === true ? 'toPrint' : ''}`} ref={ref}>
      <Header />
      <div id="certificateContent">
        <HatcheryInfos />
        <CertificateInfos number={certificate.number} date={client.hatchingDate} />
        <ClientInfos client={client} />
        {females.orderNumber !== undefined ? <ChicksInfos females={females} /> : ''}
        {males.orderNumber !== undefined ? <ChicksInfos males={males} /> : ''}
        <Vaccines vaccines={vaccines} />
      </div>
      <Footer />
    </div>
  )
})

export default CertificateToPrint
