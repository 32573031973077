import React from 'react'
import { injectIntl } from 'react-intl'
import TextArea from '../../partials/TextArea'
import NoBlockHistoryPart from '../NoBlockHistoryPart/NoBlockHistoryPart'

const NoBlockEquipmentPart = props => {
  const {
    history,
    noBlockPartAlertAfter,
    noBlockPartAlertBefore,
    noBlockPartAlertMinAfter,
    noBlockPartAlertMaxAfter,
    noBlockPartAlertMinBefore,
    noBlockPartAlertMaxBefore,
    paramId,
    blockValueMin,
    intl,
    congruentPartValueMin,
    congruentPartValueMax,
    blockValueMax,
    noBlockPartAlert,
    handleChange,
    noBlockPartAlertMin,
    noBlockPartAlertMax
  } = props

  if (history) {
    return (
      <NoBlockHistoryPart
        noBlockPartAlertAfter={noBlockPartAlertAfter}
        noBlockPartAlertBefore={noBlockPartAlertBefore}
        noBlockPartAlertMinAfter={noBlockPartAlertMinAfter}
        noBlockPartAlertMaxAfter={noBlockPartAlertMaxAfter}
        noBlockPartAlertMinBefore={noBlockPartAlertMinBefore}
        noBlockPartAlertMaxBefore={noBlockPartAlertMaxBefore}
        paramId={paramId}
      />
    )
  }
  switch (paramId) {
    case 0:
    case 6:
      return (
        <div className="row">
          <div className="col s12">
            <div className="row input">
              <div className="input col s3">
                <label htmlFor="iftemperatureBetween" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.ifTemperatureBetween' })}
                </label>
                <input name="noBlockValueMin" type="number" value={blockValueMin || ''} disabled />
              </div>
              <div className="input col s3">
                <div className="labelParams">&nbsp;</div>
                <input
                  name="noBlockValueMax"
                  type="number"
                  value={congruentPartValueMin || ''}
                  disabled
                />
              </div>
              <div className="input col s3">
                <label htmlFor="noBlockValueMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.ifTemperatureBetween' })}
                </label>
                <input
                  name="noBlockValueMin"
                  type="number"
                  value={congruentPartValueMax || ''}
                  disabled
                />
              </div>
              <div className="input col s3">
                <div className="labelParams">&nbsp;</div>
                <input name="noBlockValueMax" type="number" value={blockValueMax || ''} disabled />
              </div>
            </div>
            <TextArea
              name="noBlockPartAlert"
              value1={noBlockPartAlert || ''}
              handleChange={handleChange}
            />
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    case 7:
      return (
        <div className="row">
          <div className="col s12">
            <div className="row input">
              <div className="col s3">
                <label htmlFor="noBlockPartAlert" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.ifHigherVolume' })}
                  &nbsp;
                </label>
                <input
                  type="number"
                  name="noBlockPartAlert"
                  value={congruentPartValueMax || ''}
                  disabled
                />
              </div>
            </div>
            <TextArea
              label={intl.formatMessage({ id: 'app.label.alertNitrogenLevelLower' })}
              name="noBlockPartAlert"
              value1={noBlockPartAlert || ''}
              handleChange={handleChange}
            />
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    case 8:
    case 9:
      return (
        <div className="row">
          <div className="col s12">
            <div className="row input">
              <div className="col s3">
                <label htmlFor="congruentPartValueMin">
                  {intl.formatMessage({ id: 'app.label.ifLowerVolume' })}
                  &nbsp;
                </label>
                <input
                  name="congruentPartValueMin"
                  type="number"
                  value={congruentPartValueMin || ''}
                  disabled
                />
              </div>
              <div className="col s3">
                <label htmlFor="congruentPartValueMax">
                  {intl.formatMessage({ id: 'app.label.ifHigherVolume' })}
                  &nbsp;
                </label>
                <input
                  name="congruentPartValueMax"
                  type="number"
                  value={congruentPartValueMax || ''}
                  disabled
                />
              </div>
            </div>
            <TextArea
              name="noBlockPartAlert"
              value1={noBlockPartAlert || ''}
              handleChange={handleChange}
            />
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    default:
      return (
        <div className="row">
          <div className="col s12">
            <div className="row input">
              <div className="col s12">
                <div className="row">
                  <div className="col s6">
                    <div className="row">
                      <div className="col s6">
                        <label className="labelParams" htmlFor="noBlockValues">
                          {intl.formatMessage({ id: 'app.label.ifLowerNitrogen' })}
                          &nbsp;
                        </label>
                        <input
                          type="number"
                          disabled
                          value={congruentPartValueMin || ''}
                          name="noBlockValues"
                        />
                      </div>
                      <div className="col s6">
                        <label className="labelParams" htmlFor="noBlockValues">
                          {intl.formatMessage({ id: 'app.label.ifHigherNitrogen' })}
                          &nbsp;
                        </label>
                        <input
                          type="number"
                          disabled
                          value={congruentPartValueMax || ''}
                          name="noBlockValues"
                        />
                      </div>
                    </div>
                    <TextArea
                      label="app.label.alertNitrogenLow"
                      name="noBlockPartAlertMin"
                      value1={noBlockPartAlertMin || ''}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col s6">
                  <TextArea
                    label="app.label.alertNitrogenHigh"
                    name="noBlockPartAlertMax"
                    value1={noBlockPartAlertMax || ''}
                    handleChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    case 10:
      return null
  }
}

export default injectIntl(NoBlockEquipmentPart)
