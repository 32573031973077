import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { catchError } from '../../actions'
import icons from '../../assets/icons/icons'
import { closePage, validatedAction } from '../../components/globalAction'
import LoadingPage from '../../components/LoadingPage'
import { documentCategories } from '../../config/document'
import { api } from '../../parameters'

class DocumentImport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      file: null,
      title: '',
      category: '0',
      disabled: true
    }
    this.handleChange = this.handleChange.bind(this)
    this.setDisable = this.setDisable.bind(this)
  }

  componentDidMount() {
    M.AutoInit()
  }

  setDisable() {
    const { file, title, category } = this.state

    this.setState({ disabled: file === null || title === '' || category === '0' })
  }

  handleChange(e) {
    const { name } = e.target
    let { value } = e.target

    if (name === 'category') {
      value = parseInt(value)
    }
    this.setState({ [name]: value }, () => this.setDisable())
  }

  handleFile(e) {
    const { files } = e.target
    const file = files[0]

    this.setState({ file }, () => this.setDisable())
  }

  handleUpload() {
    const { token } = this.props
    const { title, file, category } = this.state
    this.setState({ loading: true })

    const data = new FormData()
    data.append('document', file, file.name)
    data.append('title', title)
    data.append('category', category)

    Axios.post(`${api}/api/documentation/upload`, data, {
      headers: { Authorization: token }
    })
      .then(() => {
        this.setState({ loading: false })
        validatedAction()
      })
      .catch(err => {
        this.setState({ loading: false })
        catchError(err.response)
      })
  }

  render() {
    const { intl } = this.props
    const { loading, disabled } = this.state

    return (
      <div className="Container planning">
        <div className="col offset-s1 s10">
          <div className="row center marginTop30">
            <div className="col s11">
              <h5 className="labelCustom">
                {intl.formatMessage({ id: 'app.import.importDocument' })}
              </h5>
            </div>
            <div className="col s1">
              <div
                className="goBackBtn"
                role="button"
                tabIndex="0"
                onKeyPress={() => closePage(intl, false)}
                onClick={() => closePage(intl, false)}
              >
                <i className="material-icons">close</i>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col  offset-s1 s10 form">
              <form>
                <div>
                  <div className="col s12 input">
                    <label htmlFor="file" className="active">
                      {intl.formatMessage({ id: 'app.label.selectFile' })}
                    </label>
                    <div className="file-field input-field">
                      <div className="btn blueBtn darkBlueBtn">
                        <input type="file" name="file" onChange={e => this.handleFile(e)} />
                        <span>{intl.formatMessage({ id: 'app.label.uploadFile' })}</span>
                      </div>
                      <div className="file-path-wrapper">
                        <input className="file-path validate" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col s6 input">
                    <label htmlFor="title">{intl.formatMessage({ id: 'app.label.title' })}</label>
                    <input htmlFor="title" type="text" name="title" onChange={this.handleChange} />
                  </div>
                  <div className="col s6 input">
                    <label htmlFor="category">
                      {intl.formatMessage({ id: 'app.label.category' })}
                    </label>
                    <select
                      className="select-wrapper"
                      name="category"
                      defaultValue="0"
                      onChange={this.handleChange}
                    >
                      <option value="0">{intl.formatMessage({ id: 'app.label.select' })}</option>
                      {documentCategories.map(document => (
                        <option key={document.value} value={document.value}>
                          {document.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <button
                    type="button"
                    className="btn col offset-m5 m2 blueBtn importBtn"
                    onClick={() => this.handleUpload()}
                    disabled={disabled}
                  >
                    <span className="btnTitle">
                      {intl.formatMessage({ id: 'app.label.import' })}
                    </span>
                    <img src={icons.whiteImport} alt="white import" className="whiteImport" />
                    <img src={icons.blueImport} alt="blue import" className="blueImport" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {loading === true ? <LoadingPage /> : ''}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(DocumentImport))
