import M from 'materialize-css'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import DailyBalancePart from '../../../components/Parameters/Vaccination/DailyBalancePart'
import IncompatibilityPart from '../../../components/Parameters/Vaccination/IncompatibilityPart'
import RealisationPart from '../../../components/Parameters/Vaccination/RealisationPart'
import ValidationPart from '../../../components/Parameters/Vaccination/ValidationPart'
import EndOfRealizationPart from '../../../components/Parameters/Vaccination/EndOfRealizationPart'

export default class VaccinationParamsList extends React.Component {
  componentDidMount() {
    M.AutoInit()
  }

  render() {
    return (
      <div className="row Container params">
        <div className="col s12 paramsContent">
          <ul className="tabs">
            <li className="tab col s3">
              <a className="active" href="#incompatibility">
                <FormattedMessage id="app.label.incompatibility" />
              </a>
            </li>
            <li className="tab col s3">
              <a href="#realisation">
                <FormattedMessage id="app.label.realisation" />
              </a>
            </li>
            <li className="tab col s2">
              <a href="#validation">
                <FormattedMessage id="app.label.validation" />
              </a>
            </li>
            <li className="tab col s2">
              <a href="#dailyRapport">
                <FormattedMessage id="app.label.dailyReport" />
              </a>
            </li>
            <li className="tab col s2">
              <a href="#endOfRealization">
                <FormattedMessage id="app.label.endOfRealization" />
              </a>
            </li>
            <li className="indicator" />
          </ul>
        </div>
        <div id="incompatibility" className="col s12 content marginTop30 active">
          <IncompatibilityPart />
        </div>
        <div id="realisation" className="col s12 content marginTop30">
          <RealisationPart />
        </div>
        <div id="validation" className="col s12 content marginTop30">
          <ValidationPart />
        </div>
        <div id="dailyRapport" className="col s12 content marginTop30">
          <DailyBalancePart />
        </div>
        <div id="endOfRealization" className="col s12 content marginTop30">
          <EndOfRealizationPart />
        </div>
      </div>
    )
  }
}
