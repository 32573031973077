import React from 'react'
import { injectIntl } from 'react-intl'

const Header = props => {
  const { intl, gender } = props

  return (
    <div className="prestationsHeader">
      <div>{intl.formatMessage({ id: `app.label.${gender}PrestationsValidation` })}</div>
    </div>
  )
}

export default injectIntl(Header)
