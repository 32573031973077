import React from 'react'
import { injectIntl } from 'react-intl'
import InputSelectVaccine from './partials/InputSelectVaccine'

class ReportProblemFormDamagedVaccine extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      vaccinesSelected: [{}]
    }
    this.addVaccineSelected = this.addVaccineSelected.bind(this)
  }

  componentDidMount() {
    const { getDataFromChild } = this.props
    let { disabledList } = this.props
    disabledList = [true]
    getDataFromChild('disabledList', disabledList)
  }

  addVaccineSelected() {
    const { vaccinesSelected } = this.state
    const { disabledList, getDataFromChild } = this.props
    vaccinesSelected.push({})
    disabledList.push(true)
    getDataFromChild('disabledButton', true)
    getDataFromChild('disabledList', disabledList)
  }

  render() {
    const { disabledButton, getDataFromChild, intl, preparation, type } = this.props
    const { vaccinesSelected } = this.state
    const { vaccines } = preparation.preparation

    const vaccineError = [7, 8]

    return (
      <>
        <div className="row">
          <div className="col s12">
            <div className="form">
              <div className="card-content">
                <div className="card-block">
                  {vaccinesSelected.map((vaccineSelect, index) => (
                    <InputSelectVaccine
                      key={index}
                      type={type}
                      vaccines={vaccines}
                      vaccineError={vaccineError}
                      vaccinesSelected={vaccinesSelected}
                      getDataFromChild={getDataFromChild}
                      index={index}
                    />
                  ))}
                  {vaccinesSelected && (
                    <div className="row">
                      <div className="col s12 flex flex-center">
                        <button
                          className="btn blueBtn"
                          type="button"
                          onClick={this.addVaccineSelected}
                          disabled={disabledButton}
                        >
                          {vaccineError.includes(type)
                            ? intl.formatMessage({ id: 'app.label.addWrongVaccine' })
                            : intl.formatMessage({ id: 'app.label.addDamagedVaccine' })}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default injectIntl(ReportProblemFormDamagedVaccine)
