import React from 'react'
import { injectIntl } from 'react-intl'
import OutStockButton from './OutStockButton'

function ItemHeader(props) {
  const { history, outStockPath, title } = props

  return (
    <div className="row pageHeader pageTitle itemHeader">
      <div
        onClick={() => history.goBack()}
        role="button"
        tabIndex="0"
        onKeyPress={() => history.goBack()}
        className="goBackBtn col s1"
      >
        <i className="material-icons">chevron_left</i>
      </div>
      <h5 className="col s9">{title}</h5>
      <OutStockButton to={outStockPath} className="col s2" />
    </div>
  )
}

export default injectIntl(ItemHeader)
