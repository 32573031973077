import React from 'react'
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'

const Td = props => {
  const { children, to } = props
  return (
    <td>
      <Link to={{ pathname: to }}>{children}</Link>
    </td>
  )
}

Td.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired
}

export default Td
