import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { catchError } from '../../../actions'
import icons from '../../../assets/icons/icons'
import params from '../../../parameters'
import LoadingPage from '../../LoadingPage'
import ConformitiesListView from '../ConformitiesListView'

class ContainerFridgeParamsListPart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      parameters: [],
      loading: true
    }
  }

  componentDidMount() {
    const { token } = this.props

    Axios.get(`${params.api}/api/equipment_parameters?equipmentType[]=0&equipmentType[]=1`, {
      headers: {
        Authorization: token
      }
    })
      .then(res => {
        this.setState({ parameters: res.data['hydra:member'], loading: false })
      })
      .catch(err => catchError(err.response))
  }

  render() {
    const { intl } = this.props
    const { loading, parameters } = this.state

    if (loading === true) {
      return <LoadingPage />
    }

    return (
      <div>
        <div className="row">
          <div className="col s12">
            {parameters.map(param => (
              <div className="card paramListItem" key={`param-${param.id}`}>
                <div className="paramHeader">
                  <div className="paramTitle">
                    {intl.formatMessage({ id: `app.title.equipmentParam${param.paramId}` })}
                  </div>
                  <Link to={`/parameters/equipment/edit/${param.id}`}>
                    <img src={icons.blackPen} alt="black pen icon" />
                  </Link>
                </div>
                <div className="divider greyDivider" />
                <div className="paramListItemContent">
                  <ConformitiesListView
                    conformity={param.conformityAlert.value}
                    nonBlocking={param.nonBlockingAlert.value}
                    blocking={param.blockingAlert.value}
                    paramId={param.paramId}
                    unit={
                      param.paramId === 0
                        ? intl.formatMessage({ id: 'app.unit.fridgeTemp' })
                        : intl.formatMessage({ id: 'app.unit.nitrogenLevel' })
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(ContainerFridgeParamsListPart))
