import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Filters from '../Filters/Filters'
import { ExportFile } from '../globalAction'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      role,
      token,
      filters,
      url,
      totalItems,
      getStateFromChild,
      filterByDate,
      inLoad
    } = this.props
    return (
      <div className="row pageHeader pageTitle" data-testid="injectedArray">
        <div className="flex col m8 filters">
          <div>
            <Filters
              token={token}
              setStateToParent={getStateFromChild}
              url={url}
              role={role}
              totalItems={totalItems}
              hasDate
              filterByDate={filterByDate}
              infiniteLoader
              noActive
              filters={filters}
              inLoad={inLoad}
            />
          </div>
        </div>
        <div className="export col m5 flex">
          <div className="col m9" />
          <div className="col m3">
            <ExportFile data={{ role, token }} url="/api/vaccination_histories/export/xlsx" />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(Header)))
